<template>
	<q-dialog ref="dialog">
		<q-card class="q-pa-sm dialog-card" >
			<q-btn class="close-btn" style="margin-left: auto;" flat round dense icon="close" v-close-popup color="red" size="1.25em"/>
			<SiteRemappingComponent v-if="!showEmissionFactorRemapping" :connectionId="connectionId" @update-site-map="(updatedSiteMap) => siteMap = updatedSiteMap" />

			<EmissionFactorRemapping v-if="showEmissionFactorRemapping" :connectionId="this.connectionId" :sites="sites" @update="(updatedMap) => siteEmissionFactorsMap = updatedMap" />

			<q-card-actions class="datafeed-button-right">
				<q-btn 
					v-if="!showEmissionFactorRemapping" 
					color="primary" 
					no-caps 
					label="Map Emission Factors by Site" 
					:disabled="Object.keys(siteMap?.sites ?? {}).length === 0" 
					@click="showEmissionFactorRemapping = true" 
					flat
				/>
				<div style="display: flex; gap: 10px;" v-if="showEmissionFactorRemapping" >
					<q-btn 
						label="Back"
						flat
						no-caps
						color="primary"
						@click="showEmissionFactorRemapping = false"
					/>
					<q-btn
						class="button"
						:loading="loading"
						label="Save" 
						color="secondary" 
						no-caps
						@click="updateSiteEmissionFactorMapping()"
					/>
				</div>
			</q-card-actions>
		</q-card>

	</q-dialog>
</template>
<script>

import SiteRemappingComponent from './SiteRemappingComponent.vue';
import EmissionFactorRemapping from "./DataFeedEmissionFactorRemappingComponent.vue";
import { useSiteStateStore } from '@/stores/siteState.store';
import api from '@/services/api/api';
import notify from '@/services/util/notify';

export default {
	name: "DataFeedRemappingComponent",
	components: {
		SiteRemappingComponent,
		EmissionFactorRemapping,
	},

	props: {
		connectionId: {
			required: true
		}
	},

	data() {
		return {
			siteMap: {},
			siteEmissionFactorsMap: {},
			showEmissionFactorRemapping: false,
			loading: false,
		}
	},

	computed: {
		sites() {
			const siteList = useSiteStateStore().siteList;
			const sites = {};

			for (const [rawSiteName, mappedSiteName] of Object.entries(this.siteMap?.sites ?? {})) {
				const id = siteList.find((site) => site.name === mappedSiteName).id;
				
				sites[id] ??= {
					unmapped: [],
					mapped: mappedSiteName,
				};

				sites[id].unmapped.push(rawSiteName);
			}

			return sites;
		}	
	},

	methods: {
		async updateSiteEmissionFactorMapping() {
			this.loading = true;

			try {
				await api.dataFeeds.updateEmissionFactorMappings(this.connectionId, this.siteEmissionFactorsMap);
				this.$refs.dialog.hide();
				notify.primary("Successfully updated emisison factors");
			} catch (error) {
				notify.withObject(error.response);
			} finally {
				this.loading = false;
			}
		},
	}
}

</script>
<style scoped lang="less" src="../../../assets/styles/dialog.less" />