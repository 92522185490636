import axiosInstance from "./axios-instance";

const getSuppliers = async () => {
  return await axiosInstance.get("/api/suppliers").then(res => res);
};

const getSuppliersByEmissionSource = async (emissionSourceID) => {
  return await axiosInstance.get(`/api/suppliers/${emissionSourceID}`).then(res => res);
}

const getConnectedTenants = async () => {
  return await axiosInstance("/api/suppliers/tenancies").then(res => res);
};

const createNewSupplier = async (name) => {
  return await axiosInstance.post("/api/suppliers", { name }).then(res => res);
};

export default {
  getSuppliers,
  getSuppliersByEmissionSource,
  getConnectedTenants,
  createNewSupplier,
}
