/** helper.js  */

/**
 * Converts a given date to ISO string to be used by the server.
 * 
 * When converting an NZ date to ISO string, it applies the timezone. So if the user selected Apr 1 10:am, toISOString would return Mar 31 9:00pm
 * which is what we don't want to happen
 * @param {*} date 
 * @returns 
 */
exports.toISOStringIgnoreTimezone = function (date) {
    try {
        let yourDate = new Date(date);
        let offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - (offset*60*1000))

        return yourDate.toISOString().split('T')[0]
    } catch (error) {
        console.error("Couldn't convert provided date: " , error.message)
        return date.toISOString().split('T')[0]
    }
}