<template>
    <div class="main-tab-container" :style="{ 'margin-left': sidebarWidth }">
        <ViewDatasetComponent v-if="store.dataView=='default'"
            :emissionSource="$route.params.emissionSource" 
            @toggleView="store.changeView('trace')">
        </ViewDatasetComponent>
        <TraceabilityViewComponent :emissionSource="$route.params.emissionSource" v-else />
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import ViewDatasetComponent from '@/components/DataComponents/DataComponent.vue'
import TraceabilityViewComponent from "@/components/DataComponents/TraceabilityComponents/TraceabilityViewComponent.vue";

import { useChartStateStore } from '@/stores/chartState.store';
import { useRoute } from 'vue-router';

export default {
    name: "dataset-view",
    components: { ViewDatasetComponent, TraceabilityViewComponent},
    title: 'Dataset View',    
    setup() {
        const store = useChartStateStore()
        const route = useRoute()

        store.fetchGraphData(route.params.emissionSource, 'month')
        store.changeView("default")

        return { store, sidebarWidth }
    },
};
</script>
