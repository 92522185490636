<template>
  <div class="organisations-container" :style="{ 'margin-left': sidebarWidth }">
    <div class="header">
      <!-- <q-icon name="corporate_fare" size="3em" color="primary" /> -->
      <h1 class="main-heading">Organisations</h1>
    </div>

    <div class="tabs-container">
      <q-tabs v-model="selectedTab" active-color="primary"
        indicator-color="success" align="left" narrow-indicator dense no-caps>
        <q-tab v-for="tab in tabs" :key="tab.component" :name="tab.component" :label="tab.label" :ripple="false" />
      </q-tabs>
    </div>
    <q-separator style="margin-bottom: 2em;" color="grey" />
    <component :is="selectedTab" />
  </div>
</template>

<script>
import { sidebarWidth } from '@/components/SidebarComponents/state';
import { ref } from 'vue';
import LeaderboardComponent from "@/components/OrganisationComponents/LeaderboardComponent.vue";
import DashboardComponent from '@/components/OrganisationComponents/DashboardComponent.vue';

export default {
  name: "organisations-view",
  components: { LeaderboardComponent, DashboardComponent },
  data() {
    return {
      sidebarWidth,
      selectedTab: ref("DashboardComponent"),
      tabs: [
        {
          label: "Dashboard",
          component: "DashboardComponent"
        },
        {
          label: "Leaderboard",
          component: "LeaderboardComponent"
        },
      ]
    }
  }
};
</script>

<style lang="less" src="@/assets/styles/organisations.less" />