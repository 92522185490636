<template>
  <div>
    <q-card>
      <q-card-section class="row">
        <q-input class="search-emission-factor" placeholder="Search" color="primary" v-model="search" clearable>
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>

        <EmissionFactorFilterComponent 
          @updateDisplayedEmissionFactors="updateDisplayedEmissionFactors" 
          :availableSources="availableSources"
          :availableEmissionSources="availableEmissions"
          :availableCategories="availableCategories"
          />

      </q-card-section>
      <q-card-section>
        <q-table
          class="data-types-table"
          :rows="displayedDataTypes"
          :columns="dataTypeTableColumns"
          :loading="loadingDataTypesTable"
          :rows-per-page-options="[10]"
          :filter="search"
          row-key="uuid"
          binary-state-sort
        >
          <template #body="props">
            <q-tr :props="props">
              <q-td v-for="col in props.cols" :key="col.name" :props="props">
                <div v-if="col.name === 'name'">
                  <q-btn 
                    dense
                    flat
                    style="margin-right: 3px;"
                    :icon="props.expand ? 'keyboard_arrow_up' : 'keyboard_arrow_right'"
                    @click="handleDropdown(props)" 
                  >
                  </q-btn>
                  <span>{{ props.row.carbonRateName}}</span>

                </div>
                <div v-else-if="col.name === 'subcategory'">
                  <span>{{ props.row.ghgDescription }}</span>
                </div>
                <div v-else-if="col.name === 'action' && props.row.source === 'Custom'">
                  <q-btn v-if="flags.FLAG_4867_EDIT_EMISSION_FACTOR" class="edit-button q-pa-none q-mr-md" dense icon="edit_square" flat color="primary">
                    <q-popup-proxy 
                      class="data-type-edit-popup" :ref="`editTypePopup${props.row.uuid}`"
                      @before-hide="newDataType = {}; editMessage = ''">
                      <q-banner class="data-type-edit-popup-banner">
                        <span class="edit-heading">Edit Emission Factor</span>
                        <span v-if="props.row.isSpendBased"><q-chip class="q-mb-sm q-ml-md" label="Spend-based"></q-chip></span>
                        <q-form class="edit-form" @submit="updateDataType(props.row.uuid)">
                          <template v-for="editCol in props.cols" :key="editCol.name">
                            <q-input 
                              v-if="['name', 'description'].includes(editCol.name)"
                              :label="editCol.label"
                              @update:model-value="(value) => updateEditValue(editCol.name, editCol.value, value)"
                              :model-value="newDataType[editCol.name] ?? editCol.value"
                              stack-label
                              hide-bottom-space
                              maxlength="50"
                              :required="editCol.name !== 'description'"
                            />
                            <q-select
                              v-else-if="editCol.name === 'unit'"
                              @update:model-value="(value) => updateEditValue(editCol.name, editCol.value, value)"
                              :model-value="newDataType[editCol.name] ?? editCol.value" label="Unit" :options="unitOptions"
                              :rules="[(val) => val !== null || 'Please select a unit']" stack-label emit-value map-options
                            />
                            <q-select 
                              v-else-if="editCol.name === 'scope'"
                              @update:model-value="(value) => updateEditValue(editCol.name, editCol.value, value)"
                              :model-value="newDataType[editCol.name] ?? editCol.value" label="Scope" :options="[1, 2, 3]"
                              :rules="[(val) => val !== null || 'Please select a scope']" stack-label emit-value map-options
                            />
                            <q-select 
                              v-else-if="editCol.name === 'subcategory'" 
                              :model-value="newDataType[editCol.name] ?? editCol.value"  
                              @update:model-value="(value) => updateEditValue(editCol.name, editCol.value, value)"
                              label="GHG Subcategory" 
                              :options="ghgCategoryOptions"  
                              :rules="[(val) => val !== null || 'Please select a GHG Subcategory']" stack-label 
                              :option-label="(label) => `${label.name}: ${label.description}`" 
                              :disable="(newDataType['scope'] ?? props.row.scope) != '3'"
                              >
                              <template v-slot:hint>
                                <a href="https://ghgprotocol.org/" style="color: purple">
                                  GHG protocol
                                </a>
                              </template>
                            </q-select>
                          </template>

                          <div>
                            <span>Add comments below</span>
                            <q-input borderless outlined v-model="editMessage" label="Comments" type="textarea" maxlength="200"
                              lazy-rules required :rules="[val => val.trim() && val.trim().length > 0 || 'Please enter a comment']" />
                          </div>
                          <div class="buttons-container">
                            <q-btn label="Cancel" no-caps color="grey-5" v-close-popup />
                            <div>
                              <q-btn
                                type="submit"
                                label="Confirm"
                                color="secondary"
                                no-caps
                                :loading="loadingSubmit" 
                                :disable="Object.keys(newDataType).length === 0"
                              />
                            </div>
                          </div>
                        </q-form>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                  <q-btn flat icon="delete" color="red" dense class="q-pa-none" @click="confirmDataTypeDeletion[props.row.uuid] = true">
                    <q-popup-proxy class="oid-rate-delete-popup" :ref="`deleteTypePopup${props.row.uuid}`">
                      <q-banner>
                        <div class="oid-rate-delete-confirmation">
                          <span class="delete-confirmation-heading">Are you sure you want to delete this?</span>
                          <span>This will be deleted permanently. You can not undo this.</span>
                          <div>
                            <q-btn style="margin-right: 1rem; border: 1px solid black" text-color="black" type="submit" label="Cancel" color="white" no-caps v-close-popup />
                            <q-btn type="submit" label="Delete" color="red" @click="$emit('deleteType', props.row.uuid)" no-caps />
                          </div>
                        </div>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </div>
                <span v-else>{{ col.value }}</span>
              </q-td>
            </q-tr>

            <q-tr v-if="props.expand" :props="props" class="conversion-rates-tr">
              <q-td colspan="100%">
                <q-card flat>
                  <q-card-section>
                    <q-table
                      :rows="conversionRates[props.row.uuid] || []"
                      :columns="conversionRateTableColumns"
                      :rows-per-page-options="[10]"
                      :loading="conversionRatesTableLoading[props.row.uuid]"
                      :visible-columns="visibleEmissionFactorColumns(props.row.source)"
                      binary-state-sort
                      row-key="uuid"
                    >
                    <template v-slot:header-cell-conversionRate="props">
                      <q-th :props="props">
                        {{ props.col.label }}
                      </q-th>
                    </template>
                      <template #body-cell-action="props"  >
                        <q-td :props="props">
                          <q-btn class="edit-button" icon="edit_square" fab-mini flat color="primary">
                            <q-popup-proxy
                              class="edit-emission-factor-popup-proxy"
                              @before-hide="resetValues()"
                              :ref="`editPopup${props.rowIndex}`"
                            >
                              <q-banner>
                                <q-form class="edit-form" @submit="submitChanges(props)">
                                  <span class="edit-heading">Edit Factor Amount</span>
                                  <div class="edit-factor">
                                    <q-input
                                      class="factor-input"
                                      label="Factor"
                                      :model-value="editFactorAmount || initFactors(props.row.conversionRate)"
                                      @update:model-value="(val) => editFactorAmount = val"
                                      type="number"
                                      step="any"
                                      required
                                    />
                                    <q-icon name="help" color="primary" size="1.25rem">
                                      <q-tooltip class="text-body2">This is the factor GreenHalo uses to directly calculate kgCO2e. The factor should translate the units of the emission into kgCO2e</q-tooltip>
                                    </q-icon>
                                  </div>
                                  <div>
                                    <span>Add comments below</span>
                                    <q-input borderless outlined v-model="editMessage" label="Comments" type="textarea" maxlength="200"
                                      lazy-rules required
                                      :rules="[val => val.trim() && val.trim().length > 0 || 'Please enter a comment']" />
                                  </div>

                                  <div class="buttons-container">
                                    <q-btn label="Cancel" no-caps color="grey-5" v-close-popup />
                                    <div>
                                      <q-btn
                                        type="submit"
                                        label="Confirm"
                                        color="secondary"
                                        no-caps
                                        :disabled="editFactorAmount === originalFactorAmount"
                                        :loading="loadingSubmit" />
                                      <q-tooltip v-if="editFactorAmount === originalFactorAmount" >Emission factor has not been changed</q-tooltip>
                                    </div>
                                  </div>
                                </q-form>
                              </q-banner>
                            </q-popup-proxy>
                          </q-btn>

                          <q-btn class="edit-button" icon="delete" fab-mini flat color="red">
                            <q-popup-proxy
                              class="edit-emission-factor-popup-proxy"
                              @before-hide="resetValues()"
                              :ref="`deletePopup${props.rowIndex}`"
                            >
                              <q-banner>
                                <q-form class="edit-form" @submit="deleteConversionRate(props)">
                                  <span class="edit-heading">Delete Emission Factor</span>
                                  <div>
                                    <span>Add comments below</span>
                                    <q-input borderless outlined v-model="editMessage" label="Comments" type="textarea" maxlength="200"
                                      lazy-rules required
                                      :rules="[val => val.trim() && val.trim().length > 0 || 'Please enter a comment']" />
                                  </div>

                                  <div class="buttons-container">
                                    <q-btn label="Cancel" no-caps color="grey-5" v-close-popup />
                                    <div>
                                      <q-btn
                                        type="submit"
                                        label="Confirm"
                                        no-caps
                                        color="secondary"
                                        :loading="loadingSubmit" />
                                    </div>
                                  </div>
                                </q-form>
                              </q-banner>
                            </q-popup-proxy>
                          </q-btn>
                        </q-td>
                      </template>
                    </q-table>

                    <q-expansion-item
                      v-if="!conversionRatesTableLoading[props.row.uuid] && props.row.source === 'Custom'"
                      class="add-expansion"
                      expand-icon="add"
                      expanded-icon="remove"
                      label="Add New Conversion Rate"
                      dense
                      expand-icon-toggle
                      @before-show="initNewFactorData(props.row.uuid)"
                      @after-hide="resetNewFactorInputs(props.row.uuid)"
                      :ref="`addFactorExpansion${props.row.uuid}`"
                    >
                      <q-form v-if="newFactorData[props.row.uuid]" class="add-factor-container" :ref="`addFactorForm${props.row.uuid}`" @submit="addConversionRate(props.row.uuid)">
                        <q-tr>
                          <q-td>
                            <span class="add-factor-input-label">Start Date</span>
                            <q-input
                              dense
                              v-model="newFactorData[props.row.uuid].startDate"
                              :mask="dateFormat.replace(/[a-z]/gi, '#')"
                              :rules="[(date) => dayjs(date, dateFormat, true).isValid() && isValidStartDate(date, newFactorData[props.row.uuid].endDate, dateFormat, dateFormat) || 'Please enter a valid date']"
                              :placeholder="dateFormat"
                              clearable
                              required
                              :ref="`startDateInput${props.row.uuid}`"
                            >
                              <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy cover transition-show="scale" transition-hide="scale" @before-hide="checkEndDate(newFactorData[props.row.uuid], props.row.uuid)">
                                    <q-date v-model="newFactorData[props.row.uuid].startDate" :mask="dateFormat">
                                      <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                            </q-input>
                          </q-td>
                          <q-td>
                            <span class="add-factor-input-label">End Date</span>
                            <q-input
                              dense
                              v-model="newFactorData[props.row.uuid].endDate"
                              :mask="dateFormat.replace(/[a-z]/gi, '#')"
                              :rules="[(date) => dayjs(date, dateFormat, true).isValid() && isValidEndDate(newFactorData[props.row.uuid].startDate, date, dateFormat, dateFormat) || 'Please enter a valid date']"
                              :placeholder="dateFormat"
                              clearable
                              required
                            >
                              <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                    <q-date v-model="newFactorData[props.row.uuid].endDate" :mask="dateFormat" :options="(date) => isValidEndDate(newFactorData[props.row.uuid].startDate, date, dateFormat, 'YYYY/MM/DD')">
                                      <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                            </q-input>
                          </q-td>
                          <q-td>
                            <span class="add-factor-input-label">Factor
                              <q-icon name="help" color="primary" size="1.25rem">
                                <q-tooltip class="text-body2">This is the factor GreenHalo uses to directly calculate kgCO2e. The factor should translate the units of the emission into kgCO2e</q-tooltip>
                              </q-icon>
                            </span>
                            <q-input
                              dense
                              v-model="newFactorData[props.row.uuid].factor"
                              type="number"
                              step="any"
                              required
                            />
                          </q-td>
                          <q-td>
                            <span class="add-factor-input-label">Comments (optional)</span>
                            <q-input v-model="newFactorData[props.row.uuid].message" dense maxlength="200" />
                          </q-td>
                        </q-tr>
                        <q-btn type="submit" label="Add" color="secondary" no-caps :loading="loadingSubmit"/>
                      </q-form>
                    </q-expansion-item>
                  </q-card-section>
                </q-card>
              </q-td>
            </q-tr>
          </template>
        </q-table>

      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import api from "@/services/api/api.js";
import notify from "@/services/util/notify";
import dayjs from "@/services/util/dayjs";

import EmissionFactorFilterComponent from "./EmissionFactorFilterComponent.vue";

export default {

  props: {
    ghgCategoryOptions: {
      type: Array,
      required: true,
      default: new Array(),
    },

    emissionSourceOptions: {
      type: Array,
      required: true,
    },

    unitOptions: {
				type: Array,
				required: true,
		},

    loadingSubmit: {
      type: Boolean,
      required: true
    }
  },

  components: {EmissionFactorFilterComponent},


  data() {
    return {
      dayjs: dayjs,
      dateFormat: "DD/MM/YYYY",
      search: "",
      loadingDataTypesTable: false,
      conversionRatesTableLoading: {},
      confirmDataTypeDeletion: {},
      dataTypes: [],
      displayedDataTypes: [],
      newDataType: {},
      conversionRates: {},
      editFactorAmount: null,
      originalFactorAmount: null,
      editMessage: "",
      newFactorData: {},
      existingUuids: new Set(),
      dataTypeTableColumns: [
        {
          name: "name",
          label: "Name",
          align: "left",
          style: "max-width: 25vw; min-width: 10%",
          sortable: true,
          field: (row) => row.displayName,
        },
        {
          name: "emission",
          label: "Emission Source",
          align: "left",
          sortable: true,
          field: (row) => row.emission,
        },
        {
          name: "source",
          label: "Source",
          align: "left",
          sortable: true,
          field: (row) => row.source,
        },
        {
          name: "unit",
          label: "Unit",
          align: "left",
          field: (row) => row.unit,
        },
        {
          name: "scope",
          label: "Scope",
          align: "left",
          sortable: true,
          field: (row) => row.scope
        },
        {
          name: "subcategory",
          label: "Subcategory",
          align: "left",
          sortable: true,
          field: (row) => ({
            id: row.ghgId,
            name: row.ghgName,
            description: row.ghgDescription
          })
        },
        
        {
          name: "description",
          label: "Description",
          align: "left",
          field: (row) => row.emissionFactorDescription,
        },
        {
          name: "action",
          label: "Action",
          align: "center",
        }
      ],
      conversionRateTableColumns: [
        {
          name: "startDate",
          label: "Start Date",
          align: "left",
          field: (row) => row.startDate,
          sort: (a, b) => new Date(a) - new Date(b),
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour12: false
          }),
          sortable: true,
        },
        {
          name: "endDate",
          label: "End Date",
          align: "left",
          field: (row) => row.endDate,
          sort: (a, b) => new Date(a) - new Date(b),
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
          sortable: true,
        },
        {
          name: "conversionRate",
          label: "Factor",
          align: "left",
          field: (row) => row.conversionRate,
        },
        {
          name: "action",
          label: "Action",
          align: "center",
        }
      ],

      availableSources: [],
      availableEmissions: [],
      availableCategories: [],

      currentFilters: null
    }
  },

  async created() {
    await this.initDataTypes();
  },

  methods: {

    /**
     * @desc Filters visible columns for emission factor conversion rates given whether an emission factor is custom or not
     * 
     * @param {String} source - The source of the emission factor
     * @returns {Array<String>} An array of columns that should be visible for that emission factor conversion rates
     * 
     */
    visibleEmissionFactorColumns(source) {
      return this.conversionRateTableColumns.flatMap(col => source === 'Custom' || (source !== 'Custom' && col.name !== 'action') ? [col.name] : []);
    },


    /**
     * @desc Checks if the start date is the same or after the end date.
     * 
     * @param {String} startDate 
     * @param {String} endDate 
     * @returns {Boolean}
     */
    isValidEndDate(startDate, endDate, startDateFormat, endDateFormat) {
      return startDate ? dayjs(endDate, endDateFormat).isSameOrAfter(dayjs(startDate, startDateFormat)) : true;
    },


    /**
     * @desc Checks if the start date is the same or before the end date.
     * 
     * @param {String} startDate 
     * @param {String} endDate 
     * @returns {Boolean}
     */
    isValidStartDate(startDate, endDate, startDateFormat, endDateFormat) {
      return endDate ? dayjs(startDate, startDateFormat).isSameOrBefore(dayjs(endDate, endDateFormat)) : true;
    },


    /**
     * @desc Checks if the end date is before the start date. Reset the end date if so.
     * 
     * @param {Object} factorObj newFactorData[uuid]
     */
    checkEndDate(factorObj, uuid) {
      const start = dayjs(factorObj.startDate, this.dateFormat);
      const end = dayjs(factorObj.endDate, this.dateFormat);

      if (start.isAfter(end)) {
        factorObj.endDate = "";
        this.$refs[`startDateInput${uuid}`].validate();
      }
    },


    initNewFactorData(uuid) {
      this.newFactorData[uuid] = {};
      this.newFactorData[uuid].startDate = "";
      this.newFactorData[uuid].endDate = "";
      this.newFactorData[uuid].factor = null;
      this.newFactorData[uuid].message = "";
    },


    /**
     * @desc Reset the new factor input for a given carbon rate UUID
     * @param {*} uuid 
     */
    resetNewFactorInputs(uuid) {
      this.initNewFactorData(uuid);
      this.$refs[`addFactorForm${uuid}`].resetValidation();
    },


    /**
     * @desc Reset the input values.
     */
    resetValues() {
      this.editFactorAmount = null;
      this.originalFactorAmount = null;
      this.editMessage = "";
    },


    /**
     * @desc Sets the originalFactorAmount and editFactorAmount. Should only be called once, at the start.
     * 
     * @param {Number} val 
     * @returns {Number} editFactorAmount
     */
    initFactors(val) {
      this.originalFactorAmount = val;
      this.editFactorAmount = val;
      return this.editFactorAmount;
    },

    /**
     * @desc Retrieve the data types from the backend.
     * 
     * @returns {Array} the data
     */
    async initDataTypes() {
      this.loadingDataTypesTable = true;
      try {
        this.dataTypes.length = 0;
        this.existingUuids.clear();

        const dataTypeResponse = await api.dataTypes.getDataTypes();

        if (!dataTypeResponse.data) {
          notify.error("Unable to retrieve Emission Factors")
        } else {
          const dataTypeRows = dataTypeResponse.data;

          for (const row of dataTypeRows) {
            this.dataTypes.push(row);
            this.existingUuids.add(row.uuid);
          }

          this.availableSources = [...new Set(this.dataTypes.map(item => item.source))];
          this.availableEmissions = this.emissionSourceOptions.map(item => item.label);
          this.availableCategories = this.ghgCategoryOptions.map(item => item.description);

          if (this.currentFilters) {
            this.updateDisplayedEmissionFactors()
          } else {
            this.displayedDataTypes = this.dataTypes
          }

        }
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingDataTypesTable = false;
      }
    },


    /**
     * @desc Applies the selected filters from EmissionFactorFilterComponent to the list of displayed emission sources.
     * @param {Object} selectedFilters 
     * @example
     * {
     *     'source': ['MfE', 'Customer'],
     *     'emissionSources': ['Air Travel'],
     *     'scopes': [1, 2, 3],
     *     'categories': [],
     * }
     */
    updateDisplayedEmissionFactors(selectedFilters) {
      this.currentFilters = selectedFilters ?? this.currentFilters

      this.displayedDataTypes = this.dataTypes

      for (const [filter, selectedValues] of Object.entries(this.currentFilters)) {
        if (selectedValues.length > 0) {
          switch (filter) {
            case "source":
              this.displayedDataTypes = this.displayedDataTypes.filter(item => selectedValues.includes(item.source));
              break;
            case "emissionSources":
              this.displayedDataTypes = this.displayedDataTypes.filter(item => selectedValues.includes(item.emission));
              break;
            case "scopes":
              this.displayedDataTypes = this.displayedDataTypes.filter(item => selectedValues.includes(item.scope));
              break;
            case "categories":
              this.displayedDataTypes = this.displayedDataTypes.filter(item => selectedValues.includes(item.ghgDescription));
              break;
            default:
              // Handle unknown filters
              console.error("Unknown filter:", filter);
          }
        }
      }
    },


    /**
     * @desc Retrieve the carbon conversion rates for a custom carbon rate UUID
     * @param {string} uuid Carbon rate 
     */
    async initConversionRates(uuid) {
      this.conversionRatesTableLoading[uuid] = true;
      try {
        this.conversionRates[uuid] = (await api.dataTypes.getConversionRates(uuid)).data;
      } catch (err) {
        this.conversionRates[uuid] = [];
        notify.withObject(err.response);
      } finally {
        this.conversionRatesTableLoading[uuid] = false;
      }
    },


    async handleDropdown(props) {
      props.expand = !props.expand;
      
      if (props.expand) {
        await this.initConversionRates(props.row.uuid);
      } else {
        // Mainly to reduce the noticeable delay to load the table for subsequent calls
        delete this.conversionRates[props.row.uuid];
        delete this.newFactorData[props.row.uuid];
      }
    },


    /**
     * @desc Submit the changes.
     * 
     * @param {Object} props the props value contaning the row data
     */
    async submitChanges(props) {
      await this.$emit("updateFactor", props.row.carbonRateUUID, props.row.id, this.editFactorAmount, this.editMessage);
      this.$refs[`editPopup${props.rowIndex}`].hide();
    },


    /**
     * @desc Deletes a conversion rate for a custom emission factor
     */
    async deleteConversionRate(props) {
      await this.$emit("deleteFactor", props.row.carbonRateUUID, props.row.id, this.editMessage)
      this.$refs[`deletePopup${props.rowIndex}`].hide();
    },


    /**
     * @desc Add a new conversion rate for a custom emission factor
     * @param {*} uuid 
     */
    addConversionRate(uuid) {
      const payload = {
        startDate: dayjs(this.newFactorData[uuid].startDate, this.dateFormat).format("YYYY-MM-DD"),
        endDate: dayjs(this.newFactorData[uuid].endDate, this.dateFormat).format("YYYY-MM-DD"),
        conversionRate: this.newFactorData[uuid].factor,
        message: this.newFactorData[uuid].message === "" ? 'Created new emission factor rate for custom data type': this.newFactorData[uuid].message,
      };
      this.$emit("addFactor", uuid, payload)
    },

    /**
     * @desc Parses a string into the correct UUID format
     * @returns {String} the parsed UUID value
     */
    parseUuidValue(uuid) {
      let parsed = uuid;

      if (uuid.startsWith("CUST_")) {
        parsed = uuid.substring(5);
      }
      
      return parsed.toUpperCase().trim();
    },

    updateEditValue(key, oldVal, newVal) {
      if (newVal !== oldVal) {
        switch (key) {
          case "scope":
            this.newDataType[key] = newVal;
            if (newVal === 1) {
              this.newDataType['subcategory'] = this.ghgCategoryOptions.find((opt) => opt.name === `Scope 1`) || null;
            } else if (newVal === 2) {
              this.newDataType['subcategory'] = this.ghgCategoryOptions.find((opt) => opt.name === `Scope 2`) || null;
            } else {
              this.newDataType['subcategory'] = null;
            }
            break
          case "subcategory":
            this.newDataType[key] = newVal;
            break;
          case "unit": 
            this.newDataType['unit'] = newVal.symbol;
            this.newDataType['unitId'] = newVal.id;
            break
          default:
          this.newDataType[key] = newVal.trim();
            break;
        }
      } else {
        delete this.newDataType[key];
      }
    },

    updateEmissionSource(key, oldVal, newVal) {
      this.updateEditValue(key, this.emissionSources.find((emission) => emission.label === oldVal).value, newVal);
    },

    validateUuid(key, oldUuid, newUuid) {
      if (newUuid === this.parseUuidValue(oldUuid)) {
        return;
      }

      newUuid = 'CUST_' + newUuid;
      
      if (!/^\w+$/.test(newUuid)) {
        return "Can only contain letters, numbers, and underscores";
      } else if (this.existingUuids.has(newUuid)) {
        return `${newUuid} already exists`;
      }
    },

    /**
     * Update the existing data type with new values
     * 
     * @param {String} uuid The UUID to update
     */
    async updateDataType(uuid) {
      await this.$emit("updateType", uuid, {...this.newDataType, message: this.editMessage})
    },
  },
}
</script>

<style lang="less" src="@/assets/styles/emissionFactorSettings.less" />
