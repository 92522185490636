<template>
  <div class="signup-container">
    <h1 class="main-heading">We would like to know more about your needs</h1>
    <div class="details-container">
      <h2 class="details-heading">What would you like to use GreenHalo for?</h2>
      <q-option-group class="need" :options="options" type="checkbox" v-model="group" left-label="" />
    </div>
  </div>
</template>

<script>
import { useSignupStore } from "@/stores/signup.store";

export default {
  name: "CompanyNeeds",
  data() {
    return {
      group: [],
      options: [
        { label: "Central repository for sustainability data", value: "centralRepo" },
        { label: "Collect data from our supplier automatically", value: "collectData" },
        { label: "Provide emissions data to our customers", value: "provideEmissionsData" },
        { label: "Simplify our audit process", value: "simplifyAudit" },
        { label: "Track sustainability goals & outcomes", value: "trackSustainability" },
        { label: "Report to management/board", value: "reportManagement" },
      ],
      store: useSignupStore(),
    }
  },
  watch: {
    group(){
      this.store.company.usages = this.group
    }
  },

  }

</script>

<style lang="less">
@import "../../assets/styles/signup.less";
</style>