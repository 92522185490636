import { defineStore } from 'pinia'


export const useEmissionSnapshotStore = defineStore('emissionSnapshot', {
    state: () => {
        return {
            carbonPrice: "",
            startDate: "",
            endDate: "",
            numYears: 1,
            numEntities: 5,
            numEmissions: 5,
            emissionPercentOver: 0,
            selectAll: false,
            tags: []
        }
    },

    persist: true,

    actions: {
        updateEmissionFilters(numEntities, numEmissions, emissionPercentOver, selectAll, tags) {
            this.numEntities = numEntities;
            this.numEmissions = numEmissions;
            this.emissionPercentOver = emissionPercentOver;
            this.selectAll = selectAll;
            this.tags = tags;
        },

        updateEmissionDates(startDate, endDate) {
            this.startDate = startDate;
            this.endDate = endDate;
        },
    }
})