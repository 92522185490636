<template>
    <iframe :style="{ 'height': height, 'width': width }" :src="fileSource" frameborder="1"  scrolling="auto"></iframe>
</template>

<script>
  export default {
    props: {
      file: {
        type: File,
      },
      width: {
        type: String,
        default: "100%",
      },
      height: {
        type: String,
        default: "100%",
      },
    },

    computed: {
      fileSource() {
        if (this.file instanceof File) {
          return URL.createObjectURL(new Blob([this.file], { type: "application/pdf" }));
        }

        return undefined;
      }
    }
  }
</script>

<style lang="less" scoped>

</style>