<template>
    <div class="live-carbon-price-container">       
       <span>Current Carbon Price ${{ (carbonData ) .toFixed(2)}}</span>
    </div>
</template>
  
  <script>
  import api from "@/services/api/api";
  import { useEmissionSnapshotStore } from '@/stores/audit.store';

  const LiveCarbonComponent = {
    name: "LiveCarbonComponent",
    data() {
      return {
        loading: false,
        loadSuccess: false,
  
        loadFail: false,
        loadFailMsg: "",
  
        carbonData: 0,
      };
    },

    created() {
        this.getCarbonPrice()
    },
  

    methods: {
    
      /**
       * @desc Retrieve the live carbon price information from the server
       */
      getCarbonPrice() {
        this.loading = true;
  
        api.carbon
          .getCarbonPrice()
          .then((res) => {
            this.loadSuccess = true;
            this.loadFail = false;
            this.carbonData = res.data[0].price
            useEmissionSnapshotStore().carbonPrice = this.carbonData

          })
          .catch(() => {
            this.loadFail = true;
            this.loadSuccess = false;
          })
          .finally(() => {
            this.loading = false;
          });
      },
  

      /**
       * @desc As the carbon price retrieve is in Euros, convert to NZD
       */
      getEURtoNZD() {
        this.loading = true;
  
        api.carbon
          .getLiveEURtoNZD()
          .then((res) => {
            this.loadSuccess = true;
            this.loadFail = false;
            this.conversionRate = res.data
          })
          .catch(() => {
            this.loadFail = true;
            this.loadSuccess = false;
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
    },
  };
  
  export default LiveCarbonComponent;
  </script>
  

  