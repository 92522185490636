<template>
  <div>
    <h1>Roles</h1>
    <p>Depending on the roles assigned, different roles have access to GreenHalo (Read / Write). Below is a table showing
      the permissions for each role.</p>
    <q-table bordered :columns="columns" :rows="rows" row-key="resources" :rows-per-page-options="[0]" hide-pagination />
  </div>
</template>

<script>
export default {
  data() {
    return {
      columns: [
        {
          name: "resources",
          label: "Resources",
          field: (row) => row.resources,
          align: "left",
        },
        {
          name: "master",
          label: "Master",
          field: (row) => row.master,
          align: "left",
        },
        {
          name: "admin",
          label: "Admin",
          field: (row) => row.admin,
          align: "left",
        },
        // {
        //   name: "editor",
        //   label: "Editor",
        //   field: (row) => row.editor,
        //   align: "left",
        // },
        // {
        //   name: "siteManager",
        //   label: "Site Manager",
        //   field: (row) => row.siteManager,
        //   align: "left",
        // },
        // {
        //   name: "auditor",
        //   label: "Auditor",
        //   field: (row) => row.auditor,
        //   align: "left",
        // },
        // {
        //   name: "consultant",
        //   label: "Consultant",
        //   field: (row) => row.consultant,
        //   align: "left",
        // },
        {
          name: "user",
          label: "User",
          field: (row) => row.user,
          align: "left",
        }
      ],
      rows: [
        {
          resources: "Emission Data",
          master: "R+W",
          admin: "R+W",
          editor: "W",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "R",
        },
        {
          resources: "Emission Connections",
          master: "R+W",
          admin: "R+W",
          editor: "W",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "",
        },
        {
          resources: "Mileage Claims Data",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "R",
        },
        {
          resources: "Staff Commute Data",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "R",
        },
        {
          resources: "Staff Commute Surveys",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "W",
        },
        {
          resources: "Staff Commute Survey Schedule",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "",
          consultant: "",
          user: "",
        },
        {
          resources: "Auditing",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "",
        },
        {
          resources: "Files",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "R",
          consultant: "R",
          user: "",
        },
        {
          resources: "Goals",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "",
          consultant: "R",
          user: "R",
        },
        {
          resources: "Sites",
          master: "R+W",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "",
          consultant: "R",
          user: "R",
        },
        {
          resources: "CSV Exports",
          master: "",
          admin: "R+W",
          editor: "",
          siteManager: "R+W (s)",
          auditor: "",
          consultant: "R",
          user: "",
        }
      ],
    }
  }


}
</script>

<style lang="less" scoped>
h1 {
  font-weight: bold;
  font-size: 1.5em;
  line-height: normal;
  margin-top: 0;
}
</style>