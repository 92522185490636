<template>
    <div v-if="!showRolesMatrix">
        <InviteUserComponent @fetchUsers="fetchUsers"/>
        <div class="q-pt-md users-table-container">
            <p>Manage Users</p>
            <q-table class="users-role-table" :loading="userListLoading" :rows="users" :columns="manageUserColumns">
                        <template v-slot:body-cell-role="props">
                            <q-td :props="props">
                                <q-select :ref="`select${props.row.id}`" class="role-selector"
                                dense 
                                rounded
                                standout="bg-primary text-white"
                                v-model="props.row.role" 
                                :loading="props.row.isLoading"
                                :disable="props.row.isLoading"
                                :options="allowedUserRoleTypes"
                                option-label="roleName" 
                                option-value="roleType"

                                @update:model-value="
                                    () => {
                                        onRoleSelect(props.row.id, props.row.email, props.row)
                                    }"/>
                            </q-td>
                        </template>
                        <template v-slot:body-cell-action="props">
                            <q-td :props="props">
                                <q-btn
                                no-caps
                                color="primary"
                                flat
                                @click="removeUser(props.row.id)"
                                >Remove</q-btn> 
                            </q-td>
                        </template>
            </q-table>
        </div>
    </div>
</template>
<script>
import { sidebarWidth } from '@/components/SidebarComponents/state'
import { find } from 'lodash/collection';
import { cloneDeep } from "lodash/lang"

import api from "@/services/api/api";
import notify from "@/services/util/notify";
import InviteUserComponent from "../SettingsComponents/InviteUserComponent.vue"



const RoleSettingsComponent = {

    components: {InviteUserComponent},

    data() {
        return {
            sidebarWidth: sidebarWidth,
            showRolesMatrix: false,
            users: [],
            manageUserColumns: [
                { 
                    name: "email",
                    label: "User Email",
                    field: "email",
                    style: "width: 50em",
                    align: "left",
                },
                { 
                    name: "role",
                    label: "Role",
                    field: "role",
                    align: "left"
                },
                {
                    name: "action",
                    label: "Action",
                    field: "action",
                    style: "width: 1%",
                    align: "center",
                    
                }
            ],

            allowedUserRoleTypes: [
                {roleName: "Admin", roleType: "admin"},
                {roleName: "User", roleType: "user"},
                // {roleName: "Editor", roleType: "editor"},
                // {roleName: "Site Manager", roleType: "siteManager"},
                // {roleName: "Auditor", roleType: "auditor"},
                {roleName: "Master", roleType: "master"},
                // {roleName: "Consultant", roleType: "consultant"}  
            ],
            roleList: [],
            userListLoading: true,
        }
    },
    created() {
        this.fetchRoles()
    },
    watch: {
        roleList() {
            this.fetchUsers()
        }
    },
    methods: {

        /**
         * Gets all roles avalible in the application
         */
        async fetchRoles() {
            this.roleListLoading = true;
            api.users.getUserRoles()
            .then((res) => {
                this.roleList = res.data
                } )
            .catch((error) => {
                notify.withObject(error.response);
            })
            .finally(() => {
                    this.roleListLoading = false;
            })
        },

        /**
         * Gets all users from the tenant
         */
        async fetchUsers() {
            this.userListLoading = true;
            this.users = []
            api.users.getUsers()
            .then((res) => {
                    for (let user of res.data) {
                        // We don't want to show the invoice or pavlova admin account on the users page
                        if (user.email == "invoice@greenhalo.nz" || user.email.split('@')[0] == "pavlova.admin") { continue; }

                        const _user = cloneDeep(user);
                        _user.role = find(this.roleList, (role) => { return role.roleType == _user.role });
                        _user.oldRole = _user.role;
                        _user.isLoading = false;

                        this.users.push(_user)
                    }
                }
            )
            .catch((error) => {
                notify.withObject(error.response);
            })
            .finally(() => {
                this.userListLoading = false;
            })
        },


        /**
         * Attempts to set new role for user
         * 
         * @param {*} userId The id of the user
         * @param {*} userEmail The email of the user
         * @param {*} row The row in the table that we are editing
         */
        onRoleSelect(userId, userEmail, row) {
            row.isLoading = true;
            this.$refs[`select${userId}`].blur();
            const newRole = row.role;

            api.users.updateUserRole(userId, newRole.roleType).then(() => {
                row.oldRole = newRole;
                notify.primary(`Successfully updated role of ${userEmail}, to ${newRole.roleName}.`, "top");
            }).catch((error) => {
                row.role = row.oldRole;
                notify.withObject(error.response);
            }).finally(() => {
                row.isLoading = false;
            })
        },

        /**
         * Attempts to remove a user from tenancy
         * @param {*} userId The id of the user
         */
        removeUser(userId) {
            this.userListLoading = true;
            api.users.removeUser(userId)
            .then(() => {
                    notify.primary("Successfully removed user.", "top");
                }
            )
            .catch(
                (error) => {
                    notify.withObject(error.response);
                }
            )
            .finally(() => {
                this.userListLoading = false;
                this.fetchUsers();
            })
        },
    }
};

export default RoleSettingsComponent;
</script>

<style lang="less">
@import "../../assets/styles/settings.less";
</style>