
//chart.js

/**
 * Takes a javascript object of chartData supplied by the server. The data is then parsed
 * into the format needed for ChartJS. It automatically makes the different datasets and 
 * labels from the javascript object.
 *
 * @param {*} chartData Javascript object of datapoints given by the server in the form
	* {
		"2022-01": {
			"gas": {
				"sum": 97494.8,
				"fileId": [
					"81"
				]
			},
			"coal": {
				"sum": 767420,
				"fileId": [
					"80"
				]
			}
		},
 * @returns Data formatted as chart.js line graph data.
 */
exports.generateChartData = function (chartDataDict, selectedLabel = "", datasetIndex = 0) {

	let chartData = {};

	let colours = [
		"#C4B7CB",
		"#bbc7ce",
		"#bfedef",
		"#98e2c6",
		"#545c52",
		"#61E294",
		"#7BCDBA",
		"#9799CA",
		"#BD93D8",
		"#B47AEA",
		"#FFE74C",
		"#FF5964",
		"#38618C",
		"#35A7FF"
	]

	for (const unit of Object.keys(chartDataDict)) {
		let data = {};
		let labels = [];
		let datasets = [];

		for (const [key, value] of Object.entries(chartDataDict[unit])) {
			labels.push(key)
			for (const [k, v] of Object.entries(value)) {
	
				if (!data[k]) {
					data[k] = [v.sum];
				} else {
					data[k].push(v.sum)
				}
	
			}
	
		}
		let i = 0;
		for (let type of Object.keys(data)) {
			datasets.push({
				label: type,
				data: data[type],
				borderColor: colours[i],
				backgroundColor: colours[i],
				hoverBackgroundColor: colours[i],
				hoverBorderColor: colours[i],
				hoverBorderWidth: '3',
				pointRadius: 8,
				pointHoverRadius: 8
			})
			i = (i+1) % colours.length;
		}
	
		datasets.map((x) => x.pointBackgroundColor = new Array(datasets[datasetIndex].data.length).fill(x.borderColor))
		if (selectedLabel) {
			datasets[datasetIndex].pointBackgroundColor[labels.indexOf(selectedLabel)] = '#FFF';
		}
	
		chartData[unit] = {
			labels: labels,
			datasets: datasets
		}
	}
	
	return chartData;
};

/**
 * Takes a javascript object of chartData supplied by the server. The data is truncated
 * to a smaller dataset by the given index. It gets the datapoints -5 ~ +5 from the 
 * given index and returns the dataset. The data values themselves are not mutated in
 * any way
 *
 * @param {*} chartData Javascript object of datapoints given by the server in the form
	* {
		"2022-01": {
			"gas": {
				"sum": 97494.8,
				"fileId": [
					"81"
				]
			},
			"coal": {
				"sum": 767420,
				"fileId": [
					"80"
				]
			}
		},
 * @param {*} index An integer that descides the position of the dataset that will be 
 *		truncated around
	
 * @returns A smaller set of the javascript object in the same form
 */
exports.getTraceabilityDataset = function (chartData, index, selectedLabel, datasetIndex) {

	let labels = chartData ? Object.keys(chartData) : [];
	let output = {};

	//gets lower index but 0 if lower than 0
	let i = Math.max(index - 5, 0)
	let maxI = Math.min(index + 5, labels.length)


	while (i < maxI) {
		output[labels[i]] = chartData[labels[i]]
		i++
	}

	let colours = [
		"#C4B7CB",
		"#bbc7ce",
		"#bfedef",
		"#98e2c6",
		"#545c52",
		"#61E294",
		"#7BCDBA",
		"#9799CA",
		"#BD93D8",
		"#B47AEA",
		"#FFE74C",
		"#FF5964",
		"#38618C",
		"#35A7FF"
	]


	let data = {};
	let datasets = [];
	let dataLabels = []


	for (const [key, value] of Object.entries(output)) {
		dataLabels.push(key);

		for (const [k, v] of Object.entries(value)) {

			if (!data[k]) {
				data[k] = [v.sum];
			} else {
				data[k].push(v.sum)
			}

		}

	}

	let j = 0;
	for (let type of Object.keys(data)) {
		datasets.push({
			label: type,
			data: data[type],
			borderColor: colours[j],
			backgroundColor: colours[j],
			hoverBackgroundColor: colours[j],
			hoverBorderColor: colours[j],
			hoverBorderWidth: '3',
			pointRadius: 8,
			pointHoverRadius: 8
		})
		j = (j+1) % colours.length;
	}

	datasets.map((x) => x.pointBackgroundColor = new Array(datasets[datasetIndex].data.length).fill(x.borderColor))
	if (selectedLabel) {
		datasets[datasetIndex].pointBackgroundColor[dataLabels.indexOf(selectedLabel)] = '#FFF';
	}

	return {
		labels: dataLabels,
		datasets: datasets
	}

}
