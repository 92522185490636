import { defineStore } from 'pinia'
import { ref } from 'vue'
import api from '@/services/api/api'
import notify from "@/services/util/notify";
import { useRoute, useRouter } from 'vue-router';
import { find } from 'lodash';

export const useSiteStateStore = defineStore('site', () => {

    const route = useRoute();
    const router = useRouter();

    const siteName = ref('');
    const siteId = ref(null);
    const siteList = ref([]);
    const loaded = ref(false)
    const loadingSite = ref(false);
    const siteEmissionSources = ref([]);


    /**
     * @desc Clears store and resets back to default values
     */
    function $reset() {
        siteName.value = null
        siteList.value = []
        loadingSite.value = false
        loaded.value = false;
        siteEmissionSources.value = []
    }

    async function updateActiveSite(newSiteId, newSiteName) {
        siteId.value = newSiteId;
        siteName.value = newSiteName;

        loadingSite.value = true;

        // Get Site Emission Sources
        try {
            if (localStorage.getItem("authToken") || sessionStorage.getItem("authToken")) {
                siteEmissionSources.value = (await api.sites.getSiteEmissionSources(newSiteId)).data.emissionSources;
            }
        } catch (err) {
            console.error(err);
            if (err?.response?.status != 401) {
                notify.error(`Failed to retrieve emissions sources for ${newSiteName}!`, 'top');
            }
        } finally {
            loadingSite.value = false;
        }


        // If we are on an emission source page ensure that the emission source is avalible and re-route if required
        if (route.params.emissionSource) {

            // Checks to see if the current route emission source is in the site emission sources
            if (!find(siteEmissionSources.value, ['urlName', route.params.emissionSource])) {
                router.push({ path: siteEmissionSources.value[0].urlName })
                if (siteId.value) {
                    notify.warning(`${newSiteName} is not set to track ${route.params.emissionSource}.`, 'top', 'Rerouting')
                }
            }
        }
    }
    /**
    * Retrieve the current tenants list of sites
    */
    async function getSiteList() {
        try {
            if (localStorage.getItem("authToken") || sessionStorage.getItem("authToken")) {
                siteList.value = (await api.sites.getSites()).data;

                if (siteList.value.length === 1) {
                    siteName.value = siteList.value[0].name;
                    siteId.value = siteList.value[0].id;
                } else {
                    siteName.value = 'All Sites'
                }

                if (siteId.value == null) {
                    updateActiveSite(null, "All Sites");
                } else {
                    updateActiveSite(siteId.value, siteName.value);
                }

                loaded.value = true;
            }
        } catch (err) {
            if (err?.response?.status != 401) {
                notify.error('Failed to retrieve sites list!', 'top');
            }
            loaded.value = false;
        }
    }

    return { siteName, siteList, siteId, loadingSite, siteEmissionSources, loaded, updateActiveSite, getSiteList, $reset }
})
