<template>
    <div v-if="loading" class="no-data-container">
            <q-spinner color="primary" size="6em"/>
    </div>
    <div v-else-if="Object.keys(fileInfo).length==0" class="no-data-container"><q-icon name="folder_open" size="10em"/> <span class="text-h5">No files found</span></div>
    <div v-else v-for="(object, index) in fileInfo" v-bind:key="index" >
      <q-table v-if="rowsDisplayedObject[index]=='0'"
        ref="factorFileTraceTable"
        class="trace-table"
        :rows="object"
        :columns="columns"
        row-key="name"
        hide-bottom
        :rows-per-page-options="'0'"
        virtual-scroll
        :title="index"
      >
        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-btn
              :class="props.row.id === selectedFile ? 'selected-row' : 'visibility-btn'"
              :color="props.row.id === selectedFile ? 'selected' : ''"
              :ripple="false"
              icon="visibility"
              flat
              @click="this.dataStore.changeTraceDataFileId(props.row.id)"
            />
            <q-btn
              class="download-btn"
              icon="download"
              :ripple="false"
              flat
              @click="getFileDownload(props.row.id, props.row.name)"
            />
          </q-td>
        </template>
      </q-table>
      <q-table v-else
        ref="factorFileTraceTable"
        class="trace-table"
        :rows="object"
        :columns="columns"
        row-key="name"
        hide-bottom
        :rows-per-page-options="[5]"
        virtual-scroll
        :title="index"
      >
        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-btn
            
              :class="props.row.id === selectedFile ? 'selected-row' : 'visibility-btn'"
              :color="props.row.id === selectedFile ? 'selected' : ''"
              :ripple="false"
              icon="visibility"
              flat
              @click="this.dataStore.changeTraceDataFileId(props.row.id)"
            />
            <q-btn
              class="download-btn"
              icon="download"
              :ripple="false"
              flat
              @click="getFileDownload(props.row.id, props.row.name)"
            />
          </q-td>
        </template>
      </q-table>
       <div v-if="object.length>5" class="expand-card">
                    <q-icon v-if="rowsDisplayedObject[index]=='0'" name="expand_less bounce" size="3em" @click="toggleRowExpand(index)"/>
                    <q-icon  v-else name="expand_more bounce" size="3em" @click="toggleRowExpand(index)"/>
          </div>
    </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { useDataStateStore } from "@/stores/dataState.store";
import { useChartStateStore } from "@/stores/chartState.store";

const TraceabilityFilesComponent = {
  name: "TracabilityFiles",
  data() {
    return {
      test: '3',
      loading: false,
      dataStore: useDataStateStore(),
      store: useChartStateStore(),
      rowsDisplayedObject: {},
      columns: [
        {
          name: "name",
          required: true,
          label: "File Name",
          align: "left",
          field: "name",
          style: "max-width: 40px",
          sortable: true,
        },
        {
          name: "type",
          align: "left",
          label: "Upload Method",
          format: (val) =>
            `${val.toLowerCase().charAt(0).toUpperCase() + val.slice(1)}`,
          field: "type",
          style: "max-width: 40px",
          sortable: true,
        },
        {
          name: "uploaded",
          align: "left",
          label: "Upload Date",
          field: "uploaded",
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          }),
          style: "max-width: 40px",
          sortable: true,
        },
        {
          name: "uploadedBy",
          required: true,
          label: "Uploaded By",
          align: "left",
          field: "email",
          style: "max-width: 60px",
          sortable: true,
        },
        {
          name: "action",
          align: "left",
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      fileInfo: {},
      selectedRow: "",
    };
  },

  created() {
    this.fetchFileInfo();
    Object.keys(this.fileInfo).map((x) => {
        this.rowsDisplayedObject[x] = '5';
      })
  },

  computed: {
    fileIds(){

      let chartData = this.store.chartData[this.store.selectedUnit]
      var labels = chartData ? Object.keys(chartData) : [];
      var index = labels.indexOf(this.store.selectedLabel)
      var fileObject = {};

      if(index != -1){
        var dataPoint = Object.entries(this.store.chartData[this.store.selectedUnit][labels[index]])

      for(const dataType of dataPoint) {
                fileObject[dataType[0]] = dataType[1].fileId
        }
      }
      return fileObject
    },


    selectedFile(){
      return this.dataStore.selectedTraceDataFileId
    },

    rowCount(){
      return this.rowsDisplayedObject["Electricity Transmission Loss"]
    }
  },

  watch: {
    fileIds(){
      this.fileInfo = {}
      this.fetchFileInfo()
    },

    fileInfo(){
      this.makeObject()
    },
  },
  methods: {

    makeObject() {
      Object.keys(this.fileInfo).map((x) => {
              this.rowsDisplayedObject[x] = '5';
            })
    },

    toggleRowExpand(index) {
       if(this.rowsDisplayedObject[index] === '0'){
         this.rowsDisplayedObject[index] = '5'
       }  else {
         this.rowsDisplayedObject[index] = '0'
       }
      let placeholder = this.fileInfo[index]
       this.fileInfo[index]=undefined
       this.fileInfo[index]=placeholder
    },

    async fetchFileInfo() {
      this.loading = true;
      try {
        for (const entry of Object.entries(this.fileIds)) {
          const files = (await api.file.getFileInformationById(entry[1])).data;
          if (files.length > 0) {
            this.fileInfo[entry[0]] = files;
          }
        }

        this.makeObject()
      } catch (err) {
        notify.object(err.response)
      } finally {
        this.loading = false
      }
    },

    /**
     * @desc Attempt to download a select file
     * @param {Strings} fileId id of the file to download
     * @param {Strings} fileName name of the file being downloaded
     */
    getFileDownload(fileId, fileName) {
      try {
        api.file
          .getSingleFile(fileId, fileName)
          .then(() => {
            notify.primary("File successfully downloaded", "top");
          })
          .catch((err) => {
            notify.error(err.response.data ?? "Error downloading file", "top");
          });
      } catch {
        notify.error("Error downloading file", "top");
      }
    },
  },
};

export default TraceabilityFilesComponent;
</script>

<style lang="less">
@import "../../../assets/styles/traceability.less";
</style>
