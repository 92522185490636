<template>
    <q-card style="max-width: 60rem; min-height: 96.2vh">
        <q-card-section>
        <div class="text-h6">Emission Factors</div>
        </q-card-section>

        <q-card-section>
            An emission factor gives the relationship between the amount of a pollutant produced and the amount of raw material processed or burnt. 
            We present the emission factors in carbon dioxide equivalents (CO2-e) using data and methods from the 2022 calendar year. 
            <br><br>
            For example, for every unit (kg) of coal processed/burnt, 2 CO2-e (carbon dioxide equivalents) is produced. 
        </q-card-section>

        <q-card-section>
            <a href="https://environment.govt.nz/publications/measuring-emissions-a-guide-for-organisations-2022-summary-of-emission-factors/" target="_blank">View Source Here</a>
        </q-card-section>

        <q-card-section class="q-pt-none">
            <q-table
                @row-click="(_, row) => copyName(row.displayName)"
                :rows="emissionFactors"
                :columns="columns"
                row-key="id"
                :pagination="pagination"
                :filter="filter"
                :loading="loadingFactors"
                >
                <template v-slot:top-left >
                    <q-input outlined borderless dense debounce="300" v-model="filter" placeholder="Search Emission Source" maxlength="100">
                        <template v-slot:append>
                            <q-icon name="close" @click="filter = ''" class="cursor-pointer" />
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>
				<template v-slot:body-cell-id="props">
					<q-td>
						{{props.row.uuid}}
					</q-td>
				</template>
                <template v-slot:body-cell-carbonRateName="props">
                    <q-td :name="`${props.value}-displayName`" style="max-width: 12rem; overflow: hidden; text-overflow: ellipsis;" >
                        <span>{{ props.value }} <q-tooltip>{{ props.value }}</q-tooltip> </span>
                    </q-td>
                </template>
            </q-table>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
    </q-card>
</template>
<script>

import notify from '@/services/util/notify';
import api from '@/services/api/api';
import { Notify } from 'quasar'


const EmissionFactorComponent = {
    name: 'EmissionFactorComponent',
    props: ["emissionSource"],

    data() {
        return {
            emissionFactors: [],
            loadingFactors: false,

            columns: [
				{ name: 'displayName', label: 'Name', align: 'left', field: 'displayName' },
                { name: 'emissionSource', label: 'Emission Source', align: 'left', field: 'emissionSource' },
                { name: 'source', label: 'Source', align: 'left', field: 'source' },
				{ name: 'unit', label: 'Unit', field: 'unit', align: "left", },
                { name: "isoCategory", label: "ISO Category", field: "isoCategory", align: "left", },
			],
            pagination: { rowsPerPage: 25 },

            filter: '',
        }
    },

    async created() {
        this.columns.push({ name: "ghgProtocol", label: "GHG Protocol Category", field: "category", align: "left", format: this.formatGHG })

        this.loadingFactors = true;
        
        try {
            this.emissionFactors = (await api.carbon.getEmissionFactors(this.emissionSource)).data;
        } catch (err) {
            notify.withObject(err.response);
        } finally {
            this.loadingFactors = false;
        }
    },

    methods: {
        formatGHG(val) {
			if(val){
            if (val.toLowerCase().includes("category")) {
                return val.toLowerCase().replace("category", '').trim();
            } 
			
            return val;
			} else {
				return "Not set";
			}
        },

        copyName(name) {
            navigator.clipboard.writeText(name);
            Notify.create({
                message: `Copied factor <b>${name}</b> to clipboard`,
                position: 'top',
                html: true
            })
        }

    }
}

export default EmissionFactorComponent;

</script>
