<template>
  <div :style="{ 'margin-left': sidebarWidth }">
    <div class="main-tab-container">
      <div class="header">
        <h1 class="main-heading">Settings</h1>
      </div>
      <div class="tabs-container">
        <q-tabs
          dense
          no-caps
          active-color="primary"
          indicator-color="success"
          align="left"
          narrow-indicator
        >
          <q-route-tab
            v-for="tab in tabs"
            :key="tab.name"
            :name="tab.name"
            :label="tab.label"
            :to="{ query: { tab: tab.name } }"
          />
        </q-tabs>
        <q-separator style="margin-bottom: 0.5em" color="grey" />
      </div>

      <component :is="selectedComponent" />
    </div>
  </div>
</template>

<script>
import { sidebarWidth } from "@/components/SidebarComponents/state";

import getRole from "@/services/util/role";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";
import permissions from "@/rolePermissions";

import RoleSettingsComponent from "@/components/SettingsComponents/UserRoleSettings.vue";
import ManageSubscriptionsComponent from "@/components/SettingsComponents/ManageSubscriptionsComponent.vue";
import SiteManagementComponent from "@/components/SiteComponents/ManageSite.vue";
import ManageOrganisationsComponent from "@/components/SettingsComponents/ManageOrganisationsComponent.vue";
import AcceptDataShareComponent from "@/components/SettingsComponents/AcceptDataShareComponent.vue";
import EmissionFactorSettings from "@/components/SettingsComponents/EmissionFactorSettings.vue";
import SendSurveyComponent from "@/components/SettingsComponents/SendSurveyComponent.vue";
import OIDComponent from "@/components/SettingsComponents/OIDComponent.vue";
import FinancialYearEdit from "@/components/SettingsComponents/FinancialYearEdit.vue";
import ManageSuppliersComponent from "@/components/SettingsComponents/ManageSuppliersComponent.vue";
import { find } from "lodash/collection";

export default {
  name: "settings-view",
  components: {
    RoleSettingsComponent,
    ManageSubscriptionsComponent,
    SiteManagementComponent,
    ManageOrganisationsComponent,
    AcceptDataShareComponent,
    EmissionFactorSettings,
    SendSurveyComponent,
    OIDComponent,
    FinancialYearEdit,
    ManageSuppliersComponent,
  },
  title: "settings",
  data() {
    return {
      selectedTab: this.$route.query.tab ?? "emission-factors",
      userRole: getRole(),
      subscription: getSub(),
      subPermissions: subPermissions,
      permissions: permissions,
      sidebarWidth,
    };
  },
  created() {
    if (!this.$route.query.tab) {
      this.$route.query.tab = this.tabs[0].name;
    }
  },
  computed: {
    tabs() {
      const allTabs = [
        {
          name: "emission-factors",
          label: "Emission Factors",
          visible: this.permissions.manageEmissionFactors.includes(
            this.userRole
          ),
          component: "EmissionFactorSettings",
        },
        {
          name: "manage-roles",
          label: "Manage Users",
          visible: this.permissions.manageRoles.includes(this.userRole),
          component: "RoleSettingsComponent",
        },
        {
          name: "manage-suppliers",
          label: "Manage Suppliers",
          visible: this.permissions.manageSuppliers.includes(this.userRole) && this.flags.FLAG_4872_SUPPLIER_PORTAL,
          component: "ManageSuppliersComponent",
        },
        {
          name: "manage-site",
          label: "Manage Site",
          visible:
            this.permissions.manageSites.includes(this.userRole) &&
            !this.subPermissions.manageSites.includes(this.subscription),
          component: "SiteManagementComponent",
        },
        {
          name: "incoming-organisations",
          label: "Invite Organisations",
          visible:
            this.permissions.manageOrganisationConnections.includes(
              this.userRole
            ) &&
            this.subPermissions.inviteOrganisations.includes(this.subscription),
          component: "ManageOrganisationsComponent",
        },
        {
          name: "outgoing-organisations",
          label: "Share Organisational Data",
          visible:
            this.permissions.manageOrganisationConnections.includes(
              this.userRole
            ) &&
            this.subPermissions.acceptDataSharingInvitations.includes(
              this.subscription
            ),
          component: "AcceptDataShareComponent",
        },
        {
          name: "staff-commuting-survey",
          label: "Staff Commuting Settings",
          visible: true,
          component: "SendSurveyComponent",
        },
        {
          name: "billing",
          label: "Plans & Billing",
          visible: this.permissions.manageSubscriptions.includes(this.userRole) && false,
          component: "ManageSubscriptionsComponent",
        },
      ];
      if (this.flags.FLAG_4650_OID) {
        allTabs.splice(1, 0, {
          name: "organisation-intensity-denominators",
          label: "Organisation Intensity Denominators",
          visible:
            this.permissions.manageOrganisationIntensityDenominators.includes(
              this.userRole
            ) &&
            this.subPermissions.organisationIntensityDenominators.includes(
              this.subscription
            ),
          component: "OIDComponent",
        });
      }
      if (this.flags["FLAG_4863_FY_YEAR_SETTINGS"]) {
        allTabs.splice(1, 0, {
          name: "edit-financial-year",
          label: "Carbon Financial Year End Date",
          visible: this.permissions.editFinancialYear.includes(this.userRole),
          component: "FinancialYearEdit",
        });
      }
      return allTabs.filter((t) => t.visible);
    },

    selectedComponent() {
      const tab = find(this.tabs, (tab) => tab.name == this.selectedTab);
      return tab.component;
    },
  },
};
</script>


<style lang="less" >
@import "@/assets/styles/settings.less";
</style>
