<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <ManualUploadComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import ManualUploadComponent from "../components/ManualUploadComponents/ManualUploadComponent.vue";

export default {
    name:  "audit-view",
    components: { ManualUploadComponent },
    title: 'audit',
    setup() {
        return { sidebarWidth }
    }
};
</script>