<template>
  <div class="login-container">
    <div class="login">
      <h1 class="portal-title text-center">Supplier Login</h1>
      <q-form @submit.prevent="login()">
        <div>
          <span class="input-label">Email</span>
          <q-input 
            type="email"
            v-model="email"
            autocomplete
            outlined
            :rules="['email']"
            error-message="Please enter a valid email address"
            required
            maxlength="50"
          >
            <template v-slot:prepend>
              <q-icon name="o_email" />
            </template>
          </q-input>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between;">
            <span class="input-label">Password</span>
            <router-link v-if="flags?.FLAG_4437_FORGOT_PASS" to="/auth/forgot-password" tabindex="-1" style="padding-block: 5px;">Forgot Password?</router-link>
          </div>
          <q-input
            type="password"
            autocomplete
            v-model="password"
            outlined
            required
          >
            <template v-slot:prepend>
              <q-icon name="o_lock" />
            </template>
          </q-input>
        </div>
        
        <div class="form-group">
          <q-btn
            class="form-button"
            color="secondary"
            id="button-login"
            type="submit"
            label="Login"
            :loading="loading"
            no-caps
            :disable="!email || !password"
          />
        </div>
      </q-form>

      <div class="form-group-button">
        <span>Want to log in as a user? <router-link to="/auth/login" tabindex="-1" style="padding-block: 5px;">Login</router-link></span>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';

export default {
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    async login() {
      this.loading = true;
      
      try {
        const res = await api.users.loginSupplier(this.email, this.password);
        sessionStorage.setItem("supplierToken", res.data);
        this.$router.push({ path: "/supplier/upload" });
      } catch (err) {
        notify.withObject(err.response, "top", "Login");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="css" src="../assets/styles/login.css" />