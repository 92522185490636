/**
 * goals.js
 *
 * API for handling getting goal associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Gets list of goals for current tenant logged in
 * 
 * @param {Number} siteId site to filter data for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getGoals = async function (siteId, {emissionSource, startDate, endDate, tenantId} = {}) {
  return await axiosInstance.get(`/api/goals`, {params: {siteId, emissionSource, startDate, endDate, tenantId}}).then(res => res);
};


/**
 * @desc Gets single goal by goal Id
 * 
 * @param {Number} goalId id of the goal to retrieve 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getGoalInfo = async function (goalId) {
  return await axiosInstance
    .get(`/api/goals/${goalId}/progress`)
    .then((res) => res);
};


/**
 * @desc Posts a new goal to the current tenant logged in's schema
 * 
 * @param {Object} payload new goal information
 * @returns {Promise<AxiosResponse<any>>}
 */
const postGoal = async function (payload) {
  return await axiosInstance.post(`/api/goals`, payload).then((res) => res);
};

export default {
  getGoals,
  getGoalInfo,
  postGoal,
};
