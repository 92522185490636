/**
 * manualUploadHelper.js
 *
 */

/**
 * @desc Ensures that a given filename is has a csv extension
 *
 * @param filename A filename that is checked for csv type.
 * @returns true if the filename has a csv extension, false if it doesn't
 */
function validateFileType(filename) {
    let extension = filename.split('.').slice(-1)[0];
    if (extension === 'csv') {
        return true;
    } else {
        return false;
    }
}

export default {
    validateFileType
}
