<template>
  <div class="datasource-list-container">
    <h2 class="tab-section-heading">Fuel Data Connections</h2>
    <div class="content-item center-column eiep-list-item">
      <q-table :rows="sourceList" :columns="columns" row-key="name"> </q-table>

      <q-dialog position="right" v-model="showAddFuelCardPanel">
        <FuelOptionsComponent @rerenderList="getSourcesList()" @close="showAddFuelCardPanel = false" style="width:60vw; max-width:80vw;background-color: white;"/>
      </q-dialog>

      <q-btn
        class="add-connection-btn"
        no-caps
        text-color="black"
        color="white"
        icon="add"
        label="Add a new Fuel Data source connection"
        @click="showAddFuelCardPanel = true;"
      >
        <q-tooltip>
          Connect a fuel data connection with us to have automatic transport fuel
          data
        </q-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import FuelOptionsComponent from "./FuelOptionsComponent.vue";

const FuelConnectionsListComponent = {
  name: "FuelConnectionsListComponent",

  components: {
    FuelOptionsComponent,
  },

  data() {
    return {
      loading: false,
      loadSuccess: false,

      loadFail: false,
      loadFailMsg: "",

      showAddFuelCardPanel: false,

      sourceList: [],
      columns: [
        {
          name: "id",
          align: "left",
          label: "Connection ID",
          field: (row) => row.id,
        },
        {
          name: "type",
          align: "left",
          label: "Connection Type",
          field: (row) => row.type,
        },
        {
          name: "cardNumber",
          align: "left",
          label: "Card Number",
          field: (row) => row.cardNumber,
        },
      ],
    };
  },

  async created() {
    await this.getSourcesList();
  },

  methods: {


    /**
     * @desc Retrieves a list of transport fuel data sources.
     */
    async getSourcesList() {
      this.showAddFuelCardPanel = false;
      this.loading = true;
      api.transportFuels
        .getTransportFuelsConnections()
        .then((res) => {
          this.sourceList = res.data;
          this.loadSuccess = true;
        })
        .catch((err) => {
          this.loadFail = true;
          this.loadFailMsg = err.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

export default FuelConnectionsListComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>
