<template>
	<div :style="{ 'margin-left': sidebarWidth }">
		<q-inner-loading :showing="loading" label="Generating Emissions Snapshot Report" label-class="text-purple"
			label-style="font-size: 1.1em" />
		<div v-if="!loading">
			<q-btn flat round size="lg" color="primary" icon="arrow_back" @click="this.$router.back()" />

			<div class="board-report-buttons">
				<q-btn no-caps color="primary" :loading="generatingPDF" @click="exportToPDF">Export to PDF</q-btn>
			</div>

			<div class="board-report-container" id="element-to-convert">
				<div :class="Object.keys(this.intensityGraphData).length == 0 ? 'board-report-container-summary' : 'board-report-container-summary-large' " id="first-page">
					<div class="board-report-header">
						<img src="@/assets/greenhalologo.png" style="height: 2.2em; width: 10em;" />
					</div>

					<div>
						<p class="title"> Emissions Snapshot Report: {{ dateTitle }}</p>
					</div>

					<div class="board-report-intensity"
						v-if="subPermissions.organisationIntensityDenominators.includes(subscription) && Object.keys(this.intensityGraphData).length != 0">
						<br>
						<span class="title">
							Organisation Carbon Intensity Insights
						</span>
						<q-card class="board-organisation-graph">
							<Line :chartData="this.intensityGraphData" :chartOptions="generateChartOptions()" />
						</q-card>
					</div>

					<div class="board-report-summary">
						<span class="title">
							Summary - <span style="font-size: 1rem;">Top {{ Object.keys(topEntities).length }}  site/s</span>
						</span>

						<div class="board-report-summary-items">
							<div class="summary-item" v-for="item in moneySummaryCards" :key="item.id">
								<q-card flat class="summary-card">
									<q-card-section class="q-pb-none">
										<div class="summary-title">{{ item.title }}</div>
									</q-card-section>

									<q-card-section class="q-pb-none">
										<div>
											<span class="summary-amount">{{ getCarbonEquivalence(item.value, 1) }}</span>
											<q-tooltip>{{ item.value.toLocaleString() }} tonnes</q-tooltip>
										</div>
										<div class="summary-unit">tonnes</div>
									</q-card-section>

									<q-card-section class="q-pb-none">
										<div class="text-bold">Equivalent to</div>
									</q-card-section>

									<q-card-section class="q-pb-none">
										<div><span class="summary-amount">{{ item.equivalence }}</span></div>
									</q-card-section>
								</q-card>
							</div>

							<div class="summary-item" v-for="item in treeSummaryCards" :key="item.id">
								<q-tooltip>
									To compensate 1 tonne of CO2, 31 to 46 trees are needed.
								</q-tooltip>
								<q-card flat class="summary-card">
									<q-card-section class="q-pb-none">
										<div class="summary-title">{{ item.title }}</div>
									</q-card-section>


									<q-card-section class="q-pb-none">
										<div>
											<span class="summary-amount">{{ !isNaN(Math.abs(item.value)) ?
												Math.abs(item.value) : 0 }}%</span>
											<q-icon style="position: absolute;"
												:name="item.total < 0 ? 'arrow_drop_down' : 'arrow_drop_up'" size="xl"
												:color="item.total < 0 ? 'green' : 'red'" />
										</div>
										<div class="summary-unit">

											{{ !isNaN(Math.abs(item.total)) ? getCarbonEquivalence(Math.abs(item.total), 1)
												: 0 }} tonnes</div>
									</q-card-section>


									<q-card-section class="q-pb-none">
										<div class="text-bold">Equivalent to</div>
									</q-card-section>


									<q-card-section class="q-pb-none">
										<div>
											<span class="summary-amount">{{ item.equivalence }}&nbsp;</span>
											<span style="color: green">planted</span>
										</div>
										<div class="summary-unit">Trees</div>
									</q-card-section>


								</q-card>
							</div>
						</div>

						<div class="summary-top-container" id="top-carbon-page-break">

							<div class="summary-group">
								<span class="title">
									{{ this.emissionFilters.emissionPercentOver ? `Emissions over
									${this.emissionFilters.emissionPercentOver}% of total` : `Top
									${Object.keys(this.topEmissions).length} Carbon Emissions` }}
								</span>
								<div class="summary-top">
									<ReportBarGraph style="height: 530px;" :graphData="emissionTotals"
										:visibleLabels="Object.keys(topEmissions)"></ReportBarGraph>
								</div>
							</div>

							<div class="summary-group">
								<span class="title">
									Top {{ Object.keys(topEntities).length }} {{ basedOn == 'site' ? 'Sites' :
										'Organisations' }}
								</span>
								<div class="summary-top">
									<ReportBarGraph style="height: 530px;" :graphData="entityTotals"
										:visibleLabels="Object.keys(topEntities)"></ReportBarGraph>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="board-report-container-site" id="site-summary">
					<div>
						<p class="title"> Emissions Snapshot Report: {{ dateTitle }}</p>
					</div>

					<div class="board-report-sites">
						<div class="board-sites" v-for="(item, index) in Object.keys(this.topEntities)" :key="item"
							:id="'pagebreak-' + index % 2">
							<span class="site-title">{{ item }} </span>
							<div class="board-site-totals">
								<div class="summary-item"
									v-for="[key, value] of Object.entries(entityYearlyEmissions[item])" :key="key">
									<q-card flat class="summary-card q-pa-none ">
										<q-card-section class="q-pb-none">
											<div class="text-bold">{{ key }} total</div>
										</q-card-section>
										<q-card-section class="q-pb-none q-pt-none">
											<div class="summary-amount">{{ parseInt((value / 1000)).toLocaleString() }}
											</div>
											<div class="summary-unit">tonnes</div>
										</q-card-section>
									</q-card>
								</div>
							</div>
							<div class="board-site-graph">
								<Line :chartData="this.entityChartData[item]" :chartOptions="generateChartOptions(item)" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Line from '@/components/ChartComponents/Line.vue'
import ReportBarGraph from './GraphComponents/ReportBarGraph.vue';

import { sidebarWidth } from "@/components/SidebarComponents/state";
import { useEmissionSnapshotStore } from '@/stores/audit.store';
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import chartGenerator from "@/components/ChartComponents/chartGenerators/emissionSnapshotReport.js";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";

import exportToPDF from '@/services/util/exportToPDF';
import { toISOStringIgnoreTimezone } from '@/services/util/helper';

const EmissionsReportComponent = {
	name: "EmissionsReport",
	components: { ReportBarGraph, Line },

	props: ['basedOn'],

	data() {
		return {
			subscription: getSub(),
			subPermissions: subPermissions,

			loading: false,
			generatingPDF: false,
			sidebarWidth: sidebarWidth,

			siteData: {},
			intensityGraphData: {},

			organisationNames: [],
			organisationData: {},

			today: new Date(),

			// Instead of having separate objects for sites and organisations, as only one can be used at a time, we store is under entities

			emissionTotals: {},
			entityTotals: {},

			topEmissions: {},
			topEntities: {},

			tenantId: undefined,

			entityYearlyEmissions: {},

			entityChartData: {},

			emissionFilters: useEmissionSnapshotStore(),

			moneySummaryCards: [],
			treeSummaryCards: [],

			startDate: null,
			endDate: null,
		};
	},

	async created() {
		this.startDate = toISOStringIgnoreTimezone(this.emissionFilters.startDate);
		this.endDate = toISOStringIgnoreTimezone(this.emissionFilters.endDate)

		switch (this.basedOn) {
			case 'site':
				await this.retrieveSiteEmissionData();
				break;
			case 'org':
				await this.retrieveOrganisationEmissionData()
				break;
			default:
				notify.error("Failed to generate Emissions Snapshot report")
		}
	},

	computed: {
		/**
		 * @desc Calculates total carbon emissions for tenant within time period
		 * divides by 1000 to find tonnes
		 *
		 * @returns total emissions in tonnes
		 */
		totalCarbonEmissionsTonnes() {
			let total = 0;
			Object.values(this.emissionTotals).forEach((val) => (total += val));
			return total / 1000;
		},


		/**
		 * @desc Calculates total carbon emissions of top emission sources for tenant within time period
		 * divides by 1000 to find tonnes
		 *
		 * @returns top n emission source total emissions in tonnes
		 */
		topXCarbonEmissionsTonnes() {
			let total = 0;
			Object.values(this.topEmissions).forEach((val) => (total += val));
			return total / 1000;
		},


		/**
		 * @desc Format string date title for the report
		 */
		dateTitle() {
			const startDate = new Date(this.emissionFilters.startDate);
			const endDate = new Date(this.emissionFilters.endDate);

			const startDay = startDate.getDate();
			const startMonth = startDate.toLocaleDateString(undefined, { month: 'short' });
			const startYear = startDate.getFullYear()

			const endDay = endDate.getDate();
			const endMonth = endDate.toLocaleDateString(undefined, { month: 'short' });
			const endYear = endDate.getFullYear();

			return `${startDay} ${startMonth} ${startYear} -  ${endDay} ${endMonth} ${endYear}`
		},
	},

	methods: {

		/**
		 * @desc retrieves all data necessary for emission snapshot report.
		 * Determines unique site names in data, and generates charts for each site and adds that site chart
		 * data to this.siteChartsData.
		 */
		async retrieveSiteEmissionData() {
			this.loading = true;
			await api.data
				.getEmissionSnapshotData(this.startDate, this.endDate, this.emissionFilters.tags)
				.then((res) => {

					if (this.flags.FLAG_4650_OID && this.subPermissions.organisationIntensityDenominators.includes(this.subscription)) {
						this.siteData = res?.data.report;
						this.intensityGraphData = res?.data.intensityGraph ?  res?.data.intensityGraph : {}
					} else {
						this.siteData = res?.data.report ? res?.data.report : res?.data
					}

					this.entities = this.getSites(this.siteData);
					this.entityTotals = this.getSiteTotals(this.siteData);
					this.emissionTotals = this.getSiteEmissionTotals(this.siteData);

					for (let site of this.entities) {
						try {
							this.entityChartData[site] = chartGenerator.getSiteChart(
								this.siteData,
								site
							);
						} catch (err) {
							notify.error(`Failed to generate chart for ${site}`, "top");
						}
						try {
							this.entityYearlyEmissions[site] =
								this.calculateSiteYearlyEmissions(this.siteData, site);
						} catch (err) {
							notify.error(
								`Failed to calculate yearly emission totals for ${site}`,
								"top"
							);
						}
					}
					this.formatSummaryCards();
				})
				.catch((err) => {
					notify.withObject(err.response);
				}).finally(() => {
					this.loading = false;
				});
		},


		/**
		 * @desc Parses data object looking for unique site names
		 *
		 * @param {Object} data object, should look like:
		{
			"2023-01": {
				"Electricity": {
					"Site 1": 405,
					"Site 2": 521,
				},
			},
			"2023-02": {
				"Electricity": {
					"Site 1": 496,
					"Site 2": 653,
				},
			},
		}
	  * This example object would return ["Site 1", "Site 2"]
		*
	   * @returns list of site names
	   */
		getSites(data) {
			let uniqueSites = [];
			for (let month of Object.keys(data)) {
				for (let emission of Object.keys(data?.[month])) {
					for (let site of Object.keys(data?.[month]?.[emission])) {
						if (!uniqueSites.includes(site)) {
							uniqueSites.push(site);
						}
					}
				}
			}

			return uniqueSites;
		},


		/**
			 * @desc This method will calculate the carbon emission totals of each emission
			 * source in the data param
			 *
			 * @param {Object} data the data object to calculate emission totals from.
			 * @returns {Object} An object with emission sources corresponding to their totals (in kg!) e.g.
			{
				"Electricity": 4032054.34,
				"Waste": 34054.345,
				"Freight": 4305.50,
				etc...
			}
		*/
		getSiteEmissionTotals(data) {
			let totals = {};

			for (let month of Object.keys(data)) {
				for (let source of Object.keys(data[month])) {
					// If we find a new emission source name, add it to totals and set it to 0
					if (!totals[source]) {
						totals[source] = 0;
					}

					//Only add the the emission factors from our top sites. If the user select top 2 sites, we don't emission from other random sites.
					for (let [site, siteUsage] of Object.entries(data[month][source])) {
						if (Object.keys(this.topEntities).includes(site)) {
							totals[source] += siteUsage;
						}
					}
				}
			}

			if (this.emissionFilters.emissionPercentOver > 0) {
				this.topEmissions = this.getPercentOverFromObject(totals, this.emissionFilters.emissionPercentOver)
			} else {
				this.topEmissions = this.getTopNFromObject(totals, this.emissionFilters.numEmissions)
			}

			return totals;
		},


		/**
		 * @desc This method will calculate the carbon emission totals of each site
		 * in the data param
		 *
		 * @param {Object} data the data object to calculate emission totals for each site from.
		 * @returns {Object} An object with emission sources corresponding to their totals (in kg!) e.g.
			{
				"Christchurch Office": 40872054.34,
				"Warehouse": 340354.345,
				"Storefront": 43045.50,
				etc...
			}
		*/
		getSiteTotals(data) {
			let totals = {};
			for (let month of Object.keys(data)) {
				for (let emission of Object.keys(data[month])) {
					for (let siteName of Object.keys(data[month][emission])) {
						if (!totals[siteName]) {
							totals[siteName] = 0;
						}
						totals[siteName] += data[month][emission][siteName];
					}
				}
			}

			let numOfEntities = this.emissionFilters.selectAll ? Object.keys(totals).length : this.emissionFilters.numEntities

			this.topEntities = this.getTopNFromObject(totals, numOfEntities)

			return totals;
		},


		/**
		 * @desc Retrieves all the subsidary organisation data for a given organisation.
		 */
		async retrieveOrganisationEmissionData() {
			this.loading = true;

			try {
				let res = await api.tenantConnections.getTenantConnections("guest");
				this.organisations = res.data;
			} catch (err) {
				notify.withObject(err.response);
			}

			for (let organisation of this.organisations) {
				this.tenantId = organisation.guestTenantId;
				if (organisation.status) {
					let res = await api.reports.getSubsidiaryEmissionData(organisation.sourceTenantId, this.startDate, this.endDate, this.emissionFilters.tags);
					this.organisationData[organisation.sourceTenantName] = res.data;
				}
			}

			this.entities = this.organisations.map(org => org.sourceTenantName)
			this.emissionTotals = this.getOrganisationEmissionTotals(this.organisationData)
			this.entityTotals = this.getOrganisationTotals(this.organisationData)

			for (let organisation of this.entities) {
				try {
					this.entityChartData[organisation] = chartGenerator.getOrganisationChart(this.organisationData[organisation]);
				} catch (err) {
					notify.error(`Failed to generate chart for ${organisation}`, "top");
				}

				try {
					this.entityYearlyEmissions[organisation] =
						this.calculateOrganisationYearlyEmissions(this.organisationData[organisation]);
				} catch (err) {
					notify.error(
						`Failed to calculate yearly emission totals for ${organisation}`,
						"top"
					);
				}
			}

			this.formatSummaryCards();

			this.loading = false;
		},


		/**
	 * @desc Calculate the carbon emission totals of each emission source in the data param
	 *
	 * @param {Object} data the data object to calculate emission totals from.
	 * @returns {Object} An object with emission sources corresponding to their totals (in kg!) e.g.
	{
		"Electricity": 4032054.34,
		"Waste": 34054.345,
		"Freight": 4305.50,
		etc...
	}
	*/
		getOrganisationEmissionTotals(data) {
			let totals = {};

			for (let org of Object.keys(data)) {
				for (let month of Object.keys(data[org])) {
					for (let source of Object.keys(data[org][month])) {
						// If we find a new emission source name, add it to totals and set it to 0
						if (!totals[source]) {
							totals[source] = 0;
						}
						totals[source] += data[org][month][source]['carbon']
					}
				}
			}

			if (this.emissionFilters.emissionPercentOver > 0) {
				this.topEmissions = this.getPercentOverFromObject(totals, this.emissionFilters.emissionPercentOver)
			} else {
				this.topEmissions = this.getTopNFromObject(totals, this.emissionFilters.numEmissions)
			}

			return totals
		},


		/**
		 * @desc This method will calculate the carbon emission totals of each organisation
		 *
		 * @param {Object} data the data object to calculate emission totals for each organisation from.
		 * @returns {Object} An object with emission sources corresponding to their totals (in kg!) e.g.
			{
				"Organisation 1": 40872054.34,
				"Organisation 2": 340354.345,
			}
		*/
		getOrganisationTotals(data) {
			let totals = {}

			for (let org of Object.keys(data)) {
				for (let month of Object.keys(data[org])) {
					for (let source of Object.keys(data[org][month])) {
						if (!totals[org]) {
							totals[org] = 0;
						}
						totals[org] += data[org][month][source]['carbon']
					}
				}
			}

			let numOfEntities = this.emissionFilters.selectAll ? Object.keys(totals).length : this.emissionFilters.numEntities

			this.topEntities = this.getTopNFromObject(totals, numOfEntities)

			return totals;
		},


		/**
		 * @desc Sort an object on values
		 * {
		 * 	"Item1": 123123,
		 * 	"Item2": 512,
		 *  "Item3": 81242
		 * }
		 */
		sortObject(object) {
			var allItems = [];

			for (var site in object) {
				allItems.push({ key: site, value: object[site] })
			}

			allItems.sort(function (a, b) { return b.value - a.value })

			return allItems
		},


		/**
		 * Given an object of key value pairs, return the top n sorted by value
		 * @param {*} object 
		 * @param {*} n 
		 */
		getTopNFromObject(object, n) {
			var allItems = this.sortObject(object)

			let topN = allItems.slice(0, n ?? allItems.length);

			let data = {}

			for (var item of topN) {
				data[item.key] = item.value
			}

			return data
		},


		/**
		 * @desc Given a list of objects, returns the key value pairs where the value is over a given percentage of the total emissions.  
		 * @returns {Object} Object of items that are over the specified percent.
		 */
		getPercentOverFromObject(object, percent) {
			var allItems = this.sortObject(object)

			let data = {}

			for (let item of allItems) {
				data[item.key] = item.value
			}

			const total = Object.values(data).reduce((a, b) => a + b, 0);

			// Loop through the emissions, if the value is less than the specified percent - delete it
			for (let item in data) {
				let emissionPercent = parseInt(data[item] / total * 100)
				if (emissionPercent < percent) { delete data[item] }
			}

			return data
		},


		/**
		 * @desc Calculates total emissions each year for a given site.
		 * @param {String} site name of site to get total emissions per year for.
		 * @param {Object} data object to calculate site yearly emission totals from.
		 * @returns {Object} Object relating years to emission totals e.g.
			{
				"2021": 2032434.32,
				"2022": 2404014.31,
				"2023": 1030490.54,
			}
		 */
		calculateSiteYearlyEmissions(data, site) {
			let totals = {};
			for (let month of Object.keys(data)) {
				let year = month.split("-")[0];
				// If we encounter a new year, add it to the totals object and set as 0.
				if (!totals[year]) {
					totals[year] = 0;
				}
				for (let emission of Object.keys(data[month])) {
					for (let siteName of Object.keys(data[month][emission])) {
						if (siteName === site) {
							totals[year] += data[month][emission][siteName];
						}
					}
				}
			}
			return totals;
		},


		/**
		 * @desc Calculates total emissions each year for a given organisation
		 * .
		 * @param {Object} data object to calculate organisation yearly emission totals from.
		 * @returns {Object} Object relating years to emission totals e.g.
			{
				"2021": 2032434.32,
				"2022": 2404014.31,
				"2023": 1030490.54,
			}
		 */
		calculateOrganisationYearlyEmissions(data) {
			let totals = {};
			for (let month of Object.keys(data)) {
				let year = month.split("-")[0];
				if (!totals[year]) {
					totals[year] = 0;
				}

				for (let emission of Object.keys(data[month])) {
					totals[year] += data[month][emission]['carbon']
				}
			}
			return totals;
		},


		/**
		 * @desc generates graph options for the site graphs on this report page.
		 * The options for these graphs are pretty specific to this report, so this method is just
		 * housed in this component.
		 *
		 * @param {String} site determines graph title.
		 * 
		 * @returns {Object} Chart options for site graph
		 */
		generateChartOptions() {
			return {
				maintainAspectRatio: false,
				scales: {
					y: {
						grid: {
							display: false,
						},
						title: {
							display: true,
							text: "Carbon Emissions (CO2e)",
						},
					},
					
					x: {
						grid: {
							display: false,
						},
						title: {
							display: true,
							text: "Date",
						},
					},
				},

				plugins: {
					datalabels: {
						display: false
					},
					legend: {
						position: 'bottom'
					},
				},
			}
		},


		/**
		 * @desc Calculates total carbon emissions for a given timestamp in the data param.
		 * 
		 * @param {Object} data data object to parse
		 * @param {String} timestamp should be in yyyy-mm format.
		 * 
		 * @returns total carbon emissions in kg
		 */
		getSiteEmissionsAtTimestamp(data, timestamp) {
			let total = 0;
			if (!data[timestamp]) {
				return total;
			}

			Object.values(data[timestamp]).forEach((source) => {
				Object.values(source).forEach((siteUsage) => {
					total += siteUsage;
				});
			});
			return total;
		},


		/**
		 * @desc Calculates total carbon emission for a given timestamp.
		 * 
		 * @param {Object} data Organisation data to parse
		 * @param {string} timestamp should be in yyyy-mm format
		 * 
		 * @returns {number} total carbon emission in kg
		 */
		getOrgEmissionsAtTimestamp(data, timestamp) {
			let total = 0;
			for (var organisation of this.entities) {
				if (!data[organisation][timestamp]) {
					continue
				}
				Object.keys(data[organisation][timestamp]).forEach((source) => {
					total += data[organisation][timestamp][source]['carbon'];
				});
			}
			return total;
		},


		/**
		 * @desc Export the component to PDF
		 */
		async exportToPDF() {
			this.generatingPDF = true;
			let pageBreak = [];
			
			if (this.flags.FLAG_4650_OID && this.subPermissions.organisationIntensityDenominators.includes(this.subscription)) { pageBreak = ['#top-carbon-page-break', '#site-summary']}

			await exportToPDF("element-to-convert", "emissions-snapshot", ['#pagebreak-1',], pageBreak);
			this.generatingPDF = false;
		},


		/**
		 * Given a carbon total and a factor, convert it to the equivalence
		 * @param {number} carbonTotal 
		 * @param {number} factor Factor to multiply carbon total by
		 * @returns {string} equivalence
		 */
		getCarbonEquivalence(carbonTotal, factor) {
			let equivalence = Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 1
			}).format(carbonTotal * factor);
			return equivalence;
		},


		/**
		 * @desc Gets percentage change and total tonne change between current month and last year's month
		 * 
		 * @returns {Object} example: {percentageChange: 30, totalChange: 34.2}
		 */
		compareMonths_GoBackNDays(n) {

			// First we must get timestamp string of the specified end date
			let currentDate = new Date(this.emissionFilters.endDate);
			let currTimestamp = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0');

			// Then we must get timestamp string of date one year ago in yyyy-mm format
			let historicalDate = new Date(currentDate.setDate(currentDate.getDate() - n));
			let historicalTimestamp = historicalDate.getFullYear() + '-' + String(historicalDate.getMonth() + 1).padStart(2, '0');

			let currentUsage = 0;
			let pastUsage = 0;

			if (this.basedOn == 'site') {
				currentUsage = this.getSiteEmissionsAtTimestamp(this.siteData, currTimestamp);
				pastUsage = this.getSiteEmissionsAtTimestamp(this.siteData, historicalTimestamp);
			} else if (this.basedOn == 'org') {
				currentUsage = this.getOrgEmissionsAtTimestamp(this.organisationData, currTimestamp);
				pastUsage = this.getOrgEmissionsAtTimestamp(this.organisationData, historicalTimestamp);
			}

			let totalChange = pastUsage - currentUsage;
			let percentageChange = (totalChange / pastUsage) * 100
			return { totalChange: totalChange / 1000, percentageChange: percentageChange };
		},


		/**
		 * After retrieving all the data, form the summary cards to be displayed.
		 */
		formatSummaryCards() {

			let carbonEmissions = this.totalCarbonEmissionsTonnes;
			let topNCarbonEmissions = this.topXCarbonEmissionsTonnes;

			let previousMonth = this.compareMonths_GoBackNDays(30);
			let lastYearMonth = this.compareMonths_GoBackNDays(365);

			let moneyCards = [
				{
					id: 1,
					title: "All Carbon Emissions",
					value: parseFloat(carbonEmissions),
					equivalence: `$${this.getCarbonEquivalence(carbonEmissions, this.emissionFilters.carbonPrice)}`,
				},

				{
					id: 2,
					title: this.emissionFilters.emissionPercentOver ? `Emissions over ${this.emissionFilters.emissionPercentOver}%` : `Top ${Object.keys(this.topEmissions).length} Carbon Emissions`,
					value: parseFloat(topNCarbonEmissions),
					equivalence: `$${this.getCarbonEquivalence(topNCarbonEmissions, this.emissionFilters.carbonPrice)}`,
				},
			];

			// 38 is the number of trees required to offset 1 tonne of CO2 emissions.
			let treeCards = [
				{
					id: 1,
					title: "Compared to last month",
					value: previousMonth.percentageChange.toFixed(1).toLocaleString(),
					total: previousMonth.totalChange,
					equivalence: this.getCarbonEquivalence(Math.abs(previousMonth.totalChange), 38),
				},
				{
					id: 2,
					title: "This month last year",
					value: lastYearMonth.percentageChange.toFixed(1).toLocaleString(),
					total: lastYearMonth.totalChange,
					equivalence: this.getCarbonEquivalence(Math.abs(lastYearMonth.totalChange), 38),
				}
			];

			this.moneySummaryCards = moneyCards;
			this.treeSummaryCards = treeCards;

		},
	},
};

export default EmissionsReportComponent;
</script>

<style lang="less">
@import "../../assets/styles/report.less";
</style>
