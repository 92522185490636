<template>
	<q-card
		v-if="goalDetails.goalType == 'empty'"
		:style="styleObject"
		class="new-goal-card-component"
		@click="$emit('new')"
	>
		<q-icon
			class="create-new-card"
			name="add_circle"
			size="5em"
			color="secondary"
		/>
	</q-card>

	<q-card
		v-else
		:style="styleObject"
		@click="$emit('progress', goalDetails)"
		class="goal-card-component"
	>
		<q-card-section v-if="loadingGoal">
			<q-spinner
				color="primary"
				size="3em"
			/>
		</q-card-section>
		<q-card-section
			style="width: 100%"
			v-if="!loadingGoal"
		>
			<div class="goal-card-title">{{ goalDetails.name }}</div>
			<div>
				<div class="graph-panel">
					<div
						class="graph-container"
						v-if="!loadingGoal"
					>
						<q-tooltip style="font-size: 1.15rem"
							>{{
								(
									((this.goal.startValue -
										this.goal.targetValue -
										(this.goal.averageDailyUsage -
											this.goal.targetValue)) /
										(this.goal.startValue -
											this.goal.targetValue)) *
									100
								).toFixed(0)
							}}% of way to target daily usage.</q-tooltip
						>
						<div
							style="
								display: flex;
								flex-direction: row;
								justify-content: space-between;
							"
						>
							<div style="margin-left: -20px; color: #400">
								{{
									(
										goal.startValue +
										goal.reductionDiff * -1
									).toFixed(1)
								}}
							</div>
							<div>
								{{ goal.startValue.toFixed(1) }}
							</div>
							<div style="margin-right: -20px; color: #040">
								{{
									(
										goal.startValue + goal.reductionDiff
									).toFixed(1)
								}}
							</div>
						</div>
						<div class="graph-bars-container">
							<span
								class="progress-bar graph-black-bar"
								:style="
									'left: ' + graphIndicatorLeftPosition + '%;'
								"
							>
							</span>
							<div class="graph-bar graph-red-bar" />
							<div class="graph-bar graph-green-bar" />
						</div>
					</div>
				</div>
			</div>
		</q-card-section>
	</q-card>
</template>

<script>
import api from "@/services/api/api";
const GoalsCardComponent = {
	name: "GoalsCard",

	components: {},
	props: ["goalDetails"],

	data() {
		return {
			styleObject: {
				backgroundColor: "white",
				fontSize: "13px",
			},

			// Goal data
			goal: {},
			loadingGoal: false,
			graphIndicatorLeftPosition: 0,
		};
	},

	watch: {
		goalDetails() {
			if (this.goalDetails.goalType != "empty") {
				this.getGoalInfo();
			}
		},
	},

	/**
	 * @desc On page load, retrieve goal data
	 */
	async created() {
		if (this.goalDetails.goalType != "empty") {
			await this.getGoalInfo();
		}
	},

	methods: {
		/**
		 * @desc Retrieves goal data of specific goal with matching goalId in the logged in user's tenancy.
		 */
		async getGoalInfo() {
			this.loadingGoal = true;
			await api.goals
				.getGoalInfo(this.goalDetails.id)
				.then((res) => {
					this.loadSuccess = true;
					this.goal = res.data;
					// Calculate how much reduction is required to achieve the goal
					this.goal.reductionDiff =
						this.goal.targetValue - this.goal.startValue;
					// Calculate how much the tenant has changed since startValue.
					this.goal.progressDiff =
						this.goal.averageDailyUsage - this.goal.startValue;
					this.goal.progressionPercentage =
						50 +
						(this.goal.startValue / this.goal.averageDailyUsage) *
							100;

					this.graphIndicatorLeftPosition =
						50 +
						(((this.goal.startValue -
							this.goal.targetValue -
							(this.goal.averageDailyUsage -
								this.goal.targetValue)) /
							(this.goal.startValue - this.goal.targetValue)) *
							100) /
							2;

					// Check that the indicator won't fall out of bounds.
					if (this.graphIndicatorLeftPosition > 100) {
						this.graphIndicatorLeftPosition = 100;
					} else if (this.graphIndicatorLeftPosition < 0) {
						this.graphIndicatorLeftPosition = 0;
					}
				})
				.catch((err) => {
					this.loadFail = true;
					this.loadFailMsg = err.response.data;
				})
				.finally(() => {
					this.loadingGoal = false;
				});
		},
	},
};

export default GoalsCardComponent;
</script>
