<template>
    <div>
        <AuthComponent/>
    </div>
</template>

<script>
import AuthComponent from "@/components/IntroComponents/AuthComponent.vue";

export default {
    name: "auth-view",
    components: {AuthComponent},
    title: 'Auth'
}
</script>


<style scoped>

div {
    background-color: white;
    min-height: 82vh;
}
</style>
