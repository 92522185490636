<template>
    <div class="subscription-plan-card-container">
        <div :class="['subscription-plan-card', cardBodyExpandedClass]">
            <div class="subscription-plan-card-header">
                <div class="subscription-plan-card-header-pricing"> 
                    <div class="subscription-plan-card-header-type"> {{data.subscriptionName}} </div>
                    <text class="subscription-card-header-price">${{ data.subscriptionPrice }} </text>
                    <text class="subscription-card-header-period"> + GST / month </text>

                    
                </div>
                <!-- <div class="subscription-plan-card-header-description"> {{data.subscriptionDescription}} </div> -->
            </div>

            <q-separator style="margin-bottom: 2em;" color="grey" />


            <div class="subscription-plan-card-body">
                <q-list class="subscription-plan-card-features"> 
                    <q-item v-for="item in visibleData" :key="item.index"> 
                        <text :class="featureStatus(item[0].status)" v-if="item[0].note"> &#x2022; {{ item[0].note }}</text>
                        <text :class="featureStatus(item[0].status)" v-else> &#x2022; {{ item[0].name }} </text>
                        <q-badge class="coming-soon-badge coming-soon" v-if="item[0].status == 'Coming soon'"> Coming Soon </q-badge>
                    </q-item>
                </q-list>
            </div>

            <div class="subscription-plan-card-select">
                <div v-if="overflow" class="expand-card bounce">
                    <q-icon v-if="expanded" name="expand_less bounce" size="3em" @click="expand"/>
                    <q-icon  v-else name="expand_more bounce" size="3em" @click="expand"/>
                </div>
                <q-btn 
                :disable="disabled"
                :color="disabled ? 'grey-5': ''"
                :class="['subscription-plan-card-select-btn', selectButtonClass, !buttonExists ? 'invisible' : '']" 
                no-caps
                @click="handleSelected()"
                >
                    {{buttonText}}
                </q-btn>
            </div>
        </div>
    </div>
</template>

<script>
import take from 'lodash/take';



const SubscriptionPlanCardComponent = {
    name: "SubscriptionPlanCard",
    props: ['data', 'selected', 'buttonExists', 'disabled', 'buttonText'],

    data() {
        return {
            step: 1,
            expanded: false,
            overflow: false,
        }
    },

    mounted() {
        this.isOverflown()
    },

    created() {
        this.data.features = this.data.features.sort(this.sortFeatures)
    },

    computed: {
        visibleData() {
            return !this.expanded ? take(this.data.features, 10) : this.data.features
        },
        
        selectButtonClass() {
            return this.selected == this.data.subscriptionTierId ? 'selected' : 'unselected'
        },

        cardBodyExpandedClass() {
            return this.expanded ? 'expanded' : ''
        }
    },
    methods: {
        isOverflown() {
            this.overflow = this.visibleData.length !== this.data.features.length;
        },

        featureStatus(status) {
            if(status =='active'){
                return 'subscription-plan-card-feature active'
            } else {
                return 'subscription-plan-card-feature coming-soon'
            }
        },

        expand() {
            this.expanded = !this.expanded
            if (this.expanded) {

                // Wait for component to expand before scrolling
                setTimeout(() => {
                    document.getElementById(this.data.subscriptionTierId).scrollIntoView({
                        behavior: "smooth",
                    });
                }, 10)

                
            }
        },


        sortFeatures(feature1, feature2){
            if(feature1[0].status=='active' && feature2[0].status!='active'){
                return -1
            } else if (feature1[0].status!='active' && feature2[0].status=='active'){
                return 1
            } else {
                return 0
            }
        },

        handleSelected() {
            this.$emit("selected", this.data);
        }
    },
};

export default SubscriptionPlanCardComponent;
</script>

<style scoped>
@import "../../assets/styles/subscriptions.css";
</style>
