/**
 * API for handling all test endpoints
*/ 

import axiosInstance from "./axios-instance";


/**
 * @desc Route loads all required data from test file, throws error if data already exists
 */
const loadDB = async () => {
	return await axiosInstance.patch('api/backdoor/load').then((res) => res.data);
}

/**
 * @desc Route all the data in the db, does not delete schema
 */
const clearDB = async () => {
	return await axiosInstance.patch('api/backdoor/clear').then((res) => res.data);
}

/**
 * @desc Route Deletes Schema and creates a new schema with test account credentals and loads all required data
 */
const resetDB = async() => {
	return await axiosInstance.patch('api/backdoor/reset').then((res) => res.data);
}

export default {
	loadDB,
	clearDB,
	resetDB,
}