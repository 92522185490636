<template>
    <div class="factor-filter-container">

        <div class="factor-controls">
            <div class="button-container">
                <q-select class="dropdown" dropdown-icon="expand_more" align="between" no-caps label="Source" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedSources"
                    :options="availableSources" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allSources" @update:model-value="selectAllSources()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Emission Source" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedEmissionSources"
                    :options="availableEmissionSources" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allEmissions" @update:model-value="selectAllEmissions()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown small fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Scope" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedScopes"
                    :options="availableScopes" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allScopes" @update:model-value="selectAllScopes()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Category" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedCategories"
                    :options="availableCategories" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section>
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allCategories" @update:model-value="selectAllCategories()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-btn flat square color="amber-9" no-caps @click="resetFilters" label="Reset Filters"/>

            </div>

        </div>
    </div>
</template>

<script>

const EmissionFactorFilterComponent = {
    name: "EmissionFactorFilter",

    props: {
        availableSources: {
            type: Array,
            required: true,
        },

        availableEmissionSources: {
            type: Array,
            required: true,
        },

        availableCategories: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            allSources: false,
            selectedSources: [],

            allEmissions: false,
            selectedEmissionSources: [],

            allScopes: false,
            selectedScopes: [],
            availableScopes: [1, 2, 3],

            allCategories: false,
            selectedCategories: [],

            isResettingFilters: false
        };
    },


    watch: {
        selectedSources() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedEmissionSources() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedScopes() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedCategories() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        }
    },

    methods: {

        /**
         * @desc Generate the filter object and call the parent component method
         */
        applyFilters() {
            let filterObject = {
                'source': this.selectedSources,
                'emissionSources': this.selectedEmissionSources,
                'scopes': this.selectedScopes,
                'categories': this.selectedCategories
            }

            this.$emit("updateDisplayedEmissionFactors", filterObject);
        },


        /**
         * @desc Sets the filters to the default values.
         */
        resetFilters() {
            // Set a flag to indicate filters are being reset
            this.isResettingFilters = true;

            this.allSources = false
            this.selectedSources = []

            this.allEmissions = false
            this.selectedEmissionSources = []

            this.allScopes = false
            this.selectedScopes = []
            this.availableScopes = [1, 2, 3]

            this.allCategories = false
            this.selectedCategories = []

            this.$nextTick(() => {
                this.isResettingFilters = false;
                this.applyFilters();
            });
        },


        /**
         * @desc Sets sources to list of all available sources
         */
        selectAllSources() {
			this.allSources = !this.allSources
			
			if (this.allSources) {
				this.selectedSources = this.availableSources;
			} else {
				this.selectedSources = []
			}
		},


        /**
         * @desc Sets emissions to list of all available emissions
         */
        selectAllEmissions() {
            this.allEmissions = !this.allEmissions
			
			if (this.allEmissions) {
				this.selectedEmissionSources = this.availableEmissionSources;
			} else {
				this.selectedEmissionSources = []
			}
        },


        /**
         * @desc Sets scopes to list of all available scopes
         */
        selectAllScopes() {
            this.allScopes = !this.allScopes
			
			if (this.allScopes) {
				this.selectedScopes = this.availableScopes;
			} else {
				this.selectedScopes = []
			}
        },


        /**
         * @desc Sets categories to list of all available categories
         */
        selectAllCategories() {
            this.allCategories = !this.allCategories
			
			if (this.allCategories) {
				this.selectedCategories = this.availableCategories;
			} else {
				this.selectedCategories = []
			}
        }
    },
};

export default EmissionFactorFilterComponent;
</script>

<style lang="less" src="@/assets/styles/emissionFactorSettings.less" scoped />
