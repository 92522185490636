<template>
	<div class="commute-form-container">

		<div class="commute-form">
			<div class="commute-form-heading">
				<text>Staff Commute Survey
					<q-icon name="help" color="grey" size="sm">
						<q-tooltip>
							<text>Click here for more information</text>
						</q-tooltip>
					</q-icon>
				</text>

			</div>
			<q-form @submit="onSubmit" class="q-gutter-md">

				<q-select class="test-form" label="How do you get to work?" v-model="this.selectedCommuteType"
					:options="this.commuteTypes" :option-label="(item) => createCommuteTypeLabel(item)" dense />
				<div class="commute-distance-label">
					Please type your commute distance (km)

				</div>
				<q-input filled type="number" v-model="this.commuteDistance" label="" />

				<div>
					<div v-if="!loadSuccessMsg" class="form-input">
						<q-btn label="Submit" type="submit" color="primary" :loading="loading" />
					</div>

				</div>

			</q-form>

		</div>
	</div>
</template>

<script>

import api from "../../services/api/api";
import notify from "@/services/util/notify";

const StaffCommuteFormComponent = {
	name: 'Home',


	data() {
		return {
			loading: false,
			loadSuccess: false,
			loadFail: true,

			commuteTypes: [],
			selectedCommuteType: {},
			commuteDistance: "",
		}
	},

	created() {
		this.getStaffCommuteTypes()
	},


	methods: {

		/**
		 * @desc Retrieves a list of staff commute types available
		 */
		async getStaffCommuteTypes() {
			api.commute
				.getCommuteTypes()
				.then((res) => {
					this.commuteTypes = res.data
					this.loadSuccess = true;
					this.loadFail = false;
				})
				.catch((err) => {
					this.loadFail = true;
					this.loadSuccess = false;
					this.loadFailMsg = err.response.data;
					notify.error('Could not establish connection database', 'top');
				})
		},


		/**
		 * @desc Submits a commute form to backend endpoint 
		 */
		async onSubmit() {
			this.loading = true;
			let response = {
				commuteType: this.selectedCommuteType.id,
				commuteDistance: this.commuteDistance,
				date: new Date()

			}
			api.commute
				.postStaffCommuteSurveyResponse(response, this.$route.params.surveyHash)
				.then((res) => {
					this.loadSuccess = true;
					this.loadFail = false;
					this.loadSuccessMsg = res.data;
					this.selectedCommuteType = {};
					this.commuteDistance = "";
					notify.primary('Successfully added response to database', 'top');

				})
				.catch((err) => {
					this.loadFail = true;
					this.loadSuccess = false;
					this.loadFailMsg = err.response.data;
					notify.error('Could not upload response to database', 'top');

				})
				.finally(() => {
					this.loading = false;
				})
		},


		/**
		 * @desc Creates the label for the drop down commute types
		 */
		createCommuteTypeLabel(option) {
			if (option.subCategory) {
				if (option.size) {
					//Tests to see if string is a number value
					if (/^\d+$/.test(option.size)) {
						return (
							this.capitalise(option.transportType) +
							" - " +
							this.capitalise(option.subCategory) +
							" - " +
							option.size +
							"cc"
						);
					} else {
						return (
							this.capitalise(option.transportType) +
							" - " +
							this.capitalise(option.subCategory) +
							" - " +
							this.capitalise(option.size)
						);
					}
				} else {
					return (
						this.capitalise(option.transportType) +
						" - " +
						this.capitalise(option.subCategory)
					);
				}
			} else {
				return this.capitalise(option.transportType);
			}
		},

		/**
		 * @desc Capitalise the first character of a given string
		 * @param {String} s the string to capitalise first letter
		 * @returns {String} string with first letter capitalised
		 */
		capitalise(s) {
			if(s){
				return s[0].toUpperCase() + s.slice(1);

			}
		},
	}
}


export default StaffCommuteFormComponent;
</script>

<style scoped>
@import "../../assets/styles/commute.css";
</style>