<template>
  <div class="invoice-upload-container">
    <h2 class="tab-section-heading">Upload Invoice</h2>
    <p class="action-heading">Select the invoice you want to upload. Please make sure you have selected the site that the invoice is for</p>
    <q-select class="invoice-select-dropdown" outlined v-model="invoiceSelection" :options="invoices"/>
    <p v-if="invoiceSelection?.includes('Waste Management')"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/> Please note that rows with a Unit of Measurment (UOM) of "EA", the weight of the bin is calulated using average bin weights in Christchurch between 2021/22.</p>
    <p v-if="invoiceSelection?.includes('JJ Richards')"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/> Please note that the weight of the bins are calulated using the average bin weights in Christchurch between 2021/22.</p>
    <p v-if="this.emissionSource == 'electricity'"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/>Please note that Electricity invoices can contain multiple sites per invoice. Can you please link your ICPs to their corresponding sites using the table at the bottom of the page.</p>

    <p>Our invoice upload feature is something we are continuously improving! If you have any issues uploading your invoice, send a copy of your invoice through to support@greenhalo.nz and we will get it sorted as soon as possible. Thanks for helping us improve GreenHalo!</p>

    <span class="action-heading">Upload your invoice below</span>
    <div class="upload-manual-csv">
        <q-file class="upload-files justify-center items-center" v-model="files" type="file" ref="files" multiple max-files="10" counter :counter-label="({totalSize, filesNumber, maxFiles}) => `${filesNumber} files of ${maxFiles} | ${totalSize}`"
            label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
            :item-aligned=true>

            <template v-if="files" v-slot:prepend>
                <q-icon name="cancel" @click.stop.prevent="files = null" class="cursor-pointer" />
            </template>
        </q-file>

        <div class="manual-button-center">
            <q-btn class="file-input-button" no-caps color="green" :disabled="this.files == null" @click="uploadFile"
                label="Upload" icon-right="upload" :loading="loading" />
        </div>
    </div>

    <div v-if="flags.FLAG_4545_ICP_NUMBERS && ['stationary-fuel', 'electricity'].includes(this.emissionSource)">
      <ViewICPNumberComponent :emissionSource="this.emissionSource"></ViewICPNumberComponent>
    </div>

  </div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';
import { useSiteStateStore } from '@/stores/siteState.store';

import ViewICPNumberComponent from '../DataComponents/ViewICPNumberComponent.vue';


export default {
  components: {
    ViewICPNumberComponent
  },  

  props: ["emissionSource", "siteId"],
  data() {
    return {
      invoiceSelection: null,
      files: null,
      loading: false,
    }
  },
  created() {
    this.invoiceSelection = this.invoices[0];
  },
  watch: {
    invoices() {
      this.invoiceSelection = this.invoices[0];
      this.$emit("invoiceExists", !!this.invoiceSelection);
    }
  },
  computed: {
    invoices() {
      switch (this.emissionSource) {
        case "waste":
        if (this.flags.FLAG_4561_JJRICHARDS_INVOICE) {
            return ["Waste Management - 2023", "EnviroWaste", "JJ Richards"];
          }
          return ["Waste Management - 2023", "EnviroWaste"];
        case "air-travel":
          return ["Air New Zealand"];
        case "transport-fuel":
          return ["Allied Petroleum"];
        case "electricity":
          if (this.flags.FLAG_4525_GENESIS_INVOICE) {
            return ["Ecotricity", "Meridian", "Genesis"];
          }
          return ["Ecotricity", "Meridian"];
        case "stationary-fuel":
          return ["Novagas"];
		case "water":
			if (this.flags.FLAG_4744_CCC_INVOICE){
				return ["CCC Water", "CCC Wastewater"];
			}
			return [];
        default:
          return [];
      }
    },
    selectedSiteId() {
			return this.siteId ? this.siteId : useSiteStateStore().siteId;
		},
  },
  methods: {
    /**
     * @desc Attempt to upload the invoice.
     */
    async uploadFile() {
      this.loading = true;

      try {
        const uploadRes = await api.emissions.uploadEmissionInvoice(this.files, this.invoiceSelection, this.selectedSiteId);
        notify.primary(uploadRes.data, "top");
        this.files = null;
      } catch (error) {
        notify.withObject(error.response, "top", "Upload File");
      } finally {
        this.loading = false;
      }
    }
  }

}
</script>

<style lang="less" scoped src="@/assets/styles/invoiceUpload.less" />
