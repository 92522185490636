<template>
	<div v-if="!showSignupComponent">
		<img class="bg" />
		<div class="container">
			<span class="building-blocks"></span>

			<div class="row justify-content-center">
				<div v-if="!this.loading" class="login">
					<q-form @submit.prevent="submit">
						<div class="login-form-container">
							<h2 class="portal-title text-center">Log in to GreenHalo</h2>

							<div class="form-group">
								<q-btn
									outlined
									class="form-button-sso"
									color="white"
									no-caps
									@click="microsoftSignIn"
								>
									<img
										src="..\assets\microsoft-icon.svg"
										height="15"
										width="15"
										alt="Microsoft logo"
									/>
									&nbsp; Log in with Microsoft
								</q-btn>
							</div>

							<div class="signup-divider">
								<span>OR</span>
							</div>

							<div class="login-email">
								<span class="input-label">Email</span>
								<q-input
									maxlength="100"
									type="email"
									class="input-control"
									id="email"
									icon-right="close-circle"
									v-model="email"
									autocomplete
									lazy-rules
									outlined
									:rules="[
										(val) =>
											validEmail(val) ||
											'Please enter a valid email address',
									]"
								>
									<template v-slot:prepend>
										<q-icon name="o_email" />
									</template>
								</q-input>
							</div>
							
							<div class="login-password">
								<div style="display: flex; justify-content: space-between;">
									<span class="input-label">Password</span>
									<router-link v-if="flags?.FLAG_4437_FORGOT_PASS" to="/auth/forgot-password" tabindex="-1" style="padding-block: 5px;">Forgot Password?</router-link>
								</div>

								<q-input
									type="password"
									class="input-control"
									id="MyPassword"
									autocomplete
									v-model="password"
									outlined
									:rules="[
										(val) =>
											!!val || 'Password is required',
									]"
								>
									<template v-slot:prepend>
										<q-icon name="o_lock" />
									</template>
								</q-input>
							</div>

							<div class="form-group">
								<q-btn
									:disabled="!this.valid"
									class="form-button"
									color="secondary"
									id="button-login"
									type="submit"
									label="Login"
									no-caps
								/>
							</div>

							<div class="form-group-button">
								<span>Don't have an account? <router-link to="/auth/signup" tabindex="-1" style="padding-block: 5px;">Sign Up</router-link></span>
								<span v-if="flags.FLAG_4872_SUPPLIER_PORTAL">Have a supplier account? <router-link to="/auth/supplier/login" tabindex="-1" style="padding-block: 5px;">Login</router-link></span>
							</div>
						</div>
					</q-form>
				</div>

				<LoadingComponent v-else-if="this.loading" />
			</div>
		</div>
	</div>

</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { PublicClientApplication } from "@azure/msal-browser";
import { patterns } from "quasar";
import SignupComponent from "./SignupComponent.vue";
import LoadingComponent from "./LoadingComponent.vue";

const LoginComponent = {
	name: "Login",
	components: {SignupComponent, LoadingComponent},

	data() {
		return {
			error: null,
			email: "",
			password: "",
			isLogged: false,
			account: undefined,
			loading: false,
			user: "",
			valid: false,
			showSignupComponent: false,
		};
	},

	/**
	 * @desc Watch the email for actions like autofill from the browser and validates it.
	 */
	watch: {
		email(val) {
			this.validEmail(val);
		},
	},

	/**
	 * @desc Clear the localStorage (logs out the user) and set isLogged to false.
	 */
	async created() {
		this.loadMSALInstance();

		this.isLogged = false;
		localStorage.clear();
		sessionStorage.clear();

	},

	methods: {

		/**
		 * Load Microsoft active login instance
		 */
		loadMSALInstance() {
			this.$msalInstance = new PublicClientApplication(
				this.$store.state.msalConfig 
			);
		},

		/**
		 * @desc Checks that the email provided is an email and changes property "valid" which is used by the login button
		 * @param {String} val email to check
		 * @returns {Boolean} true if email is valid
		 */
		validEmail(val) {
			if (patterns.testPattern.email(val)) {
				this.valid = true;
				return true;
			} else {
				this.valid = false;
			}
		},

		submit() {
			this.loginUser();
			this.loading = true;
		},

		/**
		 * @desc Redirct on succesful login attempt
		 */
		loginSuccess() {
			this.$router.push({ name: "home" });
		},

		/**
		 * @desc Emits a change component event
		 */
		changeComponent() {
			this.$emit("nextComponent", "SignupComponent");
		},

		/**
		 * @desc Authorizes and account using Microsoft Single Sign on option. Redirects use to homepage if successful
		 */
		async microsoftSignIn() {
			const loginReq = {
				scopes: [
					"api://b3d9a653-9c14-481b-a862-bcf317743bfb/GreenHalo.Read",
					"api://b3d9a653-9c14-481b-a862-bcf317743bfb/GreenHalo.Write",
				],
			};
			await this.$msalInstance
				.loginPopup(loginReq)
				.then((account) => {
					this.loading = true;

					const myAccounts = this.$msalInstance.getAllAccounts();

					this.account = myAccounts[0];

					// Attempt SSO sign in with encoded token.
					api.users
						.loginUserWithSSO({
							Authorization: "Bearer " + account.accessToken,
						})
						.then((res) => {
							localStorage.setItem(
								"authToken",
								res.data.authToken
							);
							this.loginSuccess();
						})
						.catch((err) => {
							this.loading = false;

							//when it can't make a request to the server err.response.data is undefined
							notify.error(
								err?.response?.data ?? "Error during authentication.",
								"top"
							);
						});
				})
				.catch(() => {
					this.loading = false;
					notify.error(
						"Error during Microsoft authentication",
						"top"
					);
				});
		},

		/**
		 * @desc Attempts to log in a user. Redirects use to homepage if successful
		 */
		loginUser() {
			this.loading = true;

			let payload = {
				email: this.email.toLowerCase(),
				password: this.password,
			};

			api.users
				.loginUserWithCredentials(payload)
				.then((res) => {
					localStorage.setItem("authToken", res.data.authToken);
					this.loginSuccess();
				})
				.catch((err) => {
					console.error(err);
					this.loading = false;
					notify.error(err?.response?.data ?? "Login failed", "top", "Login Failed");
				});
		},
	},
};

export default LoginComponent;
</script>

<style scoped>
@import "../assets/styles/login.css";
</style>
