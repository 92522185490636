<template>
  <div class="card-container">
    <q-card class="card" v-for="card in data" :key="card.label">
      <div v-if="!loading" class="main-content">
        <q-card-section class="card-section">
          <div class="card-label">{{ card.label }}</div>
          <div>
            <span class="card-value">{{ card.value }}</span>
            <span v-if="card.unit" class="card-unit">{{ card.unit }}</span>
          </div>
        </q-card-section>
        <q-card-section class="slot-section">
          <q-icon v-if="icon" class="card-icon" :name="icon" size="2em" />
          <div>
            <slot></slot>
          </div>
        </q-card-section>
      </div>
      <div v-else class="loading-container">
        <q-spinner class="spinner" color="primary" size="3em" />
      </div>
    </q-card>
  </div>
</template>

<script>
  export default {
    props: ["data", "icon", "loading"]
  }
</script>

<style lang="less" src="@/assets/styles/card.less" scoped />