/* eslint-disable */

/**
 * @desc This method accepts an object of organisations that details each of their emission sources per month.
 * It will parse the emissionData object and grab only data accoridng to the organisations, dateRange and emissionSource values
 * 
 * example of object:
    {
        "organisation 1": {
            "2023-01": {
                "Electricity": {
                    "carbon": 405,
                    "amount": 521,
                },
            },
            "2023-02": {
                "Electricity": {
                    "carbon": 496,
                    "amount": 653,
                },
            },
        },
        "organisation 2": {
            "2023-01": {
                "Electricity": {
                    "carbon": 204,
                    "amount": 393,
                },
            },
            "2023-02": {
                "Electricity": {
                    "carbon": 35,
                    "amount": 120,
                },
            },
        },
    }
 * @param {Object} emissionData All carbon emissions per organisation, grouped by month, then grouped by emission source.
 * @param {String} siteName Name of the site to get data for
 * @param {String} emissionSource Name of emission source to get data of
 * @param {dateRange} dateRange Range of dates to get data for. should contain startDate and endDate.
 * 
 * @returns {Object} chart data
 */
exports.getComparisonChart = (emissionData, organisations, dateRange, emissionSource) => {
    let labels = getDateLabelsObject(dateRange.startDate, dateRange.endDate);
    let chartData = {
        labels: Object.keys(labels),
        datasets: []
    };

    // Calculate and add chart data for each organisation to chartData.datasets
    for (let i = 0; i < organisations.length; i++) {
        let organisation = organisations[i];
        let dateLabels = getDateLabelsObject(dateRange.startDate, dateRange.endDate);
        let organisationData = emissionData[organisation];
        for (let month of Object.keys(organisationData)) {
            // If current timestamp is within the dateRange params, continue adding data
            if (dateLabels[month] != undefined) {
                if (emissionSource == "Total") {
                    for (let source of Object.keys(organisationData[month])) {
                        dateLabels[month] += organisationData[month][source]["carbon"];
                    }
                }else{
                    dateLabels[month] += organisationData?.[month]?.[emissionSource]?.["carbon"] ?? 0;
                }
            }
        }
        chartData.datasets.push({
            label: organisation,
            data: Object.values(dateLabels),
            borderColor: borderColors[i],
            backgroundColor: borderColors[i]
        })
    }
    return chartData;
};

/**
 * @desc Generates an object of monthly labels from a start date to an end date, with values of 0.
 * e.g. from 2023-01-01 to 2023-03-01 will return {"2022-01": 0, "2022-02": 0, "2022-03": 3,}
 * @param {Date} startDate date to start object from
 * @param {Date} endDate date to end object on
 * @returns {Object} monthly labels with values of 0
 */
const getDateLabelsObject = function (startDate, endDate) {
    let labels = {};
    while (startDate < endDate) {
        // Need to pad month value with 0 as postgres forces yyyy-mm month values to be padded with 0s
        let label = startDate.getFullYear() + '-' + ('0' + (startDate.getMonth() + 1)).slice(-2);
        labels[label] = 0;
        // increment start date by one month
        startDate = new Date(startDate.getFullYear(), (startDate.getMonth() + 1));
    }
    return labels;
};


/**
 * @desc This method accepts an object of organisations that details each of their emission sources per month.
 * It will parse the emissionData object and grab only data according to the organisations, dateRange and emissionSource values
 * It will return an object that is required for the generateYearOnYearChartData method.
 * 
 * example of object:
    {
        "organisation 1": {
            "2023-01": {
                "Electricity": {
                    "carbon": 405,
                    "amount": 521,
                },
            },
            "2023-02": {
                "Electricity": {
                    "carbon": 496,
                    "amount": 653,
                },
            },
        },
        "organisation 2": {
            "2023-01": {
                "Electricity": {
                    "carbon": 204,
                    "amount": 393,
                },
            },
            "2023-02": {
                "Electricity": {
                    "carbon": 35,
                    "amount": 120,
                },
            },
        },
    }
 * @param {Object} emissionData All carbon emissions per organisation, grouped by month, then grouped by emission source.
 * @param {String} siteName Name of the site to get data for
 * @param {String} emissionSource Name of emission source to get data of
 * @param {dateRange} dateRange Range of dates to get data for. should contain startDate and endDate.
 * 
 * @returns {Object} chart data
 */
exports.getYearOnYearFormattedChartData = (emissionData, dateRange, emissionSource) => {
    var dateList1 = getDateLabelsObject(dateRange.startDate, dateRange.middleDate);
    var dateList2 = getDateLabelsObject(new Date(dateRange.middleDate.getFullYear(), dateRange.middleDate.getMonth() + 2), dateRange.endDate);

    var formattedEmissionData = {
        "24 - 12 Months Ago" : dateList1,
        "12 - 0 Months Ago": dateList2
    }

    for (var organisation in emissionData) {
        for (var month in emissionData[organisation]) {
            if (emissionSource in emissionData[organisation][month]) {

                let value = emissionData[organisation][month][emissionSource]['carbon'];
                if (formattedEmissionData["24 - 12 Months Ago"].hasOwnProperty(month)) {
                    formattedEmissionData["24 - 12 Months Ago"][month] += value
                } else if (formattedEmissionData["12 - 0 Months Ago"].hasOwnProperty(month)) {
                    formattedEmissionData["12 - 0 Months Ago"][month] += value
                }
            }
        }
    }

    return formattedEmissionData
}

/**
 * colours for charts :-)
 */
const borderColors = [
    'rgb(89,153,67)',
    'rgb(139,183,52)',
    'rgb(193,205,70)',
    'rgb(96,102,35) ',
    'rgb(219, 103, 94) ',
    'rgb(66, 102, 3) ',
    'rgb(158, 117, 155) ',
    'rgb(81, 58, 199) ',
];