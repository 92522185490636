<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <StaffCommuteForm/>
    </div>
</template>

<script>
import StaffCommuteForm from "@/components/CommuteComponents/StaffCommuteFormComponent.vue";

import { sidebarWidth } from '@/components/SidebarComponents/state'
// import {  onBeforeRouteUpdate } from 'vue-router';
import api from "@/services/api/api";

export default {
    name: "staff-commute-form-view",
    components: { StaffCommuteForm },
    title: 'staff-commute-form',
    setup() {

	// checks the surveyHash param matches the latest survey from database
    // same as beforeRouteUpdate option with no access to `this`
    // onBeforeRouteUpdate(async (to) => {
	// 	const lastestSurvey = await api.commute.getLastestCommuteSurvey()
	// 	if (lastestSurvey.data.surveyHash != to.params.surveyHash){
	// 		return false
	// 	}
    // })


	return { sidebarWidth }

	},
		//checks the surveyHash param matches the latest survey from database
		async beforeRouteEnter(to,from, next) {

		const surveyList = await api.commute.getStaffCommuteSurveys();
		const surveyHashList = surveyList.data.map(survey => {
			return survey.surveyHash
		}
			);


			
		const hasResponded = await api.commute.hasResponded(to.params.surveyHash);
		if (!surveyHashList.includes(to.params.surveyHash)){
			return next('/')
		} else if (hasResponded.data.exists){
			alert("you have already submitted a response")
			return next('/')
		} 
		else {
			return next()
		}
	}

}
</script>
