/**
 * unit.js
 *
 * API for handling getting units
 */
import axiosInstance from './axios-instance'

/**
 * @desc Get list of units available within GH
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUnits = async () => {
    return await axiosInstance.get(`/api/units`).then(res => res);
};


/**
 * @desc Get list of emission factors with their available units within GH
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionFactorsWithUnits = async () => {
    return await axiosInstance.get(`/api/units/emission-factors/all`).then(res => res);
};


/**
 * @desc Get list of emission factors with their default unit within GH
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionFactorsWithDefaultUnit = async () => {
    return await axiosInstance.get(`/api/units/emission-factors/default`).then(res => res);
};

const getConvertableUnits = async (unit) => {
    return await axiosInstance.get(`/api/units/conversions`, {params: {compatibleUnits: unit}}).then(res => res);
};

export default {
    getUnits,
    getEmissionFactorsWithUnits,
    getEmissionFactorsWithDefaultUnit,
	getConvertableUnits 
};
