<template>
  <q-dialog class="side-modal-dialog" position="right">
    <q-card :style="{ width: width ?? '35vw', 'max-width': width ?? '35vw' }">

      <q-btn class="close-btn" flat round dense icon="close" v-close-popup color="red" size="1.25em"/>
      <q-card-section class="card-content">
        <slot ></slot>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "SideModal",
  props: ["width"],
}
</script>

<style lang="less" src="../assets/styles/sideModal.less" />