// util/notify.js
import Notify from 'quasar/src/plugins/notify/Notify.js';;

/**
 * Checks if the message is an object and returns a default message if it is
 * @param {Object} message the intended message to check
 * @returns {String} the message to display
 */
const formatMessage = (message) => { 
    let msg = message;
    if (typeof message === 'object') {
        msg = "An unexpected error occured. Please try again later"
    }
    return msg;
}

/**
 * @desc Creates an error notification with the given message and title
 * 
 * @param {String} message The message to display
 * @param {String} position The position of the notification
 * @param {String} title The title of the notification
 */
const error = (message, position="top", title="Error") => {
    

    const msg = formatMessage(message);

    Notify.create({
        message: title,
        caption: msg,
        icon: 'error_outline',
        color: 'red',
        position, 
        classes: 'notify',
        actions: [
        { icon: 'close', color: 'black', round: true},
        ],
    });
};


/**
 * @desc Creates an success notification with the given message and title
 * 
 * @param {String} message The message to display
 * @param {String} position The position of the notification
 * @param {String} title The title of the notification
 */
const primary = (message, position="top", title="Success") => {


    const msg = formatMessage(message);

    Notify.create({
        message: title,
        caption: msg,
        icon: 'task_alt',
        color: 'secondary',
        position,
        classes: 'notify',
        actions: [
            { icon: 'close', color: 'black', round: true},
        ],
    });
};




/**
 * @desc Creates an loading notification with the given message and title
 * 
 * @param {String} message The message to display
 * @param {String} position The position of the notification
 * @param {String} title The title of the notification
 */
const warning = (message, position="top", title="Alert") => {


    const msg = formatMessage(message);

    return Notify.create({
        message: title,
        caption: msg,
        icon: 'warning',
        color: 'amber-6',
        position,
        classes: 'notify',
        actions: [
            { icon: 'close', color: 'black', round: true},
        ],
    });
}

const inform = (message, position="top", title="") => {
	const msg = formatMessage(message);

    return Notify.create({
        message: title,
        caption: msg,
		icon: 'warning',
        color: 'light-blue-8',
        position,
        classes: 'notify',
		timeout: 30000,
        actions: [
            { icon: 'close', color: 'black', round: true},
        ],
    });
};


/**
 * @desc Creates an loading notification with the given message and title
 * 
 * @param {String} message The message to display
 * @param {String} position The position of the notification
 * @param {String} title The title of the notification
 */
const loading = (message, position="top", title="Loading") => {

    const msg = formatMessage(message);

    return Notify.create({
        message: title,
        caption: msg,
        spinner: true,
        color: 'grey',
        position,
        classes: 'notify',
        timeout: 0,
    });
}

/**
 * @desc Creates a notification dynamically based on the status.
 * 
 * @param {Object} errorObject An object containing the status and data fields:
 * {
 *   status: 400,
 *   data: "Bad request"
 * }
 * @param {String} position (optional) The position of the notification
 * @param {String} title (optional) The title of the notification
 * @returns 
 */
const withObject = (object, position="top", title=undefined) => {
    if(!object){
        return error(`Sorry, an unknown error has occured`, position="top", title="Error");
    }
    let status = object.status, data= object.data;
    // Conditions for the response status class
    switch (Math.floor(status / 100)) {
        case 5:
            return error(data, position, title);
        case 4:
            return warning(data, position, title);
        case 2:
            return primary(data, position, title);
    }
}

export default {
    error,
    primary,
    warning,
    loading,
    withObject,
	inform,
};
