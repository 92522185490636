/* 
	Exports an object that contains mappings between airline IATA designation codes
	and actual arline names, e.g. "NZ" == "Air New Zealand"
*/

exports.airlineCodes = {
	"NZ": "Air New Zealand",
	"JQ": "Jetstar",
	"QF": "Qantas Airways",
	"GZ": "Air Rarotonga"
};

/*
	Contains mappings between know iata aircraft codes and names of carbon rate converters in our postgres db
*/
exports.aircraftCodeCarbonRates = {
	"320": "airtravel744",
	"744": "airtravel744",
	"32A": "airtravel744",
	"AT7": "airtravel744",
	"789" :"airtravel744",
	"777" :"airtravel744",
	"747" :"airtravel744",
	"A320" :"airtravel744",
	"734": "airtravel734"
};

/*
	Exports an object that contains mappings between airport iata codes and
	their latitude/longitude
 */
exports.airportCoords = {
	// NZ airport codes
	"AKL": {
		latitude: -37.0082,
		longitude: 174.7850,
	},
	"CHC": {
		latitude: -43.4876,
		longitude: 172.5374,
	},
	"WLG": {
		latitude: -41.3276,
		longitude: 174.8076,
	},
	"ZQN": {
		latitude: -45.0198,
		longitude: 168.7454,
	},
	"TRG": {
		latitude: -37.6725,
		longitude: 176.1982,
	},
	"DUD": {
		latitude: -45.9259,
		longitude: 170.2022,
	},
	"NSN": {
		latitude: -41.2992,
		longitude: 173.2254,
	},
	// Australian airport coords
	"SYD": {
		latitude: -33.9500,
		longitude: 151.1819,
	},
	"MEL": {
		latitude: -37.6709,
		longitude: 144.8433,
	},
	"BNE": {
		latitude: -27.3942,
		longitude: 153.1218,
	},
	"OOL": {
		latitude: -28.1658,
		longitude: 153.5081,
	},
	"YBCG": {
		latitude: -28.1658,
		longitude: 153.5081,
	},
	"PER": {
		latitude: -31.9385,
		longitude: 115.9672,
	},
};