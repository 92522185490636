<template>
  <div>
    <div>
      <h2 class="tab-section-heading">Invite Supplier</h2>
      <q-form class="invite-supplier-form" @submit="inviteSupplier()">
        <div class="invite-supplier-inputs">
          <q-input 
            class="invite-supplier-input"
            type="email"
            v-model="email"
            autocomplete
            outlined
            :rules="['email']"
            label="Email"
            error-message="Please enter a valid email address"
            required
            maxlength="100"
          >
            <template v-slot:prepend>
              <q-icon name="o_email" />
            </template>
          </q-input>
          <q-select
            class="invite-supplier-input"
            v-model="supplier"
            :options="supplierOptions"
            label="Supplier"
            outlined
            :loading="loadingSupplierSelection"
            option-label="name"
            hide-selected
            fill-input
            use-input
            :rules="[(supplier) => typeof supplier?.name === 'string' && supplier.name?.trim() !== '' || 'Please enter a supplier', (supplier) => supplier?.name?.trim().length <= 50 || 'Character limit exceeded']"
            required
            @filter="filterSuppliers"
            @input-value="(val) => supplier = { id: null, uuid: null, name: val.trim() }"
            input-debounce="0"
          />
        </div>
        <q-btn no-caps label="Invite" color="primary" style="width: 8em; height: 2em;" type="submit" :loading="loadingSupplierInvite" />
      </q-form>
    </div>

    <div style="margin-top: 3.5rem;">
      <h2 class="tab-section-heading">Connected Suppliers</h2>
      <q-table
        class="users-role-table"
        :loading="loadingConnectedSuppliers"
        :rows="connectedSuppliers"
        :columns="connectedSupplierColumns"
        row-key="email"
      >
        <template v-slot:body-cell-action="props">
            <q-td :props="props">
                <q-btn
                :ref="'remove-btn-' + props.row.id"
                no-caps
                color="primary"
                flat
                @click="removeSupplier(props.row.id, props.row.email)"
                >Remove</q-btn> 
            </q-td>
        </template>
    </q-table>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

export default {
  data() {
    return {
      email: "",
      suppliers: [],
      supplierOptions: [],
      supplier: null,
      connectedSuppliers: [],
      connectedSupplierColumns: [
        {
          name: "email",
          label: "User Email",
          field: "email",
          required: true,
          align: "left",
          sortable: true,
          style: "font-weight: bold;"
        },
        {
          name: "supplier",
          label: "Supplier",
          field: "supplier",
          required: true,
          align: "left",
          sortable: true,
          style: "font-weight: bold;"
        },
        {
          name: "action",
          label: "Action",
          field: "action",
          style: "width: 1%",
          align: "center"
        }
      ],
      loadingSupplierSelection: false,
      loadingSupplierInvite: false,
      loadingConnectedSuppliers: false,
    }
  },
  created() {
    this.getSuppliers();
    this.getConnectedSuppliers();
    this.supplierOptions = [...this.suppliers];
  },
  methods: {
    filterSuppliers(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.supplierOptions = this.suppliers.filter((supplier) => supplier.name.toLowerCase().includes(needle));
      });
    },
    async getSuppliers() {
      this.loadingSupplierSelection = true;

      try {
        this.suppliers = (await api.supplier.getSuppliers()).data;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSupplierSelection = false;
      }
    },
    async getConnectedSuppliers() {
      this.loadingConnectedSuppliers = true;
      
      try {
        this.connectedSuppliers = (await api.users.getConnectedSuppliers()).data;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingConnectedSuppliers = false;
      }
    },
    async inviteSupplier() {
      this.loadingSupplierInvite = true;
      
      try {
        let supplierId = this.supplier.id;

        if (!(this.supplier.id && this.supplier.uuid)) {
          supplierId = (await api.supplier.createNewSupplier(this.supplier.name)).data;
        }

        await api.users.addSupplier(this.email, supplierId);
        await this.getConnectedSuppliers();
        await this.getSuppliers();
        notify.primary(`Successfully invited ${this.email} from ${this.supplier.name}`);
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSupplierInvite = false;
      }
    },

    /**
     * @desc Removes a supplier user with the given supplierId
     *  
     * @param {Number} supplierId the id of the supplier to remove
     * @param {String} supplierEmail the email of the supplier for notifcation
     */
    async removeSupplier(supplierId, supplierEmail) {
      try {
        await api.users.removeConnectedSupplier(supplierId);
        await this.getConnectedSuppliers();
        notify.primary(`Successfully removed ${supplierEmail}`, 'top', "Supplier Removed");
      } catch (err) {
        notify.withObject(err.response);
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.invite-supplier-form {
  margin-block: 1.25rem;

  .invite-supplier-inputs {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    .invite-supplier-input {
      border-color: var(--purple-colour);
      width: 30rem;
    }
  }
}
</style>