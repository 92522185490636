<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <CreateCustomDataset/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import CreateCustomDataset from "@/components/DataComponents/CustomDatasetComponents/CreateCustomDatasetComponent.vue";

export default {
    name: "create-custom-dataset-view",
    components: { CreateCustomDataset },
    title: 'create-custom-dataset',
    setup() {
        return { sidebarWidth }
    }
};
</script>
