<template>
  <div class="container">
    <div class="signup-container">
      <q-stepper
        v-model="step"
        ref="stepper"
        :header-class="'signup-stepper'"
        header-nav
        animated
        :transition-prev="'slide-down'"
        :transition-next="'slide-up'"
        keep-alive
        flat
        alternative-labels
        done-color="green"
        active-color="purple"
        inactive-color="stepper"
        error-color="red"
      >
        <q-step
          :name="1"
          title="Company details"
          :done="store.sectionsComplete[1]"
          :error="step > 1 && !store.sectionsComplete[1]"
          :icon="'none'"
          :active-icon="'circle'"
          :error-icon="'circle'"
        >
          <CompanyDetails
            ref="CompanyDetails"
            @next="nextStep"
            @prev="previousStep"
          />
        </q-step>

        <q-step
          :name="2"
          title="Company needs"
          :done="store.sectionsComplete[2]"
          :error="step > 2 && !store.sectionsComplete[2]"
          :icon="'none'"
          :active-icon="'circle'"
          :error-icon="'circle'"
        >
          <CompanyNeeds @next="nextStep" @prev="previousStep" />
        </q-step>

        <q-step
          :name="3"
          title="Select a plan"
          :done="store.sectionsComplete[3]"
          :error="step > 3 && !store.sectionsComplete[3]"
          :icon="'none'"
          :active-icon="'circle'"
          :error-icon="'circle'"
        >
          <PlanSelection @next="nextStep" @prev="previousStep" />
        </q-step>

        <q-step
          :name="4"
          title="Payment"
          :done="store.sectionsComplete[4]"
          :error="step > 4 && !store.sectionsComplete[4]"
          :icon="'none'"
          :active-icon="'circle'"
          :error-icon="'circle'"
        >
          <StripeComponent v-if="showStripe" @payNow="signupNewUser" />
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation>
            <div class="btns-container">
              <q-btn
                v-if="step < 4"
                class="next-btn"
                color="primary"
                @click="nextStep"
                label="Next"
                no-caps
              />
              <q-btn
                class="back-btn bg-back-btn-color"
                flat
                color="primary"
                @click="previousStep"
                label="Back"
              />
            </div>
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </div>
  </div>
</template>
  
<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import CompanyDetails from "@/components/IntroComponents/CompanyDetailsComponent.vue";
import CompanyNeeds from "@/components/IntroComponents/CompanyNeedsComponent.vue";
import PlanSelection from "@/components/IntroComponents/PlanSelectionComponent.vue";
import SignupOnboarding from "@/components/IntroComponents/SignupOnboardingComponent.vue";
import StripeComponent from "@/components/IntroComponents/StripeCompositionComponent.vue";
import { PublicClientApplication } from "@azure/msal-browser";
import { useSignupStore } from "../../stores/signup.store";

import bcrypt from "bcryptjs";

const SignupParentComponent = {
  name: "SignupParent",
  props: ["subscriptionTier"],
  components: {
    CompanyDetails,
    CompanyNeeds,
    PlanSelection,
    SignupOnboarding,
    StripeComponent,
  },

  data() {
    return {
      bcrypt: bcrypt,
      store: useSignupStore(),
      step: 1,
      signupObject: {},
      loading: false,
      stripePayLoading: false,
      showStripe: true,

      /* Stripe related vars */
      stripeKey: "",
      stripe: undefined,
      stripeLoading: false,
      hash: "",

      token: null,
      cardNumber: null,
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardCvc: null,
    };
  },

  /**
   * Clear the localStorage
   */
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
    this.store.clearState();
    this.store.initSubscriptionData();

    if (process.env.VUE_APP_ENV_MODE == "PROD") {
      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN;
    } else {
      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN_TEST;
    }

    this.stripe = require("stripe")(this.stripeKey);
  },

  computed: {
    company() {
      return this.store.company;
    },

    addressString() {
      let address = `${this.store.company.address.number}${
        this.store.company.address.unit ?? ""
      } ${this.store.company.address.street}`;

      if (this.store.company.address.suburb) {
        address += `, ${this.store.company.address.suburb}`;
      }

      if (this.store.company.address.city) {
        address += `, ${this.store.company.address.city}`;
      }

      if (this.store.company.address.postcode) {
        address += `, ${this.store.company.address.postcode}`;
      }

      if (this.store.company.address.country) {
        address += `, ${this.store.company.address.country}`;
      }

      return address;
    },

    user() {
      return this.store.user;
    },

    stripeSuccess() {
      return this.store.successHash;
    },
  },

  watch: {
    stripeSuccess() {
      bcrypt
        .compare(process.env.VUE_APP_STRIPE_SECRET, this.stripeSuccess)
        .then((res) => {
          if (res) {
            this.signupNewUser();
          }
        });
    },

    async step() {
      this.store.sectionsComplete[1] =
        await this.$refs.CompanyDetails.$refs.signupDetailForm.validate();
      if (this.step == 2) {
        this.store.sectionsComplete[2] = true;
      }
    },
  },
  methods: {
    /**
     * @desc Navigate to the next step. If on the last step and all the section are completed, redirect the user to the Stripe checkout component.
     */
    async nextStep() {
      if (this.step == 4 && !this.company.emissions) {
        notify.error("Please complete all sections", "top");
      } else {
        this.$refs.stepper.next();
      }
    },

    /**
     * @desc Go to the previous step. If on the first step, redirect the user to the signup/login component.
     */
    previousStep() {
      if (this.step == 1) {
        this.$router.push({ path: "/auth/signup" });
      }
      this.$refs.stepper.previous();
    },

    //Signup Methods
    async signupNewUser() {
      try {
        if (this.store.signupType == "credentials") {
          await this.credentialSignUp();
        } else {
          await this.microsoftSignUp();
        }
      } catch (error) {
        notify.error(error);
      }
    },

    /**
     * @desc Signup a user using Microsoft SSO service
     */
    async microsoftSignUp() {
      this.ssoSignupLoading = true;
      const signupObject = {
        authorization: this.user.authorization,
        companyName: this.company.companyName,
        companyAddress: this.addressString,
        companySubscribed: false,
        subscriptionTierId: this.company.subscription,
        usages: this.company.usages,
        industry: this.company.primaryIndustry,
        cardToken: this.company.token.token,
        couponId: this.company?.coupon?.id ?? "",
        newsletter: this.company.newsletter,
        financialYearEnd: this.company.financialYearEnd,
      };
      await api.users
        .signupWithSSO(signupObject)
        .then(() => {
          this.ssoSignupLoading = false;
        })
        .catch((error) => {
          this.ssoSignupLoading = false;
          notify.error(error.response.data, "top");
        });

      // Attempt SSO sign in with encoded token.
      api.users
        .loginUserWithSSO({
          Authorization: this.user.authorization,
        })
        .then((res) => {
          localStorage.setItem("authToken", res.data.authToken);
          this.loginSuccess();
        })
        .catch((err) => {
          this.loading = false;

          //when it can't make a request to the server err.response.data is undefined
          notify.error(err.response.data ?? err.message, "top");
        });
    },

    /**
     * @desc Attempts to signup using the credential method.
     */
    async credentialSignUp() {
      this.credentialSignupLoading = true;
      
      const signupObject = {
        email: this.user.email.toLowerCase(),
        password: this.user.password,
        companyName: this.company.companyName,
        companyAddress: this.addressString,
        companySubscribed: false,
        subscriptionTierId: this.company.subscription,
        usages: this.company.usages,
        industry: this.company.primaryIndustry,
        cardToken: this.company.token.token,
        couponId: this.company?.coupon?.id ?? "",
        newsletter: this.company.newsletter,
        financialYearEnd: this.company.financialYearEndDate,
      };

      await api.users
        .signupWithCredentials(signupObject)
        .then(async () => {
          this.credentialSignupLoading = false;

          this.loginUser();
        })
        .catch((error) => {
          this.credentialSignupLoading = false;
          this.store.stripePayLoading = false;
          notify.error(error.response?.data ?? "", "top");
        });
    },

    /**
     * @desc Attempts to log in a user. Redirects use to homepage if successful
     */
    loginUser() {
      this.loading = true;

      let payload = {
        email: this.user.email.toLowerCase(),
        password: this.user.password,
      };

      api.users
        .loginUserWithCredentials(payload)
        .then((res) => {
          localStorage.setItem("authToken", res.data.authToken);
          this.loginSuccess();
        })
        .catch((err) => {
          this.loading = false;
          this.store.stripePayLoading = false;

          notify.error(err.response.data ?? err.message, "top");
        });
    },

    /**
     * @desc Redirct on succesful login attempt
     */
    loginSuccess() {
      this.store.stripePayLoading = false;

      this.store.clearUserState();

      this.loading = false;
      this.stripe = false;
      this.showStripe = false;

      this.$router.push({ name: "home" });
    },
  },
};

export default SignupParentComponent;
</script>
  
<style scoped>
@import "../../assets/styles/signup.css";
</style>
  