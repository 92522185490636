/**
 * oid.js
 * 
 * API for handling getting all organisation intensity denominator associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Gets all OIDs
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getOIDs = async function () {
  return await axiosInstance.get("/app/organisation-intensity-denominators").then(res => res);
};

/**
 * @desc Gets all OID rates with UUID
 * 
 * @param {String} uuid 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getOIDRatesByUUID = async function(uuid) {
  return await axiosInstance.get(`/app/organisation-intensity-denominators/${uuid}/rates`).then(res => res);
};

/**
 * @desc Creates an OID
 * 
 * @param {String} uuid 
 * @param {*} startDate 
 * @param {*} endDate 
 * @param {*} value 
 * @returns {Promise<AxiosResponse<any>>}
 */
const createOIDRate = async function(uuid, startDate, endDate, value) {
  return await axiosInstance.post("/api/organisation-intensity-denominators/rate", { uuid, startDate, endDate, value }).then(res => res);
}

/**
 * @desc Deletes an OID rate given an ID
 * 
 * @param {Number} id 
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteOIDRateByID = async function(id) {
  return await axiosInstance.delete(`/api/organisation-intensity-denominators/rate/${id}`).then(res => res);
};

/**
 * @desc Deletes an OID with UUID
 * 
 * @param {String} uuid 
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteOID = async function(uuid) {
  return await axiosInstance.delete(`/api/organisation-intensity-denominators/${uuid}`).then(res => res);
}

/**
 * @desc Posts a new organisation intensity denominator to tenancy
 * 
 * @param {Object} payload the OID data to post
 *      {
 *          name: "Puff Puff",
 *          description: "Then I got High",
 *      }
 * @returns {Promise<AxiosResponse<any>>}
 */
const postOID = async function(payload) {
    return await axiosInstance.post(`/api/organisation-intensity-denominators`, payload).then(res => res);
};

/**
 * @desc Edits an existing organisation intensisty denominator
 * 
 * @param {Object} payload the OID data to edit
 *      {
 *          name: "New name change"
 *      }
 */
const editOID = async function(uuid, payload) {
  return await axiosInstance.patch(`/api/organisation-intensity-denominators/${uuid}`, payload).then(res => res);
}


export default {
  getOIDs,
  getOIDRatesByUUID,
  createOIDRate,
  deleteOIDRateByID,
  deleteOID,
  postOID,
  editOID
}
