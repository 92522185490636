<template>
  <div class="signup-container">
    <h1 class="main-heading">Tell us more about your needs</h1>
    <h2 class="details-heading">Select the emissions you would like to track. You can always add more later</h2>
    <div class="details-container emissions">
      <q-option-group class="emission" :options="options" type="checkbox" v-model="group" left-label="" />
    </div>
  </div>
</template>

<script>
import { useSignupStore } from "@/stores/signup.store";

export default {
  name: "SignupOnboarding",
  data() {
    return {
      group: [],
      options: [
        { label: "Electricity", value: "electricity"},
        { label: "Transport Fuels", value: "transportFuels"},
        { label: "Stationary Fuels", value: "stationaryFuels"},
        { label: "Water", value: "water"},
        { label: "Freight", value: "freight"},
        { label: "Air Travel", value: "airTravel"},
        { label: "Waste", value: "waste"},
        { label: "Fertiliser", value: "fertiliser"},
        { label: "Livestock", value: "livestock"},

      ],
      store: useSignupStore(),
    }
  },
  watch: {
    group(){
      this.store.company.emissions = this.group
      if (this.group.length > 0) {
        this.store.sectionsComplete[4]=true;
      } else {
        this.store.sectionsComplete[4]=false;

      }
    }
  }
}
</script>

<style lang="less">
@import "../../assets/styles/signup.less";
</style>