import api from "../api/api";

let customMapping = null;
let emissionsMapping = null;

/**
 * 
 * @returns {Object} a mapping of custom dataset names to its table name
 */
const getCustomMapping = () => {
  if (!customMapping) {
    customMapping = api.customDatasets.getCustomDatasets()
      .then((result) => {
        const tableNameMapping = {};

        for (const customDataset of result.data) {
          tableNameMapping[customDataset.datasetName] = customDataset.datasetTableName;
        }

        return tableNameMapping;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  return customMapping;
};

/**
 * 
 * @returns {Object} a mapping of emission source names and its tablename
 */
const getEmissionsMapping = () => {
  if (!emissionsMapping) {
    emissionsMapping = api.data.getEmissionDisplayNames()
      .then((result) => {
        const tableNameMapping = {};

        for (const key in result.data) {
          const name = result.data[key].name;
          const urlName = result.data[key].urlName;
          const tableName = result.data[key].tableName;

          tableNameMapping[name] = tableName;
          tableNameMapping[urlName] = tableName;
        }

        return tableNameMapping;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      })
  }

  return emissionsMapping;
};

/**
 * 
 * @param {String} emissionSource emission source name 
 * @returns {String} table name for emission source
 */
export const getEmissionTableName = (emissionSource) => {
  return Promise.all([
    getCustomMapping(),
    getEmissionsMapping()
  ])
  .then((result) => {
    const mapping = result.reduce((a, b) => {
      return {...a, ...b};
    }, {});

    return mapping[emissionSource] ?? emissionSource;
  })
};
