<template>
    <div class="sites-container"  :style="{ 'margin-left': sidebarWidth }">
        <div class="header">
            <h1 class="main-heading">Sites</h1>
        </div>

        <div class="tabs-container">
            <q-tabs v-model="selectedTab" active-color="primary"
                indicator-color="success" align="left" narrow-indicator dense no-caps>
                <q-tab v-for="tab in tabs" :key="tab.component" :name="tab.component" :label="tab.label" :ripple="false" />
            </q-tabs>
            <q-separator style="margin-bottom: 2em;" color="grey" />
            <component :is="selectedTab"/>
        </div>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import SitesComponent from "@/components/SiteComponents/SitesComponent.vue";
import CustomDenominators from "@/components/SiteComponents/CustomDenominators.vue"
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions.js";

export default {
    name: "sites-view",
    components: { SitesComponent, CustomDenominators },
    title: 'Sites',
    data() {
        return { 
            sidebarWidth,
            selectedTab: "SitesComponent",
            tabs: [
				{
					label: "Your Sites",
					component: "SitesComponent"
				},
			],
        }
    },

    created() {
        // Feature Flag to show CID information
        if (this.flags.FLAG_4253_CID && subPermissions.customIntensityDenominators.includes(getSub())) {
            this.tabs.push({
					label: "Custom Denominators",
					component: "CustomDenominators"
				})
        }
    }
};
</script>
<style lang="less" scoped src="../../assets/styles/sites.less" />
