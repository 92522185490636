<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <q-inner-loading :showing="loading" label="Generating Goal Snapshot Report" label-class="text-purple"
			label-style="font-size: 1.1em"/>
        <div v-if="!loading" >
            <q-btn
                flat
                round
                size="lg"
                color="primary"
                icon="arrow_back"
                @click="this.$router.back()"
                />

            <div class="board-report-buttons">             
				<q-btn :loading="generatingPDF" no-caps color="primary" @click="exportToPDF">Export to PDF</q-btn>
			</div>

            <div class="board-report-container" id="element-to-convert">
                <div class="board-report-header"> 
                    <img src="@/assets/greenhalologo.png" style="height: 2.2em; width: 10em;"/>
                </div>
                
                <div>
                    <p class="title">{{ title }}</p>
                    <p class="subtitle">{{ subtitle }}</p>
                </div>

                <div class="board-report-summary">
                    <span class="title" >Goals Summary</span>        
                    <div class="board-report-summary-items goal-summary-items">
                        <div class='summary-item'> 
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="goal-summary-title">Total Goals</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div class="goal-summary-amount">{{ numGoals }}</div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <div class='summary-item'>
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="goal-summary-title">On Track</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div class="goal-summary-amount">{{ onTrack }}</div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <div class='summary-item'>
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="goal-summary-title">Off Track</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div class="goal-summary-amount">{{ offTrack }}</div>
                                </q-card-section>
                            </q-card>
                        </div>
                    </div>
                </div>
                <span class="site-title">Your Targets</span>
                <div class="goals-report-table">
                    <q-table :rows="this.tableGoals.slice(0, 10)"
                            :columns="this.columns"
                            row-key="id"
                            hide-bottom
                            :rows-per-page-options="[0]"
                        >
                        <template v-slot:body-cell-progress="props">
                            <q-td :props="props">
                                <div>
                                    <q-linear-progress class="responseRateBar" stripe rounded size="10px" :value="props.value"
                                        :color="props.row.colour" />
                                </div>
                            </q-td>
                        </template>
                    </q-table>
                </div>
                
                <div class="goals-report-table" v-for="pageNum in tablePages" v-bind:key="pageNum">
                    <q-table 
                            :rows="this.tableGoals.slice(10 + 21 * (pageNum - 1), 10 + 21 * (pageNum))"
                            :columns="this.columns"
                            row-key="id"
                            hide-bottom
                            :rows-per-page-options="[0]"
                        >
                        <template v-slot:body-cell-progress="props">
                            <q-td :props="props">
                                <div>
                                    <q-linear-progress class="responseRateBar" stripe rounded size="10px" :value="props.value"
                                        :color="props.row.colour" />
                                </div>
                            </q-td>
                        </template>
                    </q-table>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import api from "@/services/api/api";
    import notify from "@/services/util/notify";
    import exportToPDF from '@/services/util/exportToPDF';

    import { sidebarWidth } from "../SidebarComponents/state";

    const GoalsReportComponent = {
        name: "GoalsReport",
        props: ["startDate", "endDate", "includeConnected"],
        data() {
            return {
                loading: true,
                generatingPDF: false,
                sidebarWidth: sidebarWidth,

                tableGoals: [],
                goals: [],
                numGoals: 0,
                title: `Goals Snapshot Report - ${(new Date()).toLocaleString('en-US', { month: 'short' })} ${(new Date()).getFullYear()}`,

                /* -- Table Data -- */
                columns: [
				{
					name: "goal",
					align: "left",
					label: "Goal",
					classes (row) {
						return new Date(row.deadline) < new Date() || row.startValue < row.averageDailyUsage ? 'text-red' : 'bg-white'
					},
					field: (row) => row.name,
				},
				{
					name: "progress",
					align: "left",
					style: "width: 20%",
					label: "Progress",
					field: (row) => {
						try {
							let percent = ((row.averageDailyUsage - row.targetValue) / (row.startValue - row.targetValue))
							return 1 - percent;
						} catch (e) {
							return 0;
						}
					},
				},
				{
					name: "current", 
					align: "left",
					label: "Current Value",
					field: (row) => `${row.averageDailyUsage} ${row.unit}`,
				},
				{
					name: "target", 
					align: "left",
					label: "Target",
					field: (row) => `${row.targetValue.toFixed(2)} ${row.unit}`,
				},
				{
					name: "due", 
					align: "left",
					label: "Due Date",
					field: (row) => {
						try {
							return new Date(row.deadline).toLocaleString().split(',')[0]
						} catch (e) {
							return 0;
						}
					},
				},
			],

            }
        },
        created() {
            this.init();
        },

        computed: {

            // Subtitle for report
            subtitle() {
                const startDate = new Date(this.startDate);
                const endDate = new Date(this.endDate);

                const startDay = startDate.getDate();
                const startMonth = startDate.toLocaleDateString('en-US', { month: 'short' });
                const startYear = startDate.getFullYear()

                const endDay = endDate.getDate();
                const endMonth = endDate.toLocaleDateString('en-US', { month: 'short' });
                const endYear = endDate.getFullYear();

                return `Goals started between ${startDay} ${startMonth} ${startYear} -  ${endDay} ${endMonth} ${endYear}`
            },

            // Gets total amount of pages to goals
            tablePages() {
                const rowsPP = 21 // Amount of rows per page
                const firstPageRows = 10 // Amount of rows to fit on the first page
                return Math.ceil((this.tableGoals.length - firstPageRows) / rowsPP)
            },

            offTrack() {
                return this.goals.filter(x => x.status == 'off track').length
            },

            onTrack() {
                return this.goals.filter(x => x.status == 'on track').length
            },
        },

        methods: {

            /**
             * @desc Initilases the goal report data
             */
            async init() {
                const maxGoals = 115; // Five pages of goals

                this.loading = true;

                let goals = [];

                if (this.includeConnected) {

                    // Get All source ids for connected organisation
                    const sourceIds = (await api.tenantConnections.getAllConnectedOrganisations()).map(org => org.sourceTenantId);
                    const requests = []
                    
                    // Get goals for each sourceId, this includes this tenancy
                    for (const id of sourceIds) {
                        requests.push(api.goals.getGoals(undefined, {startDate: this.startDate, endDate: this.endDate, tenantId: id}).then(res => res.data));
                    }
                    
                    await Promise.all(requests)
                    .then(responses => {
                        responses.forEach(data => {
                            goals = goals.concat(data);
                        })
                    }).catch((error) => {
                        notify.withObject(error.response);
                    })
                } else {
                    try {
                        goals = (await api.goals.getGoals(this.siteId, {startDate: this.startDate, endDate: this.endDate})).data;
                    } catch (error) {
                        this.loading = false;
                        notify.withObject(error.response);
                    }
                }

                for (let goal of goals) {
                    this.getGoalStatus(goal);
                }

                this.goals = goals.filter(x => x.status != 'completed')
                this.numGoals = this.goals.length;

                if (this.numGoals > maxGoals) {
                    this.tableGoals = this.goals.slice(0, maxGoals);
                    notify.warning(`Limiting tables to ${maxGoals} most recent goals`, 'top', "Table Limit Reached");
                } else {
                    this.tableGoals = this.goals;
                }
                
                this.loading = false;
            },

            /**
             * @desc Calculate the status of the goal (completed, off track, on track)
             */
            getGoalStatus(goal) {
                // To understand if a goal is completed, is the average daily usage less than or equal to the target value.
                if (goal.averageDailyUsage <= goal.targetValue) {
                    goal.status = 'completed'
                    goal.colour = 'green'
                    return;
                }

                let today = new Date();
                // let startDate = new Date(goal.startDate);
                let endDate = new Date(goal.deadline);

                // If the current date is past the deadline, or the average daily usage is over the start value, the goal is considered "Off Track"
                if (today > endDate || goal.averageDailyUsage > goal.startValue){
                    goal.status = "off track"
                    goal.colour = "red"
                } else {
                    goal.status = "on track";
                    goal.colour = "blue"
                }
                return;
            },


            /**
            * @desc Exports html page to a pdf using html2pdf
            */
            async exportToPDF() {
                this.generatingPDF = true;
                await exportToPDF("element-to-convert", "goals_snapshot", ['.goals-report-table']);
                this.generatingPDF = false;
			},
        },
    };

    export default GoalsReportComponent;
</script>
<style lang="less" scoped src="@/assets/styles/report.less"/>