<template>
    <nav class="navbar">
        <div class="navbar-container">
            <q-toolbar class="col-4 text-black">
                <q-img class="logo" src="../../assets/greenhalologo.png" style="{min-width: 150px; max-width: 150px;}"></q-img>

                
                <div v-if="userRole === roles.TestMaster" style="display: flex; flex-direction: row; align-items: center; gap: 1em; margin-left: 1em;" >
                    <q-btn @click="testDBLoad" :loading="testLoading">Load DB
                        <q-tooltip>
                            Loads data into Test Database
                        </q-tooltip>
                    </q-btn>
                    <q-btn @click="testDBClear" :loading="testLoading">Clear DB
                        <q-tooltip>
                            Clears data from the Test Database
                        </q-tooltip>
                    </q-btn>
                    <q-btn @click="testDBReset" :loading="testLoading">Reset Db
                        <q-tooltip>
                            Deletes test schema and re-creates and poplates a new schema
                        </q-tooltip>
                    </q-btn>
                </div>
               
                <q-space />
                <div class="navbar-site-list">

                    <q-select borderless v-model="siteStateStore.siteName" :options="siteNames">
                        <template v-slot:prepend>
                            <q-icon class="navbar-site-icon" name="location_on" size="1.7rem" />
                        </template>
                    </q-select>
                </div>

                <q-space />

                <q-btn-dropdown class="navbar-user-dropdown" size="1rem" no-caps flat :label="userEmail">
                    <q-list>
                        <q-item clickable v-close-popup @click="logout">
                            <q-item-section>
                                <q-icon name="logout" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Logout</q-item-label>
                            </q-item-section>
                        </q-item>

                    </q-list>
                </q-btn-dropdown>

            </q-toolbar>


        </div>
    </nav>
</template>

<script>
import getRole from "@/services/util/role";
import currRoles from '@/roles';

import testRoutes from '@/services/api/testing';
import notify from "@/services/util/notify";

import { useSiteStateStore } from "@/stores/siteState.store";
import { getActivePinia } from "pinia";

const jwt = require("jsonwebtoken");

export default {

    components: {
	},

    data() {
        return {
            testLoading: false,
            testRoutes,

            roles: currRoles,
            userRole: "",
            userEmail: "",
            siteStateStore: useSiteStateStore(),
            showContactUsPanel: false,
        };
    },

    /**
     * @desc Set the userRole of the logged in user
     */
    created() {
        this.userRole = getRole();
        this.setEmail();


    },

    computed: {
        siteList() {
            if (this.siteStateStore.siteList.length > 1) {
                return [{ id: null, name: "All Sites"}].concat(this.siteStateStore.siteList)
            } else {
                return this.siteStateStore.siteList;
            }
        },
        siteName(){
            return this.siteStateStore.siteName

        },
        siteNames() {
            return this.siteList.map(function (item) { return item.name });
        },

    },


    watch: {

        /**
         * @desc When new site selected, change the site the app is set to
         * @param {String} newSite the desired set to view data for.
         */
        siteName(newSite) {
            let siteObject = this.getSiteObject(newSite);

            if (siteObject.length == 1) {
                this.siteStateStore.updateActiveSite(siteObject[0].id, siteObject[0].name);
            }
        },

    },
    async mounted(){
        this.emitter.on('', () => this.userRole=getRole())
        // Because the sidebar is included in the App.vue definition (but not displayed), we need to ensure the site list isn't retrieved on the intro page
        await this.siteStateStore.getSiteList()
    },
    methods: {

        /* Test Methods */
        async testDBLoad() {
            try {
                this.testLoading = true
                let message = await testRoutes.loadDB();
                notify.primary(message.data, 'top', 'Loaded batch of test data');

            } catch (err) {
                notify.error(err.message, 'top', 'Unable to load test data');
            } finally {
                this.testLoading = false
            }
        },
        async testDBClear() {
            try {
                this.testLoading = true
                let message = await testRoutes.clearDB();
                notify.primary(message.data, 'top', 'Cleared database');
            } catch (err) {
                console.log(err)
                notify.error(err.message, 'top', 'Unable to clear test database');
            } finally {
                this.testLoading = false
            }

        },
        async testDBReset() {
            try {
                this.testLoading = true
                let message =   await testRoutes.resetDB();
                notify.primary(message.data, 'top', 'Reset database');
            } catch (err) {
                console.log(err)
                notify.error(err.message, 'top', 'Unable to reset database');
            } finally {
                this.testLoading = false
            }
        },
       
        /**
         * @desc Given a site name, return the site object to be stored in localStorage.
         * @param {String} siteName 
         */
        getSiteObject(siteName) {
            return this.siteList.filter(site => { return site.name == siteName });
        },


        /**
         * @desc Logs the user out
         * Resets the token and id of the user within local storage
         * Clears Pinia stores
         */
        logout() {
            getActivePinia()._s.forEach(store => {
                store.$reset();
            });

            localStorage.clear();
            sessionStorage.clear();
            this.$router.push({ name: "logout" });
        },

        /**
		 * @desc Closes the contact us panel.
		 */
		closeCreatePanel() {
			this.showContactUsPanel = false;
		},

        /**
         * @desc Retrieve the users email to display on the navbar
         */
        setEmail() {
            let decodedToken = jwt.decode(localStorage.getItem("authToken"));
            
            if (decodedToken?.email) {
                this.userEmail = decodedToken.email;  
            }
        }
    },
};


</script>

<style scoped>
@import "../../assets/styles/navbar.less";
</style>
