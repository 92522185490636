/**
 * livestock.js
 * 
 * API for handling getting all livestock associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves livestock chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLivestockChartData = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/livestock/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Generate livestock CSV file
 * 
 * @param {Number} siteId site to retrieve CSV for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLivestockCSV = async function (siteId) {
    return axiosInstance({
        url: `/app/livestock/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `livestock-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}



/**
 * @desc Get the total livestock usage in the past year.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsight1 = async function(siteId) {
    return await axiosInstance.get(`/app/livestock/insights/usage/year`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Retrieve total livestock usage in past year converted to equivalent barrels.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsight2 = async function(siteId) {
    return await axiosInstance.get(`/app/livestock/insights/usage/highest/month`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Get total livestock usage converted to emissions in the past year.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsight3 = async function(siteId) {
    return await axiosInstance.get(`/app/livestock/insights/emissions/year`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Get total cost spent on livestock in past year
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsight4 = async function(siteId) {
    return await axiosInstance.get(`/app/livestock/insights/common-type/year`, {params: {siteId}}).then(res => res);
}


export default {
    getLivestockChartData,
    getLivestockCSV,
    getInsight1,
    getInsight2,
    getInsight3,
    getInsight4,
}