<template>
    <q-card style="max-width: 50rem; min-height: 96.2vh">
        <q-card-section>
        <div class="text-h6">Timestamp Formatting</div>
        </q-card-section>

        <q-card-section>
            Timestamps are used to store your data with the correct time that each row is relevant to. Because timestamps can have multiple different formats, we need to have a standard
            that we will use to ensure accurate storage of your data. While the default excel format that you see with <b>d/mm/yyyy h:mm</b> will work, to ensure more accurate parsing, we recommend using our format.
            <br><br>
            Our selected format is <b>d/mm/yyyy h:mm</b><q-icon class="copy-button" name="content_copy" @click="copyToClipboard('d/mm/yyyy h:mm')"></q-icon>
            <br><br>
            To set excel to use this format, please follow the guide below.
        </q-card-section>
        <q-card-section>

    <q-carousel
      v-model="slide"
      swipeable
      animated
      navigation-position="bottom"
      navigation
      padding
      height="800px"
      control-color="purple"
      class="bg-white text-white rounded-borders"
    >
      <q-carousel-slide name="style" class="column no-wrap flex-center">
     
                <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/timestamp/get-column.png"
                >
            </q-img>
     
            <div>
                <b style="color:black">
                    Select the timestamp column by clicking the letter responding to the column. (In this case "A")
                </b>
            </div>
      
      </q-carousel-slide>
      <q-carousel-slide name="tv" class="column no-wrap flex-center">
        <div class="q-mt-md text-center">
            <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/timestamp/get-options.png"
              >
              </q-img>
              <div>
                <b style="color:black">
                   Right click anywhere along the selected column and press "Format Cells" 
                </b>
            </div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="layers" class="column no-wrap flex-center">
        <div class="q-mt-md text-center">
            <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/timestamp/custom-format.png"
              >
              </q-img>
              <div>
                <b style="color:black">
                    Choose the "custom" category on the left and under "Type" you can choose a custom format for the data. In here paste the format <u>yyyy-MM-d HH:mm:ss</u> The next picture shows this process
                </b>
            </div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="map" class="column no-wrap flex-center">
        <div class="q-mt-md text-center">
            <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/timestamp/custom-format2.png"
              >
              </q-img>
              <div>
                <b style="color:black">
                    Copy the format shown above  <u>yyyy-MM-d HH:mm:ss</u>  into the text field under "Type"
                </b>
            </div>
        </div>
      </q-carousel-slide>
      <q-carousel-slide name="final" class="column no-wrap flex-center">
        <div class="q-mt-md text-center">
            <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/timestamp/final.png"
              >
              </q-img>
              <div>
                <b style="color:black">
                    You now should have your timestamp column match our recommended format!
                </b>
            </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
    </q-card>
</template>
<script>

import { Notify } from 'quasar'

const TimestampHelpComponent = {
    name: 'TimestampHelpComponent',
    props: ["emissionSource"],
    data(){
        return {
            slide: "style"
        }
    },
    methods: {
        copyToClipboard(formatString) {
            navigator.clipboard.writeText(formatString);
            Notify.create({
                message: `Copied format string '${formatString}' to clipboard`,
                position: 'top'
            })
        }
    }
}

export default TimestampHelpComponent;

</script>
<style scoped lang="less" src="../../assets/styles/manualUpload.less" />
