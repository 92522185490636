<template>
    <div class="add-supplier-connection-parent-container">
        <div class="tabs-container">
            <q-tabs 
                v-model="tab"
                dense 
                no-caps 
                active-color="primary" 
                indicator-color="success" 
                align="left" 
                narrow-indicator
            >
                <q-tab v-for="tabPanel in tabPanels" :key="tabPanel.name" :name="tabPanel.name" :label="tabPanel.label" />
            </q-tabs>
            <q-separator color="grey" />
        </div>
        <q-form class="form" @submit="createConnection()">
            <div class="main-content">
            <q-tab-panels v-model="tab" animated keep-alive>
                <q-tab-panel v-for="tabPanel in tabPanels" :key="tabPanel.name" :name="tabPanel.name">
                    <component :is="tabPanel.component" v-bind="tabProperties" @update=updateProperty />
                </q-tab-panel>
              </q-tab-panels>
            </div>

            <template v-if="tab === 'create'">

              
              <q-toggle ref="toggle" @animationend="this.shakeToggle = false" class="justify-center" v-model="acceptUsage" label="I consent to give GreenHalo authority to use my connection information." />
              
              <q-btn
                  class="button"
                  :disable="submitLoading || !acceptUsage"
                  :loading="submitLoading"

                  tool
                  square
                  no-caps
                  padding="8px 24px"
                  color="secondary"
                  label="Create"
                  type="submit"
              >
              <q-tooltip v-if="!acceptUsage">
                Please provide consent to create a connection
              </q-tooltip>
            
              </q-btn>
            </template>

        </q-form>
    </div>
</template>

<script>
import api from '@/services/api/api';
import CreateDataFeedComponent from './CreateDataFeedConnectionComponent';
import DataFeedMappingComponent from './DataFeedMappingComponent.vue';
import SplitDataSettingsComponent from "./SplitDataSettingsComponent";

export default {
  components: {
		CreateDataFeedComponent,
    DataFeedMappingComponent,
    SplitDataSettingsComponent,
	},

  data() {
    return {
      tab: "create",
      acceptUsage: false,

      // Create Page
      emissionSource: "",
      supplier: "",
      supplierInputs: {},
      submitLoading: false,
      supplierConnections: [],


      // Mappings Page
      emissionFactors: [],
      mappings: {},
      allDefaultMappings: {},
      defaultMappings: {},


      // Settings
      splitOptions: {},
    }
  },

  async created() {
    this.supplierConnections = await this.getSupplierConnections();

    for (const conn of this.supplierConnections) {
      this.allDefaultMappings[conn.supplierId] = conn.defaultMappings
    }

    this.emissionFactors = await this.getEmissionFactors();
  },


  computed: {
    tabPanels() {
      const tabs = [
        { name: "create", label: "Create", component: "CreateDataFeedComponent" },
        { name: "mapping", label: "Mappings", component: "DataFeedMappingComponent", hide: !this.supplier },
        { name: "splitData", label: "Split Data", component: "SplitDataSettingsComponent", hide: this.emissionSource?.sourcedisplayname !== "Electricity" },
      ]

      return tabs.filter((tab) => !tab.hide);
    },
    
    tabProperties() {
      return {
        emissionSource: this.emissionSource,
        supplier: this.supplier,
        supplierInputs: this.supplierInputs,
        values: { dataTypes: Object.keys(this.defaultMappings) },
        mappings: { dataTypes: this.emissionFactors },
        savedMappings: { dataTypes: this.defaultMappings },
      }
    },
  },

  watch: {
    supplier(val) {
      if (val) {
        this.defaultMappings = this.allDefaultMappings[val.id];
      }
    }
  },

  methods: {
    updateProperty(propertyName, value) {
		this.supplierInputs = {};
      this[propertyName] = value;
    },

    async getSupplierConnections() {
      return (await api.dataFeeds.getAvailableConnections()).data;
    },

    async getEmissionFactors() {
      return (await api.emissions.getMappingOptions()).data.emissionfactor;
    },

    async createConnection() {
      if (this.acceptUsage) {
        const payload = {
          interval: "1 month",
          splitOptions: this.splitOptions,
          dataTypeMappings: this.mappings.dataTypes ?? this.defaultMappings,
          ...this.supplierInputs,
        };
        await this.$emit("createConnection", this.supplier.uuid, payload);
      }
    },
  },
}
</script>

<style lang="less" src="@/assets/styles/siteModal.less" scoped />
