<template>
				<!-- Total emission proportions by emission source -->

<div v-if="loading"
	class="home-loading-spinner-container"
	style="margin: 100px 0"
	>
		<q-spinner color="green-5" size="5em"/>
</div>
<div v-else
	class="home-total-emissions-container"
>
	<div class="home-total-emissions-title">
		Total CO2e Emission contributors last {{ this.dateRange }} ({{ total.toFixed(2) }} kg)
		<q-btn no-caps color="green" flat outlined label="Click here to view emission factors" @click="showFactors = true" />
	</div>
	<div v-if="total > 0" class="home-total-emissions-bar-container">



			<div  v-for="value, key, index in proportions" :key="key.id"
				class="home-emissions-proportion-slice"
				
				:style="'background-color:' +  colours[index] + ';width: ' + value + '%;'" 

				>
					<div v-if="value > 10"
						class="home-emissions-proportion-slice-text"
					>
						{{ key }} {{ value.toFixed(2) }}%
					</div>
					<q-tooltip v-if="value <= 10"
					:style="'font-size: ' + this.tooltipSizeRem + 'rem'" 
					anchor="top middle" 
					self="bottom middle"
					:offset="[10, 10]"
					> 
						{{ key }}
						{{ value.toFixed(2) }}%
					</q-tooltip>
		
		</div>
	</div>
	
	<!-- Container for when there is no total emissions proportions data -->
	<div v-if="total <= 0" class="home-empty-total-emissions">
		<div class="home-empty-total-emissions-text">
			No emissions found for the past {{ this.dateRange }}
		</div>
	</div>

	<q-dialog v-model="showFactors">
		<EmissionFactorComponent/>
	</q-dialog>

</div>
</template>

<script>
import api from "./../../services/api/api";
import notify from "@/services/util/notify";

import EmissionFactorComponent from "../DataComponents/EmissionFactorComponent";

const HomeComponent = {
	name: "Home",
	props: ["dateRange", "siteId"],
	components: {
		EmissionFactorComponent
	},

	data() {
		return {
			userSettings: [],
			loading: false,
			firstLoading: true,
			chartLoaded: false,
			dataExists: false,
			slide: 1,

			// This var determines how big the text of the q-tooltips (that exist when an emission
			// source's proportion is < 10% of total co2e emissions) are displayed.
			tooltipSizeRem: 1.2,

			totalEmissions: {},
			proportions: {},
			total: 0,
			colours: [
				'#b8da45',
				'#3aa474',
				'#4a5433',
				'#238285',
				'#02c5a3',
				'#2c83a3',
				'#027864',
				'#d374ab',
				'#cc74d6',
				'#d374ab',
				'#b35600'	
			],
			showFactors: false,
		};
	},

	created() {
		this.generateProportions();
	},

	watch: {
		dateRange() {
			this.generateProportions();
		},
	},

	methods: {
		/**
		 * @desc Generates a random colour
		 */
		randomColor() {
		const r = () => Math.floor(256 * Math.random());

		return `rgb(${r()}, ${r()}, ${r()})`;
		},


		/**
		 * @desc Using the total emissions data, generate the proportions graph
		 */
		async generateProportions() {

			this.loading = true;
			await api.data.getTotalEmissionPastDays(this.dateRange, this.siteId)
				.then((response) => {
					this.totalEmissions = response.data

					this.total = Object.values(this.totalEmissions).reduce((a, b) => a + b, 0);

					for (let item in this.totalEmissions) {
						this.proportions[item] = this.totalEmissions[item] / this.total * 100;
					}
				})
				.catch((error) => {
					let errorMsg =
						error.response.data ??
						"Error connecting to server. Please try again later.";
					notify.error(errorMsg, "top");
				})
				.finally(() => {
					this.loading = false;
					this.firstLoading = false;
				});
		},	
	},
};

export default HomeComponent;
</script>

<style lang="less" scoped>
@import "../../assets/styles/home.less";
</style>
