<template>
	<q-spinner
		color="primary"
		size="15vh"
	/>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

export default {
	created() {
		this.getGuestLoginToken();
	},
	methods: {
		async getGuestLoginToken() {
			try {
				const token = await api.tenantConnections.getGuestToken(
					this.$route.query.sourceTenantId
				);
				sessionStorage.setItem("authToken", token.data);
				sessionStorage.setItem("guestMode", true);
				this.emitter.emit('newToken');
				this.$router.push({ name: "home" });
			} catch (err) {
				notify.withObject(err.response);
				this.$router.push({ name: "home" });
			}
		},
	},
};
</script>
