/**
 * invoice.js
 *
 * API for handling AI assistant invoices
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Uploads an invoice file.
 * 
 * @param {File} files the files to be uploaded
 * @param {Number} siteId the id of the site the files are to be uploaded to
 * @returns 
 */
const uploadEmissionInvoice = async (files, siteId) => {
    const formData = new FormData();
    
    for (const file of files) {
        formData.append("files", file);
    }
    
    return axiosInstance.post(`/api/data/invoice`, formData, {
        params: {
            siteId,
        },
		timeout: 120000
    }).then(res => res);
}

/**
 * Asynchronously retrieves invoice statuses using a predefined axios instance.
 * 
 * @returns {object} A response object containing invoice statuses.
 */
const getInvoiceStatuses = async () => {
	return axiosInstance.get(`/api/data/invoice/status`).then(res => res);
}

/**
 * Retrieves the invoice file status options to made status ids to code/names
 * 
 * @returns {object} A response object containing invoice file status options.
 */
const getInvoiceStatusOptions = async () => {
	return axiosInstance.get(`/api/data/invoice/status/options`).then(res => res);
}

/**
 * Retrieves the split options previously used for the given supplier 
 * 
 * @returns {object} A response object containing invoice split options data.
 */
const getPreviousOptions = async (fileId) => {
	return axiosInstance.get(`/api/invoice/previous-options`, {params: {fileId}}).then(res => res);
}

/**
 * Retrieves the parsed data for a given file to be split.
 * 
 * @returns {object} A response object containing invoice file data.
 */
const getParsedData = (fileId) => {
	return axiosInstance.get(`/api/files/parsed`, { params: { fileId }}).then(res => res);
}

/**
 * Establishes a Server-Sent Events (SSE) connection for receiving invoice events based on the environment mode set in the application's environment variables.
 * Supports different URLs for local, debug, development, and production environments.
 * 
 * @returns {EventSource} An EventSource instance connected to the appropriate server URL's invoice status stream endpoint.
 * @throws {string} Throws a string error if the environment variable is incorrectly set or not recognized.
 */
const establishInvoiceEventsConnection = () => {
	let SERVER_URL;
	if (process.env.VUE_APP_ENV_MODE == 'LOCAL' || process.env.VUE_APP_ENV_MODE == 'DEBUG') {
		// Local dev server URL
		SERVER_URL = "http://localhost:3000";
	} else if (process.env.VUE_APP_ENV_MODE == 'DEV') {
		SERVER_URL = 'https://pavlovadevserver.azurewebsites.net';
	} else if (process.env.VUE_APP_ENV_MODE == 'PROD') {
		// Deployed server URL
		SERVER_URL = "https://pavlovaprodserver.azurewebsites.net";
	} else {
		throw `Environment variable is incorrectly set.`
	}


	const source = new EventSource(SERVER_URL + '/api/data/invoice/status/stream?authToken='+localStorage.getItem('authToken'));

	return source;
}

const updateInvoiceStatus = async (fileId, statusCode) => {
	return axiosInstance.patch(`/api/data/invoice/status/${fileId}`, { statusCode }).then(res => res);
};



export default {
	uploadEmissionInvoice,
	establishInvoiceEventsConnection,
	getInvoiceStatuses,
	getInvoiceStatusOptions,
	getPreviousOptions,
	getParsedData,
	updateInvoiceStatus,
};
