<template>
  <div class="supplier-portal">
    <SupplierPortalNavbarComponent />
    <div class="supplier-portal-content">
      <SupplierUploadComponent />
    </div>
  </div>
</template>

<script>
import SupplierPortalNavbarComponent from '@/components/SupplierComponents/Portal/SupplierPortalNavbarComponent.vue';
import SupplierUploadComponent from '@/components/SupplierComponents/Portal/SupplierUploadComponent.vue';

export default {
  components: {
    SupplierUploadComponent,
    SupplierPortalNavbarComponent,
  }
}
</script>

<style lang="less" scoped>
.supplier-portal {

  .supplier-portal-content {
    margin-top: var(--navbar-height);
    padding: 50px;
    height: calc(100vh - var(--navbar-height));
  }
}
</style>