<template>	
	<!-- Loaded page content -->
	<div class="title-bar">
		<span class="table-title">Your Custom Denominators</span>
		<q-space/>
		<div class="add-button">
			<q-btn
				no-caps
				color="deep-purple-4"
				label="New Denominator"
				icon="add"
				square
				padding="10px 20px"
				@click="showCreateDenominatorPanel = true"
			/>
		</div>
	</div>
	<q-card class="sites-table">
		<q-table
			hide-pagination
			:loading="loading"
			:rows-per-page-options="[0]"
			:rows="denominators"
			:columns="columns"
			row-key="name"
		>

		<template v-slot:body-cell-actions=props>
			<td style="text-align: end; color: black !important;">
				<q-btn 
					flat 
					dense 
					rounded 
					icon="delete"
					@click="() => { confirmDelete = true; CIDToDelete = props.row.uuid }">
					<q-tooltip>
						Delete Denominator
					</q-tooltip>
				</q-btn>
			</td>
		</template>

		</q-table>
	</q-card>


	<q-dialog
		v-model="confirmDelete"
		persistent
	>
		<q-card>
			<q-card-section class="row items-center">
				<span>Are you sure you want to delete {{ CIDToDelete }}?</span>
			</q-card-section>

			<q-card-actions align="center">
				<q-btn
					flat
					label="Cancel"
					color="primary"
					v-close-popup
				/>
				<q-btn
					flat
					label="Delete"
					color="deep-orange"
					@click="deleteDenominator()"
					v-close-popup
				></q-btn>
			</q-card-actions>
		</q-card>
	</q-dialog>

	<SideModalComponent v-model="showCreateDenominatorPanel">
		<CreateDenominatorComponent @close="() => { showCreateDenominatorPanel = false; getDenominators(); }" />
	</SideModalComponent>

</template>

<script>
import api from "@/services/api/api";
import SideModalComponent from "../SideModalComponent.vue";
import CreateDenominatorComponent from "./SidePanels/CreateDenominatorComponent.vue"
import notify from "@/services/util/notify";

const CustomDenominators = {
	name: "Custom Denominators",

	components: {
		SideModalComponent,
		CreateDenominatorComponent
	},

	data() {
		return {
			loading: false,
			denominators: [],
			columns: [
				{
					name: "uuid",
					align: "left",
					label: "UUID",
					field: (row) => row.uuid,
					sortable: true,
				},
				{
					name: "name",
					align: "left",
					label: "Name",
					field: (row) => row.displayName,
					sortable: true,
				},
				{
					name: "value",
					align: "left",
					label: "Value",
					field: (row) => row.defaultValue,
				},
				{
					name: "actions",
					align: "right",
					label: "Actions",
				},
			],
			showCreateDenominatorPanel: false,
			CIDToDelete: "",
			confirmDelete: false,
			loadingDelete: false,
		};
	},

	created() {
		this.getDenominators();
	},

	methods: {
		async getDenominators() {
			this.loading = true;

			try {
				this.denominators = (await api.cid.getAllCID()).data;
			} catch(err) {
				notify.withObject(err.response);
			} finally {
				this.loading = false;
			}
		},

		async deleteDenominator() {
			try {
				await api.cid.deleteCID(this.CIDToDelete);
				notify.primary(`Successfully deleted denominiator ${this.CIDToDelete}.`);
				this.getDenominators();
			} catch (err) {
				notify.withObject(err.response);
			}
		}	

	}
};

export default CustomDenominators;
</script>

<style lang="less" scoped src="../../assets/styles/sites.less" />
