<template>
	<div class="container">
		<h2 class="heading">Create New Connection</h2>
		<div class="form">
            <div class="inputs">
                <q-select
                    stack-label
                    outlined
                    :model-value="emissionSource"
                    @update:model-value="updateEmissionSource"
                    label="Emission Source"
					:disable="loadingEmissionSources"
                    required
                    :options="emissionSources"
                    option-label="sourcedisplayname"
                    :loading="loadingEmissionSources"
                    :rules="[val => val || val.length > 0 || 'Please select an emission source']"
                />

                <q-select
                    stack-label
                    outlined
                    :disable="!emissionSource || loadingSuppliers || loadingEmissionSources"
                    :model-value="supplier"
                    @update:model-value="updateSupplier"
                    label="Supplier"
                    option-label="name"
                    required
                    :options="suppliers"
                    :loading="loadingSuppliers"
                    :rules="[val => val || val.length > 0 || 'Please select a supplier']"
                />
                <div>
                    
                    <h3 class="sub-heading" v-if="supplier"> Create new connection for
                        {{supplier.name}} </h3>  
                    
                    <div class="q-mb-lg">
                        <DynamicInputsComponent v-if="Object.keys(dynamicInputConfig).length !== 0" :inputs="dynamicInputConfig" :model-value="supplierInputs"  @update:model-value="(value) => $emit('update', 'supplierInputs', value)"/>
                        <div class="row justify-center items-center" v-else>
                            <q-spinner v-if="loadingDynamicInput" size="lg" color="primary" />
                            <template v-else-if="!loadingDynamicInput && supplier">
                                <q-icon  name="warning" size="md"/> No configuration for selected supplier
                            </template> 
                            <template v-else>
                                <q-icon  name="warning" size="md"/> Please Select a Supplier
                            </template> 
                        </div>
                    </div>

                    <ConnectionTutorialComponent :supplier="supplier?.name"/>
                </div>
            </div>
		</div>
	</div>
</template>
  
<script>

import notify from '@/services/util/notify';
import DynamicInputsComponent from './DynamicInputsComponent.vue';
import ConnectionTutorialComponent from './ConnectionTutorialComponent.vue';


import api from '@/services/api/api';


export default {
	name: "CreateConnectionTab",
    components: {
        DynamicInputsComponent,
        ConnectionTutorialComponent
    },
	props: ["emissionSource", "supplier", "supplierInputs"],
	emits: ["update"],

    data() {
        return {
            emissionSources: [],
            loadingEmissionSources: false,
            
            suppliers: [],
            loadingSuppliers: false,

            loadingDynamicInput: false,
            dynamicInputConfig: {},
        }
    },

	async created() {

        if (this.emissionSources.length === 0) {
            this.loadingEmissionSources = true;

            try {
                this.emissionSources = (await api.data.getEmissionSources(true)).data;
            } catch (error) {
                console.error(error);
                notify.error("Unable to retrieve connection emission sources. Please try again later.")
            } finally {
                this.loadingEmissionSources = false;
            }
        }
    },

    methods: {
        async updateEmissionSource(selectedSource) {
            this.loadingSuppliers = true;

			this.dynamicInputConfig = {};

            try {
                if (this.emissionSource !== selectedSource) {
                    this.$emit('update', 'supplier', '');
                }

                this.$emit('update', 'emissionSource', selectedSource);

                this.suppliers = (await api.supplier.getSuppliersByEmissionSource(selectedSource.id)).data;
            } catch (error) {
                console.error(error);
                notify.error("Unable to retrieve suppliers for selected emission source")
            } finally {
                this.loadingSuppliers = false;
            }

            
        },

        async updateSupplier(selectedSupplier) {
            this.loadingDynamicInput = true;

            try {
                if (!this.supplier) {
                    this.dynamicInputConfig = {}
                }
                this.$emit('update', 'supplier', selectedSupplier);
                this.dynamicInputConfig = (await api.dataFeeds.getFrontendFormConfig(selectedSupplier.uuid)).data ?? {};
				for (let input in this.dynamicInputConfig) {
					const inputObject = this.dynamicInputConfig[input];
					const url = inputObject.optionListRoute;
					if (url) {
						inputObject.props.options = await api.callWithRoute(url);
					}
				}
            } catch (error) {
                console.error(error);
                notify.error("Unable to retrieve supplier connection infomation. Please try again later.")
            } finally {
                this.loadingDynamicInput = false;
            }
        },
    },
}
</script>

<style lang="less" src="@/assets/styles/sideModal.less" scoped />
