<template>
  <div :style="{ 'margin-left': sidebarWidth }">
    <ViewGoalsComponent />
  </div>
</template>

<script>
import { sidebarWidth } from "@/components/SidebarComponents/state";
import ViewGoalsComponent from "../../components/GoalsComponents/ViewGoalsComponent.vue";

export default {
  name: "view-goals-view",
  components: { ViewGoalsComponent },
  title: "view-goals-view",
  setup() {
    return { sidebarWidth };
  },
};
</script>