// role.js

import jwt from "jsonwebtoken";

/**
 * @desc Decodes the auth token in local storage, and grabs the user role value.
 * @returns user role
 */
export default () => {
    let token;
    if(sessionStorage.getItem("authToken")){
        token = sessionStorage.getItem("authToken");
    }else{
        token = localStorage.getItem("authToken");
    }
    if(!token){
        return undefined;
    }

    let fields = jwt.decode(token);
    return fields.role;
}