<template>
  <component 
    :is="popup ? 'q-dialog' : 'div'"
    v-if="popup || modelValue"
    v-bind="props"
    :model-value="modelValue"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
  >
    <q-card class="main-card" :style="{ 'height': height, 'width': width }">
      <q-btn 
        @click="$emit('update:modelValue', false)"
        style="margin-left: auto;"
        icon="close"
        color="red"
        size="1.25em"
        flat
        round
        dense
        v-close-popup
      />
      <q-card-section>
        <slot></slot>
      </q-card-section>
    </q-card>
  </component>
</template>

<script>
  export default {
    props: {
      popup: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: "100%",
      },
      height: {
        type: String,
        default: "100%",
      },
      persistent: {
        type: Boolean,
      },
      noEscDismiss: {
        type: Boolean,
      },
      noBackdropDismiss: {
        type: Boolean,
      }
    },

    computed: {
      component() {
        return this.popup ? "q-dialog" : "div";
      },
      props() {
        if (this.component === "q-dialog") {
          return {
            "persistent": this.persistent,
            "no-esc-dismiss": this.noEscDismiss,
            "no-backdrop-dismiss": this.noBackdropDismiss,
          };
        }

        return {};
      }
    }
  }
</script>

<style lang="less" scoped>
  .main-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: initial;
    max-height: initial;
  }
</style>