/**
 * petrol.js
 * 
 * API for handling getting all petrol associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves air travel chart data
 * 
 * @param {String} filters query string to filter with 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPetrolLineChart = async function(filters) {
    return await axiosInstance.get(`/app/petrol/chart${filters}`).then(res => res);
};


/**
 * @desc Gets petrol data in CSV form. Once fetched, does browser stuff for the download
 * 
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPetrolCSVExport = async function(siteId) {
    return axiosInstance({
        url: `/app/petrol/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `petrol_data.csv`);
        document.body.appendChild(link);
        link.click();
    });
}


export default {
    getPetrolLineChart,
    getPetrolCSVExport,
}