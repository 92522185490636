<template>
		<q-stepper
			flat
			v-model="step"
			ref="stepper"
			class="stepper"
			color="primary"
		>
			<q-step
				v-for="(header, index) in headers"
				:key="header"
				:name="index"
				title=""
			>
				<q-table 
					class="bulk-upload-mapping-table"
					:columns="columns"
					:rows="getRows(header)"
					:rows-per-page-options="[0]"
					row-key="value"
					virtual-scroll
					:pagination-label="() => ''"
				>
					<template #body-cell-seperator="props">
						<q-td :props="props">
							<q-icon name="arrow_forward" size="1.5rem" />
						</q-td>
					</template>
					
					<template #body-cell-matchingValue="props">
						<q-td :props="props">
							<q-select 
								class="bulk-upload-mapping-selection"
								v-model="mappingValues[header].selected[props.row.value]"
								:options="mappingValues[header]?.filtered"
								outlined 
								dense 
								use-input 
								hide-selected
								hide-bottom-space
								fill-input
								@update:model-value="updateMappings"
								:rules="[ val => val && val.length > 0 || '' ]"
								@filter="(val, update) => filterFn(val, update, header)"
							>
								<template v-slot:prepend>
									<q-icon name="search" />
								</template>
							</q-select>
						</q-td>
					</template>
				</q-table>
			
				<q-card-actions class="bulk-upload-mapping-buttons">
					<q-btn
						v-if="step > 0" 
						class="rounded-button"
						color="primary"
						round
						icon="arrow_back"
						@click="$refs.stepper.previous()"
						/>
					<q-btn
						v-if="headers.length !== step + 1"
						class="rounded-button"
						color="secondary" 
						round
						icon="arrow_forward"
						@click="$refs.stepper.next()"
						
					/>
				</q-card-actions>
			
			</q-step>
		</q-stepper>

	
</template>

<script>

import isEqual from 'lodash/isEqual';

export default {
	name: "DataFeedMapping",
    props: {
		values:  {
			type: Object,
			required: true,
		},
		mappings: {
			type: Object,
			required: true,
		},
		savedMappings: Object,
    },
    data() {
        return {
			step: 0,
			headers: [],
            columns: [
                {
                    name: "value",
                    label: "Value",
                    align: "left",
                    classes: "bg-white",
                    field: (row) => row.value,
                },
                {
                    name: "seperator",
                    align: "center",
                    label: "",
                    headerStyle: "width: 100%",
                    classes: "bg-white",
                },
                {
                    name: "matchingValue",
                    label: "Matching Value",
                    align: "left",
                    classes: "bg-white",
                    field: (row) => row.matchingValue,
                }
            ],
			mappingValues: {}
        }
    },

	created() {
		this.setDefaultMappings()
	},


	watch: {
		savedMappings(newMappings, oldMappings) {
			if (!isEqual(newMappings, oldMappings)) {
				this.setDefaultMappings();
			}

		}
	},

	methods: {
		updateMappings() {
			const returnMappings = {};
			
			for (const [column, mapping] of Object.entries(this.mappingValues)) {
				returnMappings[column] = mapping.selected;
			}
			this.$emit('update', 'mappings', returnMappings);
		},
	

		setDefaultMappings() {
			this.headers = Object.keys(this.values);
			this.currentHeader = this.headers[0];
			for (const header of this.headers) {
				this.mappingValues[header] = {
					original: this.mappings[header],
					filtered: [...this.mappings[header]],
					selected: {}
				}

				for (const value of this.values?.[header] ?? []) {
					// If we have a saved user mapping, autofill it
					if(this.savedMappings && this.savedMappings[header]?.[value]){
						this.mappingValues[header].selected[value] = this.savedMappings[header][value];
					}
					// If the user supplied value matches the GreenHalo value
					else if(this.mappings[header].includes(value)){
						this.mappingValues[header].selected[value] = value;
					}
					// If nothing matches, we must prompt user to provide a mapping
					else{
						this.mappingValues[header].selected[value] = null;
					} 
				}
			}

			this.updateMappings();
		},

		filterFn(inputVal, update, header) {
            update(() => {
                const needle = inputVal.toLowerCase();
                this.mappingValues[header].filtered = this.mappingValues[header].original.filter((value) => value.toLowerCase().includes(needle));
            });
        },

		getRows(header) {
            const returnRows = [];

            if (returnRows.length === 0) {
				for (const value of this.values?.[header] ?? []) {
                    if (value) {
                        returnRows.push({
                            value: value,
                        });
                    }
                }
            }

            return returnRows;
        },

	}
}
</script>
<style lang="less">
	.stepper {
		.q-stepper__header {
			display: none;
		}
	}
</style>