/**
 * reports.js
 * 
 * API for handling getting all reports associated data
 */

import axiosInstance from "./axios-instance";
import { formatInLocaleShortDate } from "../util/dates";


/**
 * @desc Retrieves all emission data for a given subsidiary tenant
 * 
 * @param {int} sourceTenantId the emission source to retrieve data for
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getSubsidiaryEmissionData = async function (sourceTenantId, startDate, endDate, tag) {
    return await axiosInstance.get(`/app/reports/organisation-summary/emissions/${sourceTenantId}`, {params: {startDate, endDate, tag}}).then(res => res);
};

/**
 * @desc Retrieve the number of on-track and off-track targets for a given source tenant
 * 
 * @param {int} tenantId the emission source to retrieve data for
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getOrganisationTargetsByTenantId = async (tenantId) => {
    return await axiosInstance.get(`/app/reports/organisation-summary/targets/${tenantId}`).then(res => res);
};

/**
 * @desc Retrieves list of sites for a given source tenant
 * 
 * @param {int} tenantId the emission source to retrieve data for
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getOrganisationSitesByTenantId = async (tenantId) => {
    return await axiosInstance.get(`/app/reports/organisation-summary/sites/${tenantId}`).then(res => res);
};


/**
 * @desc Generates and Downloads Emission Factor Spreadsheet
 * @param reqBody object containing date range
 * 
 * @returns {Promise<AxiosResponse<any>>}
 * @throws {Object} an object containing the status and message
 * {
 *   status: 500,
 *   data: "Error..." 
 * }
 */
const getEmissionFactorsReport = async function(reqBody) {
    return axiosInstance({
        url: `/app/reports/emission-factor-report`,
        method: 'GET',
        responseType: 'blob',
        params: reqBody,
        timeout: 60000 // Timeout after 1 min
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const startDate = formatInLocaleShortDate(reqBody.startDate);
        const endDate = formatInLocaleShortDate(reqBody.endDate);

        const dateString = startDate + (startDate === endDate ? "" : `_to_${endDate}`);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `emission_usage_spreadsheet_${dateString}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(async (error) => {
        const errorMessage = JSON.parse(await error.response.data.text());
        throw {
            status: error.response.status,
            data: errorMessage
        };
    })
}


/**
 * @desc Generates and Downloads Accounting Scope Report
 * @param {Object} queryParams Contains information to generate report
 * @param {String} queryParams.reportType The type of report to generate, 'detail' or 'summary'
 * @param {String} queryParams.selectedRange The range selected by user to compare to
 * @param {String} queryParams.comparisonRanges The other ranges to compare to the selected range 
 * 
 * @returns {Promise<AxiosResponse<any>>}
 * @throws {Object} an object containing the status and message
 * {
 *   status: 500,
 *   data: "Error..." 
 * }
 */
const getAccountingScopeReport = async function (queryParams) {
    return axiosInstance({
        url: `/app/reports/accounting-scope-report`,
        method: 'GET',
        responseType: 'blob',
        params: queryParams,
        timeout: 60000 // Timeout after 1 min
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const endDate = formatInLocaleShortDate(queryParams.selectedRange.end)
        const startDate = queryParams.comparisonRanges.length ? formatInLocaleShortDate(queryParams.comparisonRanges[queryParams.comparisonRanges.length - 1].start) : formatInLocaleShortDate(queryParams.selectedRange.start)

        link.setAttribute('download', `Emissions ${queryParams.reportType} (${startDate} - ${endDate}).xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(async (error) => {
        const errorMessage = JSON.parse(await error.response.data.text());
        throw {
            status: error.response.status,
            data: errorMessage
        };
    })
}

/**
 * Uploads a Toitu template file for the system to scrape and prepare for generating a report.
 * 
 * @param {File} file The template file.
 * @param {Object} dateRange An object containing the startDate and endDate.
 * @param {Array} emissionSources An array containing the selected emission sources
 * @returns {Promise}
 */
const uploadToituTemplate = async (file, dateRange, emissionSources) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("dateRange", JSON.stringify(dateRange));
    formData.append("emissionSources", emissionSources);
    
    return axiosInstance.post(`/api/reports/toitu/emanage/template`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const getEmissionFactorsInUseToitu = async  (toituMappings, dateRange) => {
    return await axiosInstance.post(
        `/api/reports/toitu/mappings/data-types/used`, {toituMappings, dateRange})
        .then((res) => res);
}

const getToituEmanageBulkData = async (dateRange, emissionSources, mappingId) => {
    try {
        const response = await axiosInstance({
            url: `/api/reports/toitu/emanage`,
            method: 'POST',
            responseType: 'blob',
            data: {
                emissionSources: emissionSources,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate
            },
            params: {
                mappingId
            },
            timeout: 60000 // Timeout after 1 min
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `Toitū emanage Bulk Data (${formatInLocaleShortDate(dateRange.startDate)} - ${formatInLocaleShortDate(dateRange.endDate)}).xlsx`);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        const errorMessage = (await error.response.data.text()).replaceAll('"', '');
        throw {
            status: error.response.status,
            data: errorMessage
        };
    }
}

/**
 * @desc Checks to see if mappings are available for a tennant
 * @returns {Boolean} - True if there are mappings available
 */
const checkSavedMappingsAvailable = async () => {
    return await axiosInstance.get(`/app/reports/toitu/mappings-available`).then((res) => res);
}

/**
 * @desc Gets the latest toitu mapping object
 * @returns {Object} - The latest toitu mapping object
 */
const getLatestToituMapping = async () => {
    return await axiosInstance.get(
        `/app/reports/toitu/mapping/latest`)
        .then((res) => res);
}

/**
 * @desc Checks to see if mappings are available for a tennant
 * @returns {Boolean} - True if there are mappings available
 */
const checkAllFactorsMapped = async (dateRange, emissionSources) => {
    return await axiosInstance.post(`/api/reports/toitu/mappings/are-mapped`, { emissionSources: emissionSources, startDate: dateRange.startDate, endDate: dateRange.endDate }).then((res) => res);
}


/**
 * @desc Updates a given Toitu mapping
 */
const updateToituMapping = async (mappingId, toituMappings) => {
    return await axiosInstance.put(`/api/reports/toitu/emanage/mappings/${mappingId}`, {toituMappings}).then((res) => res);
}


export default {
    getSubsidiaryEmissionData,
    getOrganisationTargetsByTenantId,
    getOrganisationSitesByTenantId,
    getEmissionFactorsReport,
    getAccountingScopeReport,
    uploadToituTemplate,
    getEmissionFactorsInUseToitu,
    checkSavedMappingsAvailable,
    getToituEmanageBulkData,
    updateToituMapping,
    checkAllFactorsMapped,
    getLatestToituMapping
}
