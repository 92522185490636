import html2pdf from "html2pdf.js";

/**
 * @desc Exports html page to a pdf using html2pdf
 * 
 * @param {String} exportDivId The id of the html component to export
 * @param {String} filename The name of the file to export
 * @param {Array} pagebreakAfter An array which has the identifiers of components to pagebreak after
 * @param {Array} pagebreakBefore An array which has the identifiers of components to pagebreak before
 * see https://ekoopmans.github.io/html2pdf.js/#page-break-settings
 * 
 */
export default async function (exportDivId, filename, pagebreakAfter = [], pagebreakBefore = []) {
    await html2pdf(document.getElementById(exportDivId), {
        margin: 4,
        image: {type: 'jpeg', quality: 1},
        filename: `${filename}.pdf`,
        html2canvas: {
            scale: 5
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak : {before: [...pagebreakBefore], after: [...pagebreakAfter]}
    }).set({});
}

