<template>
  <!-- Tag Selector -->

  <q-select
    class="dropdown"
    :loading="tagsLoading"
    v-model="selectedTags"
    dropdown-icon="expand_more"
    option-value="name"
    hide-bottom-space
    multiple
    outlined
    dense
    options-dense
    display-value="Tags"
    emit-value
    map-options
    :options="availableTags"
  >

  <template v-slot:before-options>
    <q-item>
      <q-item-section>
      <q-item-label>Select All</q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-checkbox :model-value="selectAll" @update:model-value="selectAllTags()"/>
      </q-item-section>
    </q-item>

    <q-separator />
  </template>

  <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
    <q-item v-bind="itemProps">
      <q-item-section>
      <q-item-label v-html="opt" />
      </q-item-section>
      <q-item-section side>
        <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
      </q-item-section>
    </q-item>
  </template>	
	</q-select>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

const TagSelectionComponent = {
  name: "TagSelectionComponent",
  data() {
    return {
      //Tags
      tagsLoading: false,
      selectAll: false,
      availableTags: [],
      selectedTags: [],
    };
  },
  created() {
    this.getAvailableTags();
  },

  watch: {
    selectedTags() {
      this.$emit("updateSelectedTags", this.selectedTags);
    },
  },

  methods: {
    async getAvailableTags() {
      this.tagsLoading = true

      try {
        let response = await api.tags.getTags();
        this.availableTags = response.data.map(tag => tag.name);
      } catch (err) {
        notify.error("Unable to retrieve tags.", "top", "Tags")        

      } finally {
        this.tagsLoading = false
      }       
    },

    selectAllTags() {
			this.selectAll = !this.selectAll
			
			if (this.selectAll) {
				this.selectedTags = this.availableTags;
			} else {
				this.selectedTags = []
			}
		},
  },
};

export default TagSelectionComponent;
</script>
<style scoped src="../../assets/styles/data.less" lang="less" />