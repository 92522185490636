<template>
	<div class="subscriptions-container">
		<div class="subscriptions-info-container">
			<div id="current" class="subscriptions-header">Your Current Subscription</div>
			<div v-if="!this.loading && currentPlan" id="current-card" class="subscription-info-card">
				<q-card>
					<q-card-section>
						<div class="text-h6">{{ currentPlan.name }}</div>
						<text class="subscription-price">${{ currentPlan.price }} </text>
						<text class="subscription-period"> + GST / month </text>
						<div v-if="currentPlan.next.cancelAt" class="subscription-subtitle">Your subscription will be cancelled on {{ formatDate(currentPlan.next.cancelAt) }}</div>
						<div v-else-if="!currentPlan.next.subscription" class="subscription-subtitle"><span>Your next payment will be on the {{ formatDate(currentPlan.next.bill) }}</span></div>
						
					</q-card-section>

					<q-card-actions align="center" >
						<q-btn v-if="currentPlan.next.cancelAt" class="resubscribe-btn" outline no-caps @click="selectionClick(currentPlan.id)">Resubscribe</q-btn>
						<q-btn v-else-if="!currentPlan.next.subscription" class="cancel-btn" outline no-caps @click="cancelSubscription()">Cancel</q-btn>
					</q-card-actions>
				</q-card>
			</div>
	
			<template v-if="!this.loading && currentPlan?.next.subscription">
				<div id="change-icon" >
					<q-icon name="east" size="6em" />
				</div>
				
				<div id="downgrading"  class="subscriptions-header">Downgrading To</div>
				<div id="downgrade-card" class="subscription-info-card">
					<q-card>
						<q-card-section>
							<div class="text-h6">{{ currentPlan.next.subscription.name }}</div>
							<text class="subscription-price">{{ currentPlan.next.subscription.price }} </text>
							<text class="subscription-period"> + GST / month </text>
							<div class="subscription-subtitle">You will be charged this price on the {{ formatDate(currentPlan.next.bill) }}</div>
						</q-card-section>

						<q-card-actions align="center">
							<q-btn class="cancel-btn" outline no-caps @click="resubscribeClick()">Cancel</q-btn>
						</q-card-actions>
					</q-card>
				</div>
			</template>
		</div>

		<div v-if="loading"
			style="margin: 50px 0; justify-self: center; align-self: center;"
		>
			<q-spinner
				color="deep-purple-4"
				size="5em"
			/>
		</div>


		<div class="subscription-plans-list">
			<div class="subscriptions-header">Change Plans</div>
			<!-- Loading Spinner -->
			<div
				v-if="loading"
				class="home-loading-spinner-container"
				style="margin: 50px 0; justify-self: center; align-self: center;"
			>
				<q-spinner
					color="deep-purple-4"
					size="5em"
				/>
			</div>

			<div class="plans-container" v-if="!loading && currentPlan">

				<SubscriptionPlanCard
					v-for="plan in plans"
					:key="plan.subscriptionTierId"
					:id="plan.subscriptionTierId"
					:buttonExists="
						currentPlan.name !=
						plan.subscriptionName
					"
					:class="
						currentPlan.name !=
						plan.subscriptionName
							? 'plan-card'
							: 'plan-card subscription-card-glow'
					"
					:disabled = "currentPlan.next.subscription?.id == plan.subscriptionTierId"

					:buttonText="isDowngrade(plan) ? 'Downgrade' : 'Upgrade'"
					:data="plan"
					@selected="selectionClick(plan.subscriptionTierId)"
				/>


				<q-dialog
					v-model="showResubscribe"
					persistent
				>
					<q-card>
						<q-card-section>
							<div class="text-h6 text-bold">Resubscribe</div>
							<div class="subscription-subtitle">You are resubscribing to the {{selectedPlan?.subscriptionName}} plan</div>
							<div class="q-mt-md text-h7" >Charge Today:</div>
							<text class="subscription-price">$0</text>

							<div >Your next charge will be <span class="purple-text">${{this.selectedPlan?.subscriptionPrice}} + GST</span> on the {{ formatDate(this.currentPlan.next.bill) }}</div>
						</q-card-section>

						<q-card-actions align="right">
							<q-btn
								label="Cancel"
								v-close-popup
							/>
							<q-btn
								label="Resubscribe"
								color="secondary"
								@click="updateSubscription(); this.showResubscribe=false;"
								v-close-popup
							></q-btn>
						</q-card-actions>
					</q-card>
				</q-dialog>



				<q-dialog
					v-model="showConfirmation"
					persistent
				>
					<q-card>
						<q-card-section>
							<div class="text-h6 text-bold">Change Your Plan</div>
							<div class="subscription-subtitle">You are changing your plan to the {{selectedPlan?.subscriptionName}} plan</div>
							
							<div class="q-mt-md text-h7" >Charge Today:</div>
							<text class="subscription-price">${{ isDowngrade(selectedPlan) ? 0 : selectedPlan?.subscriptionPrice}} </text>
							<text class="subscription-period">{{ isDowngrade(selectedPlan) ? '' : ' + GST' }}</text>
							<div >Your next charge will be <span class="purple-text">${{this.selectedPlan?.subscriptionPrice}} + GST</span> on the {{ formatDate(this.currentPlan.next.bill) }}</div>
						</q-card-section>

						<q-card-actions align="right">
							<q-btn
								label="Cancel"
								v-close-popup
							/>
							<q-btn
								label="Change"
								color="secondary"
								@click="updateSubscription(); this.showConfirmation=false;"
								v-close-popup
							></q-btn>
						</q-card-actions>
					</q-card>
				</q-dialog>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/services/api/api";
import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";
import notify from "@/services/util/notify";

import SubscriptionPlanCard from "@/components/IntroComponents/PricingCardComponent.vue";
import { sortBy } from "lodash/collection";
import { format } from "date-fns";

const ManageSubscriptionsComponent = {
	name: "ManageSubscriptions",
	components: { SubscriptionPlanCard },
	data() {
		return {
			userRole: getRole(),
			permissions: permissions,
			loading: false,

			selected: "",

			plans: [],
			currentPlan: undefined,
			selectedPlan: undefined,

			showConfirmation: false,
			showResubscribe: false,
		};
	},

	created() {
		this.init();
	},

	methods: {

		/**
		 * Formats date string to the required format
		 * @param {String} dateString string of date to be formated 
		 */
		formatDate(dateString) {
			return format(new Date(dateString), "do 'of' MMMM yyyy")
		},


		/**
		 * Checks if a plan is a downgrade compared to the current plan by comparing price
		 * @param {Object} plan the plan to check 
		 */
		isDowngrade(plan) {
			return plan.subscriptionPrice < this.currentPlan?.price
		},


		/**
		 * @desc Load the list of subscriptions and the current plan the user's tenancy is
		 * subscribed to.
		 */
		async init() {
			this.loading = true;

			try {
				// Get the users current subscription infomation
				let response = await api.users.getSubscriptionInfo();
				this.plans = sortBy(response?.data, 'subscriptionTierId');
				
				// Get the current plan of the user
				this.currentPlan =  (await api.users.getCurrentSubscription()).data;
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.loading = false;
			}
		},

		/**
		 *	@desc emit method for price card components. Determines that a user has selected a new plan.
		 * 	Brings up a confirmation popup for the user to confirm their new plan selection.
		 *	@param {*} plan the name of the plan the user has selected.
		 */
		async selectionClick(planId) {
			if (!this.permissions.manageSubscriptions.includes(this.userRole)) {
				notify.error(
					`You must be a master user to edit subscriptions.`,
					"top"
				);
				return;
			}
			for(let plan of Object.values(this.plans)){
				if(plan.subscriptionTierId == planId){
					this.selectedPlan = plan;
				}
			}

			if (this.currentPlan.id === planId) {
				this.updateSubscription()
			} else {
				this.showConfirmation = true;
			}
			
		},

		/**
		 *	@desc emit method for price card components. Determines that a user has selected a new plan.
		 * 	Brings up a confirmation popup for the user to confirm their new plan selection.
		 *	@param {*} plan the name of the plan the user has selected.
		 */
		async resubscribeClick() {
			if (!this.permissions.manageSubscriptions.includes(this.userRole)) {
				notify.error(
					`You must be a master user to edit subscriptions.`,
					"top"
				);
				return;
			}
			for(let plan of Object.values(this.plans)){
				if(plan.subscriptionTierId === this.currentPlan?.id){
					this.selectedPlan = plan;
				}
			}
			this.showResubscribe = true;
		},

		async cancelSubscription () {
			this.loading = true;

			try {
				const response = await api.users.deleteSubscription();
				notify.primary(response.data.message, "top", "Cancelled Subscription");

				this.currentPlan = (await api.users.getCurrentSubscription()).data;
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.loading = false;
			}
		},


		/**
		 * @desc Updates the tenant's subscription tier 👍👍.
		 */
		async updateSubscription(){
			this.loading = true;
			let payload = {
				subscriptionId: this.selectedPlan.subscriptionTierId,
			};

			try {
				const newSubscription = await api.users.patchSubscriptions(payload);
				notify.primary(newSubscription.data.message, "top", "Update Successful");

				// Update auth token with new subscription tier
				localStorage.setItem(
						"authToken",
						newSubscription.data.authToken
				);

				this.currentPlan = (await api.users.getCurrentSubscription()).data;
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.loading = false;
				this.selectedPlan = undefined;
				this.emitter.emit('newToken');
			}
		}
	},
};

export default ManageSubscriptionsComponent;
</script>

<style scoped lang="less" src="../../assets/styles/manageSubscriptions.less"/>
