<template>
    <div class="transparent scope-graph">
            <div class="no-data" v-if="Object.keys(graphData).length === 0" >
                <q-icon name="query_stats" size="5em" ></q-icon>
                <span>No Chart Data</span>
            </div>

            <div v-else class="graph">
                <Bar :chartData="formattedChartData(this.graphData)" :chartOptions="barChartOptions"/>
            </div>
            
    </div>
</template>
<script>

import Bar from '@/components/ChartComponents/Bar.vue';

const ReportBarGraph = {
    

    name: "ReportBarGraph",
    props: ["graphData", "visibleLabels"],
    components: {
        Bar,
    },
    methods: {
        /**
        * @desc Formats Bar Chart data
        * @param {Object} data the chart data to display
        */
        formattedChartData(data) {
            const labels = Object.entries(data).map(([emission, value]) => [emission, `(${(value / 1000).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} t)`]); 
            const total = Object.values(data).reduce((a, b) => a + b, 0);

            let dataObject = {};
            let labelArray = []

            if (this.visibleLabels) {
                for (const key of this.visibleLabels) {
                    if (Object.prototype.hasOwnProperty.call(data, key)) {
                        dataObject[key] = data[key]
                    }
                }

                for (const label of labels) {
                    if (this.visibleLabels.includes(label[0])) {
                        labelArray.unshift(label)
                    }

                    // Because of some monkey business, we need to sort the label arrays to be in the order they'll appear in the graph - which is descending order.
                    labelArray.sort(function(a, b) {
                        return parseFloat(b[1].replace(/[^\d.-]/g, '')) - parseFloat(a[1].replace(/[^\d.-]/g, ''));
                    });
                }

            } else {
                dataObject = data
                labelArray = labels
            }

            let datasets = [
                {
                    data: Object.values(dataObject).map(val => val / total * 100),
                    backgroundColor: [
                        '#40ad8a',
                        '#5bf7c5',
                        '#786bf7',
                        '#4074ad',
                        '#5ba5f7',
                        '#5b7ff7',
                        '#4059ad',
                        '#9059f7',
                        '#59edf7',
                        '#3ea6ad',
                    ],
                    datalabels: {
                        labels: {
                            percentage : {
                                anchor: 'end',
                                align: 'end',
                                font: {
                                    weight: 'bold',
                                    size: 14
                                },
                                color: "black",
                                formatter: (value) => {
                                    return value.toFixed(2) + '%';
                                }
                            },
                        }
                    }
                },
            ]
            return {
                labels: labelArray,
                datasets: datasets
            }
        },
    },

    computed: {
        /**
         * Report Bar Graph Options
         */
        barChartOptions() {
            return {
                indexAxis: 'y',
                maxBarThickness: 70,
                layout: {
                    padding: {
                        right: 50,
                    } 
                },
                maintainAspectRatio: false,
                plugins: {
                    colors: {
                        enabled: true
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: function(context) {
                                return context[0].label.substring(0, context[0].label.indexOf(","))
                            },
                        }
                    }
                },
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            crossAlign: 'far',
                            font: {
                                size: 14,
                            },
                            color: "black",
                        },
                        
                    }
                },
                
            }
        }
    }
}

export default ReportBarGraph;
</script>
<style lang="less" scoped src="@/assets/styles/report.less"/>