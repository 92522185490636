<template>
  <div class="dynamic-inputs">
    <!-- Input components can be q-select / q-input -->
    <component
      v-for="(input, key) in inputs"
      :key="key"
      :is="input.component"
      :model-value="modelValue?.[key]"
      @update:model-value="value => userInputs[key] = value"
      v-bind="input.props"
      :option-label="evaluateProp(input.props?.['option-label'])"
      :rules="evaluateProp(input.props?.rules)"
    />
  </div>
</template>

<script>
import AddressInput from "@/components/FormComponents/AddressInput.vue";

export default {
  components: {
    AddressInput,
  },
  props: {
    inputs: {
      type: Object,
      required: true,
    },
    modelValue: {
      required: true,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      userInputs: {},
    }
  },

  created() {
    this.userInputs = structuredClone(this.modelValue);
    this.$emit("update:modelValue", this.userInputs);
  },
  
  methods: {
    evaluateProp(prop) {
      const evaluatedProp = eval(prop)
      if (!evaluatedProp) {
        return prop;
      }
      return evaluatedProp;
    }
  },

  watch: {
    userInputs: {
      handler() {
        this.$emit("update:modelValue", this.userInputs);
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
.dynamic-inputs {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
</style>
