<template>
    <div v-if="validId" :style="{ 'margin-left': sidebarWidth }">
        <div v-if="store.dataView=='default'">
            <CustomDataset @toggleView="store.changeView('trace')"/>
        </div>
        <div v-else>
            <TraceabilityViewComponent/>
        </div>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import TraceabilityViewComponent from "@/components/DataComponents/TraceabilityComponents/TraceabilityViewComponent.vue";
import CustomDataset from "@/components/DataComponents/CustomDatasetComponents/CustomDatasetComponent.vue";

import { useChartStateStore } from '@/stores/chartState.store';

import api from "@/services/api/api";
import notify from "@/services/util/notify";


export default {
    name: "custom-dataset-view",
    components: { CustomDataset, TraceabilityViewComponent },
    title: 'custom-dataset',
    setup() {
        const store = useChartStateStore();
        store.changeView("default");
        return { store, sidebarWidth };
    },

    data() {
        return {
            validId: false,
        }
    },


    /**
     * For custom datasets, we need to set the CustomDatasetId before fetching the graph data. Therefore, we need to ensure all is well
     */
    created() {
        api.customDatasets.getCustomDatasets(`?id=${this.$route.params.datasetId}`).then(() => {
            this.store.addCustomDatasetId(this.$route.params.datasetId);
            this.store.fetchGraphData('custom', 'month');
            this.validId = true
        }).catch((err) => {
            let errMessage = err.response.data ?? "Error connecting to server.";
			notify.error(errMessage, "top");
            this.$router.push({ name: 'viewCustomDatasets' })
        })
    }
};
</script>
