import { defineStore } from 'pinia'
import {ref} from "vue";
import { useSiteStateStore } from './siteState.store';
import api from "@/services/api/api";
import axios from 'axios';

export const useChartStateStore = defineStore('label', () => {
	const defaultEndDate = new Date();
	const defaultStartDate = new Date().setFullYear(defaultEndDate.getFullYear()-1)
  const selectedLabel = ref("");
  const selectedIndex = ref();
  const selectedUnit = ref("");
  const chartData = ref({});

  const emissionSourceView = ref("");
  const cancelController = ref();

  const dataView = ref("");
  const datasetIndex = ref("");
  const fileId = ref();
  const customDatasetId = ref();
  const loadingChartData = ref(false);
  const filterStartDate = ref(formatDateToString(defaultStartDate));
  const filterEndDate = ref(formatDateToString(defaultEndDate));
  const filterGranularity = ref("month");
  const filterDisplayGranularity = ref("Month");
	const unitConversionMap = ref({});

  /**
   * @desc Clears store and resets back to default values
   */
  function $reset() {
    selectedLabel.value = ""
    selectedIndex.value = null
    selectedUnit.value = ""
    chartData.value = {}
    emissionSourceView.value = ""
    cancelController.value = null
    dataView.value = ""
    datasetIndex.value = ""
    fileId.value = null
    customDatasetId.value = null
    loadingChartData.value = false
    filterStartDate.value = ""
    filterEndDate.value = ""
    filterGranularity.value = "month"
    filterDisplayGranularity.value = "Month"
  }


  function addUnit(unit) {
    selectedUnit.value = unit;
  }

  function addFileId(id){
    fileId.value = id
  }
  function addDatasetIndex(index){
    datasetIndex.value = index
  }

  function addLabel(label) {
    selectedLabel.value = label;
  }

  function addIndex(index){
    selectedIndex.value = index;
  }

  function changeView(view){
    dataView.value = view;
  }

  function addCustomDatasetId(id) {
    customDatasetId.value = id;
  }

  function setFilterStartDate(startDate) {
    filterStartDate.value = startDate;
  }

  function setFilterEndDate(endDate) {
    filterEndDate.value = endDate;
  }

  function setFilterGranularity(granularity) {
    filterGranularity.value = granularity;
  }

  function setFilterDisplayGranularity(granularity) {
    filterDisplayGranularity.value = granularity;
  }



  //This function is used to hydrate the store with the right emissionSource data
  async function fetchGraphData (emissionSource, granularity, view, startDate, endDate, tags=null, period=null) {  
    if (!emissionSource) {
      emissionSource = emissionSourceView.value;
    }

    // Create Abort Controller
    let controller = cancelController.value;
    if (controller) {
      controller.abort();
    } 
    controller = new AbortController();
    cancelController.value = controller;

    const siteStore = useSiteStateStore()
    const siteId = siteStore.siteId

    let data;
    const params = { 
      siteId: siteId,
      view: view,
      startDate: startDate,
      endDate: endDate,
      period: period, 
      tags: tags
    };

    try {
      loadingChartData.value = true;
      if (view === 'year') {
        data = await api.emissions.getYearOnYearChartData(emissionSource, siteId, tags, controller.signal);
      } else {
        data = await api.emissions.getEmissionChartData(emissionSource, granularity, params, controller.signal);
      }

      if (!emissionSourceView.value || emissionSourceView.value === emissionSource) {
        chartData.value = data.data.chartData;
      }

      loadingChartData.value = false;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.info('Request Canceled:', error.message);
      } else {
        console.error(error);
        chartData.value = {};
        loadingChartData.value = false;
      }
    } 
  }

  function removeGraphData() {
    chartData.value = {}
  }

	/**
	 * @desc Formats date to dd/mm/yyyy with day and month fields
	 * padded with 0's if < 10.
	 */
	function formatDateToString(date) {
		date = new Date(date);
		return `${(`0${date.getDate()}`).slice(-2)}/${(`0${date.getMonth()+1}`).slice(-2)}/${date.getFullYear()}`;
	}

	async function getUnitConversions (chartData) {
		Object.keys(chartData).forEach(async (unit) => {
			const compatibleUnits = (await api.units.getConvertableUnits(unit)).data
			compatibleUnits.forEach((compatibleUnit) => {
				unitConversionMap.value[unit] ??= {}
				unitConversionMap.value[unit][compatibleUnit.toSymbol] = compatibleUnit; 
			})
		})
	}

  return {
    customDatasetId,
    fileId,
    datasetIndex,
    dataView,
    chartData,
    selectedLabel,
    selectedIndex,
    selectedUnit,
    emissionSourceView,
    loadingChartData,
    filterStartDate,
    filterEndDate,
    filterGranularity,
    filterDisplayGranularity,
    addFileId,
    addDatasetIndex,
    addUnit,
    addLabel,
    addIndex,
    fetchGraphData,
    changeView,
    addCustomDatasetId,
    removeGraphData,
    setFilterStartDate,
    setFilterEndDate,
    setFilterGranularity,
    setFilterDisplayGranularity,
    $reset,
	unitConversionMap, 
	getUnitConversions 
  };
})
