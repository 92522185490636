/**
 * fertiliser.js
 * 
 * API for handling getting all fertiliser associated data
 */

import axiosInstance from "./axios-instance";


/**
 * @desc Retrieves fertiliser chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFertiliserChartData = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/fertiliser/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Generate fertiliser CSV file
 * 
 * @param {Number} siteId contains query for filtering
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFertiliserCSV = async function (siteId) {
    return axiosInstance({
        url: `/app/fertiliser/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `fertiliser-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Get the total fertiliser usage in the past year.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearlyUsage = async function (siteId) {
    return await axiosInstance.get(`/app/fertiliser/insights/year-total`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Retrieve total fertiliser usage in past year converted to equivalent barrels.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearlyEmissions = async function (siteId) {
    return await axiosInstance.get(`/app/fertiliser/insights/yearly-emissions`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Get total fertiliser usage converted to emissions in the past year.
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTotalEmissions = async function (siteId) {
    return await axiosInstance.get(`/app/fertiliser/insights/total-emissions`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Get total cost spent on fertiliser in past year
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMostUsed = async function (siteId) {
    return await axiosInstance.get(`/app/fertiliser/insights/most-used`, {params: {siteId}}).then(res => res);
}


export default {
    getFertiliserChartData,
    getFertiliserCSV,
    getYearlyUsage,
    getYearlyEmissions,
    getTotalEmissions,
    getMostUsed,
}