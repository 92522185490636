<template>
	<div class="home-container">
		<q-dialog v-model="showAddSiteDialog" persistent>
			<q-card id="onboarding-add-site-card">
				<span>Please create your first site to get started</span>
				<CreateSiteComponent @close="successfullyAddSite()" />
			</q-card>
		</q-dialog>

		<div class="home-container-data-exists">
			<div class="home-header-info">
				<h1 class="home-title">
					Dashboard Overview
					<q-btn flat round dense icon="info" class="navbar-help-button" style="padding-bottom: 5px;" @click="this.showDashboardHelp = true" />
				</h1>
				<p v-if="!dataExists && !loading">
					Hello, your dashboard is currently empty. We need your data
					to get started. Below you will see cards for the emission sources you have choosen for the given site. 
					Click the card to be taken to their respective data page. From here, you can choose to manually upload data or connect a data link.
				</p>
				<q-btn-toggle class="date-toggle" v-model="dateRange" text-color="black" toggle-color="secondary" no-caps
					padding="5px 50px" toggle-text-color="white" :options="[
						{ label: 'Weekly', value: 'Weekly' },
						{ label: 'Monthly', value: 'Monthly' },
						{ label: 'Yearly', value: 'Yearly' },
					]" />
			</div>

			<div class="home-carbon-output-container">
				<div>
					<h2 class="home-carbon-output-heading">
						{{ homeHeader }}
						<q-icon class="q-pb-xs" name="help">
							<q-tooltip class="text-body2" anchor="center right" self="center left" :offset="[10, 10]">
								{{ homeTooltip }}
							</q-tooltip>
						</q-icon>
					</h2>

					<div class="home-carbon-output-header">
						<div class="home-carbon-output-flex-item">
							<q-btn v-if="dataView === 'carbon'" class="emission-factors-btn" label="View Emission Factors"
								@click="showEmissionFactors = !showEmissionFactors" flat no-caps padding="none" />
							<q-dialog v-model="showEmissionFactors">
								<EmissionFactorComponent />
							</q-dialog>

							<div class="insights-finance-filter q-pa-none" v-if="dataView == 'finance'">
								<q-select v-model="financePrice" :options="this.financeCosts" option-label="name"
									class="emission-factors-btn">
								</q-select>
								
								<q-input v-if="isCustom"
								label="Set custom price"
								type="number"
								step="any"
								outlined
								min="0"
								v-model="financePrice.price">

								</q-input>
							</div>

						</div>
						<div class="home-carbon-output-flex-item">
							<InsightTabsComponent class="insight-tabs" @tab-selection="(tab) => dataView = tab" />
						</div>
						<div class="home-carbon-output-flex-item">
							<LiveCarbonComponent v-if="subPermissions.liveCarbonPrice.includes(subscription)" class="carbon-price" />
						</div>
					</div>
				</div>

				<HomeInsightsComponent keep-alive :dateRange="dateRange" :key="siteId" :siteId="siteId" :tab="dataView"
					:carbonPrice="financePriceValue" :subscription="subscription" />

				

				<EmissionContributorsComponent style="height: 50vh;" v-if="dataExists" :sourceIds="tenantConnections" :granularity="dateRange" :siteId="siteId" />
				<EmissionContributorsComponent style="height: 50vh;" v-if="!dataExists && !loading" :sourceIds="[]" />

			</div>

		</div>

		<SideModalComponent v-model="this.showDashboardHelp">
			<DashboardHelpComponent
				@close="this.showGoalProgressModal = false"
			/>
		</SideModalComponent>

	</div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import api from "../services/api/api";
import notify from "@/services/util/notify";
import getRole from "@/services/util/role";
import getSub from "@/services/util/subscription.js";
import roles from "@/roles";
import subPermissions from "@/subPermissions.js";
import rolePermissions from "@/rolePermissions.js";

import HomeInsightsComponent from "./HomeComponents/HomeInsightsComponent.vue";
import HomeProportionsComponent from "./HomeComponents/HomeProportionsComponent.vue";
import LiveCarbonComponent from "./HomeComponents/LiveCarbonPriceComponent.vue";

import CreateSiteComponent from './SiteComponents/SidePanels/CreateSiteComponent.vue';

import InsightTabsComponent from '@/components/InsightTabsComponent.vue';
import EmissionContributorsComponent from '@/components/DashboardComponents/EmissionContributorsComponent.vue';
import EmissionFactorComponent from "@/components/DataComponents/EmissionFactorComponent";
import SideModalComponent from './SideModalComponent.vue';
import DashboardHelpComponent from '@/components/HomeComponents/DashboardHelpComponent.vue';

const HomeComponent = {
	name: "Home",
	components: {
		HomeInsightsComponent,
		HomeProportionsComponent,
		LiveCarbonComponent,
		CreateSiteComponent,
		InsightTabsComponent,
		EmissionContributorsComponent,
		EmissionFactorComponent,
		SideModalComponent,
		DashboardHelpComponent
	},

	data() {
		return {
			userRole: getRole(),
			subscription: getSub(),
			subPermissions: subPermissions,
			roles: roles,
			siteStore: undefined,
			HomeComponentSettings: {},
			showAddSiteDialog: false,
			loading: false,
			firstLoading: true,
			loadSuccess: false,
			chartLoaded: false,
			dataExists: false,
			dateRange: "Monthly",
			slide: 1,

			dataView: "",
			showEmissionFactors: false,

			tenantConnections: [],

			// This var determines how big the text of the q-tooltips (that exist when an emission
			// source's proportion is < 10% of total co2e emissions) are displayed.
			tooltipSizeRem: 1.2,
			totalEmissions: {},
			total: 0,

			financePrice: {},
			financeCosts: [{ name: 'Current Carbon Price', price: 0 },
			{ name: "Custom", price: 0 }],

			showDashboardHelp: false,
		};
	},


	async created() {
		this.onInitialize();

		this.financePrice = this.financeCosts[0];
		this.siteStore = useSiteStateStore();
	},


	computed: {
		siteId() {
			return useSiteStateStore().siteId
		},

		loaded() {
			return this.siteStore?.loaded
		},

		isCustom() {
			return this.financePrice.name == "Custom"
		},

		financePriceValue(){
			return this.financePrice.price;
		},

		homeHeader(){
			let header = '';
			switch(this.dataView){
				case "carbon":
					header = "Carbon Output";
					break;
				case "finance":
					header = "Financial Impact of Emissions";
					break;
				case "trees":
					header = "Emissions Impact by Trees";
					break;
				case "cars":
					header = "Emissions Impact by Cars";
					break;
				default:
					header = "Carbon Output";
					break;
			}
			
			return header;
		},

		homeTooltip(){
			let tooltip = '';

			let dayObject = {
				'Weekly': 7,
				'Monthly': 30,
				'Yearly': 365
			}

			switch(this.dataView){
				case "carbon":
					tooltip = `Your carbon emissions for the past ${this.dateRange.slice(0, -2).toLowerCase()}. 
					The percentage change shows how much your emissions have changed from the past ${dayObject[this.dateRange]} days compared to the ${dayObject[this.dateRange]} days prior.`
					break;
				case "finance":
					tooltip = `The financial impact of your emissions. 
					This view shows in financial terms how much you would save/spend based on your reduction/increase in emissions. This value is based on the price of offsetting a tonne of carbon. `
					break;
				case "trees":
					tooltip = `This view shows how many trees you are effectively planting/removing for a ${this.dateRange.slice(0, -2).toLowerCase()} based on your reduction/increase in emissions. 
					This value is based on the average carbon consumption of a tree - 27kg of carbon per year  `
					break;
				case "cars":
					tooltip = `This view shows how many cars you are effectively adding to/removing from the road for a ${this.dateRange.slice(0, -2).toLowerCase()} based on your reduction/increase in emissions. 
					This value is based on the average carbon emissions of a car - 4.6 tonnes of carbon per year`
					break;
				default:
					tooltip = `Your carbon emissions for the past ${this.dateRange.slice(0, -2).toLowerCase()}. 
					The percentage change shows how much your emissions have changed from the past ${dayObject[this.dateRange]} days compared to the ${dayObject[this.dateRange]} days prior.`
					break;
			}
			
			return tooltip;
		}


	},

	watch: {
		loaded() {
			if (this.siteStore.loaded == true && this.siteStore.siteList.length < 1) {
				this.showAddSiteDialog = true
			}
		}
	},

	methods: {
		/**
		 * @desc On load up, retrieve the required data from the backend
		 */
		async onInitialize() {
			this.loading = true;

			
			// Only get an set the carbon price if subscription tier allows it
			if (subPermissions.liveCarbonPrice.includes(this.subscription)) {
				const carbonPriceRes = (await api.carbon.getCarbonPrice()).data;

				if(carbonPriceRes.length < 1){
					notify.error(`Couldn't retrieve current carbon price.`, "top");
				}else{
					//this.financeCosts[0] should always be the current carbon price.
					this.financeCosts[0].price = carbonPriceRes[0].price;
				}
			}

			// Determine if we should make a request for the guest orgs of this tenancy.
			if (subPermissions.manageOrganisations.includes(this.subscription) && 
				rolePermissions.manageOrganisationConnections.includes(this.userRole)) {

				const guestTenantResponse = (await api.tenantConnections.getTenantConnections("guest")).data;

				for (let conn of guestTenantResponse) {
					if (conn.status) {
						this.tenantConnections.push(conn.sourceTenantId);
					}
				}
			}


			// Syncronously Get the Rest of the Information For the Page
			let query = this.queryBuilder();
			let gets = [
					api.data.checkDataExists(),
					api.users.getUserSettings(),
			]

			const fetchData = function () {
				return Promise.all(gets);
			};

			await fetchData(query)
				.then(async (response) => {
					this.dataExists = response[0].data;

					this.HomeComponentSettings = response[1].data.userSettings.homecomponent ?? {};
					this.HomeComponentSettings.firstTime = this.HomeComponentSettings.firstTime ?? true;


					this.loadSuccess = true;
					if (this.dataExists) { this.retrieveTotalEmissionsData() }
				})
				.catch((error) => {
					console.error(error);
					notify.error(error?.response?.data ?? "Error loading home page data", "top");
				})
				.finally(() => {
					this.loading = false;
					this.firstLoading = false;
				});
		},


		/**
		 * @desc If the data exists, retrieve total emission data
		 */
		async retrieveTotalEmissionsData() {
			let query = this.queryBuilder(this.dateRange)
			await api.data.getTotalEmissionPastDays(query, this.siteId)
				.then((response) => {
					this.totalEmissions = response.data

					this.total = Object.values(this.totalEmissions).reduce((a, b) => a + b, 0);

				})
				.catch((error) => {
					let errorMsg =
						error.response.data ??
						"Error connecting to server. Please try again later.";
					notify.error(errorMsg, "top");
				})
		},


		async successfullyAddSite() {
			this.showAddSiteDialog = false;
			this.HomeComponentSettings.firstTime = false;
			await api.users.addUserSettings({ homecomponent: this.HomeComponentSettings });
		},


		/**
		 * @desc Determines how many days back the query should use based on what this.dateRange is.
		 * @returns {Number} number of days to go back
		 */
		queryBuilder() {
			let numDaysBack = 0;
			switch (this.dateRange) {
				case "Monthly":
					numDaysBack = 30;
					break;
				case "Yearly":
					numDaysBack = 365;
					break;
			}
			return numDaysBack;
		},
	},
};

export default HomeComponent;
</script>

<style lang="less" src="@/assets/styles/home.less" scoped />
