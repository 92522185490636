<template>
	<div class="invoice-upload-container">


		<h2 class="tab-section-heading">Upload Invoice</h2>
		<div v-if="showSplitInvoiceComponent">
			<EditInvoice :emissionSource="this.emissionSource" :invoiceData="invoiceData" @submit-allocations="submitAllocations"/>
		</div>
		<div v-else>
			<p class="action-heading">Select the invoice you want to upload. Please make sure you have selected the site that the invoice is for</p>
			<div style="display:flex">
				<q-select class="invoice-select-dropdown" outlined v-model="invoiceSelection" :options="invoices"/> 
					<q-checkbox v-if="flags.FLAG_4873_SPLIT_INVOICE" v-model="shouldSplitInvoice" label="Split invoice data"></q-checkbox>
			</div>
			<p v-if="invoiceSelection.includes('Waste Management')"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/> Please note that rows with a Unit of Measurment (UOM) of "EA", the weight of the bin is calulated using average bin weights in Christchurch between 2021/22.</p>
			<p v-if="invoiceSelection.includes('JJ Richards')"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/> Please note that the weight of the bins are calulated using the average bin weights in Christchurch between 2021/22.</p>
			<p v-if="invoiceSelection.includes('WasteCo')"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/> Please note that 
				the weight of the bins are calulated using the average bin weights in Christchurch between 2021/22. 
				If your invoice contains WasteCo bin types that GreenHalo doesn't support yet, feel free to send your invoice to support@greenhalo.nz
				to request this bin type be added.
			</p>
			<p v-if="this.emissionSource == 'electricity'"  style="color: red;"> <q-icon name="warning" class="q-mr-m" size="1.5rem"/>Please note that Electricity invoices can contain multiple sites per invoice. Can you please link your ICPs to their corresponding sites using the table at the bottom of the page.</p>

			<p>Our invoice upload feature is something we are continuously improving! If you have any issues uploading your invoice, send a copy of your invoice through to support@greenhalo.nz and we will get it sorted as soon as possible. Thanks for helping us improve GreenHalo!</p>

			<span class="action-heading">Upload your invoice below</span>
			<div class="upload-manual-csv">
				<q-file class="upload-files justify-center items-center" v-model="files" type="file" ref="files" multiple max-files="10" counter :counter-label="({totalSize, filesNumber, maxFiles}) => `${filesNumber} files of ${maxFiles} | ${totalSize}`"
					label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
					:item-aligned=true>

					<template v-if="files" v-slot:prepend>
						<q-icon name="cancel" @click.stop.prevent="files = null" class="cursor-pointer" />
					</template>
				</q-file>

				<div class="manual-button-center">
					<q-btn class="file-input-button" no-caps color="green" :disabled="this.files == null || this.noSiteMapping" @click="uploadFile"
						label="Upload" icon-right="upload" :loading="loading" />
				</div>
			</div>

			<div v-if="flags.FLAG_4545_ICP_NUMBERS && ['stationary-fuel', 'electricity'].includes(this.emissionSource)">
				<ViewICPNumberComponent :emissionSource="this.emissionSource"></ViewICPNumberComponent>
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/services/api/api';
	import notify from '@/services/util/notify';
	import { useSiteStateStore } from '@/stores/siteState.store';

	import ViewICPNumberComponent from '../DataComponents/ViewICPNumberComponent.vue';
	import EditInvoice from './SplitInvoiceComponents/EditInvoiceComponent.vue';


	export default {
		components: {
			ViewICPNumberComponent,
			EditInvoice,
		},  

		props: ["emissionSource"],
		data() {
			return {
				//Invoice splitting related data
				shouldSplitInvoice: false,
				showSplitInvoiceComponent: false,
				invoiceData: {},
				splitOptions: {},
				optionsObject: {},
				accrualRange: {},
				invoiceSelection: null,
				files: null,
				loading: false,
			}
		},
		created() {
			this.invoiceSelection = this.invoices[0];
		},
		computed: {
			invoices() {
				switch (this.emissionSource) {
					case "waste":
						var companies = ["Waste Management - 2023", "EnviroWaste"];
						if (this.flags.FLAG_4561_JJRICHARDS_INVOICE) companies.push("JJ Richards");
						if (this.flags.FLAG_5049_WASTECO) companies.push("WasteCo");
						return companies;
					case "air-travel":
						return ["Air New Zealand"];
					case "transport-fuel":
						return ["Allied Petroleum"];
					case "electricity":
						if (this.flags.FLAG_4525_GENESIS_INVOICE) {
							return ["Ecotricity", "Meridian", "Genesis"];
						}
						return ["Ecotricity", "Meridian"];
					case "stationary-fuel":
						return ["Novagas"];
					case "water":
						if (this.flags.FLAG_4744_CCC_INVOICE){
							return ["CCC Water", "CCC Wastewater"];
						}
						return null;
					default:
						return null;
				}
			},
			siteId() {
				return useSiteStateStore().siteId;
			},
		},
		methods: {


			/**
			* @desc Handles the submission of allocation
			* @param {Object} optionsObject - Options for the allocation.
			*/
			async submitAllocations(optionsObject, accrualRange) {
				try {
					this.loading = true;
					this.optionsObject = optionsObject;
					this.accrualRange = accrualRange
					await this.uploadFile()
				} catch (error) {
					notify.error(error.message, "top")
				} finally {
					this.showSplitInvoiceComponent = false;
					this.loading = false;
				}
			},


			/**
			* @desc Attempts to upload the invoice, handling split and single invoice accordingly.
			*/
			async uploadFile() {
				this.loading = true;
				try {
					if (this.shouldSplitInvoice) {
						if (Object.getOwnPropertyNames(this.invoiceData).length === 0) {
							await this.uploadAndRetrieveSplitData();
						} else {

							await this.uploadSplitData();
							this.files = null
						}
					} else {
						await this.uploadSingleInvoice();
						this.files = null
					}
				} catch (error) {
					if (error.response.status === 400) {
						notify.warning(error.response.data, "top")
					} else {
						notify.error(error.response.data, "top")
					}
				} finally {
					this.loading = false;
				}
			},


			/**
			* @desc Uploads a invoice that has been requested to be split. 
			* @returns {Object} - The supplied invoice in split version
			*/
			async uploadAndRetrieveSplitData() {
				const uploadRes = await api.emissions.uploadEmissionInvoiceSplit(
					this.files[0],
					this.invoiceSelection,
					this.siteId
				);
				this.showSplitInvoiceComponent = true;
				this.invoiceData = uploadRes.data;
				notify.primary("Successfully retrieved split invoice data", "top");
				return uploadRes;
			},


			/**
			 * @desc Uploads invoice data that has been split between sites
			 * @return {Object} - The upload response 
			 */
			async uploadSplitData() {
				try { 
					const payload = {
					parsedData: this.invoiceData.parsedData,
					accrualRange: this.accrualRange,
					options: this.optionsObject
					}

					const uploadRes = await api.emissions.uploadEmissionSplitData(
					this.files[0],
					payload,
					this.invoiceSelection
					);
					this.invoiceData = {}

					notify.primary("Successfully uploaded split invoice data", "top");

					return uploadRes
				} catch (error) {
					notify.error("Unable to upload split data. Please try again later.");
				}
			},


			/**
			* @desc Uploads a single invoice file and handles the response.
			* @returns {Object} - The upload response.
			*/
			async uploadSingleInvoice() {
				const uploadRes = await api.emissions.uploadEmissionInvoice(
					this.files,
					this.invoiceSelection,
					this.siteId
				);

				notify.primary("Successfully uploaded invoice data")

				return uploadRes;
			},
		}

	}
</script>

<style lang="less" scoped src="@/assets/styles/invoiceUpload.less" />
