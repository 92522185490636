<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <FuelDataSourceOptionsComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import FuelDataSourceOptionsComponent from "@/components/DataComponents/ConnectDataSourceComponents/Fuel/FuelOptionsComponent.vue";

export default {
    name: "fuel-data-source-options-view",
    components: { FuelDataSourceOptionsComponent },
    title: 'FuelDataSourceOptions',
    setup() {
    return { sidebarWidth }
    }
};
</script>


<style >

</style>