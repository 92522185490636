
/**
 * company.js
 *
 * API for handling creating, and getting company information
 */
import axiosInstance from './axios-instance'

/**
 * @desc Gets company financial year setting
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFinancialYearSetting = function () {
    return axiosInstance.get(`/api/company/financial-year`);
};


/**
 * @desc Sets company financial year setting
 * @returns {Promise<AxiosResponse<any>>}
 */
const setFinancialYearSetting = function (body) {
    return axiosInstance.put(`/api/company/financial-year`, body);
};



export default {
    getFinancialYearSetting,
	setFinancialYearSetting,
}
