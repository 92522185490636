<template>
  <div class="container">
    <stripe-checkout ref="checkoutRef" mode="subscription" :pk="stripeKey" :line-items="lineItems" :customerEmail="email"
      :success-url="successURL" :cancel-url="cancelURL"/>
  </div>
</template>
    
<script>

import { StripeCheckout } from "@vue-stripe/vue-stripe";
import bcrypt from "bcryptjs"

const StripeCheckoutComponent = {
  name: "StripeCheckoutPage",
  components: {
    StripeCheckout,
  },

  data() {
    return {
      bcrypt: bcrypt,
      type: "credential",
      step: 1,
      subscriptionId: 3,
      signupObject: {},
      stripeSubscriptionKeys: {},

      sectionDone: {
        1: false,
        2: false,
        3: false
      },

      /* Stripe related vars */
      stripeKey: "",
      stripe: false,
      stripeLoading: false,

      hash: ""
    };
  },

  created() {
    if(process.env.VUE_APP_ENV_MODE == "PROD"){
      this.stripeSubscriptionKeys = {
        1: process.env.VUE_APP_PRICE_ID_BASIC,
        2: process.env.VUE_APP_PRICE_ID_SMALL,
        3: process.env.VUE_APP_PRICE_ID_PRO,
      };

      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN;
    }else{
      this.stripeSubscriptionKeys = {
        1: process.env.VUE_APP_PRICE_ID_BASIC_TEST,
        2: process.env.VUE_APP_PRICE_ID_SMALL_TEST,
        3: process.env.VUE_APP_PRICE_ID_PRO_TEST,
      };
      
      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN_TEST;
    }
    this.signupObject = JSON.parse(localStorage.getItem('stripeInfo'));

    this.generateHash().then(() => this.paymentScreen())

  },


  computed: {
    lineItems() {
      return [
        {
          price: this.stripeSubscriptionKeys[this.signupObject.subscription],
          quantity: 1,
        }
      ]
    },

    successURL() {
      return window.location.host.includes("localhost") ? 'http://' + window.location.host + '/subscription/checkout-complete/' + this.hash : 'https://' + window.location.host + '/subscription/checkout-complete/' + this.hash 
    },

    cancelURL() {
      return window.location.host.includes("localhost") ? 'http://' + window.location.host + '/signup' : 'https://' + window.location.host + '/signup'
    },


    email() {
        return localStorage.getItem('email')
      }
  },


  methods: {
    async generateHash() {
      const secret = process.env.VUE_APP_STRIPE_SECRET

      var salt = bcrypt.genSaltSync(10);
      var hash = bcrypt.hashSync(secret, salt);
 


      while (!/^[^/]*[a-zA-Z0-9][^/]*$/.test(hash)) {
        salt = bcrypt.genSaltSync(10);
        hash = bcrypt.hashSync(secret, salt);
      }
      this.hash = hash
        return
    },

    async start() {
      await this.paymentScreen()
    },
    //Stripe Methods
    /**
     * @desc Brings up the Stripe payment screen for the user
     */
    async paymentScreen() {
      this.stripe = true;
      this.stripeLoading = true;
      await new Promise(r => setTimeout(r, 333));
      try {
        this.$refs.checkoutRef.redirectToCheckout();
      } catch (err) {
        console.error(err);
      }

    },

  },
};

export default StripeCheckoutComponent;
</script>
    