<template>
    <div>
        <div class="container-fluid px-0">
            <div class="row align-items-center">
                <div class="col-md-3"></div>
                <div class="col-md-6 text-center order-1 order-md-2">
                    <div class="row justify-content-center">
                        <div class="col-10 col-lg-6 blurb mb-5 mb-md-0 text-center">

                            <div class="text-center" style="padding-top: 10px">
                                <q-img src="../assets/greenhalologo.png" width="50%"></q-img>
                            </div>

                            <div class="text-center" style="padding-top: 10px">
                                <q-img src="../assets/404-broken.png" width="50%" alt=""/>
                            </div>

                            <div>
                                <h3 class="intro-header">Page Not Found</h3>
                                <p class="lead">Uh Oh . . . it looks like the page you are looking for doesn't exist. Please either go back to the previous page, or the intro page. </p>
                            </div>

                            <div class="intro-list">
                                <q-btn color="green" no-caps label="Go Back" @click="this.$router.go(-1)"/>
                                <q-btn outlined no-caps label="Go to Login" @click="this.$router.push({path: '/auth/login' })"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <p>© GreenHalo 2023 | Powered by Ember Technology</p>
        </div>
    </div>

</template>

<script>

export default {
    name: '404-view',
    
    title: 'Page Not Found'


}
</script>
