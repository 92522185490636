/**
 *
 * routes.js
 *
 *
 * Javascript file for handling routing on frontend application
 */

import { createRouter, createWebHistory } from "vue-router";
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import api from "./services/api/api";
import roles from "./roles";
import subscriptions from "./subscriptions";
import jwt from "jsonwebtoken";
import notify from "./services/util/notify";
import flags from './config/featureFlags.config.json';

import Main from "@/views/Main"

import Signup from "@/views/Signup";
import Home from "@/views/Home";
import Sites from "@/views/SiteViews/Sites";
import SiteEmissionsLeaderboard from "@/views/SiteViews/SiteEmissionsLeaderboard";

import WasteConnectionsList from "@/views/DataViews/ConnectDataSourceViews/Waste/WasteConnectionsList";
import ElectricityDataSourceOptions from "@/views/DataViews/ConnectDataSourceViews/Electricity/ElectricityOptions";
import AddICPMeter from "@/views/DataViews/ConnectDataSourceViews/Electricity/AddICPMeter";
import FuelDataSourceOptions from "@/views/DataViews/ConnectDataSourceViews/Fuel/FuelOptions";
import FuelConnectionsList from "@/views/DataViews/ConnectDataSourceViews/Fuel/FuelConnectionsList";
import FreightDataSourceOptions from "@/views/DataViews/ConnectDataSourceViews/Freight/FreightOptions";
import StationaryFuelSourceOptions from "@/views/DataViews/ConnectDataSourceViews/StationaryFuels/StationaryFuelsOptions.vue"
import WaterSourceOptions from "@/views/DataViews/ConnectDataSourceViews/Water/WaterOptions.vue"

import ViewDataset from "@/views/DataViews/Data";

import ViewCustomDatasets from "@/views/DataViews/CustomDatasetViews/ViewCustomDatasets";
import CustomDataset from "@/views/DataViews/CustomDatasetViews/CustomDataset";
import CreateCustomDataset from "@/views/DataViews/CustomDatasetViews/CreateCustomDataset";

import MileageClaims from "@/views/MileageClaims";

import ManualUpload from "@/views/ManualUpload";
import Audit from "@/views/Audit";
import AuditInfo from "@/views/AuditInfo"

import Files from "@/views/Files"

import ViewGoals from "@/views/GoalsViews/ViewGoals";

import Auth from "@/views/Auth";
import Settings from "@/views/Settings";

import StaffCommuteForm from "@/views/CommuteViews/StaffCommuteForm";
import ViewAllSurveys from "@/views/CommuteViews/ViewAllSurveys";
import SingleStaffCommuteSurvey from "@/views/CommuteViews/SingleCommuteSurvey"

import NotFound from "@/views/404";

import StripePayment from "@/views/Stripe"
import StripeComplete from "@/views/StripeComplete"

import EmissionsReportComponent from "@/components/ReportsComponents/EmissionsReportComponent";
import GraphicalScopeReportComponent from "@/components/ReportsComponents/GraphicalScopeReportComponent";


import GoalReportComponent from "@/components/ReportsComponents/GoalsReportComponent";

import Organisations from "@/views/Organisations";
import GuestLogin from "@/components/OrganisationComponents/GuestLoginComponent"

import CarbonOffset from "@/views/DataViews/CarbonOffset.vue"

import ResetPassword from "@/views/ResetPassword.vue";
import SupplierLoginComponent from "@/components/SupplierLoginComponent.vue";

import SupplierUpload from "@/components/SupplierComponents/Portal/SupplierUploadComponent";

import SupplierPortal from "@/views/SupplierPortal.vue";

import DataHub from "@/views/DataHub.vue"

const allRoutes = [

  { name: "signup", path: "/signup", component: Signup},
  { name: "stripeCheckout", path: "/checkout", component: StripePayment},
  { name: "stripeComplete", path: "/subscription/checkout-complete/:subscriptionHash", component: StripeComplete},

  { name: "demo", path: "/auth/demo", beforeEnter: () => {
      // After issues with login we are ensuring that when a demo user is created all 
      // prevous tokens and logins are cleared
      localStorage.clear();
      sessionStorage.clear();
      api.users
          .loginUserDemo()
          .then((res) => {
            localStorage.setItem("authToken", res.data.authToken);
            router.push({ name: "home"});
          })
          .catch((err) => {
            console.error("Error during authentication: " + err);
            notify.error("Sorry, the Demo site seems to be down at the moment. Please try again later.", "top");
            window.location.href = "http://www.greenhalo.nz";
        });
    },
  },

  {
    name: "resetPassword",
    path: "/auth/reset-password/:token",
    component: ResetPassword,
    beforeEnter: async (to, from, next) => {
      const token = to.params.token;
      
      if (!token) {
        next({path: "/404"});
      }

      try {
        await api.users.getPasswordReset(token);
        next();
      } catch {
        next({path: "/404"});
      }
    }
  },

  { path: "/auth", 
    redirect: () => {return { path: "auth/login" }} 
  },

  { name: "auth", path: "/auth/:method",
      async beforeEnter() {
      // If user is already logged in reroute to home
      if (localStorage.getItem("authToken") && (await api.users.verifyJWTToken()).data) {
        router.push({name: "home"});
      }
    },
    component: Auth
  },
  
  {
    name: "supplierLogin",
    path: "/auth/supplier/login",
    component: SupplierLoginComponent,
    meta: {
      featureFlag: flags.FLAG_4872_SUPPLIER_PORTAL
    }
  },

  {
    name: "supplierPortal",
    path: "/supplier",
    component: SupplierPortal,
    children: [
      {
        name: "supplierUpload",
        path: "/supplier/upload",
        component: SupplierUpload,
      }
    ],
    meta: {
      featureFlag: flags.FLAG_4872_SUPPLIER_PORTAL,
      requiresAuth: true,
      roles: [roles.Supplier],
    }
  },

  { name: "logout", path: "/logout",
    beforeEnter() {
      // Making sure that session and local storage is cleared on logout
      localStorage.clear();
      sessionStorage.clear();
      router.push({path: "/auth/login"})
    }
  },

  { name: "login", path: "/login",
    redirect: () => {
      return { path: "/auth/login" }
    }
  },

  //Rather than have the Sidebar and Navbar initialised with the App, there are only loaded in the following components
  { path: '/', component: Main,
    children: [
    {
      name: "home",
      path: "/",
      component: Home,
      meta: { requiresAuth: true }
    },

    {
      name: "dataHub",
      path: "/data-hub",
      component: DataHub,
      meta: {
        featureFlag: flags.FLAG_5036_BULK_UPLOAD,
        requiresAuth: true,
      }
    },

    {
      name: "sites",
      path: "/sites",
      component: Sites,
      defualt: true,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin] },
    },

    {
      name: "siteEmissionsLeaderboard",
      path: "/sites/emissions",
      component: SiteEmissionsLeaderboard,
      defualt: true,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Consultant, roles.User, roles.Demo, roles.Guest],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },
    
    {
      name: "electricityDataSourceOptionsList",
      path: "/data-sources/connect/electricity/options",
      component: ElectricityDataSourceOptions,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },
    
    {
      name: "wasteConnections",
      path: "/data-sources/connect/waste/options",
      component: WasteConnectionsList,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },
    
    {
      name: "addICPMeter",
      path: "/data-sources/connect/electricity/icp-meter",
      component: AddICPMeter,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },
    {
      name: "fuelDataSourceOptionsList",
      path: "/data-sources/connect/fuel/options",
      component: FuelDataSourceOptions,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "fuelDataSourceList",
      path: "/data-sources/fuel",
      component: FuelConnectionsList,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "freightDataSourceOptionsList",
      path: "/data-sources/connect/freight/options",
      component: FreightDataSourceOptions,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "stationaryFuelsOptionsList",
      path: "/data-sources/connect/stationary-fuels/options",
      component: StationaryFuelSourceOptions,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "WaterSourceOptionsList",
      path: "/data-sources/connect/water",
      component: WaterSourceOptions,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Demo],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "carbonOffset",
      path: "/data/carbon-offset",
      component: CarbonOffset,
      meta: {
        requiresAuth: true,
      }
    },

    {
      name: "viewDataset",
      path: "/data/:emissionSource",
      beforeEnter(to) {
        // Because carbon offset is a different page we have reroute
        if (to.params.emissionSource === 'carbon-offset') {
          router.push({ name: "carbonOffset" })
        }
      },
      component: ViewDataset,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.User, roles.Demo, roles.Guest] }
    },


    {
      name: "viewCustomDatasets",
      path: "/data/custom/all",
      component: ViewCustomDatasets,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.User, roles.Demo, roles.Guest] },
    },

    {
      name: "customDataset",
      path: "/data/custom/:datasetId",
      component: CustomDataset,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.User, roles.Demo, roles.Guest] },
    },

    {
      name: "createCustomDataset",
      path: "/data/custom-dataset/create",
      component: CreateCustomDataset,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Demo] },
    },

    {
      name: "audit",
      path: "/audit",
      component: Audit,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "graphicalScopeReport",
      path: "/graphical-scope-report",
      component: GraphicalScopeReportComponent,
      props: route => ({ startDate: route.query.startDate, endDate: route.query.endDate, includeConnected: (route.query.includeConnected === 'true'), tag: route.query.tag }),
      meta: { requiresAuth: true }
    },

    {
      name: "goalReport",
      path: "/goals-report",
      component: GoalReportComponent,
      props: route => ({ startDate: route.query.startDate, endDate: route.query.endDate, includeConnected: (route.query.includeConnected === 'true') }),
      meta: { requiresAuth: true }
    },

    {
      name: "auditInfo",
      path: "/audit/information",
      component: AuditInfo,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      }
    },
    
    {
      name: "targets",
      path: "/targets",
      component: ViewGoals,
      meta: {
        requiresAuth: true,
        roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.User, roles.Demo, roles.Guest],
        subscriptions: [subscriptions.SmallBusiness, subscriptions.Pro],
      },
    },

    {
      name: "mileage",
      path: "/mileage",
      component: MileageClaims,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Demo] },
    },

    {
      name: "commuteSurvey",
      path: "/survey/commute/surveys",
      component: ViewAllSurveys,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Demo] },
    },

    {
      name: "files",
      path: "/files/:folderId?",
      component: Files,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest] }
    },

    {
      name: "staffCommuteForm",
      path: `/survey/commute/:surveyHash/response`,
      component: StaffCommuteForm,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Demo] },

    },

    {
      name: "singleStaffCommuteSurvey",
      path: `/survey/commute/:surveyHash`,
      component: SingleStaffCommuteSurvey,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.User, roles.Demo] },

    },

    {
      name: "manualUpload",
      path: "/manual-upload",
      component: ManualUpload,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Editor, roles.Demo] },
    },

    {
      name: "settings",
      path: "/settings",
      component: Settings,
      meta: { requiresAuth: true, roles: [roles.TestMaster, roles.Admin, roles.Master] },

    },

    {
      name: "emissionSnapshot",
      path: "/emission-snapshot",
      component: EmissionsReportComponent,
      props: route => ({ basedOn: route.query.basedOn }),
      meta: {requiresAuth: true}
    },

    {
      name: "organisations",
      path: "/organisations",
      component: Organisations,
      meta: {
        requiresAuth: true,
        subscriptions: [subscriptions.Pro],
      },
    },

    {
      name: "guestLogin",
      path: "/guest-login",
      component: GuestLogin,
      meta: {
        requiresAuth: true,
        subscriptions: [subscriptions.Pro],
      },
    },
  ]},


  // You can't redirect to `*` path, so this notFound page needs to here
  { name: "notFound", path: '/404', component: NotFound, meta: { hideNavbar: true } },
  // Ensure this is the last route, essentially if route doesn't exist, redirect to 404 page
  { path: '/:pathMatch(.*)*', component: NotFound, meta: { hideNavbar: true } },
  
];

//VueRouter object to handle routing
const router = createRouter({
  history: createWebHistory(),
  routes: allRoutes,
});

var toSaved = '';

let appInsights

if (process.env.VUE_APP_ENV_MODE != "LOCAL") {

  let connection = process.env.VUE_APP_ENV_MODE == 'PROD' ? process.env.VUE_APP_INSIGHTS_PROD : process.env.VUE_APP_INSIGHTS_DEV

  appInsights = new ApplicationInsights({ config: {
    connectionString: connection
  } });

  appInsights.loadAppInsights();
}


/**
 * @desc Before each route, check if the route requires authentication.
 * @desc Before each route, check if any of the roles of the saved authToken match any of the roles of to.meta.roles[].
 */
router.beforeEach(async (to, from) => {

  // Track page views for dev and prod deployments
  if (process.env.VUE_APP_ENV_MODE != "LOCAL") {
    appInsights.trackPageView({
      name: to.name,
      uri: to.fullPath
    })
  }
  
  if (to.meta.featureFlag === false) {
    return "/404";
  }

  let token;
  if(sessionStorage.getItem("authToken")){
      token = sessionStorage.getItem("authToken");
  }else{
      token = localStorage.getItem("authToken");
  }
  let verified = false;
  if (to.meta.requiresAuth) {
    try {
      verified = (await api.users.verifyJWTToken()).data;
    } catch (error) {
      verified = false;
    }
    if (!verified) {
      if (sessionStorage.getItem("supplierToken")) {
        return true;
      }

      toSaved = to.path;
      // If the user isn't visiting the app for the first time (i.e. their token has timed out while they're still using the app)
      // display an error message to them.
      if(to.name && to.name != 'auth' && from.name){
        notify.warning("Your Session has expired. Please log in again.", "top", "You've been signed out")
      }
      sessionStorage.clear();
      localStorage.clear();
      return '/auth/login'
    }
    if (from.name === 'auth' && toSaved) {
      const goTo = toSaved;
      toSaved = '';
      return goTo
    }
  }

  if (sessionStorage.getItem("supplierToken")) {
    token = sessionStorage.getItem("supplierToken");
  }

  // Check if token role matches any destination page role (if exists)
  if (to.meta.roles) {
    // First we must check that localStorage.authToken exists. If it doesn't, we should push to intro.
    if (!token) {
      return '/auth/login';
    }

    const tokenFields = jwt.decode(token);
    const role = tokenFields.role;
    if (role != "master") {
      if (!to.meta.roles.includes(role)) {
        notify.error("Sorry, you currently do not have permissions to access this page.", "top")
        return from;
      }
    }
  }

  // Check if token subscription tier matches any destination page subscription tier restriction (if exists)
  if (to.meta.subscriptions) {
    // First we must check that localStorage.authToken exists. If it doesn't, we should push to intro.
    if (!token) {
      return '/auth/login';
    }

    const tokenFields = jwt.decode(token);
    const subscription = tokenFields?.subscription;

    if (!to.meta.subscriptions.includes(subscription)) {
      notify.error("Sorry, your current subscription tier does not allow access to this page.", "top")
      return from;
    }
  }
});

export default router;
