<template>
  <q-card class="file-action-card text-black">
    <q-card-section>
      <div class="title text-h5">{{ title }}</div>
    </q-card-section>

    <q-form
      @submit="onSubmit"
    >
      <q-card-section v-if="action === 'update'" class="update-options">
        <q-input v-model="fileName" class="file-name-input" label="File name" :rules="[ val => val.trim() && val.trim().length > 0 || 'Please enter a file name']" maxlength="50" />
        <q-select class="site-selection" v-model="selectedSites" :options="data.sites" label="Sites" filled multiple option-value="id" option-label="name" :rules="[val => val.length > 0 || 'Please select at least one site']"/>
      </q-card-section>
      
      <q-card-section class="message-container">
        <div class="text-subtitle2">Add comments below</div>
        <q-input class="message-area" borderless outlined v-model="message" label="Comments" type="textarea" lazy-rules maxlength="200"
          :rules="[ val => val.trim() && val.trim().length > 0 || 'Please enter a comment']"
        />
      </q-card-section>
  
      <q-card-actions class="buttons-card">
        <q-btn flat class="action-button cancel" label="Cancel" v-close-popup />
        <div>
          <q-btn flat :class="`action-button confirm ${confirmButtonState}`" :loading="waiting" type="submit" :disable="!formChanged && action === 'update'" label="Confirm" />
          <q-tooltip v-if="!formChanged && action === 'update'">This file has not been edited</q-tooltip>
        </div>
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>

export default {
  props: ["action", "data", "waiting"],

  data() {
    return {
      title: "",
      confirmButtonState: "",
      message: "",
      sites: [],
      selectedSites: [],
      fileId: this.data.fileId,
      fileName: "",
      fileType: "",
      originalFileName: "",
    }
  },
  computed: {
    formChanged() {
      if (this.originalFileName !== this.fileName.trim()) {
        return true;
      }

      // checks if the sites have changed
      if (this.data.siteIds) {
        const selectedSiteIds = this.selectedSites.map((site) => site.id)
        const difference = this.data.siteIds
          .filter((id) => !selectedSiteIds.includes(id))
          .concat(selectedSiteIds.filter((id) => !this.data.siteIds.includes(id)));
        if (difference.length > 0) {
          return true;
        }
      } else if (this.selectedSites.length > 0) {
        return true;
      }

      return false;
    }
  },
  created() {
    const i = this.data.fileName.lastIndexOf(".");
    if (i !== -1) {
      this.fileName = this.data.fileName.slice(0, i);
      this.fileType = this.data.fileName.slice(i);
    } else {
      this.fileName = this.data.fileName;
    }

    this.originalFileName = this.fileName;

    if (this.action === "delete") {
      this.title = "Delete File";
      this.confirmButtonState = "delete";
    } else if (this.action === "update") {
      this.title = "Edit File";
      this.confirmButtonState = "update";
      
      // Init selectedSites - [{id: ..., name: ...}, ...]
      if (this.data.siteIds) {
        for (const siteId of this.data.siteIds) {
          for (const site of this.data.sites) {
            if (siteId === site.id) {
              this.selectedSites.push({
                id: site.id,
                name: site.name
              });
            }
          }
        }
      }
    }
  },
  methods: {
    handleDeletion() {
      const data = {
        message: this.message.trim(),
      }

      this.$emit("delete-file", this.fileId, data);
    },

    handleUpdate() {
      const data = {
        fileName: (this.fileName + this.fileType).trim(),
        message: this.message.trim(),
        sites: this.selectedSites,
      }

      this.$emit("update-file", this.fileId, data);
    },

    onSubmit() {
      if (this.action === "delete") {
        this.handleDeletion();
      } else if (this.action === "update") {
        this.handleUpdate();
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/audit.less";
</style>