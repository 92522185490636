// subscription.js

import jwt from "jsonwebtoken";

/**
 * @desc Decodes the auth token in local storage, and grabs the subscription tier value.
 * @returns subscription tier name
 */
export default () => {
    let token = localStorage.getItem("authToken");
    if(!token){
        return undefined;
    }

    let fields = jwt.decode(token);
    return fields.subscription;
}
