<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <FuelConnectionsListComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import FuelConnectionsListComponent from "@/components/DataComponents/ConnectDataSourceComponents/Fuel/FuelConnectionsListComponent.vue";

export default {
    name: "list-fuel-sources-view",
    components: { FuelConnectionsListComponent },
    title: 'FuelConnectionsListComponent',
    setup() {
    return { sidebarWidth }
    }
};
</script>


<style >

</style>