<template>
  <div class="insight-tabs-container">
    <q-tabs v-model="tab" dense switch-indicator>
      <q-tab name="carbon" icon="insights" />
      <q-tab v-if="subPermissions.liveCarbonPrice.includes(this.subscription)" name="finance" icon="local_atm" />
      <q-tab name="trees" icon="forest" />
      <q-tab name="cars" icon="directions_car" />
    </q-tabs>
  </div>
</template>

<script>
import { ref } from 'vue';
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";

export default {
  data() {
    return {
      tab: ref("carbon"),
      subscription: getSub(),
      subPermissions: subPermissions,

    }
  },
  created() {
    this.$emit("tab-selection", this.tab);
  },
  watch: {
    tab(selection) {
      this.$emit("tab-selection", selection);
    }
  }
}
</script>

<style lang="less" scoped>

</style>