<template>
  <div class="tracability-graph-container">
    <div class="btn-left">
        <q-btn
          flat
          round
          icon="chevron_left"
          color="grey-6"
          size="xl"
          @click="decrementIndex">
      </q-btn>
    </div>
    <div class="tracability-graph-chart">
      <Line :chartData="formattedChartData" :chartOptions="lineOptions" />
    </div>
    <div class="btn-right">
      <q-btn
        flat
        round
        color="grey-6"
        icon="chevron_right"
        size="xl"
        @click="incrementIndex">
      </q-btn>
    </div>
   
  </div>
</template>

<script>
import { useChartStateStore } from '@/stores/chartState.store';

import Line from "../../ChartComponents/Line.vue";
import * as lineChartOptions from "../../ChartComponents/ChartOptions/LineOptions.js";
import ChartGenerator from "../../ChartComponents/chartGenerators/chart";

const TraceabilityGraphComponent = {
  name: "TraceabilityGraph",
  components: {
    Line
  },

  data() {
    return {
      store: useChartStateStore(),
    };
  },
  created() {

  },

    computed: {
      formattedChartData() {
        return ChartGenerator.getTraceabilityDataset(this.store.chartData[this.store.selectedUnit], this.store.selectedIndex, this.store.selectedLabel, this.store.datasetIndex)
      },

      lineOptions() {
        return lineChartOptions.getLineOptions("Date", this.store.selectedUnit, '', this.store.selectedUnit)
      },

      index() {
        return this.store.selectedIndex
      },

      label() {
        return this.store.selectedLabel
      }


  },
 
  methods: {
    incrementIndex(){
      if(this.index < Object.keys(this.store.chartData[this.store.selectedUnit]).length - 1){
        this.store.selectedIndex++;
      }
    },

    decrementIndex(){
      if(this.index > 0 ){
        this.store.selectedIndex--;
      }
    }
  }
};

export default TraceabilityGraphComponent;
</script>

<style scoped src="../../../assets/styles/traceability.less" lang="less" />
