<template>
		<!-- Loading Spinner -->
		<div
			v-if="loading"
			class="home-loading-spinner-container"
			style="margin: 100px 0"
		>
			<q-spinner
				color="deep-purple-4"
				size="5em"
			/>
		</div>

		<!-- Loaded page content -->
		<div v-if="!loading">
			<div class="title-bar">
				<span class="table-title">Your Sites</span>
				<q-space/>
				<div class="add-button">
					<q-btn
						no-caps
						color="deep-purple-4"
						label="Add New Site"
						icon="add"
						square
						padding="10px 20px"
						@click="showCreateSitePanel = true"
					/>
				</div>
			</div>

			
			<q-card class="sites-table">
				<q-table
					hide-pagination
					:rows-per-page-options="[0]"
					:rows="siteStore.siteList"
					:columns="columns"
					row-key="name"
				>
					<template v-slot:body-cell-icon="props">
						<q-td :props="props">
							<q-icon
								name="pin_drop"
								size="2rem"
								color="deep-purple-4"
							/>
						</q-td>
					</template>

					<template v-slot:body-cell-action="props">
						<q-td :props="props">
							{{ props.row.id }}
						</q-td>
					</template>

					<template v-slot:body-cell-edit="props">
						<q-td
							:props="props"
							style="width: 80px"
						>
							<q-btn
								padding="10px 20px"
								color="blue-6"
								label="Edit"
								no-caps
								flat
								@click="
									this.siteToEdit = props.row;
									this.showEditSitePanel = true;
								"
							/>
						</q-td>
					</template>

					<template v-slot:body-cell-remove="props">
						<q-td
							:props="props"
							style="width: 80px"
						>
							<q-btn
								color="deep-purple-4"
								flat
								no-caps
								label="Disable"
								padding="10px 20px"
								@click="
									this.siteToDelete = {
										id: props.row.id,
										name: props.row.name,
									};
									confirmDelete = true;
								"
							/>
						</q-td>
					</template>
				</q-table>
			</q-card>
		</div>

		<q-dialog
			v-model="confirmDelete"
			persistent
		>
			<q-card>
				<q-card-section class="row items-center">
					<span class="q-ml-md">
						Are you sure you want to disable
						{{ this.siteToDelete.name }}?
						<br><br>
						Emission activity assigned to this site will remain in GreenHalo
						and be shown when reporting.
					</span>
				</q-card-section>

				<q-card-actions align="center">
					<q-btn
						flat
						label="Cancel"
						color="primary"
						v-close-popup
					/>
					<q-btn
						flat
						label="Disable"
						color="deep-orange"
						@click="deleteSite()"
						v-close-popup
					></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>

		<SideModalComponent v-model="showCreateSitePanel">
			<CreateSiteComponent @close="showCreateSitePanel = false" />
		</SideModalComponent>

		<SideModalComponent v-model="showEditSitePanel" >
			<EditSiteComponent :site="siteToEdit" @close="showEditSitePanel = false"/>
		</SideModalComponent>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import CreateSiteComponent from "./SidePanels/CreateSiteComponent.vue";
import EditSiteComponent from "./SidePanels/EditSiteComponent.vue";
import {useSiteStateStore} from "@/stores/siteState.store"
import SideModalComponent from "../SideModalComponent.vue";

const SitesComponent = {
	name: "Sites",

	components: {
		CreateSiteComponent,
		EditSiteComponent,
		SideModalComponent,
	},

	data() {
		return {
			siteStore: useSiteStateStore(),
			loading: false,
			columns: [
				{
					name: "icon",
					style: "width: 30px",
					align: "left",
				},

				{
					name: "name",
					align: "left",
					label: "Site Name",
					field: (row) => row.name,
				},
				{
					name: "edit",
					align: "right",
				},
				{
					name: "remove",
					align: "right",
				},
			],
			siteToEdit: { id: -1, name: "null" },

			siteToDelete: { id: -1, name: "null" },
			confirmDelete: false,

			showEditSitePanel: false,
			showCreateSitePanel: false,
		};
	},

	created() {
	},

	methods: {


		/**
		 * @desc Deletes a selected site, and removes it from the sites list.
		 */
		deleteSite() {
			api.sites
				.deleteSite(this.siteToDelete.id)
				.then(() => {
					notify.primary("Successfully disabled site.", "top");
					// Delete site from local sites list.
					this.siteStore.getSiteList()
					this.siteToDelete = { id: -1, name: "null" };
				})
				.catch((err) => {
					notify.withObject(err.response);
				});
		},
	},
};

export default SitesComponent;
</script>

<style lang="less" scoped src="../../assets/styles/sites.less" />
