<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <AuditInformationComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import AuditInformationComponent from "../components/AuditComponents/AuditInformationComponent.vue";

export default {
    name:  "audit-info-view",
    components: { AuditInformationComponent },
    title: 'audit-info',
    setup() {
        return { sidebarWidth }
    }
};
</script>