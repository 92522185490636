/**
 * file.js
 *
 * API for handling creating, and getting files
 */
import axiosInstance from './axios-instance'

/**
 * @desc Sends a file in multipart/form data form to /user/files
 * 
 * @param {Object} file file data to upload
 * @param {String} emissionSource the emission source that the file relates to
 * @param {String} fileType the type of file to be uploaded
 * @param {Array} siteIds array of site Ids to link file to
 * @returns {Promise<AxiosResponse<any>>}
 */
const postFile = function (file, emissionSource, siteIds, fileType = "manual", customDatasetId = undefined,) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType);
    formData.append('siteIds', siteIds);
    if (customDatasetId) {
        formData.append('customEmissionSourceId', customDatasetId);
    }

    return axiosInstance.post(`/api/files/${emissionSource}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};

/**
 * @desc Sends a file in multipart/form data form to /user/files
 * 
 * @param {Object} file file data to upload
 * @param {Number} folderId folder to upload to
 * @param {String} fileType the type of file to be uploaded
 * @returns {Promise<AxiosResponse<any>>}
 */
const postDocument = function (file, folderId, fileType = "manual") {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('folderId', folderId);
    formData.append('fileType', fileType);

    return axiosInstance.post(`/api/documents`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

/**
 * @desc rename a general file with fileId.
 * 
 * @param {Number} fileId id of the file
 * @param {Object} fileName the new name for the file
 * @returns {Promise<AxiosResponse<any>>}
 */
const renameDocument = async (fileId, fileName) => {
    return await axiosInstance.put(`/api/documents/${fileId}/rename`, { fileName }).then(res => res);
};

/**
 * @desc Deletes a genral non-emission file
 * 
 * @param {Object} fileId the ID of the file to delete
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteDocument = function (fileId) {
    return axiosInstance.delete(`/api/documents/${fileId}`)
}


/**
 * @desc Gets the file information for all files
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFileInformationById = async function (fileIds) {
    return await axiosInstance.get(`/api/files/info`, { params: { fileIds } }).then(res => res);
}

/**
 * 
 * @desc Sends a file with a custom emission source as multipart/form data to /user/files
 * 
 * @param {Object} file file data to upload 
 * @param {Number} emissionSourceId the custom emission source Id 
 * @param {String} fileType the type of file to be uploaded
 * @returns {Promise<AxiosResponse<any>>}
 */
const postCustomFile = function (file, emissionSourceId, siteId, fileType = "manual") {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', fileType);
    formData.append('siteId', siteId);
    formData.append('customEmissionSourceId', emissionSourceId);

    return axiosInstance.post(`/api/files/Custom`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
};


/**
 * @desc Gets the file information for all files
 * 
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllFileInformation = async function (siteId) {
    return await axiosInstance.get(`/api/files`, { params: { siteId } }).then(res => res);
}


/**
 * @desc Gets the file information for a specific file type
 * 
 * @param {String} emissionSource carbon emission source to retrieve data for
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllFileInformationByType = async function (emissionSource, siteId) {
    return await axiosInstance.get(`/api/files/${emissionSource}`, { params: { siteId } }).then(res => res);
}

/**
 * @desc Gets the file information for a specific custom file type
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllCustomFileInformationByType = async function (customEmissionSourceId, siteId) {
    return await axiosInstance.get(`/api/files/Custom/${customEmissionSourceId}`, { params: { siteId } }).then(res => res);
}

/**
 * @desc Attempt to download a single file
 * 
 * @param {Number} fileId id of the file to download
 * @param {String} fileName name of file to use for download name
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSingleFile = async function (fileId, fileName) {
    return await axiosInstance({
        url: `/api/files/download/${fileId}`,
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}

/**
 * @desc Attempt to delete a single file
 * 
 * @param {Number} fileId id of the file to download
 * @param {Object} data object containing data for the FileTrace
 * {
 *   message,
 * }
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteFile = async function (fileId, data) {
    return await axiosInstance.delete(`/api/files/${fileId}`, {data}).then(res => res)
}

/**
 * @desc Gets all the data related to file with fileId
 * 
 * @param {Number} fileId id of the file
 * @param {String} emissionSource the emission source of data to get from the file
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFileDataById = async function (fileId, emissionSource) {
    return await axiosInstance.get(`/app/file/${fileId}/data`, { params: { emissionSource } }).then(res => res);
}

/**
 * @desc Gets file trace data for an emission source
 * 
 * @returns {Array of file trace objects}
 */
const getLatestFileTraceByEmissionSource = async function (emissionSource) {
    return await axiosInstance.get(`/api/history/${emissionSource}`).then(res => res);
}

/**
 * @desc Gets the latest 100 file trace data
 * 
 * @returns {Array of file trace objects}
 */
const getLatestFileTraceByCustomEmissionSource = async function (emissionSource) {
    return await axiosInstance.get(`/api/history/custom?emissionSource=${emissionSource}`).then(res => res);
}

/**
 * @desc Gets the latest 100 file trace data
 * 
 * @returns {Array of file trace objects}
 */
const getFileTraceById = async function (fileId) {
    return await axiosInstance.get(`/api/history/` + fileId).then(res => res);
}

/**
 * @desc Update file data with fileId.
 * 
 * @param {Number} fileId id of the file
 * @param {Object} data data containing new fields and message for the FileTrace
 * {
 *   fileName,
 *   message,
 *   sites,
 * } 
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateFileDataById = async (fileId, data) => {
    return await axiosInstance.put(`/api/files/${fileId}`, data).then(res => res);
};

/**
 * Retreives a file's data as arraybuffer
 * 
 * @param {string|number} fileId 
 * @returns {Promise<AxiosResponse<any>>} 
 */
const getRawFile = async (fileId) => {
    return await axiosInstance.get(`/api/file/${fileId}/raw`, { responseType: "arraybuffer", }).then(res => res);
};

/**
 * Deletes all emission data related to a file.
 * 
 * @param {string|number} fileId - The file's ID 
 * @returns {Promise<AxiosResponse<any>>} 
 */
const deleteFileData = async (fileId) => {
    return await axiosInstance.delete(`/api/file/${fileId}/data`).then(res => res);
};


export default {
    postFile,
    postCustomFile,
    getAllFileInformation,
    getAllFileInformationByType,
    getAllCustomFileInformationByType,
    getSingleFile,
    deleteFile,
    getFileInformationById,
    getFileDataById,
    getLatestFileTraceByEmissionSource,
    getLatestFileTraceByCustomEmissionSource,
    getFileTraceById,
    updateFileDataById,
    postDocument,
    renameDocument,
    deleteDocument,
    getRawFile,
    deleteFileData,
}