<template>
    <div class="manage-icp-container">
        <div>
            <div class="manage-icp-header">
                <h2 class="tab-section-heading">Manage ICP Numbers</h2>
                <div class="manage-icp-sub-header">
                    <p>You can manage your ICP number connections below</p>
                </div>
            </div>
        </div>

        <div class="datasource-list-container">
            <div class="content-item center-column icp-connection-table">
                <q-btn
                    no-caps
                    text-color="white"
                    color="deep-purple-4"
                    icon="add"
                    label="Add a new ICP site link"
                    square
                    padding="5px 10px" 
                    @click="showAddICPNumber = true"
                    >
                    <q-tooltip class="text-body2">
                    Link your ICP numbers to a corresponding site.
                    </q-tooltip>
                </q-btn>
                <q-table :rows="icpList" :columns="columns" :loading="loading" :pagination="{ rowsPerPage: 10 }" row-key="name">
                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props">
                            <q-btn
                            icon="delete"
                            flat
                            color="deep-orange"
                            @click="deleteICPNumber(props.row.id)"
                            ></q-btn>
                        </q-td>
                        </template>
                </q-table>
            </div>
        </div>

        <q-dialog v-model="showAddICPNumber">
			<q-card id="add-icp-link-card">
				<AddICPNumberComponent @close="showAddICPNumber = false" @refreshTable="getICPNumbers" :emission="this.emissionSource" />
			</q-card>
		</q-dialog>

    </div>
</template>

<script>

import api from "@/services/api/api";
import notify from "@/services/util/notify";

import AddICPNumberComponent from "./AddICPNumberComponent.vue";
import { useSiteStateStore } from "@/stores/siteState.store";

const ViewICPNumberComponent = {
    name: "ViewICPNumberComponent",

    components: {
        AddICPNumberComponent
    },

    props: ["emissionSource"],

    data() {
        return {
            loading: false,

            showAddICPNumber: false,

            icpList: [],

            columns: [
            { name: "icp", style: "width: 25%", align: "left", label: "ICP", field: "ICP", sortable: true },
            { name: "siteName", style: "width: 25%", align: "left", label: "Site", field: "siteName", sortable: true },
            { name: "created", style: "width: 25%", label: "Created", align: "left", field: "created", format: (date) => new Date(date).toLocaleDateString(undefined, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour12: false
            }), },
            { name: "emission", align: "left", label: "Emission", field: "emission" },
            { name: "actions",  style: "width: 10%",  label: "",  align: "left",  field: "" },
            ]
        };
    },

    created() {
        this.getICPNumbers()
    },

    computed: {
        siteId() {
            return useSiteStateStore().siteId
        }
    },

    watch: {
        siteId() {
            this.getICPNumbers();
        }
    },

    methods: {
        /**
         * @desc Retrieves a list of EIE Registry Retailers and their identifiers
         */
        async getICPNumbers() {
            this.loading = true;
            try {
                var response = await api.icp.retrieveICPNumbers(this.emissionSource, this.siteId)
                this.icpList = response.data
            }catch (error) {
                notify.error("Error loading ICP numbers", "top");
            } finally {
                this.loading = false;
            }
        },

        /**
         * @desc Deletes an existing ICP number
         */
        async deleteICPNumber(id) {
            try {
                await api.icp.deleteICPNumber(id)
                notify.withObject({status: 200, data: "Successfully delete ICP number"})
            }catch (error) {
                notify.error("Error deleting ICP number", "top");
            } finally {
                this.getICPNumbers();
            }
        }
    }
};

export default ViewICPNumberComponent;
</script>

<style scoped>
@import "../../assets/styles/viewDataSources.less";
</style>