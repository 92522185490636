<template>
	<div>
		<div>
			<q-card-section>
				<h2 class="bulk-upload-mapping-title">Emission Factors</h2>
			</q-card-section>
			<q-card-section>
				<p>
					Please map your emission factor names to GreenHalo's emission factors.
					You may also choose which units to upload each emission factor as.
				</p>
			</q-card-section>
		</div>

		<q-table
				class="bulk-upload-mapping-table"
				:columns="tableColumns"
				:rows="tableRows"
				:rows-per-page-options="[0]"
				row-key="fileValue"
				virtual-scroll
				>
				<template #body-cell-seperator="props">
					<q-td :props="props">
						<q-icon name="arrow_forward" size="1.5rem" />
					</q-td>
				</template>

				<template #body-cell-matchingValue="props">
					<q-td :props="props">
						<q-select class="bulk-upload-mapping-selection"
								v-model="mappingValues.emissionfactor.selected[props.row.fileValue]"
								:options="mappingValues.emissionfactor?.filtered"
								outlined 
								dense 
								use-input 
								hide-bottom-space
								hide-selected
								@update:model-value="(value) => updateEmissionFactorMapping('emissionfactor',
								props.row.fileValue, value, props.row.fileUnit)"
								fill-input
								@filter="(val, update) => filterFn(val, update, 'emissionfactor')"
								:rules="[ val => val != undefined  || 'Please select a matching value' ]"
								:class="{ 'highlight-emission-factor': mappingValues.emissionfactor.selected[props.row.fileValue] == highlightSelectedEf }"
								>
								<template v-slot:prepend>
									<q-icon name="search" />
								</template>
						</q-select>
					</q-td>
				</template>

				<template #body-cell-unit="props">
					<q-td :props="props">
						<q-select 
							v-model="mappingValues.efUnitMappings.selected[mappingValues.emissionfactor.selected[props.row.fileValue]]"
							:options="getUnitOptionsForEmissionFactor(mappingValues.emissionfactor.selected[props.row.fileValue])"
							outlined 
							dense 
							hide-bottom-space
							@update:model-value="(val) => updateUnitMapping(val, mappingValues.emissionfactor.selected[props.row.fileValue], props.row.fileUnit)"
							/>
					</q-td>
				</template>
		</q-table>

		<q-separator dark></q-separator>

		<q-card-actions class="bulk-upload-mapping-buttons">
			<q-btn v-if="hasBackButton" flat color="primary" @click="this.$emit('back')" label="Back" class="q-ml-sm" no-caps />
				<q-btn
					v-if="true"
					style="width: 7.5rem;"
					@click="nextButtonClick()"
					:label="isSubmitButton ? 'Submit' : 'Next'"
					color="secondary" 
					no-caps 
					:loading="loadingUpload"
					:disable="!validMappings()"
					/>
		</q-card-actions>
	</div>
</template>

<script>
	import notify from "@/services/util/notify";

	export default {
		name: "EmissionFactorMapping",
		props: {
			/** 
			These are the mappings we should be updating
			based on user input
			 */
			modelValue: {
				type: Object,
			},
			mappings: {
				type: Object,
			},
			fileData: {
				type: Object,
			},
			emissionFactorHeaderName: {
				type: String,
			},
			isSubmitButton: {
				type: Boolean,
			},
			hasBackButton: {
				type: Boolean,
			},
			loadingUpload: {
				type: Boolean,
			},
			unitHeaderName: {
				type: String,
			},
			fileEmissionFactorUnitMap: {
				type: Object,
			},
			savedMappings: {
				type: Object,
			},
		},
		emits: [
			'update:modelValue',
			'updateMapping',
			"submit",
			"next",
			"upload",
			'back',
		],

		data() {
			return {
				mappingValues: structuredClone(this.modelValue),
				tableRows: this.getTableRows(),
				tableColumns: this.getTableColumns(),
				highlightSelectedEf: "",
			}
		},

		created() {
			if (this.unitHeaderName){
				this.initialiseSavedEmissionFactorDefaultUnits();
			}
		},

		methods: {
			/**
			This method looks through all the pre-saved/autofilled emission factors, and
			sets the selected file unit mappings to the corresponding emission factor's
			default unit.
			*/
			initialiseSavedEmissionFactorDefaultUnits() {
				const savedEmissionFactors = this.mappingValues.emissionfactor.selected;
				for (const [fileEf, ef] of Object.entries(savedEmissionFactors)) {
					if(!ef) continue;
					const emissionFactorDefaultUnit = this.mappings.efDefaultUnits[ef];
					const fileUnitValues = this.fileEmissionFactorUnitMap[fileEf];
					let fileUnit;
					if (fileUnitValues && fileUnitValues.length) {
						fileUnit = fileUnitValues[0];
					}
					this.setSelectedFileUnitMapping(ef, fileUnit, emissionFactorDefaultUnit);

					if (this.unitHeaderName && fileUnit) {
						this.autoselectSavedUserUnitMapping(ef, fileUnit);
					}
				}
			},

			/**
			This method will attempt to autoselect a previous mapping the user
			has made, between:
				- A GH emission factor + the user's given unit, and...
				- A GH unit display symbol
			*/
			autoselectSavedUserUnitMapping(greenhaloEF, userUnit) {
				const savedUnitMappings = this?.savedMappings?.userUnitMappings;
				if (!savedUnitMappings) return;

				const savedUnit = this.retrieveSavedGreenHaloUserUnitMapping(savedUnitMappings,
					greenhaloEF, userUnit);

				if (savedUnit) {
					this.setSelectedFileUnitMapping(greenhaloEF, userUnit, savedUnit);
					this.mappingValues.efUnitMappings.selected[greenhaloEF] = savedUnit;
				}
			},

			/**
			Attempts to retrieve a saved GH unit mapping given a GH emission factor
			and a user's unit value.

			Currently we split the user value's mapping (the GH emission factor
			and the user's unit) by "::", which is what 
			the below .split line is undoing.
			*/
			retrieveSavedGreenHaloUserUnitMapping(savedUnitMappings, greenhaloEF, userUnit) {
				for (const [efAndFileUnit, greenHaloUnit] of Object.entries(savedUnitMappings)){
					try {
						const [savedGreenHaloEF, savedUserUnit] = efAndFileUnit.split("::");
						if (
							savedGreenHaloEF === greenhaloEF &&
							savedUserUnit === userUnit
						) {
							return greenHaloUnit;
						}
					} catch {
						return;
					}
				}
			},

			/**
			Set mappingValue's unitMappings selected emission factor file unit mapping
			to the given unit.
			e.g. for the emission factor 'Waste Average', map the user-supplied unit 
			'tonnage' to 'tonnes'
			*/
			setSelectedFileUnitMapping(emissionFactor, fileUnit, unit) {
				this.mappingValues.unitMappings.selected[emissionFactor] ??= {};
				this.mappingValues.unitMappings.selected[emissionFactor][fileUnit] = unit;
				Object.keys(
					this.mappingValues.unitMappings.selected[emissionFactor])
					.forEach((existingUnit) => {
						this.mappingValues.unitMappings.selected[emissionFactor][existingUnit] =
							unit;
					})
				this.updateModelValue();
			},

			/**
			Updates fileUnit to ghUnit mapping, and asks parent component to save this
			mapping on the server for the future.
			*/
			updateUnitMapping(unit, ef, fileUnit){
				if(!this.unitHeaderName) {
					const efArray = Object.values(this.mappingValues.emissionfactor.selected);
					const efCount = efArray.filter(factor => factor == ef).length;
					if (efCount > 1) {
						notify.inform(`You have previous mappings for this GreenHalo emission factor. All units for emission factor ${ef} have been changed to ${unit}.`, "top", "Previous mapping identified");
						this.highlightSelectedEf = ef;

						setTimeout(() => {
							this.highlightSelectedEf = "";
						}, 5000); 
					}

					this.$emit('updateMapping', 'emissionFactorUnitMappings', ef, unit);
					return;
				}

				if (fileUnit) {
					this.setSelectedFileUnitMapping(ef, fileUnit, unit);
				}
				this.$emit(
					'updateMapping', 
					'userUnitMappings', 
					`${ef}::${fileUnit}`, 
					unit);
				this.updateModelValue();
			},

			/**
			Checks if a given emission factor's mapped unit is already set. If it isn't,
			set the unit to the emission factor's default unit.
			*/
			checkAndSetDefaultFileUnitMapping(selectedEF, fileUnit) {
				if (!this.mappingValues.unitMappings.selected?.[selectedEF]?.[fileUnit]) {
					const defaultUnit = this.mappingValues.efUnitMappings.selected[selectedEF];
					this.setSelectedFileUnitMapping(selectedEF, fileUnit, defaultUnit);
				}
			},

			/**
			Call parent component's updateEmissionFactorMapping method to save bulk upload mapping
			 */
			updateEmissionFactorMapping(emissionFactor, fileEmissionFactor, selectedEF, fileUnit) {
				if (this.unitHeaderName) {
					this.checkAndSetDefaultFileUnitMapping(selectedEF, fileUnit);
				}

				try {
					this.$emit('updateMapping', emissionFactor, fileEmissionFactor, selectedEF);
					this.updateModelValue();
				} catch (err) {
					console.error(`Error updating bulk upload mapping between '${fileEmissionFactor}' and '${selectedEF}'`);
					console.error(err.message);
				}
			},

			/**
			Updates v-model prop with new model value
			*/
			updateModelValue() {
				this.$emit('update:modelValue', this.mappingValues);
			},

			filterFn(inputVal, update) {
				update(() => {
					const needle = inputVal.toLowerCase();
					this.mappingValues.emissionfactor.filtered = this.mappingValues.emissionfactor.original.filter(
						(value) => value.toLowerCase().includes(needle)
					);
				});
			},

			getUnitOptionsForEmissionFactor(factorName) {
				if (!factorName || factorName == "") {
					return undefined;
				}

				return this.mappings.efUnitOptions[factorName];
			},

			getTableColumns() {
				const rows = [
					{
						name: "fileValue",
						label: "Value",
						align: "left",
						classes: "bg-white",
						style: "width: 2vw",
						field: (row) => row.fileValue,
					},
					{
						name: "seperator",
						align: "center",
						label: "",
						classes: "bg-white",
					},
					{
						name: "matchingValue",
						label: "Matching Value",
						align: "right",
						classes: "bg-white",
						field: (row) => row.matchingValue,
					},
					{
						name: "unit",
						label: "Unit",
						align: "right",
						classes: "bg-white unit-select-input",
						style: "width: 1vw",
						field: (row) => row.unit
					}
				]

				if (this.unitHeaderName) {
					rows.splice(1, 0, {
						name: "fileUnit",
						label: "Your unit",
						align: "left",
						classes: "bg-white",
						field: (row) => row.fileUnit,
					});
				}
				return rows;
			},

			/**
			Retrieves an emission factor's corresponding unit (from the user's
			excel file). If the emission factor has multiple units, notify the user
			that we can only process one unit per emission factor.
			*/
			getEmissionFactorUnit (emissionFactor) {
				const efUnitFileValues = this.fileEmissionFactorUnitMap[emissionFactor];
				if (!efUnitFileValues) {
					return undefined;
				} else if (efUnitFileValues.length === 1) {
					return efUnitFileValues[0];
				} else if (efUnitFileValues.length > 1) {
					notify.inform(`We detected multiple units for the given` + 
						` emission factor '${emissionFactor}'.` + 
						` Currently multiple units aren't supported, we will only convert`+
						` '${efUnitFileValues[0]}' to the mapped unit.`,
					);
					return efUnitFileValues[0];
				}
			},

			/**
			For a given emission factor, generate a table row object based on the emission
			factor's name.
			*/
			getIndividualRow (emissionFactor) {
				const row = {
					fileValue: emissionFactor,
				};

				if (this.unitHeaderName) {
					row.fileUnit = this.getEmissionFactorUnit(emissionFactor);
				}

				return row;
			},

			getTableRows() {
				const rows = [];
				for (const ef of this.fileData.uniqueValues[this.emissionFactorHeaderName]) {
					rows.push(this.getIndividualRow(ef));
				}
				return rows;
			},

			nextButtonClick() {
				if (this.isSubmitButton) {
					this.$emit("submit");
				} else {
					this.$emit("next")
				}
			},

			validMappings() {
				const header = this.emissionFactorHeaderName;
				if(!this.mappingValues?.[header]?.selected) {
					return false
				}

				const numMappingsRequired = this.fileData?.uniqueValues?.[this.emissionFactorHeaderName]?.length;
				const selectedMappingValues = this.mappingValues?.emissionfactor?.selected;
				if (selectedMappingValues) {
					const numMappingsMade = Object.values(selectedMappingValues).filter((item) =>
						item).length;
					return numMappingsMade >= numMappingsRequired;
				}

				return true;
			}
		}
	};
</script>
