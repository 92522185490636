<template>
  <div class="file-container">
    <h2 class="tab-section-heading">Documents</h2>
    <div v-if="userRole == 'admin' || 'demo'" class="file-upload-input"></div>

    <template v-if="subPermissions.auditing.includes(subscription)">
      <span>Upload General Documents Below</span>
      <div class="upload-manual-csv">
          <q-file class="upload-files justify-center items-center" v-model="file" type="file" ref="file"
              label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
              :item-aligned=true>

              <template v-if="file" v-slot:prepend>
                  <q-icon name="cancel" @click.stop.prevent="file = null" class="cursor-pointer" />
              </template>
          </q-file>

          <div class="manual-button-center">
              <q-btn class="file-input-button" no-caps color="green" :disabled="this.file == null" @click="uploadFile"
                  label="Upload" icon-right="upload" :loading="loading" />
          </div>
      </div>
  </template>

    <div class="file-table">
      <q-icon name="description" color="primary" size="3em"/> 
      <span>Documents on this system</span>
      <q-table class="q-table-files" :rows="files" :columns="columns" row-key="name" :loading="loadingFiles"
        :virtual-scroll-sticky-size-start="48" :rows-per-page-options="[15]" :filter="filter" binary-state-sort
        :no-data-label="userRole == 'demo' ? 'No files available on demo account': 'No files saved'">
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn icon="download" flat color="primary"
              @click="getFileDownload(props.row.id, props.row.fileName)"></q-btn>

            <q-btn v-if="userRole == 'admin' || 'demo'" icon="edit" flat color="primary"
              @click=" updateFileId = props.row.id">
              <q-popup-proxy :ref="`update${props.row.id}`" class="file-action-popup-proxy">
                  <FileActionComponent
                  action="update" 
                  :data="{fileId: props.row.id, fileName: props.row.fileName, siteIds: props.row.siteIds, sites,}"
                  :waiting="loadingUpdate"
                  @update-file="updateFile"
                  @delete-file="deleteFile"
                  />
              </q-popup-proxy>
            </q-btn>
            <q-btn v-if="userRole == 'admin' || 'demo'" icon="delete" flat color="deep-orange"
              @click="(confirm = true) && (deleteFileId = props.row.id)">

              <q-popup-proxy :ref="`delete${props.row.id}`" class="file-action-popup-proxy">
                <FileActionComponent
                action="delete" 
                :data="{fileId: props.row.id, fileName: props.row.fileName, siteId: props.row.siteId, sites,}"
                :waiting="loadingUpdate"
                @update-file="updateFile"
                @delete-file="deleteFile"
                />
              </q-popup-proxy>
            </q-btn>
            <q-btn :class="props.row.id==selectedFile.fileId ? 'selected' : '' " v-if="userRole == 'admin' || 'demo'" icon="visibility" flat color="primary"
              @click="selectFile(props.row)"></q-btn>
          </q-td>
        </template>

        <template v-slot:top-left>
          <q-input outlined borderless dense debounce="300" v-model="filter" placeholder="Search" clearable>
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
      </q-table>
      <div :style="{'margin-top': files.length <= 0 ? '1em' : '0'}">
        <q-icon name="description" color="primary" size="3em"/> 
        <span>Files deleted or modified</span>
        <FileHistoryTableComponent ref="historyTable" :key="type" :type="type" :custom="custom" :selectedFile="selectedFile" @clearSelection="selectedFile={fileId:'', fileName:''}"/>
      </div>
    </div>
  </div>
</template>

<script>

import api from "../../../services/api/api"
import notify from '@/services/util/notify';
import getRole from "@/services/util/role";
import subPermissions from "@/subPermissions";
import getSub from "@/services/util/subscription.js";

import { useSiteStateStore } from '@/stores/siteState.store';
import { ref } from 'vue'
import {filesize} from "filesize";

import FileActionComponent from "./FileActionComponent";
import FileHistoryTableComponent from './FileHistoryTableComponent.vue'

const DocumentsTabComponent = {
  name: "DocumentsTab",
  props: ["type", "custom", "customDatasetId", "files", "emissionTypes", "loadingFiles"],
  components: {FileHistoryTableComponent, FileActionComponent},

  setup() {
    return {
      pagination: ref({
        rowsPerPage: 0,
      }),
    };
  },

  data() {
    return {
      userRole: getRole(),
      file: null,

      ascending: false,
      sortColumn: "",

      columns: [
        {
          name: "fileName",
          style: "width: 25%",
          label: "File Name",
          align: "left",
          field: "fileName",
          sortable: true,
          required: true,
        },
        {
          name: "fileSize",
          style: "width: 20%",
          label: "File Size",
          align: "left",
          field: "fileSize",
          sortable: true,
          format: (val) => filesize(val)
        },
        {
          name: "uploadDate",
          style: "width: 20%",
          label: "Upload Date",
          align: "left",
          field: "uploadDate",
          sortable: true,
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          }),
        },
        {
          name: "uploaded_by",
          style: "width: 20%",
          label: "User",
          align: "left",
          field: "uploadedBy",
          sortable: true,
        },
      ],

      confirm: false,
      updateFileId: null,
      deleteFileId: null,
      filter: "",
      emissionType: this.type,
      requireEmissionTypes: false,
      selectedEmissionType: "",
      selectedFile: {
        fileId: "",
        fileName: ""
      },

      loadingUpdate: false,
      loading: false,

      subscription: getSub(),
      subPermissions: subPermissions,

      sites: [],
    };
  },
  created() {
    this.$emit("onInitialize");
    this.initEmissionTypes();
    this.getAllSites();

    if (this.userRole.toLowerCase() !== "demo") {
      this.columns.push({
          name: "actions",
          style: "width: 10%",
          label: "Action",
          align: "center",
          field: "",
        }
      );
    }
  },
  watch: {
    emissionTypes() {
      this.initEmissionTypes();
    },
    selectedEmissionType(value) {
      // If a value from the type dropdown is selected
      if (value) {
        this.selectedEmissionType = value;
        this.emissionType = this.emissionTypes[this.selectedEmissionType];
      }
    },
    type(value) {
      // When emission type changes clear the selected file
      this.selectedFile = {
        fileId: "",
        fileName: ""
      };

      // Checks if currently on the transport/stationary fuels page
      if (!["TransportFuels", "StationaryFuels"].includes(value)) {
        this.emissionType = value;
      } else {
        // Default selects the first value in the dropdown
        this.emissionType = Object.values(this.emissionTypes)[0];
      }
    },
    siteId() {
      this.$emit("onInitialize");
    }
  },
  computed: {
    siteId() {
      return useSiteStateStore().siteId;
    },
  },
  methods: {
    /**
     * @desc Initializes the dropdown, depending on the current data page
     */
    initEmissionTypes() {
      if (this.emissionTypes) {
        this.requireEmissionTypes = true;
        this.selectedEmissionType = Object.keys(this.emissionTypes)[0];
      } else {
        this.requireEmissionTypes = false;
        this.selectedEmissionType = "";
      }
    },

    selectFile(selection) {
        if(selection.id==this.selectedFile.fileId){
          this.selectedFile = {
          fileId: "", 
          fileName: ""
        }
      } else {
          this.selectedFile = {
          fileId: selection.id, 
          fileName:selection.fileName
        }
      }

    },

    /**
     * @desc Attempt to download a select file
     * @param {Strings} fileId id of the file to download
     * @param {Strings} fileName name of the file being downloaded
     */
    getFileDownload(fileId, fileName) {
      try {
        api.file
          .getSingleFile(fileId, fileName)
          .then(() => {
            this.$refs.historyTable.getInitialFileTrace();
            notify.primary("File successfully downloaded", "top");
          })
          .catch((error) => {
            notify.withObject(error.response, "top", "File Download");
          });
      } catch {
        notify.error("Error downloading file", "top");
      }
    },

    /**
     * @desc Attemp to get a list of all sites.
     */
    getAllSites() {
      api.sites.getSites()
        .then((res) => {
          for (const site of res.data) {
            this.sites.push({id: site.id, name: site.name});
          }
        })
        .catch((error) => {
          notify.withObject(error.response, "top", "Sites");
        });
    },

    /**
     * @desc Attempt to upload the file
     */
    async uploadFile() {
      this.loading = true;

      try {
        await api.file.postFile(this.file, this.custom ? "custom" : this.emissionType, [this.siteId], "manual", this.customDatasetId)
        this.$emit("onInitialize");
        this.$refs.historyTable.getInitialFileTrace();
        notify.primary(`${this.file.name}`, "top", "Successfully Uploaded File");
      } catch (error) {
        notify.withObject(error.response, "top", "File Upload");
      } finally {
        this.file = null;
        this.loading = false;
      }

    },

    /**
     * @desc Attempt to delete a selected file.
     * 
     * @param {Object} data
     * {
     *   message,
     * } 
     */
    deleteFile(fileId, data) {
      this.loadingUpdate = true;

      api.file
        .deleteFile(fileId, data)
        .then(() => {
          this.$emit("onInitialize");
          notify.primary("File successfully deleted", "top");
          this.$refs[`delete${fileId}`].hide();
          this.$refs.historyTable.getInitialFileTrace();
        })
        .catch((error) => {
          notify.withObject(error.response, "top", "File Deletion");
        }).finally(() => {
          this.loadingUpdate = false; 
        });
      
      
    },


    /**
     * @desc Attempt to update a selected file.
     * 
     * @param {Object} data
     * {
     *   fileName,
     *   message,
     *   sites - an array of the updated sites, e.g. [{id: "5", name: "site name"}, ...],
     * } 
     */
    updateFile(fileId, data) {
      this.loadingUpdate = true;

      api.file
        .updateFileDataById(fileId, data)
        .then(() => {
          this.$emit("onInitialize");
          notify.primary("File successfully updated", "top");
          this.$refs[`update${fileId}`].hide();
          this.$refs.historyTable.getInitialFileTrace();
        })
        .catch((error) => {
          notify.withObject(error.response, "top", "File Update");
        })
        .finally(() => {
          this.loadingUpdate = false;
          
        });
        
        
    },

    /**
     * @desc Error catcher for file uploader
     */
    onRejected() {
      notify.error('Error uploading files. Too many files selected', 'top');
    }
  }
}

export default DocumentsTabComponent;
</script>

<style scoped lang="less">
@import "../../../assets/styles/audit.less";
</style>