<template>
	<q-carousel
			v-model="slide"
			animated
			navigation-position="bottom"
			navigation
			padding
			arrows
			height="850px"
			control-color="purple"
			class="bg-white text-white rounded-borders"
			>
			<q-carousel-slide name="intro" class="column no-wrap ">
				<div class="q-mt-md">
					<span >
						To start the split invoice process simply
						press the "Split Invoice" icon for the invoice 
						you would like to split.
					</span>
				</div>
				<q-img
						fit="fill"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/invoiceSplit/splitButton.png"
						>
				</q-img>
			</q-carousel-slide>
		
			<q-carousel-slide name="sections-overview" class="column no-wrap ">
				<div class="q-mt-md">
					<span >
						You should notice now a couple different components have popped up.
						<br>
						Let's go through each section.
					</span>
				</div>
			</q-carousel-slide>

			<q-carousel-slide name="accrual" class="column no-wrap ">
				<div class="q-mt-md">
					<span >
						This is where you can even split your invoice line items over a date range.
						<br>
						for instance, if you have an electricity invoice issued on the 1st of may but wanted
						this invoice spread out evenly across the whole month of may, we can simply enter the 
						dates and green halo will sort the rest out!

						If you don't need this then you can just leave the dates blank and move on.
					</span>
				</div>
				<q-img
						fit="fill"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/invoiceSplit/dateAccrual.png"
						>
				</q-img>
			</q-carousel-slide>


			<q-carousel-slide name="splitting" class="column no-wrap ">
				<div class="q-mt-md">
					<span >
						This is where the main feature of Split Invoice lives. Here we can see each line item
						that was retrieved from the original invoice. 

						From here we can choose a line item to split by pressing the "Split" button for that line.
					</span>
				</div>
				<q-img
						fit="fill"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/invoiceSplit/splitRowButton.png"
						>
				</q-img>
			</q-carousel-slide>

			<q-carousel-slide name="row-allocation" class="column no-wrap ">
				<div class="q-mt-md">
					<span >
						You should now notice that there are extra rows for you to fill in.
						These extra rows that have appeared under your line items are the resultant rows
						you want after the split.


						For instance, if we wanted to split that line item into 2 seperate sites equally, we can:

						<ol>
							<li>Assign "50" to the amount column and set its type to "%"</li>

							<li>For each of the splitting options we have allocated, assign each to 2 different sites</li>

							<li>You can also create/assign tags to each row here as you please
								(Tags are automatically created so simply type a tag and press enter!)</li>
							<li>
								There are also options for 
								<ul>
									<li>
										Changing emission factors
									</li>
									<li>
										Changing assigned site
									</li>
									<li>
										Changing unit of measurement
									</li>
								</ul>
							</li>
						</ol>

					</span>
				</div>
				<q-img
						fit="fill"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/invoiceSplit/optionsFilledOut.png"
						>
				</q-img>
			</q-carousel-slide>
	</q-carousel>
</template>

<script>

	export default {

		data() {
			return {
				slide: "intro",
			}
		}
	}

</script>

<style lang="less" src="@/assets/styles/bulkUpload.less" scoped />
