/**
 * filestorage.js
 * 
 * API for handling filestorage on the frontend
 */


import axiosInstance from "./axios-instance";

/**
 * @desc Gets the files and subfolders for a folder
 * 
 * @returns {Object} The files and subfolders in the provided folder
 */
const getFolderInfo = async function(folderId) {
    return await axiosInstance.get(`/api/folders/${folderId ? folderId : ""}`).then(res => res);
}

export default {
    getFolderInfo
}