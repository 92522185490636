<template>
	<div class="create-custom-dataset-container">
		<q-btn
			style="margin-bottom: 50px"
			no-caps
			label="How do custom datasets work?"
			@click="displayGuide = true"
		/>
		<!-- Guide for users making new custom datasets -->
		<q-dialog v-model="displayGuide">
			<q-card>
				<q-card-section class="row items-center q-pb-none">
					<div class="text-h4">Custom Datasets</div>
					<q-space />
					<q-btn
						icon="close"
						flat
						round
						dense
						v-close-popup
					/>
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Introduction</div>
					Custom datasets are used to represent and track emissions
					sources that are not currently supported by GreenHalo, e.g.
					Livestock, Fertiliser, Refrigerant etc..
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Dataset Name</div>
					The dataset name determines the name of what the custom
					dataset represents and tracks.
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Units</div>
					The units of a custom dataset represent what the dataset
					measures , e.g. Kg, Litres.
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Emission Factor</div>
					The emission factor is used to calculate how many CO2e are
					produced from entries in the custom dataset. e.g. if your
					custom data is measured by Kg, and each Kg of your custom
					data produces 3.5 CO2e, then the emission factor should be
					set as 3.5.
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Required fields</div>
					There are three fields that are required in every custom
					dataset, these are the fields that CO2e calculations, 
					time series graphing are based off of, and allows for linking custom dataset
					entries to a registered site.
					<div style="width: 90%; margin: 15px 0 15px auto">
						<div class="text-h6">timestamp</div>
						This value determines what date and time the custom data
						entry occured.

						<div class="text-h6">amount</div>
						This values determines how much/many of the "units"
						value were used for a given custom dataset entry.

						<div class="text-h6">siteId</div>
						This value is optional for any given custom dataset entry. It allows for
						linking dataset entries to a site that is registered under your company's account, by site ID.
					</div>
					e.g. If 10 Litres of refrigerant were used on 2023-2-20 at site 3, and
					the "units" value of the custom dataset is "Litres", then
					the "timestamp" value of the entry will be 2023-2-20 and
					the "amount" value will be 10, and the "siteId" field would be 3.
				</q-card-section>

				<q-card-section>
					<div class="text-h5">Custom fields</div>
					Custom datasets can also have a number of custom fields.
					These fields can help you keep track of additional
					information that may be relevant to the emission source that
					the custom dataset was created for. e.g. if a custom dataset
					has been made to track "Sheep", it may be helpful to track
					breed of sheep per entry, or wool quality.
				</q-card-section>
			</q-card>
		</q-dialog>
		


		<div class="custom-datasets-title">Create new custom dataset</div>



		<div class="create-custom-dataset-form-container">

			<q-form @submit.prevent="submit">
				<div class="custom-dataset-form-name-question">
					<div class="custom-dataset-form-question">
						<div class="custom-dataset-form-question-header">
							Dataset Icon
						</div>
						<q-select v-model="selectedIcon" :options="iconList">
							<template v-slot:option="scope">
								<q-item
							v-bind="scope.itemProps"
							v-on="scope.itemEvents"
						>

							<q-item-section avatar>
								<q-icon :name="scope.label"></q-icon>
								</q-item-section>
							</q-item>

							</template>
						</q-select>


						<div class="custom-dataset-form-question-header">
							Dataset Name
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 0.75rem;">
									Name of the data to track/measure, e.g.
									Electricity, TaxiUsage, etc.
								</q-tooltip>
							</q-icon>
						</div>
						<q-input
							outlined
							v-model="datasetName"
							type="text"
							:rules="[
								(val) =>
									!!val || 'Custom Dataset Name is required',
							]"
						/>
					</div>
				</div>

				<div class="custom-dataset-form-name-question">
					<div class="custom-dataset-form-question">
						<div class="custom-dataset-form-question-header">
							Units
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 0.75rem">
									The unit of measurement for this dataset,
									e.g. "Kg", "Litres", "Metric Ton"
								</q-tooltip>
							</q-icon>
						</div>
						<q-input
							outlined
							v-model="units"
							type="text"
							:rules="[(val) => !!val || 'Units is required']"
						>
						</q-input>
					</div>
				</div>

				<div class="custom-dataset-form-name-question">
					<div class="custom-dataset-form-question">
						<div class="custom-dataset-form-question-header">
							Emissions Factor
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 0.75rem;">
									The emissions factor is how much the
									"amount" field is multipled by to convert
									your custom data to its CO2 equivalent. e.g.
									if your units are "Kg", and 1Kg of your
									custom data type emits 3 CO2e, then the
									emissions factor would be 3.
								</q-tooltip>
							</q-icon>
						</div>
						<q-input
							outlined
							v-model="emissionsFactor"
							type="text"
							:rules="[
								(val) =>
									!!val || 'Emissions Factor is required',
							]"
						>
							<template v-slot:hint> </template>
						</q-input>
					</div>
				</div>

				<div class="custom-rows-header">
					Custom field definitions

					<q-icon
						name="help"
						color="grey"
						size="sm"
					>
						<q-tooltip style="font-size: 0.75rem">
							Here you can define custom fields of your dataset. This allows you to track additional information about the
							custom dataset being defined.
						</q-tooltip>
					</q-icon>
				</div>
				<div class="custom-rows-input-container">
					<q-input
						disable
						outlined
						type="text"
						label="timestamp"
						style="margin-bottom: 20px"
					/>

					<q-input
						outlined
						disable
						type="text"
						label="amount"
						style="margin-bottom: 20px"
					/>

					<q-input
						outlined
						disable
						type="text"
						label="siteId"
						style="margin-bottom: 20px"
					/>

					<q-input
						v-for="row in customFields"
						:key="row.id"
						:rules="[
							(val) => !!val || 'Custom Row name is required',
						]"
						v-model="row.name"
						outlined
					>
						<template v-slot:append>
							<q-btn
								no-caps
								label="X"
								@click="deleteCustomRow(row.id)"
							/>
						</template>
					</q-input>
				</div>

				<div class="add-rows-btn">
					<q-btn
						icon="add"
						label="Add custom row"
						no-caps
						color="brown-5"
						@click="addCustomRow()"
					>
						<template v-slot:prepend>
							<q-icon name="event" />
						</template>
					</q-btn>
				</div>

				<div class="submit-btn">
					<q-btn
						:loading="loading"
						no-caps
						color="green-6"
						type="submit"
						label="Submit"
					/>
				</div>
			</q-form>
		</div>
	</div>
</template>

<script>
import notify from "@/services/util/notify";
import api from "@/services/api/api";

const CreateCustomDatasetComponent = {
	name: "ViewCustomDatasets",

	data() {
		return {

			iconList: [
				"star", 
				"help", 
				"add", 
				"ev_station", 
				"local_shipping",
				"delete",
				"local_gas_station",
				"propane_tank",
				"connecting_airports",
				"water_drop",
				"commute"
			],
			loading: false,
			loadSuccess: false,
			loadFail: false,

			datasetName: "",
			units: "",
			emissionsFactor: undefined,
			selectedIcon: "",

			currRowId: 1,
			customFields: [
				{
					id: 1,
					name: "",
				},
			],

			displayGuide: false,
		};
	},

	methods: {

		/**
		 * @desc On form submission, attempt to create a new custom dataset
		 */
		submit() {
			this.loading = true;
			let customFields = [];
			for (let row of this.customFields) {
				customFields.push(row.name);
			}

			let payload = {
				name: this.datasetName,
				customFields: customFields,
				emissionsFactor: this.emissionsFactor,
				units: this.units,
				icon: this.selectedIcon,
			};

			api.customDatasets
				.postCustomDataset(payload)
				.then(() => {
					notify.primary(
						`Successfully created new custom dataset: ${this.datasetName}`,
						"top"
					);
					this.$emit("reload");
					this.$emit("close");
				})
				.catch((err) => {
					notify.error(
						err.response.data ??
							"Error connecting to server, please try again later.",
						"top"
					);
				})
				.finally(() => (this.loading = false));
		},


		/**
		 * @desc Attempt to delete a custom row when filling out form
		 * @param {Number} rowId id of the custom row to be deleted
		 */
		deleteCustomRow(rowId) {
			this.customFields = this.customFields.filter(
				(row) => row.id !== rowId
			);
		},

		
		/**
		 * @desc Add a custom row to the custom dataset definition.
		 */
		addCustomRow() {
			this.currRowId += 1;
			this.customFields.push({
				id: this.currRowId,
				name: "",
			});
		},
	},
};

export default CreateCustomDatasetComponent;
</script>

<style scoped>
@import "../../../assets/styles/customDatasets.css";
</style>
