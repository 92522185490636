<template>
  <div class="org-dashboard-summary-container">
    <div class="summary-container">
      <span class="organisation-dashboard-sub-title">Summary</span>
      <div class="summary-cards-container">
        <CardComponent v-for="summary in summaryData" :key="summary.label" :data="[summary]" >
          <div v-if="summary.label == 'Total Targets'" class="targets-container">
            <div class="ontrack-container">
              <span class="ontrack-span">On Track</span>
              <q-circular-progress
                show-value
                :value="targetData.onTrack"
                size="5em"
                color="secondary"
                :max="summary.value"
                track-color="grey-3"
              />
            </div>

            <div class="offtrack-container">
              <span class="offtrack-span">Off Track</span>
              <q-circular-progress
                show-value
                :value="targetData.offTrack"
                size="5em"
                color="red"
                :max="summary.value"
                track-color="grey-3"
              />
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
    
    <div class="top-contributors-container">
      <h2 class="section-heading">Top Contributors</h2>
      <div v-if="topContributors.length > 0" class="contributor-cards-container">
        <CardComponent v-for="contributor in topContributorsFormatted" :key="contributor.label" :data="[contributor]" >
          <span class="percentage-change" :class="[emissionChanges[contributor.label] <= 0 ? 'increase' : 'decrease']">
            <q-icon :name="emissionChanges[contributor.label] >= 0 ? 'arrow_upward' : 'arrow_downward'" size="1.25em" />
            {{ emissionChanges[contributor.label].toFixed(0) }}%
          </span>
        </CardComponent>
      </div>
      <div v-else class="contributor-cards-container">
        <CardComponent v-for="obj in Array(numTopContributors).fill({value: 'No Data'})" :key="obj.value" :data="[obj]" />
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from '../CardComponent.vue';
import api from '@/services/api/api';
import subMonths from 'date-fns/subMonths';
import subYears from 'date-fns/subYears';

export default {
  name: "DashboardSummary",
  components: {CardComponent, },
  props: ["orgData", "granularity"],

  data() {
    return {
      sites: [],
      totalTargets: 0,
      numTopContributors: 4,

      targetData: {
        onTrack: 0,
        offTrack: 0,
      },

      emissionData: {
        current: [],
        previous: [],
      }
    }
  },
  async created() {
    await this.init();
  },
  watch: {
    granularity() {
      this.initEmissionData();
    }
  },
  computed: {
    summaryData() {
      return [
        {
          label: "Number of Organisations",
          value: Object.keys(this.orgData.organisations).length,
        },
        {
          label: "Number of Total Sites",
          value: this.sites.length ?? "Unknown",
        },
        {
          label: "Total Emissions",
          value: (this.totalEmissions / 1000).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
          unit: "Tonnes"
        },
        {
          label: "Total Targets",
          value: this.targetData.onTrack + this.targetData.offTrack,
        },
      ];
    },

    /**
     * Parses the emissions data into a format containing the aggregated data for that date
     * Example: { "2021-06": {"freight": 2324, "Electricity": 43242 }, "2021-05": {...} }
     */
    formattedEmissions() {
      const formattedEmissions = {};
    
      for (const org of Object.values(this.orgData.organisationEmissions)) {
        for (const [date, emissions] of Object.entries(org)) {
          formattedEmissions[date] ??= {};
    
          for (const [emission, values] of Object.entries(emissions)) {
            const currentAmount = formattedEmissions[date][emission];
            formattedEmissions[date][emission] = currentAmount ? currentAmount + values.carbon : values.carbon;
          }
        }
      }

      return formattedEmissions;
    },

    /**
     * Gets the top numTopContributors carbon emission contributors
     * Example: [{name: "Freight", value: 24332}, {name: "Electricity", value: 2342}]
     */
    topContributors() {
      const emissions = {};

      for (const data of this.emissionData.current) {
        for (const [emission, value] of Object.entries(data)) {
          emissions[emission] = emissions[emission] ? emissions[emission] + value : value;
        }
      }

      return Object.entries(emissions)
        .sort((a, b) => b[1] - a[1])
        .slice(0, this.numTopContributors)
        .map((emission) => {
          return {
            name: emission[0],
            value: emission[1],
          }
        })
        
    },

    /**
     * Parses the top contributors into a format that's suitable for passing into the CardComponent
     */
    topContributorsFormatted() {
      return this.topContributors.map((emission) => {
        const useTonnes = emission.value >= 100000 ? true : false;
        let value = emission.value;
        
        if (useTonnes) {
          value = emission.value / 1000;
        }
        
        return {
          label: emission.name,
          value: value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}),
          unit: `${useTonnes ? "Tonnes" : "Kg"}`
        }
      })
    },

    /**
     * Returns the total number of carbon emissions for current month / past year
     */
    totalEmissions() {
      let total = 0;
      
      for (const emissions of this.emissionData.current) {
        total += Object.values(emissions).reduce((a, b) => a + b, 0);
      }

      return total;
    },

    /**
     * Calculate the percentage changes of current emissons compared to previous
     */
    emissionChanges() {
      const percentageChanges = {};
      const previousEmissionTotals = {};

      //Get the previous emission totals, only for emissions that are in the top emissions
      for (const emission of this.topContributors) {
        for (const previousEmissions of this.emissionData.previous) {
          const value = previousEmissions[emission.name];

          if (value) {
            previousEmissionTotals[emission.name] = previousEmissionTotals[emission.name] ? previousEmissionTotals[emission.name] + value : value;
          }
        }
      }

      for (const emission of this.topContributors) {
        if (previousEmissionTotals[emission.name]) {
          percentageChanges[emission.name] = ((emission.value - previousEmissionTotals[emission.name]) / Math.abs(previousEmissionTotals[emission.name])) * 100;
        } else {
          percentageChanges[emission.name] = 0;
        }
      }

      return percentageChanges;
    }
  },
  methods: {
    async init() {
      this.initEmissionData();
      this.initTargetData();
      this.initSitesData();
    },

    /**
     * Initialises the current and previous emission data based on the granularity
     */
    initEmissionData() {
      const today = new Date();

      if (this.granularity === "Monthly") {
        this.emissionData.current = this.getEmissionsByDate(today);
        this.emissionData.previous = this.getEmissionsByDate(subMonths(today, 1), subMonths(today, 1));        
      } else if (this.granularity === "Yearly") {
        this.emissionData.current = this.getEmissionsByDate(subYears(today, 1));
        this.emissionData.previous = this.getEmissionsByDate(subYears(today, 2), subYears(today, 1));
      }
    },

    /**
     * Aggregates the targets for all organisations
     */
    async initTargetData() {
      for (const org of this.orgData.organisations) {
        const target = (await api.reports.getOrganisationTargetsByTenantId(org.sourceTenantId)).data
        this.targetData.onTrack += target.onTrack;
        this.targetData.offTrack += target.offTrack;
      }
    },

    /**
     * Retrieves sites for all organisations and adds each one to this.sites list
     */
    async initSitesData() {
      for (const org of this.orgData.organisations) {
        const sites = (await api.reports.getOrganisationSitesByTenantId(org.sourceTenantId)).data
        this.sites = this.sites.concat(sites);
      }
    },

    /**
     * @desc Returns a list of emissions between startDate and endDate (inclusive)
     * 
     * @param {*} startDate the start date
     * @param {*} endDate the end date
     * @returns {Array<Object>} an array of the emissions:
     * [
     *  {"Livestock": 1312, "Freight": 2342, ...},
     *  {"Electricity": 1231, "Fertiliser": 4242, ...},
     *  ... 
     * ]
     */
    getEmissionsByDate(startDate, endDate = new Date()) {
      const emissions = [];
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
    
      const date = new Date(startDateObj);
    
      while (date <= endDateObj) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
    
        const formattedDate = `${year}-${month.toString().padStart(2, "0")}`;
        const emission = this.formattedEmissions[formattedDate];
    
        if (emission) {
          emissions.push(emission);
        }
    
        // Increment the date by 1 month
        date.setMonth(date.getMonth() + 1);
      }
      
      return emissions;
    },
  }
}
</script>

<style lang="less" src="@/assets/styles/organisationDashboard.less" />