<template>
	<q-card flat>
		<div class="business-unit-mapping-header">
			Please map GreenHalo Emission Factors to the available Toitu Emission Factors
		</div>

		
		<q-form class="toitu-mapping-form" @submit.prevent="nextPage()">
			<q-scroll-area style="height: 50vh;">
				<div v-for="(businessUnit, unitName) in emissionFactorMappings" :key="businessUnit" :name="unitName">
					<q-expansion-item 
						class="q-pt-md"
						:label="unitName" 
						:caption="`${getNumberOfMappedFactors(unitName)} out of ${getNumberOfFactors(unitName)} factors mapped`"
						v-if="emissionFactorMappings[unitName]?.unmappedGH.length + Object.keys(emissionFactorMappings[unitName]?.mappings).length > 0"
						>
						<div class="business-unit-mapping-container">
							<div class="site-select-container">
								<span class="business-unit-mapping-title">Green Halo Emission Factor : Unit</span>
							</div>
							<div class="business-unit-details">
								<q-icon name="arrow_forward" size="1.5rem" color="transparent" style="margin-right: 25px;" />
								<span class="business-unit-mapping-title text-left">Toitu Emission Factor</span>
							</div>
						</div>

						<div class="separator-line"></div>

						<div class="q-mb-md business-unit-emission-factor-mapping-container" v-for="(emissionFactor, efIndex) in
									getBusinessUnitMappingOptions(unitName)" :key="efIndex" :name="efIndex">

							<div class="site-select-container" :style="{ color: emissionFactor.selected ? 'black' : 'gray' }">
								<q-checkbox v-model="emissionFactor.selected" @update:model-value="attemptToSelectNewMapping(unitName, emissionFactor)" />
								<span class="site-name">{{ emissionFactor.displayName }} </span>
								<span class="site-name">{{ emissionFactor.unit}}</span>
							</div>
							<div class="business-unit-details">
								<q-icon name="arrow_forward" size="1.5rem" style="margin-right: 25px;" />

								<q-select class="toitu-audit-business-unit-input"
									:options="filteredFactorOptions" dense style="margin-bottom: -20px;" emit-value map-options
									v-model="newMappings[unitName][emissionFactor.dataTypeUUID]" outlined
									@update:model-value="attemptToSelectUnit(unitName, emissionFactor, newMappings[unitName][emissionFactor.dataTypeUUID]?.combine )" 
									option-label="combine"
									:disable="!emissionFactor.selected"
									use-input
									hide-selected
									fill-input
									@filter="(val, update) => filterFactors(val, update, filterByCategory(unitName, emissionFactor.isoCategory))"
									:rules="[(val) => (!!val || !emissionFactor.selected) ]"
									>
									<template v-slot:selected-item="scope">
										<div>{{scope.opt.emissionFactor}}</div>
									</template> </q-select>
								<q-select class="toitu-audit-factor-unit-input"
									v-if="newMappings[unitName][emissionFactor.dataTypeUUID] != null"
									:options="filteredUnitOptions" dense style="margin-bottom: -20px;" emit-value map-options
									v-model="newMappings[unitName][emissionFactor.dataTypeUUID].unit" outlined
									option-label="unit"
									:disable="!emissionFactor.selected"
									use-input
									hide-selected
									fill-input
									@filter="(val, update) => filterUnits(val, update, unitMappings[newMappings[unitName][emissionFactor.dataTypeUUID]?.combine])"
									:rules="[(val) => (!!val || !emissionFactor.selected) ]"
								/>
							</div>
						</div>
					</q-expansion-item>
				</div>
			</q-scroll-area>
			<div class="toitu-mapping-btn-container">
				<q-btn class="next" no-caps color="secondary" padding="8px 20px" type="submit" :loading="buttonLoading" :label="buttonLabel" />
				<q-btn flat no-caps color="primary" padding="8px 20px" @click="previousPage()" :disable="buttonLoading" label="Back" />
			</div>
		</q-form>
	</q-card>
</template>

<script>
import commonMappings from "./commonUnitMappings";

export default {
	props: {
		/*
		@example of this object is included at the bottom of this document
		*/
		emissionFactorMappings: {
			type: Object,
			required: true,
		},

		unitMappings: {
			type: Object,
			required: true,
		},

		buttonLoading: {
			type: Boolean,
			required: true
		},

		buttonLabel: {
			type: String,
			required: true,
		},

		selectedEmissionSources: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			newMappings: {},
			filteredFactorOptions: [],
			filteredUnitOptions: [],
			alreadyMappedFactorsLists: {},
		}
	},

	beforeMount() {
		this.combineUnmappedAndMappedEmissionFactors();
		this.addAutoMappedFactorsToNewMappings();
	},

	computed: {
		/*
		 * Pulls out each mapping value object from each site's unmapped array and
		 * adds it to an array which is returned.
		 */
		toituMappingValues() {
			let toituMappingValues = [];
			for (const businessUnit of Object.values(this.emissionFactorMappings)) {
				for (const efObject of businessUnit.unmapped ?? []) {
					toituMappingValues.push(efObject);
				}
			}
			return toituMappingValues;
		},
	},

	methods: {


		/**
		 * Gets total number of factors that need to be mapped
		 * @param {String} unitName - The business unit to check factors for
		 * @returns {Number} - The number of factors that need mapping for the selected business unit
		 */
		getNumberOfFactors(unitName) {
			return this.getBusinessUnitMappingOptions(unitName).length;
		},

		/**
		 * @desc Gets the total number of factors that have been mapped
		 * @param {String} unitName - The business unit to check factors for
		 * @returns {Number} - The number of factors that have been mapped
		 */
		getNumberOfMappedFactors(unitName) {
			const mappingUUIDs = Object.values(this.getBusinessUnitMappingOptions(unitName)).map(option => option.dataTypeUUID);
			return Object.keys(this.newMappings[unitName]).filter(factor => mappingUUIDs.includes(factor)).length;
		},

		/**
		 * Combines unmapped and mapped emission factors for each business unit within the instance.
		 * This new list is then assigned to `alreadyMappedFactorsLists` for the corresponding business unit.
		 */
		combineUnmappedAndMappedEmissionFactors() {
			Object.keys(this.emissionFactorMappings).forEach((businessUnit) => {
				this.newMappings[businessUnit] ??= {};
				let mappedFactorsNewList = [];
				const mappedFactors = this.emissionFactorMappings[businessUnit].mappings;
				for (let [uuid, toituFactor] of Object.entries(mappedFactors)) {
					let copiedFactor = {
						dataTypeUUID: uuid,
						displayName: toituFactor.displayName,
						isoCategory: toituFactor.category,
						unit: toituFactor.unitGH,
						selected: true,
						emissionSource: toituFactor.emissionSource,
					}
					mappedFactorsNewList.push(copiedFactor);
				}
				this.alreadyMappedFactorsLists[businessUnit] = mappedFactorsNewList;
			});
		},


		/**
		 * Iterates over each business unit in `emissionFactorMappings`, copying and modifying emission factors into `newMappings`.
		 * Additionally, it attempts to select a unit for each mapped emission factor by calling `attemptToSelectUnit`.
		 */
		addAutoMappedFactorsToNewMappings() {
			Object.keys(this.emissionFactorMappings).forEach((businessUnit) => {
				for (const [uuid, toituFactor] of
					Object.entries(this.emissionFactorMappings[businessUnit].mappings)) {
					this.newMappings[businessUnit][uuid] = toituFactor;
					this.newMappings[businessUnit][uuid].selected = true; 
					this.attemptToSelectUnit(businessUnit, {dataTypeUUID: uuid, unit: toituFactor.unitGH, selected: true}, toituFactor.combine)
				}
			});
		},


		filterFactors(inputVal, update, options) {
			update(() => {
                const needle = inputVal.toLowerCase();
                this.filteredFactorOptions = options.filter((value) => value.combine.toLowerCase().includes(needle));
            });
		},

		filterUnits(inputVal, update, options) {
			update(() => {
                const needle = inputVal.toLowerCase();
                this.filteredUnitOptions = options.filter((value) => value.toLowerCase().includes(needle));
            });
		},


		/**
		 * Attempts to set the unit of an emission factor based on provided unit mappings.
		 * 
		 * @param {string} unitName - The name of the unit to attempt to select.
		 * @param {Object} emissionFactor - The emission factor object containing unit and dataTypeUUID properties.
		 * @param {string} combine - The key used to retrieve the corresponding Toitu unit options from `this.unitMappings`.
		 */
		attemptToSelectUnit(unitName, emissionFactor, combine) {
			const toituUnitOptions = this.unitMappings[combine];
			const commonMappingsValue = commonMappings[emissionFactor.unit];
			const dataTypeUUID = emissionFactor.dataTypeUUID

			if (toituUnitOptions && dataTypeUUID && unitName) {
				this.newMappings[unitName][emissionFactor.dataTypeUUID].selected = emissionFactor.selected;
				if (toituUnitOptions.includes(emissionFactor.unit)) {
					this.newMappings[unitName][emissionFactor.dataTypeUUID].unit = emissionFactor.unit;
				} else if (toituUnitOptions.includes(commonMappingsValue)) {
					this.newMappings[unitName][emissionFactor.dataTypeUUID].unit = commonMappingsValue;
				}
			}
		},


		/**
		 * If the mappings has a toitu factor, but becomes unselected, sets selected to false.
		 */
		attemptToSelectNewMapping(unitName, emissionFactor) {
			if (this.newMappings[unitName][emissionFactor.dataTypeUUID]) {
				this.newMappings[unitName][emissionFactor.dataTypeUUID].selected = emissionFactor.selected;
			}
		},


		previousPage() {
			this.$emit("previousPage")
		},


		/**
		 * Returns a list of the unmapped GH emission factors with the already mapped emission factors appended.
		 * @param {*} bu 
		 */
		getBusinessUnitMappingOptions (bu) {
			let list = this.emissionFactorMappings[bu]?.unmappedGH ?? [];

			const returnFactors = [];
			const currentUUIDs = [];
			for (const factor of list.concat(this.alreadyMappedFactorsLists[bu])) {
				if (!currentUUIDs.includes(factor.dataTypeUUID) && this.selectedEmissionSources.includes(factor.emissionSource)) {
					currentUUIDs.push(factor.dataTypeUUID);
					returnFactors.push(factor);
				}
			}

			return returnFactors;
		},

		/**
		 * Filters emission factors by category for a specific business unit.
		 * 
		 * @param {string} businessUnit - The business unit to filter emission factors for.
		 * @param {string} category - The category to filter the emission factors by.
		 * @returns {Array} A list of filtered emission factors.
		 */
		filterByCategory(businessUnit, category) {
			const mappings = structuredClone(this.emissionFactorMappings[businessUnit].toituFactors);
			if (!category) return mappings;
			const filteredList = mappings.filter((toituEF) => {
				return toituEF.category.toLowerCase() === category.toLowerCase();
			});

			if (filteredList.length <= 0) return this.emissionFactorMappings[businessUnit].toituFactors;
			return filteredList;
		},


		/**
		 * Processes to the next page, updating the emission factor mappings with new mappings from the current stage and emitting the updated mappings.
		 * It first clones the current emission factor mappings, then iterates through `newMappings` to update each business unit's mappings 
		 * with the given UUID and associated emission factor, and removes the UUID from the unmapped GreenHalo factors array.
		 */
		nextPage() {
			let clonedEmissionFactorMappings = structuredClone(this.emissionFactorMappings);
			for (const [businessUnit, businessUnitEFMappings] of Object.entries(this.newMappings)) {
				for (const [uuid, toituEF] of Object.entries(businessUnitEFMappings)) {
					// Add uuid and toitu emission factor to mapped factors array
					clonedEmissionFactorMappings[businessUnit].mappings[uuid] = toituEF;
					// Remove the uuid from unmapped GreenHalo factors array
					clonedEmissionFactorMappings[businessUnit].unmappedGH = clonedEmissionFactorMappings[businessUnit].unmappedGH.filter((ghFactor) => {
						return ghFactor.dataTypeUUID != uuid;
					});
				}
			}
			return this.$emit('nextPage', clonedEmissionFactorMappings);
		},
	},
	/*

	Example of prop object:

{
						'Business Unit1': {
							toituFactors: [{
								emissionFactorForBU: 'Accommodation - Australia',
								category: 'Category 3',
								subCategory: 'Business travel - Transport (non-company owned vehicles)',
								description: '',
								combine: 'Accommodation - Australia : Category 3 - Business travel - Transport (non-company owned vehicles)'
							},
							{
								emissionFactorForBU: 'Electricity',
								category: 'Category 2',
								subCategory: 'Grid Average',
								description: '',
								combine: 'Electricity - Grid Average: Category 2'

							},
							{
								emissionFactorForBU: 'Diesel',
								category: 'Category 1',
								subCategory: 'Transport Fuels',
								description: '',
								combine: 'Transport Fuels - Diesel Fuels: Category 1'
							},
							],
							mapped: {},
							unmappedGH: [
								{
									'dataTypeUUID': 'AIRTRAVELFIRSTCLASSLONGHAUL',
									'displayName': 'Air Travel First Class Long Haul (>3700 km)',
									'scope': 3,
									'isoCategory': 'Category 3',
									'siteId': '2'
								},
								{
									'dataTypeUUID': 'DIESELAVERAGE',
									'displayName': 'Diesel (Commercial)',
									'scope': 1,
									'isoCategory': 'Category 1',
									'siteId': '2'
								},
								{
									'dataTypeUUID': 'GRIDAVERAGE',
									'displayName': 'Electricity Grid Average',
									'scope': 2,
									'isoCategory': 'Category 2',
									'siteId': '2'
								}
							]
						},
						'Business Unit 2': {
							toituFactors: [
								{
									emissionFactorForBU: 'caca',
									category: 'Category 1',
									subCategory: 'ahhhhhhhhh',
									description: '',
									combine: 'caca - ahhhhhhhhh: Category 1'
								},
								{
									emissionFactorForBU: 'mapped',
									category: 'Category 1',
									subCategory: 'map me',
									description: '',
									combine: 'mapped - map me: Category 1'
								}
							],
							mapped: {
								MAPPED: {
									emissionFactorForBU: 'mapped',
									category: 'Category 1',
									subCategory: 'map me',
									description: '',
									combine: 'mapped - map me: Category 1'
								}
							},
							unmappedGH: [
								{
									'dataTypeUUID': 'CACA',
									'displayName': 'Map caca to this one',
									'scope': 1,
									'isoCategory': 'Category 1',
									'siteId': '2'
								},
							]
						}
					}

	*/
}

</script>

<style lang="less" scoped src="@/assets/styles/auditToitu.less"/>


