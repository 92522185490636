<template>
  <nav class="navbar">
    <div class="supplier-nav-container">
      <img src="../../../assets/greenhalologo.png" style="{min-width: 150px; max-width: 150px;}" />
      <q-btn-dropdown class="navbar-user-dropdown" size="1rem" no-caps flat :label="userEmail">
        <q-list>
          <q-item clickable v-close-popup @click="logout">
              <q-item-section>
                  <q-icon name="logout" />
              </q-item-section>
              <q-item-section>
                  <q-item-label>Logout</q-item-label>
              </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
  </nav>
</template>

<script>
import jwt from "jsonwebtoken";

export default {
  data() {
    return {
      userEmail: jwt.decode(sessionStorage.getItem('supplierToken')).email,
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ name: "supplierLogin" });
    }
  }
}
</script>

<style lang="less" scoped>
.supplier-nav-container {
  display: flex;
  align-items: center;
  height: 100%;
  padding-inline: 10px;
  justify-content: space-between;
}
</style>