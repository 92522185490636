import axiosInstance from "./axios-instance";

/**
 * @desc Creates a target
 * 
 * @param {Object} payload object containing the required fields for the endpoint
 * {
 *     name,
 *     startDate,
 *     endDate,
 *     targetValue,
 *     reduceBy,
 * }
 */
const createTarget = async (payload) => {
  return await axiosInstance.post("/api/targets", payload).then(res => res);
};


/**
 * 
 * @desc Updates a target with the given payload
 * 
 * @param {Number} id The id of the target to update 
 * @param {Object} payloadobject containing the following fields
 * {
 *     name,
 *     startDate,
 *     endDate,
 *     targetValue,
 *     reduceBy,
 * } 
 */
const patchTarget = async (id, payload) => {
  return await axiosInstance.patch(`/api/targets/${id}`, payload).then(res => res);
};


/**
 * @desc Deletes a company target with the given id
 * 
 * @param {Number} id The id of the target to delete 
 */
const deleteTarget = async (id) => {
  return await axiosInstance.delete(`/api/targets/${id}`).then(res => res);
};


/**
 * 
 * @desc Gets all emission targets for a tenant
 * 
 * @returns An array containing all the targets for a tenant
 */
const getTargets = async () => {
  return await axiosInstance.get("/api/targets").then(res => res);
};

/**
 * 
 * @desc Gets baseline emissions for a given year 
 * 
 * @returns An array containing all the targets for a tenant
 */
const getYearBaseline = async (params) => {
  return await axiosInstance.get("/api/targets/baseline/year", {params}).then(res => res);
};


/**
 * @desc Gets the graph data for the emission target
 *
 * @param {Number} id 
 * 
 * @returns formated graph data to view on the emission target graph
 */
const getTargetGraphById = async (id) => {
  return await axiosInstance.get(`/app/targets/${id}/graph`).then(res => res);
};

/**
 * @desc Adds a new user defined point to the target graph
 *
 * @param {Number} id 
 * 
 */
const postNewUserTargetPoint = async (params, data) => {
  return await axiosInstance.patch(`/api/targets/${params.targetId}/points/${params.dataset}`, data).then(res => res);
};


export default {
  getTargets,
  getTargetGraphById,
  createTarget,
  patchTarget,
  deleteTarget,
  postNewUserTargetPoint,
getYearBaseline 
}
