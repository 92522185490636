/**
 * electricity.js
 * 
 * API for handling getting all electricity associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves electricity data by meter usage chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getElectricityChartData = async function (granularity, siteId="") {
    return await axiosInstance.get(`/app/emissions/electricity/chart/${granularity}?view=financial`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Generate Meter Usage CSV file
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMeterUsageCSV = async function (siteId) {
    return axiosInstance({
        url: `/app/electricity/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `electricty-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Returns year total for logged in tenant
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns 
 */
const getYearTotalInsight = function (siteId) {
    return axiosInstance.get("/app/electricity/year-total", { params: { siteId } }).then(res => res)
}


/**
 * @desc Returns monthly total for logged in tenant
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns 
 */
const getMonthlyTotalInsight = function (siteId) {
    return axiosInstance.get("/app/electricity/monthly-total", { params: { siteId } }).then(res => res)
}


/**
 * @desc Returns hourly total for logged in tenant
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns 
 */
const getHourlyTotalInsight = function (siteId) {
    return axiosInstance.get("/app/electricity/hourly-total", { params: { siteId } }).then(res => res)
}


/**
 * @desc Returns sensor totals for logged in tenant
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns 
 */
const getSensorTotalInsight = function (siteId) {
    return axiosInstance.get("/app/electricity/sensor-total", { params: { siteId} }).then(res => res)
}


/**
 * @desc Gets the electricity distribution loss chart data.
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getElectricityLossChart = async function (granularity, siteId) {
    return await axiosInstance.get(`/app/electricity/loss/chart/${granularity}`, {params: {siteId}}).then(res => res);
}


export default {
    getElectricityChartData,
    getMeterUsageCSV,
    getYearTotalInsight,
    getMonthlyTotalInsight,
    getHourlyTotalInsight,
    getSensorTotalInsight,
    getElectricityLossChart,
}