/**
 * coal.js
 * 
 * API for handling getting all coal associated data
 */

import axiosInstance from "./axios-instance";


/**
 * @desc Gets coal data in CSV form. Once fetched, does browser stuff for the download
 * 
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCoalCSVExport = async function(siteId) {
    return axiosInstance({
        url: `/app/coal/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `coal_data.csv`); 
        document.body.appendChild(link);
        link.click();
    })
}


export default {
    getCoalCSVExport,
}