<template>
  <div class="carbon-offset-trends-container">
    <h2 class="tab-section-heading">Trends</h2>
    <div class="tab-section-container">

      <q-btn-toggle class="date-toggle" v-model="dateRange" text-color="black" toggle-color="secondary" no-caps
					padding="5px 50px" toggle-text-color="white" :options="[
						{ label: 'Monthly', value: 'month' },
						{ label: 'Yearly', value: 'year' },
					]" />
      <CardComponent :loading="loading" :data="insights" icon="energy_savings_leaf" />
      <div>
        <TrendGraphComponent  emissionSource="carbon-offset" emissionUnit="tonnes" />
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent.vue";
import TrendGraphComponent from "../TrendGraphComponent.vue";
import api from "../../../services/api/api";
import notify from "@/services/util/notify";

export default {
  props: ["siteId"],
  components: {
    CardComponent,
    TrendGraphComponent,
  },
  data() {
    return {
      total: 0,
      dateRange: "month",
      totalOffset: 0,
      loading: false
    }
  },

  created() {
    this.generateProportions()
  },

  watch: {
		days() {
			this.generateProportions();
		},
	},
  
  computed: {
    insights() {
      return [
        {
          label: "Total Footprint",
          value: this.total.toLocaleString(undefined, {maximumFractionDigits: 0}),
          unit: "Tonnes"
        },
        {
          label: "Total Off-Set",
          value: this.totalOffset.toLocaleString(undefined, {maximumFractionDigits: 0}),
          unit: "Tonnes"
        },
        {
          label: "Remaining Emissions",
          value: (this.total - this.totalOffset).toLocaleString(undefined, {maximumFractionDigits: 0}),
          unit: "Tonnes"
        }
      ]
    },

    days() {
      return this.dateRange=="month" ? 30 : 365
    }
  },

  methods: {
  /**
		 * @desc Using the total emissions data, generate the proportions graph
		 */
     async generateProportions() {

      this.loading = true;
      try {
        // Get all carbon offset infomation
        this.totalOffset = (await api.carbon.getCarbonOffsetTotal(this.dateRange, this.siteId)).data.sum ?? 0
        this.totalEmissions = (await api.data.getTotalEmissionPastDays(this.days, this.siteId)).data;
        this.total = Object.values(this.totalEmissions).reduce((a, b) => a + b, 0);

      } catch(err) {
        notify.withObject(err)
      } finally {
        this.loading = false;
      }
    },	
  }
}
</script>

<style lang="less" src="@/assets/styles/home.less" scoped />
