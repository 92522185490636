<template>
  <q-card class="q-pa-sm dialog-card">
    <q-card-section class="card-content">
      <h2 style="font-size: 1.25em; font-weight: bold; line-height: initial">Emission Factor Mapping by Site</h2>
      <q-list>
        <q-expansion-item 
          v-for="(site, siteId) in sites" :key="siteId"
          expand-separator
          :label="site.mapped"
          :caption="site.unmapped.join(', ')"
        >
          <DataFeedMappingComponent v-if="!isLoading" v-bind="dataFeedMappingProps(siteEmissionFactorsMap[siteId])" @update="(_, value) => updateDefaultMapping(siteId, value)" />
        </q-expansion-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<script>
  import DataFeedMappingComponent from './DataFeedMappingComponent.vue';
  import api from '@/services/api/api';

  export default {
    components: {
      DataFeedMappingComponent,
    },

    props: {
      sites: {
        type: Object,
        required: true,
      },
      connectionId: {
        required: true,
      },
    },

    data() {
      return {
        emissionFactorsMap: {},
        siteEmissionFactorsMap: {},
        isLoading: true,
      }
    },

    async created() {
      await this.initEmissionFactorMappings();
      await this.initSiteEmissionFactorMappings();
      this.isLoading = false;
    },

    methods: {
      async initSiteEmissionFactorMappings() {
        for (const siteId in this.sites) {
          this.siteEmissionFactorsMap[siteId] = (await api.dataFeeds.getEmissionFactorMappings(this.connectionId, siteId)).data;
        }
      },

      async initEmissionFactorMappings() {
        this.emissionFactorsMap = (await api.emissions.getMappingOptions()).data.emissionfactor;
      },

      dataFeedMappingProps(mapping) {
        return {
          values: { dataTypes: Object.keys(mapping) },
          mappings: { dataTypes: this.emissionFactorsMap },
          savedMappings: { dataTypes: mapping },
        }
      },

      updateDefaultMapping(siteId, value) {
        this.siteEmissionFactorsMap[siteId] = value.dataTypes;
        this.$emit("update", this.siteEmissionFactorsMap);
      },
    }
  }
</script>

<style lang="less" scoped>

</style>