<template>
	<div class="create-mileage-container">
		<h2 class="heading"> {{ editing ? 'Edit' : 'Create' }} a travel claim</h2>
		<div>
			<q-form class="form" @submit.prevent="submitForm()">
				<div class="inputs">

          <span class="form-label">
            Personal Information
          </span>
          <q-input
            outlined
            ref="firstName"
            v-model="firstName"
            label="First Name"
            required
            maxlength="50"
          ></q-input>
          
          <q-input
            outlined
            ref="lastName"
            v-model="lastName"
            label="Last Name"
            required
            maxlength="50"
          ></q-input>

          <q-input
            outlined
            ref="email"
            v-model="email"
            label="Email"
            maxlength="100"
            :rules="[
                    (val) =>
                      validEmail(val) || 'Please enter a valid email address',
                  ]"
          ></q-input>

          <span class="form-label">
            Travel information
          </span>
          <q-input
            outlined
            ref="amount"
            type="number"
            step="any"
            v-model="amount"
            label="Distance travelled (km)"
            required
          ></q-input>
          
          <q-select
            outlined
            ref="vehicleSize"
            v-model="dataTypeUUID"
            :options="vehicleOptions"
            option-label="displayName"
            label="Vehicle Size"
            required
          ></q-select>
	
          <q-input v-model="timestamp" outlined :mask="dateFormat.replace(/[a-z]/gi, '#')" :rules="[(val) => dayjs(val, dateFormat, true).isValid()]" :placeholder="dateFormat" error-message="Please enter a valid date" label="Date of Travel">
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="timestamp" :mask="dateFormat">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Ok" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-input
            outlined
            ref="destination"
            type="text"
            v-model="destination"
            label="Destination"
            maxlength="75"
          ></q-input>

        </div>
				<q-btn
					class="button"
					:disable="submitLoading"
					:loading="submitLoading"
					square
					no-caps
					padding="8px 24px"
					color="secondary"
					:label="editing ? 'Submit Edit' : 'Create'"
					type="submit"
				/>
			</q-form>
		</div>
	</div>
</template>

<script>
import api from "../../services/api/api";
import notify from "@/services/util/notify";
import { patterns } from "quasar";
import dayjs from "@/services/util/dayjs";

const CreateEditMileageComponent = {
  name: "CreateEditMileageComponent",
  components: {},

  props: ["mileageData", "vehicleOptions", "editing", "siteId"],

  data() {
    return {
      submitLoading: false,
      dayjs: dayjs,
      dateFormat: "DD/MM/YYYY",
      firstName: "",
      lastName: "",
      email: "",
      amount: 0.0,
      timestamp: "",
      destination: "",
      status: "",

      dataTypeUUID: "",

      timestampError: false,
    };
  },

  created() {
    this.setMileageHeader();
  },

  methods: {

    /**
     * @desc Checks that the email provided is an email and changes property "valid" which is used by the login button
     * @param {String} val email to check
     * @returns {Boolean} true if email is valid
     */
    validEmail(val) {
      if (patterns.testPattern.email(val)) {
        this.valid = true;
        return true;
      } else {
        this.valid = false;
      }
    },


    /**
     * @desc Sets the title of the card and any existing information if the mileage request is being edited
     */
    setMileageHeader() {
      if (this.editing == true) {
        this.setEditDetails();
      } else {
        this.cardHeader = "Create Mileage Reimbursement Request";
      }
    },


    /**
     * @desc Populates the text entries with the original information
     */
    setEditDetails() {
      this.firstName = this.mileageData.firstName;
      this.lastName = this.mileageData.lastName;
      this.email = this.mileageData.email;
      this.amount = this.mileageData.amount;
      this.vehicleSize = this.vehicleOptions.find(item => item.displayName === this.mileageData.vehicle);
      this.timestamp = this.mileageData.timestamp
        .split("/")
        .reverse()
        .join("-")
        .split("T")[0];
      this.destination = this.mileageData.destination;
    },

    submitForm() {
      this.submitLoading = true;
      this.editing ? this.editRequest() : this.createRequest();
    },


    /**
     * @desc Edit fields on a given mileage request and send the new mileage request object to the server
     */
    editRequest() {

      let editObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        dataTypeUUID: this.dataTypeUUID.uuid,
        amount: this.amount,
        timestamp: this.timestamp,
        destination: this.destination,
      };

      api.mileage
        .editMileageRequest(this.mileageData.id, editObject)
        .then(() => {
          notify.primary("Successfully edited mileage request", "top");
          try {
              this.$emit("refresh")
							this.$emit("close");
						} catch (err) {
							console.error(err);
						}
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },


    /**
     * @desc Create a new mileage reimbursement request
     */
    createRequest() {
      let createObject = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        dataTypeUUID: this.dataTypeUUID.uuid,
        amount: this.amount,
        timestamp: this.timestamp,
        destination: this.destination,
      };
      
      api.mileage
        .createMileageRequest(createObject, this.siteId)
        .then(() => {
          notify.primary("Successfully created mileage request", "top");
          try {
              this.$emit("refresh")
							this.$emit("close");
						} catch (err) {
							console.error(err);
						}
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};

export default CreateEditMileageComponent;
</script>

<style lang="less" src="@/assets/styles/mileage.less" scoped />