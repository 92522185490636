/**
 * diesel.js
 * 
 * API for handling getting all diesel associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves diesel data in line chart format
 * 
 * @param {String} filters query string to filter chart data with 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDieselLineChart = async function(filters) {
    return await axiosInstance.get(`/app/diesel/chart${filters}`).then(res => res);
};

/**
 * @desc Gets diesel data in CSV form. Once fetched, does browser stuff for the download
 * 
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDieselCSVExport = async function(siteId) {
    return axiosInstance({
        url: `/app/diesel/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `diesel_data.csv`); 
        document.body.appendChild(link);
        link.click();
    })
}


export default {
    getDieselLineChart,
    getDieselCSVExport,
}