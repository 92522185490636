<template>
<div>
<q-select
	outlined
    v-model="selections"
    :options="options"
    multiple
    map-options
    emit-value
    >
    
    <template v-slot:option="{itemProps, opt, selected, toggleOption }">
        <q-item v-bind="itemProps">
            <q-item-section >
                <q-checkbox 
                    checked-icon="task_alt"
                    unchecked-icon="highlight_off"
                    :label="opt.label" :model-value="selected" @update:model-value="toggleOption(opt)" />
            </q-item-section>
    </q-item>
    </template>
</q-select>
</div>
</template>
<script>
    const ScopeSelectionComponent = {
        name: 'ScopeSelection',
        props: ['targetScopes'],

        data(){
            return {
                selections: [],
                options: [

                    {label: 'Scope 1', value: "1"},
                    {label: 'Scope 2', value: "2"},
                    {label: 'Scope 3', value: "3"},
                ]
            }
            
        },
        created(){
            this.selections = this.targetScopes
        },
        watch: {
            selections(){
                this.$emit('update', this.selections)
            }
        }
    }
export default ScopeSelectionComponent
</script>


<style lang="less" src="@/assets/styles/createTarget.less" scoped />
