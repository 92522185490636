<template>
	<div class="main-tab-container" :style="{ 'margin-left': sidebarWidth }">
		<div v-if="store.dataView=='default'">
			<div class="header">
				<h1 class="main-heading">Carbon Offsets</h1>
			</div>
	
			<div class="tabs-container">
				<q-tabs
					v-model="selectedTab"
					active-color="primary"
					indicator-color="success"
					align="left"
					narrow-indicator
					dense
					no-caps
				>
					<q-tab
						v-for="tab in tabs"
						:key="tab.component"
						:name="tab.component"
						:label="tab.label"
						:ripple="false"
					/>
				</q-tabs>
			</div>
			<q-separator color="grey" />
			<component :is="selectedTab" v-bind="siteId" />
		</div>
		<TraceabilityViewComponent v-else />
	</div>
</template>

<script>
import { sidebarWidth } from "@/components/SidebarComponents/state";
import { ref } from "vue";
import CarbonOffsetComponent from "@/components/DataComponents/CarbonOffsetComponents/CarbonOffsetComponent.vue";
import CarbonOffsetUploadComponent from "@/components/DataComponents/CarbonOffsetComponents/CarbonOffsetUploadComponent.vue";
import { useSiteStateStore } from "@/stores/siteState.store";
import TraceabilityViewComponent from "@/components/DataComponents/TraceabilityComponents/TraceabilityViewComponent.vue";
import { useChartStateStore } from '@/stores/chartState.store';

import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";

export default {
	name: "carbon-offset-view",
	components: {
		CarbonOffsetComponent,
		CarbonOffsetUploadComponent,
		TraceabilityViewComponent,
	},
	data() {
		return {
			userRole: getRole(),
			permissions: permissions,
			store: useChartStateStore(),

			sidebarWidth,
			selectedTab: ref("CarbonOffsetComponent"),
			tabs: [
				{
					label: "Trends",
					component: "CarbonOffsetComponent",
				},
			],
		};
	},

	created() {
		this.store.fetchGraphData("carbon-offset", 'month');
		this.store.changeView("default");

		if (this.permissions.manualUpload.includes(this.userRole)) {
			this.tabs.push({
				label: "Upload Carbon Credits",
				component: "CarbonOffsetUploadComponent",
			});
		}
	},

	computed: {
		siteId() {
			return { siteId: useSiteStateStore().siteId };
		},
	},
};
</script>

<style lang="less" src="@/assets/styles/tabComponent.less" />