<template>
    <div class="datasource-list-container">
        <h2 class="tab-section-heading">Connect Stationary Fuels (Invoice Scraping)</h2>

        <div class="datasource-information">

            <div class="datasource-info-toc">
                <h5>Table of Contents</h5>

                <ul class="datasource-table-list">
                    <li>Overview</li>
                    <li><a href="#connected-suppliers">Connect a Supplier</a></li>
                    <li><a href="#supported-suppliers">Supported Suppliers</a></li>
                    <ul>
                        <li><a href="#coal-invoices">Coal</a></li>
                        <li><a href="#gas-invoices">Gas</a></li>
                    </ul>
                </ul>

            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4>Overview</h4>
                </div>
                <div class="datasource-info-text">
                    <p>Staionary fuels within the GreenHalo platform currently consists of gas and coal. We keep track of your data by scraping your usage information
                    from invoices provided by your coal and gas providers.</p>
                </div>
            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4 id="connected-suppliers">Connect a supplier</h4>
                </div>
                <div class="datasource-info-text">
                    <p>As mentioned, we retrieve you coal and gas usage data directly from your suppliers. This is achieved through an automation which extracts the
                    critical data from the invoices and uploads them. To ensure we receive the invoices, you will need to set up an inbox rule that will automatically
                    forwards any invoices you receive to our inbox. Please use the following links to set up these rules. </p>
                    <p>Please set the forwarding email address as <strong>'reports@greenhalo.nz'</strong>.</p>
                    <ul>
                        <li>Gmail: <a href="https://www.technipages.com/gmail-how-to-auto-forward-emails-from-specific-senders" target="_blank">How to Auto-Forward Emails From Specific Senders</a></li>
                        <li>Outlook:<a href="https://www.montana.edu/office365/windows-outlook-forwarding.html" target="_blank">Forwarding Email from Outlook (Windows)</a></li>
                    </ul>
                </div>
            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4 id="supported-suppliers">Suported Suppliers</h4>
                </div>
                <div class="datasource-info-text">
                    <p>The following contains the supported stationary fuel providers that have invoice scraping support.</p>
                    <ul>
                        <li id="coal-invoices">Coal</li>
                        <ul>
                            <li>Tailored Energy Solutions <a class="datasource-info-invoice-btn" @click="coalInvoice = true"> (View Example Invoice)</a></li>
                            <li>Birchfield Coal Mines (Coming Soon!)</li>
                        </ul>
                        <li id="gas-invoices">Gas</li>
                        <ul>
                            <li>RockGas<a class="datasource-info-invoice-btn" @click="gasInvoice = true"> (View Example Invoice)</a></li>
                            <li>NovaGas (Coming Soon!)</li>
                        </ul>
                    </ul>

                </div>
            </div>

        </div>

        <q-dialog
            v-model="gasInvoice"
            >
            <q-card class="scroll" style="min-width: 50vw">
                <q-img src="../../../../assets/tutorials/stationaryFuels/rockgas-invoice.png"></q-img>
            </q-card>
        </q-dialog>

        <q-dialog
            v-model="coalInvoice"
            >
            <q-card class="scroll" style="min-width: 50vw">
                <q-img src="../../../../assets/tutorials/stationaryFuels/tailored-energy-invoice.png"></q-img>
            </q-card>
        </q-dialog>

    </div>
</template>

<script>


const StationaryFuelsSourceOptionsComponent = {
    name: "StationaryFuelsSourceOptionsComponent",

    data() {
        return {

            gasInvoice: false,
            coalInvoice: false,
        };
    },

}

export default StationaryFuelsSourceOptionsComponent;
</script>

<style scoped>

@import "../../../../assets/styles/viewDataSources.less";

</style>