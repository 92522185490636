/**
 * data.js
 *
 * API for handling getting all general data
 */

import axiosInstance from "./axios-instance";


/**
 * @desc Retrieves list of emissios sources.
 *
 * @param {Boolean} [supplierAssociated] - Get only emission sources assoicated with suppliers
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionSources = async function (supplierAssociated = false) {
    return await axiosInstance
        .get(`/api/emission-sources`,  { params: { supplierAssociated } })
        .then((res) => res);
};

/**
 * @desc Retrieves list of emissios sources.
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionDisplayNames = async function () {
    return await axiosInstance
        .get(`/app/emission-display-names`).then((res) => res);
};


/**
 * @desc Retrieves the insights for the home page.
 *
 * @param {Number} days Tells the backend how far back to go for the insight. Should be in the
 * form {days: <numDaysBack>}
 * @returns {Promise<AxiosResponse<any>>}
 */
const getHomePageInsights = async function (days, siteId) {
    return await axiosInstance
        .get(`/app/insights/home-page/${days}`, { params: { siteId } })
        .then((res) => res);
};


/**
 * @desc Retrieves the total emissions across all emission sources over the past n days.
 *
 * @param {Number} days Tells the backend how far back to go for the insight. Should be in the
 * form {days: <numDaysBack>}
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTotalEmissionPastDays = async function (days, siteId) {
    return await axiosInstance
        .get(`/app/insights/home-page/total-emissions/${days}`, {params: {siteId}})
        .then((res) => res);
};

/**
 * @desc Checks if emission source data for the tenant exists or not
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const checkDataExists = async function () {
    return await axiosInstance.get("/app/data/exists").then((res) => res);
};


/**
 * @desc Checks if emission source data for the tenant exists or not
 * 
 * @param {String} emissionSource source to check
 * @param {Number} days number of days to check back
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastUsage = async function (emissionSource, days, siteId) {
    return await axiosInstance
        .get(`/app/data/usage/${emissionSource}/${days}`, { params: { siteId } })
        .then((res) => res);
};

/**
 * 
 * @desc Gets scoped emission data across provided start and end date
 * 
 * @param {String} [startDate] the start date to view data from
 * @param {String} [startDate] the end date to view data to
 * @param {String} [tenantId] the id of the tenant to retreve data for 
 * @returns 
 */
const getScopedEmissions = async function (startDate, endDate, tenantId, tag) {
    return await axiosInstance
        .get(`/app/data/emissions/scope/date`, {params: {startDate, endDate, tenantId, tag}})
        .then((res) => res);
}

/**
 * 
 * @desc Gets scoped emission data across provided granularity period
 * 
 * @param {String} [granularity] the period in the past from today to view data from
 * @param {String} [tenantId] the id of the tenant to retreve data for 
 * @returns 
 */
const getScopedEmissionsWithGranularity = async function (granularity, siteId) {
    return await axiosInstance
        .get(`/app/data/emissions/scope`, {params: {granularity: granularity ?? "", siteId: siteId}})
        .then((res) => res);
}

/**
 * @desc Submit a manual CSV upload for a given emission source
 * 
 * @param {String} URL url of emissions source to submit file to
 * @param {File} file CSV file to upload
 * @param {Number} siteId site to upload manual data for
 * @returns {Promise<AxiosResponse<any>>}
 */
const submitManualCSVUpload = async function (URL, file, siteId) {
    let formData = new FormData();
    formData.append('file', file);
    let headers = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return await axiosInstance.post(URL, formData, { params: { siteId } }, headers).then(res => res)
}

/**
 * @desc Gets emission snap shot report data
 * 
 * @param {String} filter filter string to potentially append e.g. "startDate=2023-01-01"
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionSnapshotData = async function (startDate, endDate, tag) {
    return await axiosInstance.get(`/app/data/emissions/totals`, {params: {startDate, endDate, tag}}).then((res) => res);
};


/**
 * @desc Gets and inserts data into the DB, from a specified API connection
 * @param {string} api 
 * @returns 
 */
const handleAPIConnections = async function(api, siteId) {
    return await axiosInstance.get(`/app/api-connection/${api}`, {params: { siteId }}).then((res) =>  res);
}


const getTotalEmissions = async function (startDate, endDate) {
    return await axiosInstance.get(`/app/data/emissions/total`, {params: {startDate, endDate}}).then((res) => res);
}

export default {
    getEmissionSources,
    getEmissionDisplayNames,
    getHomePageInsights,
    getTotalEmissionPastDays,
    checkDataExists,
    getPastUsage,
    getScopedEmissions,
    getScopedEmissionsWithGranularity,
    submitManualCSVUpload,
    getEmissionSnapshotData,
    handleAPIConnections,
    getTotalEmissions
};
