<template>
  <div v-if="flags.FLAG_4437_FORGOT_PASS" class="login">
    <h2 class="portal-title text-center">Reset Password</h2>
    <div class="login-password">
      <div>
        <span class="input-label">New Password</span>
        <q-form @submit="submitNewPassword()">
          <PasswordStrengthMeter v-model="newPassword" />
          <div style="margin-block: 10px 20px;">
            <span class="input-label">Confirm Password</span>
            <q-input type="password" class="input-control" v-model="confirmPassword" autocomplete outlined :rules="[(val) => val === newPassword || 'Password is different.']" required>
              <template v-slot:prepend>
                <q-icon name="o_lock" />
              </template>
            </q-input>
          </div>
          <q-btn label="Reset" type="submit" color="secondary" :loading="loading" no-caps rounded style="width: 100%;" :disable="(!newPassword || !confirmPassword) || (newPassword !== confirmPassword)" />
        </q-form>
        </div>
    </div>
  </div>
</template>

<script>
import notify from '@/services/util/notify';
import PasswordStrengthMeter from '../PasswordStrengthMeter.vue';
import api from '@/services/api/api';

export default {
  components: {
    PasswordStrengthMeter,
  },
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      loading: false,
    }
  },
  methods: {
    async submitNewPassword() {
      this.loading = true;

      try {
        await api.users.resetPassword(this.$route.params.token, this.newPassword);
        notify.primary("Successfully updated the user's password.");
        localStorage.clear();
        sessionStorage.clear();
        this.$router.push({path: "/auth/login", replace: true});
      } catch (err) {
        if (err.response.status === 400) {
          notify.warning("Link is invalid");
          this.$router.push({path: "/404", replace: true})
        } else {
          notify.withObject(err.response);
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>