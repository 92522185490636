<template>
  <q-card class="stripe-payment-container">
    <div v-if="store.pricing.selectedPlanDetails" class="stripe-payment-details">
      <div class="stripe-payment-details-heading">
        Order summary
      </div>

      <div class="stripe-payment-details-description">
        You are subscribing to GreenHalo and you will be billed ${{ (planDetails.subscriptionPrice) }} + GST monthly.<br>
        <span v-if=this.store.company.coupon>
          Your next payment of ${{ (planDetails.subscriptionPrice) }} + GST will be billed on {{ nextMonth(this.store.company.coupon.duration_in_months) }}
        </span>
        <span v-else>
          Your next payment of ${{ (planDetails.subscriptionPrice) }} + GST will be billed on {{ nextMonth() }}
        </span>
      </div>

      <hr>

      <div class="stripe-payment-details-pricing">
        <table>

          <tr>
            <td>
              {{ planDetails.subscriptionDescription }}
            </td>
            <td class="stripe-payment-detail-table-price">
              NZ${{ planDetails.subscriptionPrice }}
            </td>
          </tr>

          <tr>
            <td>
              GST
            </td>
            <td class="stripe-payment-detail-table-price">
              NZ${{ (planDetails.subscriptionPrice * 0.15).toFixed(2) }}
            </td>
          </tr>

          <tr>
            <td class="stripe-payment-detail-table-text">
              Total
            </td>
            <td class="stripe-payment-detail-table-price">
              <span v-if="this.store.company.coupon">NZ$0.00 <br> Coupon Applied</span>
              <span v-else>NZ${{ (planDetails.subscriptionPrice + (planDetails.subscriptionPrice * 0.15)).toFixed(2) }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div style="display: flex; justify-content: center;">
        <img src="@/assets/Treesignup-01.svg" alt="tree" style="max-height: 35em" />
      </div>
      <div class="stripe-payment-detail-terms">
        <span>
          New Zealand
        </span>
        <a class="item" href="https://www.greenhalo.nz/general-8" target="_blank">
          Terms
        </a>
        <a class="item" href="https://www.greenhalo.nz/general-8-1" target="_blank">
          Privacy
        </a>
      </div>
    </div>
    <div v-else class="stripe-payment-details">
      <div class="stripe-payment-details-heading">
        Please select a plan
      </div>


    </div>

    <div class="stripe-payment-card">
      <div class="stripe-payment-card-heading"> Subscribe to GreenHalo</div>
      <div class="stripe-payment-card-container">
        Apply Coupon code
        <div class="stripe-payment-card-coupon-container">
          <q-input class="coupon-input" :class="validCoupon == true ?? 'coupon-border-green'" v-model="stripeCoupon" outlined :loading="checkingCoupon" maxlength="50">
            <template v-slot:append>
              <q-icon v-if="validCoupon == true" name="done" color="green"/>
              <q-icon v-if="validCoupon == false" name="warning" color="yellow"/>
            </template>
            <q-tooltip v-if="validCoupon == false" class="text-body2">Invalid coupon code</q-tooltip>
          </q-input>
          <q-btn class="coupon-button" label="APPLY" @click="checkValidCoupon" unelevated outline :size="'md'"></q-btn>
        </div>
        <span v-if="this.store.company.coupon">Currently Applied: {{ this.store.company.coupon.id }} <span class="remove-coupon" @click="removeAppliedCoupon">(Remove)</span></span>
      </div>

      <StripeElements v-if="stripeLoaded" v-slot="{ elements }" ref="elms" :stripe-key="stripeKey"
         :elements-options="{
          mode: 'subscription',
          currency: 'nzd',
          amount: 999,
        }">


        <div class="stripe-payment-card-info-container">
          Card Information
          <StripeElement ref="card" :elements="elements" :options="cardOptions" :type="'cardNumber'"
            style="padding-bottom:3em" class="stripe-payment-card-number" />
        </div>

        <div class="stripe-payment-card-double-row">
          <div class="stripe-payment-card-expiry-container">
            Expiry date
            <StripeElement ref="card" :elements="elements" :options="cardOptions" :type="'cardExpiry'"
              class="stripe-payment-card-expiry" />
          </div>

          <div class="stripe-payment-card-expiry-container">
            Security code
            <StripeElement ref="card" :elements="elements" :options="cardOptions" :type="'cardCvc'"
              class="stripe-payment-card-cvc" />
          </div>

        </div>
      </StripeElements>

      <div class="stripe-payment-card-name-container">
        Name on card
        <q-input v-model="cardName" outlined maxlength="50"/>
      </div>

      <div class="stripe-payment-card-country-container">
        Country or origin
        <q-select v-model="selectedCountry" :options="countryList" outlined />
      </div>

      <div class="stripe-payment-card-agreement">
        <div>
          <q-checkbox v-model="agreement" />
          <span>I agree to GreenHalo Ltd's <a class="item" href="https://www.greenhalo.nz/general-8" target="_blank">Terms of Service.</a></span>
        </div>
        <div v-if="flags['FLAG_4360_ORPH_NEWSLETTER']">
          <q-checkbox v-model="this.store.company.newsletter"/>
          <span>I wish to subscribe to the official GreenHalo newsletter. </span>
        </div>
      </div>

      <q-btn :disabled="!valid" class="stripe-payment-card-button" type="button" :loading="stripePayLoading"
        @click="submitPayment" no-caps>
        Subscribe
        <q-tooltip class="text-body2" v-if="!valid">Please complete all sections and confirm agreement before
          subscribing</q-tooltip>
      </q-btn>

      <div class="stripe-payment-acknowledge-text">
        By confirming your subscription, you allow GreenHalo Ltd to charge your card for this payment and future payment
        in accordance with their terms. You can always cancel you subscription.
      </div>
    </div>
  </q-card>
</template>


<script>
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { ref } from 'vue';
import { useSignupStore } from "@/stores/signup.store";
import notify from '@/services/util/notify';
import api from '@/services/api/api';

const SignupParentComponent = {
  name: "SignupParent",
  components: {
    StripeElements,
    StripeElement,
  },

  data() {
    return {

      /* Stripe related vars */
      stripeLoaded: false,
      stripeKey: "",
      stripe: undefined,
      stripeLoading: false,
      hash: "",
      store: useSignupStore(),
      loading: false,
      card: ref(),
      elms: ref(),

      cardNumber: null,
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardCvc: null,
      cardName: "",
      stripeCoupon: "",
      validCoupon: undefined,
      applied: false,
      selectedCountry: 'New Zealand',
      countryList: ['New Zealand', 'Australia'],
      cardOptions: {
        style: {
          base: {
            iconColor: '#c4f0ff',
            color: '#000',
            fontWeight: '500',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#9569E1',
            },
          },
          invalid: {
            iconColor: '#D33',
            color: '#D33',
          },
        },
      },
      agreement: false,
      agreementSelect: null,
    };
  },


  computed: {
    stripePayLoading() {
      return this.store.stripePayLoading
    },
    planDetails() {
      return this.store.pricing.selectedPlanDetails
    },

    valid() {
      return (!Object.values(this.store.sectionsComplete).includes(false) && this.agreement)
    }
  },
  async created() {

    if (process.env.VUE_APP_ENV_MODE == "PROD") {
      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN;
    } else {
      this.stripeKey = process.env.VUE_APP_STRIPE_TOKEN_TEST;
    }
  },

  async beforeMount() {
    try {
      await loadStripe(process.env.VUE_APP_STRIPE_TOKEN_TEST)
      this.stripeLoaded = true
    } catch (error) {
      notify.error("There was an error loading stripe.");
    }
  },

  watch: {
    stripeCoupon(){
      if (this.validCoupon != undefined){
        this.validCoupon = undefined;
      }
    }
  },

  methods: {

    /**
     * Payment methods
     */
    async submitPayment() {
      this.store.stripePayLoading = true;
      try {
        var token = await this.$refs.elms.instance.createToken(this.$refs.card.stripeElement);
        this.store.company.token = token

        // If we done ran into an error, handle it and don't push to payNow.
        // Handling is based on the stripe error code docs: https://stripe.com/docs/api/errors
        let err = token.error;
        if (err) {
          switch (err.type) {
            case "validation_error":
              notify.warning(err.message, "top", "Invalid card information");
              break;
            default:
              notify.error(`Something went wrong while processing your card. Your card have not been charged yet. Please try again later.`, "top");
              break;
          }
          this.store.stripePayLoading = false;
        } else {
          this.$emit('payNow');
        }

      } catch (err) {
        notify.error("There was an error submitting payment.", "top");
        this.store.stripePayLoading = false
      }
    },

    /**
     * @desc Uses Stripe API to check if the token provided is valid
     */
    async checkValidCoupon() {

      this.store.company.coupon = "";

      await api.stripe.getStripeCoupon(this.stripeCoupon).then((res) => {
        this.validCoupon = true
        this.applied = true;
        this.store.company.coupon = res.data
      }).catch(() => {
        this.validCoupon = false;
      })
    },

    
    /**
     * Remove the currently applied coupon and reset the couponId value in the store. 
     */
    removeAppliedCoupon() {
      this.applied = false;
      this.stripeCoupon = '';
      this.store.company.coupon = undefined;
    },


    /**
     * Retrieve the next month. Additionally, the next month after the coupon has expired
     * @param {*} monthsToAdd Number of months to add to current date
     */
    nextMonth(monthsToAdd = 1) {
      var CurrentDate = new Date();
      CurrentDate.setMonth(CurrentDate.getMonth() + monthsToAdd);
      return CurrentDate.toLocaleDateString()
    },
  },
};

export default SignupParentComponent;


</script>

<style>
@import "../../assets/styles/stripe.css";
</style>