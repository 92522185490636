import dayjs from "dayjs";
require('dayjs/locale/en-nz')

const formats = ["DD-MM-YYYY", "DD/MM/YYYY"];

/**
 * Converts a date string to an ISO 8601 string format using defined formats.
 * 
 * @param {string} dateString - The date string to be converted.
 * @returns {string|undefined} The ISO 8601 string representation of the date, or undefined if conversion fails.
 */
const convertStringDate = (dateString) => {
	try {
		const parsedDate = dayjs(dateString, formats, true);
		return parsedDate.toISOString();
	} catch {
		return undefined;
	}
}

/**
 * Converts a date string to the short date format for the current locale
 * 
 * @param {Dayjs|Date|String} date 
 * @returns {String} Short date formatted string in the NZ locale format
 */

export const formatInLocaleShortDate = (date) => {
	return dayjs(date).locale('en-nz').format('L');
};

/**
 * Converts a given date to ISO 8601 string format.
 * 
 * @param {string|Date} date - The date to be converted. Can be a string or Date object.
 * @returns {string} The ISO 8601 string representation of the date.
 */
export const convertDateToISO = (date) => {
	const type = typeof date;

	if (type === "string") {
		return convertStringDate(date);
	} else if (date instanceof Date) {
		return date.toISOString();
	}
};
