<template>
    <div v-if="error" class="data-dashboard-insights">
        <q-card class="data-dashboard-insights-error">

            <div>
                <span class="insights-error-message">Sorry, something seems to have gone wrong with insights.</span>

                <q-icon size="lg" name="sentiment_dissatisfied" color="primary" />
            </div>
        </q-card>
    </div>
    <div v-else class="data-dashboard-insights">
        <InsightsCardComponent :insight=insights.insight1 :icon=icon />

        <InsightsCardComponent :insight=insights.insight2 :icon=icon />

        <InsightsCardComponent :insight=insights.insight3 :icon=icon />

        <InsightsCardComponent :insight=insights.insight4 :icon=icon />
    </div>
</template>
    
    
<script>
import InsightsCardComponent from "../InsightsComponents/InsightCardComponent.vue";
import { useSiteStateStore } from '@/stores/siteState.store';
import { useChartStateStore } from '@/stores/chartState.store';
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import dayjs from 'dayjs';

const InsightsContainerComponent = {
    name: "InsightsContainer",
    props: ['emissionSource', 'customDatasetId', 'icon'],
    data() {
        return {
            insights: [],
            siteStore: useSiteStateStore(),
            chartStore: useChartStateStore(),
            error: false,

            dateFormat: "DD/MM/YYYY",
            startDateError: false,
            endDateError: false,
        }
    },

    components: { InsightsCardComponent },

    watch: {
        "siteStore.siteId"() {
            this.getEmissionInsights();
        },

        //When the start date filter changes, update the date. Only sends update on valid date.
        "chartStore.filterStartDate": function (val, oldVal) {
            if (!val) {
                this.startDateError = false;
                this.getEmissionInsights();
            } else if (dayjs(val, this.dateFormat, true).isValid()) {
                this.startDateError = false;

                if (oldVal && dayjs(val, this.dateFormat).isSame(dayjs(oldVal, this.dateFormat))) {
                    // If value is the same and the watch is triggered ignore
                    return;
                }

                if (this.chartStore.filterGranularity == 'hour' && this.chartStore.filterEndDate) {
                    const start = dayjs(val, this.dateFormat);
                    const end = dayjs(this.chartStore.filterEndDate, this.dateFormat);

                    const allowedEnd = start.add(1, 'week') // Start Date + 1 Week

                    // Checks to see if end date is within a week of the start date
                    if (!end.isBetween(start, allowedEnd, null, "[]")) {
                        this.chartStore.setFilterEndDate(allowedEnd.format(this.dateFormat));
                    }
                }

                this.getEmissionInsights();
            } else if (val.length >= 10) { // If an invalid date is inputed
                this.startDateError = true;
            }
        },

        //When the end date filter changes, update the date. Only sends update on valid date.
        "chartStore.filterEndDate": function (val, oldVal) {
            if (!val) {
                this.endDateError = false;
                this.getEmissionInsights();
            } else if (dayjs(val, this.dateFormat, true).isValid()) {
                this.endDateError = false;

                if (oldVal && dayjs(val, this.dateFormat).isSame(dayjs(oldVal, this.dateFormat))) {
                    // If value is the same and the watch is triggered ignore
                    return;
                }

                if (this.chartStore.filterGranularity == 'hour' && this.chartStore.filterStartDate) {
                    const start = dayjs(this.chartStore.filterStartDate, this.dateFormat);
                    const end = dayjs(val, this.dateFormat);
                    const allowedStart = end.subtract(1, "week"); // End Date - 1 Week

                    // Checks to see if start date is within a week of the end date
                    if (!start.isBetween(allowedStart, end, null, "[]")) {
                        this.chartStore.setFilterStartDate(allowedStart.format(this.dateFormat));
                    }
                }

                this.getEmissionInsights();
            } else if (val.length >= 10) { // If an invalid date is inputed
                this.endDateError = true;
            }
        },

        'chartStore.filterGranularity': function() {
            this.getEmissionInsights();
		},
    },

    created() {
        this.getEmissionInsights();
    },

    methods: {
        /**
        * @desc Retrieve the insights for a given emission source. If emission source isn't custom,
        * query the standard emission sources insights route. If it is custom, query the custom dataset 
        * insights method using the custom dataset id that is passed from props.
        */
        async getEmissionInsights() {
            const {startDate, endDate} = this.retrieveDateFilters();
            
            if (this.emissionSource != "custom") {
                //If we set insights to empty object, then the loading spinners appear as they are handled in the child component.
                this.insights = {};
                api.emissions.getEmissionInsights(this.emissionSource, this.siteStore.siteId, startDate, endDate, this.chartStore.filterGranularity).then((res) => {
                    this.insights = res.data
                }).catch((err) => {
                    notify.withObject(err.response, "top", "Insights");
                    this.error = true
                });
            } else {
                api.customDatasets.getCustomDatasetInsights(this.customDatasetId, this.siteStore.siteId).then((res) => {
                    this.insights = res.data;
                }).catch((err) => {
                    notify.withObject(err.response, "top", "Insights");
                    this.error = true;
                });
            }
        },


        /**
         * Retrieve the date filters and apply the required changes depending on the granularity.
         */
        retrieveDateFilters() {
            let startDate = this.chartStore.filterStartDate ? dayjs(this.chartStore.filterStartDate, this.dateFormat, true) : undefined;
            let endDate = this.chartStore.filterEndDate ? dayjs(this.chartStore.filterEndDate, this.dateFormat, true) : undefined;

            if (this.chartStore.filterGranularity == 'hour') {
                const today = dayjs();

                if (!startDate && !endDate) {
                    endDate = today;
                    startDate = today.subtract(1, "week"); 
                } else if (!startDate) {
                    startDate = endDate.subtract(1, "week");
                } else if (!endDate) {
                    endDate = startDate.add(1, "week"); 
                }
            } else if (this.chartStore.filterGranularity == 'minute') {
                // If 'minute' is selected as the granularity set start and end date to the same balues
                if (!startDate) {
                    startDate = dayjs();
                }
                endDate = startDate.endOf("day");
            }

            if (startDate && !this.startDateError) {
                startDate = startDate.startOf('day').toISOString();
            }

            if (endDate && !this.endDateError) {
                endDate = endDate.endOf('day').toISOString();
            }

            return {startDate, endDate}
        }
    }
};

export default InsightsContainerComponent;
</script>
    
<style scoped>
@import "../../../assets/styles/data.less";
</style>
    
    