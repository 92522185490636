/**
 * waste.js
 * 
 * API for handling getting all waste associated data
 */

import axiosInstance from "./axios-instance";


/**
 * @desc Retrieves waste chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWasteChartData = async function (granularity, siteId) {
    return await axiosInstance.get(`/app/waste/chart/${granularity}`, { params: { siteId } }).then(res => res);
};


/**
 * @desc Generate waste CSV file
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWasteCSV = async function (siteId) {
    return axiosInstance({
        url: `/app/waste/csv`,
        method: 'GET',
        responseType: 'blob',
        params: { siteId }
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `waste-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Get pie chart type split by tpe of waste
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWasteChartByType = async function (siteId) {
    return await axiosInstance.get(`/app/waste/chart`, { params: { siteId } }).then(res => res);
};


/**
 * @desc Retrieve total amount generated year in kilograms 
 *
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getKilogramsProducedYearInsight = async function (siteId) {
    return await axiosInstance.get(`/app/waste/insights/kilograms`, { params: { siteId } }).then(res => res);
}


/**
 * @desc Retrieved the amount of waste generated current week
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getKilogramsProducedWeekInsight = async function (siteId) {
    return await axiosInstance.get(`/app/waste/insights/kilograms-week`, { params: { siteId } }).then(res => res);
}


/**
 * @desc Retrieve total amount waste emissions generated
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionsProducedInsight = async function (siteId) {
    return await axiosInstance.get(`/app/waste/insights/emissions`, { params: { siteId } }).then(res => res);
}


/**
 * @desc Retrieves the percentage of waste that is recycling
 * 
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getRecyclingPercentageInsight = async function (siteId) {
    return await axiosInstance.get(`/app/waste/insights/recycling-percentage`, { params: { siteId } }).then(res => res);
}


/**
 * @desc retrieves all waste connections
 * @param {*} siteId id of site to retrieve connections for.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getConnections = async function (siteId) {
    return await axiosInstance.get(`/api/waste/connections`, { params: { siteId } }).then(res => res)
}


/**
 * @desc posts a new connection to the server
 * @param {*} payload body of request that contains new connection's information.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const postConnection = async function (payload) {
    return await axiosInstance.post(`/api/waste/connections`, payload).then(res => res)
}


/**
 * @desc edits/updates an existing waste connection by id
 * @param {*} id id of connection to patch
 * @param {*} payload data of connection to edit
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchConnection = async function (id, payload) {
    return await axiosInstance.patch(`/api/waste/connections/${id}`, payload).then(res => res)
}


/**
 * @desc deletes a waste connection by id
 * @param {*} id the id of the connection to delete
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteConnection = async function (id) {
    return await axiosInstance.delete(`/api/waste/connections/${id}`).then(res => res)
}


export default {
    getWasteChartData,
    getWasteCSV,
    getWasteChartByType,
    getKilogramsProducedYearInsight,
    getKilogramsProducedWeekInsight,
    getEmissionsProducedInsight,
    getRecyclingPercentageInsight,

    getConnections,
    postConnection,
    patchConnection,
    deleteConnection
}