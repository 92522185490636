import {defineStore} from 'pinia';
import {ref} from "vue";
import {emitter} from "../config/emitter"
import api from "@/services/api/api";

export const useTargetStore = defineStore('targets', () => {

    const showOptions = ref(false)
    const showChangeValueComponent = ref(false)
    const chartMetaData = ref({})
    const chartData = ref({})
    const currentTargetId = ref({})
    const loadingChartData = ref(true)
    const selectedIndex = ref()
    
    function $reset (){
        showOptions.value = false;
        showChangeValueComponent.value = false;
        chartMetaData.value = {};
        chartData.value = {};
        currentTargetId.value = {};
        loadingChartData.value = true;
        selectedIndex.value = undefined;
    }


    //     "data": {         
    //           "year": 2023,
    //           "oldValue": -1,      
    //           "newValue": -1,           
    //           "comment": "wah wah wee wuh",
    //       }
    //     }
    async function postUserTargets(dataset, data) {
      try {
        await api.targets.postNewUserTargetPoint(
          { targetId: currentTargetId.value, dataset: dataset },
          data
        );
        await loadChartData(currentTargetId.value);
        showChangeValueComponent.value = false;
        showOptions.value=false

      } catch (error) {
        throw new Error("Something went wrong while adding new point");
      }
    }

    function removeUserTargetByLabel(label) {
        const userTargets = userTargets.value 
        delete userTargets[label]   
    } 


    async function loadChartData(id) {
        try {
            chartData.value = (await api.targets.getTargetGraphById(id)).data;
            currentTargetId.value = id
        } finally {
            loadingChartData.value = false;
        }
    }
    
    emitter.on('targetGraphClick', (evt) => {
        const editableActiveElements = []
        evt.activeElements.forEach((x)=> {
            if(
                chartData.value.datasets[x.datasetIndex].label == 'Best Case Emission Trend' || 
                chartData.value.datasets[x.datasetIndex].label == 'Worst Case Emission Trend' ||
                chartData.value.datasets[x.datasetIndex].label == 'Target Emission Trend' ){
                editableActiveElements.push(x)
            }
        })

        if( chartMetaData.value.x==evt.evt.x && chartMetaData.value.y==evt.evt.y){
            showOptions.value=!showOptions.value
        } else if (editableActiveElements.length > 0){
            showOptions.value = true
            var selectedElements = [];
            editableActiveElements.forEach((element)=> {selectedElements.push(
                {
                    datasetIndex: element.datasetIndex,
                    index: element.index
                }
            )
        })
            chartMetaData.value = {
                x: evt.evt.x,
                y: evt.evt.y,
                activeElement: selectedElements,
                index: selectedElements.length > 0 ? selectedElements[0]["index"] : undefined
            }
            
        } else {
            showOptions.value = false
        }
    })

    return {
        showOptions,
        showChangeValueComponent,
        chartMetaData,
        selectedIndex,
        chartData,
        loadingChartData,
        currentTargetId,
        loadChartData,
        postUserTargets,
        removeUserTargetByLabel,
        $reset
    }
})