<template>
  <div class="supplier-upload-container">
    <div class="supplier-upload-options-container">
      <q-select
        outlined
        v-model="selectedTenant"
        label="Tenant"
        :options="connectedTenants"
        style="width: 250px"
        :loading="loadingConnectedTenants"
        option-label="name"
      />
      <q-select
        outlined
        v-model="selectedSite"
        label="Site"
        :options="sites"
        style="width: 250px"
        :loading="loadingSites"
        option-label="name"
        :disable="!selectedTenant"
      />
      <q-select
        outlined
        v-model="selectedEmissionSource"
        label="Emission Source"
        :options="emissionSources"
        style="width: 250px"
        :loading="loadingSiteEmissionSources"
        option-label="sourcedisplayname"
        :disable="!selectedSite"
      />
    </div>
    <q-tabs class="supplier-upload-tabs" dense no-caps active-color="primary" indicator-color="success" align="left" narrow-indicator>
      <q-route-tab 
        :to="{ query: { tab: 'manual' } }"
        label="Manual Upload" 
        exact 
        replace 
      />
      <q-route-tab 
        v-if="hasInvoice"
        :to="{ query: { tab: 'invoice' } }"
        label="Invoice Upload"
        exact
        replace
      />
    </q-tabs>
    <div class="supplier-upload-main-section">
      <ManualUploadComponent
        v-if="selectedEmissionSource && selectedSite && this.$route.query.tab === 'manual'"
        :emissionSource="selectedEmissionSource.urlName"
        :exampleRow="selectedEmissionSource.datasetDefinition.exampleRow"
        :siteId="selectedSite.id"
      />
      <span v-else-if="!selectedEmissionSource || !selectedSite">Please select an emission source</span>
      <InvoiceUploadComponent
        ref="invoiceUpload"
        v-show="hasInvoice && selectedEmissionSource && selectedSite && this.$route.query.tab === 'invoice'"
        @invoiceExists="(value) => { hasInvoice = value; !value ? $router.replace({ query: { tab: 'manual' } }) : _ }"
        :emissionSource="selectedEmissionSource?.urlName"
        :siteId="selectedSite?.id"
      />
    </div>
  </div>
</template>

<script>
import ManualUploadComponent from '../../ManualUploadComponents/ManualUploadComponentTemp.vue';
import InvoiceUploadComponent from '../../ManualUploadComponents/InvoiceUploadComponentTemp.vue';
import api from '@/services/api/api';
import notify from '@/services/util/notify';

export default {
  components: {
    ManualUploadComponent,
    InvoiceUploadComponent,
  },
  data() {
    return {
      loadingConnectedTenants: false,
      loadingSites: false,
      loadingSiteEmissionSources: false,
      selectedTenant: null,
      selectedSite: null,
      selectedEmissionSource: null,
      sites: [],
      connectedTenants: [],
      emissionSources: [],
      hasInvoice: false,
    }
  },
  created() {
    this.$route.query.tab = "manual";
    this.getConnectedTenants();
  },
  watch: {
    async selectedTenant() {
      if (this.selectedTenant) {
        const token = (await api.users.getSupplierTenantToken(this.selectedTenant.tenantHash)).data.data;
        sessionStorage.setItem("authToken", token);
        await this.getSites();
      }

      this.selectedSite = null;
    },
    async selectedSite() {
      if (this.selectedSite) {
        await this.getSiteEmissionSources();
        this.selectedEmissionSource = this.emissionSources.find((source) => {
          return source.emissionSourceId === this.selectedEmissionSource?.emissionSourceId
        });
      } else {
        this.selectedEmissionSource = null;
      }
    },
  },
  methods: {
    async getConnectedTenants() {
      this.loadingConnectedTenants = true;

      try {
        this.connectedTenants = (await api.supplier.getConnectedTenants()).data;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingConnectedTenants = false;
      }
    },
    async getSites() {
      this.loadingSites = true;

      try {
        this.sites = (await api.sites.getSites()).data;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSites = false;
      }
    },
    async getSiteEmissionSources() {
      this.loadingSiteEmissionSources = true;

      try {
        this.emissionSources = (await api.sites.getSiteEmissionSources(this.selectedSite.id)).data.emissionSources;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSiteEmissionSources = false;
      }
    },
  }
}
</script>

<style lang="less" scoped>
.supplier-upload-container {
  .supplier-upload-card {
    height: 90vh;
  }

  .supplier-upload-tabs {
    margin-block: 30px;
  }

  .supplier-upload-options-container {
    display: flex;
    gap: 15px;  
  }
}
</style>