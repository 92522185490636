import axiosInstance from "./axios-instance";

/**
 * @desc Get all the data feeds for a given tenancy.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDataFeeds = async () => {
    return await axiosInstance.get(`/api/data-feeds/connected`).then((res) => res);
};

const getFrontendFormConfig = async (supplier) => {
  return await axiosInstance.get(`/api/data-feeds/frontend/config/${supplier}`).then((res) => res);
};

/**
 * @desc Get all the unmapped sites for a data feed connection
 * @param {Number} feedConnectionId - The id for the connection to get unmapped site ids for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUnmappedSites = async (feedConnectionId) => {
  return await axiosInstance.get(`/api/data-feeds/${feedConnectionId}/site-mappings/unmapped`).then((res) => res);
};

const updateConnectionSiteMappings = async (feedConnectionId, mappings) => {
  return await axiosInstance.put(`/api/data-feeds/${feedConnectionId}`, { siteMappings: mappings }).then((res) => res);
}

const deleteConnection = async (connectionId) => {
  return await axiosInstance.delete(`/api/data-feeds/${connectionId}`).then(res => res)
}

const getAvailableConnections = async () => {
  return await axiosInstance.get("/api/data-feeds/available-connections").then(res => res);
};

const createConnection = async (supplierUUID, payload) => {
  return await axiosInstance.post(`/api/data-feeds/${supplierUUID}`, payload).then(res => res);
};

const getEmissionFactorMappings = async (connectionId, site = "") => {
  return await axiosInstance.get(`/api/data-feeds/${connectionId}/emission-factors/mapping`, { params: { site } }).then(res => res);
};

const updateEmissionFactorMappings = async (connectionId, mapping) => {
  return await axiosInstance.post(`/api/data-feeds/${connectionId}/emission-factors/mapping`, mapping).then(res => res);
};

export default {
    getDataFeeds,
    getFrontendFormConfig,
    getUnmappedSites,
    updateConnectionSiteMappings,
    deleteConnection,
    getAvailableConnections,
    createConnection,
    getEmissionFactorMappings,
    updateEmissionFactorMappings,
}
