// All available roles

module.exports = {
	Master: "master",
	Admin: "admin",
	User: "user",
	Demo: "demo",
	Editor: "editor",
	SiteManager: "siteManager",
	Auditor: "auditor",
	Consultant: "consultant",
	Device: "device",
	
	TestMaster: "testMaster",
	Supplier: "supplier",
	Pipeline: "pipeline",
	Guest: "guest",
};