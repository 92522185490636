<template>
    <div class="login">
        <h3 class="login-attempt">Logging in...</h3>
        <div class="loader"></div>
    </div>
</template>

<script>

const LoadingComponent = {
    name: "Loading",

    data() {
        return {

        }
    }
}

export default LoadingComponent

</script>

<style scoped>
@import "../assets/styles/elements/loader.css";
</style>
