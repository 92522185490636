import axiosInstance from "./axios-instance";


const updateSingleMapping = async ({columnName, clientLabel, appLabel}) => {
	return await axiosInstance.put(
		`/api/upload/manual/mappings/${columnName}`,
		{clientLabel, appLabel}, // Body
	).then(res => res);
};


export default {
	updateSingleMapping,
};
