/**
 * mileage.js
 * 
 * All api requests relating to mileage data.
 */ 

import axiosInstance from "./axios-instance";

/**
 * @desc Attempt to retrieve all mileage data for given company
 * 
 * @param {Number} individual retrieve either individual or all mileage claims 
 * @param {Number} siteId contains siteId to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMileageData = async function (individual, siteId) {
    return await axiosInstance.get('/api/mileage', {params: {individual, siteId}} ).then(res => res);
};


/**
 * @desc Attempt to create a new mileage reimbursement request
 * 
 * @param {String} payload contains mileage request information
 * @param {Number} siteId contains siteId to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const createMileageRequest = async function (payload, siteId) {
    return await axiosInstance.post(`/api/mileage`, payload, { params: { siteId }}).then(res => res);
};


/**
 * @desc Edit an existing mileage reimbursement request
 * 
 * @param {String} payload contains mileage request information to update
 * @returns {Promise<AxiosResponse<any>>}
 */
const editMileageRequest = async function (id, payload) {
    return await axiosInstance.patch(`/app/mileage/${id}`, payload).then (res => res);
};


/**
 * @desc Set the status of an existing mileage reimbursement request to approved
 * 
 * @param {Number} entryId id of request to approve
 * @returns {Promise<AxiosResponse<any>>}
 */
const approveMileageReimbursementRequest = async function (entryId) {
    return await axiosInstance.patch(`/app/mileage/${entryId}/approve`).then(res => res);
};


/**
 * @desc Set the status of an existing mileage reimbursement request to deny
 * 
 * @param {Number} entryId id of request to deny
 * @returns {Promise<AxiosResponse<any>>}
 */
const denyMileageRequestReimbursementRequest = async function (entryId) {
    return await axiosInstance.patch(`/app/mileage/${entryId}/deny`).then(res => res);
};


export default {
    getMileageData,
    createMileageRequest,
    editMileageRequest, 
    approveMileageReimbursementRequest, 
    denyMileageRequestReimbursementRequest
}