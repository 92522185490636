<template>
  <div class="goals-container">
    <q-icon
      class="close-modal"
      size="2rem"
      name="close"
      @click="$emit('close')"
    />

    <h2 class="goal-name">{{ goal.name }}</h2>

    <div v-if="loading" class="loading-spinner">
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-if="!loading" class="goal-info-container">
      <div class="goal-attributes-container">
        <div class="goal-usage-values">
          <p class="usage-value">
            Historical average daily usage: {{ goal.startValue }} {{ unit }}
          </p>
          <p class="usage-value">
            Target daily average usage: {{ goal.targetValue }} {{ unit }}
          </p>
          <p
            v-if="goal.percentageChange < 0"
            class="usage-value good-usage-value"
          >
            Current daily average usage: {{ goal.averageDailyUsage }} {{ unit }}
          </p>
          <p
            v-if="goal.percentageChange > 0"
            class="usage-value bad-usage-value"
          >
            Current daily average usage: {{ goal.averageDailyUsage }} {{ unit }}
          </p>
        </div>
      </div>
      <div class="goal-usage-column">
        <div class="emission-source-name">
          {{ goal.emissionSource }}
        </div>
        <div class="graph-panel">
          <div class="graph-title">
            Current percentage change in average daily usage of
            {{ goal.emissionSource }}:
            {{ goal.percentageChange.toFixed(0) }}%
          </div>

          <div style="margin-top: 100px;" >
            <div class="graph-bars-container">
              <span
                class="progress-bar graph-black-bar"
                :style="'left: ' + graphIndicatorLeftPosition + '%;'"
              >
                <q-tooltip style="font-size: 1.15rem"
                  >{{ (goal.percentageChange / goal.targetPercentageChange * 100).toFixed(0) }}% of way to
                  target daily usage.</q-tooltip
                >
              </span>
              <div class="graph-bar graph-red-bar" />
              <div class="graph-bar graph-green-bar" />
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div style="margin-left: -20px; color: #400">
              {{ (goal.targetPercentageChange * -1).toFixed(1) }}%
            </div>
            <div style="margin-right: -20px; color: #040">
              {{ goal.targetPercentageChange.toFixed(1) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "chartjs-adapter-date-fns";
import api from "@/services/api/api";
import notify from "@/services/util/notify";

const ViewGoalProgressComponent = {
  name: "Goals",
  props: ["goalId", "unit"],

  components: {},

  data() {
    return {
      // Loading vars
      loading: false,
      // Goal data
      goal: {},
      // Left percentage of goal progress graph
      graphIndicatorLeftPosition: 90,
    };
  },

  /**
   * @desc On page load, retrieve goal data
   */
  async created() {
    await this.getGoalInfo();
  },

  methods: {

    /**
     * @desc Retrieves goal data of specific goal with matching goalId in the logged in user's tenancy
     */
    async getGoalInfo() {
      this.loading = true;
      await api.goals
        .getGoalInfo(this.goalId ? this.goalId : this.$route.params.goalId)
        .then((res) => {
          this.goal = res.data;

          // Calculate target percentage change in avg daily usage
          let targetPercentageChange =
            ((res.data.targetValue - res.data.startValue) /
              res.data.startValue) *
            100;
          // Calculate current percentage change in avg daily usage
          let percentageChange =
            ((res.data.averageDailyUsage - res.data.startValue) /
              res.data.startValue) *
            100;
          this.goal.percentageChange = !isNaN(percentageChange)
            ? percentageChange
            : 0;
          this.goal.targetPercentageChange = !isNaN(targetPercentageChange)
            ? targetPercentageChange
            : 0;

          // Calculate position for goal progress bar graph
          this.graphIndicatorLeftPosition =
            50 + ((percentageChange / targetPercentageChange) * 100)/2;
          // Check that the indicator won't fall out of bounds.
          if (this.graphIndicatorLeftPosition > 100) {
            this.graphIndicatorLeftPosition = 100;
          } else if (this.graphIndicatorLeftPosition < 0) {
            this.graphIndicatorLeftPosition = 0;
          }
        })
        .catch(() => {
          notify.error("There was an issue retrieving goal data", "top");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

export default ViewGoalProgressComponent;
</script>

<style lang="less" src="../../assets/styles/goals.less" scoped />