/**
 * tranportFuel.js
 * 
 * API for handling getting all transport fuel associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Get transport fuel data in line chart format.
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTransportFuelsChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/emissions/transport-fuel/chart/${granularity}`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Return a list of currently configured transport fuel connections
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTransportFuelsConnections = async function() {
    return await axiosInstance.get(`/app/transport-fuels/sources`).then(res => res);
};


/**
 * @desc Get past year transport fuel usage insight.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearUsageInsight = async function(siteId) {
    return await axiosInstance.get(`/app/transport-fuels/insights/litres`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Get past year transport fuel emissions insight.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearEmissionsInsight = async function(siteId) {
    return await axiosInstance.get(`/app/transport-fuels/insights/emissions`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Get most frequent entry month in past year.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMostFrequentMonthInsight = async function(siteId) {
    return await axiosInstance.get(`/app/transport-fuels/insights/frequent-month`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Get total money spent on transport fuels in past year.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearCost = async function(siteId) {
    return await axiosInstance.get(`/app/transport-fuels/insights/money`, { params: { siteId }}).then(res => res);
};


export default {
    getTransportFuelsChart,
    getTransportFuelsConnections,
    getPastYearUsageInsight,
    getPastYearEmissionsInsight,
    getMostFrequentMonthInsight,
    getPastYearCost,
}