<template>
        <q-btn v-if="!isRoot"
        class="back-arrow"
        flat
        round
        size="lg"
        color="primary"
        icon="arrow_back"
        @click="this.$router.back()"
        />
    <div class="root">
        <div class="header">
            <span class="title"> {{ folderTitle }} </span>
            <span class="subtitle"> {{ folderSubtitle }}</span>
        </div>

        <div v-if="loading" class="loading">
            <q-spinner color="primary" size="6em"/>
        </div>

        <div v-if="folders.length" class="folders">
            <q-card v-for="folder in folders" v-bind:key="folder.id" class="folder" flat @click="$router.push({path: `/files/${folder.id}`} )">
                <q-icon class="icon" name="folder" size="25em" color="grey-2"/>
                <span class="name"> {{ folder.name }} </span>
            </q-card>
        </div>

        <!-- TODO: Remove v-if when files are supported in root folder -->
        <div v-if="!isRoot" :class="`files ${!folders.length ? 'files-only' : ''}`">
            <div class="file-table">
                <span class="sub-header">Files</span>
                <q-table :loading="loading" bordered class="q-table-files" :rows="files" :columns="columns" row-key="name"
                    :virtual-scroll-sticky-size-start="48" :rows-per-page-options="[15]"
                    :no-data-label="tableNoDataLabel">
                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props">

                            <q-btn v-if="userRole == 'admin' || 'demo'" icon="edit" flat color="primary"
                                @click="
                                    i = props.row.name.lastIndexOf('.');
                                    if (i !== -1) {
                                        updatedFileName = props.row.name.slice(0, i)
                                        fileType = props.row.name.slice(i);
                                    } else {
                                        updatedFileName = props.row.name;
                                        fileType = '';
                                    }
                                ">
                                <q-popup-proxy>
                                    <q-card>
                                        
                                        <q-form class="form" @submit="updateFile(props.row.id, updatedFileName + fileType)">
                                            <q-card-section style="margin-bottom: -20px;">
                                                <span class="text-bold row">Rename File</span>
                                            </q-card-section>
                                            <q-card-section>
                                                <q-input v-model="updatedFileName" dense style="margin-bottom: -20px;" maxlength="50"
                                                    label="File name" :rules="[ val => val && val.length > 0 || 'Please enter a file name']" />
                                            </q-card-section>
                                        
                                            <q-card-actions align="right">
                                                <q-btn color="grey-6" v-close-popup>Cancel</q-btn>
                                                <q-btn type="submit" :loading="loadingUpdate" color="secondary">Confirm</q-btn>
                                            </q-card-actions>
                                        </q-form>
                                        
                                         
                                    </q-card>
                                </q-popup-proxy>
                            </q-btn>

                            <q-btn icon="download" flat color="primary"
                            @click="getFileDownload(props.row.id, props.row.name)"></q-btn>

                            <q-btn v-if="userRole == 'admin' || 'demo'" icon="delete" flat color="deep-orange">
                                <q-popup-proxy>
                                    <q-card>
                                        <q-card-section>
                                            <span class="text-bold row">Are you sure you</span>
                                            <span class="text-bold row">want to delete this file?</span>
                                            <span class="row q-pt-sm">This action can not be undone.</span>
                                        </q-card-section>
                                        <q-card-actions align="right">
                                            <q-btn color="grey-6" v-close-popup>Cancel</q-btn>
                                            <q-btn :loading="loadingUpdate" color="red" @click="deleteFile(props.row.id)">Confirm</q-btn>
                                        </q-card-actions> 
                                    </q-card>
                                </q-popup-proxy>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>

            <div class="file-uploader">
                <span class="sub-header" >Upload your files below</span>
                <div class="uploader" >
                    <q-file class="upload-files justify-center items-center" v-model="file" type="file" ref="file"
                        label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
                        :item-aligned=true>

                        <template v-if="file" v-slot:prepend>
                            <q-icon name="cancel" @click.stop.prevent="file = null" class="cursor-pointer" />
                        </template>
                    </q-file>

                    <div class="manual-button-center">
                        <q-btn class="file-input-button" no-caps color="green" :disabled="this.file == null" @click="uploadFile"
                            label="Upload" icon-right="upload" :loading="loadingUpload" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import getRole from "@/services/util/role";

import notify from "@/services/util/notify";
import api from "@/services/api/api";


const FileStorageComponent = {
    name: "FileStorageComponent",
    data() {
        return {

            // Auth
            userRole: getRole(),

            folderId: this.$route.params.folderId,
            folderName: undefined,
            folderDescription: undefined,
            file: undefined,

            updatedFileName: "",
            files: [],
            folders: [],

            columns: [
                {
                    name: "fileName",
                    style: "width: 25%",
                    label: "File Name",
                    align: "left",
                    field: "name",
                    sortable: true,
                    required: true,
                },
                {
                    name: "uploadDate",
                    style: "width: 20%",
                    label: "Uploaded",
                    align: "left",
                    field: "uploaded",
                    format: (date) => new Date(date).toLocaleDateString('en-NZ', {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false
                    }),
                    sortable: true,
                },
                {
                    name: "uploaded_by",
                    style: "width: 20%",
                    label: "Uploaded By",
                    align: "left",
                    field: "email",
                    sortable: true,
                },
                {
                    name: "actions",
                    style: "width: 10%",
                    label: "Actions",
                    align: "left",
                    field: "",
                },
            ],

            loading: false,
            loadingUpload: false,
            loadingUpdate: false,
        };
    },

    computed: {
        isRoot() {
            return !this.folderId
        },

        folderTitle() {
            if (this.folderId) {
                return this.folderName;
            }
            return "Upload your Plans & Documents"
        },

        folderSubtitle() {
            if (this.folderId) {
                return this.folderDescription;
            }
            return "Select a folder to upload your documents"
        },

        tableNoDataLabel() {
            if (this.loading) {
                return ""
            }
            return this.userRole == 'demo' ? 'No files available on demo account' : 'No files saved'
        },
    },

    mounted() {
        this.getData();
    },

    methods: {
        /**
         * @desc Gets all files and subfolders for folder with provided file id
         */
        async getData() {
            this.loading = true;

            const folder = this.isRoot ? "root" : this.folderId

            try {   
                const data = (await api.filestorage.getFolderInfo(folder)).data;
                this.folderName = data.name;
                this.folderDescription = data.description;
                this.folders = data.folders;
                this.files = data.files;

            } catch (err) {
                notify.error(err.response.data ?? 'Unable to fetch folder informaiton', 'top');
            } finally {
                this.loading = false
            }
        },


        /**
         * @desc Attempt to delete a selected file.
         * 
         * @param {Number} fileId the id of the file to delete
         */
        deleteFile(fileId) {
            this.loadingUpdate = true;
                api.file
                    .deleteDocument(fileId)
                    .then(() => {
                        notify.primary("File successfully deleted", "top");
                    })
                    .catch((error) => {
                        notify.error(error.response.data ?? 'Could not establish connection to file storage', 'top');
                    }).finally(() => {
                        this.getData();
                        this.loadingUpdate = false; 
                    });
        },


        /**
         * @desc Attempt to update a selected file.
         * 
         * @param {Number} fileId the id of the file to update
         * @param {String} fileName the new file name to update
         */
        updateFile(fileId, fileName) {
            this.loadingUpdate = true;

            api.file
                .renameDocument(fileId, fileName)
                .then(() => {
                    notify.primary("File successfully renamed", "top");
                })
                .catch(() => {
                    notify.error("Error updating file", "top");
                })
                .finally(() => {
                    this.getData();
                    this.loadingUpdate = false;
                });
        },


        /**
         * @desc Attempt to download a select file
         * @param {Strings} fileId id of the file to download
         * @param {Strings} fileName name of the file being downloaded
         */
        getFileDownload(fileId, fileName) {
            try {
                api.file
                .getSingleFile(fileId, fileName)
                .then(() => {
                    notify.primary("File successfully downloaded", "top");
                })
                .catch((error) => {
                    notify.error(error.response.data ?? 'Could not establish connection to file storage', 'top');
                });
            } catch {
                notify.error("Error downloading file", "top");
            }
        },



        /**
         * @desc Attempt to upload the file
         */
        async uploadFile() {
            this.loadingUpload = true;
            
            try {
                await api.file.postDocument(this.file, this.folderId);
                notify.primary("Successfully uploaded file", "top", `Uploaded ${this.file.name}`);
                this.getData();
            } catch (error) {
                let status = error?.response?.status;
                status >= 400 && status <= 499 ?
				notify.warning(error?.response?.data ?? "Unable to upload document. Please try again", "top", "Upload Unsuccessful") : 
				notify.error(error?.response?.data ?? "Unable to upload document. Please try again", "top", "Upload Unsuccessful");
            } finally {
                this.loadingUpload = false;
            }
        }
    },
};

export default FileStorageComponent;

</script>
<style lang="less" scoped src="@/assets/styles/filestorage.less"/>
