/**
 * stripe.js
 * 
 * API for handling Stripe requests which required the secret key
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Check if provided stripe coupon is valid
 * 
 * @param {String} couponId The id of the coupon to check
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStripeCoupon = async function(couponId) {
    return await axiosInstance.get(`/app/stripe/coupon/${couponId}`).then(res => res);
};


export default {
    getStripeCoupon,
}