<template>
  <div class="emission-factor-history-container">
    <q-card>
      <q-card-section>
        <q-input class="search-emission-factor" placeholder="Search" color="primary" v-model="search" clearable>
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-table
          class="data-types-table"
          :rows="historyData"
          :columns="tableColumns"
          :loading="loadingTable"
          :rows-per-page-options="[10]"
          :filter="search"
          binary-state-sort
        >
          <template #body-cell-action="props">
            <q-td :props="props">
              <q-badge :class="`action-badge ${props.value.toLowerCase()}`" rounded :label="props.value" />
            </q-td>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import api from "@/services/api/api.js";
import notify from '@/services/util/notify';

export default {
  data() {
    return {
      historyData: [],
      tableRows: [],
      loadingTable: false,
      search: "",
      tableColumns: [
        {
          name: "action",
          label: "Action",
          align: "left",
          field: (row) => row.action,
        },
        {
          name: "uuid",
          label: "UUID",
          align: "left",
          field: (row) => row.uuid,
        },
        {
          name: "name",
          label: "Name",
          align: "left",
          field: (row) => row.name,
        },
        {
          name: "factor",
          label: "Factor",
          align: "left",
          field: (row) => row.factor,
          format: (val) => parseFloat(val),
          sortable: true,
        },
        {
          name: "author",
          label: "Author",
          align: "left",
          field: (row) => row.author,
        },
        {
          name: "timestamp",
          label: "Date Modified",
          align: "left",
          field: (row) => row.timestamp,
          sort: (a, b) => new Date(a) - new Date(b),
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          }),
          sortable: true,
        },
        {
          name: "description",
          label: "Comments",
          align: "left",
          field: (row) => row.description,
        },
      ],
    }
  },
  async created() {
    await this.init();
  },
  methods: {
    /**
     * @desc Retrieve the data for the table from the backend.
     * 
     * @returns {Array} the data.
     */
    async getHistory() {
      try {
        return (await api.dataTypes.getDataTypesTrace()).data;
      } catch (err) {
        notify.withObject(err.response);
      }
    },
    /**
     * @desc Initialisation for the table.
     */
    async init() {
      this.loadingTable = true;
      this.historyData = await this.getHistory();
      this.loadingTable = false;
    }
  },
}
</script>

<style lang="less" src="@/assets/styles/emissionFactorSettings.less" />