<template>
    <div class="freight-api-container">

        <q-icon class="close-modal" size="2rem" name="close" @click="$emit('close')" />

        <div class="form-container">
            <q-form @submit="addGVIConnection">
                <h3 class="connect-form-title">
                    Add Freight GVI Connection
                </h3>
                <p class="freight-form-info">
                    Through providing the API and subscription key for your freight services, we can automatically pull your
                    freight information and display it on the web platform.
                </p>

                <div class="connect-form-inputs">

                    <div class="connect-form-input">
                        <q-input outlined bg-color="white" v-model="GVICustomerID" label="GVI Customer ID" />
                    </div>
                </div>

                <div class="connect-form-buttons">
                    <q-btn class="submit-new-connection" label="Submit" :disabled="!GVICustomerID" type="submit"
                        color="secondary" />
                </div>

                <div class="connect-form-tutorial">
                    <h3 class="connect-form-title">
                        How to find your GVI number
                    </h3>
                    <p class="connect-form-tutorial-step">To find your GVI Customer ID, open your GVI invoice PDF.</p>
                    <div class="freight-info-images">
                        <q-img src="../../../../assets/tutorials/freight/gviInvoice.png" width="100%"></q-img>
                    </div>
                </div>
            </q-form>
        </div>
    </div>
</template>
    
<script>


import api from "@/services/api/api";
import notify from '@/services/util/notify';

const FreightAddGVIComponent = {
    name: "FreightAddGVIComponent",

    data() {
        return {

            GVICustomerID: '',
            loading: false,
        };
    },

    created() {

    },

    methods: {

        /**
         * @desc Adds the credentials for an GVI connection. Will be added to database as 'pending' (needs to be validated)
         */
        addGVIConnection() {

            this.loading = true;

            const postBody = { customerNumber: this.GVICustomerID };

            api.freight.addFreightConnection("GVI", postBody).then(() => {
                notify.primary("Successfully added GVI connection", 'top');
                this.$emit("getFreightConnections")
            }).catch((err) => {
                notify.withObject(err.response)
            })
            .finally(() => {
                this.gviCustomerId = '';
                this.loading = false;
            });
        }
    }
};

export default FreightAddGVIComponent;
</script>
    
<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>