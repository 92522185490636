<template>
	<div :class="{'main-tab-container': $route.path.includes('/targets')}">
		<div class="goals-header">
			<h2 v-if="!$route.path.includes('/targets')" class="tab-section-heading">Goals</h2>
			<h1 v-else class="goals-main-heading">Targets and Goals</h1>
		</div>

		<div class="emission-target-container" v-if="$route.path.includes('/targets')">

			<div class="section-header">
				<span class="title">Emission Target</span>
				<span>Set and track your Company's Emission Target. You can set a target and monitor how you are tracking towards it.</span>
			</div>

			<div v-if="!loadingTarget">
				<q-card v-if="!noTarget" class="q-pa-md q-mb-lg">
					<TargetGraphComponent :target="emissionTarget" @getTarget="getTarget()"/>
				</q-card>

				<div class="goals-summary-container" v-else-if="permissions.createGoals.includes(this.userRole)">
					<div  class="goals-add-card">
						<q-card
							v-if="userRole == 'admin' || 'demo'"
							class="new-goal-card-component"
							@click="this.showCreateTargetModal = true"
						>
							<q-card-section class="goal-card-progress">
								<q-icon
									name="add_circle"
									size="3.5em"
									color="secondary"
								/>
								<div>
									Add New Target
								</div>
							</q-card-section>
						</q-card>
					</div>
				</div>
			</div>

			
			<div v-else class="loading-spinner">
				<q-spinner color="primary" size="3em" />
			</div>


			<div class="section-header">
				<span class="title">Goals</span>
				<span>Set goals that will contribute to reaching your Company's Emission Target</span>
			</div>
		</div>

		<div
			style="width: 100%"
			class="row"
		>
			<div class="col-9">
				<q-toggle 
					v-if="!this.emissionSource"
					v-model="filter.showAllFilter"
					val="showAll"
					label="Show All"
					@click="showAllFilter"
				/>
				<q-select
					v-if="!this.emissionSource"
					filled
					label="Type"
					class="goals-card-filter"
					style="width: 150px"
					v-model="filter.filterCategory"
					:options="this.emissionSourceOptions"
					option-value="tableName"
					option-label="name"
					map-options
					emit-value
				/>
			</div>
		</div>

		<div class="goals-summary-container" v-if="!loading" >

			<div class="goals-summary-card" >
				<q-card class="goal-card">
					<q-card-section>
						<div class="text-h6">Completed</div>
					</q-card-section>
					<q-card-section class="goal-card-progress">
						<q-circular-progress
							show-value
							:value="(getCompleted / numGoals) * 100"
							size="150px"
							:thickness="0.15"
							rounded
							color="green"
							track-color="grey-2"
							class="q-ma-md"
							>{{ getCompleted }}</q-circular-progress>
					</q-card-section>
				<q-tooltip class="text-body2">To determine if a goal is 'Completed', we check if the current usage value is less than or equal to the target value (and that the current date is before the due date)</q-tooltip>
				</q-card>
			</div>

			<div class="goals-summary-card">
				<q-card class="goal-card">
					<q-card-section>
						<div class="text-h6">Off Track</div>
					</q-card-section>
					<q-card-section class="goal-card-progress">
						<q-circular-progress
							show-value
							:value="(getOffTrack / numGoals) * 100"
							size="150px"
							:thickness="0.15"
							rounded
							color="red"
							track-color="grey-2"
							class="q-ma-md"
							>{{ getOffTrack }}</q-circular-progress>
					</q-card-section>
					<q-tooltip class="text-body2">A goal is considered 'Off Track' when either the current value is greater than the value of the emission source when the goal was set, or if the current date is past the due date.</q-tooltip>
				</q-card>
			</div>

			<div class="goals-summary-card">
				<q-card class="goal-card">
					<q-card-section>
						<div class="text-h6">On Track</div>
					</q-card-section>
					<q-card-section class="goal-card-progress">
						<q-circular-progress
							show-value
							:value="(getOnTrack / numGoals) * 100"
							size="150px"
							:thickness="0.15"
							rounded
							color="blue"
							track-color="grey-2"
							class="q-ma-md"
							>{{ getOnTrack }}</q-circular-progress>
					<q-tooltip class="text-body2">A goal is considered 'On Track' when the current average is less than the historical average (but not less than the target) and the current date is before the due date.</q-tooltip>
					</q-card-section>
				</q-card>
			</div>

			<div v-if="permissions.createGoals.includes(this.userRole)" class="goals-add-card">
				<q-card
					v-if="userRole == 'admin' || 'demo'"
					class="new-goal-card-component"
					@click="this.showCreateGoalModal = true"
				>
				<q-card-section class="goal-card-progress">
					<q-icon
						name="add_circle"
						size="3.5em"
						color="secondary"
					/>
					<div>
						Add New Goal
					</div>
				</q-card-section>
				</q-card>
			</div>

		</div>

		<SideModalComponent v-model="this.showCreateGoalModal">
			<CreateGoalComponent
				@close="this.showCreateGoalModal = false"
				:emission="emission"
				:emissionSourceOptions="emissionSourceOptions"
				@reload="this.getGoals()"
			/>
		</SideModalComponent>

		<SideModalComponent v-model="this.showCreateTargetModal">
			<CreateTargetComponent :currentTarget="emissionTarget" @close="() => {this.showCreateTargetModal = false; this.getTarget();}"/>
		</SideModalComponent>

		<q-dialog
			v-model="showGoalProgressModal"
			class="select-goal"
		>
			<ViewGoalProgressComponent
				@close="this.showGoalProgressModal = false"
				:goalId="this.selectedGoal.id"
				:unit="this.selectedGoal.unit"
				style="width: 60vw; max-width: 80vw"
			/>
		</q-dialog>

		
		<div class="goals-table" v-if="!loading">
			<q-table
			:rows-per-page-options="[10]"
			:rows="this.fullGoalList"
			:columns="this.columns"
			row-key="id"
			:filter="filter" 
			:filter-method="customFilter"
			@row-click="showGoalProgress"
			>
			<template v-slot:body-cell-progress="props">
				<q-td :props="props">
						<div class="meter-connection-target">
							<q-linear-progress class="responseRateBar" stripe rounded size="20px" :value="props.value"
							:color="props.row.colour" />
						</div>
					</q-td>
				</template>
			</q-table>
		</div>

		<div v-if="loading" class="loading-spinner">
			<q-spinner color="primary" size="3em" />
		</div>

	</div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import "chartjs-adapter-date-fns";
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import getRole from "@/services/util/role";
import {getEmissionTableName} from "@/services/util/emissions";
import permissions from "@/rolePermissions";
import GoalCardComponent from "@/components/GoalsComponents/GoalCardComponent.vue";
import CreateGoalComponent from "@/components/GoalsComponents/CreateGoalComponent.vue";
import ViewGoalProgressComponent from "@/components/GoalsComponents/ViewGoalProgressComponent.vue";

import SideModalComponent from '../SideModalComponent.vue';
import TargetGraphComponent from './TargetGraphComponent.vue'
import CreateTargetComponent from './CreateTargetComponent.vue';
import isEmpty from 'lodash/isEmpty';

const ViewGoalsComponent = {
	name: "Goals",
	props: ["emissionSource"],

	components: { GoalCardComponent, CreateGoalComponent, ViewGoalProgressComponent, SideModalComponent, TargetGraphComponent, CreateTargetComponent },

	data() {
		return {
			userRole: getRole(),
			permissions: permissions,

			// Loading vars
			loading: false,
			// List of goal data
			showCreateGoalModal: false,
			showCreateTargetModal: false,
			showGoalProgressModal: false,

			emissionSourceOptions: [],

			emissionSourceTableName: "",

			goalList: [],
			fullGoalList: [],
			filter: {
				filterOptions: [],
				filterCategory: "",
				showAllFilter: false,
				previousFilter: "All",
			},
			customFilterOptions: [],

			emissionTarget: {},
			loadingTarget: false,

			search: "",
			selectedGoal: "",
			// Current day in yyyy-mm-dd format.
			currDate: new Date().toISOString().split("T")[0],

			numGoals: 0,

			columns: [
				{
					name: "goal",
					align: "left",
					label: "Goal",
					classes (row) {
						return row.averageDailyUsage < row.targetValue ? 'text-green' : 'bg-white'
					},
					field: (row) => row.name,
				},
				{
					name: "progress",
					align: "left",
					style: "width: 20%",
					label: "Progress",
					field: (row) => {
						try {
							let percent = ((row.averageDailyUsage - row.targetValue) / (row.startValue - row.targetValue))
							return 1 - percent;
						} catch (e) {
							return 0;
						}
					},
					sortable: true,
				},
				{
					name: "start",
					align: "left",
					label: "Initial Value",
					field: (row) => row.startValue,
					format: (val, row) => `${val.toFixed(2).replace(/[.,]00$/, "")} ${row.unit}`,
				},
				{
					name: "current", 
					align: "left",
					label: "Current Value",
					field: (row) => row.averageDailyUsage,
					format: (val, row) => `${val.toFixed(2).replace(/[.,]00$/, "")} ${row.unit}`,
				},
				{
					name: "target", 
					align: "left",
					label: "Target",
					field: (row) => row.targetValue,
					format: (val, row) => `${val.toFixed(2).replace(/[.,]00$/, "")} ${row.unit}`,
				},
				{
					name: "due", 
					align: "center",
					label: "Due Date",
					field: (row) => row.deadline,
					format: (date) => new Date(date).toLocaleString().split(',')[0],
					sort: (a, b) => new Date(a) - new Date(b),
					sortable: true,
				},
			],
		};
	},


	/**
	 * Retrieve list of valid emission source display names for select
	 */
	created() {
		Promise.all([
			api.data.getEmissionDisplayNames(),
			api.customDatasets.getCustomDatasets(),
		]).then((responses) => {
			this.emissionSourceOptions = this.emissionSourceOptions.concat(
				responses[0].data,
				responses[1].data.map((customDataset) => {
					return {name: customDataset.datasetName, tableName: customDataset.datasetTableName}
				})
			);
		})
	},

	watch: {
		// Making use of a deep watch to set showAllFliter to false when a category is selected
		filter: {
			handler(newValue) {
				if (newValue.filterCategory != "All") {
					this.filter.showAllFilter = false;
				}
			},
			deep: true,
		},

		siteId() {
			this.getGoals();
		}
	},

	computed: {
		isAll() {
			return this.emissionSources === undefined;
		},

		getCompleted() {
			return this.customFilter().filter(x => x.status == 'completed').length
		},

		getOffTrack() {
			return this.customFilter().filter(x => x.status == 'off track').length
		},

		getOnTrack() {
			return this.customFilter().filter(x => x.status == 'on track').length
		},

		siteId() {
			return useSiteStateStore().siteId
		},
		emission() {
			return this.emissionSource && this.emissionSourceTableName ? {name: this.emissionSource, tableName: this.emissionSourceTableName} : undefined;
		},

		noTarget() {
			return isEmpty(this.emissionTarget);
		}
	},

	async mounted() {
		this.loading = true;

		if (this.$route.path.includes('/targets')) {
			await this.getTarget();
		}

		await this.getGoals();
		this.emissionSourceTableName = await getEmissionTableName(this.emissionSource);

		// Initalise Filter Settings
		this.filter.filterOptions = this.emissionSource ? this.emissionSourceTableName : this.emissionSourceOptions.map(item => item.tableName)
		this.filter.filterCategory = this.emissionSource ? this.emissionSourceTableName : "All"
		this.filter.showAllFilter = !!this.emissionSource;

		

		this.loading = false;
	},

	methods: {

		/**
		 * @desc If show all filter selected, show all goals. Otherwise, show only the filter
		 * category that has been selected
		 */
		showAllFilter() {
			if (this.filter.showAllFilter) {
				this.filter.previousFilterCategory = this.filter.filterCategory;
				this.filter.filterCategory = "All";
			} else {
				this.filter.filterCategory = this.filter.previousFilterCategory;
				this.customFilter();
			}
		},

		/**
		 * @desc Create custom filter, and apply filter to list of goals
		 */
		customFilter() {
			var filteredGoalList = [];
			filteredGoalList = this.fullGoalList.filter((goal) => {
				let categoryBool = false;

				if (this.filter.filterOptions.includes(goal.emissionSource)) {
					if (this.filter.filterCategory == "All" || !this.filter.filterCategory) {
						categoryBool = true;
					} else if (this.filter.filterCategory.includes(goal.emissionSource)) {
						categoryBool = true;
					} 
				}
				return categoryBool;
			});

			this.numGoals = filteredGoalList.length != 0 ? filteredGoalList.length : 1 

			return filteredGoalList;
		},

		/**
		 * @desc Gets the current tennants emission target
		 */
		async getTarget() {
			this.loadingTarget = true;

			try {
				let response = await api.targets.getTargets();
				if(response.data.length == 0) {
					this.emissionTarget = {}
					
				} else {
					this.emissionTarget = response.data[0]
					const startDate = this.emissionTarget.startDate;
					const endDate = this.emissionTarget.endDate;
					if(this.emissionTarget.scopes.includes(null)) this.emissionTarget.scopes = []
					this.emissionTarget.baselineEmissions = Math.round((await api.data.getTotalEmissions(startDate, endDate)).data);
				}
			} catch (err) {
				notify.withObject(err);
			} finally {
				this.loadingTarget = false;
			}
		},


		/**
		 * @desc Open up modal for specific goal
		 * @param {Object} goal the individual goal
		 */
		showGoalProgress(evt, row) {
			this.showGoalProgressModal = true;
			this.selectedGoal = row;
		},


		/**
		 * @desc Retrieves all of the goals for the logged in user's tenancy, and sets it to goalList
		 */
		async getGoals() {
			this.loading = true;

			await api.goals
				.getGoals(this.siteId, {emissionSource: this.emissionSource})
				.then((res) => {
					this.fullGoalList = res.data;

					for (let goal of this.fullGoalList) {
						this.getGoalStatus(goal);
					}

					this.numGoals = this.fullGoalList.length != 0 ? this.fullGoalList.length : 1 ;

				})
				.catch((err) => {
					notify.withObject(err.response, "top", "Targets");
				})
				.finally(() => {
					this.loading = false;
				});
		},


		/**
		 * @desc Calculate the status of the goal (completed, off track, on track)
		 */
		getGoalStatus(goal) {
			// To understand if a goal is completed, is the average daily usage less than or equal to the target value.
			if (goal.averageDailyUsage <= goal.targetValue) {
				goal.status = 'completed'
				goal.colour = 'green'
				return;
			}

			let today = new Date();
			// let startDate = new Date(goal.startDate);
			let endDate = new Date(goal.deadline);

			// If the current date is past the deadline, or the average daily usage is over the start value, the goal is considered "Off Track"
			if (today > endDate || goal.averageDailyUsage > goal.startValue){
				goal.status = "off track"
				goal.colour = "red"
			} else {
				goal.status = "on track";
				goal.colour = "blue"
			}
			return;
		},
	},
};

export default ViewGoalsComponent;
</script>

<style lang="less">
@import "../../assets/styles/goals.less";
</style>
