<template>
	<div class="container">
		<h2 class="heading">Create Denominator</h2>
		<div>
			<q-form class="form" @submit.prevent="createDenominator()">
				<div class="inputs">
					<q-input
						stack-label
						outlined
						bg-color="white"
						v-model="name"
						label="Denominator Name *"
						required
						maxlength="75"
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The name of your custom denominator.
								</q-tooltip>
							</q-icon></template
						>
					</q-input>
					<q-input
						stack-label
						outlined
						type="number"
						bg-color="white"
						step="any"
						v-model.number="defaultValue"
						label="Default Value"
						:rules="[
							(val) => !Number.isNaN(parseFloat(val)) || 'Value must be a number',
							(val) => val > 0 || 'Value must be greater than 0.'
						]"
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The default value of your custom denominator.
								</q-tooltip>
							</q-icon></template
						>
					</q-input>
				</div>

				<q-btn
					class="button"
					:disable="submitLoading"
					:loading="submitLoading"
					square
					no-caps
					padding="8px 24px"
					color="secondary"
					label="Create"
					type="submit"
				/>
			</q-form>
		</div>
	</div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';


const CreateDenominatorComponent = {
	name: "Create Denominator",
	props: ["hideCloseButton"],
	emits: ["close"],
	data() {
		return {
			submitLoading: false,

			// Form data v-models
			name: "",
			defaultValue: 1,
		};
	},
	
	computed: {
		getSourceDisplayNames() {
			return this.emissionSources.map(function (item) {
				return item.sourcedisplayname;
			});
		},
	},

	methods: {
		async createDenominator() {
			this.submitLoading = true;

			try {
				const res = await api.cid.postCID({default: this.defaultValue, name: this.name })
				notify.withObject(res);
				this.$emit('close')
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.submitLoading = false;
			}
		},
	},
};

export default CreateDenominatorComponent;
</script>

<style lang="less" src="@/assets/styles/siteModal.less" scoped />