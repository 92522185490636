<template>
  <div class="connection-settings-split-data">
    <h3 class="sub-heading">Split Data</h3>
    <p>Please ensure all values sum up to 100% and a site is selected.</p>
    <q-table 
        class="split-data-table"
        :rows-per-page-options="[0]" 
        :rows="rows"
        :columns="inputColumns"
        :hide-pagination="true"
        hide-header
      >

      <template v-slot:body-cell-partialAmount="props">
          <q-td :props="props">
              <div class="split-allocation-partial-amount">
                <q-input
                    :ref="`amountInput${props.rowIndex}`"
                    v-model="props.row.amount"
                    inputmode="numeric"
                    hide-bottom-space
                    no-error-icon
                    borderless
                    square outlined
                    :rules="[(val) => isValidAmount(val)]" suffix="%" mask="###"
                    clearable
                />
              </div>
          </q-td>
      </template>

      <template v-slot:body-cell-partialSite="props">
          <q-td :props="props">
              <q-select 
                :ref="`siteInput${props.rowIndex}`"
                class="split-input"
                v-model="props.row.siteId"
                :options="availableSiteSelection"
                emit-value
                map-options
                label="Site"
                square 
                outlined
                hide-bottom-space
                no-error-icon
                stack-label
                :rules="[(val) => isValidSite(val, props.rowIndex)]"
                @blur="validateInputs(props.rowIndex)"
              />
          </q-td>
      </template>

      <!-- <template v-slot:body-cell-partialTags="props">
          <q-td :props="props">

              <q-select  class="split-input" label="Select Tags" :model-value="props.row.tags" @remove="(val) => { props.row.tags.splice(val.index, 1) }" @add="(val) => { props.row.tags.push(val.value) }" stack-label multiple use-chips use-input
                  :options="availableTags" :option-label="(item) => item" @new-value="newTag"
                  @popup-show="loadAvailableTags"
                  square outlined >
              </q-select>
          </q-td>

      </template> -->
      
      
    </q-table>
    <div class="add-row-container">
        <q-btn 
            v-if="rows.length > 2"
            icon="close" 
            class="add-split-allocation-button" 
            color="red" 
            round
            @click="rows.pop()"
        />
        <q-btn 
            icon="add" 
            class="add-split-allocation-button" 
            color="secondary" 
            round 
            @click="rows.push({ amount: null, siteId: null, tags: [] })"
            :disable="rows.length >= 10"
        />
    </div>
  </div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

  export default {
    data() {
      return {
        rows: [
            {
                amount: null,
                siteId: null,
                tags: [],
            },
            {
                amount: null,
                siteId: null,
                tags: [],
            },
        ],
        inputColumns: [
          {
              name: 'partialAmount',
              field: row => row.amount,
              align: 'left',
              style: 'width: 25%',
          },
          {
              name: 'partialSite',
              field: row => row.siteId,
              align: 'left',
              style: 'width: 25%',
          },
        //   {
        //       name: 'partialTags',
        //       field: row => row.tags,
        //       align: 'left',
        //       style: 'width: 25%',
        //   },
        ],
      }
    },

    computed: {
        sitesDict() {
            return useSiteStateStore().siteList.reduce((acc, siteObject) => {
                acc[siteObject.id] = siteObject;
                return acc;
            }, {});
        },
        
        allocationAmount() {
            let total = 0;

            for (let i = 0; i < this.rows.length; i++) {
                const amount = parseInt(this.rows[i].amount)
                
                if (!isNaN(amount)) {
                    total += amount;
                }
            }
            
            return total;
        },

        availableSiteSelection() {
            const siteSelection = [];

            for (const [siteId, siteDetails] of Object.entries(this.sitesDict)) {
                siteSelection.push({
                    value: siteId,
                    label: siteDetails.name,
                });
            }

            return siteSelection;
        },

        splitOptionRows() {
            const optionRows = [];

            for (let i = 0; i < this.rows.length; i++) {
                const row = this.rows[i];
                const amount = parseInt(row.amount)
                const invalidRow = (
                    !this.isValidAmount(amount) ||
                    !this.isValidSite(row.siteId, i) ||
                    isNaN(amount) ||
                    amount <= 0
                );

                if (invalidRow) {
                    continue;
                }

                optionRows.push({
                    amount: row.amount,
                    siteId: [
                        row.siteId,
                        this.sitesDict[row.siteId],
                    ],
                    tags: row.tags,
                    uuid: "",
                    splitType: "%",
                    maxAmount: 0,
                });
            }

            return optionRows;
        },

        optionsObject() {
            if (this.splitOptionRows.length === 0) {
                return {};
            }

            return {
                "1": {
                    "0": {
                        shouldSplit: true,
                        type: "%",
                        amount: 0,
                        dataTypeUUID: "",
                        timestamp: "",
                        rows: this.splitOptionRows,
                        rowIndex: "0",
                        siteId: "1"
                    }
                }
            }
        },
    },

    methods: {
        isValidAmount(amount) {
            return !amount || parseInt(amount) === 0 || this.allocationAmount === 100;
        },

        isValidSite(siteId, rowNum) {
            let rowAmount = parseInt(this.rows[rowNum].amount);
            rowAmount = isNaN(rowAmount) ? 0 : rowAmount;

            if (rowAmount > 0 && !siteId) {
                return false;
            }

            return true;
        },

        validateInputs(rowNum) {
            this.$refs[`amountInput${rowNum}`]?.validate();

            if (parseInt(this.rows[rowNum].amount) > 0) {
                this.$refs[`siteInput${rowNum}`].validate();
            } else {
                this.$refs[`siteInput${rowNum}`]?.resetValidation();
            }
        }
    },

    watch: {
        rows: {
            handler() {
                for (let i = 0; i < this.rows.length; i++) {
                    this.validateInputs(i);
                }
            },
            deep: true
        },

        optionsObject: {
            handler() {
                this.$emit("update", "splitOptions", this.optionsObject)
            },
            deep: true,
        }
    }
  }
</script>

<style lang="less" src="@/assets/styles/dataFeedConnections.less" />