/**
 * @desc Finds the minimum and maximum dates within the datesArray.
 * 
 * @param {Array} datesArray the array of dates to retrieve minimum and maximum date from
 * @param {String} frequency the frequency of the labels
 * @returns {Array} array containing minimum and maximum date labels
 */
function getMinMaxDates(datesArray, frequency) {
	var maxDate = new Date("0001-01-01");
	var minDate = new Date("9999-12-30");

	// In this for loop we determine the min and max dates of the datesArray.
	for (let date of datesArray) {
		date = new Date(date)
		if (date > maxDate) {
			maxDate = new Date(date.getTime());
		}
		if (date < minDate) {

			minDate = new Date(date.getTime());
		}
	}

	/**
	 * If the frequency is monthly, set the min date as the first day of previous month, and max date as first date of next month
	 * 
	 */ 
	if (frequency == "month") {
		minDate = new Date(minDate.getFullYear(), minDate.getMonth() - 1, 1); 
		maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 1); 
	} 

	return [minDate, maxDate];

}


/**
 * @desc This method takes an array of Date objects and constructs an array of strings in 'YYYY-MM' format,
 * beginning from the earliest date found in datesArray and ending on the latest date found in datesArray.
 * The time granularity is monthly.
 *
 * This method is mainly purposed for chart.js graph data construction.
 *
 * @param {Array} datesArray the array of dates to construct the labels array from
 * @returns array of date strings incremented by month.
 */
exports.getMonthlyLabels = function (datesArray) {
	let labels = [];
	let dates = getMinMaxDates(datesArray, "month");

	let minDate = dates[0], maxDate = dates[1];

	/*
    We set a variable to minDate called iterateDate, then add a 'YYYY-MM' label to labels based on the value of iterateDate.
    While iterateDate is less than or equal to maxDate, increment iterateDate by 1 month and add the 'YYYY-MM' label to labels.
    */
	var iterateDate = new Date(minDate.getTime());

	while (iterateDate <= maxDate) {
		let year = iterateDate.getFullYear();
		// We need to take the getMonth() value incremented by 1 because js stores months in Date objects from 0-11...
		let month = iterateDate.getMonth() + 1;

		let dateMinimised = year + "-" + month;

		labels.push(dateMinimised);
		// Iterate date by 1 month.
		iterateDate.setMonth(iterateDate.getMonth() + 1);
	}

	return labels;
};


/**
 * @desc Get year labels from date array
 * 
 * @param {Array} datesArray contains all dates from data
 * @returns {Array} labels updated arrays with the labels between the minimum and maximum dates
 */
exports.getYearlyLabels = function (datesArray) {
	let labels = [];

	let dates = getMinMaxDates(datesArray, "year");

	let minDate = dates[0], maxDate = dates[1];


	var iterateDate = new Date(minDate.getTime());
	while (iterateDate.getFullYear() <= maxDate.getFullYear()) {
		let year = iterateDate.getFullYear();

		let dateMinimised = `${year}`;

		labels.push(dateMinimised);
		
		iterateDate.setFullYear(iterateDate.getFullYear() + 1);
	}
	return labels;
};


/**
 * @desc Get weekly labels from date array
 * 
 * @param {Array} datesArray contains all dates from data
 * @returns {Array} labels updated arrays with the labels between the minimum and maximum dates
 */
exports.getWeeklyLabels = function (datesArray) {
	let labels = [];

	let dates = getMinMaxDates(datesArray, "week");

	let minDate = dates[0], maxDate = dates[1];

	var iterateDate = new Date(minDate.getTime());
	while (iterateDate <= maxDate) {
		let year = iterateDate.getFullYear();
		// We need to take the getMonth() value incremented by 1 because js stores months in Date objects from 0-11...
		let month = iterateDate.getMonth() + 1;

		// Get the day as the first day in the week given the date
		let diff = iterateDate.getDate() - iterateDate.getDay() + (iterateDate.getDay() === 0 ? -6 : 1);

		// If diff is negative, the first day of the week is in the previous year which we need to calculate.
		let dateMinimised = "";
		if (diff < 0) {
			year -= 1;
			month = 12;
			let endDiff = new Date(year, month, 0).getDate() + diff;
			dateMinimised = year + "-" + month + "-" + endDiff;
		} else {
			dateMinimised = year + "-" + month + "-" + diff;
		}

		labels.push(dateMinimised);
		// Iterate date by 7 days ( 1 week ).
		iterateDate.setDate(diff + 7);
	}

	return labels;
};


/**
 * @desc Get daily labels from date array
 * 
 * @param {Array} datesArray contains all dates from data
 * @returns {Array} labels updated arrays with the labels between the minimum and maximum dates
 */
exports.getDailyLabels = function (datesArray) {
	let labels = [];

	let dates = getMinMaxDates(datesArray, "day");

	let minDate = dates[0], maxDate = dates[1];

	var iterateDate = new Date(minDate.getTime());
	while (iterateDate <= maxDate) {
		let year = iterateDate.getFullYear();
		// We need to take the getMonth() value incremented by 1 because js stores months in Date objects from 0-11...
		let month = iterateDate.getMonth() + 1;

		let day = iterateDate.getDate();

		let dateMinimised = year + "-" + month + "-" + day;
		
		labels.push(dateMinimised);
		// Iterate date by 1 month.
		iterateDate.setDate(iterateDate.getDate() + 1);
	}

	return labels;
};


/**
 * @desc Given a dates array and granularity, generated the required labels
 * 
 * @param {*} datesArray 
 * @param {*} granularity 
 */
exports.getDateLabels = function (datesArray, granularity) {

	let chartLabels = [];

	switch(granularity) {
		case "year":
			chartLabels = exports.getYearlyLabels(datesArray);
			break;
		case "month":
			chartLabels = exports.getMonthlyLabels(datesArray);
			break;
		case "week":
			chartLabels = exports.getWeeklyLabels(datesArray);
			break;
		case "day":
			chartLabels = exports.getDailyLabels(datesArray);
	}
	return chartLabels;
};


/**
 * @desc Given a timestamp and granulatrity, generate the time label for the timetstamp.
 * Weekly granularity will convert the timestamp to the first Monday of that week 
 * 
 * @param {Date} timestamp date to generate label for
 * @param {String} granularity 
 * @returns 
 */
exports.getEntryLabelString = function (timestamp, granularity){
	timestamp = new Date(timestamp)
	let entryLabel = timestamp.getFullYear()

	switch(granularity) {
		case "month":
			entryLabel += "-" + (timestamp.getMonth() + 1);
			break;
		case "week": {
			let currentDateObj = timestamp;
			currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() + 6) % 7);
			entryLabel = (currentDateObj.getFullYear()) + "-" + (currentDateObj.getMonth() + 1) + "-" + (currentDateObj.getDate());
			break;
		}
		case "day":
			entryLabel += "-" + (timestamp.getMonth() + 1) + "-" + (timestamp.getDate());
			break;
	}

	return entryLabel;
};


/**
 * @desc Gets a list of small months in the small format provided by .toLocaleDateString()
 * 
 * @returns {Array<String>} An array containing strings representing months in small format
 * @example ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
 */
exports.getListOfShortMonths = function () {
	const labels = [];
    for (let month = 0; month < 12; month++) {
        const year = new Date().getFullYear()
        labels.push((new Date(year, month)).toLocaleDateString(undefined, {month: 'short'}));
    }

	return labels;
}