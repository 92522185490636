<template>
  <div class="datasource-list-container">
    <h2 class="tab-section-heading">Freight API Connections
      <q-icon class="q-pb-xs" name="help">
        <q-tooltip class="text-body2" anchor="center right" self="center left" :offset="[10, 10]">
          A list of your current API connections. Data will automatically be pulled at the beginning of each week. However, you are able to manually trigger the API connections
        </q-tooltip>
      </q-icon>
    </h2>

    <div class="content-item center-column freight-connection-table">
      <q-btn
        no-caps
        text-color="white"
        color="deep-purple-4"
        icon="add"
        label="Add a new API connection"
        square
        padding="5px 10px" 
        :disabled=!siteId
        @click="showAddFreightAPI = true"
      >
      <q-tooltip v-if="!siteId" class="text-body2">Please select a site to connect API with</q-tooltip>
      <q-tooltip v-else class="text-body2">
        Connect your freight API connection with us to have automatic freight
        data
      </q-tooltip>
      </q-btn>
      <q-table :rows="apiList" :columns="apiColumns" :loading="loadingAPI" row-key="name">
        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <div class="meter-connection-status">
              <q-badge v-if="props.value == 'Valid'" text-color="green">
                <div class="text-center full-width">{{ props.value }}</div>
                <q-tooltip>
                  We were able to successfully establish a connection to the API with the keys you provided
                </q-tooltip>
              </q-badge>

              <q-badge v-if="props.value == 'Invalid'" text-color="red">
                <div class="text-center full-width">{{ props.value }}</div>
                <q-tooltip>
                  Something has gone wrong while setting up your API connection
                  Please contact us to solve this issue
                </q-tooltip>
              </q-badge>
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              :loading="props.row.isLoading"
              :disable="updateable(props.row)"
              icon="update"
              flat
              color="blue"
              @click="updateOrders(props.row.id, props.row)"
            >
            <q-tooltip>
                  Manually run check for orders to add, and update database
            </q-tooltip>
              </q-btn>
            <q-btn
              icon="delete"
              flat
              color="deep-orange"
              @click="(deleteConnection = true) && (connectionId = props.row.id) && (supplier = 'API')"
            ></q-btn>
          </q-td>
        </template>
      </q-table>
    </div>

    <h2 v-if="this.flags.FLAG_4484_DHL_CONNECTION" class="tab-section-heading q-pt-xl">Freight DHL Connections</h2>

    <div v-if="this.flags.FLAG_4484_DHL_CONNECTION" class="content-item center-column freight-connection-table">
      <q-btn
        no-caps
        text-color="white"
        color="deep-purple-4"
        icon="add"
        label="Add a new DHL connection"
        square
        padding="5px 10px" 
        :disabled=!siteId
        @click="showAddFreightDHL = true"
      >
        <q-tooltip v-if="!siteId" class="text-body2">Please select a site to connect DHL account number with</q-tooltip>
        <q-tooltip v-else class="text-body2">
          Connect your freight DHL account number with us to have automatic freight
          data
        </q-tooltip>
      </q-btn>
      <q-table :rows="dhlList" :columns="dhlColumns" row-key="name">
        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <div class="meter-connection-status">
              <q-badge v-if="props.value != 'Submitted'" text-color="green">
                <div class="text-center full-width">{{ props.value }}</div>
              </q-badge>

              <q-badge v-if="props.value == 'Submitted'" text-color="orange">
                <div class="text-center full-width">{{ props.value }}</div>
                <q-tooltip>
                  Something has gone wrong while setting up your GVI connection
                  Please contact us to solve this issue
                </q-tooltip>
              </q-badge>
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              icon="delete"
              flat
              color="deep-orange"
              @click="(deleteConnection = true) && (connectionId = props.row.id) && (supplier = 'DHL')"
            ></q-btn>
          </q-td>
        </template>
      </q-table>
    </div>
    
    <h2 v-if="this.flags.FLAG_0000_GVI_CONNECTIONS" class="tab-section-heading">Freight GVI Connections</h2>

    <div v-if="this.flags.FLAG_0000_GVI_CONNECTIONS && subscription != 'Basic'" class="content-item center-column freight-connection-table">
      <q-btn
        no-caps
        text-color="white"
        color="deep-purple-4"
        icon="add"
        label="Add a new GVI connection"
        square
        padding="5px 10px" 
        @click="showAddFreightGVI = true"
      >
        <q-tooltip class="text-body2">
          Link your GVI freight customer number to your GreenHalo account.
        </q-tooltip>
      </q-btn>
      <q-table :rows="gviList" :columns="gviColumns" row-key="name">
        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <div class="meter-connection-status">
              <q-badge v-if="props.value != 'Submitted'" text-color="green">
                <div class="text-center full-width">{{ props.value }}</div>
              </q-badge>

              <q-badge v-if="props.value == 'Submitted'" text-color="orange">
                <div class="text-center full-width">{{ props.value }}</div>
                <q-tooltip>
                  Something has gone wrong while setting up your GVI connection
                  Please contact us to solve this issue
                </q-tooltip>
              </q-badge>
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              icon="delete"
              flat
              color="deep-orange"
              @click="(deleteGVI = true) && (deleteGVIID = props.row.id)"
            ></q-btn>
          </q-td>
        </template>
      </q-table>
    </div>

    <q-dialog position="right" v-model="showAddFreightAPI" class="new-connection">
      <AddFreightAPI @close="showAddFreightAPI = false" @getFreightConnections="onInitialize" :siteId="siteId" style="width:60vw; max-width:80vw"/>
    </q-dialog>

    <q-dialog position="right" v-model="showAddFreightDHL" class="new-connection">
      <AddFreightDHL @close="showAddFreightDHL = false" @getFreightConnections="onInitialize" :siteId="siteId" style="width:60vw; max-width:80vw"/>
    </q-dialog>

    <q-dialog position="right" v-model="showAddFreightGVI" class="new-connection">
      <AddFreightGVI @close="showAddFreightGVI = false" @getFreightConnections="onInitialize" style="width:60vw; max-width:80vw"/>
    </q-dialog>

    <q-dialog v-model="deleteConnection" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-md"
            >Are you sure you want to delete this freight connection?</span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Cancel"
            color="primary"
            @click="connectionId = null"
            v-close-popup
          />
          <q-btn
            flat
            label="Delete"
            color="deep-orange"
            @click="deleteFreightConnection(supplier)"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import api from "@/services/api/api";
import notify from "@/services/util/notify";

import getSub from "@/services/util/subscription.js";

import AddFreightAPI from "@/components/DataComponents/ConnectDataSourceComponents/Freight/AddFreightAPIComponent.vue";
import AddFreightDHL from "@/components/DataComponents/ConnectDataSourceComponents/Freight/AddFreightDHLComponent.vue";
import AddFreightGVI from "@/components/DataComponents/ConnectDataSourceComponents/Freight/AddFreightGVIComponent.vue";

const FreightDataSourceOptionsComponent = {
  name: "FreightDataSourceOptionsComponent",

  components: { AddFreightAPI, AddFreightDHL, AddFreightGVI },

  data() {
    return {
      subscription: getSub(),

      apiList: [],
      apiColumns: [
        { name: "platformName", style: "width: 25%", align: "left", label: "API Connection", field: "platformName", sortable: true },
        { name: "siteName", style: "width: 25%", align: "left", label: "Site", field: "siteName", sortable: true },
        { name: "updatedDate", style: "width: 25%", label: "Updated", align: "left", field: "updatedDate"},
        { name: "status", align: "left", label: "Status", field: "status" },
        { name: "actions",  style: "width: 10%",  label: "",  align: "left",  field: "" },
      ],

      dhlList: [],
      dhlColumns: [
        { name: "DHLAccountNumber", style: "width: 25%", align: "left", label: "Account Number", field: "customerNumber", sortable: true },
        { name: "siteName", style: "width: 25%", align: "left", label: "Site", field: "siteName", sortable: true },
        { name: "updatedDate", style: "width: 25%", label: "Updated", align: "left", field: "lastUpdated"},
        { name: "status", align: "left", label: "Status", field: "status" },
        { name: "actions",  style: "width: 10%",  label: "",  align: "left",  field: "" },
      ],


      gviList: [],
      gviColumns: [
        { name: "customerNumber", style: "width: 33%", align: "left", label: "GVI Connection", field: "customerNumber", sortable: true },
        { name: "lastUpdated", style: "width: 33%", label: "Updated", align: "left", field: "lastUpdated" },
        { name: "status", align: "left", label: "Status", field: "status" },
        { name: "actions", style: "width: 10%", label: "", align: "left", field: "" },
      ],

      deleteConnection: false,
      connectionId: null,

      showAddFreightAPI: false,
      showAddFreightDHL: false,
      showAddFreightGVI: false,

      loadingAPI: false,
      orderUpdateLoading: false,

      supplier: '',
    };
  },

  created() {
    this.onInitialize();
  },

  computed: {
    siteId() {
        return useSiteStateStore().siteId;
      },
  },

  watch: {
    siteId() {
      this.onInitialize()
    }
  },

  methods: {

    /**
     * @desc Retrieve current API connections
     */
    async onInitialize() {
      this.loadingAPI = true;
      const fetchData = function(siteId) {
        return Promise.all([
          api.freight.getFreightConnections("API", siteId),
          api.freight.getFreightConnections("GVI", siteId),
          api.freight.getFreightConnections("DHL", siteId),
        ])
      }

      await fetchData(this.siteId).then((response) => {
        this.apiList = response[0].data;
        this.gviList = response[1].data;

        if (this.flags.FLAG_4484_DHL_CONNECTION) this.dhlList = response[2].data;
        
        this.apiList.map(
          (meter) => (meter.updatedDate = meter.updatedDate.split("T")[0])
        );

        this.gviList.map(
          (meter) => (meter.lastUpdated = meter.lastUpdated.split("T")[0])
        );
      }).catch((error) => {
        notify.withObject(error.response);
      }).finally(() => {
        this.loadingAPI = false;
      })
    },


    /**
     * @desc Given a supplier and item id, delete the item from the list of connections
     */
    deleteFreightConnection() {
      api.freight
        .deleteFreightConnection(this.supplier, this.connectionId)
        .then(() => {

          switch (this.supplier) {
            case "API":
              this.apiList = this.apiList.filter(
                (item) => item.id !== this.connectionId
              );
              break;
            case "GVI":
              this.gviList = this.gviList.filter(
                (item) => item.id !== this.connectionId
              );
              break;
            case "DHL":
              this.dhlList = this.dhlList.filter(
                (item) => item.id !== this.connectionId
              );
              break;
          }

          this.connectionId = "";
          this.supplier = null;
          notify.primary("Freight connection successfully deleted", "top");
        })
        .catch(() => {
          notify.error("Error deleting Freight connection", "top");
        });
    },



    /**
     * @desc Attempt to update eShip orders.
     * TODO Currently it only works with eShip. Will need to update when we add additional freight API
     */
    updateOrders(id, row) {
      row.isLoading = true
      api.data.handleAPIConnections('eShip', row.siteId).then((res) => {
        if (Array.isArray(res.data)) {
          notify.primary("Successfully updated orders", "top")
        } else {
          notify.primary( res.data ?? "Successfully updated orders", "top")
        }
      })
      .catch((error) => {
        notify.error(error?.response?.data ?? "Failed to update orders", "top");
      }).finally(() => {
        row.isLoading = false
      })
    },

    /**
     * @desc Determines if a row can be updated
     * @param {*} row 
     */
    updateable(row) {
      return row.status == 'Valid' ? false: true
    }
  },
};

export default FreightDataSourceOptionsComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>