/**
 * icp.js
 * 
 * API for handling getting all icp associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Add ICP number link
 * 
 * @param {String} emissionSource the emission source to retrieve chart data for
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const addICPNumber = async function (emissionSource, payload) {
    return await axiosInstance.post(`/app/icp/${emissionSource}`, payload).then(res => res);
}


/**
 * @desc Retrieve all ICP numbers for a given emission source
 * 
 * @param {*} emissionSource 
 * @param {*} siteId 
 * @returns 
 */
const retrieveICPNumbers = async function (emissionSource, siteId) {
    return await axiosInstance.get(`/app/icp/${emissionSource}`, { params: {siteId} }).then(res => res);
}


/**
 * @desc Retrieve all ICP numbers for a given emission source
 * 
 * @param {*} emissionSource 
 * @param {*} siteId 
 * @returns 
 */
const deleteICPNumber = async function (id) {
    return await axiosInstance.delete(`/app/icp/${id}`).then(res => res);
}


export default {
    addICPNumber,
    retrieveICPNumbers,
    deleteICPNumber
}
