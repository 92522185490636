<template>
	<div class="container">
		<h2 class="heading">Create Organisational Denominator</h2>
		<div>
			<q-form class="form" @submit.prevent="createDenominator()">
				<div class="inputs">
					<q-input
						stack-label
						outlined
						v-model="name"
						label="Denominator Name *"
						required
						maxlength="50"
					>
						<template v-slot:after>
							<q-icon name="help" color="grey" size="sm">
								<q-tooltip style="font-size: 1rem">
									The name of your organisational intensity denominator.
								</q-tooltip>
							</q-icon>
						</template>
					</q-input>
					<q-input
						stack-label
						outlined
						type="textarea"
						step="any"
						v-model="description"
						label="Description"
						maxlength="200"
					>
						<template v-slot:after>
							<q-icon name="help" color="grey" size="sm">
								<q-tooltip style="font-size: 1rem">
									An optional description of your denominator.
								</q-tooltip>
							</q-icon>
						</template>
					</q-input>
				</div>

				<q-btn
					class="button"
					:disable="submitLoading"
					:loading="submitLoading"
					square
					no-caps
					padding="8px 24px"
					color="secondary"
					label="Create"
					type="submit"
				/>
			</q-form>
		</div>
	</div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';


const CreateDenominatorComponent = {
	name: "Create Denominator",
	props: ["hideCloseButton"],
	emits: ["close"],
	data() {
		return {
			submitLoading: false,

			// Form data v-models
			name: "",
			description: "",
		};
	},
	
	methods: {
		async createDenominator() {
			this.submitLoading = true;

			try {
				const res = await api.oid.postOID({description: this.description, name: this.name })
				notify.withObject(res);
				this.$emit('close')
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.submitLoading = false;
			}
		},
	},
};

export default CreateDenominatorComponent;
</script>

<style lang="less" src="@/assets/styles/siteModal.less" scoped />