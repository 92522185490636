<template>
    <div class="datatype-manual-upload-container">
        <q-table 
            class="manual-upload-table"
            :columns="columns"
            :rows="rows"
            hide-bottom
            row-key="name"
            >   
            <template v-slot:header-cell="props">
                <q-th>
                    <span style="color: red;" v-if="columnRequired(props.col.label)">*</span>{{ props.col.label }}
                </q-th>
            </template>

            <template v-slot:body-cell-id="props">
                <q-td :props="props">
                    <div>
                        <span v-if="flags['FLAG_5120_COLUMN_NAMES']">The emission factor ID of the type of emission source</span>
						<span v-else>The UUID of the type of emission source</span>
                        <q-btn flat round dense icon="help" color="primary" class="q-mr-sm" @click="(showFactors = true)" />
                    </div>
                </q-td>
            </template>
            <template v-slot:body-cell-timestamp="props">
                <q-td :props="props">
                    <div>
                        <span>Date associated with data. Format: d/mm/yyyy h:mm</span>
                        <q-btn flat round dense icon="help" color="primary" class="q-mr-sm" @click="(showTimestampHelp = true)" />
                    </div>
                </q-td>
            </template>


            <template v-slot:body-cell-supplierId="props">
                <q-td :props="props">
                    <div>
                        <span>ID of the supplier. Format: a number</span>
                        <q-btn v-if="flags.FLAG_5158_SUPPLIER_HELPER" flat round dense icon="help" color="primary" class="q-mr-sm" @click="(showSuppliers = true)" />
                    </div>
                </q-td>
            </template>

        </q-table>

        <div class="manual-button-center">
            <q-btn :loading="gettingTemplate" flat no-caps label="Download template" class="download-btn template" color="blue"
                icon-right="download" @click="downloadTemplate">
                <q-tooltip>
                    Download CSV file with only the required headers. You can use this template and upload your own data. Please be sure to rename the file.
                </q-tooltip>
            </q-btn>
            |
            <q-btn :loading="gettingExample" flat no-caps label="Download example" class="download-btn example" color="green"
                icon-right="download" @click="downloadExample">
                <q-tooltip>
                    Download CSV file with the required headers AND several rows of example data.
                </q-tooltip>
            </q-btn>
        </div>

        <span>Upload your CSV file below</span>

        <div class="upload-manual-csv">
            <q-file class="upload-files justify-center items-center" v-model="file" type="file" ref="file"
                label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
                :item-aligned=true>

                <template v-if="file" v-slot:prepend>
                    <q-icon name="cancel" @click.stop.prevent="file = null" class="cursor-pointer" />
                </template>
            </q-file>

            <div class="manual-button-center">
                <q-btn class="file-input-button" no-caps color="green" :disabled="this.file == null || !siteId" @click="submitCSVUpload"
                    label="Upload" icon-right="upload" :loading="loading" />
                <q-tooltip v-if="this.file == null || !siteId">Please upload a file and select a site</q-tooltip>
            </div>
        </div>
    </div>

    <q-dialog v-model="showFactors">
		<EmissionFactorComponent :emissionSource="emission"/>
	</q-dialog>

    <q-dialog v-model="showTimestampHelp">
		<TimestampHelpComponent/>
	</q-dialog>

    <q-dialog v-model="showSuppliers">
		<SupplierComponent :suppliers="suppliers" :loading="loadingSuppliers"/>
	</q-dialog>

    

</template>

<script>
import api from "../../services/api/api";
import notify from "@/services/util/notify";

import EmissionFactorComponent from "../DataComponents/EmissionFactorComponent.vue";
import TimestampHelpComponent from "../DataComponents/TimestampHelpComponent.vue";
import SupplierComponent from "../DataComponents/SupplierComponent.vue";

import { sortBy } from "lodash/collection";

const ReusableManualUploadComponent = {
    name: "ReusableManualUpload",
    props: ["emission", "headers", "rows", "loading", "siteId"],
    emits: ["uploadCSV"],
    
    components: {
        EmissionFactorComponent,
        TimestampHelpComponent,
        SupplierComponent,
    },

    data() {
        return {
            file: null,
            showFactors: false,
            showTimestampHelp: false,
            showSuppliers: false,

            loadingSuppliers: false,
            suppliers: [],
            
            gettingExample: false,
            gettingTemplate: false,
        };
    },

    async created() {
        this.loadingSuppliers = true

        try {
            this.suppliers = (await api.supplier.getSuppliers()).data
        } catch (err) {
            notify.error("Unable to retrieve suppliers.", "top")
        } finally {
            this.loadingSuppliers = false
        }
    },

    computed: {
        columns() {
			let uuidColumn = {
                name: "id",
                label: "dataTypeUUID",
                field: "dataTypeUUID",
            }

            let supplierColumn = {
                name: "supplierId",
                label: "supplierId",
                field: "supplierId",
            }
			
			if(this.flags.FLAG_5120_COLUMN_NAMES){
				uuidColumn = {
					name: 'id',
					label: 'emissionFactor',
					field: 'emissionFactor',
				}
			}

            const tableColumns = this.headers.map((header) => {
                return {
                    name: header.field,
                    ...header,
                };
            }).concat(uuidColumn, supplierColumn);

            return sortBy(tableColumns, (col) => !this.columnRequired(col.label));
        }
    },

    methods: {

        /**
         * @desc Emits a CSV upload event
         */
        async submitCSVUpload() {
            this.$emit("uploadCSV", {file: this.file});
            this.file = null;
        },


        /**
         * Given the column label identifies if the row is required for manual upload
         * @param {String} columnLabel the label of the column to check
         */
        columnRequired(columnLabel) {
            return columnLabel == "dataTypeUUID" || columnLabel == "emissionFactor" || columnLabel == "amount" || columnLabel == "timestamp"      
        },


        /**
         * @desc Download an example manual CSV file from the demo tenant
         */
        downloadExample() {
            let emissionSource = this.emission.replace(/ /g, '');

            this.gettingExample = true;
            api.emissions.getExampleCSV(emissionSource).then(() => {
                notify.primary("Successfully downloaded example CSV file!", "top")
            }).catch(err => {
                let data = new Blob([err.response.data],  { type: 'text/xml' })

                data.text().then((message) => {
                    notify.withObject({
                        status: err.response.status,
                        data: message
                    }, "top", "Example Download")
                });
            }).finally(() => {
                this.gettingExample = false;
            });
        },


        /**
         * @desc Download a template manual CSV file from the demo tenant. Will only contain the required headers.
         */
        downloadTemplate() {
            let emissionSource = this.emission.replace(/ /g, '');

            this.gettingTemplate = true;
            api.emissions.getTemplateCSV(emissionSource).then(() => {
                notify.primary("Successfully downloaded template CSV file!", "top");
            }).catch(() => {
                notify.error("There was an error downloading the template CSV file!", "top");
            }).finally(() => {
                this.gettingTemplate = false;
            });
        }
    },
};

export default ReusableManualUploadComponent;
</script>

<style lang="less">
@import "../../assets/styles/dataManualUploadComponent.less";
</style>
