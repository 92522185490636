<template>
    <div>
        <SignupParentComponent/>
    </div>
</template>

<script>
import SignupParentComponent from "@/components/IntroComponents/SignupParentComponent";
import { useSignupStore } from "@/stores/signup.store";

export default {
    name: "home-signup",
    components: {SignupParentComponent},
    title: 'signup-component',

    data() {
        return {
            store: useSignupStore(),
        }
    },

    created() {
        if (this.store.signupType !== "credentials" && this.store.signupType !== "sso") {
            this.$router.push({ path: "/auth/signup" })
        }
    }
}
</script>
