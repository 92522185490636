<template>

    <q-toggle v-model="manualAddress" id="manual-toggle" label="Can't find your address?" style="user-select: none;"/>

    <div v-if="manualAddress" class="manual-address">

        <div class="num-unit" >
            <q-input  stack-label  class="number" outlined v-model="addressObject.number" label="Street Number" lazy-rules required maxlength="20" /> 
            <q-input stack-label class="unit" outlined v-model="addressObject.unit" label="Unit" lazy-rules maxlength="20" />
        </div>

        <q-input stack-label outlined v-model="addressObject.street" label="Street Name" lazy-rules required maxlength="100" />
        <q-input stack-label outlined v-model="addressObject.suburb" label="Suburb" lazy-rules required maxlength="50" />
        <q-input stack-label outlined v-model="addressObject.city" label="City" lazy-rules required maxlength="75" />
        <q-input stack-label outlined v-model="addressObject.postcode" label="Post Code" lazy-rules required mask="##########" />
        <q-input stack-label outlined v-model="addressObject.country" label="Country" lazy-rules required maxlength="75" />
        <q-input stack-label outlined v-model="addressObject.POBox" label="PO Box" maxlength="50" />
    </div>

    <q-select v-else
        outlined
        stack-label
        v-model="address"
        :options="addresses"
        use-input
        hide-selected
        fill-input
        input-debounce="0"
        hide-dropdown-icon
        @filter="addressFilterFn"
        label="Address"
        :rules="[(val) => val && val?.trim().length > 0 || 'Address is required']"
        maxlength="75"
    >
        <template v-if="toolTip" v-slot:after>
            <q-icon
                name="help"
                color="grey"
                size="sm">
                <q-tooltip style="font-size: 1rem">
                    {{ toolTip }}
                </q-tooltip>
            </q-icon>
        </template>
        
        <template v-slot:no-option>
            <q-item>
                <q-item-section class="text-grey">
                    No results
                </q-item-section>
            </q-item>
        </template>
    </q-select>


</template>
<script>

import api from "../../services/api/api";

/**
 * @prop {Object} ModelValue The address object to update 
 * 
 * {
 *  addressUnit: "",
 *  addressNumber: "",
 *  addressStreet: "",
 *  addressSuburb: "",
 *  addressPOBox: "",
 *  addressCity: "",
 *  addressPostCode: "",
 *  addressCountry: ""
 * }
 * 
 * 
 */

const AddressInput = {
    props: {
        modelValue: {
            type: Object,
        },
        toolTip: {
            type: String,
        }
    },
    inheritAttrs: false,
    emits: ['update:modelValue'],
    data() {
        return {
            manualAddress: false,
            address: "",
            addressObject: {},
            addresses: []
        }
    },
    created() {
        if (!this.modelValue || Object.keys(this.modelValue).length !== 8) {
            this.addressObject = {
                unit: "",
                number: "",
                street: "",
                suburb: "",
                POBox: "",
                city: "",
                postcode: "",
                country: ""
            }
        } else {
            this.addressObject = this.modelValue;
        }

        this.address = `${this.addressObject.number}${this.addressObject.unit ?? ""} ${this.addressObject.street}`

        if (this.addressObject.suburb) {
            this.address += `, ${this.addressObject.suburb}`
        }

        if (this.addressObject.city) {
            this.address += `, ${this.addressObject.city}`
        }

        if (this.addressObject.postcode) {
            this.address += `, ${this.addressObject.postcode}`
        }

        if (this.addressObject.country) {
            this.address += `, ${this.addressObject.country}`
        }
        
    },

    watch: {
        address(val) {
            let addressArray = val.split(",")
            let streetArray = addressArray.at(0) ? addressArray.at(0).split(" ") : []

            let streetNumberArray = streetArray.at(0) ? streetArray.shift().match(/[a-zA-Z]+|[0-9]+/g) : []

            const tempAddress = {
                number: streetNumberArray.at(0) ? streetNumberArray.shift().trim() : "",
                unit: streetNumberArray.join(""),
                street: streetArray.join(" "),
                suburb: addressArray.at(1) ? addressArray.at(1).trim() : "",
                POBox: "",
                city: addressArray.at(2) ? addressArray.at(2).trim() : "",
                postcode: addressArray.at(3) ? addressArray.at(3).trim() : "",
                country: addressArray.at(4) ? addressArray.at(4).trim() : "",
               
            }
            this.$emit('update:modelValue', tempAddress);
        },

        addressObject: {
            deep: true,
            handler() {
                this.$emit('update:modelValue', this.addressObject) 
            }
        },
        
        manualAddress(val) {
            if (val) {
                this.addressObject = this.modelValue;
            } else {
                this.address = `${this.addressObject.number}${this.addressObject.unit ?? ""} ${this.addressObject.street}`

                if (this.addressObject.suburb) {
                    this.address += `, ${this.addressObject.suburb}`
                }

                if (this.addressObject.city) {
                    this.address += `, ${this.addressObject.city}`
                }

                if (this.addressObject.postcode) {
                    this.address += `, ${this.addressObject.postcode}`
                }

                if (this.addressObject.country) {
                    this.address += `, ${this.addressObject.country}`
                }
            }
        }
    },

    methods: {
        /**
		 * @desc When address field is edited update address options accordingly
		 * @param {String} val current value of the address field
		 */
		addressFilterFn(val, update) {
			update(() => {
				const address = !val ? this.address : val;
				api.sites.getAutoCompleteAddresses(address).then((addresses) => this.addresses = addresses)
			})
		},
    }




}

export default AddressInput;

</script>
<style src="../../assets/styles/forms.less" lang="less" />
