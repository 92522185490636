import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            msalConfig: {
                auth: {
                    clientId: 'b3d9a653-9c14-481b-a862-bcf317743bfb',
                    authority:
                        'https://login.microsoftonline.com/common',
                        
                },
                cache: {
                    cacheLocation: 'localStorage',
                },
                
            },
            accessToken: ''
        };
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token;
        }
    }
});

export default store;