<template>
	<q-card style="max-width: 50rem; min-height: 96.2vh">
		<q-card-section>
			<div class="text-h4">Column and Value mapping </div>
		</q-card-section>

		<q-card-section>
			The bulk upload feature supports column header and column value mapping
			between the values on your spreadsheets, and the values GreenHalo
			uses to record emission usages. The bulk upload mapping feature aims
			to make your manual data upload process easier, and auditing process
			to go more smoothly.
			<br><br>
			Further slides on this guide will illustrate
			how to use the bulk upload mapping feature.
		</q-card-section>
		<q-card-section>
			<BulkUploadHelpCarousel />
		</q-card-section>

		<q-card-actions align="right">
			<q-btn flat label="OK" color="primary" v-close-popup />
		</q-card-actions>
	</q-card>
</template>


<script>

	import BulkUploadHelpCarousel from "./BulkUploadHelpCarousel.vue";

	export default {
		components: {
			BulkUploadHelpCarousel,
		},
	}
</script>

<style lang="less" src="@/assets/styles/bulkUpload.less" scoped />
