<template>
  <div class="report-export-container">
    <h2 class="report-heading">Emissions Summary - Excel</h2>
    <q-form class="export-form" @submit="generateReport()">
      <div>
        <span class="bold-text">Report Type</span>
        <q-select v-model="selectedReportType" :options="reportTypes" outlined emit-value map-options />
      </div>
      <div>
        <span class="bold-text">Date Range</span>
        <q-select v-model="selectedDate" :options="dateOptions" outlined />
      </div>
      <div class="date-selection-container">
        <q-input 
          class="date-input"
          v-model="formattedStartDate"
          :mask="dateFormat.replace(/[a-z]/gi, '#')"
          :rules="[(val) => dayjs(val, dateFormat, true).isValid()]" label="From"
          :placeholder="dateFormat"
          clearable
          required
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date v-model="formattedStartDate" today-btn :mask="dateFormat">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Ok" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-input
          class="date-input"
          v-model="formattedEndDate"
          :mask="dateFormat.replace(/[a-z]/gi, '#')"
          :rules="[(endDate) => dayjs(endDate, dateFormat, true).isSameOrAfter(selectedDate?.start)]"
          label="To"
          :placeholder="dateFormat"
          clearable
          required
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date v-model="formattedEndDate" :mask="dateFormat" :options="(endDate) => dayjs(endDate).isSameOrAfter(selectedDate?.start)">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Ok" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
      <div v-if="selectedReportType!='Flat Format Report'" class="comparison-container">
        <div>
          <span class="bold-text">Comparison</span>
          <q-select v-model="selectedComparison" :options="comparisonOptions"
            :disable="!(dayjs.isDayjs(selectedDate?.start) && dayjs.isDayjs(selectedDate?.end))" outlined />
          <div v-if="selectedComparison?.label === 'Custom'" class="date-selection-container">
            <q-input
              class="date-input"
              v-model="formattedStartDateComparison"
              :mask="dateFormat.replace(/[a-z]/gi, '#')"
              :rules="[(startDate) => dayjs(startDate, dateFormat).isAfter(selectedDate?.end)]"
              label="From"
              :placeholder="dateFormat"
              clearable
              required
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="formattedStartDateComparison" today-btn :mask="dateFormat"
                      :options="(startDate) => dayjs(startDate).isAfter(selectedDate?.end)">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>

            <q-input
              class="date-input"
              v-model="formattedEndDateComparison"
              :mask="dateFormat.replace(/[a-z]/gi, '#')"
              :rules="[(endDate) => dayjs(endDate, dateFormat, true).isSameOrAfter(selectedComparison?.dates[0]?.start)]"
              label="To"
              :placeholder="dateFormat"
              clearable
              required
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="formattedEndDateComparison" :mask="dateFormat"
                      :options="(endDate) => dayjs(endDate).isSameOrAfter(selectedComparison?.dates[0]?.start)">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </div>
      </div>

      <div>
        <span class="bold-text">Report Subcategory</span>
        <q-select v-model="selectedReportSubcategory" :options="reportSubcategories" outlined />
      </div>

	<div v-if="selectedReportType=='Flat Format Report'" class="report-tag-selector" >
        <span class="bold-text">Tags</span>

		<q-select
			outlined
			bg-color="white"
			multiple
			use-chips
			:disable="sourcesLoading"
			v-model="selectedTags"
			label="Tags"
			:options="availableTags"
			>
		</q-select>
	</div>
	<q-btn
		class="generate-button"
		no-caps
        label="Generate Report"
        type="submit"
        color="secondary"
        icon-right="description"
        :loading="loading"
      />
    </q-form>
  </div>
</template>

<script>
	import dayjs from "@/services/util/dayjs";
	import api from '@/services/api/api';
	import notify from '@/services/util/notify';

	import { convertDateToISO } from "@/services/util/dates";
	export default {
		data() {
			return {
				dayjs: dayjs,
				dateFormat: "DD/MM/YYYY",
				reportTypes: [
					{ 
						value: "Detailed Report",
						label: "Accounting style detailed report",
					},
					{ 
						value: "Summary Report",
						label: "Accounting style summary report",
					},
					{ 
						value: "Flat Format Report",
						label: "Flat format snapshot report"
					},
				],
				reportSubcategories: [
					"None",
					"ISO",
					"GHG"
				],
				availableTags: [],
				selectedComparison: null,
				selectedReportSubcategory: null,
				selectedReportType: "",
				dateOptions: [],
				selectedDate: null,
				selectedTags: [],
				comparisonNumOptions: 4,
				loading: false,
				loadingTags: false,
			}
		},
		created() {
			this.initDateOptions();
			this.selectedReportSubcategory= this.reportSubcategories[0];
			this.selectedReportType = this.reportTypes[0].value;
			this.selectedDate = this.dateOptions[0];
			this.selectedComparison = this.comparisonOptions[0];
			this.loadAvailableTags();
		},
		computed: {
			formattedStartDate: {
				get() {
					// Display the invalid date string
					if (typeof this.selectedDate?.start === "string") {
						return this.selectedDate.start;
					}

					return this.selectedDate?.start?.format(this.dateFormat);
				},
				set(value) {
					const parsed = dayjs(value, this.dateFormat, true);
					const newSelectedDate = {
						type: null,
						label: "Custom",
						start: value,
						end: null,
					};

					if (parsed.isValid()) {
						newSelectedDate.start = parsed;

						// Use existing end date if the start date is <= the end date
						if (this.selectedDate?.end && parsed.isSameOrBefore(this.selectedDate.end)) {
							newSelectedDate.end = this.selectedDate.end;
						}
					} else {
						newSelectedDate.end = this.selectedDate?.end;
					}

					this.selectedDate = newSelectedDate;
				}
			},
			formattedEndDate: {
				get() {
					// Display the invalid date string
					if (typeof this.selectedDate?.end === "string") {
						return this.selectedDate.end;
					}

					return this.selectedDate?.end?.format(this.dateFormat);
				},
				set(value) {
					const parsed = dayjs(value, this.dateFormat, true);
					this.selectedDate = {
						type: null,
						label: "Custom",
						start: this.selectedDate?.start,
						end: parsed.isValid() ? parsed : (value ? value : null),
					};
				}
			},
			formattedStartDateComparison: {
				get() {
					// Display the invalid date string
					if (typeof this.selectedComparison?.dates[0]?.start === "string") {
						return this.selectedComparison.dates[0].start;
					}

					return this.selectedComparison?.dates[0]?.start?.format(this.dateFormat);
				},
				set(value) {
					const parsed = dayjs(value, this.dateFormat, true);
					const date = {
						start: value,
						end: null,
					};

					if (parsed.isValid()) {
						date.start = parsed;

						// Use existing end date if the start date is <= the end date
						if (this.selectedComparison?.dates[0]?.end && parsed.isSameOrBefore(this.selectedComparison.dates[0].end)) {
							date.end = this.selectedComparison.dates[0].end;
						}
					} else {
						date.end = this.selectedComparison?.dates[0]?.end;
					}

					this.selectedComparison.dates[0] = date;
				}
			},
			formattedEndDateComparison: {
				get() {
					// Display the invalid date string
					if (typeof this.selectedComparison?.dates[0]?.end === "string") {
						return this.selectedComparison.dates[0].end;
					}

					return this.selectedComparison?.dates[0]?.end?.format(this.dateFormat);
				},
				set(value) {
					const parsed = dayjs(value, this.dateFormat, true);
					this.selectedComparison.dates[0] = {
						start: this.selectedComparison.dates[0]?.start,
						end: parsed.isValid() ? parsed : (value ? value : null),
					};
				}
			},
			comparisonOptions() {
				const options = [{ dates: [], label: "No comparison", }];
				const yearOptions = [];

				// If the dates are invalid
				if (!dayjs.isDayjs(this.selectedDate?.start) || !dayjs.isDayjs(this.selectedDate?.end)) {
					return options;
				}

				// Create the comparison options
				for (let i = 1; i <= this.comparisonNumOptions; i++) {
					const option = { dates: [], label: `Last${i > 1 ? ` ${i}` : ""} ${this.selectedDate.type}${i > 1 ? "s" : ""}` };
					const yearOption = { dates: [], label: `Last${i > 1 ? ` ${i}` : ""} year${i > 1 ? "s" : ""}` };

					// Create all the dates - e.g. if last 3 months, get the start & end date values for the last month, last last month, and last last last month
					for (let j = 1; j <= i; j++) {
						// If selected date range is not custom
						if (this.selectedDate.type) {
							option.dates.push({
								start: dayjs(this.selectedDate.start).subtract(j, this.selectedDate.type),
								end: dayjs(this.selectedDate.end).subtract(j, this.selectedDate.type),
							});
						}

						// If the comparison selection is not years, add past years as an option
						if (this.selectedDate.type !== "year") {
							yearOption.dates.push({
								start: dayjs(this.selectedDate.start).subtract(j, "year"),
								end: dayjs(this.selectedDate.end).subtract(j, "year"),
							});
						}
					}

					if (this.selectedDate.type) {
						options.push(option);
					}

					if (this.selectedDate.type !== "year") {
						yearOptions.push(yearOption);
					}
				}

				if (yearOptions.length > 0) {
					options.push(...yearOptions);
				}

				options.push({ dates: [], label: "Custom" });

				return options;
			},

		},
		watch: {
			comparisonOptions() {
				// Reset the selected comparison value
				this.selectedComparison = this.comparisonOptions[0];
			},
			selectedComparison() {
				// Reset custom date values when different option selected
				if (this.selectedComparison?.label === "Custom") {
					this.selectedComparison.dates = [];
				}
			}
		},
		methods: {
			async initDateOptions() {
				const currentDate = dayjs();

				const currentMonthStart = currentDate.startOf("month");
				const currentMonthEnd = currentDate.endOf("month");

				const currentQuarterStart = currentDate.startOf("quarter");
				const currentQuarterEnd = currentDate.endOf("quarter");

				const currentYear = currentDate.year();


				let currentFinancialYearSetting;
				try {
					const setting = await api.company.getFinancialYearSetting();
					currentFinancialYearSetting = setting.data.fullDate;
				} catch {
					console.error("Unable to get financial year setting");
				}

				let currentFiscalYearStart = dayjs(currentFinancialYearSetting)				
				if (!currentFiscalYearStart.isValid()) {
					currentFiscalYearStart = dayjs({ year: currentYear, month: 3, day: 1 });
					notify.warning("Unable to get your financial year setting. Default financial year has been selected.")

				}		

				// If the current month is before April (i.e., January, February, or March), subtract a year
				if (currentDate.isBefore(currentFiscalYearStart)) {
					currentFiscalYearStart = currentFiscalYearStart.subtract(1, "year");
				}

				let currentFiscalYearEnd = currentFiscalYearStart.subtract(1, "day").add(1, "year");


				const lastMonthStart = currentDate.subtract(1, "month").startOf("month");
				const lastMonthEnd = currentDate.subtract(1, "month").endOf("month");

				const lastQuarterStart = currentDate.quarter(currentDate.quarter() - 1).startOf("quarter");
				const lastQuarterEnd = currentDate.quarter(currentDate.quarter() - 1).endOf("quarter");

				const lastFiscalYearStart = currentFiscalYearStart.subtract(1, "year");
				const lastFiscalYearEnd = currentFiscalYearEnd.subtract(1, "year");

				const currentYearStart = dayjs(new Date(currentYear, 0, 1));
				const currentYearEnd = dayjs(new Date(currentYear, 11, 31));


				const previousYearStart = dayjs(new Date(currentYear - 1, 0, 1));
				const previousYearEnd = dayjs(new Date(currentYear - 1, 11, 31)); 

				// The type property is used for adding/subtracting dayjs objects, so it needs to be a suitable value
				this.dateOptions.push(
					{
						type: "month",
						label: `This month (${currentMonthStart.format("MMM YYYY")})`,
						start: currentMonthStart,
						end: currentMonthEnd,
					},
					{
						type: "quarter",
						label: `This quarter (${currentQuarterStart.format("DD MMM YYYY")} - ${currentQuarterEnd.format("DD MMM YYYY")})`,
						start: currentQuarterStart,
						end: currentQuarterEnd,
					},
					{
						type: "year",
						label: `This financial year (${currentFiscalYearStart.format("DD MMM YYYY")} - ${currentFiscalYearEnd.format("DD MMM YYYY")})`,
						start: currentFiscalYearStart,
						end: currentFiscalYearEnd,
					},
					{
						type: "year",
						label: `Last financial year (${lastFiscalYearStart.format("DD MMM YYYY")} - ${lastFiscalYearEnd.format("DD MMM YYYY")})`,
						start: lastFiscalYearStart,
						end: lastFiscalYearEnd,
					},
					{
						type: "month",
						label: `Last month (${lastMonthStart.format("MMM YYYY")})`,
						start: lastMonthStart,
						end: lastMonthEnd,
					},
					{
						type: "quarter",
						label: `Last quarter (${lastQuarterStart.format("DD MMM YYYY")} - ${lastQuarterEnd.format("DD MMM YYYY")})`,
						start: lastQuarterStart,
						end: lastQuarterEnd,
					},
					{
						type: "year",
						label: `Current calendar year (${currentYearStart.format("DD MMM YYYY")} - ${currentYearEnd.format("DD MMM YYYY")})`,
						start: currentYearStart,
						end: currentYearEnd,
					},
					{
						type: "year",
						label: `Last calendar year (${previousYearStart.format("DD MMM YYYY")} - ${previousYearEnd.format("DD MMM YYYY")})`,
						start: previousYearStart,
						end: previousYearEnd,
					},
				);
			},
			async generateReport() {
				this.loading = true;

				// Pretty hacky. We need to use endOf('day') to ensure that date inclusive works. This is how we use it for the trend graphs. Might be worth generalising it somewhere/somehow
				let dateObj = {
					start: convertDateToISO(this.selectedDate.start.toDate()),
					end: convertDateToISO(this.selectedDate.end.endOf('day').toDate()),
					label: this.selectedDate.label,
					type: this.selectedDate.type
				}

				let comparisonRanges = {
					dates: [],
					label: this.selectedComparison.label
				};

				for (let dateObject of this.selectedComparison.dates) {
					const comparisonDate = {
						start: convertDateToISO(dateObject.start.toDate()),
						end: convertDateToISO(dateObject.end.endOf('day').toDate()),
					}

					comparisonRanges.dates.push(comparisonDate)
				}

				const queryParams = {
					reportType: this.selectedReportType,
					selectedRange: dateObj,
					comparisonRanges: comparisonRanges.dates,
					reportSubcategory: this.selectedReportSubcategory,
					tags: this.selectedTags
				};

				try {
					await api.reports.getAccountingScopeReport(queryParams);
					notify.primary(`Accounting ${this.selectedReportType} successfuly generated`, 'top', "Report Generated");
					this.$emit('close');
				} catch (err) {
					notify.withObject(err, 'top', "Unable to Generate Report");
				} finally {
					this.loading = false;
				}
			},

			async loadAvailableTags () {
				try {
					this.loadingTags = true;
					let response = await api.tags.getTags();
					this.availableTags = response.data.map((tagObject) => tagObject.name);
				} catch (error) {
					console.error(error);
					notify.withObject(error, 'top', "Error fetching tags");
				} finally {
					this.loadingTags = false;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
.report-export-container {
	.export-form {
		.bold-text {
			font-weight: bold;
		}

		.date-selection-container {
			display: flex;
			gap: 2.5rem;
			justify-content: space-between;

			.date-input {
				width: 50%;
			}
		}
	}
}
</style>
