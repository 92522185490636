<template>
  <div class="container">
    <h2 class="heading">Add Site</h2>
    <div>
      <q-form class="form" @submit.prevent="createSite()">
        <div class="inputs">
          <q-input
            stack-label
            outlined
            bg-color="white"
            v-model="siteName"
            label="Site Name"
            required
            maxlength="50"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The name of your new site.
                </q-tooltip>
              </q-icon></template
            >
          </q-input>
          <q-input
            stack-label
            outlined
            type="number"
            bg-color="white"
            use-input
            step="any"
            hide-dropdown-icon
            v-model.number="fte"
            label="FTE employees"
            @new-value="newCategory"
            required
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The equivalent number of full time employees currently
                  employed at this new site.
                </q-tooltip>
              </q-icon></template
            >
          </q-input>
          <q-select
            stack-label
            outlined
            bg-color="white"
            use-input
            hide-dropdown-icon
            v-model="category"
            label="Category"
            :options="siteCategories"
            @new-value="newCategory"
            :rules="[(val) => !!val || 'Category is required']"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The category of your new site. To add a new category, type
                  something and press enter
                </q-tooltip>
              </q-icon></template
            >
          </q-select>

          <AddressInput
            v-model="address"
            tool-tip="The address of your new site"
          />

          <q-select
            stack-label
            outlined
            bg-color="white"
            multiple
            v-model="sources"
            label="Emission Sources"
            :popup-content-style="{ height: '30vh' }"
            :options="getSourceDisplayNames"
            :rules="[
              (val) =>
                val.length > 0 ||
                'Please select at least 1 (one) emission source',
            ]"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The emission sources that you wish to track for this site.
                </q-tooltip>
              </q-icon></template
            >
          </q-select>
        </div>

        <q-btn
          class="button"
          :disable="submitLoading"
          :loading="submitLoading"
          square
          no-caps
          padding="8px 24px"
          color="secondary"
          label="Create"
          type="submit"
        />
      </q-form>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { useSiteStateStore } from "@/stores/siteState.store";

import AddressInput from "../../FormComponents/AddressInput.vue";

const CreateSiteComponent = {
  name: "Sites",
  props: ["hideCloseButton"],
  emits: ["close"],
  components: {
    AddressInput,
  },
  data() {
    return {
      siteStateStore: useSiteStateStore(),
      submitLoading: false,

      siteCategories: [],
      emissionSources: [],
      addresses: [],

      // Form data v-models
      siteName: "",
      category: undefined,
      address: {},

      fte: 0,
      sources: [],

      addressResult: "",
    };
  },

  created() {
    this.init();
  },
  computed: {
    getSourceDisplayNames() {
      return this.emissionSources.map(function (item) {
        return item.sourcedisplayname;
      });
    },
  },

  methods: {
    /**
     * @desc Posts new site data to server based on info filled out in form.
     */
    createSite() {
      this.submitLoading = true;
      let emissionSourceIds = this.getEmissionSourceIDs();

      let payload = {
        sites: [
          {
            name: this.siteName,
            addressNumber: this.address.number,
            addressUnit: this.address.unit,
            addressStreet: this.address.street,
            addressSuburb: this.address.suburb,
            addressPOBox: this.address.POBox,
            addressCity: this.address.city,
            addressPostCode: this.address.postcode,
            addressCountry: this.address.country,
            category: this.category,
            fte: this.fte,
            emissionSources: emissionSourceIds,
          },
        ],
      };

      api.sites
        .postSite(payload)
        .then(() => {
          notify.primary(
            `Successfully created new site ${this.siteName}`,
            "top"
          );
          this.$emit("close");
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => {
          this.submitLoading = false;
          this.siteStateStore.getSiteList();
        });
    },

    /**
     * @desc Loads all of the site categories that currently exist.
     */
    init() {
      api.sites.getSiteCategories().then((res) =>
        res.data.forEach((category) => {
          if (category.categoryDetail) {
            return this.siteCategories.push(category.categoryDetail);
          }
        })
      );

      api.data
        .getEmissionSources()
        .then((res) => (this.emissionSources = res.data));
    },

    /**
     * @desc Adds new site category to siteCategories
     */
    newCategory(val, done) {
      if (!this.siteCategories.includes(val)) {
        this.siteCategories.push(val);
        done(val, "add-unique");
      }
    },

    /**
     * @desc This method will determine the IDs of the selected emission
     * sources in this.sources, and return an array of objects with the attribute emissionSourceId: <id>
     *
     * @returns array of objects with the attribute emissionSourceId: <id>
     */
    getEmissionSourceIDs() {
      let ids = [];
      this.sources.forEach((selectedSource) => {
        this.emissionSources.forEach((source) => {
          if (source.sourcedisplayname == selectedSource) {
            ids.push({ emissionSourceId: source.emissionSourceId });
          }
        });
      });
      return ids;
    },
  },
};

export default CreateSiteComponent;
</script>

<style lang="less" src="@/assets/styles/siteModal.less" scoped />