<template>
	<div v-if="supplier === 'Z' || supplier === 'BP' || supplier === 'NZ Post'"  class="row items-center" style="gap: 1em;">
		<q-btn color="primary" size="smg" :label="`Setup Connection`" no-caps @click="openTutorial = true">
			<q-dialog v-model="openTutorial"> 
				<q-card class="q-ma-sm dialog-card row reverse">
					<q-btn class="close-btn" flat round dense icon="close" v-close-popup color="red" size="1.25em"/>
					<q-card-section class="card-content">
						<component :is="currentTutorial" />
					</q-card-section>
				</q-card>
			</q-dialog>
		</q-btn>
	</div>
	
</template>
<script>
import ZTutorial from "../Tutorials/ZTutorial"
import BPTutorial from "../Tutorials/BPTutorial"
import EShipTutorial from "../Tutorials/EShipTutorial"

export default {
	name: "ConnectionTutorialComponent",
	components: {
		ZTutorial,
		BPTutorial,
		EShipTutorial,
	},

	data() {
		return {
			openTutorial: false
		}
	},

	props: {
		supplier: {
			type: String
		}
	},

	computed: {
		currentTutorial() {
			switch (this.supplier) {
				case "Z":
					return "ZTutorial";
				case "BP":
					return "BPTutorial";
				case "NZ Post":
					return "EShipTutorial";
				default:
					return "";
			}
		}
	}
}

</script>
<style scoped lang="less" src="../../../assets/styles/dialog.less"/>
