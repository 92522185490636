<template>
    <div class="report-export-container">
      <h2 class="report-heading">Goal Snapshot</h2>
  
        <!-- Body of the modal -->
        <q-checkbox class="q-pb-md" v-model="includeConnected" left-label label="Include Connected Organisations:"/>

        <q-form class="export-form" @submit="generateReport()">
            <p class="emissions-heading text-bold">Date Range (inclusive)</p>

            <div class="date-container">
                <div class="date-field-container">
                    <span class="report-text report-label">From</span>
                        <q-field class="date-field" dense>
                            <q-input class="date-input" hide-bottom-space v-model="startDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" 
                                :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
                                :placeholder="dateFormat">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                            <q-date v-model="startDate" :mask="dateFormat">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </q-field>
                </div>

                <div class="date-field-container">
                    <span class="report-text report-label">To</span>
                        <q-field class="date-field" dense>
                            <q-input class="date-input" hide-bottom-space v-model="endDate" :mask="dateFormat.replace(/[a-z]/gi, '#')"
                                :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
                                :placeholder="dateFormat">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                            <q-date v-model="endDate" :mask="dateFormat">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </q-field>
                </div>
            </div>
            <q-btn class="generate-button" no-caps label="Generate Report" type="submit" color="secondary" icon-right="description"/>
        </q-form>   

       
    </div>
</template>
<script>

import { toISOStringIgnoreTimezone } from "@/services/util/helper";
import dayjs from "@/services/util/dayjs";

const goalsExportComponent = {
    name: "GoalExportComponent",
    data() {
        return {
            dayjs: dayjs,
            dateFormat: "DD/MM/YYYY",
            startDate: "",
            endDate: "",
            includeConnected: false,
        }
    },

    created() {
        this.endDate = dayjs().format(this.dateFormat);
    },

    methods: {

        /**
         * @desc Goes to the report generation page with provided parameters
         */
        generateReport() {
            let startDate = toISOStringIgnoreTimezone(dayjs(this.startDate, this.dateFormat).format("YYYY-MM-DD"));
            let endDate = toISOStringIgnoreTimezone(dayjs(this.endDate, this.dateFormat).format("YYYY-MM-DD"));

            let includeConnected = this.includeConnected;    

            this.$router.push({ path: '/goals-report', query: { startDate, endDate, includeConnected }})
        },
    }
}

export default goalsExportComponent;


</script>
<style lang="less" src="../../assets/styles/reports.less"/>