<template>
  <div class="report-export-container">
    <h2 class="report-heading">Toitū emanage</h2>
    <!-- Body of the modal -->
    <q-form class="export-form" @submit="submit">
      <!-- Dates -->
      <div class="date-container">
        <!-- Start date -->
        <div class="date-field-container">
          <span class="date-span">From</span>
          <q-field class="date-field" dense>
            <q-input class="date-input" hide-bottom-space v-model="startDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
              :placeholder="dateFormat">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="startDate" :mask="dateFormat">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-field>
        </div>

        <!-- End date -->
        <div class="date-field-container">
          <span class="date-span">To</span>
          <q-field class="date-field" dense>
            <q-input class="date-input" hide-bottom-space v-model="endDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
              :placeholder="dateFormat">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="endDate" :options="validEndDate" :mask="dateFormat">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-field>
        </div>
      </div>

      <div class="emissions-container">

        <h3 class="emissions-heading">Upload Your Bulk Upload Template</h3>

        <div class="column">
          <q-toggle v-if="mappingsAvailible" v-model="useSavedMappings" label="Use Previous Mappings"/>
          <q-checkbox v-if="useSavedMappings" v-model="remapMappings" left-label label="Update Mappings"/>
        </div>
        
        <FileUpload
          v-if="!useSavedMappings"
          v-model="templateFile"
          height="7.5rem"
          accept=".xlsx,.xls"
          remove-submit
        />
        
      </div>

      <!-- Search -->
      <div class="search-container">
        <q-icon name="search" size="1.5em" color="grey" />
        <q-input class="search-input" borderless v-model="search" label="Search Emission" />
      </div>

      <!-- Emission sources selection -->
      <div class="emissions-container">
        <h3 class="emissions-heading">Your Emissions</h3>

        <div class="flex flex-center" v-if="loadingSources">
          <q-spinner color="primary" size="xl"/>
        </div>

        <q-field v-else class="emissions-field">
          <q-checkbox class="select-all" v-if="options.length" v-model="selectAll" :label="selectAllLabel" left-label />
          <q-option-group class="emissions-group" v-model="selectedEmissionSources" :options="options" type="checkbox" left-label>
            <template v-slot:label="opt">
              <span class="audit-emission-option">{{ opt.label }}</span>
            </template>
          </q-option-group>
          <span class="not-found" v-if="!options.length && !loadingSources">No Results Found</span>
        </q-field>
        
        
      </div>
      <q-btn class="generate-button" no-caps label="Export Report" type="submit" color="secondary" icon-right="ios_share"
        :loading="loading" :disable="loading || (!templateFile && !useSavedMappings)" />
    </q-form>

    <q-dialog v-model="showToituMapping" persistent>
      <ToituMappingParent :mappingId="mappingId" :toituMappings="toituMappings" :selectedEmissionSources="selectedEmissionSources"  :dateRange="dateRange" @generateReport="onMappingSuccess"></ToituMappingParent>
    </q-dialog>

  </div>
</template>

<script>
import { ref } from 'vue';
import notify from "@/services/util/notify";
import api from "@/services/api/api";
import dayjs from "@/services/util/dayjs";
import FileUpload from '../FileUploadComponent.vue';
import ToituMappingParent from './Toitu/ToituMappingParent.vue';

const ToituExportComponent = {
  components: {
    FileUpload,
    ToituMappingParent
  },
  data() {
    return {
      dayjs: dayjs,
      dateFormat: "DD/MM/YYYY",
      startDate: "",
      endDate: "",
      selectedEmissionSources: ref([]),
      originalOptions: [],
      options: [],
      labels: [],
      selectAll: false,
      selectAllLabel: "Select All",
      search: "",
      loading: false,
      loadingSources: false,
      templateFile: null,
      showToituMapping: false,

      mappingsAvailible: false,
      useSavedMappings: false,
      remapMappings: false,
    }
  },

  async created(){
    await this.loadEmissionSources();
    await this.checkMappingsAvailable();
  },

  computed: {
    dateRange() {
      const parsedStartDate = dayjs(this.startDate, this.dateFormat);
      const parsedEndDate = dayjs(this.endDate, this.dateFormat).endOf('day');

      return {
        startDate: parsedStartDate.isValid() ? parsedStartDate.toISOString() : "",
        endDate: parsedEndDate.isValid() ? parsedEndDate.toISOString() : "",
      }
    }
  },

  methods: {

    /**
     * @desc Checks to see if mappings are avalible
     */
    async checkMappingsAvailable() {
      try {
        const response = await api.reports.checkSavedMappingsAvailable();
        this.mappingsAvailible = response.data;
      } catch (error) {
        notify.withObject(error.response);
      }
    },

    /**
     * @desc Queries server for list of available emission sources.
     */
    async loadEmissionSources(){
      this.loadingSources = true;
      try {
        const response = await api.sites.getSiteEmissionSources();
        this.options = response.data.emissionSources.map((source) => { 
          return { label: source.sourcedisplayname, value: source.tablename };
          }
        )
        this.originalOptions = [...this.options];
      } catch (error) {
        notify.withObject(error.response);
      } finally {
        this.loadingSources = false;
      }
    },


    /**
     * @desc Checks if the endDate is the same or after the startDate - endDate cannot be before startDate.
     * @param {String} date selected endDate
     */
    validEndDate(date) {
      return dayjs(date).isSameOrAfter(dayjs(this.startDate, this.dateFormat));
    },


    /**
     * @desc Uploads the provided Toitu template file.
     */
    async submit() {
      if (this.selectedEmissionSources.length === 0) {
        notify.error("Please select at least one emission source", "top");
        return;
      }

      if (this.useSavedMappings) {
        if (this.remapMappings) {
          this.remapSavedMappings();
        } else {
          await this.checkSavedMappings();
        }
      } else {
        await this.uploadTemplateFile();
      }
    },

    /**
     * @desc Runs when user successfully maps toitu data using the mapping component
     */
    async onMappingSuccess() {
      this.checkMappingsAvailable();
      this.useSavedMappings = true;
      this.showToituMapping = false;
      await this.generateReport();
    },


    /**
     * Generates a report by fetching Toitu emanage bulk data for the selected date range and emission sources.
     */
    async generateReport() {
      this.loading = true
      try {
        await api.reports.getToituEmanageBulkData(this.dateRange, this.selectedEmissionSources, this.mappingId);
        notify.primary("Successfully downloaded emanage bulk data", "top", "Toitū emanage")
        this.$emit('close');
      } catch (error) {
        notify.withObject(error.response, "top", "Toitū emanage");
      } finally {
        this.loading = false;
      }
    },


    /**
     * Prompts the user to remap their mappings as requested  
     */
    async remapSavedMappings() {
      this.loading = true;
      try {
        const response = await api.reports.getLatestToituMapping();
        this.toituMappings = response.data.toituMappings;
        this.mappingId = response.data.mappingId;
        this.showToituMapping = true
      } catch (error) {
        notify.withObject(error.response, "top", "Toitū emanage");
      } finally {
        this.loading = false;
      }
    },


    /**
     * Checks to see if all mappings required are avalible to build the report, else prompts the user to remap
     */
    async checkSavedMappings() {
      this.loading = true;
      try {
        const response = await api.reports.checkAllFactorsMapped(this.dateRange, this.selectedEmissionSources);

        if (Object.keys(response.data).length !== 0) {
          this.toituMappings = response.data.toituMappings;
          this.mappingId = response.data.mappingId;
          this.showToituMapping = true
        } else {
          await this.generateReport();
        }
      } catch (error) {
        notify.withObject(error.response, "top", "Toitū emanage");
      } finally {
        this.loading = false;
      }
    },


    /**
     * Uploads a template file for Toitu integration.
     * Sets loading state, processes date range, uploads the file, and handles response or error.
     */
    async uploadTemplateFile() {
      this.loading = true 

      try {
        const response = await api.reports.uploadToituTemplate(this.templateFile, this.dateRange, this.selectedEmissionSources)
        this.toituMappings = response.data.toituMappings;
        this.mappingId = response.data.mappingId;
        this.showToituMapping = true
      } catch (error) {
        notify.error(error.response.data ?? "Failed to upload template file.", "top", "Toitū emanage");
      } finally {
        this.loading = false;
      }
    },
  },


  watch: {
    startDate(date) {
      // Clears the endDate if selected startDate is after the endDate
      if (dayjs(date, this.dateFormat).isAfter(dayjs(this.endDate, this.dateFormat))) {
        this.endDate = "";
      }
    },

    selectAll(isSelected) {
      // Select All / Unselect All checkbox
      this.labels = this.options.map((option) => option.value);

      if (isSelected) {
        this.selectedEmissionSources = this.labels;
        this.selectAllLabel = "Unselect All";
      } else if (this.selectedEmissionSources.length === this.labels.length) {
        this.selectedEmissionSources = [];
      }
    },

    useSavedMappings(val) {
        // Set remapMappings to false if saved mappings is disabled
        if (!val) {
          this.remapMappings = false;
        }
    },
  
    selectedEmissionSources(selected) {
      // Unchecks Select All if not all emisisons are selected
      if (selected.length != this.labels.length) {
        this.selectAll = false;
        this.selectAllLabel = "Select All"
      }
    },

  
    search(value) {
      // Filters the options to only the ones that match the search string
      if (value.length !== 0) {
        this.options = this.originalOptions.filter((option) => option.label.match(new RegExp(`^${value}.*`, "i")));
      } else {
        this.options = this.originalOptions;
      }
    }
  }
};

export default ToituExportComponent;
</script>

<style lang="less" src="../../assets/styles/reports.less" />
