<template>
  <div v-if="flags.FLAG_4437_FORGOT_PASS" class="auth-container" style="min-height: 55vh">
    <ResetPasswordComponent />
  </div>
</template>

<script>
import ResetPasswordComponent from '@/components/IntroComponents/ResetPasswordComponent.vue';

export default {
  components: {
    ResetPasswordComponent,
  }
}
</script>

<style lang="less" scoped>

</style>