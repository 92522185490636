<template>
	<div class="invoice-upload-container">
		<h2 class="tab-section-heading">Upload Invoice</h2>

		We offer the option to split your invoice line items further into different amounts and allocate them to their own sites
		<a class="split-invoice-guide" @click="displayHelpModal = true"> 
			<q-tooltip style="font-size: 0.9rem;">
				Click here for a guide on the split invoice process.
			</q-tooltip>
			How to use the split invoice feature
			<q-btn 
				flat 
				round 
				dense 
				icon="help" color="primary" class="q-mr-sm"/>
		</a>
		<div>
			<p class="action-heading">Please select the site you would like to upload the invoice data to.</p>
			<div style="display:flex">
				<q-select class="invoice-select-dropdown" :loading="sitesLoading" outlined v-model="siteSelection" :options="sites" :option-label="'name'" />
			</div>
			
			<p>
				You can upload any invoice into the box below. <strong>GreenHalo</strong> will detect the emission source to upload the invoice under! 
			</p>
			
			<span class="action-heading">Upload your invoice below</span>
			<FileUpload
				v-model="files" 
				@submit="uploadFile" 
				@rejected="notify.warning('Each file must be under 5MB, with a maximum of 5 PDFs', 'top', 'Error')" 
				:loading="loading" 
				:disable-submit="!files || !siteId"
				multiple
				max-files="5"
				accept=".pdf" 
				disabled-message="Please select a site and file before uploading."
				max-file-size="5242880"
			/>
		</div>
		<ViewInvoice v-if="showSplitInvoiceComponent" 
			v-model="showSplitInvoiceComponent" 
			:filename="splitInvoiceFileName" 
			:file-id="splitInvoiceFileId" 
			:units="units" 
			:efUnits="efUnits"
			:defaultEfUnits="defaultEfUnits"
			:emissionFactors="emissionFactors"
			:loadingApproval="loadingApproval"
			:loadingFailure="loadingFailure"
			:invoiceStatus="selectedInvoiceStatus"
			@submit-allocations="submitAllocations"
			@approve-invoice="approveInvoice()"
			@fail-invoice="failInvoice()"
		/>
		<InvoiceUploadStatusTable 
			ref="statusTable"
			v-if="invoiceStatusData != null" 
			:loadingUpdate="loadingUpdate"
			:invoiceStatusData="invoiceStatusData"
			@download="getFileDownload"	
			@delete="deleteFile"
			@splitInvoice="splitInvoice"
		/>
		<q-dialog v-model="displayHelpModal">
			<SplitInvoiceHelpComponent/>
		</q-dialog>
	</div>
</template>

<script>
	import api from '@/services/api/api';
	import notify from '@/services/util/notify';
	import { useSiteStateStore } from '@/stores/siteState.store';

	import SplitInvoiceHelpComponent from './SplitInvoiceComponents/SplitInvoiceHelpComponent';
	import FileUpload from "@/components/FileUploadComponent.vue";
	import InvoiceUploadStatusTable from "@/components/ManualUploadComponents/InvoiceUploadStatusTableComponent.vue";
	import ViewInvoice from "./ViewInvoiceComponent.vue";

	import { InvoiceStatus as Status } from "@/enums/fileStatus";

	export default {
		components: {
			SplitInvoiceHelpComponent,
			FileUpload,
			InvoiceUploadStatusTable,
			ViewInvoice,
		},

		props: ["emissionSource"],
		data() {
			return {
				notify: notify,
				displayHelpModal: false,
				//Invoice splitting related data
				shouldSplitInvoice: false,
				showSplitInvoiceComponent: false,
				invoiceData: {},
				splitOptions: {},
				optionsObject: {},
				accrualRange: {},
				splitInvoiceFileId: null,
				splitInvoiceFileName: null,

				siteSelection: null,
				siteId: null,
				files: null,
				loading: false,
				loadingUpdate: false,
				loadingApproval: false,
				loadingFailure: false,
				siteStore: null,
				invoiceStatusData: null,
				invoiceStatusOptions: null,
				sseConnection: null,

				viewInvoice: false,

				units: [],
				efUnits: [],
				defaultEfUnits: {},
				emissionFactors: [],
			}
		},

		async created() {
			this.siteStore = useSiteStateStore();
			this.invoiceStatusOptions = (await api.invoice.getInvoiceStatusOptions()).data;
			this.fetchInvoiceStatusData();
			this.registerToInvoiceEvents();

			this.retrieveUnits();
			this.retrieveDefaultEfUnits();
			this.retrieveEmissionFactorUnits();
			this.retrieveEmissionFactors();
		},

		computed: {
			sites() {
				return this.siteStore.siteList;
			},

			sitesLoading() {
				return !this.siteStore.loaded;
			},

			selectedInvoiceStatus() {
				return this.invoiceStatusData.find((file) => file.fileId === this.splitInvoiceFileId);
			}
		},

		watch: {
			siteSelection() {
				if (this.siteSelection != null) {
					this.siteId = this.siteSelection.id
				}
			},

			// Used to check if the siteStore is loading, if it is loaded set the site selection to the first avalible site
			"siteStore.loaded": function (isLoaded) {
				if (isLoaded) {
					this.siteSelection = this.siteStore.siteList[0];
				}
			}
		},

		beforeUnmount() {
			this.sseConnection?.close();
		},



		methods: {
			async fetchInvoiceStatusData() {
				const invoiceStatusResponse = await api.invoice.getInvoiceStatuses()
				this.invoiceStatusData = invoiceStatusResponse.data
			},
			//Code for receiving SSE events. Very basic was used to test funcitonality but probs is nice to have as a reference 

			registerToInvoiceEvents() {
				this.sseConnection = api.invoice.establishInvoiceEventsConnection();
				this.sseConnection.addEventListener('Success', this.updateTestEvents);
				this.sseConnection.addEventListener('Failure', this.updateTestEvents);
				this.sseConnection.addEventListener('Processing', this.updateTestEvents);
				this.sseConnection.addEventListener('UsageLimit', this.updateTestEvents);
				this.sseConnection.onmessage = (event) => {
					this.testEvents.push(event.data);
				};
			},

			updateTestEvents(event) {
				const eventData = JSON.parse(event.data);
				const currentFileIds = this.invoiceStatusData.map((invoiceStatusObject) => invoiceStatusObject.fileId.toString());
				const currentFileNames = this.invoiceStatusData.map((invoiceStatusObject) => invoiceStatusObject.fileName )
				switch(event.type) {
					case `Processing`:
						if(!currentFileIds.includes(eventData.fileId)){
							if (currentFileNames.includes(eventData.fileName)) {
								this.invoiceStatusData = this.invoiceStatusData.filter((item) => item.fileName != eventData.fileName);
							}

							this.invoiceStatusData.unshift({fileId: eventData.fileId, statusCode: Status.PROCESSING, fileName: eventData.fileName, uploadedBy: eventData.userEmail, uploaded: (new Date()).toISOString()})
						}
						break;
				}

				this.invoiceStatusData.forEach((invoiceStatusObject) => {
					if(invoiceStatusObject.fileId.toString() === eventData.fileId.toString()) {
						invoiceStatusObject.statusCode= eventData.statusCode; 
					}
				})
			},


			/**
			 * @desc Retrieves the available units within GreenHalo
			 */
			async retrieveUnits() {
				let unitResponse = {};
				try {
					unitResponse = await api.units.getUnits();
					this.units = unitResponse.data;
				} catch (error) {
					notify.error("Error retrieving GreenHalo units", "top");
				} 
			},


			/**
			 * @desc Retrieves the default emission factor units:
			 */
			async retrieveDefaultEfUnits() {
				let defaultEfUnitResponse = {};
				try {
					defaultEfUnitResponse = await api.units.getEmissionFactorsWithDefaultUnit();
					this.defaultEfUnits = defaultEfUnitResponse.data;
				} catch (error) {
					notify.error("Error retrieving GreenHalo units", "top");
				}
			},


			/**
			 * @desc Retrieves the available units for each emission factor
			 */
			async retrieveEmissionFactorUnits() {
				let efUnitResponse = {};
				try {
					efUnitResponse = await api.units.getEmissionFactorsWithUnits();
					this.efUnits = efUnitResponse.data;
				} catch (error) {
					notify.error("Error retrieving GreenHalo units", "top");
				} 
			},


			/**
			 * @desc Retrieve emission factors
			 */
			async retrieveEmissionFactors() {
				let emissionFactorResponse = {};
				try {
					emissionFactorResponse = await api.dataTypes.getDataTypes();
					this.emissionFactors = emissionFactorResponse.data;
				} catch (error) {
					notify.error("Error retrieving emission factors", "top")
				}
			},


			splitInvoice(fileId, fileName){
				this.splitInvoiceFileId = fileId;
				this.splitInvoiceFileName = fileName;
				this.showSplitInvoiceComponent=true
			},


			hideSplitInvoiceComponent() {
				this.showSplitInvoiceComponent=false
				this.splitInvoiceFileId = null;
				this.splitInvoiceFileName = null
			},

			/**
 			* Upload the split options and updates the invoice status if necessary.
			*
			* @async
			* @param {object} parsedData - The parsed data to be uploaded.
			* @param {object} optionsObject - The options for the upload.
			* @param {object} accrualRange - The accrual range for the upload.
			* @throws {Error} If an API call within the function fails.
			*/
			async submitAllocations(parsedData, optionsObject, accrualRange) {
				try {
					this.loadingApproval = true;
					this.optionsObject = optionsObject;
					this.accrualRange = accrualRange

					await this.uploadSplitData({parsedData, optionsObject, accrualRange});

					if (this.selectedInvoiceStatus.statusCode === Status.APPROVED) {
						notify.primary(`Successfully uploaded`);
					} else {
						await api.invoice.updateInvoiceStatus(this.splitInvoiceFileId, Status.APPROVED);
						notify.primary(`Successfully approved and uploaded`);
					}
				} catch (error) {
					notify.withObject(error.response);
				} finally {
					this.hideSplitInvoiceComponent();
					this.loadingApproval = false;
					await this.fetchInvoiceStatusData();
				}
			},

			async approveInvoice() {
				this.loadingApproval = true;

				try {
					await api.invoice.updateInvoiceStatus(this.splitInvoiceFileId, Status.APPROVED);
					notify.primary(`Successfully approved the invoice`);
				} catch (error) {
					notify.withObject(error.response);
				} finally {
					this.hideSplitInvoiceComponent();
					this.loadingApproval = false;
					await this.fetchInvoiceStatusData();
				}
			},

			async failInvoice() {
				this.loadingFailure = true;

				try {
					await api.file.deleteFileData(this.splitInvoiceFileId);
					await api.invoice.updateInvoiceStatus(this.splitInvoiceFileId, Status.FAILURE);
					notify.primary("Successfully failed the invoice")
				} catch (error) {
					notify.withObject(error.response);
				} finally {
					this.hideSplitInvoiceComponent();
					this.loadingFailure = false;
					await this.fetchInvoiceStatusData();
				}
			},

			/**
			* @desc Uploads invoice data that has been split between sites
			* @return {Object} - The upload response 
			*/
			async uploadSplitData({parsedData, optionsObject, accrualRange}) {
				try { 

					const payload = {
						parsedData: Object.values(parsedData)[0],
						accrualRange: accrualRange,
						options: optionsObject
					}
					const uploadRes = await api.emissions.uploadEmissionSplitData(
						payload,
						this.splitInvoiceFileId
					);

					const splitFileObjectMatch = (invoiceStatusData) => invoiceStatusData.fileId === this.splitInvoiceFileId.toString();
					const fileObjectIndex = this.invoiceStatusData.findIndex(splitFileObjectMatch)
					this.invoiceStatusData[fileObjectIndex].hasBeenSplit = true;
					return uploadRes
				} catch (error) {
					notify.error("Unable to upload split data. Please try again later.");
				}
			},


			/**
			* @desc Attempt to delete a selected file.
			* 
			* @param {Number} fileId the id of the file to delete
			*/
			deleteFile(fileId) {
				this.loadingUpdate = true;
				api.file
					.deleteFile(fileId)
					.then(() => {
						notify.primary("File successfully deleted", "top");
						this.$refs.statusTable.$refs[`deletePopup${fileId}`][0].hide();
					})
					.catch((error) => {
						notify.error(error.response.data ?? 'Could not establish connection to file storage', 'top');
					}).finally(() => {
						this.fetchInvoiceStatusData();
						this.loadingUpdate = false; 
					});
			},
			/**
			* @desc Attempt to download a select file
			* @param {Strings} fileId id of the file to download
			* @param {Strings} fileName name of the file being downloaded
			*/
			getFileDownload(fileId, fileName) {
				try {
					api.file
						.getSingleFile(fileId, fileName)
						.then(() => {
							notify.primary("File successfully downloaded", "top");
						})
						.catch((error) => {
							notify.error(error.response.data ?? 'Could not establish connection to file storage', 'top');
						});
				} catch {
					notify.error("Error downloading file", "top");
				}
			},
			/**
			* @desc Attempts to upload the invoice, handling split and single invoice accordingly.
			*/
			async uploadFile() {
				this.loading = true;
				try {
					if (!Array.isArray(this.files)) {
						this.files = [this.files];
					}
					await this.uploadInvoices();
					this.files = null;
				} catch (error) {
					if (error.response.status === 400) {
						notify.warning(error.response.data, "top")
					} else {
						notify.error(error.response.data, "top")
					}
				} finally {
					this.loading = false;
				}
			},


			/**
			* @desc Uploads a single invoice file and handles the response.
			* @returns {Object} - The upload response.
			*/
			async uploadInvoices() {

				const uploadRes = await api.invoice.uploadEmissionInvoice(
					this.files,
					this.siteId
				)

				this.files = null
				return uploadRes;
			},
		}

}
</script>

<style lang="less" scoped src="@/assets/styles/invoiceUpload.less" />
