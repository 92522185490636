<template>
  <div class="main-tab-container">
    <h1 class="goals-main-heading">Reports & Audit</h1>
    <div class="content">
      <div class="section-container" v-for="report in reports" :key="report.component">
        <p class="card-desc">{{ report.description }}</p>
        <q-card :ref="`${report.component}-card`" v-ripple class="card-container cursor-pointer q-hoverable" @click="openComponent(report.component)">
          <h2 class="card-heading">{{ report.heading }}</h2>
          <div class="export-container">
            <span class="export-text">Export</span>
            <q-icon class="export-icon" name="ios_share" size="2rem" color="secondary" />
          </div>
        </q-card>
      </div>
    </div>

		<SideModal v-model="openSideModal">
		<!-- Slot content -->
		<component :is="selectedComponent" @close="openSideModal = false" />
		</SideModal>
	</div>
</template>

<script>
import SideModal from "../SideModalComponent.vue";
import ToituExport from "./ToituExportComponent.vue";
import GoalsExport from "./GoalsExportComponent.vue"
import ScopeExport from "./ScopeExportComponent.vue"
import EmissionExport from "./EmissionSnapshotExportComponent.vue"
import EmissionFactorsExport from "./EmissionFactorsExportComponent.vue";
import ScopeExportAccounting from "./ScopeExportAccountingComponent.vue";

export default {
	components: {
		SideModal,
		ToituExport,
		EmissionExport,
		GoalsExport,
		ScopeExport,
		EmissionFactorsExport,
		ScopeExportAccounting,
	},
	data() {
		return {
			showEFMapping: false,
			reports: [
				{
					component: "ToituExport",
					heading: "Toitū emanage Upload File",
					description: "Export a file compatible for uploading into Toitū emanage"
				},
				{
					component: "EmissionExport",
					heading: "Emissions Snapshot",
					description: "Export a detailed sustainability report"
				},
				{
					component: "GoalsExport",
					heading: "Goals Report",
					description: "Export your goals and tracking report"
				},
				{
					component: "ScopeExport",
					heading: "Scope Report - Graphical",
					description: "Export a simplified graphical version of your sustainability report",
				},
			],
			openSideModal: false,
			selectedComponent: "",

			showMapping: false,
		}
	},
	mounted() {
		if (this.flags.FLAG_4822_ACCOUNTING_SCOPE_REPORT) {
			this.reports.push({
				component: "ScopeExportAccounting",
				heading: "Emission Summary - Excel",
				description: "Export an excel sheet of your sustainability report."
			});
		}

		if (this.flags.FLAG_4788_EMISSION_FACTORS_REPORT) {
			this.reports.push({
				component: "EmissionFactorsExport",
				heading: "Emission Factors Report",
				description: "Export your emission factor information",
			});
		}
	},
	methods: {
		/**
		 * @desc Sets the selectedComponent and opens the side dialog.
		 * 
		 * @param {String} component name of the component
		 */
		openComponent(component) {
			this.selectedComponent = component;
			this.openSideModal = true;
		}
	}
}
</script>

<style lang="css" scoped src="../../assets/styles/auditreports.css" />
