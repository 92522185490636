import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import objectSupport from "dayjs/plugin/objectSupport";
import isBetween from 'dayjs/plugin/isBetween';


// Timezone Stuff
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(quarterOfYear);
dayjs.extend(objectSupport);
dayjs.extend(isBetween)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);


export default dayjs;