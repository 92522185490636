<template>

<div class="freight-api-container">

    <q-icon class="close-modal"
	size="2rem"
	name="close" 
	@click="$emit('close')" 
	/>

    <div class="form-container">
        <q-form
        @reset="resetFields"
        @submit="addAPIConnection"
    >
        <h3 class="connect-form-title">
            Add Freight API Connection
        </h3>
        <p class="freight-form-info">
            Through providing the API and subscription key for your freight services, we can automatically pull your freight informatoin and display it on the web platform.
        </p>

        <div class="connect-form-inputs">


            <div class="connect-form-input">
                <q-select 
                    outlined 
                    bg-color="white"
                    v-model="selectedAPI" 
                    :options="options" 
                    maxlength="50" 
                    @keydown.space.prevent
                    label="Freight API" 
                />
            </div>

            <div class="connect-form-input">
                <q-input 
                    outlined
                    bg-color="white"
                    v-model="apiKey"
                    maxlength="50" 
                    @keydown.space.prevent
                    label="API Key"
                />
            </div>

            <div class="connect-form-input">
                <q-input 
                    outlined
                    bg-color="white"
                    v-model="subscriptionKey"
                    maxlength="50" 
                    @keydown.space.prevent
                    label="Subscription Key"
                />
            </div>

        </div>

        <div class="connect-form-buttons">
            <q-btn label="Reset" type="reset" color="primary" no-caps/>
            <q-btn class="submit-new-connection" label="Submit" no-caps :disabled="!selectedAPI || !apiKey || !subscriptionKey" :loading="loading" type="submit" color="secondary"/>
        </div>

        <div class="connect-form-tutorial">
            <h3 class="connect-form-title">
                How to find your API keys
            </h3>
            <p class="connect-form-tutorial-step">To access your API, log into eShip <a href="https://eship.nzpost.co.nz/intro/nzpost.aspx" target="_blank">here.</a></p>
            <div class="freight-info-images">
                <q-img src="../../../../assets/tutorials/freight/eship1.png" width="100%"></q-img>
            </div>
            <p class="connect-form-tutorial-step">Select <strong>Settings</strong> on the bottom left on the screen.</p>
            <p class="connect-form-tutorial-step">On the <strong>Settings</strong> page, select <strong>API</strong>.</p>
            <p class="connect-form-tutorial-step">Your API Key and Subscription Key should be shown. You may need to regenerate if you haven't before.</p>
            <div class="freight-info-images">
                <q-img src="../../../../assets/tutorials/freight/eship2.png" width="100%"></q-img>
            </div>

        </div>
    </q-form>
    </div>
</div>

</template>

<script>

import api from "@/services/api/api";
import notify from '@/services/util/notify';

const FreightAddAPIComponent = {
    name: "FreightAddAPIComponent",

    props: ["siteId"],

    data() {
        return {

            options: ['NZ Post - eShip'],
            apiKey: '',
            subscriptionKey: '',
            selectedAPI: '',

            loading: false,
            loadFailMsg: "",
        };
    },

    created() {

    },

    methods: {

        /**
         * @desc Adds the credentials for an API connection. Will be added to database as 'pending' (needs to be validated)
         */
        addAPIConnection() {

            this.loading = true;

            const postBody =  { platformName: this.selectedAPI, apiKey: this.apiKey, subscriptionKey: this.subscriptionKey}

            api.freight.addFreightConnection(this.selectedAPI, postBody, this.siteId)
                .then(() => {
                    notify.primary("Successfully added API connection", 'top')
                })
                .catch((err) => {
                    this.loadFailMsg = err.response.data;
                    
                    if (typeof this.loadFailMsg === "object") {
                        this.loadFailMsg = this.loadFailMsg.error;
                    }

                    if(this.loadFailMsg.includes(`API connection already exists`)){
                        this.loadFailMsg = `Warning: You have already added this API connection for the selected site.`;
                    }

                    notify.warning(this.loadFailMsg, 'top')
                })
                .finally(() => {
                    this.loading = false;
                    this.selectedAPI = '';
                    this.apiKey = '';
                    this.subscriptionKey = '';
                });
        },


        /**
         * @desc Clears the fields to their empty values
         */
        resetFields() {
            this.selectedAPI = '';
            this.apiKey = '';
            this.subscriptionKey = '';
        }
    }
};

export default FreightAddAPIComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>