<template>
    <div class="container">
      Your payment succesfully went through!
      You can now close this tab :)
    </div>
  </template>
    
  <script>
  import { useSignupStore } from "../stores/signup.store"

  
  // const jwt = require("jsonwebtoken");
  
  const StripeCompleteComponent = {
    name: "StripeComplete",
      
    data() {
      return {
        store: useSignupStore(),

      }
    },
    /**
     * Clear the localStorage
     */
    created() {
        this.store.successHash = this.$route.params.subscriptionHash
    },

    computed: {
      company() {
        return this.store.company
      }
    }

    
  };
  
  export default StripeCompleteComponent;
  </script>
    