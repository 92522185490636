<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <div v-if="store.dataView=='default'">
            <WasteConnections @toggleView="store.changeView('trace')"/>
        </div>
        <div v-else>
            <TraceabilityViewComponent/>
        </div>
        
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import TraceabilityViewComponent from "@/components/DataComponents/TraceabilityComponents/TraceabilityViewComponent.vue";

import { useChartStateStore } from '@/stores/chartState.store';

import WasteConnections from "@/components/DataComponents/ConnectDataSourceComponents/Waste/WasteConnectionsListComponent.vue";


export default {
    name: "waste-connections-view",
    components: { WasteConnections, TraceabilityViewComponent },
    title: 'waste-connections',
    setup() {
        const store = useChartStateStore()
        store.fetchGraphData('waste-connections', 'month')
        store.changeView("default")

        return { store, sidebarWidth }
    }
};
</script>
