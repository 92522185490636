<template>
  <div class="org-leaderboard-container">
		<!-- Summary -->
    <div>
      <h2 class="section-heading">Summary</h2>
      <CardComponent :data="summaryData" :loading="loading" />
    </div>
    
		<!-- Table -->
    <div class="orgs-connected-container">
      <h2 class="section-heading">Organisations Connected</h2>
      <LeaderboardTableComponent @period="(x) => period= x" :data="rowObject"/>
    </div>
  </div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';
import LeaderboardTableComponent from './LeaderboardTableComponent.vue';
import CardComponent from '../CardComponent.vue';

  export default {
    components: {LeaderboardTableComponent, CardComponent},
    
    data() {
      return {
        period: "week",
        rowObject: Object.create(null),
        loading: false
      }
    },
    async created() {
      this.initialiseTable()
    },

    computed: {
      summaryData() {
        let numOrganisations = Object.keys(this.rowObject).length
        let emissionSourcesObject = {}
        Object.values(this.rowObject).map((x) => {
          if(x.data){
            Object.keys(x.data.emissionData).map((y) => {
              if(!emissionSourcesObject[y]){
                emissionSourcesObject[y] = x.data.emissionData[y]
              } else {
                emissionSourcesObject[y] += x.data.emissionData[y]
              }
            })
          }
        })

        let totalEmissions = emissionSourcesObject.total;
        delete emissionSourcesObject.total;

        let worsetEmissionSource = Object.keys(emissionSourcesObject).reduce((a, b) => emissionSourcesObject[a] > emissionSourcesObject[b] ? a : b, 0)
        
        let industryObject = {}
        Object.values(this.rowObject).map((x) => {
          if(x.data){
              if(!industryObject[x.data.company.industry]){
                industryObject[x.data.company.industry] = x.data.emissionData.total
              } else {
                industryObject[x.data.company.industry] += x.data.emissionData.total
              }
          }
        })
        let worstIndustry = Object.keys(industryObject).reduce((a, b) => industryObject[a] > industryObject[b] ? a : b, 0)

        
        let organisationObject = {}
        Object.values(this.rowObject).map((x) => {
          if(x.data){
            organisationObject[x.data.company.name] = x.data.emissionData.total
          }
        })
        let worstOrganisation = Object.keys(organisationObject).reduce((a, b) => organisationObject[a] > organisationObject[b] ? a : b, 0)

      return [
        {
          label: "Number of Organisations",
          value: numOrganisations,
        },
        {
          label: "Worst Emission Source",
          value: worsetEmissionSource,
        },
        {
          label: "Worst Emitting Industry",
          value: worstIndustry,
        },
        {
          label: "Worst Emitting Organisation",
          value: worstOrganisation,
        },
        {
          label: "Total Emissions",
          value: (totalEmissions??0).toFixed(2),
        }
      ];
    }
  },
		watch: {
      period(){
        this.getOrganisationData()
      }
		},

    methods: {
			

      async initialiseTable(){
        try {
            let connectedOrganisations = await api.tenantConnections.getAllConnectedOrganisations();
            connectedOrganisations.map((x) => {

                this.rowObject[x.sourceTenantId] = x
            })
            this.getOrganisationData()
        } catch (error) {
            notify.withObject(error.response);
        }

        
       },
       async getOrganisationData() {
        this.loading=true
        await Promise.all(Object.keys(this.rowObject).map(async (x) => {
            delete this.rowObject[x]["data"] 
            try{
            this.rowObject[x]["data"] = await api.tenantConnections.getOrganisationData(this.rowObject[x]["sourceTenantId"], this.period);
            }catch(err){
              notify.withObject(err.response);
            }
            return

        })
        )
        this.loading=false
       }
    }
  }
</script>

<style lang="less" src="@/assets/styles/organisationLeaderboard.less" />