<template>
	<div class="container">

        <q-icon class="close-modal"
            size="2rem"
            name="close" 
            @click="$emit('close')" 
            />

		<h5 class="tab-section-heading text-center">Add ICP Site Link</h5>
		<div>
			<q-form class="form" @submit.prevent="addICPNumberLink()">
				<div class="inputs">
                    <q-select 
                        class="q-mb-lg"
                        outlined 
                        bg-color="white"
                        v-model="selectedSite"
                        option-label="name" 
                        option-value="id"
                        :options="siteList" 
                        label="Site" 
                        />
					<q-input
                        maxlength="15"
						outlined
						bg-color="white"
						label="ICP Number"
                        v-model="icp"
						:rules="[
							(val) =>
								!!val ||
								'ICP is required',
						]"
					>

					</q-input>
				</div>

				<q-btn
					class="button"
					:disable="submitLoading"
					:loading="submitLoading"
					square
					no-caps
					padding="8px 24px"
					color="secondary"
					label="Create"
					type="submit"
				/>
			</q-form>
		</div>
	</div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import api from "@/services/api/api";
import notify from "@/services/util/notify";

const AddICPNumberComponent = {
	name: "ICPNumbers",
    props: ["emission"],
	emits: ["close"],

	data() {
		return {
            selectedSite: null,
            icp: '',
		};
	},


    computed: {
        siteList() {
            return useSiteStateStore().siteList
        }
    },


	methods: {

        /**
         * @desc Adds ICP link
         */
        addICPNumberLink() {
            let payload = {
                icp: this.icp,
                siteId: this.selectedSite.id
            }

            api.icp.addICPNumber(this.emission, payload).then((res) => {
                notify.withObject(res, "top");
                this.$emit("refreshTable")
            }).catch((err) => {
                notify.withObject(err.response, "top");
            }).finally(() => {
                this.$emit("close");
            })
        }

	},
};

export default AddICPNumberComponent;
</script>

<style lang="less" scoped />