<template>
    <div class="file-history-table">

      <q-table class="q-table-file-history" :rows="data" :columns="columns" row-key="name" binary-state-sort
        :virtual-scroll-sticky-size-start="48" :rows-per-page-options="[15]" :filter="filter"
        :no-data-label="userRole == 'demo' ? 'No files available on demo account': 'No files saved'"
        :loading="fileTraceLoading"
        >

        <template v-slot:top-left>
          <q-input outlined borderless dense debounce="300" v-model="filter" placeholder="Search" clearable>
            <template v-slot:prepend>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>


        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            
            <div :class="['file-history-' + props.value ,'action-badge']"> 
              <q-badge align="middle">
                <div class="text-center full-width"> {{ props.value }} </div>
              </q-badge>
            </div>
          </q-td>
        </template>

        <template v-if="selectedFile.fileName" v-slot:top-right> 
            <div class="selected-file-container">
                <q-badge>
                    <div class="selected-file-title">
                        <text>{{ selectedFile.fileName }}</text>
                    </div>
                    <q-icon name="close" size="2em" @click="$emit('clearSelection')"/>

                </q-badge>

            </div>

        </template>
      </q-table>
    </div>
</template>

<script>

import getRole from "@/services/util/role";
import api from "@/services/api/api"
import notify from '@/services/util/notify';
import convertEmissionURL from "@/services/util/convertEmissionURL";

const FileHistoryTableComponent = {
  name: "FileHistoryTable",
  props: ["type", "custom", "selectedFile"],


  data() {
    return {
      userRole: getRole(),
      file: null,
      filter: "",
      ascending: false,
      sortColumn: "",

      fileTraceLoading: false,

      columns: [
        {
          name: "fileName",
          style: "width: 25%",
          label: "File Name",
          align: "left",
          field: "fileName",
          sortable: true,
          required: true,
        },
        {
          name: "action",
          style: "width: 25%",
          label: "Action",
          align: "middle",
          field: "action",
          sortable: true,
        },
        {
          name: "updated",
          style: "width: 20%",
          label: "Date",
          align: "left",
          field: "updated",
          sortable: true,
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false
          }),
        },
        {
          name: "modifiedBy",
          style: "width: 20%",
          label: "User",
          align: "left",
          field: "modifiedBy",
          sortable: true,
        },
        {
          name: "description",
          style: "width: 10%",
          label: "Comments",
          align: "left",
          field: "description",
        },
      ],

    data: [],
    };
  },
  created() {
    this.getInitialFileTrace()
  },
 
  watch: {
    selectedFile: {
      handler: function() {
        if(this.selectedFile.fileId){
            this.getFileTraceById()
        } else {
            this.getInitialFileTrace()
        }
      },
      deep: true
    },
  },
  methods: {
    /**
     * @desc Retrieve all file trace information for the given emission source.
     */
    getInitialFileTrace() {
      this.fileTraceLoading = true;

      
      
      if (this.custom) {

        // Get files expect the emission source to be capitalised (for table name purposes), we cannot provide the emission source in its URL version.        
        var emissionSourceTableName = convertEmissionURL(this.type)

        api.file
        .getLatestFileTraceByCustomEmissionSource(emissionSourceTableName)
        .then((res) => {
            this.data = res.data
        })
        .catch(() => {
            notify.error("Error getting file history file", "top");
        }).finally(() => {
          this.fileTraceLoading = false;
        })
      } else {
        api.file
        .getLatestFileTraceByEmissionSource(this.type)
        .then((res) => {
            this.data = res.data
        })
        .catch((err) => {
            notify.error(err.response.data ?? 'Could not establish connection to file storage', 'top');
        }).finally(() => {
          this.fileTraceLoading = false;
        })
      }
    },

    getFileTraceById() {
        api.file
        .getFileTraceById(this.selectedFile.fileId)
        .then((res) => {
            this.data = res.data
        })
        .catch((err) => {
            notify.error(err.response.data ?? 'Could not establish connection to file storage', 'top');
        })
    }
  }
}

export default FileHistoryTableComponent;
</script>

<style scoped lang="less">
@import "../../../assets/styles/audit.less";
</style>