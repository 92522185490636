/**
 * file.js
 *
 * API for handling creating, and getting files
 */
import axiosInstance from './axios-instance'



/**
 * @desc Attempts to invite new user to tenancy
 * 
 * @param {Object} payload contains email and role type that the invited user will have
 * @returns {Message of success or failure}
 */
const getUserRoles = async function () {
    return await axiosInstance.get("/app/users/roles").then(res => res);
};


/**
 * @desc Attempts to invite new user to tenancy
 * 
 * @param {Object} payload contains email and role type that the invited user will have
 * @returns {Message of success or failure}
 */
const inviteUser = async function (payload) {
    return await axiosInstance.post("/api/users/invite", payload).then(res => res);
};


/**
 * @desc Attempt to signup a user with username and password.
 * 
 * @param {Object} payload contains credentials to sign up with
 * @returns {Promise<AxiosResponse<any>>}
 */
const signupWithCredentials = async function (payload) {
    return await axiosInstance.post("/api/users/signup/credentials", payload).then(res => res);
};

/**
 * @desc Attempt to signup a user using SSO.
 * 
 * @param {Object} payload contains credentials to sign up with
 * @returns 
 */
const signupWithSSO = async function (payload) {
    return await axiosInstance.post("/api/users/signup/sso",
        payload,
        {
            headers: 
            {
                Authorization: payload.authorization
            }
        }).then(res => res);
};

/**
 * @desc Attempt to login a user with username and password
 * 
 * @param {Object} payload contains credentials to log in with
 * @returns 
 */
const loginUserWithCredentials = async function (payload) {
    return await axiosInstance.post("/api/users/login/credentials", payload).then(res => res);
};

/**
 * @desc Attempt to login a user using SSO sign in
 * 
 * @param {Object} payload.authorization a jwt token generated from a Microsoft login
 * @returns 
 */
const loginUserWithSSO = async function (payload) {
    return await axiosInstance.post("/app/users/login/sso",
        {},
        {
            headers:
            {
                Authorization: payload.Authorization
            }
        }).then(res => res);
}

/**
 * @desc Attempts to login a user with a demo account
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const loginUserDemo = async function () {
    return await axiosInstance.post(`/app/users/login/demo`).then(res => res);
};


/**
 * @desc Gets all users and their roles  
 * 
 * @returns {Promise<AxiosResponse<any>>}
*/
const getUsers = async function () {
    return await axiosInstance.get(`/api/users`).then(res => res);
};


/**
 * @desc Removes a user from the tenancy
 * 
 * @param {Number} id The id of the user to remove
 * @returns {Promise<AxiosResponse<any>>} 
 */
const removeUser = async function (id) {
    return await axiosInstance.delete(`/api/users/${id}`).then(res => res)
}

/**
 * @desc Updates a users role
 * 
 * @param {Number} id The id of the user to update
 * @param {String} role The role to update the user to
 * @returns {Promise<AxiosResponse<any>>} 
 */
const updateUserRole = async function (id, role) {
    return await axiosInstance.patch(`/api/users/${id}/role`, {role}).then(res => res);
}



/**
 * @desc Attempt to retrieve a users information
 * 
 * @param {Number} id user id 
 * @returns 
 */
const getUserProfileInfo = async function (id) {
    return await axiosInstance.get(`/api/users/${id}`).then(res => res);
};


/**
 * @desc Attempts to add a new BP customer number to the currently logged in tenant
 * 
 * @param {Object} payload contains bp customer number
 * @returns
 */
const addBPAccountNumber = async function (payload) {
    return await axiosInstance.post('/api/users/customer-number/bp', payload).then(res => res);
}


/**
 * @desc Attempts to add a new Z customer number to the currently logged in tenant
 * 
 * @param {Object} payload contains Z customer number
 * @returns
 */
const addZAccountNumber = async function (payload) {
    return await axiosInstance.post('/api/users/customer-number/z', payload).then(res => res);
}


/**
 * @desc Attempts to add a new EIEP information to the currently logged in tenant
 * 
 * @param {Object} payload contains EIEP information
 * @returns
 */
const addEIEPInformation = async function (payload) {
    return await axiosInstance.post('/app/electricity-information-exchange/13c/info', payload).then(res => res);
}


/**
 * @desc Attempts to retrieve a users settings
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUserSettings = async function () {
    return await axiosInstance.get(`/api/users/settings`).then(res => res);
};


/**
 * @desc Attempts to update a users settings
 * 
 * @param {Object} payload contains new user settings
 * @returns {Promise<AxiosResponse<any>>}
 */
const addUserSettings = async function (payload) {
    return await axiosInstance.patch(`/api/users/settings`, payload).then(res => res);
};


/**
 * @desc Attempts to verifys JWT token
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const verifyJWTToken = async function () {
    return await axiosInstance.get(`/api/users/verifyToken`).then(res => res);
};

/**
 * @desc Gets all the subscription plans
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSubscriptionInfo = async function () {
    return await axiosInstance.get("/api/subscriptions").then(res => res);
}

/**
 * @desc Retrieves info on tenant's current subscription tier.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCurrentSubscription = async function () {
    return await axiosInstance.get("/api/users/subscription").then(res => res);
}

/**
 * @desc Patches tenant subscription plan
 * 
 * @param {*} payload.subscriptionId the ID of the subscription to switch to.
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchSubscriptions = async function (payload) {
    return await axiosInstance.patch("/api/subscriptions", payload).then(res => res);
}

/**
 * @desc Cancels a tenants subscription plan
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteSubscription = async function () {
    return await axiosInstance.delete("/api/subscriptions").then(res => res);
}

/**
 * @desc Given an email, check if the user exists in the public table.
 */
const checkUserExistsWithEmail = async function (email) {
    return await axiosInstance.get(`/api/users/${email}/exists`).then(res => res);
}

/**
 * @desc Send a password reset link to the email.
 * 
 * @param {String} email 
 * @returns 
 */
const forgotPassword = async function (email) {
    return await axiosInstance.post("/api/users/forgot-password", {email}).then(res => res);
};

/**
 * @desc Checks if the token/password reset link is valid.
 * 
 * @param {String} token 
 * @returns 
 */
const getPasswordReset = async function (token) {
    return await axiosInstance.get(`/api/users/reset-password/${token}`).then(res => res);
};

/**
 * @desc Resets the user's password
 * 
 * @param {String} token
 * @param {String} newPassword 
 * @returns 
 */
const resetPassword = async function (token, newPassword) {
    return await axiosInstance.put(`/api/users/reset-password/${token}`, {newPassword}).then(res => res);
};

const loginSupplier = async function (email, password) {
    return await axiosInstance.post("/api/users/login/supplier", { email, password }).then(res => res);
}

const addSupplier = async function (email, supplierId) {
    return await axiosInstance.post("/api/users/supplier", { email, supplierId }).then(res => res);
}

const getConnectedSuppliers = async function () {
    return await axiosInstance.get("/api/users/suppliers").then(res => res);
}

const getSupplierTenantToken = async function (tenantHash) {
    return await axiosInstance(`/api/users/supplier/tenant-token/${tenantHash}`).then(res => res);
}

/**
 * @desc Removes a supplier user
 * 
 * @param {Number} supplierId the id of the supplier to remove
 * @returns Success or Error message given response from server
 */
const removeConnectedSupplier = async function (supplierId) {
    return await axiosInstance.delete(`/api/users/supplier/${supplierId}`).then(res => res);
}


export default {
    inviteUser,
    getUserRoles,
    loginUserWithCredentials,
    loginUserWithSSO,
    getUserProfileInfo,
    signupWithCredentials,
    signupWithSSO,

    addBPAccountNumber,
    addZAccountNumber,
    addEIEPInformation,

    getUserSettings,
    addUserSettings,
    verifyJWTToken,

    loginUserDemo,

    getUsers,
    removeUser,
    updateUserRole,

    getSubscriptionInfo,
    getCurrentSubscription,
    patchSubscriptions,
    deleteSubscription,

    checkUserExistsWithEmail,
    forgotPassword,
    getPasswordReset,
    resetPassword,
    loginSupplier,
    addSupplier,


    getConnectedSuppliers,
    getSupplierTenantToken,
    removeConnectedSupplier,
}