<template>
  <div class="create-goal-container">
    <h2 class="create-goals-header">Add New Goal</h2>
    <div class="create-goal-form-container">
      <q-form @submit.prevent="submitNewGoal()">
        <!-- emissionSource dropdown -->
        <div v-if="!emission" class="form-input">
          <q-select
            outlined
            v-model="emissionSource"
            label="Select emission source"
            :options="emissionSourceOptions"
            option-value="tableName"
            option-label="name"
            map-options
            emit-value
            :rules="[
              (val) =>
                !!val || 'Please select an emission source to set a goal for',
            ]"
          />
        </div>
        <!-- name question -->
        <div class="form-input">
          <div class="new-goal-sub-heading">Goal Name</div>
          <q-input
            outlined
            v-model="name"
            required
            maxlength="75"
          />
        </div>
        <!-- deadline dropdown -->
        <div class="form-input">
          <div class="new-goal-sub-heading">Metric</div>

          <q-select
            outlined
            v-model="timeframe"
            label="Select timeframe"
            :options="this.timeframeOptions"
            :rules="[
              (val) => !!val || 'Please select a timeframe to set a goal for',
            ]"
          />
        </div>

        <!-- targetValue question -->
        <div class="form-input">
          <div class="new-goal-sub-heading">Desired Outcome</div>
          <q-radio
            v-model="outcomeType"
            val="redBy"
            label="Reduce average daily usage by %"
          />
          <q-radio
            v-model="outcomeType"
            val="redTo"
            label="Reduce average daily usage to"
          />
        </div>
        <!-- targetValue question -->
        <div class="form-input">
          <div v-if="!historicalUsageLoading" class="new-goal-sub-heading">
            Past {{ timeframe }} total usage:
            {{ currentTimeframeHistoricalUsage.total.toFixed(1) }}
          </div>
          <div v-if="!historicalUsageLoading" class="new-goal-sub-heading">
            Past {{ timeframe }} average daily usage:
            {{ currentTimeframeHistoricalUsage.daily.toFixed(1) }}
          </div>

          <span class="target-amount-span">Goal Amount</span>

          <q-input
            outlined
            type="number"
            v-model="targetValue"
            label="Goal value"
            required
          />
        </div>
        <!-- description question -->
        <div class="form-input">
          <q-input
            outlined
            type="textarea"
            v-model="description"
            label="Goal description (optional)"
            maxlength="200"
          />
        </div>
        <!-- submit button -->
        <div class="form-input">
          <q-btn
            class="submit-new-goal"
            style="padding: 10px; width: 150px"
            no-caps
            :loading="loading"
            :disabled="!emissionSource || !name || !timeframe || !targetValue"
            label="Submit"
            type="submit"
            color="secondary"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import "chartjs-adapter-date-fns";
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { useSiteStateStore } from "@/stores/siteState.store";

const CreateGoalComponent = {
  name: "CreateGoal",
  props: ["emission", "emissionSourceOptions"],
  components: {},

  data() {
    return {
      // Loading vars
      loading: false,
      historicalUsageLoading: false,
      // Form inputs
      emissionSource: this.emission?.tableName,
      name: undefined,
      deadline: undefined,
      timeframe: "Month",
      targetValue: undefined,
      description: "",
      goalType: "totalUsage",
      outcomeType: "redBy",

      timeframeOptions: ["Month", "Quarter", "Year"],
      loadingEmissionSourceUsages: false,
      emissionSourceUsages: {
        month: {
          total: 0,
          daily: 0,
        },
        quarter: {
          total: 0,
          daily: 0,
        },
        year: {
          total: 0,
          daily: 0,
        },
      },
      currentTimeframeHistoricalUsage: {
        total: 0,
        daily: 0,
      },
    };
  },

  created() {
    if(this.emissionSource){
      this.getEmissionSourceUsages();
    } 
  },

  watch: {

    /**
     * @desc When emission source value changes, get usage data for given emission source.
     */
    emissionSource() {
      this.getEmissionSourceUsages();
    },


    /**
     * @desc When selected timeframe changes, get emission source usage for given timeframe.
     */
    timeframe() {
      switch (this.timeframe) {
        case "Month":
          this.currentTimeframeHistoricalUsage =
            this.emissionSourceUsages.month;
          break;
        case "Quarter":
          this.currentTimeframeHistoricalUsage =
            this.emissionSourceUsages.quarter;
          break;
        case "Year":
          this.currentTimeframeHistoricalUsage = this.emissionSourceUsages.year;
          break;
      }
    },
  },

  computed: {
    siteId() {
			return useSiteStateStore().siteId
		}
  },

  methods: {
    /**
     * @desc Submits a new goal to the server based on the form data.
     */
    async submitNewGoal() {
      // Checks that the goal being created has a historical usage greater than or equal to 1.
      if(this.currentTimeframeHistoricalUsage.daily < 1){
        notify.error("Cannot create a goal with historical usage of 0", "top");
        return;
      }

      this.loading = true;
      try {
        this.deadline = this.calculateDeadline();
      } catch (err) {
        console.error(err);
        this.loading = false;
        return;
      }
      let newGoal = {
        emissionSource: this.emissionSource,
        name: this.name,
        deadline: this.deadline,
        targetValue: this.targetValue,
        description: this.description,
        goalType: this.goalType,
        outcomeType: this.outcomeType == "redBy" ? "reduceBy" : "reduceTo",
        siteId: this.siteId,
      };
      await api.goals
        .postGoal(newGoal)
        .then((res) => {
          notify.primary(res.data, "top")
          this.$emit("reload");
          this.$emit("close");
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },


    /**
     * @desc Calculates the correct deadline based on current date and selected timeframe.
     */
    calculateDeadline() {
      let deadline = new Date();
      switch (this.timeframe) {
        case "Month":
          deadline.setMonth(deadline.getMonth() + 1);
          break;
        case "Quarter":
          deadline.setMonth(deadline.getMonth() + 3);
          break;
        case "Year":
          deadline.setMonth(deadline.getMonth() + 12);
          break;
        default:
          throw `Couldn't calculate deadline with timeframe: ${this.timeframe}`;
      }
      return deadline;
    },


    /**
     * @desc Gets the emission source past timeframe usages.
     */
    async getEmissionSourceUsages() {
      this.historicalUsageLoading = true;
      const fetchData = async (emissionSource) => {
        return Promise.all([
          api.data.getPastUsage(emissionSource, 30, this.siteId),
          api.data.getPastUsage(emissionSource, 91, this.siteId),
          api.data.getPastUsage(emissionSource, 365, this.siteId),
        ]);
      };

      fetchData(this.emissionSource)
        .then((values) => {
          this.emissionSourceUsages.month = values[0].data;
          this.emissionSourceUsages.quarter = values[1].data;
          this.emissionSourceUsages.year = values[2].data;

          switch (this.timeframe) {
            case "Month":
              this.currentTimeframeHistoricalUsage =
                this.emissionSourceUsages.month;
              break;
            case "Quarter":
              this.currentTimeframeHistoricalUsage =
                this.emissionSourceUsages.quarter;
              break;
            case "Year":
              this.currentTimeframeHistoricalUsage =
                this.emissionSourceUsages.year;
              break;
          }
        })
        .catch((error) => {
          notify.withObject(error.response);
        })
        .finally(() => (this.historicalUsageLoading = false));
    },
  },
};

export default CreateGoalComponent;
</script>

<style lang="less" src="../../assets/styles/goals.less" scoped />