<template>
	<div class="tutorial-container">
      <div class="tutorial-header">Connect BP Fuel Card</div>
      <div class="tutorial-description">
        This guide details how to set up a BP Fuel Card recurring report to be
        sent to our GreenHalo inbox. From here the reports will be scraped for
        transport fuel usage data which will be added to your account
        automatically.
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          1
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/login.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Navigate to the
          <a
            class="outgoing-link"
            href="https://www.wex.bpfuelcard.co.nz/IFCSWeb-bp/faces/secure/home/homepage.xhtml"
            target="_blank"
            >BP login portal.
            <q-icon
              name="login"
              style="text-decoration: underline; text-underline-offset: -1px"
          /></a>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          2
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/navigate_to_manage_reports_page.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>Navigate to the <b>Manage Recurring Reports</b> page</div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          3
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/manage_reports_page.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>Press the <b>Create a new Recurring Report</b> button</div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          4
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_type.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>Under Report Type, select <b>Transcations</b></div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          5
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_detail.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          Under Report Detail, select
          <b>Export Transaction File - with Headers</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          6
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_account.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          Under <b>Account</b>, select which BP Account you'd like to generate
          the report for
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          7
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_schedule.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          Under <b>Report Scheduling</b>, select the
          <b>Schedule this as a new Recurring Report</b> option
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          8
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_email_options.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          Under <b>Delivery email</b> options, ensure that the
          <b>Email full report as attachment</b> option is selected
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          9
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_delivery_frequency.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          Under the <b>Report Frequency</b>, select the <b>Day option</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          10
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/report_email_delivery_address.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>
          In the <b>Report Delivery</b> options, select
          <b>Send to this email</b>, and enter
          <b>reports@greenhalo.nz</b>
          <q-icon name="help" color="grey" size="0.9rem">
            <q-tooltip style="font-size: 0.75rem">
              This allows us to automatically scrape the transport fuel usage
              from the selected BP Account, and add it to your GreenHalo carbon
              emissions tracking.
            </q-tooltip>
          </q-icon>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          11
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/bp/run_report_button.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div>Finally, press the <b>Run Report</b> button</div>
      </div>
	</div>
</template>
<script>

export default {
  name: "BPTutorial"
}

</script>
<style scoped src="../../../assets/styles/dataSourceTutorial.css"></style>