<template>

<!-- Past year usage insight -->


<q-card v-if="!insight" class="data-insights-card">
    <q-card-section>
        <div class="text-h5 data-insights-text">
            <q-skeleton type="text" />
        </div>
    </q-card-section>
    <q-card-section>
        <div class="text-h5 data-insights-text">
            <q-skeleton type="text" />
        </div>
    </q-card-section>
    <q-card-section>
        <div class="text-h7">
            <q-skeleton type="text" />
        </div>
    </q-card-section>
</q-card>

<q-card v-else class="data-insights-card">
    <q-tooltip class="text-body2" v-if="insight.tooltip != undefined" icon="help">{{ insight.tooltip }}</q-tooltip>

    <q-card-section class="header-wrapper">
        <div class="text-h5">
            {{insight.header}}
        </div>
    </q-card-section>
    <q-card-section>
        <div class="text-h5 data-insights-text">
            <q-icon
                size="lg"
                :name="icon"
                color="primary"
            />
            <span v-if="insight.units=='$'"> ${{isNaN(Number.parseInt(insight.value, 10)) ?  insight.value : insight.value.toLocaleString(undefined, {maximumFractionDigits: 2})  }}</span>

            <span v-else>{{ isNaN(Number.parseInt(insight.value, 10))  ?  insight.value : insight.value.toLocaleString(undefined, {maximumFractionDigits: 2}) + " " + insight.units }}</span>
        </div>
    </q-card-section>
    <q-card-section>
        <div class="text-h7 data-insights-small-text">
            <span style="display: inline-block;">{{ insight.time }}</span>
        </div>
    </q-card-section>
</q-card>


</template>


<script>

const InsightCardComponent = {
	name: "InsightsCard",
    props: ["insight", "icon"],
};

export default InsightCardComponent;
</script>

<style scoped>
@import "../../../assets/styles/data.less";
</style>

