/**
 * sites.js
 *
 * API for handling creating, and getting files
 */
import axiosInstance from './axios-instance'

 
/**
 * @desc Retrieves site category and related data by ID.
 * 
 * @param {*} id ID of the site category to retrieve
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSiteCategory = async function(id) {
    return await axiosInstance.get(`/api/sites/category/${id}`).then(res => res);
};
 
/**
 * @desc Retrieves a list of all site categories
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSiteCategories = async function() {
    return await axiosInstance.get(`/api/sites/category`).then(res => res);
};
 
/**
 * @desc Gets site information by ID
 * 
 * @param {*} id ID of site to retrieve information for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSite = async function(id) {
    return await axiosInstance.get(`/api/sites/${id}`).then(res => res);
};
 
/**
 * @desc Gets a list of all sites
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSites = async function() {
    return await axiosInstance.get(`/api/sites`).then(res => res);
};
 
/**
 * @desc Posts a new site to tenancy
 * 
 * @param {*} payload site data to post
 * @returns {Promise<AxiosResponse<any>>}
 */
const postSite = async function(payload) {
    return await axiosInstance.post(`/api/sites`, payload).then(res => res);
};
 
/**
 * @desc Updates site information
 * 
 * @param {*} id ID of site to patch
 * @param {*} payload data to patch site with
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchSite = async function(id, payload) {
    return await axiosInstance.patch(`/api/sites/${id}`, payload).then(res => res);
};
 
/**
 * @desc Deletes a site by ID.
 * 
 * @param {*} id ID of the site to delete
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteSite = async function(id) {
    return await axiosInstance.delete(`/api/sites/${id}`).then(res => res);
};
 
/**
 * @desc Retrieves all emission sources for a site
 * 
 * @param {Number} [siteId] ID of the site to get the emission source of
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSiteEmissionSources = async function(siteId) {
    return await axiosInstance.get(`/api/sites/emission-sources`, { params: { siteId }}).then(res => res);
};
 
/**
 * @desc Adds emission source to a site's emissions list.
 * 
 * @param {*} id ID of the site to add the emission sources to
 * @param {*} payload The data of the emission sources payload to add
 * @returns {Promise<AxiosResponse<any>>}
 */
const addSiteEmissionSources = async function(id, payload) {
    return await axiosInstance.post(`/api/sites/${id}/emission-sources`, payload).then(res => res);
};
 
/**
 * @desc Edits a site's emission sources lis
 * 
 * @param {*} id ID of the site to edit the emission sources to
 * @param {*} payload The data of the emission source payload to edit
 * @returns {Promise<AxiosResponse<any>>}
 */
const patchSiteEmissionSources = async function(id, payload) {
    return await axiosInstance.patch(`/api/sites/${id}/emission-sources`, payload).then(res => res);
};


/**
 * @desc Retrieves total emissions per emission source for a given site.
 * 
 * @param {*} id ID of the site to get the emission source of
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSiteEmissions = async function(id, granularity) {
    return await axiosInstance.get(`/api/sites/${id}/emissions`, {
        params: {
            granularity: granularity,
        }
    }).then(res => res);
};


/**
 * @desc Get Addresses from photon.komoot.io
 * 
 * @param {String} Address The partically filled address to autofill
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAutoCompleteAddresses = async function(address) {
    return await axiosInstance.get(`https://photon.komoot.io/api/`, {params: {q: address, limit: 5}} ).then(
        res => {
            const addresses = [];
            for (const feature in res.data.features) {
                const address = res.data.features[feature].properties;

                if (address.housenumber && address.street && address.district && address.city && address.postcode && address.country) {
                    addresses.push(`${address.housenumber} ${address.street}, ${address.district}, ${address.city}, ${address.postcode}, ${address.country}`); 
                }  
            }
            return addresses;
        })
}

/**
 * @desc Gets a single address from photon.komoot.io
 * 
 * @param {String} address The formatted address 
 * @returns {Promise<AxiosResponse<any>>} 
 */
const getAddressDetails = async function(address) {
    return await axiosInstance.get(`https://photon.komoot.io/api/`, {params: {q: address}} ).then(res => res)
}

export default {
    getSiteCategory,
    getSiteCategories,
    getSite,
    getSites,
    postSite,
    patchSite,
    deleteSite,

    getSiteEmissionSources,
    addSiteEmissionSources,
    patchSiteEmissionSources,
    getSiteEmissions,
    getAutoCompleteAddresses,
    getAddressDetails
}