/**
 * freight.js
 * 
 * API for handling getting all carbon associated data
 */

import axiosInstance from "./axios-instance";



/**
 * @desc Gets past year total number of freight shipments
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsightsTotal = async function(siteId) {
    return await axiosInstance.get(`/app/freight/insights/total`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year total number of kilometers for freight shipments
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsightsDistance = async function(siteId) {
    return await axiosInstance.get(`/app/freight/insights/distance`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year total number of kilometers travelled for freight shipments
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsightsEmissions = async function(siteId) {
    return await axiosInstance.get(`/app/freight/insights/emissions`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year most common freight shipment reciever
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getInsightsCommonReceiver = async function(siteId) {
    return await axiosInstance.get(`/app/freight/insights/common-receiver`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Attempt to update the orders for a given api connection.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateOrders = async function() {
    return axiosInstance.put(`/app/freight/eship-orders`).then(res => res);
}


/**
 * @desc Attempt to add a new GVI freight connection
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const addFreightConnection = async function(supplier, payload, siteId) {
    return axiosInstance.post(`/api/freight/connections/${supplier}`, payload, { params: { siteId }}).then(res => res);
}


/**
 * @desc Retrieve all GVI connections for a tenant
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFreightConnections = async function(supplier, siteId) {
    return axiosInstance.get(`/api/freight/connections/${supplier}`, { params: { siteId }}).then(res => res);
}


/**
 * @desc Attempt to delete a freight GVI connections
 * 
 * @param {Number} GVI id of freight connection to delete
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteFreightConnection = async function(supplier, id) {
    return axiosInstance.delete(`/api/freight/connections/${supplier}/${id}`).then(res => res);
}


export default {
    getInsightsTotal,
    getInsightsDistance,
    getInsightsEmissions,
    getInsightsCommonReceiver,
    updateOrders,
    addFreightConnection,
    getFreightConnections,
    deleteFreightConnection
};