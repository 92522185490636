/**
 * water.js
 * 
 * API for handling getting all water associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves water chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWaterChartData = async function (granularity, siteId) {
    return await axiosInstance.get(`/app/water/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Generate water CSV file
 * 
 * @param {Number} siteId contains query for filtering 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWaterCSV = async function(siteId) {
    return axiosInstance({
        url: `/app/water/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `water-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}



/**
 * @desc Get the total water usage in the past year.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearUsageInsight = async function(siteId) {
    return await axiosInstance.get(`/app/water/insights/year/usage`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Retrieve total water usage in past year converted to equivalent barrels.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearBarrels = async function (siteId) {
    return await axiosInstance.get(`/app/water/insights/year/barrels`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Get total water usage converted to emissions in the past year.
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearEmissions = async function(siteId) {
    return await axiosInstance.get(`/app/water/insights/year/emissions`, { params: { siteId } }).then(res => res)
}


/**
 * @desc Get total cost spent on water in past year
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearTotalCost = async function(siteId) {
    return await axiosInstance.get(`/app/water/insights/year/cost`, { params: { siteId } }).then(res => res)
}

/**
 * @desc Submit a manual CSV upload for water data
 * 
 * @param {File} file CSV file to upload
 * @returns {Promise<AxiosResponse<any>>}
 */
const submitManualCSVUpload = async function (file) {
    let formData = new FormData();
    formData.append('file', file);
    let headers = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return await axiosInstance.post(`/app/water/manual/csv`, formData, headers).then(res => res)
}

/**
 * @desc Add a new waster invoice customer number
 * 
 * @param {Object} payload contains water customer number
 * @returns {Promise<AxiosResponse<any>>}
 */
const addWaterCustomerNumber = async function (payload) {
    return await axiosInstance.post(`/app/water/add-customer-number`, payload).then(res => res)
}


/**
 * @desc Retrieve all water customer numbers from database
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWaterCustomerNumber = async function () {
    return await axiosInstance.get(`/app/water/customer-number`).then(res => res)
}


export default {
    getWaterChartData,
    getWaterCSV,

    getPastYearUsageInsight,
    getPastYearBarrels,
    getPastYearEmissions,
    getPastYearTotalCost,

    submitManualCSVUpload,
    addWaterCustomerNumber,
    getWaterCustomerNumber
}