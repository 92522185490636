/**
 * gas.js
 * 
 * API for handling getting all gas associated data
 */

import axiosInstance from "./axios-instance";


/**
 * @desc Gets gas data in CSV form. Once fetched, does browser stuff for the download
 * 
 * @param {Number} siteId site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getGasCSVExport = async function(siteId) {
    return axiosInstance({
        url: `/app/gas/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `gas_data.csv`); 
        document.body.appendChild(link);
        link.click();
    })
}

/**
 * @desc Gets the natural gas distribution loss chart data.
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getNaturalGasDistributionLossChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/gas/natural/loss/chart/${granularity}`, {params: {siteId}}).then(res => res);
}


export default {
    getGasCSVExport,
    getNaturalGasDistributionLossChart,
}