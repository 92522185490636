<template>
	<div class="sites-container">
		<div class="sites-title">Leaderboards</div>
		<div class="sites-leaderboard-information">
			See how your sites' carbon emissions rank amongst each other.
		</div>



		<div class="leaderboard-controls">
			<div class="button-container">
			
				<q-btn-dropdown
					class="dropdown"
					dropdown-icon="expand_more"
					align="between"
					no-caps
					:label="granularity"
					outline
				>
					<q-list>
						<q-item
							clickable
							v-close-popup
							@click="granularity = 'Daily'"
						>
							<q-item-section>
								<q-item-label>Daily</q-item-label>
							</q-item-section>
						</q-item>

						<q-item
							clickable
							v-close-popup
							@click="granularity = 'Weekly'"
						>
							<q-item-section>
								<q-item-label>Weekly</q-item-label>
							</q-item-section>
						</q-item>

						<q-item
							clickable
							v-close-popup
							@click="granularity = 'Monthly'"
						>
							<q-item-section>
								<q-item-label>Monthly</q-item-label>
							</q-item-section>
						</q-item>

						<q-item
							clickable
							v-close-popup
							@click="granularity = 'Yearly'"
						>
							<q-item-section>
								<q-item-label>Yearly</q-item-label>
							</q-item-section>
						</q-item>
					</q-list>
				</q-btn-dropdown>
			

				<q-select
					:loading="firstLoad"
					dropdown-icon="expand_more"
					class="select"
					v-model="visibleColumns"
					multiple
					outlined
					dense
					options-dense
					display-value="Intensity Modifiers"
					emit-value
					map-options
					:options="cidColumns"
					option-value="name"
				>

					<template v-slot:before-options>
						<q-item>
							<q-item-section>
							<q-item-label>Select All</q-item-label>
							</q-item-section>
							<q-item-section side>
								<q-checkbox :model-value="selectAll" @update:model-value="selectAllModifiers()" />
							</q-item-section>
						</q-item>

						<q-separator />
					</template>

					<template v-slot:option="{ itemProps, opt, selected, toggleOption }">
						<q-item v-bind="itemProps">
							<q-item-section>
							<q-item-label v-html="opt.label" />
							</q-item-section>
							<q-item-section side>
								<q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
							</q-item-section>
						</q-item>
					</template>	
				</q-select>

				
				<q-select
					:loading="firstLoad"
					class="select"
					dropdown-icon="expand_more"
					outlined
					dense
					options-dense
					v-model="selectedModifier"
					no-caps
					:options="modifierOptions"
				/>
			</div>
		</div>


		<q-card class="sites-table">
			<q-table
				:table-style="'counter-reset: cssRowCounter ' + ((pagination.page - 1) * pagination.rowsPerPage) + ';'"
				hide-pagination
				:rows-per-page-options="[0]"
				:rows="!loading ? allSites : []"
				:columns="columns"
				row-key="name"
				:loading="loading"
				:visible-columns="visibleColumns"
				:pagination="pagination"
			>
				<template v-slot:body-cell-number>
					<q-td>
						<span class="rowNumber"/>
					</q-td>
				</template>
			</q-table>
		</q-card>
	</div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions.js";

import { find } from "lodash/collection";

const SiteEmissionsLeaderboardComponent = {
	name: "SitesEmissionsLeaderboard",

	data() {
		return {
			subscription: getSub(),
			subPermissions: subPermissions,

			loading: false,
			firstLoad: true,
			granularity: "Monthly",
			pagination: {
				sortBy: 'emissions', 
			},
			allSites: [],
			columns: [
				{
					name: "number",
					required: true,
					align: "left",
					label: "#",
				},
				{
					name: "name",
					required: true,
					label: "Site Name",
					align: "left",
					field: (row) => row.name,
				},
				{
					name: "address",
					required: true,
					label: "Address",
					align: "left",
					field: (row) =>
						row.addressNumber +
						" " +
						row.addressStreet +
						", " +
						row.addressCity,
			},
				{
					name: "category",
					required: true,
					label: "Category",
					align: "left",
					field: (row) => row.categoryDetail,
				},
				{
					name: "emissions",
					required: true,
					label: "Emissions",
					align: "left",
					field: (row) => {
						try {
							const emissions  = row.totalEmissions / this.divisonFactor(row);
							const value = Number.parseFloat(emissions.toFixed(2));

							return Number.isNaN(value) ? "0 Kg" : `${value.toLocaleString()} Kg`;
						} catch (e) {
							return "0 Kg";
						}
					},
					sort: (a, b) => {
						// Have to clean 'Kg' and ',' from string and convert to float to sort
						const _a = Number.parseFloat(a.replaceAll(/[,\s(Kg)]/g, ''))
						const _b = Number.parseFloat(b.replaceAll(/[,\s(Kg)]/g, ''))
						return _a - _b
					}
				},
				{
					name: "fte",
					label: "FTE",
					align: "center",
					field: (row) => row.FTE,
				},
			],

			// Intensity Modifiers
			selectAll: false,
			selectedModifier: { label: 'Total Emissions', value: 'total' },
			visibleColumns: [ 'fte' ],
			modifierOptions: [
				{ label: 'Total Emissions', value: 'total' },
				{ label: 'By FTE', value: 'fte' },
			],

			cidColumns: [
				{
					name: "fte",
					label: "FTE",
					align: "center",
					field: (row) => row.FTE,
				},
			],
		};
	},

	created() {
		this.init();
	},

	watch: {
		granularity() {
			this.init();
		},

		visibleColumns(val) {
			if (this.selectAll && val.length < this.cidColumns.length) {
				this.selectAll = false;
			} else if (val.length === this.cidColumns.length) {
				this.selectAll = true;
			}
		},

		selectedModifier(val) {
			let label;
			if (val.value === "total") {
				label = "Emissions"
			} else {
				// Get display label from column label
				const displayValue = find(this.cidColumns, ['name', val.value]).label
				label = `Emissions By ${displayValue}`
			}
			this.columns[4].label = label;
		}
	},

	methods: {
		/**
		 * @desc Loads list of sites and their attributes. Then for each site load the total emissions of each emission
		 * source the site tracks. For each site we also make a deep copy, calculate the average emissions
		 * per FTE, and add this to another array called averagedSites. This is the site that is displayed
		 * for FTE averaged sites.
		 */
		async init() {
			this.loading = true;

			// Reset averaged sites so we don't add copies
			this.averagedSites = [];

			try {
				this.allSites = (await api.sites.getSites()).data;

				if (this.flags.FLAG_4253_CID && this.subPermissions.customIntensityDenominators.includes(this.subscription)) {
					
					if (this.firstLoad) {
						// Get the CID's related to the site
						const cids = (await api.cid.getAllCID()).data;
						
						for (const cid of cids) {
							const columnObject = {
								name: cid.uuid,
								align: "left",
								label: cid.displayName,
								field: (row) => { 
									const site = find(this.allSites, ['id', row.id])		
									return site.customDenominators[cid.uuid] 
								}
							}
						
							this.modifierOptions.push({ label: `By ${cid.displayName}`, value: cid.uuid })

							this.columns.push(columnObject);
							this.cidColumns.push(columnObject);
						}
					
					}
				}


				const siteRequests = []
				
				// Use promise all to speed up requests
				for (const site of this.allSites) {
					siteRequests.push(api.sites.getSiteEmissions(site.id, this.granularity)
					.then((res) => {
						let totalEmissions = 0
						for (const emission of Object.values(res.data)) {
							totalEmissions += emission;
						}
						site.totalEmissions = totalEmissions;
					}));
				}

				await Promise.all(siteRequests);
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				this.loading = false;
				this.firstLoad = false;
			}
		},

		selectAllModifiers() {
			this.selectAll = !this.selectAll
			
			if (this.selectAll) {
				this.visibleColumns = this.cidColumns.map(col => col.name);
			} else {
				this.visibleColumns = []
			}
		},
		
		divisonFactor(site) {
			if (this.selectedModifier.value === 'fte') {
				return site.FTE
			} else if (this.selectedModifier && this.selectedModifier.value !== 'total') {		
				return site.customDenominators[this.selectedModifier.value];
			}
			return 1;
		}

	},
};

export default SiteEmissionsLeaderboardComponent;
</script>

<style lang="less" src="../../assets/styles/sites.less" scoped />
