<template>
		<div class="date-container">
			<div class="date-field-container">
				<span class="report-text report-label">From</span>
				<q-input square outlined class="date-input" hide-bottom-space v-model="startDate" :mask="dateFormat.replace(/[a-z]/gi, '#')"
					:rules="[(val) => validate(val)]"
					:placeholder="dateFormat"
					no-error-icon
					>
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy cover transition-show="scale" transition-hide="scale">
								<q-date v-model="startDate" :mask="dateFormat" :options="startDateOptions">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>
			</div>

			<div class="date-field-container">
				<span class="report-text report-label">To</span>
				<q-input square outlined class="date-input" hide-bottom-space v-model="endDate" :mask="dateFormat.replace(/[a-z]/gi, '#')"
					:rules="[(val) => validate(val)]"
					:placeholder="dateFormat"
					no-error-icon
					>
					<template v-slot:append>
						<q-icon name="event" class="cursor-pointer">
							<q-popup-proxy cover transition-show="scale" transition-hide="scale">
								<q-date v-model="endDate" :mask="dateFormat" :options="endDateOptions">
									<div class="row items-center justify-end">
										<q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
									</div>
								</q-date>
							</q-popup-proxy>
						</q-icon>
					</template>
				</q-input>
			</div>
        </div>
</template>
<script>

import dayjs from "@/services/util/dayjs";

export default {
	props: {
		dateFormat: {
			type: String,
			default: "DD/MM/YYYY",
		},
	},
	name: 'DateRangeInput',
    emits: ['update:dateRange'],
    data() {
        return {
			dayjs: dayjs,
			startDate: "",
			endDate: "",
		}
	},

	watch: {
		startDate() {
			this.emitChanges();
		},

		endDate() {
			this.emitChanges()
		}

	},
	methods: {
		emitChanges() {
			const valid = this.validate(this.startDate) && this.validate(this.endDate) && this.startDate && this.endDate;
			this.$emit("update:dateRange", { startDate: this.startDate, endDate: this.endDate, valid, })
		},

		startDateOptions(date) {
			const start = dayjs(date);
			const end = dayjs(this.endDate, this.dateFormat, true);

			return !end.isValid() || start.isSameOrBefore(end);
		},

		endDateOptions(date) {
			const start = dayjs(this.startDate, this.dateFormat, true)
			const end = dayjs(date);

			return !start.isValid() || end.isSameOrAfter(start);
		},

		validate(val) {
			return dayjs(val, this.dateFormat, true).isValid() || val.length === 0;
		}
	}
}

</script>