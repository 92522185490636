/**
 * tags.js
 *
 * API for handling creating, and getting tags
 */
 import axiosInstance from './axios-instance'

 /**
  * @desc Get list of tag names within the organisation
  * 
  * @returns {Promise<AxiosResponse<any>>}
  */
 const getTags = async () => {
     return await axiosInstance.get(`/api/tags`).then(res => res);
 };
 

 
 export default {
     getTags,
 };