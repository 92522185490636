<template>
	<div class="split-invoice-container">

		<div class="split-title">
			<span class="text" >Edit Invoice Data</span>
			<span class="subtitle">Edit the current invoice, including splitting the data, changing units and emission factors, adding new rows</span>
		</div>

		<div class="split-title">
				<span class="text" >Split Data By Accrual</span>
				<span class="subtitle">Split your invoice data evenly across the selected range</span>
				<DateRangeInput v-model:date-range="accrualRange" />
		</div>
		
		<q-table 
			class="split-invoice-parent-table invoice-upload-container" 
			ref="table" 
			:row-key="(row) => `${row.siteId}-${row.rowIndex}`" 
			:columns="columns" 
			:rows="optionsArray" 
			virtual-scroll 
			hide-pagination 
			:rows-per-page-options="[0]"
			bordered
		>
			<template v-slot:header="props">
				<q-tr class="parent-table" :props="props">
					<q-th v-for="col in columns" :key="col.name" :props="props">
						<span v-if="col.isRequired" style="color: red;">* </span>{{ col.label }}
					</q-th>
				</q-tr>
			</template>
			<template v-slot:body="props">
				<q-tr :props="props" v-if="showRow(props.row)">
					<q-td style="padding: 0%;" v-for="col in props.cols" :key="col.name">
							<div v-if="col.name === 'actions'" class="flex justify-evenly no-wrap" style="padding-inline: 10px;">
								<q-btn :icon="props.row.shouldSplit ? 'keyboard_arrow_up' : 'call_split'" flat dense color="primary" @click="triggerSplit(props.row)" :disable="!isValidRow({...props.row}) || props.row.ignore" />
								<q-btn :icon="props.row.ignore ? 'check' : 'close'" :color="props.row.ignore ? 'green' : 'red'" flat dense @click="ignoreRow(props.row)" />
							</div>
							<q-input 
								v-else-if="col.name === 'amount'" 
								v-model="props.row[col.name]" 
								type="number"
								step="any"
								:rules="[(val) => inputValidation(!!val, col.name, props.rowIndex)]"
								no-error-icon
								style="height: 100%;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								:disable="props.row.ignore || props.row.shouldSplit"
								form="edit-form"
								required
							/>
							<q-input 
								v-else-if="col.name === 'timestamp'"
								v-model="props.row[col.name]"
								:mask="timestampFormat.replace(/[a-z]/gi, '#')"
								:rules="[(val) => inputValidation(!!val && isValidTimestamp(val), col.name, props.rowIndex)]"
								no-error-icon
								style="height: 100%;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								:placeholder="timestampFormat"
								:disable="props.row.ignore || props.row.shouldSplit"
								form="edit-form"
								required
							>
								<template v-slot:prepend>
									<q-icon name="event" class="cursor-pointer">
										<q-popup-proxy cover transition-show="scale" transition-hide="scale">
											<q-date v-model="props.row[col.name]" :mask="timestampFormat">
												<div class="row items-center justify-end">
													<q-btn v-close-popup label="Ok" no-caps color="primary" flat></q-btn>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
									<q-icon name="access_time" class="cursor-pointer">
										<q-popup-proxy cover transition-show="scale" transition-hide="scale">
											<q-time v-model="props.row[col.name]" :mask="timestampFormat" format24h with-seconds>
												<div class="row items-center justify-end">
													<q-btn v-close-popup label="Ok" no-caps color="primary" flat></q-btn>
												</div>
											</q-time>
										</q-popup-proxy>
									</q-icon>
								</template>
								
							</q-input>
	
							<q-select 
								v-else-if="col.name === 'unit'" 
								v-model="props.row.unitId" 
								:rules="[(val) => inputValidation(!!val, col.name, props.rowIndex)]"
								:options="retrieveUnitMappings(props.row.dataTypeUUID)"
								:disable="!props.row.dataTypeUUID || props.row.ignore || props.row.shouldSplit"
								option-value="unitId"
								option-label="symbol"
								map-options
								emit-value
								style="height: 100%;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								no-error-icon
								form="edit-form"
								required
							/>
	
							<q-select 
								v-else-if="col.name === 'site'" 
								:model-value="props.row.siteId || (props.row.siteId = siteOptions[0][0])"
								:rules="[(val) => inputValidation(!!val && val !== 'null', col.name, props.rowIndex)]"
								@update:model-value="(siteId) => moveOptionsRow(siteId, props.row)"
								:options="siteOptions"
								:option-label="(site) => site[1]?.name"
								:option-value="(site) => site[0]"
								map-options
								emit-value
								style="height: 100%;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								:disable="props.row.ignore || props.row.shouldSplit"
								no-error-icon
								form="edit-form"
								required
							/>
							
							<AddTagsComponent 
								v-else-if="col.name === 'tags'"
								style="height: 100%; width: min-content;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								:disable="props.row.ignore || props.row.shouldSplit"
								:model-value="props.row.tags"
								@update:model-value="(val) => props.row.tags = val"
								:available-tags="availableTags"
								:loading="loadingTags"
								@new-tag="newTag"
							/>
			
							<q-select
								v-else-if="col.name === 'uuid'"
								style="height: 100%;"
								hide-bottom-space
								square
								filled
								bg-color="white"
								v-model="props.row.dataTypeUUID"
								:options="filteredEmissionFactors"
								@update:model-value="setDefaultUnitId($event, props.row)"
								option-value="uuid"
								option-label="displayName"
								:rules="[(val) => inputValidation(!!val, col.name, props.rowIndex)]"
								no-error-icon
								map-options
								emit-value
								:disable="props.row.ignore || props.row.shouldSplit"
								hide-selected
								use-input
								fill-input
								@filter="(val, update) => filterFn(val, update)"
								form="edit-form"
								required
							/>
						</q-td>
				</q-tr>
				
				<q-tr v-if="props.row.shouldSplit" :props="props" class="split-allocation-tr">
					<q-td style="padding: 0px" colspan="100%">
						<q-table :rows=props.row.rows :columns="inputColumns" :hide-pagination="true" :rows-per-page-options="[0]" hide-header
							class="split-invoice-child-table"
							:style="{ backgroundColor: calculateRemaining(props.row) == 0 ? 'green' : '' }">
							<template v-slot:body-cell-partialAmount="props">
								<q-td :props="props">
									<div class="split-allocation-partial-amount">
										<q-input type="number" dense hide-bottom-space no-error-icon borderless v-model="props.row.amount" square outlined required :rules="[(val) => val <= maxValue(props.row)]" />
										<q-select class="split-amount-input" dense borderless v-model="props.row.splitType" square outlined :options="['Amount', '%']" />
									</div>
								</q-td>
							</template>


							<template v-slot:body-cell-_="props">
								<q-td :props="props">
									
								</q-td>
							</template>

							<template v-slot:body-cell-partialUnit="props">
								<q-td :props="props">
									<q-select class="split-input" label="Unit" 
										v-model="props.row.unitId"
										:options="retrieveUnitMappings(props.row.dataTypeUUID)"
										option-value="unitId"
										option-label="symbol"
										map-options
										dense
										outlined
										emit-value
										>
									</q-select>
								</q-td>
							</template>

							<template v-slot:body-cell-partialSite="props">
								<q-td :props="props">
									<q-select class="split-input" label="Site" dense v-model="props.row.siteId" :options="Object.entries(sitesDict)"
										:option-label="(item) => item[1].name" outlined
										>
									</q-select>
								</q-td>
							</template>

							<template v-slot:body-cell-partialTags="props">
								<q-td :props="props">
									<q-select  class="split-input" label="Tags" dense :model-value="props.row.tags" @remove="(val) => { props.row.tags.splice(val.index, 1) }" @add="(val) => { props.row.tags.push(val.value) }" multiple use-chips use-input
										:options="availableTags" :option-label="(item) => item" @new-value="newTag"
										@popup-show="loadAvailableTags"
										square outlined >
									</q-select>
								</q-td>
							</template>

							<template v-slot:body-cell-partialUUID="props">
								<q-td :props="props">
									<q-select label="Emission Factor" 
										v-model="props.row.dataTypeUUID"
										:options="filteredEmissionFactors"
										option-value="uuid"
										option-label="displayName"
										@update:model-value="setDefaultUnitId($event, props.row)"
										outlined
										map-options
										dense
										emit-value
										hide-selected
										use-input
										fill-input
										@filter="(val, update) => filterFn(val, update)"
										>
										<template v-slot:prepend>
											<q-icon name="search" />
										</template>
									</q-select>
								</q-td>
							</template>
						</q-table>
					</q-td>
				</q-tr>

				<q-tr v-if="props.row.shouldSplit" :props="props" class="add-split-allocation-row">
					<td style="border: none" colspan="100%">
						<div>
							<q-btn 
								v-if="props.row.rows.length > 2" 
								class="add-split-allocation-button" 
								@click="removeSplitAllocation(props.row.siteId, props.row.rowIndex)"
								icon="close" 
								size="sm" 
								color="red" 
								dense 
								unelevated 
							>
								<q-tooltip class="text-body2">
									Remove most recent split row
								</q-tooltip>
							</q-btn>
							<q-btn 
								class="add-split-allocation-button" 
								@click="addSplitAllocation(props.row.siteId, props.row.rowIndex)" 
								icon="add" 
								size="sm" 
								color="secondary"
								dense 
								unelevated 
							>
								<q-tooltip class="text-body2">
									Add additional row for split data
								</q-tooltip>
							</q-btn>
							<q-btn 
								class="add-split-allocation-button" 
								@click="calculateRemainingAndNotifiy(props.row)"
								icon="o_check_circle" 
								size="sm" 
								color="purple" 
								dense 
								unelevated 
							>
								<q-tooltip class="text-body2">
									Click to validate split. Checks if the the split amount matches the total amount
								</q-tooltip>
							</q-btn>
							<span>Remaining Allocation: {{ calculateRemaining(props.row).toFixed(2) }}</span> 
						</div>
					</td>
				</q-tr>
			</template>

			<template v-slot:bottom>
				<q-tr style="margin-inline: auto;">
					<q-td>
						<q-btn
							class="add-split-allocation-button" 
							color="secondary" 
							icon="add" 
							dense 
							unelevated
							@click="addNewRow()"
						/>
					</q-td>
				</q-tr>
			</template>
		</q-table>
	</div>
</template>

<script>
	import { useSiteStateStore } from '@/stores/siteState.store';
	import api from '@/services/api/api'
	import notify from '@/services/util/notify';
	import dayjs from "@/services/util/dayjs";

	import DateRangeInput from '@/components/FormComponents/DateRangeInput.vue';
	import AddTagsComponent from './AddTagsComponent.vue';


	export default {
		name: 'SplitInvoiceTable',
		props: {
			fileId: {
				type: [String, Number],
				default: "",
				required: true,
			},
			units: {
				type: Array,
				required: true,
			},
			efUnits: {
				type: Object,
				required: true,
			},
			defaultEfUnits: {
				type: Object,
				required: true,
			},
			emissionFactors: {
				type: Array,
				required: true,
			}
		},
		emits: ['valid-allocations', 'update:accrualRange', "invalid"],
		components: {
			DateRangeInput,
			AddTagsComponent
		},
		data() {

			return {
				filteredEmissionFactors: structuredClone(this.emissionFactors),
				loading: false,
				dayjs: dayjs,

				dateFormat: "DD/MM/YYYY",
				
				accrualRange: {
					startDate: "",
					endDate: ""
				},

				inputErrors: new Set(),

				loadingTags: false,
				availableTags: [],
				selectedTags: [],
				availableUUIDObject: {},
				lastUsedOptionsObject: {},
				optionsObject: {},
				invoiceData: {},
				inputColumns: [
					{
						name: 'partialAmount',
						field: row => row.amount,
						align: 'left',
						style: 'width: 17.5%'

					},
					{
						name: 'partialUnit',
						field: row => row.unitId,
						align: 'left',
						style: 'width: 15%'
					},
					{
						name: 'partialSite',
						field: row => row.siteId,
						align: 'left',
						style: 'width: 15%'
					},
					{
						name: 'partialTags',
						field: row => row.tags,
						align: 'left',
						style: 'width: 15%'
					},
					{
						name: 'partialUUID',
						field: row => row.dataTypeUUID,
						align: 'left',
					},
				],
				columns: [
					{
						name: 'amount',
						label: 'Amount',
						field: row => row.amount,
						align: 'left',
						isRequired: true,
					},
					{
						name: 'unit',
						label: 'Unit',
						field: row => row.unitId,
						align: 'left',
						isRequired: true,
					},
					{
						name: "timestamp",
						label: "Timestamp",
						field: (row) => row.timestamp,
						sort: (a, b) => dayjs(a, this.timestampFormat) - dayjs(b, this.timestampFormat),
						align: "left",
						isRequired: true,
					},
					{
						name: 'site',
						label: 'Site',
						field: row => row.siteId,
						align: 'left',
						style: 'width: 15%',		
						isRequired: true,
					},
					{
						name: 'uuid',
						label: 'Emission Factor',
						field: row => row.dataTypeUUID,
						align: 'left',
						isRequired: true,
					},
					{
						name: 'tags',
						label: 'Tags',
						field: row => row.tags,
						align: 'left',
					},
					{
						name: 'actions',
						align: 'left',
					},
				],
        }

    },



    computed: {
        //Map to use to convert siteId to site names for display purposes
        sitesDict() {
            const sitesDict = {}
            useSiteStateStore().siteList.forEach((siteObject) => sitesDict[siteObject.id] = siteObject)
            return sitesDict
        },

				siteOptions() {
					return Object.entries(this.sitesDict);
				},

				timestampFormat() {
					return `${this.dateFormat} HH:mm:ss`;
				},

				requiredHeaders() {
					const mapping = {
						uuid: "dataTypeUUID",
						site: "siteId",
						unit: "unitId",
					};
					const headers = [];

					for (const header of this.columns) {
						if (header.isRequired) {
							headers.push(mapping[header.name] ?? header.name);
						}
					}

					return headers;
				},


		availableUnits() {
			const unitsDict = {}
			this.units.forEach((unitObject) => unitsDict[unitObject.id] = unitObject);
			return unitsDict
		},

        //Converts the optionsObject into an array form to be passed into the table
        optionsArray() {
					const optionsArray = [];

					for (const siteId in this.optionsObject) {
							for (const rowIndex in this.optionsObject[siteId]) {
									const rowObject = this.optionsObject[siteId][rowIndex];

									rowObject.rowIndex = rowIndex;
									rowObject.siteId = siteId;

									for (const row of rowObject.rows) {
											row.maxAmount = rowObject.amount;
									}

									optionsArray.push(rowObject);
							}
					}
					
					return optionsArray;
        },


        rowSplit() {
            const startValid = dayjs(this.accrualRange.startDate, this.dateFormat, true).isValid();
            const endValid = dayjs(this.accrualRange.endDate, this.dateFormat, true).isValid();
        
            for (const site of Object.values(this.optionsObject)) {
                for (const row of Object.values(site)) {
                    if ((row.shouldSplit && startValid && endValid) || (row.shouldSplit && !this.accrualRange.startDate && !this.accrualRange.endDate) ) {
                        return true;
                    }
                }
            }

            if (startValid && endValid) {
                return true;
            }

            return false;
        }
    },


    async created() {
		this.emissionFactors.forEach((ef) => this.availableUUIDObject[ef.uuid] = ef.displayName);

		this.invoiceData.previousOptionsObject = (await api.invoice.getPreviousOptions(this.fileId)).data
		this.invoiceData.parsedData = (await api.invoice.getParsedData(this.fileId)).data
		if(this.invoiceData.previousOptionsObject) {
			this.lastUsedOptionsObject = this.invoiceData.previousOptionsObject
		}
		this.availableTags = await this.loadAvailableTags();
		this.optionsObject = this.createOptionsObject(this.invoiceData.parsedData[Object.keys(this.invoiceData.parsedData)[0]])
    },


    watch: {
        invoiceData() {
            if (Object.getOwnPropertyNames(this.invoiceData).length > 0) {
                this.optionsObject = this.createOptionsObject(this.invoiceData.parsedData)
            }
        },

				async fileId(newVal) {
					if (newVal != null) {
						this.invoiceData.previousOptionsObject = (await api.invoice.getPreviousOptions(this.fileId)).data
						this.invoiceData.parsedData = (await api.invoice.getParsedData(this.fileId)).data
						this.optionsObject = this.createOptionsObject(this.invoiceData.parsedData[Object.keys(this.invoiceData.parsedData)[0]])

						if (this.invoiceData.previousOptionsObject) {
							this.lastUsedOptionsObject = this.invoiceData.previousOptionsObject
						} 

						this.availableTags = await this.loadAvailableTags()
					}
				},

				// Move the new rows into optionsObject
				"optionsObject.null": {
					handler(newRows) {
						if (!newRows) {
							return;
						}

						// Add the new rows into optionsObject
						for (const [key, row] of Object.entries(newRows)) {
							if (!this.isValidRow(row)) {
								continue;
							}
							
							this.optionsObject[row.siteId] ??= {};

							// Add into into the apprpriate siteId key in optionsObject
							const rowIndex = Object.keys(this.optionsObject[row.siteId]).length;
							row.rowIndex = rowIndex;
							this.optionsObject[row.siteId][rowIndex] = row;

							delete newRows[key];
						}
					},
					deep: true,
				},

				// Checks the accural range selections
				accrualRange: {
					handler(value) {
						// If valid or both inputs are empty, it is not an error
						if (value.valid || (!value.startDate && !value.endDate)) {
							this.inputErrors.delete("accrual");
						} else {
							this.inputErrors.add("accrual");
						}
					},
					deep: true,
				},

				inputErrors: {
					handler() {
						this.$emit("invalid", this.inputErrors.size > 0);
					},
					deep: true,
				},
    },


    methods: {

        //Checks all split allocation rows are valid (totals add up and all rows have no missing required fields)
        validateSplitAllocations() {
            Object.keys(this.optionsObject).map((rowKey) => {
                const invoiceRow = this.optionsObject[rowKey]
                Object.keys(invoiceRow).map((rowId) => {
                    if (invoiceRow[rowId].shouldSplit) {
                        invoiceRow[rowId].validAllocations = Number.parseFloat(invoiceRow[rowId].amount) == this.calculateTotalSplitAllocationAmount(invoiceRow[rowId])
                    } else {
                        invoiceRow[rowId].validAllocations = true
                    }
                })
            })
        },


        //Checks all required values in each split allocation row has a value
        checkAllRequiredFields({ rows }) {
            return rows.every((row) => {
                if (row.amount && row.siteId && row.unitId && row.dataTypeUUID) {
                    return true
                } else {
                    return false
                }
            })
        },


        // Validates that a single split allocation row is correct
        validateIndividualSplitAllocationAmount(data) {
            let total = 0;
            let siteSelected = true;
            const maxRowAmount = data.amount;

            for (const row of data.rows) {
                if (row.siteId == null) {
                    siteSelected = false;
                    break; // exit early if site is not selected
                } else if (row.amount == null) {
                    continue; // skip to the next iteration if amount is null
                } else {
                    if (row.splitType === '%') {
                        total += ((row.amount) / 100 * maxRowAmount);
                    } else {
                        total += Number.parseFloat(row.amount);
                    }
                }
            }

            // Use Number.EPSILON for floating-point comparison
            if (siteSelected && Math.abs(total - maxRowAmount) < Number.EPSILON) {
                notify.primary("This split allocation is valid");
            } else if (!siteSelected) {
                notify.warning("Please ensure a site is selected for each split allocation")
            } else {
                notify.warning(`The current split allocation (${total}) does not equal the total (${maxRowAmount}) `)
            }
        },


        //Calculate the remaining allocation amount and display on the table
        calculateRemaining(data) {
            const total = Number.parseFloat(data.amount);
            let remaining = total;
			let totalPercentage = 0;

            for (const row of data.rows) {
                if (row.splitType == '%') {
                    //Instead of taking away from the remaining every loop we aggregate the total percentage to avoid multiple floating point calculations
					totalPercentage += Number.parseFloat(row.amount)
                } else {
                    remaining -= row.amount
                }
            }
		
			if(totalPercentage > 0) {
				remaining -= totalPercentage / 100 * total
			}
			
            if (Math.abs(remaining) < Number.EPSILON) {
				return 0 
			} else {
				return remaining
			}
        },


		//Notifies user if row has remaining amount or not
		//Used for that little *check row is split correctly* button
		calculateRemainingAndNotifiy(data){
			const remaining = this.calculateRemaining(data);
			if(remaining !== 0){
				notify.warning(
					`This row currently isn't split correctly. The remaining split amount is ${remaining.toFixed(2)}`, 
					"top", 
					"Incorrect row split")
			}else{
				notify.primary("Row split correctly.");
			}
		},

        //Check split allocations amounts add up to the original amount
        calculateTotalSplitAllocationAmount({ amount, rows }) {
            let rawSum = 0;
            let percentageSum = 0;

            rows.reduce((accumulator, splitAllocation) => {
                if (splitAllocation.amount != null) {
                    if (splitAllocation.splitType === '%') {
                        percentageSum += Number.parseFloat(splitAllocation.amount) / 100;
                    } else if (splitAllocation.splitType === 'Amount') {
                        rawSum += Number.parseFloat(splitAllocation.amount);
                    }
                }
            }, 0);

            const totalAmount = rawSum + Number.parseFloat(amount) * percentageSum;
            return totalAmount;
        },

        async validAllocations() {
            this.validateSplitAllocations()
			
            const allocationsValidated = Object.keys(this.optionsObject).every((rowKey) => {
                const invoiceRow = this.optionsObject[rowKey]
                const validRow = Object.keys(invoiceRow).every((rowId) => {
                    if (invoiceRow[rowId].shouldSplit) {
                        return invoiceRow[rowId].validAllocations && this.checkAllRequiredFields(invoiceRow[rowId])
                    }
                    return true
                })
                return validRow;
            })
            
            if (allocationsValidated) {
                this.$emit("valid-allocations", {
									parsedData: this.invoiceData.parsedData,
									optionsObject: this.optionsObject, accrualRange: this.accrualRange
								});
            } else {
                notify.warning("Please ensure all split allocations sum to the total row amount and values for required fields", "top")
				return {};
            }
        },

		// Load available tags from server
		async loadAvailableTags() {
            if (this.availableTags.length == 0) {
				this.loadingTags = true;				
                try {
                    const response = await api.tags.getTags()
                    return response.data.map(tag => tag.name)
                } catch (error) {
                    console.error(error)

                } finally {
					this.loadingTags = false;
				}
            }
        },


        //Method use to add new tag to availableTags using dropdown
        newTag(val, done) {
            if (!this.availableTags.includes(val)) {
                this.availableTags.push(val);
                done(val, 'add-unique');
            }
        },


        //Adds another split allocation row to a selected rowId row
        addSplitAllocation(siteId, rowIndex, splitAllocationCount = 1) {
					this.optionsObject[siteId][rowIndex]
            if (splitAllocationCount == -1) {
                if (this.optionsObject[siteId][rowIndex]['rows'].length == 0) {

                    for (let i = 0; i < 2; i++) {
                        this.optionsObject[siteId][rowIndex]['rows'].push(this.createSplitAllocation(siteId, rowIndex))
                    }
                }
            } else {
                for (let i = 0; i < splitAllocationCount; i++) {
                    this.optionsObject[siteId][rowIndex]['rows'].push(structuredClone(this.createSplitAllocation(siteId, rowIndex)))
                }
            }
        },
		
		createSplitAllocation(siteId, rowIndex) {
			const allocationIndex = this.optionsObject[siteId][rowIndex]['rows'].length;
			
            if (!this.lastUsedOptionsObject[siteId] || rowIndex >= Object.keys(this.lastUsedOptionsObject[siteId]).length || allocationIndex >= this.lastUsedOptionsObject[siteId][rowIndex]['rows'].length ) {
				return {
                        amount: null,
                        siteId: null,
                        tags: [],
                        dataTypeUUID: this.optionsObject[siteId][rowIndex]['dataTypeUUID'],
                        splitType: this.optionsObject[siteId][rowIndex]['type'],
                        maxAmount: this.optionsObject[siteId][rowIndex]['amount'],
						unitId: this.optionsObject[siteId][rowIndex]['unitId']
                    }
			} else {
				const splitAllocationObject = this.lastUsedOptionsObject[siteId][rowIndex]['rows'][allocationIndex]
				splitAllocationObject.maxAmount = this.optionsObject[siteId][rowIndex]['amount']
				return splitAllocationObject
			}
		},

        //removes the latest split allocation
        removeSplitAllocation(siteId, rowIndex) {
            this.optionsObject[siteId][rowIndex]['rows'].pop()
        },  


        //Makes an obtions object to be used for the table from the incoming parsedData
        createOptionsObject(parsedData) {
            const optionsObject = {}

						for (const siteId in parsedData) {
							optionsObject[siteId] = {};

							for (let i = 0; i < parsedData[siteId].rows.length; i++) {
								const row = parsedData[siteId].rows[i];

								optionsObject[siteId][i] = {
									shouldSplit: false,
									type: "%",
									amount: row.amount,
									dataTypeUUID: row.dataTypeUUID,
									timestamp: dayjs(row.timestamp).format(this.timestampFormat),
									rows: [],
									unitId: row.unitId ?? row.originalUnitId,
								}
							}
						}

						this.mapPreviousOptions(optionsObject, this.lastUsedOptionsObject);
						
            return optionsObject;
        },

		mapPreviousOptions(optionsObject, lastUsedOptionsObject) {
			if(Object.keys(lastUsedOptionsObject).length > 0) {
				Object.keys(optionsObject).map((siteId) => {
					Object.values(optionsObject[siteId]).map((currentLineItem, lineItemIndex) => {
						if (lastUsedOptionsObject[siteId]?.[lineItemIndex]) {
							//TODO these lines dicate what bits of the last used invoice settings will be auto applied to the new invoice
							//NOTE: these settings to the new invoice are just placeholders in the component and may be confusing as the 
							//underlying data will be different.

								//eg. If i previously uploaded an avis invoice (line items usually rentalCar) and changed and emissionFactor to waste
								// the next time i open an Avis invoice to be split (unverified) it will pre fill the row with "Waste" EF which may be very confusing??
								//Same with siteId, unit, etc etc
							currentLineItem.unitId = lastUsedOptionsObject[siteId][lineItemIndex].unitId;
							currentLineItem.dataTypeUUID= lastUsedOptionsObject[siteId][lineItemIndex].dataTypeUUID;
							currentLineItem.shouldSplit = lastUsedOptionsObject[siteId][lineItemIndex].shouldSplit;
							currentLineItem.rows = [...lastUsedOptionsObject[siteId][lineItemIndex].rows]
						}
					})
				})
			}
		},

        // Calculates the max value depending on the split type
        maxValue(data) {
            if (data.splitType == '%') {
                return 100
            }
            else {
                return data.maxAmount
            }
        },


		/**
		 * @desc Using the available units for an emission factor, create an object that contains the ids as well.
		 * @param {Array} units List of units for the emission factor.
		 */
		retrieveUnitMappings(uuid) {
			const emissionFactorUnitObjectArray = [];
			
			const displayName = this.availableUUIDObject[uuid];
			const units = this.efUnits[displayName];

			if (Array.isArray(units)) {
				units.forEach((symbol) => {
					const newUnitObject = {
						unitId: "",
						symbol
					}
					newUnitObject['unitId'] = Object.keys(this.availableUnits).find(key => this.availableUnits[key].symbol === symbol)
					emissionFactorUnitObjectArray.push(newUnitObject);
				});
			}
			
			return emissionFactorUnitObjectArray
		},

			scrollToBottomOfTable() {
				// Wait until next DOM update cycle for the new rows then scroll to the bottom
				this.$nextTick(() => {
					const table = this.$refs.table.$el.querySelector(".q-table__middle.scroll");
					table.scrollTop = table.scrollHeight;
				});
			},

			triggerSplit(row) {
				row.shouldSplit = !row.shouldSplit;
				this.addSplitAllocation(row.siteId, row.rowIndex, -1);
			},

			/**
			 * Checks if a new row is valid by ensuring it contains all required keys and their values are truthy.
			 * @method
			 * @param {Object} row - The row to check.
			 * @returns {boolean} - Returns true if the row is valid, false otherwise.
			 */
			isValidRow(row) {
				row.siteId = row.siteId === "null" ? null : row.siteId;
				const containsRequired = this.requiredHeaders.every((header) => Object.hasOwn(row, header) && Boolean(row[header]));
				const validTimestamp = this.isValidTimestamp(row.timestamp);
				
				return containsRequired && validTimestamp;
			},

			isValidTimestamp(timestamp) {
				return dayjs(timestamp, this.timestampFormat, true).isValid()
			},

			showRow(row) {
				// If it's a new row and not all inputs have been set
				if (row.newRow && row.ignore && !this.isValidRow(row)) {
					return false;
				}

				return true;
			},

			/**
			 * Move the row to the correct optionsObject site key.
			 * 
			 * This is for when the row's siteId changes, as it will need to be moved into the correct key in optionsObject.
			 * 
			 * @method
			 * @param {Object} row - The selected row.
			 */
			moveOptionsRow(newSiteId, row) {
				if (this.isValidRow(row) && row.newRow) {
					this.optionsObject[newSiteId] ??= {};

					// Add into the apprpriate siteId key in optionsObject
					const rowIndex = Object.keys(this.optionsObject[newSiteId]).length;
					this.optionsObject[newSiteId][rowIndex] = row;

					// Delete the old value
					delete this.optionsObject[row.siteId][row.rowIndex];

					row.rowIndex = rowIndex;
				}

				row.siteId = newSiteId;
			},

			ignoreRow(row) {
				row.ignore = !row.ignore;
				row.shouldSplit = false;

				// If it's a new row and not all inputs have been set, delete the row from optionsObject
				if (!this.showRow(row)) {
					delete this.optionsObject[null][row.rowIndex];
				}

			},

			/**
			 * Adds an empty row into optionsObject.
			 * 
			 * @method
			 */
			addNewRow() {
				const siteId = null;
				
				this.optionsObject[siteId] ??= {};

				const rowIndex = Object.keys(this.optionsObject[siteId]).length;

				this.optionsObject[siteId][rowIndex] = {
						rows: [],
						shouldSplit: false,
						type: "%",
						newRow: true,
				};

				this.scrollToBottomOfTable();
			},

		/**
		 * Given a UUID, set the unit id for that row to the default.
		 * @param {String} uuid - UUID to retrieve default for
		 * @param {Object} row - Row to update
		 */
		setDefaultUnitId(uuid, row) {
			const displayName = this.availableUUIDObject[uuid];
			const defaultUnitSymbol = this.defaultEfUnits[displayName];
			
			for (const unitObject of this.units) {
				if (unitObject.symbol == defaultUnitSymbol) {
					row.unitId = unitObject.id;
					break;
				}
			}
		},

		/**
		 * Adds to inputErrors if any field has been validated and not valid.
		 * 
		 * @param {boolean|string} result - The result of the validation.
		 * @param {string} colName - The name of the column.
		 * @param {number|string} rowIndex - The row number.
		 * @returns {boolean|string} result value.
		 */
		inputValidation(result, colName, rowIndex) {
			// If the result is False or a string e.g. "Please enter a value"
			if (!result || (typeof result === "string" && result?.length > 0)) {
				this.inputErrors.add(`${rowIndex}, ${colName}`);
			} else {
				this.inputErrors.delete(`${rowIndex}, ${colName}`);
			}

			return result;
		},
		
		/**
		 * Given a UUID, set the unit id for that row to the default.
		 * @param {String} uuid - UUID to retrieve default for
		 * @param {Object} row - Row to update
		 */
		filterFn(val, update) {
			update(() => {
				const needle = val.toLowerCase();
				this.filteredEmissionFactors = this.emissionFactors.filter(
					option => option.displayName.toLowerCase().includes(needle)
				);
			});
		},
    },
}

</script>

<style lang="less" src="@/assets/styles/invoiceUpload.less" scoped />
