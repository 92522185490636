// Common subscription permissions

import subscriptions from "./subscriptions";

export default {
    viewTargets: [subscriptions.SmallBusiness, subscriptions.Pro],
    auditing: [subscriptions.SmallBusiness, subscriptions.Pro],
    fileStorage: [subscriptions.SmallBusiness, subscriptions.Pro],
    toituExport: [subscriptions.SmallBusiness, subscriptions.Pro],
    addDataConnections: [subscriptions.SmallBusiness, subscriptions.Pro],
    siteLeaderboard: [subscriptions.SmallBusiness, subscriptions.Pro],
    dataTracing: [subscriptions.SmallBusiness, subscriptions.Pro],
    manageSites: [subscriptions.SmallBusiness, subscriptions.Pro],
    acceptDataSharingInvitations: [subscriptions.SmallBusiness, subscriptions.Pro],
    inviteOrganisations: [subscriptions.Pro],
    liveCarbonPrice: [subscriptions.Pro],
    manageOrganisations: [subscriptions.Pro],
    customIntensityDenominators: [subscriptions.Pro],
    organisationIntensityDenominators: [subscriptions.Pro],
	aiInvoiceScrape: [subscriptions.Pro, subscriptions.SmallBusiness],
};
