<template>
  <div>
    <q-form @submit.prevent="acceptInvite()">
      <div class="users-invite-container">
        <p>Accept invitations</p>
        <div class="users-invite-input-container">
          
					<div class="users-invite-inputs">
            <q-input
							style="width: 25rem;"
              borderless
              v-model="inviteCode"
              label="Enter your invite code here"
            />
          </div>
        </div>
        <div class="users-invite-button">
          <q-btn no-caps label="Accept" :loading="submitting" type="submit" />
        </div>
      </div>
    </q-form>
    <div class="q-pt-md users-table-container">
      <p>Manage Organisations who you share data with</p>
      <q-table
        class="users-role-table"
        :loading="loading"
        :rows="organisations"
        :columns="tableColumns"
      >
        <template v-slot:body-cell-status="props">
          <q-td :props="props" class="status-text">
            <div v-if="props.row.status" style="color: green">Connected</div>
            <div v-if="!props.row.status" style="color: orange">Pending</div>
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-btn
              no-caps
              color="primary"
              flat
              @click="displayDeleteConfirmation(props.row)"
              >Remove</q-btn
            >
          </q-td>
        </template>
      </q-table>
    </div>

    <q-dialog v-model="displayDeleteConfirmationPanel" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-md"
            >Are you sure you want to delete your connection to
            {{
              this?.connectionToDelete?.guestTenantName ?? "This tenant"
            }}?</span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn
            flat
            label="Delete"
            color="deep-orange"
            @click="deleteConnection(this?.connectionToDelete?.id)"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

const AcceptDataShareInviteComponent = {
  name: "AcceptDataShareInvite",

  data() {
    return {
      inviteCode: "",
      loading: false,
      submitting: false,

      tableColumns: [
        {
          name: "name",
          label: "Organisation Name",
          field: "guestTenantName",
          style: "width: 40em",
          align: "left",
        },
        {
          name: "email",
          label: "Email",
          field: "email",
          align: "left",
        },
        {
          name: "status",
          label: "Status",
          field: "status",
          align: "left",
        },
        {
          name: "action",
          label: "Action",
          field: "action",
          style: "width: 1%",
          align: "center",
        },
      ],

      displayDeleteConfirmationPanel: false,
      connectionToDelete: {},

      organisations: [],
    };
  },

  async created() {
    await this.getCurrentOrganisations();
  },

  methods: {
    /**
     * @desc submits a new organisation invite.
     */
    async acceptInvite() {
      this.submitting = true;

      let payload = { inviteHash: this.inviteCode };

      api.tenantConnections
        .patchTenantConnection(payload)
        .then((res) => {
          notify.primary(
            res?.data ??
              `Successfully accepted invitation to code ${this.inviteCode}`,
            "top",
            "Accept successful"
          );
          this.getCurrentOrganisations();
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => (this.submitting = false));
    },

    /**
     * @desc retrieves all currently invited organisations for current tenant
     */
    async getCurrentOrganisations() {
      this.loading = true;
      api.tenantConnections
        .getTenantConnections("source")
        .then((res) => {
          this.organisations = res.data;
        })
        .catch((err) => {
          notify.withObject(err.response);
        })
        .finally(() => (this.loading = false));
    },

    /**
     * @desc Displays the delete confirmation message box to the user, and sets
     * the connectionToDelete variable to the connection selected by the user to delete.
     */
    async displayDeleteConfirmation(connection) {
      this.connectionToDelete = connection;
      this.displayDeleteConfirmationPanel = true;
    },

    /**
     * @desc Deletes the connectionToDelete variable currently stored.
     */
    async deleteConnection() {
      api.tenantConnections
        .deleteTenantConnection(this.connectionToDelete.id)
        .then(() => {
          notify.primary(
            `Successfully deleted connection to ${this.connectionToDelete.guestTenantName}`,
            "top",
            "Delete successful"
          );
          this.getCurrentOrganisations();
        })
        .catch((err) => {
          notify.withObject(err.response, "top", `Failed to delete connection to ${this?.connectionToDelete?.guestTenantName ?? "guest tenant"}`);
        });
    },
  },
};

export default AcceptDataShareInviteComponent;
</script>

<style lang="less">
@import "@/assets/styles/settings.less";
</style>
