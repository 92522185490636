<template>
  <div class="eiep-connection-container">
    <div class="eiep-connection-header">
      <q-icon class="close-modal" name="close" size="2rem" @click="$emit('closeModal')" />
      <div class="eiep-connection-header-title">Add a new connection
        <q-icon color="primary" name="help">
          <q-tooltip>GreenHalo can be set up to automatically pull your electricity data onto the
            platform periodically.
            To get started fill in the form below and wait for us to get everything set up.
          </q-tooltip>
        </q-icon>
      </div>
      <div class="eiep-connection-header-body">
        <div>To link your electricity data, please fill in the details below and
          click submit when done. Your connection request will then be processed
          and if successful it will be indicated by showing "connected" in the
          "Meter Connections" page.
          This information can be found on your electricity invoice
        </div>
      </div>
    </div>

    <div class="eiep-form-container">

      <q-form @submit="onSubmit" class="q-gutter-md">

        <q-input outlined label="ICP Identifier Number" maxlength="15" style="margin-bottom: 1rem;" 
          v-model="customerInformation.ICPIdentifier" 
          @update:model-value="(val) => customerInformation.ICPIdentifier = val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()"
          :rules="[(val) => (val?.length === 15 || !val) || 'Value must be 15 characters long']"
          hint="Your electricity meter's unique identifier" lazy-rules required
        >
          <q-icon name="help">
            <q-tooltip>
              <q-img src="@/assets/tutorials/electricity/icp/ICP.png" style="width: 60vh" fit="fill" />
            </q-tooltip>
          </q-icon>
        </q-input>

        <q-input outlined v-model="customerInformation.customerName" label="Name of the person on invoice *" lazy-rules maxlength="50" required />

        <q-toggle v-model="manualAddress"
          label="Can't find your address? You can use our manual form"
          style="user-select: none;"/>


        <div v-if="manualAddress" style="display: flex; flex-direction: column; gap: 1rem;">
          <q-input outlined v-model="manualAddressObject.installAddressUnit" label="Unit Number" lazy-rules maxlength="20" />
          <q-input outlined v-model="manualAddressObject.installAddressNumber" label="Street Number" lazy-rules maxlength="20" required />
          <q-input outlined v-model="manualAddressObject.installAddressStreet" label="Street Name" lazy-rules maxlength="100" required />
          <q-input outlined v-model="manualAddressObject.installAddressSuburb" label="Suburb" lazy-rules maxlength="50" required />
          <q-input outlined v-model="manualAddressObject.installAddressPOBox" label="PO Box" maxlength="50" />
          <q-input outlined v-model="manualAddressObject.installAddressTown" label="Town" lazy-rules maxlength="75" required />
          <q-input outlined v-model="manualAddressObject.installAddressPostCode" label="Post Code" lazy-rules mask="##########" required />
          <q-input outlined v-model="manualAddressObject.installAddressCountry" label="Country" lazy-rules maxlength="75" required />
        </div>

        <q-input v-else
					outlined
					bg-color="white"
					v-model="address"
					label="Address"
					required
          maxlength="75"
				>
					<template v-slot:after>
						<q-icon
							name="help"
							color="grey"
							size="sm"
						>
							<q-tooltip style="font-size: 1rem">
								The address of your meter.
							</q-tooltip>
						</q-icon></template
					>
					<q-menu
						no-focus
						auto-close
						fit
					>
						<q-list>
							<q-item
								v-if="addresses.length == 0 && address != ''"
							>
								{{ addressResult }}
							</q-item>

							<q-item
								clickable
								v-for="ad in addresses"
								:key="ad"
								@click="address = ad"
							>
								{{ ad }}
							</q-item>
						</q-list>
					</q-menu>
				</q-input>

          <div>
            <q-select label="Please select your electricity provider" v-model="this.selectedRetailerIdentifier"
          :options="this.retailerIdentifiers"
          :option-label="((item) => {
            if(item.retailerName!=undefined) {
              return item.retailerName + ' - ' + item.retailerIdentifier;
            }  
            })" />
            <q-icon name="help"/> If you have any confusion, please refer to the 
              <a href="https://register.ea.govt.nz/identifiers/identifiers" target=”_blank”> Electricity Authorities Retailer Identifier page</a>
           
          </div>


        <q-toggle v-model="accept"
          label="I consent to give Ember Technology authority to use my electricity information" />


        <div>
          <q-btn label="Submit" type="submit" color="primary" />
          <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm" />
        </div>
      </q-form>

    </div>
    <div v-if="loading"
	class="loader"
	/>

  </div>
</template>

<script>

import api from "@/services/api/api";
import notify from "@/services/util/notify";

const AddICPMeterCardComponent = {
  name: "AddICPMeterCardComponent",

  data() {
    return {
      loading: false,
      addresses: [],
      address: "",
      addressResult:"",
      accept: false,
      customerInformation: {},
      retailerIdentifiers: [],
      selectedRetailerIdentifier: {},
      requestFilename: "",
      manualAddress: false,
      manualAddressObject: {},
    };
  },

  created() {
    this.getRetailIdentifiers()
  },

  watch: {
		address: {
			handler(add) {
				// Removes residual addresses from previous searches if input is empty
				if (add.length < 1) {
					this.addresses = [];
					return;
				}
				this.addressResult = "Finding Addresses ...";
				api.sites
					.getAutoCompleteAddresses(add)
					.then((addresses) => {
            this.addresses = addresses
          })
					
            .catch((error) => console.error(error))
					.finally((this.addressResult = "No Addresses Found"));
			},
		},
	},

  methods: {
    /**
     * @desc Retrieves a list of EIE Registry Retailers and their identifiers
     */
    async getRetailIdentifiers() {
      try{
        var response = await api.EIEP.getRetailerIdentifiers()
        this.retailerIdentifiers = response.data
        this.retailerIdentifiers.sort((retailer1, retailer2) => (retailer1.retailerName > retailer2.retailerName) ? 1 : -1)
      }
      catch(error) {
        notify.error("Error loading retailer list", "top");
      }
    },

    
    /**
     * @desc Checks that the form meets all rules and post to server
     */
    async onSubmit() {

      if (this.accept !== true) {
        notify.warning("You need to accept the terms first", "top");
        return
      }

      if (this.selectedRetailerIdentifier.retailerIdentifier == undefined) {
        notify.warning("Please select an electricity provider", "top");
        return
      }

      this.customerInformation.retailerIdentifier = this.selectedRetailerIdentifier.retailerIdentifier
      this.loading = true

      await this.postData()
    },


    /**
     * @desc Sends data collected in form to backend route to add to DB and calls the backend 
     * routes for creating and sending request file to EIE registry
     */
    async postData() {
        this.customerInformation["address"] = this.sortAddress(this.manualAddress)

        try {
          await api.users.addEIEPInformation(this.customerInformation)
          await api.EIEP.createEIEPRequestFile(this.customerInformation.ICPIdentifier)    
          
          this.$emit("updateTable")
          this.$emit("closeModal")
          notify.primary("Successfuly setup EIEP Connection.", "top");
          
        } catch (err) {
          console.error(err);

          if (err.response.status === 400) {
            notify.warning(err.response.data);
          } else {
            notify.error("Unable to setup EIEP Connection. Please try again later.");
          }          
        } finally {
          this.loading = false;
        }
    },

    sortAddress (manualAddress) {
      let addressObject = {}

      if(manualAddress){
        addressObject["installAddressUnit"] = this.manualAddressObject["installAddressUnit"]
        addressObject["installAddressNumber"] = this.manualAddressObject["installAddressNumber"] 
        addressObject["installAddressSuburb"] = this.manualAddressObject["installAddressSuburb"] 
        addressObject["installAddressTown"] = this.manualAddressObject["installAddressTown"] 
        addressObject["installAddressPostCode"] = this.manualAddressObject["installAddressPostCode"] 
        addressObject["installAddressCountry"] = this.manualAddressObject["installAddressCountry"] 
        addressObject["installAddressStreet"] = this.manualAddressObject["installAddressStreet"] 
        addressObject["installAddressCountry"] = this.manualAddressObject["installAddressCountry"] 

      } else {
        let addressArray = this.address.split(",")
        let streetArray = addressArray[0].split(" ")
        let streetNumberArray = streetArray.shift()
      
        addressObject["installAddressNumber"] = streetNumberArray.trim()
        addressObject["installAddressSuburb"] = addressArray[1].trim()
        addressObject["installAddressTown"] = addressArray[2].trim()
        addressObject["installAddressPostCode"] = addressArray[3].trim()
        addressObject["installAddressCountry"] = addressArray[4].trim()
        addressObject["installAddressStreet"] = streetArray.join(" ").trim()
      }
      

      return addressObject
      }
      
    
  }
};

export default AddICPMeterCardComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>