<template>
  <div class="datasource-list-container q-pa-md">
    <div v-if="!displayBPGuide && !displayZGuide">
      <div class="datasource-list-title">
        Select a service provider to connect
      </div>
      <div class="fuel-datasource-container">
        <!-- BP Card -->
        <q-list class="content-list">
          <q-item
            class="content-item center-column"
            clickable
            v-ripple
            @click="displayBPGuide = true"
          >
            <q-img
              style="max-height: 100px; max-width: 100px"
              src="@/assets/tutorials/fuel/datasourceListIcons/bp_icon.png"
            />
            <div class="content-item-inner-text">
              Add a BP Fuel card <q-icon name="arrow_forward_ios" />
            </div>
          </q-item>

          <!-- Z Fuel Card -->
          <q-item
            class="content-item center-column"
            clickable
            v-ripple
            @click="displayZGuide = true"
          >
            <q-img
              style="max-height: 100px; max-width: 100px"
              src="@/assets/tutorials/fuel/datasourceListIcons/z_icon.png"
            />
            <div class="content-item-inner-text">
              Add a Z Fuel card <q-icon name="arrow_forward_ios" />
            </div>
          </q-item>
        </q-list>
      </div>
    </div>

    <AddBPCardComponent @rerenderList="rerenderList()" v-if="displayBPGuide" @back="resetProviderSelection" />

    <AddZCardComponent @rerenderList="rerenderList()" v-if="displayZGuide" @back="resetProviderSelection" />
  </div>
</template>

<script>
import notify from "@/services/util/notify";
import AddBPCardComponent from "./AddBPCardComponent.vue";
import AddZCardComponent from "./AddZCardComponent.vue";
const FuelDataSourceOptionsComponent = {
  name: "FuelDataSourceOptionsComponent",
  components: { AddBPCardComponent, AddZCardComponent },
  data() {
    return {
      displayBPGuide: false,
      displayZGuide: false,
    };
  },

  created() {},

  methods: {

    /**
     * @desc Alerts user that functionality isn't set up yet
     * @param {String} company the name of company with fuel card
     */
    alertNotmplemented(company) {
      notify.error(`${company} Fuel Card not yet implemented, sorry!`, "top");
    },


    /**
     * @desc Resets q dialog to "select provider" view
     */
    resetProviderSelection() {
      this.displayBPGuide = false;
      this.displayZGuide = false;
    },

    /**
     * Reloads fuel sources list with new card connection
     */
    rerenderList(){
      this.$emit("rerenderList");
    }
  },
};

export default FuelDataSourceOptionsComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>