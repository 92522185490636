<template>
  <div>
  <img class="bg" />
  <div class="container">
    <span class="building-blocks"></span>

    <div class="row justify-content-center">
      <div class="login">
        <q-form @submit.prevent="submit">
          <div class="login-form-container">
            <h2 class="portal-title text-center">Sign up for GreenHalo</h2>

            <div class="form-group">
              <q-btn outlined class="form-button-sso" color="white" no-caps @click="microsoftSignUp()">
                <img src="..\assets\microsoft-icon.svg" height="15" width="15" alt="Microsoft logo" />
                &nbsp; Sign up with Microsoft
              </q-btn>
            </div>

            <div class="signup-divider">
              <span>OR</span>
            </div>

            <div class="login-email">
              <span class="input-label">Email</span>
              <q-input type="email" class="input-control" id="email" icon-right="close-circle" v-model="store.user.email" maxlength="50"
                autocomplete lazy-rules outlined :rules="[
                    (val) =>
                      validEmail(val) || 'Please enter a valid email address',
                  ]">
                <template v-slot:prepend>
                  <q-icon name="o_email" />
                </template>
              </q-input>
            </div>

            <div class="login-password">
              <span class="input-label">Password</span>
              <PasswordStrengthMeter v-model="store.user.password" />
            </div>

            <div class="form-group">
              <q-btn :loading="credentialSignupLoading" :disabled="!this.valid" class="form-button" id="button-login" type="submit" label="Sign up" color="secondary"
                no-caps />
            </div>

            <div class="form-group-button">
              <span>Already have an account? <router-link to="/auth/login" tabindex="-1" style="padding-block: 5px;">Login</router-link></span>
            </div>
          </div>
        </q-form>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import api from "@/services/api/api"
import notify from "@/services/util/notify";
import { patterns } from "quasar";
import { PublicClientApplication } from "@azure/msal-browser";
import { useSignupStore } from "@/stores/signup.store";
import PasswordStrengthMeter from "./PasswordStrengthMeter.vue";

export default {
  name: "SignupComponent",
  components: {
    PasswordStrengthMeter,
  },
  data() {
    return {

      valid: false,
      ssoSignupLoading: false,
      credentialSignupLoading: false,

      store: useSignupStore(),
    };
  },

  /**
   * Clear the localStorage
   */
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
    localStorage.clear();
    sessionStorage.clear();

    this.store.clearUserState();
    this.store.signupType = "";

  },

  methods: {
    /**
     * @desc Go to /signup.
     */
    async submit() {
      this.credentialSignupLoading = true;
      localStorage.clear()
      sessionStorage.clear();

      let userExists = await api.users.checkUserExistsWithEmail(this.store.user.email);
      this.credentialSignupLoading = false;

      if (!userExists.data) {
        localStorage.setItem('email', this.store.user.email);
        this.store.signupType = 'credentials';
        this.$router.push({ path: '/signup', query: {subscriptionTier: this.$route.query.subscriptionTier} });
      } else {
        notify.warning("A user already exists with the provided email", "top");
      }
    },

    validPassword(val) {
      if( val.length < 8 || !/[A-Z]/.test(val)){
        return 'Please make sure your password is longer than 7 characters and contains atleast one captial letter'
      }
    },
    /**
     * @desc Checks that the email provided is an email and changes property "valid" which is used by the login button
     * @param {String} val email to check
     * @returns {Boolean} true if email is valid
     */
    validEmail(val) {
      if (patterns.testPattern.email(val)) {
        this.valid = true;
        return true;
      } else {
        this.valid = false;
      }
    },

    /**
     * @desc Attempts to signup using the sso signup method.
     */
    async microsoftSignUp() {
      const signupReq = {
        scopes: [
          "api://b3d9a653-9c14-481b-a862-bcf317743bfb/GreenHalo.Read",
          "api://b3d9a653-9c14-481b-a862-bcf317743bfb/GreenHalo.Write",
        ],
      };
      this.ssoSignupLoading = true;
      await this.$msalInstance
        .loginPopup(signupReq)
        .then(async (account) => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          const ssoSignupObject = {
            authorization: "Bearer " + account.accessToken,

          };

          let userExists = await api.users.checkUserExistsWithEmail(this.account.username);

          if (!userExists.data) {
            this.store.signupType = 'sso'
            this.store.user = ssoSignupObject
            localStorage.setItem('email', this.account.username)
            this.$router.push({ path: '/signup' });
          } else {
            notify.warning("A user already exists with the provided email", "top");
          }
          
        })
        .catch(() => {
          notify.error("Error during Microsoft authentication", "top");
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/styles/login.css";
@import "../assets/styles/elements/loader.css";
</style>
