<template>
	<div class="report-export-container">
		<h2 class="report-heading">Add Emission Factor</h2>
		<q-form class="add-emission-factor-form" @submit="addDataType()">
			<q-input v-model="displayName" label="Display Name" stack-label required maxlength="75"
			:rules="[(val) => /^[a-zA-Z0-9\s]+$/.test(val) || `Display Name may only contain letters, numbers, and spaces.`]">
			<template v-slot:append>
					<q-icon class="q-pb-xs" name="help" color="primary">
						<q-tooltip class="text-body2" anchor="center right" style="font-size: 1.1rem;" self="center left" :offset="[10, 10]">
							This value will be what GreenHalo uses to uniquely identify this new 
							custom emission factor.
							<br>
							<br>
							It determines what will be entered in the "emissionFactor"
							field during manual upload to assign emission activity under this new
							emission factor.
						</q-tooltip>
					</q-icon>
				</template>
				</q-input>
				<q-checkbox v-model="isSpendBased" label="Is Spend Based?" ></q-checkbox>

				<div class="row">
					<q-select class="col" v-model="selectedEmissionSource" label="Emission Source" :options="emissionSourceOptions" :rules="[(val) => val !== null || 'Please select an emission source']" stack-label emit-value map-options />
					<q-select :disable="isSpendBased" class="col-3 offset-xs-1" v-model="unit" label="Unit" :rules="[(val) => val !== null || 'Please select a unit']" stack-label required :options="unitOptions" />
				</div>

				<q-select v-model="scope" label="Scope" :options="[1, 2, 3]"
					:rules="[(val) => val !== null || 'Please select a scope']" stack-label emit-value map-options 
					:disable="flags.FLAG_5331_ISO_GHG && loadingGHG"
					/>
				<q-select v-model="ghgCategory" label="GHG Protocol Subcategory" :options="ghgCategoryOptions"  :loading="loadingGHG"
					:rules="[(val) => val !== null || 'Please select a GHG Protocol Subcategory']" required stack-label
					:option-label="(label) => `${label.name}: ${label.description}`" :disable="enableGHGInput"
					lazy-rules="">
					<template v-slot:hint>
						<a href="https://ghgprotocol.org/" style="color: purple" @click.stop target="_blank">
							GHG protocol
						</a>
					</template>
				</q-select>
				<q-input v-model="description" type="textarea" outlined stack-label label="Description (optional)" maxlength="200" />
				<q-btn type="submit" label="Confirm" color="secondary" :loading="loading" style="width: 7.5em;" no-caps />
		</q-form>
	</div>
</template>

<script>
export default {

		props: {
			ghgCategoryOptions: {
				type: Object,
				required: true,
			},

			emissionSourceOptions: {
				type: Array,
				required: true,
			},

			unitOptions: {
				type: Array,
				required: true,
			},

			loading: {
				type: Boolean,
				required: true,
			},
		},

		data() {
			return {
				selectedEmissionSource: null,
				loadingGHG: false,
				ghgCategory: null,
				carbonRateName: "",
				displayName: "",
				isSpendBased: false,
				unit: null,
				description: "",
				scope: null
			}
		},

		computed: {
			enableGHGInput(){
				return this.scope != 3;
			},
		},

		watch: {
			scope(){
				switch(this.scope){
					case 1:
						this.ghgCategory = this.ghgCategoryOptions.filter((opt) => {
							return opt.name === `Scope 1`;
						})[0];
						break;
					case 2:
						this.ghgCategory = this.ghgCategoryOptions.filter((opt) => {
							return opt.name === `Scope 2`;
						})[0];
						break;
					default: 
						this.ghgCategory = null;
						break;
				}
			},

			// For spend based emissions, there are defaults
			isSpendBased(newVal) {
				if (newVal) {
					this.unit = this.unitOptions.find((item) => item.symbol === '$');
					this.scope = 3;
					this.$nextTick(() => {
						this.ghgCategory = this.ghgCategoryOptions.filter((opt) => {
							return opt.name === `Category 1`;
						})[0];
					});
				} else {
					this.unit = null;
					this.scope = null;
				}
			}
		},

		methods: {
			async addDataType() {
				this.carbonRateName = this.camelCase(this.displayName);
				try {
					const emitObject = {
						carbonRateName: this.carbonRateName,
						displayName: this.displayName,
						unit: this.unit.symbol,
						unitId: this.unit.id,
						emissionSourceId: this.selectedEmissionSource,
						description: this.description,
						scope: this.scope,
						isSpendBased: this.isSpendBased,
						ghgSubcategoryId: this.ghgCategory.id
					};
					await this.$emit('addDataType', emitObject);
				}catch(err){
					console.error(err);
				} 
			},


			/**
			 * @desc Returns the camel case of the given string
			 * @param {String} str - to manipulate into camel case. 
			 * @return {String} camelCaseStringExample
			 */
			camelCase(str) {
				//The first .replace will remove all non alpha numeric characters. The last replaces will remove all whitepace between words.
				return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, '');
			},
		}
	}
</script>

<style lang="less" scoped>
.add-emission-factor-form {
	display: flex;
	flex-direction: column;
	gap: 1em;

	.emission-source-unit {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

}
</style>
