<template>
    <div class="report-export-container">
        <h2 class="report-heading">Emissions Snapshot</h2>

        <span v-if="this.flags['FLAG_4650_OID'] && subscription == 'Pro'">
            As a pro user, please ensure you have organisation intensity denominator's set to utilize the reports functionality fully.
        </span>

        <!-- Body of the modal -->
        <q-form class="export-form q-mt-md" @submit="generateEmissionSnapshot">
            <p class="emissions-heading text-bold">Date Range (inclusive)</p>
            
            <div class="date-container">
                <div class="date-field-container">
                    <span class="report-text report-label">From</span>
                        <q-field class="date-field" dense>
                            <q-input class="date-input" hide-bottom-space v-model="startDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" 
                                :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
                                :placeholder="dateFormat">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                            <q-date v-model="startDate" :mask="dateFormat">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </q-field>
                </div>

                <div class="date-field-container">
                    <span class="report-text report-label">To</span>
                        <q-field class="date-field" dense>
                            <q-input class="date-input" hide-bottom-space v-model="endDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" 
                                :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
                                :placeholder="dateFormat">
                                <template v-slot:append>
                                    <q-icon name="event" class="cursor-pointer">
                                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                            <q-date v-model="endDate" :mask="dateFormat" :options="validEndDate">
                                                <div class="row items-center justify-end">
                                                    <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                                                </div>
                                            </q-date>
                                        </q-popup-proxy>
                                    </q-icon>
                                </template>
                            </q-input>
                        </q-field>
                </div>
            </div>

            <div class="report-export-group">
                <p class="emissions-heading text-bold">
                    Customise By 
                    <q-btn flat round dense icon="help" color="primary" class="q-mb-xs" @click="(showCustomHelp = true)" />
                </p>          
                <div class="report-export-item q-mb-md">
                    <q-btn-toggle style="border-radius: 8px" no-caps v-model="customSelection" :options=toggleOptions />                    
                </div>

                <div class="report-export-item">
                    <span class="report-text report-label">Top</span>
                    <q-field class="report-field" dense>
                        <q-input :disable="selectAll" input-class="text-center" v-model="numEntities" type="number" filled min="1" style="max-width: 100px;" />
                    </q-field>
                    <span class="report-text"> {{ customSelection == 'site' ?  "Site(s)" : "Organisation(s)" }}</span>
                </div>

                
                <div class="report-export-item q-mb-md">
                    <span class="report-label q-pb-none q-mb-none"></span>
                    <q-checkbox v-model="selectAll" label="Select all" color="primary" />
                </div>

                <div class="report-export-item q-mt-lg">
                    <span class="report-text report-label">Top</span>
                    <q-field class="report-field" dense>
                        <q-input input-class="text-center" v-model="numEmissions" type="number" filled min="0" max="100" style="max-width: 100px;" />
                    </q-field>
                    <span class="report-text">Emission Source(s)</span>
                </div>

                <div class="report-export-item" style="margin: 10px 0px 10px 0px;">
                    <span class="report-label"></span>
                    <span class="report-text text-center" style="width: 100%">
                        OR
                    </span>
                </div>

                <div class="report-export-item q-mb-md">
                    <span class="report-text report-label">All sources over</span>
                    <q-field class="report-field" dense>
                        <q-input input-class="text-center" v-model="emissionPercentOver" type="number" filled min="0" max="100" style="max-width: 100px;" />
                    </q-field>
                    <span class="report-text">%</span>
                </div>

            </div>


            <div v-if="flags['FLAG_4108_DATA_TAGS']" class="report-export-item" style="margin: 10px 0px 10px 0px;">
                <q-select 
                    :loading="loadingTags"
                    filled
                    label="Tags"
                    input-class="text-center"
                    v-model="selectedTags"
                    :options="tagOptions"
                    multiple
                    style="max-width: 300px;"
                />
            </div>

            <q-btn ref="generate-btn" class="generate-button" no-caps label="Generate Report" type="submit" color="secondary" icon-right="description"
                :loading="loading" :disable="loading" />

            <q-btn ref="clear-btn" flat align="left" class="clear-all-button" no-caps label="Clear Inputs" color="primary" @click="clearInputs"/>

        </q-form>

        <q-dialog v-model="showCustomHelp">
            <q-card style="padding: 25px;">
                <p>For the Emissions Snapshot report, you have the ability to generate the reports based on two factors: Sites or Organisations.</p>
                <p>Selecting 'Sites' will generate the Emission Snapshot report for the sites and their emission data for the current organisation (the organisation you are logged into). It will not include any emission data from your subsidary organisations.</p>
                <p>Selecting 'Organisations' will generate the Emission Snapshot report for your current organisations and its subsidary organisations. It will be a high level overview, and will not distinguish between the sites of those organisations.</p>

                You can choose to select all sites or organisations, or you can limit the number to the top contributor(s). Be aware, that when selecting the top organisations, your current organisation will not always be included in the reports if it is not a top contributor. If you want to ensure that
                your current organisation will be included, choose 'Select All'.
            </q-card>
        </q-dialog>
    </div>
</template>

<script>

import { useEmissionSnapshotStore } from '@/stores/audit.store';
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import getSub from "@/services/util/subscription.js";
import dayjs from "@/services/util/dayjs";

const EmissionSnapshotExportComponent = {

    props: ['organisations'],

    data() {
        return {
            dayjs: dayjs,
            dateFormat: "DD/MM/YYYY",

            customSelection: 'site',

            toggleOptions: [
                {label: 'Sites', value: 'site'},
                {label: 'Organisations', value: 'org'}
            ],

            tagOptions: [],
            selectedTags: [],

            showCustomHelp: false,

            startDate: "",
            endDate: "",
            numEntities: 5,
            numEmissions: 5,
            emissionPercentOver: 0,

            selectAll: false,
            search: "",
            loading: false,
            loadingTags: false,

            emissionFilters: useEmissionSnapshotStore(),

            subscription: getSub(),
        }
    },

    created() {
        if(this.flags['FLAG_4108_DATA_TAGS']){
            this.getTagNames();
        }

        this.endDate = dayjs().format(this.dateFormat);
    },

    watch: {
        /**
         * If numEmissions or emissionPercentOver is greater than 0, set the other variable to 0
         */
        numEmissions() {
            if (this.numEmissions > 0) {
                this.emissionPercentOver = 0
            }
        },
        emissionPercentOver() {
            if (this.emissionPercentOver > 0) {
                this.numEmissions = 0
            }
        },

        startDate(date) {
            // Clears the endDate if selected startDate is after the endDate
            if (dayjs(date, this.dateFormat).isAfter(dayjs(this.endDate, this.dateFormat))) {
                this.endDate = "";
            }
        },
    },

    methods: {

        /**
         * @desc Checks if the endDate is the same or after the startDate - endDate cannot be before startDate.
         * @param {String} date selected endDate
         */
         validEndDate(date) {
            return dayjs(date).isSameOrAfter(dayjs(this.startDate, this.dateFormat));
         },


        /**
         * @desc Generate the Emissions snapshot
         */
        generateEmissionSnapshot() {

            let basedOn = this.customSelection

            this.emissionFilters.updateEmissionDates(
                dayjs(this.startDate, this.dateFormat).toISOString(),
                dayjs(this.endDate, this.dateFormat).toISOString()
            )

            this.emissionFilters.updateEmissionFilters(
                this.numEntities,
                this.numEmissions,
                this.emissionPercentOver,
                this.selectAll,
                this.selectedTags,
            );

            // The basedOn query determines whether we are generating the report for sites or organisations.
            this.$router.push({
                name: 'emissionSnapshot', query: { basedOn }
            })

        },

        /**
         * Gets all tags within an organisation and puts the names into an array
         */
        async getTagNames() {
            this.loadingTags = true;
            await api.tags.getTags()
            .then((res) => {
                let retrievedTagNames = [];
                for(let tagData of res.data){
                    retrievedTagNames.push(tagData.name);
                }
                this.tagOptions = retrievedTagNames
            })
            .catch((err) => notify.withObject(err.response), "top", "Failed to retrieve tags list.")
            .finally(() => this.loadingTags = false);
        },

        clearInputs() {
            this.startDate = ""
            this.endDate = ""
            this.numEntities = ""
            this.numEmissions = ""
            this.emissionPercentOver = 0
            this.selectAll = false
            this.selectedTags = [];
        }
    },
};

export default EmissionSnapshotExportComponent;
</script>

<style lang="less" src="../../assets/styles/reports.less" />
