// convertEmissionURL.js

const emissionURLs = 
    {
        "air-travel": "AirTravel",
        "custom": "CustomDatasetDefintions",
        "electricity": "Electricity",
        "fertiliser": "Fertiliser",
        "freight": "Freight",
        "livestock": "Livestock",
        "stationary-fuel": "StationaryFuels",
        "transport-fuel": "TransportFuels",
        "waste": "Waste",
        "water": "Water"
    }

/**
 * @desc Given the URL string for an emission source, return the emission source table name
 * @params {string} emissionURl The emission URL
 * @returns {string} The equivalent table name for the given emission URL.
 */
export default (emissionURL) => {

    var emissionTableName = ""

    try {
        emissionTableName = emissionURLs[emissionURL]
    } catch {
        console.error("Couldn't identify emission with provided URL")
    }

    return emissionTableName ? emissionTableName : emissionURL
}