/**
 * customDatasets.js
 * 
 * API for handling getting all custom dataset API routes.
 */

import axiosInstance from "./axios-instance"

/**
 * @desc Creates a new custom dataset
 * 
 * @param {Object} payload information of newly created custom dataset
 * @returns {Promise<AxiosResponse<any>>}
 */
const postCustomDataset = async function (payload) {
    return await axiosInstance.post("/api/custom-datasets", payload).then(res => res);
}


/**
 * @desc Gets all custom dataset definitions
 * 
 * @param {String} filters filters to apply to list of custom dataset
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDatasets = async function (filters="") {
    return await axiosInstance.get(`/api/custom-datasets${filters}`).then(res => res);
}


/**
 * @desc Gets data from a single custom dataset
 * 
 * @param {Number} datasetId id of custom dataset to retrieve data from
 * @param {Number} siteId site to retrieve data for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDatasetData = async function (datasetId, siteId) {
    return await axiosInstance.get(`/api/custom-datasets/${datasetId}`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Gets insights from a single custom dataset
 * 
 * @param {Number} datasetId id of custom dataset to retrieve insights from
 * @param {Number} siteId site to retrieve insights for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDatasetInsights = async function (datasetId, siteId) {
    return await axiosInstance.get(`/app/custom-datasets/${datasetId}/insights`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Gets data from a single custom dataset in chart.js format
 * 
 * @param {String} datasetId id of custom dataset to retrieve chart data
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve insights for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDatasetChart = async function (datasetId, granularity, siteId) {
    return await axiosInstance.get(`/app/custom-datasets/chart/${datasetId}/${granularity}`, {params: {siteId}}).then(res => res);
}


/**
 * @desc ets data from a single custom dataset in csv format
 * 
 * @param {String} datasetId id of custom dataset to retrieve chart data
 * @param {String} params filters
 * @param {String} datasetName name of the dataset to use when saving
 * @param {Number} siteId site to retrieve insights for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDataCSV = async function (datasetId, params, datasetName, siteId) {
    return axiosInstance({
        url: `/app/custom-datasets/csv/${datasetId}`,
        method: 'GET',
        responseType: 'blob',
        params: {params, siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${datasetName}-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Submit a manual CSV upload
 * 
 * @param {File} file CSV file to upload
 * @param {Number} datasetId id of dataset to upload manual data to
 * @returns {Promise<AxiosResponse<any>>}
 */
const submitManualCSVUpload = async function (file, datasetId, siteId) {
    let formData = new FormData();
    formData.append('file', file);
    let headers = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };
    return await axiosInstance.post(`/app/custom-datasets/manual/${datasetId}`, formData, { params: { siteId }}, headers).then(res => res);
}

export default {
    postCustomDataset,
    getCustomDatasets,
    getCustomDatasetData,
    getCustomDatasetInsights,
    getCustomDatasetChart,
    getCustomDataCSV,
    submitManualCSVUpload,
}