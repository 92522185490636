<template>
  <div>
    <div class="organisation-dashboard-sub-title">Compare</div>
    <div class="organisation-comparison-chart-container">
      <div class="organisation-comparison-chart-options">
        <q-select
          multiple
          filled
          dense
          label-color="deep-purple-4"
          padding="10px 5px"
          v-model="selectedOrganisations"
          :options="this.organisations"
          label="Select Organisations"
          style="width: 200px;"
        />

        <q-select
          filled
          dense
          label-color="deep-purple-4"
          v-model="selectedEmissionSource"
          :options="this.sources"
          label="Select Emission Source"
          style="width: 200px"
        />
        <!-- Date Range Selector -->

        <q-input
          v-model="startDate"
          class="date-select shadow-1"
          label="Start Date"
          dense
          standout
          label-color="deep-purple-4"
          mask="date"
        >
          <template v-slot:append>
            <q-icon size="sm" name="event" class="cursor-pointer">
              <q-popup-proxy transition-show="scale" transition-hide="scale">
                <q-date minimal v-model="startDate" mask="YYYY-MM-DD">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Ok"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>

        <q-input
          v-model="endDate"
          hide-bottom-space
          class="date-select shadow-1"
          label="End Date"
          dense
          standout
          label-color="deep-purple-4"
          mask="date"
        >
          <template v-slot:append>
            <q-icon size="sm" name="event" class="cursor-pointer">
              <q-popup-proxy transition-show="scale" transition-hide="scale">
                <q-date minimal v-model="endDate" mask="YYYY-MM-DD">
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Ok"
                      color="primary"
                      flat
                    ></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div class="organisation-comparison-chart">
        <Line v-bind:chartData="chartData" v-bind:chartOptions="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import chartGenerator from "@/components/ChartComponents/chartGenerators/organisationComparisonChart.js";
import Line from "@/components/ChartComponents/Line.vue";
const OrganisationComparison = {
  name: "OrganisationComparison",

  props: ["data", "emissionSources"],

  components: { Line },

  data() {
    return {
      loading: false,

      // Set start date one year ago by default
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        .toISOString()
        .split("T")[0],
      // Set end date as current date by default
      endDate: new Date().toISOString().split("T")[0],

      selectedOrganisations: [],
      selectedEmissionSource: "Total",
    };
  },

  created() {},

  computed: {
    /**
     * @desc Determines name list of organisations
     * @return {Array} names of the organisations
     */
    organisations() {
      return Object.keys(this.data);
    },

    /**
     * @desc determines list of emission source names, also adds "Total" to list
     * @returns {Array} List of emission sources
     */
    sources() {
      let list = [];
      list.push("Total");
      Object.values(this.emissionSources).forEach((source) => {
        list.push(source.sourcedisplayname);
      });
      return list;
    },

    /**
     * @desc calls the chart generator for orgnaisation comparison
     * @return {Object} chart data
     */
    chartData() {
      return chartGenerator.getComparisonChart(
        this.data,
        this.selectedOrganisations,
        {
          startDate: new Date(this.startDate),
          endDate: new Date(this.endDate),
        },
        this.selectedEmissionSource
      );
    },

    /**
     * @desc generates graph options for the site graphs on this report page.
     * The options for these graphs are pretty specific to this report, so this method is just
     * housed in this component.
     *
     * @param {String} site determines graph title.
     * @returns {Object} Chart options for site graph
     */
    chartOptions() {
      return {
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Kg CO2e",
            },
          },
          x: {
            grid: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        },

        plugins: {
			legend:{
				display: true,
				// On legend click do nothing (i.e. don't hide line)
				onClick: () => {},
			},
			title:{
                display: true,
                text: this.selectedEmissionSource + " CO2e"
			},
          datalabels: {
            display: false,
          },
        },
        elements: {
        point: {
          pointHoverBorderWidth: 10,
        },
        line: {
          cubicInterpolationMode: "monotone",
        },
      },
      };
    },
  },

  methods: {},
};

export default OrganisationComparison;
</script>

<style lang="less">
@import "@/assets/styles/organisationDashboard.less";
</style>
