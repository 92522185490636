<template>
    <ChartOptionsListContainerComponent v-if="targetStore.showOptions" 
    :style="{'margin-left': targetStore.chartMetaData.x  + 'px', 'margin-top': targetStore.chartMetaData.y + 'px'}"
    class="chart-options-list-container"
    />

    <Line :options="chartOptions" :data="chartData" />
</template>

<script>
import {useTargetStore} from "../../stores/target.store"
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, LineElement, PointElement, } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartOptionsListContainerComponent from '@/components/ChartComponents/ChartOptionsListComponents/ChartOptionsListContainerComponent'
// import chartValidator from './validation'

ChartJS.register(Title, Tooltip, Legend, PointElement, CategoryScale, LinearScale, LineElement, annotationPlugin)

/**
* Exports a configured chart.js Pie chart 
*/
export default {
  name: 'LineChart',
  components: { Line, ChartOptionsListContainerComponent },
  props: {
    chartData: {
      type: Object,
      required: true,

      // validator(data) {
      //   return chartValidator(data)
      // } 
    },
    chartOptions: {
      type: Object,
      required: false
    },
	conversionOptions: {
		type: Object,
		required: false
	},
  },
  data(){
    return { 
      //Chart data should be in its own store or something so it can be used across all charts
      targetStore: useTargetStore()
    }
  },

  beforeUnmount() {
    this.targetStore.showOptions = false;
  },  

}
</script>

<style>
    @import "../../assets/styles/chart.css";
</style>
