<template>
  <div class="report-export-container">
    <h2 class="report-heading">Emission Factors</h2>
    <q-form class="export-form">
      <!-- Dates -->
      <div class="date-container">
        <!-- Start date -->
        <div class="date-field-container">
          <span class="date-span">From</span>
          <q-field class="date-field" dense>
            <q-input class="date-input" hide-bottom-space v-model="startDate" :mask="dateFormat.replace(/[a-z]/gi, '#')" :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
              :placeholder="dateFormat">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="startDate" :mask="dateFormat">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-field>
        </div>

        <!-- End date -->
        <div class="date-field-container">
          <span class="date-span">To</span>
          <q-field class="date-field" dense>
            <q-input class="date-input" hide-bottom-space v-model="endDate" :mask="dateFormat.replace(/[a-z]/gi, '#')"  :rules="[(val) => dayjs(val, dateFormat, true).isValid()]"
              :placeholder="dateFormat">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="endDate" :options="(date) => dayjs(date).isSameOrAfter(dayjs(this.startDate, this.dateFormat))" :mask="dateFormat">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </q-field>
        </div>
      </div>

      <q-btn class="generate-button" icon-right="ios_share" label="Export Report" color="secondary" :disable="!startDate || !endDate" :loading="loading" no-caps @click="generateEmissionFactorSheet" />
    </q-form>
  </div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';
import dayjs from "@/services/util/dayjs";
import { convertDateToISO } from '@/services/util/dates';

export default {
  data() {
    return {
      dayjs: dayjs,
      dateFormat: "DD/MM/YYYY",
      startDate: "",
      endDate: "",
      loading: false,
    }
  },
  watch: {
    startDate(date) {
      // Clears the endDate if selected startDate is after the endDate
      if (dayjs(date, this.dateFormat).isAfter(dayjs(this.endDate, this.dateFormat))) {
        this.endDate = "";
      }
    },
  },
  methods: {
    async generateEmissionFactorSheet() {
      this.loading = true;
      const reqBody = {
        "startDate": convertDateToISO(this.startDate),
        "endDate": convertDateToISO(this.endDate),
      };

      try {
        await api.reports.getEmissionFactorsReport(reqBody);
        notify.primary("Emission Factors Report successfuly generated", 'top', "Report Generated");
        this.$emit('close');
      } catch (err) {
        notify.withObject(err, 'top', "Unable to Generate Report");
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>