<template>
  <div class="container">
    <h2 class="heading">Edit Site</h2>
    <div class="form">
      <q-form @submit.prevent="editSite()">
        <div class="inputs">
          <q-input
            stack-label
            outlined
            bg-color="white"
            v-model="siteInfo.name"
            label="Site Name"
            required
            maxlength="50"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The name of your site.
                </q-tooltip>
              </q-icon>
            </template>
          </q-input>

          <q-input
            stack-label
            outlined
            type="number"
            bg-color="white"
            use-input
            step="any"
            hide-dropdown-icon
            v-model.number="siteInfo.FTE"
            label="FTE employees"
            @new-value="newCategory"
            required
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The equivalent number of full time employees currently
                  employed at this new site.
                </q-tooltip>
              </q-icon>
            </template>
          </q-input>
          
          <q-select
            stack-label
            outlined
            bg-color="white"
            use-input
            fill-input
            hide-selected
            input-debounce="0"
            hide-dropdown-icon
            v-model="siteInfo.categoryDetail"
            label="Category"
            :options="siteCategoryOptions"
            @filter="filterCategories"
            @input-value="(val) => siteInfo.categoryDetail = val.trim()"
            required
            :rules="[(category) => category?.trim() !== '' || 'Please enter a category']"
            maxlength="50"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The category of your site.
                </q-tooltip>
              </q-icon>
            </template>
          </q-select>

          <AddressInput v-model="address" tool-tip="The address of your site" />

          <q-select
            stack-label
            outlined
            bg-color="white"
            multiple
            use-chips
            :loading="sourcesLoading"
            :disable="sourcesLoading"
            v-model="sources"
            label="Emission Sources"
            :popup-content-style="{ height: '30vh' }"
            :options="getSourceDisplayNames"
            :rules="[
              (val) =>
                val.length > 0 ||
                'Please select at least 1 (one) emission source',
            ]"
          >
            <template v-slot:after>
              <q-icon name="help" color="grey" size="sm">
                <q-tooltip style="font-size: 1rem">
                  The emission sources that you wish to track for this site.
                </q-tooltip>
              </q-icon>
            </template>
          </q-select>

          <template
            v-if="
              flags.FLAG_4253_CID &&
              subPermissions.customIntensityDenominators.includes(subscription)
            "
          >
            <div class="column">
              <span class="sub-heading"> Custom Intensity Denominators </span>
            </div>

            <template v-if="customDenomLoading">
              <div style="width: 100%; display: flex; justify-content: center">
                <q-spinner color="primary" size="4em" />
              </div>
            </template>

            <template v-else>
              <q-input
                v-for="denom in denominators"
                :key="denom.uuid"
                stack-label
                outlined
                type="number"
                bg-color="white"
                use-input
                step="any"
                hide-dropdown-icon
                v-model.number="denom.value"
                :label="denom.displayName"
                :rules="[
                  (val) =>
                    !Number.isNaN(parseFloat(val)) || 'Value must be a number',
                  (val) => val > 0 || 'Value must be greater than 0.',
                ]"
              >
              </q-input>

              <div style="width: 100%; display: flex; justify-content: center">
                <span v-if="denominators.length === 0">
                  <q-icon name="warning" size="md" /> No Custom Denominators
                  Defined
                </span>
              </div>
            </template>
          </template>

          <q-btn
            class="button"
            :disable="submitLoading"
            :loading="submitLoading"
            square
            no-caps
            padding="8px 24px"
            color="blue-4"
            label="Update"
            type="submit"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions.js";

import { useSiteStateStore } from "@/stores/siteState.store";
import AddressInput from "../../FormComponents/AddressInput.vue";

const CreateSiteComponent = {
  name: "Sites",
  props: ["site"],
  emits: ["close"],
  components: {
    AddressInput,
  },
  data() {
    return {
      siteStore: useSiteStateStore(),
      subscription: getSub(),
      subPermissions: subPermissions,
      sourcesLoading: false,
      customDenomLoading: false,
      submitLoading: false,

      siteInfo: JSON.parse(JSON.stringify(this.site)),

      siteCategories: [],
      emissionSources: [],

      siteCategoryOptions: [],

      sources: [],

      // Address Information
      addresses: [],
      address: {},
      findingAddresses: false,

      // Custom Denominators
      denominators: [],
    };
  },

  created() {
    this.init();
    this.address = {
      number: this.siteInfo.addressNumber,
      unit: this.siteInfo.addressUnit,
      street: this.siteInfo.addressStreet,
      suburb: this.siteInfo.addressSuburb,
      POBox: this.siteInfo.addressPOBox,
      city: this.siteInfo.addressCity,
      postcode: this.siteInfo.addressPostCode,
      country: this.siteInfo.addressCountry,
    };
  },

  computed: {
    getSourceDisplayNames() {
      return this.emissionSources.map(function (item) {
        return item.sourcedisplayname;
      });
    },
  },

  methods: {
    /**
     * @desc Posts new site data to server based on info filled out in form.
     */
    async editSite() {
      this.submitLoading = true;

      /* We need to set siteInfo.category to the string representation of the category detail name
			selected by the user, as this is what the site patch route takes when updating a site's category. */
      this.siteInfo.category = this.siteInfo.categoryDetail;
      // The patch route takes the fte lowercase, so we also need to set that.
      this.siteInfo.fte = this.siteInfo.FTE;

      this.siteInfo.addressNumber = this.address.number;
      this.siteInfo.addressUnit = this.address.unit;
      this.siteInfo.addressStreet = this.address.street;
      this.siteInfo.addressSuburb = this.address.suburb;
      this.siteInfo.addressPOBox = this.address.POBox;
      this.siteInfo.addressCity = this.address.city;
      this.siteInfo.addressCountry = this.address.country;
      this.siteInfo.addressPostCode = this.address.postcode;

      // Update Site
      try {
        await api.sites.patchSite(this.siteInfo.id, this.siteInfo);
      } catch (err) {
        notify.withObject(err.response);
        this.submitLoading = false;
        return;
      }

      // Update Site emission sources
      let emissionSourceIds = this.getEmissionSourceIDs();
      try {
        await api.sites.patchSiteEmissionSources(
          this.siteInfo.id,
          emissionSourceIds
        );
      } catch (err) {
        notify.error(
          `Updated ${this.siteInfo.name} details, but failed to update ${this.siteInfo.name} emission sources`,
          "top"
        );
      }

      if (
        this.flags.FLAG_4253_CID &&
        this.subPermissions.customIntensityDenominators.includes(
          this.subscription
        )
      ) {
        // Update CIDS
        try {
          const CIDS = {};
          for (const denom of this.denominators) {
            CIDS[denom.uuid] = denom.value;
          }

          await api.cid.editSiteCIDs(this.siteInfo.id, CIDS);
        } catch (err) {
          notify.error(
            "Unable to update custom intensity denominators for this site."
          );
        }
      }

      notify.primary(`Successfully updated site ${this.siteInfo.name}`, "top");
      this.submitLoading = false;

      // Update emission sources if updated siteId matches currently selected siteId or is All Sites
      if (
        this.siteInfo.id === this.siteStore.siteId ||
        this.siteStore.siteId === null
      ) {
        this.siteStore.getSiteList();
      }

      this.$emit("close");
    },

    /**
     * @desc Loads all of the site categories that currently exist.
     */
    async init() {
      this.sourcesLoading = true;
      this.customDenomLoading = true;

      api.sites
        .getSiteCategories()
        .then((res) =>
          res.data.forEach((category) => {
            this.siteCategories.push(category.categoryDetail);
            this.siteCategoryOptions.push(category.categoryDetail);
          })
        );

      api.data
        .getEmissionSources()
        .then((res) => (this.emissionSources = res.data));

      api.sites
        .getSiteEmissionSources(this.siteInfo.id)
        .then((res) =>
          res.data.emissionSources.map((emission) =>
            this.sources.push(emission.sourcedisplayname)
          )
        )
        .finally(() => (this.sourcesLoading = false));

      if (
        this.flags.FLAG_4253_CID &&
        this.subPermissions.customIntensityDenominators.includes(
          this.subscription
        )
      ) {
        api.cid
          .getCIDBySite(this.siteInfo.id)
          .then((res) => {
            this.denominators = res.data.sort(
              (a, b) => a.displayName > b.displayName
            );
          })
          .finally(() => (this.customDenomLoading = false));
      }
    },

    /**
     * @desc Adds new site category to siteCategories
     */
    newCategory(val, done) {
      if (!this.siteCategories.includes(val)) {
        this.siteCategories.push(val);
        done(val, "add-unique");
      }
    },

    /**
     * @desc This method will determine the IDs of the selected emission
     * sources in this.sources, and return an array of objects with the attribute emissionSourceId: <id>
     *
     * @returns array of objects with the attribute emissionSourceId: <id>
     */
    getEmissionSourceIDs() {
      let ids = [];
      this.sources.forEach((selectedSource) => {
        this.emissionSources.forEach((source) => {
          if (source.sourcedisplayname == selectedSource) {
            ids.push({ emissionSourceId: source.emissionSourceId });
          }
        });
      });
      return ids;
    },

    filterCategories(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.siteCategoryOptions = this.siteCategories.filter((category) => category.toLowerCase().includes(needle));
      })
    },
  },
};

export default CreateSiteComponent;
</script>
<style lang="less" src="@/assets/styles/siteModal.less" scoped />
