import { defineStore } from 'pinia'
import { ref } from "vue";
import api from "@/services/api/api";


export const useSignupStore = defineStore('signup', () => {
  const stripePayLoading = ref(false)
  const signupType = ref("")

  const details = ref({
    businessName: "",
    primaryIndustry: "",
    employeeCount: ""
  })

  const successHash = ref("")

  const needs = ref({
    0: false,
    1: false,
    2: false,
    3: false
  })

  const pricing = ref({
    selectedPlanDetails: ""
  })

  const sectionsComplete = ref({
    1: false,
    2: false,
    3: false,
  })

  const user = ref({
    email: "",
    password: "",
  })

  const company = ref({
    companyName: "",
    address: {},
    numSites: "",
    primaryIndustry: "",
    usages: new Array(),
    subscription: "",
    subscriptionName: "",
    token: "",
    coupon: undefined,
    newsletter: false,
    financialYearEndDate: undefined,
  })

  const subscriptionData = ref({})

  const initSubscriptionData = async function () {
    try {
      var response = await api.users.getSubscriptionInfo();
      [subscriptionData.value.proPlan, subscriptionData.value.smallPlan, subscriptionData.value.basicPlan] = response.data;
    } catch (error) {
      console.error(error);
    }
  }
  const clearUserState = function () {
    user.value = {
      email: "",
      password: "",
    }
  }

  const clearState = function () {
    details.value = {
      businessName: "",
      primaryIndustry: "",
      employeeCount: ""
    }

    successHash.value = ""

    needs.value = {
      0: false,
      1: false,
      2: false,
      3: false
    }

    pricing.value = {
      selectedId: ""
    }

    sectionsComplete.value = {
      1: false,
      2: false,
      3: false,
    }



    company.value = {
      companyName: "",
      address: {},
      numSites: "",
      primaryIndustry: "",
      usages: new Array(),
      subscription: "",
      coupon: undefined,
      newsletter: false,
      financialYearEndDate: undefined,
    }

    subscriptionData.value = {}
  }

  function $reset() {
    clearState();
    clearUserState();
  }

  return {
    details,
    needs,
    pricing,
    sectionsComplete,
    company,
    subscriptionData,
    user,
    successHash,
    signupType,
    initSubscriptionData,
    clearState,
    clearUserState,
    $reset,
    stripePayLoading
  }
},
  {
    share: {
      // Override global config for this store.
      enable: true,
      initialize: true,
    },
  }
)