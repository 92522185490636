<template>
	<div :style="{ 'margin-left': sidebarWidth }">
		<AuditComponent/>
	</div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import AuditComponent from "../components/AuditComponents/AuditComponent.vue";

export default {
	name: "audit-view",
	components: { AuditComponent },
	title: 'audit',
	setup() {
		return { sidebarWidth }
	}
};
</script>
