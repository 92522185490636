<template>
	<div class="intro-component">
		<div class="auth-container" style="min-height: 55vh">
			<keep-alive v-if="$route.params.method=='signup'">
				<SignupComponent/>
			</keep-alive>
			<keep-alive v-else-if="$route.params.method=='login'">
				<LoginComponent/>
			</keep-alive>
			<keep-alive v-else-if="flags.FLAG_4437_FORGOT_PASS && $route.params.method=='forgot-password'">
				<ForgotPasswordComponent/>
			</keep-alive>
		</div>


	</div>

</template>

<script>


import LoginComponent from "../LoginComponent.vue";
import SignupComponent from "../SignupComponent.vue";
import ForgotPasswordComponent from "./ForgotPasswordComponent.vue";
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import router from "@/routes";

const IntroComponent = {

    name: 'intro',
	components: { LoginComponent, SignupComponent, ForgotPasswordComponent },
    data() {

		
        return {
			selectedComponent: 'SignupComponent',
			showLoginComponent: false,
            confirm: false,
            loading: false,

        }
    },

    created() {
        if (this.$route.params.method.toLowerCase() === "signup") {
            this.selectedComponent = "SignupComponent";
        } else if (this.$route.params.method.toLowerCase() === "login") {
            this.selectedComponent = "LoginComponent";
        } else if (this.$route.params.method.toLowerCase() === "forgot-password") {
            this.selectedComponent = "ForgotPasswordComponent";
        } else {
            router.push({path: "/404"})
        }
    },

	methods: {
        
        /**
         * @desc Redirect the user to the next component
         * @param {Object} component the component to direct to.
         */
		nextComponent(component){
			this.selectedComponent = component;
        },


        /**
         * @desc Redirect the user to the signup component
         */
        redirectUser(component) {
            this.showLoginComponent = true;
            this.selectedComponent = component;
            window.scrollTo(0,0);
        },


        /**
        * @desc Redirct on succesful login attempt
        */
        loginSuccess() {
            this.loading = false;
            this.$router.push({ name: "home" });
        },


        /**
        * @desc Attempts to login a user with a demo account. Redirects use to homepage if successful
        */
        demoLogin() {
        this.loading = true;

        api.users
            .loginUserDemo()
            .then((res) => {
            localStorage.setItem("authToken", res.data.authToken);
            this.confirm = false;
            this.loginSuccess();
            })
            .catch((err) => {
            this.loading = false;
            this.confirm = false;
            notify.error(err.response.data ?? err.message, "top");
            });
        },
},



}

export default IntroComponent
</script>

<style scoped>

@import "../../assets/styles/intro.css";

</style>
