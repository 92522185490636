<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <ViewCustomDatasets/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import ViewCustomDatasets from "@/components/DataComponents/CustomDatasetComponents/ViewCustomDatasetsComponent.vue";

export default {
    name: "view-custom-datasets-view",
    components: { ViewCustomDatasets },
    title: 'view-custom-datasets',
    setup() {
        return { sidebarWidth }
    }
};
</script>
