/**
 * API for handling getting all air travel associated data
 */
import axiosInstance from "./axios-instance";

/**
 * @desc Retrieves the id of the current tenant.
 * 
 * @returns {Array<Object>} an array of all the connected organisations to the tenant
 */
const getCurrentTenantId = async () => {
  return await axiosInstance.get("/api/tenant/id").then((res) => res.data);
};



/**
 * @desc Retrieves an array of all the organisations connected to a tenant.
 * 
 * @returns {Array<Object>} an array of all the connected organisations to the tenant
 */
const getAllConnectedOrganisations = async () => {
  return await axiosInstance.get("/api/organisations").then((res) => res.data);
};

/**
 * @desc Retrieves an array of all the data related to a organisation with id.
 * 
 * @param {Number} id sourceTenantId
 * @returns {Array<Object>} an array of data related to organisation
 */
const getOrganisationData = async (id, period = "") => {
  return await axiosInstance.get(`/api/organisations/${id}/emissions${period ? `?period=${period}` : ""}`).then((res) => res.data);
};

 
/**
 * @desc Retrieves all tenant connections for currently logged in user.
 * 
 * @param {String} type determines whether to get connections to or from. Should be one of "source" or
 * "guest"
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getTenantConnections = async function (type) {
    return await axiosInstance.get(`/api/tenant-connections/${type}`).then(res => res);
};

 
/**
 * @desc POSTs a new tenant connection invitation.
 * 
 * @param {Object} payload tenant connection invitation data
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
 const postTenantConnection = async function (payload) {
    return await axiosInstance.post(`/api/tenant-connections`, payload).then(res => res);
};

 
/**
 * @desc Accepts a new tenant connection invitation.
 * 
 * @param {Object} payload req body to pass to API. Should contain inviteHash attribute.
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
 const patchTenantConnection = async function (payload) {
    return await axiosInstance.patch(`/api/tenant-connections`, payload).then(res => res);
 };

 
/**
 * @desc DELETEs a tenant connection.
 * 
 * @param {String} id ID of tenant connection to attempt to delete
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
 const deleteTenantConnection = async function (id) {
    return await axiosInstance.delete(`/api/tenant-connections/${id}`).then(res => res);
};


/**
 * @desc Gets a tenant signin token.
 * 
 * @param {String} id ID of tenant connection to attempt to view
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getGuestToken = async function (id) {
  return await axiosInstance.get(`/auth/login/guest/${id}`).then(res => res);
};


export default {
    getTenantConnections,
    postTenantConnection,
    patchTenantConnection,
    deleteTenantConnection,
    getAllConnectedOrganisations,
    getOrganisationData,
    getGuestToken,
    getCurrentTenantId
}