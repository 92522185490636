import { defineStore } from 'pinia'

export const useFileUploadStore = defineStore('fileUpload', () =>  {
	let manualBulkUploadFile = null;

	function $reset() {
		manualBulkUploadFile = null;
	}

	return {
		manualBulkUploadFile,
		$reset,
	};
});
