<template>
    <div class="freight-api-container">

        <q-icon class="close-modal" size="2rem" name="close" @click="$emit('close')" />

        <div class="form-container">
            <q-form @submit="addDHLConnection">
                <h3 class="connect-form-title">
                    Add Freight DHL Connection
                </h3>
                <p class="freight-form-info">
                    Through providing your account number for DHL freight services, we can set up automatic reporting to ensure your data is added to GreenHalo.
                </p>

                <div class="connect-form-inputs">

                    <div class="connect-form-input">
                        <q-input outlined bg-color="white" v-model="DHLAccountNumber" maxlength="30" @keydown.space.prevent label="DHL Account Number" />
                    </div>
                </div>

                <div class="connect-form-buttons">
                    <q-btn class="submit-new-connection" label="Submit" :disabled="!DHLAccountNumber" type="submit"
                        color="secondary" />
                </div>

                <div class="connect-form-tutorial">
                    <h3 class="connect-form-title">
                        How to find your DHL account number
                    </h3>
                    <p class="freight-form-info">To find your DHL account number, open your DHL invoice PDF or DHL freight CSV report.</p>
                </div>
            </q-form>
        </div>
    </div>
</template>
    
<script>


import api from "@/services/api/api";
import notify from '@/services/util/notify';

const FreightAddDHLComponent = {
    name: "FreightAddDHLComponent",

    props: ["siteId"],

    data() {
        return {

            DHLAccountNumber: '',
            loading: false,
        };
    },

    created() {

    },

    methods: {

        /**
         * @desc Adds the credentials for an DHL connection. Will be added to database as 'pending' (needs to be validated)
         */
        addDHLConnection() {

            this.loading = true;

            const postBody = { accountNumber: this.DHLAccountNumber, siteId: this.siteId };

            api.freight.addFreightConnection("DHL", postBody).then(() => {
                notify.primary("Successfully added DHL account number", 'top');
                this.$emit("getFreightConnections")
            }).catch((err) => {
                notify.withObject(err.response)
            })
            .finally(() => {
                this.DHLAccountNumber = '';
                this.loading = false;
            });
        }
    }
};

export default FreightAddDHLComponent;
</script>
    
<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>