<template>
	<div class="datatype-manual-upload-container">
		<h2 class="tab-section-heading">Manual Upload</h2>
		<p>
			You can upload your {{ definition.datasetName }} data to GreenHalo
			manually with a CSV import. Please ensure that the
			<span class="text-primary">header rows of your CSV file</span>
			contains the following attributes
			<span class="text-primary">(case sensitive)</span>:
		</p>

		<div class="datatype-manual-upload-container">
			<q-table
				class="manual-upload-table"
				:columns="headers"
				:rows="rows"
				hide-bottom
			>
			</q-table>

			<!-- FIXME: No Download Example for Custom Emissions	 -->
			<!-- <div class="manual-button-center">
				<q-btn
					flat
					no-caps
					label="Download example"
					class="download-example-btn"
					color="green"
					icon-right="download"
					@click="downloadExample"
				/>
			</div> -->

			<span style="display: inline-block; margin-top: 2em">Upload your CSV file below</span>

			<div class="upload-manual-csv">
				<q-file
					class="upload-files justify-center items-center"
					v-model="file"
					type="file"
					ref="file"
					label="Drag and drop files, or Browse"
					:input-style="{ height: '230px' }"
					:item-aligned="true"
				>
					<template
						v-if="file"
						v-slot:prepend
					>
						<q-icon
							name="cancel"
							@click.stop.prevent="file = null"
							class="cursor-pointer"
						/>
					</template>
				</q-file>

				<div class="manual-button-center">
					<q-btn
						class="file-input-button"
						no-caps
						color="green"
						:disabled="this.file == null || !siteId"
						@click="submitCustomDatasetCSV"
						label="Upload"
						icon-right="upload"
						:loading="loading"
					/>
					<q-tooltip v-if="this.file == null || !siteId">Please upload a file and select a site</q-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useSiteStateStore } from "@/stores/siteState.store";

import api from "../../../services/api/api";
import notify from "@/services/util/notify";
const CustomDatasetManualUploadComponent = {
	name: "CustomDatasetManualUpload",
	props: ["definition"],

	data() {
		return {
			file: null,
			loading: false,
			csvUploading: false,

			headers: [
				{ label: "timestamp", field: "timestamp" },
				{ label: "amount", field: "amount" },
			],

			rows: [
				{
					timestamp: "Format: YYYY-MM-DD",
					amount: "Must be numeric",
				},
			],
		};
	},

	computed: {
		siteId() {
			return useSiteStateStore().siteId;
		},
	},

	created(){
		this.definition.customFields.forEach((field) => {
			let newObj = {
				label: field,
				field: field,
			};
			this.headers.push(newObj);
			this.rows[0][field] = "Custom Field"
		});
	},

	methods: {
		/**
		 * @desc Submits the custom dataset CSV file to the server. This method will also set loading variables
		 * to their correct values depending on the response of the server.
		 *
		 * Checks that a file has actually been uploaded before attempting to send
		 * the file to the server.
		 */
		async submitCustomDatasetCSV() {
			if (!this.file) {
				notify.error("Please upload a csv file.", "top");
			} else if (!this.validateFileType(this.file.name)) {
				notify.error("File must have a .csv extension.", "top");
			} else {
				this.csvUploading = true;
				await api.customDatasets
					.submitManualCSVUpload(
						this.file,
						this.definition.id,
						this.siteId
					)
					.then((res) => {
						this.$emit("initialise");
						notify.primary(res.data, "top");
					})
					.catch((err) => {
						notify.error(
							err.response.data ?? "Error saving file.",
							"top"
						);
					})
					.finally(() => (this.csvUploading = false));
			}
		},

		/**
		 * @desc Ensures that a given filename is has a csv extension
		 * @param {String} filename A filename that is checked for csv type.
		 * @returns {Boolean} true if the filename has a csv extension, false if it doesn't
		 */
		validateFileType(filename) {
			// slice(-1) retrieves the last item of the array
			let extension = filename.split(".").slice(-1)[0];
			if (extension === "csv") {
				return true;
			} else {
				return false;
			}
		},
	},
};

export default CustomDatasetManualUploadComponent;
</script>

<style scoped lang="less">
@import "../../../assets/styles/dataManualUploadComponent.less";
</style>
