<template>
    <div :style="{ 'margin-left': sidebarWidth, 'padding': '3em' }">
        <HomeComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import HomeComponent from "@/components/HomeComponent.vue";

export default {
    name: "home-view",
    components: { HomeComponent },
    title: 'Home',
    setup() {
    return { sidebarWidth }
    },
};
</script>


<style >

</style>