<template>
  <Pie :options="chartOptions" :data="chartData" />
</template>

<script>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, ArcElement, CategoryScale, LinearScale } from 'chart.js'
// import chartValidator from './validation'

ChartJS.register(Title, Tooltip, Legend, PointElement, ArcElement, CategoryScale, LinearScale)

export default {
  name: 'PieChart',
  components: { Pie },
  props: {
    chartData: {
      type: Object,
      required: true,

      // validator(data) {
      //   return chartValidator(data)
      // }
    },
    chartOptions: {
      type: Object,
      required: false
    },


    data() {
      return {
      }
    }
  }
}
</script>
