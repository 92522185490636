<template>
    <div class="organisation-dashboard-container">
        <div v-if="loading" class="loading-container">
            <q-spinner class="spinner" color="primary" size="3em" />
        </div>
        <q-select v-if="!loading" class="granularity-select" dense filled v-model="granularity" :options="options" />
		<DashboardSummaryComponent v-if="!loading" :orgData="{organisations, organisationEmissions}" :granularity="granularity"/>

        <div>
            <EmissionContributorsComponent style="height: 50vh;" v-if="!loading" :sourceIds="organisations.map(org => org.sourceTenantId)" :granularity="granularity" :organisations="true"/>
        </div>

        <OrganisationYearOnYear  v-if="!loading" :emissionSources="emissionSources" />
        
        <OrganisationComparison  v-if="!loading" :data="organisationEmissions" :emissionSources="emissionSources"/>
        
	</div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import DashboardSummaryComponent from "./DashboardSummaryComponent.vue";
import OrganisationComparison from "./OrganisationComparisonComponent.vue";
import OrganisationYearOnYear from "./OrganisationYearOnYearComponent.vue";
import EmissionContributorsComponent from "../DashboardComponents/EmissionContributorsComponent.vue";

const DashboardComponent = {
	name: "Dashboard",
    components: {
        DashboardSummaryComponent,
        OrganisationComparison,
        OrganisationYearOnYear,
        EmissionContributorsComponent,
    },

	data() {
		return {
			loading: false,

            tenantId: undefined,
            organisations: [],
            organisationEmissions: {},

            granularity: "Monthly",
            options: ["Monthly", "Yearly"],

            emissionSources: [],

		};
	},

	async created() {
        await this.init();
	},

    methods: {
        /**
         * @desc Initialises page with required data.
         * Retrieves organisations currently connected as source tenants to current tenant.
         * Retrieves all emissions grouped by source and month (timestamp format should be yyyy-mm)
         */
        async init() {
        this.loading = true;

        // First we need to retrieve all the organisations that are currently connected to the current user as guests (techinaclly source tenants)
        // We only add them to this.organisations if their status is active
        try {
            let res = await api.tenantConnections.getTenantConnections("guest");
            for(let org of res.data){
                if(org.status){
                    this.organisations.push(org);
                }
            }
        } catch (err) {
            notify.withObject(err.response);
        }

        let emissionSources = await api.data.getEmissionSources();
        this.emissionSources = emissionSources.data;

        // Now we retrieve each organisation's emissions data.
        for (let organisation of this.organisations) {
            this.tenantId = organisation.guestTenantId;
            let res = await api.reports.getSubsidiaryEmissionData(
                organisation.sourceTenantId
            );
            this.organisationEmissions[organisation.sourceTenantName] = res.data;
        }

        this.loading = false;
        },
    }
};

export default DashboardComponent;
</script>

<style lang="less" src="@/assets/styles/organisationDashboard.less" />
