<template>
	<div class="main-tab-container">
		<div class="header">
			<h1 class="main-heading">{{ datasetDefinition.datasetName }}</h1>
		</div>
		<div v-if="definitionLoaded" class="tabs-container">
			<q-tabs v-model="tab" active-color="primary"
        indicator-color="success" align="left" narrow-indicator dense no-caps>
				<q-tab name="trends" label="Trends" />
				<q-tab name="targets" label="Targets" />
				<q-tab name="files" :label="`Documents`" />
				<q-tab name="upload" :label="`Manual Upload`" />
			</q-tabs>
			<q-separator color="grey" />
		</div>
		<div v-if="!loading && tab == 'trends'">
			<div class="data-dashboard-container">
				<div class="data-page-title">
					<h2 class="tab-section-heading">Trends</h2>
				</div>

			<InsightsContainerComponent
						v-if="!loading"
						:emissionSource="'custom'"
						:customDatasetId="datasetDefinition.id"
						:insights="insights"/>

			</div>

			<div class="data-dashboard-chart-container">
				<div class="chart-container">
					<div class="chart-button-container">
						<div class="chart-button-item">

							<q-btn-toggle  filled color="grey-6" label="Toggle View" v-model="this.view" toggle-color="primary" no-caps
								:options="[
								{ label: 'Default', value: 'default' },
								{ label: 'Carbon', value: 'carbon' },
								]" />
						</div>

						<q-btn-dropdown class="dropdown"  no-caps :label="this.displayGraphType + ' Graph'" color="grey-6" text-color="white">
							<q-list>
								<q-item clickable v-close-popup @click="this.chartType = 'line'">
									<q-item-section>
										<q-item-label>Line Graph</q-item-label>
									</q-item-section>
								</q-item>

								<q-item clickable v-close-popup @click="this.chartType = 'bar'">
									<q-item-section>
										<q-item-label>Bar Graph</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>
						</q-btn-dropdown>

						<q-btn-dropdown class="dropdown"  no-caps :label="displayGranularity" color="grey-6" text-color="white">
							<q-list>
								<q-item clickable v-close-popup @click="granularity = 'day'; displayGranularity = 'Daily'">
									<q-item-section>
										<q-item-label>Daily</q-item-label>
									</q-item-section>
								</q-item>

								<q-item clickable v-close-popup @click="granularity = 'week'; displayGranularity = 'Weekly'">
									<q-item-section>
										<q-item-label>Weekly</q-item-label>
									</q-item-section>
								</q-item>

								<q-item clickable v-close-popup @click="granularity = 'month'; displayGranularity = 'Monthly'">
									<q-item-section>
										<q-item-label>Monthly</q-item-label>
									</q-item-section>
								</q-item>

								<q-item clickable v-close-popup @click="granularity = 'year'; displayGranularity = 'Yearly'">
									<q-item-section>
										<q-item-label>Yearly</q-item-label>
									</q-item-section>
								</q-item>
							</q-list>
						</q-btn-dropdown>

						<q-space />
						<div class="chart-button-item">
							<q-spinner color="primary" size="2em" v-if="loadingChart"/>
						</div>
						<q-space />


						<div class="chart-actions">
							<q-btn v-if="permissions.csvExport.includes(this.userRole)" @click="getCSVExport" color="primary" no-caps icon="ios_share" label="Export"/>		
						</div>
					</div>

					<div v-if="view === 'default'">
						<Bar v-if="this.chartType == 'bar' && chartLoaded" v-bind:chartData="formattedChartData"
							v-bind:chartOptions="barChartjsOptions" :styles="{ height: '600px' }" />

						<Line v-if="this.chartType == 'line' && chartLoaded" v-bind:chartData="formattedChartData"
							v-bind:chartOptions="lineChartjsOptions" :styles="{ height: '600px' }" />
					</div>
					<div v-if="view === 'carbon'">
						<Bar v-if="this.chartType == 'bar' && chartLoaded" v-bind:chartData="carbonChartData"
							v-bind:chartOptions="carbonBarOptions" :styles="{ height: '600px' }" />

						<Line v-if="this.chartType == 'line' && chartLoaded" v-bind:chartData="carbonChartData"
							v-bind:chartOptions="carbonLineOptions" :styles="{ height: '600px' }" />
					</div>
				</div>
			</div>
		</div>
		<ViewGoalsComponent v-if="tab == 'targets'" :emissionSource="datasetDefinition.datasetName"/>
		<CustomDatasetDataUpload v-if="tab == 'upload'" :definition="datasetDefinition" @initialise="initialise()" />
		<DocumentsTabComponent v-if="tab == 'files'" :customDatasetId="datasetDefinition.id" :type="datasetDefinition.datasetName" :custom="true" :files="files" @onInitialize="getCustomFiles()" />
		
	</div>
</template>

<script>
import { useChartStateStore } from '@/stores/chartState.store';
import { useSiteStateStore } from '@/stores/siteState.store';

import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";

import notify from "@/services/util/notify";
import api from "@/services/api/api";
import Bar from "../../ChartComponents/Bar.vue";
import Line from "../../ChartComponents/Line.vue";
import * as lineChartOptions from "../../ChartComponents/ChartOptions/LineOptions.js";
import * as barChartOptions from "../../ChartComponents/ChartOptions/BarOptions.js";
import Chart from "../../ChartComponents/chartGenerators/chart.js";
import carbonChart from "../../ChartComponents/chartGenerators/carbon.charts.js";


import CustomDatasetDataUpload from "./CustomDatasetDataUploadComponent.vue";
import CustomDatasetFiles from "./CustomDatasetFilesComponent.vue";
import InsightsContainerComponent from "../InsightsComponents/InsightsContainerComponent.vue";
import ViewGoalsComponent from '@/components/GoalsComponents/ViewGoalsComponent.vue';
import DocumentsTabComponent from '@/components/DataComponents/DocumentsComponents/DocumentsTabComponent.vue';

const ViewCustomDatasetDataComponent = {
	name: "ViewCustomDatasets",

	components: {
		Line,
		Bar,
		CustomDatasetDataUpload,
		CustomDatasetFiles,
		DocumentsTabComponent,
		InsightsContainerComponent,
		useChartStateStore,
		ViewGoalsComponent
	},

	data() {
		return {
			permissions: permissions,
            userRole: getRole(),

			// Loading vars
			loading: false,
			definitionLoaded: false,
			chartLoaded: false,
			loadingChart: false,

			// Insight vars

			insights: [],


			// Chart vars
			view: "default",
			tab: "trends",
			dateRange: "",
			chartType: "line",
			granularity: "month",
			displayGranularity: 'Monthly',

			carbonChartData: {},
			lineChartOptions: {},
			barChartOptions: {},
			carbonLineOptions: lineChartOptions.getLineOptions(
				"Date",
				"Kg CO₂e",
				"Carbon Emissions (Kg CO₂e)"
			),
			carbonBarOptions: barChartOptions.getBarOptions(
				"Date",
				"Kg CO₂e",
				"Carbon Emissions (Kg CO₂e)"
			),

			// Custom dataset vars
			datasetDefinition: {},

			// Files
			files: [],

			// Chart state store
			store: useChartStateStore(),
		};
	},

	created() {
		this.store.addCustomDatasetId(this.$route.params.datasetId);
		this.initialise();
	},

	watch: {

		granularity() {
			this.store.fetchGraphData("custom", this.granularity);
			this.initialise();
		},

		siteId() {
			this.store.fetchGraphData("custom", this.granularity);
			this.initialise();
		}
	},

	computed: {
		displayGraphType() {
			return this.chartType.charAt(0).toUpperCase() + this.chartType.slice(1);
		},

		formattedChartData() {
			return Chart.generateChartData(this.store.chartData);
		},

		siteId() {
			return useSiteStateStore().siteId
		}
	},

	methods: {

		/**
		 * @desc Gets a CSV export of the dataset on the current page.
		 */
		getCSVExport() {
			api.customDatasets
				.getCustomDataCSV(
					this.$route.params.datasetId,
					"",
					this.datasetDefinition.datasetName,
					this.siteId
				)
				.then(() => {
					notify.primary("Successfully retrieve Waste CSV data", "top")
				})
				.catch((err) => {
					notify.error(err.response.data ?? "Couldn't retrieve Waste CSV data", "top")
				})
		},

		/**
		 * @desc Retrieve only the electricity files
		 */
		getCustomFiles() {
			api.file.getAllCustomFileInformationByType(this.$route.params.datasetId, this.siteId).then((res) => {
				this.files = res.data;
			}).catch(() => {
				notify.error('Could not establish connection to file storage', 'top');
			})
		},


		/**
		 * @desc Initialises all required page data and handles loading vars.
		 */
		initialise() {
			this.loading = true;
			this.chartLoaded = false;
			api.customDatasets
				.getCustomDatasets(`?id=${this.$route.params.datasetId}`)
				.then((res) => {

					this.datasetDefinition = res.data[0];

					let granularity = this.granularity;
					let displayGranularity = this.displayGranularity;
					let datasetId = this.datasetDefinition.id;

					this.lineChartjsOptions = lineChartOptions.getLineOptions(
						"Date",
						this.datasetDefinition.units,
						`${displayGranularity} ${this.datasetDefinition.datasetName} Usage (${this.datasetDefinition.units})`
					);

					this.barChartjsOptions = barChartOptions.getBarOptions(
						"Date",
						this.datasetDefinition.units,
						`${displayGranularity} ${this.datasetDefinition.datasetName} (${this.datasetDefinition.units})`,
						true
					);

					const fetchChartData = function (siteId) {
						return Promise.all([
							api.customDatasets.getCustomDatasetChart(
								datasetId,
								granularity,
								siteId
							),
							api.carbon.getCustomDatasetCarbonConversionChart(
								datasetId,
								granularity,
								siteId
							),
							api.customDatasets.getCustomDatasetInsights(
								datasetId,
								siteId
							),
							api.file.getAllCustomFileInformationByType(datasetId, siteId),
						]);
					};
					
					fetchChartData(this.siteId)
						.then((values) => {
							this.carbonChartData = values[1]["data"];
							this.carbonChartData = carbonChart.generateCustomCarbonLineChart(this.carbonChartData.data, this.carbonChartData.definition, this.granularity)

							this.insights = values[2].data;

							this.files = values[3]["data"];
							this.chartLoaded = true;
						})
						.catch((err) => {
							notify.error(
								err.response.data ??
								"Error connecting to server.",
								"top"
							);
						})
						.finally(() => {
							this.loading = false;
							this.loadingChart = false;
							this.chartLoaded = true;
							this.definitionLoaded = true;
						});
				})
				.catch((err) => {
					let errMessage =
						err.response.data ?? "Error connecting to server.";
					notify.error(errMessage, "top");
				})
		},
	},
};

export default ViewCustomDatasetDataComponent;
</script>

<style scoped lang="less">
@import "../../../assets/styles/data.less";
</style>
