<template>
  <div v-if="flags.FLAG_4437_FORGOT_PASS" class="login">
    <a @click="$router.go(-1)" style="cursor: pointer"><i class="fas fa-arrow-left" /></a>
    <h2 class="portal-title text-center">Forgot Password</h2>
    <p>
      Enter your email address below and you will be sent a link to reset your password.
    </p>
    <q-form class="forgot-form" @submit.prevent="submitForgotPassword">
      <div>
        <span class="input-label">Email</span>
        <q-input
          type="email"
          v-model="email"
          autocomplete
          lazy-rules
          outlined
          required
          no-caps
          :rules="[(val, rules) => rules.email(val) || 'Please enter a valid email address']"
          maxlength="100"
        >
          <template v-slot:prepend>
            <q-icon name="o_email" />
          </template>
        </q-input>
      </div>
      <q-btn rounded label="Send" color="secondary" no-caps :disable="!email" type="submit" :loading="loading" />
    </q-form>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

export default {
  data() {
    return {
      email: "",
      loading: false,
    }
  },
  methods: {
    async submitForgotPassword() {
      this.loading = true;

      try {
        const res = await api.users.forgotPassword(this.email);
        notify.withObject(res, "top", "Forgot Password");
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.forgot-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.bottom-nav-space-apart {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
</style>