<template>
	<div class="create-target-container">
		<div style="padding-top: 2rem; max-width: 230px">
			<q-form @submit.prevent="onSubmit">
				<span class="input-field-label">Carbon Financial Year End Date</span>
 
                <q-input :disable="loading" :loading="loading" class="date-select q-pt-sm" :model-value="formattedDate" @update:model-value="formatDate" 
					hide-bottom-space :error="dateInvalid" error-message="Please input a valid date" dense outlined mask="##/##" placeholder="DD/MM">
					<template v-slot:append>
						<q-icon size="sm" name="event" color="black" class="cursor-pointer">
						<q-popup-proxy transition-show="scale" transition-hide="scale">
							<q-date minimal :model-value="financialYearDate.format('YYYY/MM/DD')" @update:model-value="formatDate" 
									:default-year-month="defaultMonthYear()" :navigation-min-year-month="navigationMin()" :navigation-max-year-month="navigationMax()">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
								</div>
							</q-date>
						</q-popup-proxy>
						</q-icon>
					</template>
                </q-input>

				<q-btn
						class="q-mt-md"
						label="Update"
						color="primary"
						no-caps
						type="submit"
						:loading="submitting"
						/>
			</q-form>
		</div>
	</div>
</template>

<script>
	import api from "@/services/api/api";
	import notify from "@/services/util/notify";
	import dayjs from "@/services/util/dayjs"

	export default {
		name: "EditFYSetting",


		data() {
			return {
				dayjs: dayjs,
				financialYearDate: undefined,
				loading: false,
				dateInvalid: false,
				submitting: false,
			};
		},

		async created() {
			await this.init();
		},

		computed: {
			formattedDate() {
				if (this.financialYearDate) {
					return this.financialYearDate.format("DD/MM")
				}
				return this.financialYearDate;		
			},
		},

		methods: {
			defaultMonthYear() {
				let month = this.financialYearDate.month();
				if (!month) {
					month = 3;
				}

				return `${dayjs().year()}/${month + 1}` 
			},

			navigationMax() {
				const date = dayjs().endOf('year')
				return `${date.year()}/${date.month() + 1}`
			},

			navigationMin() {
				const date = dayjs().startOf('year')
				return `${date.year()}/${date.month() + 1}`
			},

			formatDate(value) {
				this.financialYearDate = dayjs(value, ['DD/MM', 'YYYY/MM/DD'], true)

				if (!this.financialYearDate.isValid) {
					this.financialYearDate = value;
				}
			},


			async init() {
				this.loading = true;

				try {
					this.financialYearDate = dayjs((await api.company.getFinancialYearSetting()).data.fullDate)
				} catch (err) {
					notify.error(
						err.data ?? "Error retrieving current financial year setting",
						"top"
					)
				} finally {
					this.loading = false;
				}
			},

			async onSubmit() {
				
				const date = dayjs(this.financialYearDate);

				if (!date.isValid()) {
					this.dateInvalid = true;
				} else {
					this.submitting = true;
					try {
						const res = await api.company.setFinancialYearSetting({financialYearEnd: date.toISOString()});
						this.dateInvalid = false;
						notify.primary(res.data ?? "Success", "top")
					} catch (err) {
						notify.error(
							err.response?.data ?? "Failed to update financial year settings",
							"top"
						)
					} finally {
						this.submitting = false;
					}
				}
			},
		},
	};
</script>

<style lang="less" src="@/assets/styles/data.less" scoped/>
