<template>
    <div class="audit-container">
        <div class="audit-container-header">

            <h3>
                <q-icon name="arrow_back_ios" size="md" @click="this.$router.push({ name: 'audit' })"></q-icon>
                Audit Information (Data Collection)
            </h3>
        </div>

        <div class="audit-information">
            <div class="audit-info-toc">
                <h5>Table of Contents</h5>
                <ul class="audit-table-list">
                    <li>Overview</li>
                    <li>Environmental Measures</li>
                        <ul>
                            <li><a href="#electricity">Electricity</a></li>
                            <li><a href="#freight">Freight</a></li>
                            <li><a href="#transport-fuels">Transport Fuels</a></li>
                            <li><a href="#waste">Waste</a></li>
                            <li><a href="#stationary-fuels">Stationary Fuels</a></li>
                            <ul>
                                <li><a href="#">Coal</a></li>
                                <li><a href="#">LPG</a></li>
                            </ul>
                        </ul>
                    <li><a href="#file-storage">File Storage</a></li>
                </ul>
            </div>
            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4>Overview</h4>
                </div>
                <div class="audit-info-text">
                    <p>This page contains documentation on how the data is collected for the various environmental measures that are tracked within the web application. Each emissions source we have connected has documentation
                    to back up the reliability of the process. </p>
                    <p>Each environmental measure has a corresponding manual upload feature. This allows the user to enter information that isn't available through the current app integrations for data collection.</p>
                </div>
            </div>

            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="electricity">Electricity</h4>
                </div>
                <div class="audit-info-text">
                    <p><strong>How is electricity data gathered?</strong></p>
                    <p>Currently, there are several ways in which electricty data is collected for the web application
                        <ul>
                            <li>Montly Electricity invoices</li>
                            <li>EIEP Registry</li>
                            <li>Direct from data provider</li>
                        </ul>
                    </p>
                    <p><strong>Monthly Electricity Invoices</strong></p>
                    <p>Using the file storage system, you can easily upload your monthly electricity invoices to the web platform.</p>

                    <p><strong>EIEP Registry</strong></p>
                    <p>-----------------------------</p>

                    <p><strong>Direct from data provider</strong></p>
                    <p>Unfortunately, most New Zealand electricty providers lack a consistent API that can be used to easily retrieve electricity data. However, half-hourly data for the current year can be provided upon request. 
                        A date & time is provided with the power usage in kwH. This is useful information and is contained within an excel sheet. However, the data can only be retrieved through requesting  </p>
                </div>
            </div>

            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="freight">Freight</h4>
                </div>
                <div class="audit-info-text">
                    <p><strong>How is freight data gathered?</strong></p>
                    <p>Currently, there is only one way in which freight data is gathered
                        <ul>
                            <li>API</li>
                        </ul>
                    </p>

                    <p><strong>Freight API</strong></p>
                    <p>The three most commonly used freight companies used by New Zealand businesses (NZ Post, Freightways, Aramex) have readily available APIs that can be incorporated into the web application. Using their API integration,
                        a connection was establish with NZ Post. Through the API, I was able to retrieve outgoing and delivered orders. Unfortunately, the API responses do not contain information regarding the package, weight, cost, or distance. This 
                        makes it increasingly difficult to display any information regarding carbon emmissions. Can use the receiver and sender addresses to calculate the distance between the two points.
                    </p>
                    <p>There is a manual data upload that allows the tenant to enter any freight information that may not be available through API integration (or additionally contains more information).</p>
                </div>
            </div>

            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="transport-fuels">Transport Fuels</h4>
                </div>
                <div class="audit-info-text">
                    <p><strong>Fuel Cards</strong></p>
                    <p>A fuel card is like a credit card that can only be used at petrol stations. This makes it super convenient for businesses that own vehicles or have employees that drive to create to manage fuel expenses without the need to rely on receipts for reimbursement.
                        Monthly reports are generated for the various fuel providers. This information is extracted using automation and is uploaded to the database to be displayed.
                    </p>
                </div>
            </div>

            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="waste">Waste</h4>
                </div>
                <div class="audit-info-text">
                    <p><strong>Waste/bins</strong></p>
                    <p>Waste companies do not collect specific waste weight of each business they collect from. They only provide the service based on number of bins. The number of bins is useful for the carbon zero certification as that is what Toitū are concerened with. However,
                        we care about the emmissions generated. Therefore, we have configured a set of scales to automatically upload waste information after being used. The user places the rubbish on the scales, selects the type of waste it is, and the information is uploaded to the 
                        database.
                    </p>
                </div>
            </div>

            
            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="stationary-fuels">Stationary Fuels</h4>
                </div>
                <div class="audit-info-text">
                    <p>Users are responsible for setting up forwarding rules in the email inboxes. The rule should forward the stationary fuel invoices to our support inbox. From here, we will periodically check the inbox for unread invoices. If an invoice is detected, the automation process
                        begins and the required information is collected. We use the customer number provided on the invoice to link it to the site/address of the business unit. This allows us to track coal and gas usage by business units.
                    </p>
                    <p><strong>LPG</strong></p>
                    <p>Currently there is only one way to collect LPG data and that is through monthly invoices. Uploaded LPG related invoices will be automatically scraped for data and inserted to the relevant tables.
						
                    </p>
                </div>
                <div class="audit-info-text">
                    <p><strong>Coal</strong></p>
                    <p>Like with LPG, currently the only way to collect coal related data is through uploaded monthly invoices.
                    </p>
                </div>
            </div>

            <div class="audit-info-group">
                <div class="audit-info-title">
                    <h4 id="file-storage">File Storage</h4>
                </div>
                <div class="audit-info-text">
                    <p><strong>How are files uploaded?</strong></p>
                    <p>Files are exclusively uploaded through the web application. While logged in as the administrator of a tenancy, you can navigate to the Audit page through the sidebar navigation (Audit).
                    The Audit page is currently separated into 4 different environmental measures, with navigation between them achieved through the tabs near the top of the screen.</p>

                    <p><strong>How are the files stored?</strong></p>
                    <p>Currently, the files are stored in a MongoDB collection. MongoDB has been used as it is a NoSQL database program, which allows for the storage of various file types and sizes.
                    MongoDB can store data in a document up to the maximum size of 16 Megabytes. This is ideal as most invoices and files involved are PDF documents. PDFs are likely to be only a few hundred kilobytes.
                    You can store files directly in the MongoDB collection (as long as the file is less than 16 MB). To achieve this, you'll need to base64 encode the file, and then store it using Mongo's BinData types. <br /></p>
                    <p>GridFS is a specification for storing and retrieving files that exceed the BSON-document size limit of 16MB.
                    Instead of storing a file in a single document, GridFS divides a file into parts or chunks, and stores each of those chunks as a separate document. By default, GridFS limits chunk size to 255 kilobytes. 
                    GridFS uses two collections to store files: the chunks collection which stores the file chunks and the files collection which stores the file metadata. <br /></p>
                    <p>No preprocessing is required to be the user when using GridFS, so there is no requirement to base64 encode files. GridFS additionally has a collection for metadata which allows us to store additional information (such as tenancy hash, file type, and email of the uploader).</p>

                    <p><strong>How are the files retrieved?</strong></p>
                    <p>To retrieve the files displayed on the web app, the MongoDB storage is queried for all files with tenancyHash values that match the tenancyHash of the currently logged-in user. 
                        All files that have a match are returned to the front end. From here, the frontend is responsible for separating the files based on their file type (Electricity, Freight, etc..)</p>

                    <p><strong>How are individual files downloaded?</strong></p>
                    <p>An icon on the rows for individual files provides the user with a way of downloading files. When a file is downloaded, the file is retrieved from the storage and saved to the server directory. The file is then sent to the frontend of the web application. Once the file has beeen
                        successfully sent and downloaded, it is removed from the server directory. This also means that if the same file is downloaded multiple times, it is individually retrieved each time from the MongoDB storage.
                    </p>


                </div>
            </div>

        </div>
    </div>
</template>

<script>

const AuditInformationComponent = {
    name: 'AuditInformation',

    data() {

        }
    }


export default AuditInformationComponent;
</script>


<style lang="less">

@import "../../assets/styles/audit.less";

</style>