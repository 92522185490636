<template>
	<div class="datasource-list-container">
		<div
			v-if="loading && firstLoad"
			class="loading"
		>
			<q-spinner
				color="deep-purple-4"
				size="3em"
			/>
		</div>
		<!-- Loaded page content -->
		<div v-if="!firstLoad">
			<div class="add-source-button">
				<q-btn
					style="margin-block: 1rem;"
					no-caps
					color="deep-purple-4"
					label="Connect Waste"
					icon="add"
                    :disabled="!siteId"
					square
					padding="10px 20px"
					@click="showCreateSourcePanel = true"
				>
                <q-tooltip style="font-size:0.8rem" v-if="!siteId">Please select a site to connect waste with</q-tooltip>
                </q-btn>
			</div>
			<div class="sources-table">
				<q-table
					hide-pagination
					:loading="loading"
					:rows-per-page-options="[0]"
					:rows="connections"
					:columns="columns"
					row-key="name"
				>
                <template v-slot:body-cell-status="props">
						<q-td :props="props">
                            <div
                            v-if="props.row.status == 'Connected'"
                            style="color: #0d0;"
                            >
                                {{props.row.status}}
                            </div>
                            <div
                            v-else
                            style="color: #ff781f;">
                                {{props.row.status}}
                            </div>
						</q-td>
					</template>
					<!-- <template v-slot:body-cell-edit="props">
						<q-td :props="props">
							<q-btn
								padding="10px 20px"
								color="blue-6"
								label="Edit"
								no-caps
								flat
								@click="
									this.connectionToEdit = props.row;
									this.showEditSourcePanel = true;
								"
							/>
						</q-td>
					</template> -->
				</q-table>
			</div>
		</div>

		<q-dialog
			position="right"
			v-model="showCreateSourcePanel"
		>
			<AddWasteConnectionComponent
				@reload="init()"
				@close="closeCreatePanel()"
			/>
		</q-dialog>
	</div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import AddWasteConnectionComponent from "./AddWasteConnectionComponent.vue";
import { useSiteStateStore } from '@/stores/siteState.store';

const WasteConnectionsList = {
	name: "WasteConnectionsList",

    components: {
        AddWasteConnectionComponent
    },

	data() {
		return {
			firstLoad: true,
			loading: false,

            showCreateSourcePanel: false,
            showEditSourcePanel: false,
            showDeleteSourcePanel: false,

			connections: [],
			columns: [
				{
					name: "supplier",
					align: "center",
					label: "Waste Supplier",
					field: (row) => row.supplier,
				},
				{
					name: "site",
					align: "center",
					label: "Site",
					field: (row) => row.site,
				},
				{
					name: "status",
					align: "center",
					label: "Status",
					field: (row) => row.status,
				},
				{
					name: "customerNumber",
					align: "center",
					label: "Customer Number",
					field: (row) => row.customerNumber,
				},
				{
					name: "siteName",
					align: "center",
					label: "Invoice Site Name",
					field: (row) => row.siteName ?? "N/A",
				},
				{
					name: "lastUpdated",
					align: "right",
					label: "Last Updated",
					field: (row) => new Date(row.lastUpdated).toLocaleDateString('en-NZ'),
                },
				// {
				// 	name: "edit",
				// 	align: "right",
				// 	style: "width: 40px",
				// },
				// {
				// 	name: "delete",
				// 	align: "right",
				// 	style: "width: 40px",
				// },
			],

            connectionToEdit: undefined,
            connectionToDelete: undefined,
		};
	},

	created() {
		this.init();
	},

    computed: {
        siteId() {
            return useSiteStateStore().siteId
        }
    },

	methods: {
		/**
		 * @desc retrieves and stores waste connections
		 */
		init() {
			this.loading = true;

			api.waste
				.getConnections()
				.then((res) => {
                    this.connections = [];
					res.data.forEach((connection) => {
						let supplier;
						// Parse and determine supplier name to display to user
						switch (connection.type) {
							case "enviroWaste":
								supplier = "Enviro Waste";
								break;
							case "wasteManagement":
								supplier = "Waste Management";
								break;
							default:
								supplier = "";
								break;
						}
                        connection.supplier = supplier;
						this.connections.push(connection);
					});
				})
				.catch(() => {
					notify.error(
						"Failed to retrieve waste data feed connections.",
						"top"
					);
				})
				.finally(() => {
					this.loading = false;
					this.firstLoad = false;
				});
		},
        

		/**
		 * @desc Closes the create site panel.
		 */
		closeCreatePanel() {
			this.showCreateSourcePanel = false;
		},
	},
};

export default WasteConnectionsList;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>
