<template>
  <div class="fuel-bp-container">
    <q-icon
      class="freight-form-back-icon"
      name="arrow_back_ios"
      @click="back()"
    />
    <div class="tutorial-container">
      <div class="tutorial-header">Connect Z Fuel Card</div>
      <div class="tutorial-description">
        This guide details how to set up a Z Fuel Card scheduled report to be
        sent to our GreenHalo inbox. From here the reports will be scraped for
        transport fuel usage data which will be added to your account
        automatically.
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          1
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/login.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Navigate to the
          <a
            class="outgoing-link"
            href="https://zbusinessonline.co.nz/IFCSWeb-zen/faces/secure/home/homepage.xhtml"
            target="_blank"
            >Z login portal.
            <q-icon
              name="login"
              style="text-decoration: underline; text-underline-offset: -1px"
          /></a>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          2
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/homepage.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Once logged in, hover over <b>Reports</b> and click on
          <b>Scheduled Reports</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          3
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/reportsSearch.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Click on the <b>ADD A SCHEDULED REPORT</b> button.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          4
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/reportType.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Under the <b>Report Type</b> dropdown, select <b>DTE-B</b>.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">5</div>
        <div class="tutorial-step">
          You may name the <b>Schedule Name</b> whatever you like, and select
          whatever Report Schedule <b>Frequency</b> best fits your needs.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          6
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/deliveryType.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Under the <b>Delivery Type</b> dropdown, select <b>Email</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          7
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/emailAddress.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Set the <b>Email Address</b> the report is sent to as
          <b>reports@greenhalo.nz</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          8
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/saveButton.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Finally, click the <b>Save</b> button. You should get a success
          message near the top of the window.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">9</div>
        <div class="tutorial-text-step">
          Link your Z customer number using the form below if you have not
          already registered it.
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 2px;
          background-color: lightgray;
          margin: 80px 0px 40px 0px;
        "
      />

      <div class="input-form-container">
        <q-form
          class="add-card-form"
          style="width: 100%"
          @submit.prevent="addCustomerNumber()"
        >
          <div style="display: flex; flex-direction: column">
            <q-input
              class="input-form-item input-field"
              placeholder="e.g. 10014301"
              v-model="customerNumber"
              :rules="[(val) => !!val || '']"
              outlined
              label="Z Customer Number"
              maxlength="30"
            />
            <div class="input-hint" style="clear: both">
              Add a Z Customer Number here so we can track your petrol/diesel
              usage
            </div>

            <q-select
              class="input-form-item input-field"
              v-model="selectedSite"
              :options="sites"
              outlined
              label="Site"
            />
            <div class="input-hint" style="clear: both">
              Choose which site uses this Z fuel card
            </div>
            <q-btn
              color="green-8"
              :loading="loading"
              class="input-form-item submit-btn"
              type="submit"
              label="Add"
            />
          </div>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";

const AddZCardComponent = {
  name: "AddZCardComponent",

  data() {
    return {
      loading: false,
      customerNumber: "",
      selectedSite: null,

      sites: [],
    };
  },

  async created() {
    let siteRes = await api.sites.getSites();
    for (let site of siteRes.data) {
      this.sites.push({
        label: site.name,
        value: site.id,
        description: "",
      });
    }
  },

  methods: {
    /**
     * @desc Attempt to add a company's Z customer number to the database
     */
    addCustomerNumber() {
      this.loading = true;
      const postBody = {
        customerNumber: this.customerNumber,
        siteId: this?.selectedSite?.value,
      };

      api.users
        .addZAccountNumber(postBody)
        .then((res) => {
          notify.primary(res.data, "top");
          this.$emit("rerenderList");
        })
        .catch((err) => {
          this.loadFailMsg = err?.response?.data ?? "Error adding Z fuel card";

          if (this?.loadFailMsg?.includes(`duplicate key value`)) {
            this.loadFailMsg = `Error: This Z Customer Number is already registered within our application.`;
          }

          notify.error(this.loadFailMsg, "top");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * @desc Emit a back event to the parent component.
     */
    back() {
      this.$emit("back");
    },
  },
};

export default AddZCardComponent;
</script>

<style scoped>
@import "../../../../assets/styles/dataSourceTutorial.css";
</style>