<template>
  <div style="height: 100%; width: 200vw">
    <div class="add-connection-container">
      <div class="add-connection-header">
        <div class="add-connection-title">Connect Waste Supplier</div>
        <div class="add-connection-description">
          Below you can add your waste supplier. We will then be able to get
          your data on you behalf to display on GreenHalo.
        </div>
      </div>

      <div class="add-connection-form-container">
        <q-form
          class="add-connection-form"
          @submit.prevent="createConnection()"
        >
          <div class="form-question">
            <label class="form-question-label">
              Select your waste provider
            </label>
            <q-select
              v-model="provider"
              :options="providers"
              option-value="value"
              option-label="label"
              option-disable="inactive"
              outlined
              :rules="[(val) => !!val || 'Provider must be selected.']"
            />
          </div>
          <div 
              v-if="provider?.value=='wasteManagement'"
              class="form-question">
            <label class="form-question-label">
              In order to ensure we can allocate your waste usage data to the correct site, please enter
              the value in the <b>Site Name</b> of your Waste Management invoices that are dedicated to this site.
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/waste/wasteManagement/wasteManagementSiteName.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
            </label>
            <q-input
              placeholder="e.g. GreenHalo Office #2"
              v-model="siteName"
              option-value="value"
              option-label="label"
              option-disable="inactive"
              outlined
              :rules="[(val) => !!val || 'Site Name must be provided.']"
              maxlength="50"
            />
          </div>
          <div class="form-question">
            <label class="form-question-label">
              In order to ensure we can collect your data properly, please
              supply the customer number for your waste company account.
            </label>
            <q-input
              v-model="customerNumber"
              outlined
              placeholder="e.g. WM10204305"
              :rules="[(val) => !!val || 'Customer number must be given.']"
            />
          </div>

          <div class="form-question">
            <label class="form-question-label">
              I consent to GreenHalo collection this information on my behalf
            </label>
            <q-toggle
              :rules="[
                (val) => !!val || 'Consent must be given to collect data.',
              ]"
              v-model="consented"
            />
          </div>

          <q-btn
            :disable="loading"
            :loading="loading"
            square
            no-caps
            padding="8px 24px"
            color="green-5"
            label="Create"
            type="submit"
          />
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api/api";
import { useSiteStateStore } from "@/stores/siteState.store";
import notify from "@/services/util/notify";

const CreateWasteConnection = {
  name: "CreateWasteConnection",

  data() {
    return {
      loading: false,

      provider: undefined,
      customerNumber: undefined,
      consented: false,
      siteName: undefined,

      providers: [
        {
          label: "Waste Management",
          value: "wasteManagement",
        },
        // {
        //   label: "Enviro Waste",
        //   value: "enviroWaste",
        // },
      ],
    };
  },

  created() {},

  computed: {
    siteId() {
      return useSiteStateStore().siteId;
    },
  },

  methods: {
    /**
     * @desc Uses the data filled out in the form to create a new waste data connection feed
     * Also does some validation on the customer number given.
     */
    createConnection() {
      if (!this.consented) {
        notify.error(
          "You must consent to GreenHalo collecting your waste data to create a new waste data connection.",
          "top"
        );
        return;
      }

      let postBody = {
        customerNumber: this.customerNumber,
        wasteCompany: this.provider.value,
        siteId: this.siteId,
        consented: this.consented,
      };
      
      // Validate Waste Management customer number
      if (this.provider.value == "wasteManagement") {
        postBody.siteName = this.siteName;
        if (
          this.provider.value < 3 ||
          this.customerNumber.substring(0, 2) != "WM"
        ) {
          notify.error(
            `Waste Management customer number must begin with WM`,
            "top"
          );
          return;
        }
      }

      this.loading = true;

      api.waste
        .postConnection(postBody)
        .then(() => {
          notify.primary(`Successfully submitted new waste connection`, "top");
          this.$emit("reload");
          this.$emit("close");
        })
        .catch(() => {
          notify.error("Failed to submit new waste connection", "top");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};

export default CreateWasteConnection;
</script>

<style scoped>
@import "../../../../assets/styles/addConnectionComponent.less";
</style>
