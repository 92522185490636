<template>
  <div class="datasource-list-container">
    <h2 class="tab-section-heading">Meter Connections</h2>
    <div class="content-item center-column eiep-list-item">
      
      <q-table
      :rows="meterList"
      :columns="columns"
      row-key="name"
      >
          <template v-slot:body-cell-status="props">
                      <q-td :props="props">
                        <div class="meter-connection-status"> 
                          <q-badge v-if="props.value=='Pending'" align="middle" text-color="orange">
                            <div class="text-center full-width">{{ props.value }}</div>
                            <q-tooltip>
                              Your connection is being set up with the EIE Registry. 
                              Please check back in a few days
                            </q-tooltip>
                          </q-badge>
                          <q-badge v-if="props.value=='Connected'" text-color="green">
                            <div class="text-center full-width">{{ props.value }}</div>
                          </q-badge>
                        
                          <q-badge v-if="props.value=='Failed'" text-color="red">
                            
                            <div class="text-center full-width">{{ props.value }}</div>
                            <q-tooltip>
                              Something has gone wrong while setting up your meter connection. 
                              Please contact us to solve this issue
                            </q-tooltip>
                          </q-badge>

                        </div>
                      </q-td>
                  </template>
        </q-table>

      <q-btn class="add-connection-btn"
      no-caps
      text-color="black"
      color="white"
      icon="add"
      label="Add a new meter connection"
      @click="showAddConnectionModal=true"
      >
        <q-tooltip>
          Register your electricity meter with us to have automatic 
          electricity usage data pulled from EIE Registry
        </q-tooltip>
      </q-btn>
    </div>
  </div>

  <q-dialog position="right" v-model="showAddConnectionModal" class="new-eiep-container">
    <AddICPMeterCardComponent 
    @updateTable="this.getMeterList()"  
    @closeModal="this.showAddConnectionModal=false"
    style="width:60vw; max-width: 80vw; background-color: white;"/>
  </q-dialog>
  
</template>

<script>

import api from "@/services/api/api";
import AddICPMeterCardComponent from "@/components/DataComponents/ConnectDataSourceComponents/Electricity/AddICPMeterComponent.vue"

const ElectricityDataSourceOptionsComponent = {
  name: "ElectricityDataSourceOptionsComponent",
	components: {AddICPMeterCardComponent},

  data() {
    return {
		showAddConnectionModal: false,
      meterList: [],
      columns: [
        { name: 'ICPIdentifier', align: 'left', label: 'Meter Name', field: 'ICPIdentifier', sortable: true },
        { name: 'address',  align: 'left', label: 'Address', field: 'fullAddress'},
        { name: 'status',  align: 'left', label: 'Status', field: 'status' },
        { name: 'updatedDate', label: 'Updated', align: 'left', field: 'updated', format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour12: false
          }), }
      ]

    };
  },

  created() {
    this.getMeterList()

  },

  methods: {

    /**
     * @desc Retrieves list of tenants connected meters.
     */
    async getMeterList() {
      api.EIEP
      .getEIEPMeterList()
      .then((res) => {
        this.meterList = res.data;
        this.meterList.map((meter) => meter.updated = meter.updated.split("T")[0] );
      })
    }
  }
};

export default ElectricityDataSourceOptionsComponent;
</script>

<style scoped>
@import "../../../../assets/styles/viewDataSources.less";
</style>