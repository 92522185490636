<template>
	<div class="sidebar" :style="{ width: sidebarWidth }">
		<transition name="fade">
			<div class="sidebar-content" v-if="!collapsed">
			<div class="sidebar-items">

				<q-item
				style="margin-top: 60px;"
				class="sidebar-item"
				clickable
				@click="this.$router.push({ name: 'home' })"
				v-bind:class="{ 'color-nav': $route.path == '/' }"
				>
				<q-item-section class="sidebar-icon">
					<q-icon name="home" />
				</q-item-section>
				<q-item-section>Dashboard</q-item-section>
				

				</q-item>

				<q-expansion-item
				ref="emissionDropdown"
				class="sidebar-item"
				clickable
				:disable="loadingEmissions"
				>
 
				<!-- Setting up using the actual classes for a sidebar item but to allow for the loading spinner when loading emissions -->
				<template v-slot:header>
					

					<div class="q-item__section column q-item__section--main justify-center sidebar-icon">
						<q-icon class="" name="o_signal_cellular_alt"/>
					</div>

					<div class="q-item__section column q-item__section--main justify-center">Your Emissions</div>

					<q-spinner v-if="loadingEmissions"
						color="primary"
						size="1.5em"
					/>
				</template>

				<q-card class="bg-transparent">
					<q-card-section class="collapse-items">

					<!-- Emission Source Data Routes -->
					<li v-for="source in emissionSources" 
						v-bind:key="source.emissionSourceId" 
						v-bind:class="{ 'color-nav collapse-items-active': $route.path == `/data/${source.urlName}`}" 
						@click="selectEmissionSource(source)"
					>
						{{ source.sourcedisplayname }}
					</li>

					<!-- Other Data Routes -->
					<li v-if="showOffset" :class="{ 'color-nav collapse-items-active': $route.path.includes('/data/carbon-offset') }" @click="this.$router.push({ name: 'carbonOffset' })">Carbon Offset</li>
					</q-card-section>
				</q-card>
				</q-expansion-item>

				<q-item
					v-if="flags.FLAG_5036_BULK_UPLOAD"
					class="sidebar-item"
					clickable
					@click="this.$router.push({ name: 'dataHub' })"
					:class="{ 'color-nav': $route.path == '/data-hub' }"
					>
					<q-item-section class="sidebar-icon">
						<q-icon name="hub" />
					</q-item-section>
					<q-item-section>Data Hub</q-item-section>
				</q-item>

				<q-item
				class="sidebar-item"
				clickable
				v-if="permissions.viewGoals.includes(this.userRole) && subPermissions.viewTargets.includes(this.subscription)"
				@click="this.$router.push({ name: 'targets', })"
				v-bind:class="{ 'color-nav': $route.path == '/targets' }"
				>
				<q-item-section class="sidebar-icon">
					<q-icon name="ads_click" />
				</q-item-section>
				<q-item-section>Targets</q-item-section>
				</q-item>

				
				<q-item
					class="sidebar-item"
					v-if="permissions.viewDocuments.includes(this.userRole) && subPermissions.auditing.includes(this.subscription)"
					clickable
					@click="this.$router.push({ name: 'audit' })"
					v-bind:class="{ 'color-nav': $route.path == '/audit' }"
				>
					<q-item-section class="sidebar-icon">
					<q-icon name="task" />
					</q-item-section>
					<q-item-section>Reports & Audit</q-item-section>
				</q-item>


				
				<q-item
					class="sidebar-item"
					v-if="permissions.viewDocuments.includes(this.userRole) && subPermissions.fileStorage.includes(this.subscription)"
					clickable
					@click="this.$router.push({ name: 'files' })"
					v-bind:class="{ 'color-nav': $route.path == '/files' }"
				>
					<q-item-section class="sidebar-icon">
					<q-icon name="folder" />
					</q-item-section>
					<q-item-section>Plans & Documents</q-item-section>
				</q-item>
				

				<q-expansion-item
				v-if="subPermissions.manageSites.includes(this.subscription)"
				icon="pin_drop"
				label="Sites"
				class="sidebar-item"
				clickable
				>
				<q-card class="bg-transparent">
					<q-card-section class="collapse-items">
					<li v-bind:class="{ 'color-nav collapse-items-active': $route.path == '/sites' }" v-if="permissions.manageSites.includes(this.userRole)" @click="this.$router.push({ name: 'sites' })">Manage</li>
					<li v-bind:class="{ 'color-nav collapse-items-active': $route.path == '/sites/emissions' }" v-if="subPermissions.siteLeaderboard.includes(this.subscription)" @click="this.$router.push({ name: 'siteEmissionsLeaderboard' })">Leaderboard</li>
					</q-card-section>
				</q-card>
				</q-expansion-item>
				
				<q-item
				v-if="subPermissions.manageOrganisations.includes(this.subscription) && 
				permissions.manageOrganisationConnections.includes(this.userRole)"
				:class="$route.path === '/organisations' ? 'sidebar-item color-nav' : 'sidebar-item'"
				@click="this.$router.push({ name: 'organisations' })"
				clickable
				>
				<q-item-section class="sidebar-icon">
					<q-icon name="corporate_fare" />
				</q-item-section>
				<q-item-section>Organisations</q-item-section>
				</q-item>
				
				<q-item
				v-if="permissions.accessSettings.includes(userRole)"
				class="sidebar-item"
				clickable
				@click="this.$router.push({ name: 'settings' })"
				v-bind:class="{ 'color-nav': $route.path == '/settings' }"
				>
				<q-item-section  class="sidebar-icon">
					<q-icon name="o_settings" />
				</q-item-section>
				<q-item-section>Settings</q-item-section>
				</q-item>
			</div>



			<!-- Commenting out support as it's not used at the momment -->
			<div class="sidebar-items">
				<!-- <q-item
				class="sidebar-item"
				clickable
				v-bind:class="{ 'color-nav': $route.path == '/support' }"
				>
				<q-item-section class="sidebar-icon">
					<q-icon name="o_support" />
				</q-item-section>
				<q-item-section>Support</q-item-section>
				</q-item> -->
			</div>
			
			</div>
		</transition>
		<span class="collapse-icon" @click="toggleSidebar">
			<i v-if="collapsed"><q-icon name="reorder" /></i>
			<i v-if="!collapsed"><q-icon name="menu_open" /></i>
		</span>
	</div>
</template>

<script>
import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";

import { collapsed, toggleSidebar, sidebarWidth } from "./state";
import { ref } from "vue";
import { useSiteStateStore } from "@/stores/siteState.store";
import { useChartStateStore } from "@/stores/chartState.store";
import find from "lodash/find";


export default {
	props: {},

	data() {
		return {
			userRole: getRole(),
			permissions: permissions,
			subscription: getSub(),
			subPermissions: subPermissions,

			store: useSiteStateStore(),

			// Show different pages 
			showCustom: false,
			showOffset: false,
		};
	},

	/**
	 * @desc Setup the required variables for the sidebar and sites
	 */
	setup() {
		return { 
			collapsed, 
			toggleSidebar, 
			sidebarWidth, 
			expanded: ref(false) };
	},

	mounted(){
		this.emitter.on('newToken', () => {
			this.userRole = getRole()
			this.subscription = getSub()
		})

		const emissionDropdown = this.$refs.emissionDropdown;
		if (emissionDropdown) {
			emissionDropdown.hide();
		}	
	},

	computed: {
		siteId() {
			return this.store.siteId;
		},

		loadingEmissions() {
			return this.store.loadingSite;
		},

		emissionSources() {
			return this.store.siteEmissionSources.filter(source => {
				// Since custom emissions and carbon-offset have a different implementation to other emissions
					// Do not add to list and just show the custom section
					if (source.urlName === "custom") {
						return false;
					} else if (source.urlName === "carbon-offset") {
						return false;
					}
					return true;
			})
		}
	},

	watch: {
		siteId() {
			const emissionDropdown = this.$refs.emissionDropdown;
			if (emissionDropdown) {
				emissionDropdown.hide();
			}	
		},
		emissionSources() {
			this.showCustom = !!find(this.store.siteEmissionSources, ['urlName', 'custom']);
			this.showOffset = !!find(this.store.siteEmissionSources, ['urlName', 'carbon-offset']);
		}
	},

	methods: {
		selectEmissionSource(source) {
			const chartStore = useChartStateStore();
			chartStore.emissionSourceView = source.urlName;
			this.$router.push({ name: 'viewDataset', params: { emissionSource: source.urlName }})
		}
	}
};
</script>


<style scoped>
@import "../../assets/styles/sidebar.less";
</style>
