<template>
  <div class="signup-container plan-selection">
    <h1 class="main-heading plan-selection">Select a pricing plan to get started</h1>
    <div class="plans-container">
      <SubscriptionPlanCard :id="store.subscriptionData.basicPlan.subscriptionTierId" 
        v-if="store.subscriptionData.basicPlan" 
        :selected="store.company.subscription" 
        class="planCard" 
        :data="basicPlan" 
        @selected="selectionClick" 
        buttonExists="true"
        :buttonText="store.company.subscription == 1 ? 'Selected' : 'Select'"/>
      <SubscriptionPlanCard 
        :id="store.subscriptionData.smallPlan.subscriptionTierId" 
        v-if="store.subscriptionData.smallPlan" 
        :selected="store.company.subscription" 
        class="planCard" 
        :data="smallPlan" 
        @selected="selectionClick"
        buttonExists="true"
        :buttonText="store.company.subscription == 2 ? 'Selected' : 'Select'"/>
      <SubscriptionPlanCard 
        :id="store.subscriptionData.proPlan.subscriptionTierId" 
        v-if="store.subscriptionData.proPlan" 
        :selected="store.company.subscription" 
        class="planCard" 
        :data="proPlan"  
        @selected="selectionClick"
        buttonExists="true"
        :buttonText="store.company.subscription == 3 ? 'Selected' : 'Select'"/>
    </div>
  </div>

</template>

<script>
import SubscriptionPlanCard from "./PricingCardComponent.vue";
import { useSignupStore } from "@/stores/signup.store";

export default {
  name: "PlanSelection",
  components: { SubscriptionPlanCard },
  data() {
    return {
      basicPlan: { 
        "subscriptionTierId": "1", "subscriptionPrice": 1.99, 
        "subscriptionName": "Basic", 
        "subscriptionDescription": "Basic subscription", 
        "features": [ 
          [ { "name": "Manual data upload", "description": "", "status": "active" } ],
          [ { "name": "Track your carbon emissions",  "description": "", "status": "active" } ],
          [ { "name": "Export your data", "note": "", "description": "", "status": "active" } ],
          [ { "name": "Data storage", "note": "Data storage amount: 1GB", "description": "", "status": "active" } ],
          [ { "name": "One location / site maximum", "note": "", "description": "", "status": "active" } ],
        
        ] 
      },
      smallPlan: { 
        "subscriptionTierId": "2", 
        "subscriptionPrice": 149, 
        "subscriptionName": "Small Business", 
        "subscriptionDescription": "Small Business subscription", 
        "features": [ 
          [ { "name": "Automated data feeds where available","description": "", "status": "active" } ], 
          [ { "name": "Manual data upload option", "description": "", "status": "active" } ], 
          [ { "name": "Track your carbon emissions", "description": "", "status": "active" } ], 
          [ { "name": "Track your targets", "description": "", "status": "active" } ], 
          [ { "name": "Generate emissions, scope & Board reports", "description": "", "status": "active" } ], 
          [ { "name": "Automated Toitū Envirocare export", "description": "", "status": "active" } ], 
          [ { "name": "Audit document storage", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Export your data", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Visualise your data", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Location / site leaderboard", "description": "", "status": "active" } ], 
          [ { "name": "Share your organisational data ", "description": "", "status": "active" } ], 
          [ { "name": "One location / site included", "description": "", "status": "active" } ], 
          [ { "name": "Up to three additional sites for $59 + GST / month / site", "description": "", "status": "active" } ], 
          [ { "name": "Up to 15GB data storage", "description": "", "status": "active" } ], 
          [ { "name": "Tailored support packages available", "description": "", "status": "active" } ], 
        ] 
      },
      proPlan:  { 
        "subscriptionTierId": "3", 
        "subscriptionPrice": 999, 
        "subscriptionName": "Pro", 
        "subscriptionDescription": "The PRO stuff", 
        "features": [ 
          [ { "name": "Automated data feeds where available","description": "", "status": "active" } ], 
          [ { "name": "Manual data upload option", "description": "", "status": "active" } ], 
          [ { "name": "Track your carbon emissions", "description": "", "status": "active" } ], 
          [ { "name": "Track your financial impact", "description": "", "status": "active" } ], 
          [ { "name": "Track your targets", "description": "", "status": "active" } ], 
          [ { "name": "Generate emissions, scope & Board reports", "description": "", "status": "active" } ], 
          [ { "name": "Automated Toitū Envirocare export", "description": "", "status": "active" } ], 
          [ { "name": "Audit document storage", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Export your data", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Visualise your data", "note": "", "description": "", "status": "active" } ], 
          [ { "name": "Location / site leaderboard", "description": "", "status": "active" } ], 
          [ { "name": "Data insights", "description": "", "status": "active" } ], 
          [ { "name": "Share your organisational data", "description": "", "status": "active" } ], 
          [ { "name": "Receive other organisations' data", "description": "", "status": "active" } ], 
          [ { "name": "Document storage for strategies, plans, annual reports, etc", "description": "", "status": "active" } ], 
          [ { "name": "Live carbon price", "description": "", "status": "active" } ], 
          [ { "name": "Three locations / sites included", "description": "", "status": "active" } ], 
          [ { "name": "Up to three additional sites for $149+ GST / month / site.  Contact us for more sites at a reduced price", "description": "", "status": "active" } ], 
          [ { "name": "Three hours free on-boarding", "description": "", "status": "active" } ], 
          [ { "name": "Tailored support packages available", "description": "", "status": "active" } ], 
        ] 
      },
      selectedPlan: "",

      store: useSignupStore(),
    }
  },


 

  methods: {
      selectionClick(plan){
        this.store.company.subscription = plan.subscriptionTierId
        this.store.pricing.selectedPlanDetails = plan
        this.store.sectionsComplete[3]=true
        this.$emit('next')
      }
    },  
}
</script>

<style lang="less">
@import "../../assets/styles/signup.less";
</style>