<template>
  <CloseableCard 
    class="invoice-viewer"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    :model-value="modelValue"
    :width="width"
    :height="height"
    popup
    no-backdrop-dismiss
  >
    <div>
      <h2 class="card-title">{{ invoiceFilename }}</h2>
      <p>We have detected the following information for your invoice</p>
    </div>
    <q-form @submit.prevent="approveInvoice()" id="edit-form" style="display: none;" />
    <q-splitter
      class="splitter"
      v-model="split"
      separator-style="width: 5px; margin-inline: 20px;"
    >
      <template v-slot:before>
        <PDFViewer v-if="file" :file="file" />
        <div v-else class="loading-container">
          <q-spinner color="primary" size="3em" />
        </div>
      </template>

      <template v-slot:separator>
        <q-avatar color="primary" text-color="white" size="40px" icon="drag_indicator" />
      </template>

      <template v-slot:after>
        <EditInvoice ref="editInvoice" 
          :file-id="invoiceFileId"
          :units="units"
          :efUnits="efUnits"
          :emissionFactors="emissionFactors"
          :defaultEfUnits="defaultEfUnits"
          @valid-allocations="setAllocations" 
          @invalid="(val) => invalidEdit = val"
        />
      </template>
    </q-splitter>
    <div class="submission-container">
      <p class="disclaimer-text">Showing original invoice data. This data has been edited - edited data will show on graphs and reports</p>
      <div class="buttons-container">
        <q-btn v-if="invoiceStatus?.statusCode === Status.SUCCESS" class="fail" label="Fail" no-caps color="red" @click="failInvoice()" :loading="loadingFailure" :disabled="loadingApproval"/>
        <q-btn class="approve" :label="invoiceStatus?.statusCode !== Status.APPROVED ? 'Approve' : 'Upload'" no-caps color="secondary" type="submit" form="edit-form" :loading="loadingApproval" :disabled="loadingFailure" />
      </div>
    </div>
  </CloseableCard>
</template>

<script>
  import PDFViewer from "@/components/PDFViewerComponent.vue";
  import EditInvoice from './SplitInvoiceComponents/EditInvoiceComponent.vue';
  import CloseableCard from "@/components/CloseableCardComponent.vue";
  import api from "@/services/api/api";
  import notify from "@/services/util/notify";
  import { InvoiceStatus as Status } from "@/enums/fileStatus";
  import dayjs from "@/services/util/dayjs";

  export default {
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      filename: {
        type: String,
      },
      fileId: {
        type: [String, Number],
      },
      width: {
        type: String,
        default: "90vw",
      },
      height: {
        type: String,
        default: "90vh",
      },
      units: {
        type: Array,
        required: true,
      },
			efUnits: {
				type: Object,
				required: true,
			},
      defaultEfUnits: {
        type: Object,
        required: true,
      },
      emissionFactors: {
        type: Array,
        required: true,
      },
      loadingApproval: {
        type: Boolean,
        default: false,
      },
      loadingFailure: {
        type: Boolean,
        default: false,
      },
      invoiceStatus: {
        type: Object
      },
    },

    components: {
      EditInvoice,
      PDFViewer,
      CloseableCard,
    },

    emits: ["update:modelValue", "submit-allocations", "approve-invoice", "fail-invoice"],

    data() {
      return {
        split: 50,
        file: null,
        allocations: {},
        Status: Status,
        invalidEdit: false,
      }
    },

    computed: {
      invoiceFileId() {
        return this.fileId ?? this.invoiceStatus?.fileId;
      },

      invoiceFilename() {
        return this.filename ?? this.invoiceStatus?.fileName;
      }
    },

    async created() {
      this.file = await this.getFile(this.invoiceFileId);
    },

    methods: {
      /**
       * Retrieves the raw file. 
       * 
       * @param {string|number} fileId - File's ID
       * @returns {File}
       */
      async getFile(fileId) {
        const fileData = (await api.file.getRawFile(fileId)).data;
        return new File([fileData], this.invoiceFilename);
      },

      /**
       * Returns true/false if the user has checked the split option for a least one row.
       * 
       * @param {object} optionsObject - Options for the splitting.
       * @returns {boolean}
       */
      splitSelected(optionsObject) {
        for (const options of Object.values(optionsObject)) {
          for (const row of Object.values(options)) {
            if (row.shouldSplit) {
              return true;
            }
          }
        }

        return false;
      },

      /**
       * Sets the allocations for parsedData, optionsObject, and accrualRange.
       *
       * @param {object} options - The allocations options.
       * @param {any} options.parsedData - The parsed data to be set.
       * @param {any} options.optionsObject - The options object to be set.
       * @param {any} options.accrualRange - The accrual range to be set.
       */
      setAllocations({parsedData, optionsObject, accrualRange}) {
        this.allocations.parsedData = parsedData;
        this.allocations.optionsObject = optionsObject;
        this.allocations.accrualRange = accrualRange;
      },

      /**
       * Parses all the timestamps in the optionsObject to ISO format. This is required prior to the data being sent to the backend.
       * 
       * @param {object} optionsObject - The object containing the invoice data, including split options.
       * @param {string} format - The format of the timestamp to parse.
       * @param {string} [timezone] - The timezone the timestamps are in. Default set to the user's browser timezone.
       * @returns {object} A new object containing the updated timestamps.
       */
      parseTimestamps(optionsObject, format, timezone = dayjs.tz.guess()) {
        const updatedObj = structuredClone(optionsObject);

        for (const rows of Object.values(updatedObj)) {
          for (const row of Object.values(rows)) {
            row.timestamp = dayjs.tz(row.timestamp, format, timezone).toISOString();
          }
        }

        return updatedObj;
      },

      /**
       * Approves the invoice. Also uploads the split options if selected.
       */
      async approveInvoice() {
        if (this.invalidEdit) {
          notify.warning("Please ensure all inputs are correct");
          return;
        }

        try {
          const optionsObject = this.$refs.editInvoice?.optionsObject;
          const timestampFormat = this.$refs.editInvoice?.timestampFormat;
          
          if (this.splitSelected(optionsObject)) {
            await this.$refs.editInvoice.validAllocations();
            
            if (Object.keys(this.allocations).length > 0) {
              const updatedOptionsObject = this.parseTimestamps(this.allocations.optionsObject, timestampFormat);
              this.$emit("submit-allocations", this.allocations.parsedData, updatedOptionsObject, this.allocations.accrualRange);
            }
          } else {
            const updatedOptionsObject = this.parseTimestamps(optionsObject, timestampFormat);
            this.$emit("submit-allocations", this.$refs.editInvoice?.invoiceData.parsedData, updatedOptionsObject, this.$refs.editInvoice?.accrualRange);
          }
        } catch (error) {
          notify.withObject(error.response);
        }
      },

      failInvoice() {
        this.$emit("fail-invoice");
      },
    }
  }
</script>

<style lang="less" src="@/assets/styles/invoiceView.less" />