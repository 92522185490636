<template>
    <div>
        <h2 class="tab-section-heading">Invite User</h2>
        <div class="users-invite-container">
            <div class="users-invite-input-container">
                <div class="users-invite-inputs">
                    <q-input class="users-invite-input-email" borderless v-model="userEmail" label="Enter user email address" maxlength="100" type="email"/>
                    <q-select class="users-invite-input-role" borderless v-model="userRole" :options="allowedUserRoleTypes" option-label="roleName" option-value="roleType" label="Role"/> 
                </div>
                <q-btn icon="info" rounded flat dense @click="showRolesMatrix = true" color="primary" /> 
            </div>
            <div class="users-invite-button"><q-btn no-caps label='Invite' :loading="loading" :disable="loading" @click="submitInvite"/></div>
    
            <SideModal v-model="showRolesMatrix" width="70rem">
                <UserMatrixComponent />
            </SideModal>
        </div>
    </div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import UserMatrixComponent from "./UserMatrixComponent.vue";
import SideModal from "../SideModalComponent.vue";

const InviteUserComponent = {
	name: "InviteUser",
    components: {
        UserMatrixComponent,
        SideModal,
    },

	data() {
		return {
            userEmail: "",
            userRole: "",
            allowedUserRoleTypes: [
                {roleName: "Admin", roleType: "admin"},
                {roleName: "User", roleType: "user"},
                // {roleName: "Editor", roleType: "editor"},
                // {roleName: "Site Manager", roleType: "siteManager"},
                // {roleName: "Auditor", roleType: "auditor"},
                {roleName: "Master", roleType: "master"},
                // {roleName: "Consultant", roleType: "consultant"}  
            ],
            showRolesMatrix: false,
            loading: false,
		};
	},

	methods: {
    
        /**
         * Submit user invite
         */
        submitInvite(){
            this.loading = true;
            let payload = {
                email: this.userEmail,
                role: this.userRole.roleType
            }
            api.users.inviteUser(payload)
            .then((res) => {
                notify.primary(res.data, 'top')
                this.$emit('fetchUsers');
            })
            .catch((error) => {
                notify.error(error.response.data ?? "An error occurred. Please try again later", 'top')
            })
            .finally(() => {
                this.userEmail = "";
                this.userRole = "";
                this.loading = false
            })
        },
	},
};

export default InviteUserComponent;
</script>

<style lang="less">
@import '@/assets/styles/settings.less';
</style>