<template>
    <q-card style="min-width: 50rem; min-height: 96.2vh">
        <q-card-section>
        <div class="text-h6">Suppliers</div>
        </q-card-section>

        <q-card-section>
        These are all the suppliers currently avalible for your tenancy. 
        <br/>
        Click on a row to copy the supplier id for that row. 
        </q-card-section>

        <q-card-section class="q-pt-none">
            <q-table
                @row-click="(_, row) => copyId(row.id, row.name)"
                :rows="suppliers"
                :columns="columns"
                row-key="id"
                :pagination="pagination"
                :filter="filter"
                :loading="loadingSuppliers"
                >
                <template v-slot:top-left >
                    <q-input clearable outlined borderless dense debounce="300" v-model="filter" placeholder="Search Suppliers" maxlength="100">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                </template>

                <template v-slot:body-cell-custom="props">
                <q-td :props="props">
                    <div>
                        <q-icon color="secondary" size="sm" v-if="!!props.row.isCustom" name="done"/>
                    </div>
                
                    </q-td>
                </template>
            </q-table>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
    </q-card>
</template>
<script>

import { Notify } from 'quasar'


const SupplierComponent = {
    name: 'SupplierComponent',
    props: ["suppliers", "loadingSuppliers"],

    data() {
        return {
            columns: [
                { name: 'id', label: 'Id', align: 'left', field: 'id' },
				{ name: 'name', label: 'Supplier', align: 'left', field: 'name' },
                { name: 'custom', label: 'Custom', align: 'center'},
			],
            pagination: { rowsPerPage: 25 },
            filter: '',
        }
    },

    methods: {
        copyId(id, name) {
            navigator.clipboard.writeText(id);
            Notify.create({
                message: `Copied Id for Supplier '${name}' to clipboard`,
                position: 'top'
            })
        }

    }
}

export default SupplierComponent;

</script>
