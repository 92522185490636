/* eslint-disable */

const { getListOfShortMonths } = require("./helpers.charts");

/**
 * @desc This method accepts an object of emissions that contains total emissions per site, grouped by
 * emission source, monthly.
 * 
 * It will parse the emissionData object and grab data only for the site determined by the siteName param.
 * 
 * example of object:
    {
        "2023-01": {
            "Electricity": {
                "Site 1": 405,
                "Site 2": 521,
            },
        },
        "2023-02": {
            "Electricity": {
                "Site 1": 496,
                "Site 2": 653,
            },
        },
    }
 * @param {Object} emissionData All carbon emissions per site, grouped by emission source, indexed by site.
 * @param {String} siteName Name of the site to get data for
 * 
 * @returns chart data
 */
exports.getSiteChart = (emissionData, siteName) => {
    
    // Get date labels dynamically matching the toLocaleDateString function of the provided browser
    const labels = getListOfShortMonths();
    
    let chartData = {
        labels: labels,
        datasets: []
    }

    // Set up year-split datasets e.g. 2021, 2022, 2023.
    let years = [];
    Object.keys(emissionData).forEach((key) => {
        let yearString = key.split("-")[0];
        if (!years.includes(yearString)) {
            years.push(yearString);
        }
    });

    // Fill out each dataset with necessary info.
    for (let i = 0; i < years.length; i++) {
        chartData.datasets.push({
            label: years[i],
            data: new Array(12).fill(0),
            borderColor: borderColors[i],
            backgroundColor: borderColors[i]
        });
    }

    /** 
    * For each entry in the emissionData, add the total carbon emissions to the correct year and 
    * correct month. To do this we need to do some nested for loops as there's objects within objects
    */
    let months = Object.keys(emissionData);
    for (let month of months) {
        // Now we loop through each emission source for that month.
        let emissionSources = Object.keys(emissionData[month]);
        for (let source of emissionSources) {
            let usage = emissionData?.[month]?.[source]?.[siteName] ?? 0;
            let label = month.split("-")[0]
            // Now we need to determine which dataset and which datapoint to increse by the usage.
            for (let dataset of chartData.datasets) {
                if (dataset.label === label) {
                    let monthLabel = getMonthName(month) ;
                    dataset.data[chartData.labels.indexOf(monthLabel)] += usage;
                }
            }
        }
    }


    // Go through each dataset's data and convert 0s to nulls so charts are more readable
    Object.values(chartData.datasets).forEach((dataset) => {
        for (let i = 0; i < dataset.data.length; i++) {
            if (dataset.data[i] <= 0) {
                dataset.data[i] = undefined;
            }
        }
    });
    return chartData;
}


/**
 * @desc This method accepts an object of emissions that contains total emissions for an organisation, grouped by
 * emission source, monthly. All the data relates to one organisation
 *  
 * example of object:
    {
        "2023-01": {
            "Electricity": {
                "carbon": 405,
                "amount": 521,
            },
        },
        "2023-02": {
            "Electricity": {
                "carbon": 496,
                "amount": 653,
            },
        },
    }
 * @param {Object} emissionData All carbon emissions per site, grouped by emission source
 * @param {String} siteName Name of the site to get data for
 * 
 * @returns chart data
 */
exports.getOrganisationChart = (emissionData) => {
    let labelsNums = Array.from({ length: 12 }, (_, i) => i + 1)
    let labels = [];
    labelsNums.forEach((item) => labels.push(getMonthName(`2020-${item}`)));
    let chartData = {
        labels: labels,
        datasets: []
    }

    // Set up year-split datasets e.g. 2021, 2022, 2023.
    let years = [];
    Object.keys(emissionData).forEach((key) => {
        let yearString = key.split("-")[0];
        if (!years.includes(yearString)) {
            years.push(yearString);
        }
    });

    // Fill out each dataset with necessary info.
    for (let i = 0; i < years.length; i++) {
        chartData.datasets.push({
            label: years[i],
            data: new Array(12).fill(0),
            borderColor: borderColors[i],
            backgroundColor: borderColors[i]
        });
    }

    let months = Object.keys(emissionData);
    for (let month of months) {
        // Now we loop through each emission source for that month.
        let emissionSources = Object.keys(emissionData[month]);
        for (let source of emissionSources) {
            let usage = emissionData?.[month]?.[source]?.['carbon'] ?? 0;
            let label = month.split("-")[0]
            // Now we need to determine which dataset and which datapoint to increse by the usage.
            for (let dataset of chartData.datasets) {
                if (dataset.label === label) {
                    let monthLabel = getMonthName(month);
                    dataset.data[chartData.labels.indexOf(monthLabel)] += usage;
                }
            }
        }
    }

    Object.values(chartData.datasets).forEach((dataset) => {
        for (let i = 0; i < dataset.data.length; i++) {
            if (dataset.data[i] <= 0) {
                dataset.data[i] = undefined;
            }
        }
    });

    return chartData;
}


/**
 * @desc Returns month name based on month number given
 * @param {String} date Date string to get month name of e.g. "2022-01"
 * 
 * @returns string representation of month
 */
const getMonthName = (date) => {
    let month = date.split("-")[1];
    let dateObj = new Date(date);

    dateObj.setMonth(parseInt(month) - 1);

    return dateObj.toLocaleString([], { month: 'short' })
}

/**
 * colours for charts :-)
 */
const borderColors = [
    'rgb(89,153,67)',
    'rgb(139,183,52)',
    'rgb(193,205,70)',
    'rgb(96,102,35) ',
];
