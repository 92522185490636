<template>
	<div class="file-container">
		<h5 class="file-header">Upload {{ definition.datasetName }} Files</h5>
		<div class="file-upload-input">
			<q-file
				class="upload-files"
				v-model="file"
				type="file"
				@change="uploadFile"
				ref="file"
				label="Drag and drop files, or click to browse (max 10 files)"
				:input-style="{ height: '70px' }"
				:item-aligned="true"
				multiple
				max-files="10"
				@rejected="onRejected"
			>
				<template
					v-if="file"
					v-slot:prepend
				>
					<q-icon
						name="cancel"
						@click.stop.prevent="file = null"
						class="cursor-pointer"
					/>
				</template>
			</q-file>

			<q-btn
				class="file-input-button"
				color="green"
				:disabled="this.file == null"
				@click="submitFile"
				label="Upload"
			/>
		</div>

		<div class="file-table">
			<q-table
				class="q-table-files"
				:rows="files"
				:columns="columns"
				row-key="id"
				:virtual-scroll-sticky-size-start="48"
				:rows-per-page-options="[0]"
				:filter="filter"
			>
				<template v-slot:body-cell-actions="props">
					<q-td :props="props">
						<q-btn
							icon="download"
							flat
							color="primary"
							@click="
								getFileDownload(
									props.row.id,
									props.row.fileName
								)
							"
						></q-btn>
						<q-btn
							icon="delete"
							flat
							color="deep-orange"
							@click="
								(confirm = true) &&
									(deleteFileId = props.row.id)
							"
						></q-btn>
					</q-td>
				</template>

				<template v-slot:top-left>
					<q-input
						outlined
						borderless
						dense
						debounce="300"
						v-model="filter"
						placeholder="Search"
					>
						<template v-slot:append>
							<q-icon
								name="close"
								@click="filter = ''"
								class="cursor-pointer"
							/>
							<q-icon name="search" />
						</template>
					</q-input>
				</template>
			</q-table>
		</div>

		<q-dialog
			v-model="confirm"
			persistent
		>
			<q-card>
				<q-card-section class="row items-center">
					<span class="q-ml-md"
						>Are you sure you want to delete this file?</span
					>
				</q-card-section>

				<q-card-actions align="center">
					<q-btn
						flat
						label="Cancel"
						color="primary"
						@click="deleteFileId = null"
						v-close-popup
					/>
					<q-btn
						flat
						label="Delete"
						color="deep-orange"
						@click="deleteFile()"
						v-close-popup
					></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>
	</div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { useSiteStateStore } from '@/stores/siteState.store';
import { ref } from "vue";

const AuditTabComponent = {
	name: "AuditTab",
	props: ["files", "definition"],

	setup() {
		return {
			pagination: ref({
				rowsPerPage: 0,
			}),
		};
	},

	data() {
		return {
			file: null,

			ascending: false,
			sortColumn: "",

			columns: [
				{
					name: "fileName",
					style: "width: 25%",
					label: "File Name",
					align: "left",
					field: "fileName",
					sortable: true,
					required: true,
				},
				{
					name: "fileSize",
					style: "width: 25%",
					label: "File Size",
					align: "left",
					field: "fileSize",
					sortable: true,
				},
				{
					name: "date",
					style: "width: 20%",
					label: "Upload Date",
					align: "left",
					field: "uploadDate",
					sortable: true,
				},
				{
					name: "uploaded_by",
					style: "width: 20%",
					label: "Uploaded By",
					align: "left",
					field: "uploaded_by",
					sortable: true,
				},
				{
					name: "actions",
					style: "width: 10%",
					label: "",
					align: "left",
					field: "",
				},
			],

			confirm: false,
			deleteFileId: null,

			filter: "",
		};
	},
	computed: {
		siteId() {
			return useSiteStateStore().siteId;
		}
	},

	methods: {

		/**
		 * @desc When a file is uploaded, save it to the refs so it can be accessed when we submit the files
		 */
		uploadFile() {
			this.file = this.$refs.file.files[0];
		},

		/**
		 * @desc For all the selected files, upload them to the database
		 */
		async submitFile() {
			for (let i = 0; i < this.file.length; i++) {
				await api.file
					.postCustomFile(this.file[i], this.definition.id, this.siteId)
					.then(() => {
						notify.primary("File successfully uploaded", "top");
					})
					.catch(() => {
						notify.error("Error uploading file", "top");
					});
			}
			this.$emit("initialise");
			this.file = null;
		},


		/**
		 * @desc Attempt to download a select file
		 * @param {Strings} fileId id of the file to download
		 * @param {Strings} fileName name of the file being downloaded
		 */
		getFileDownload(fileId, fileName) {
			try {
				api.file
					.getSingleFile(fileId, fileName)
					.then(() => {
						notify.primary("File successfully downloaded", "top");
					})
					.catch(() => {
						notify.error("Error downloading file", "top");
					});
			} catch {
				notify.error("Error downloading file", "top");
			}
		},


		/**
		 * @desc Attempt to delete a selected file.
		 */
		deleteFile() {
			try {
				api.file
					.archiveFile(this.deleteFileId)
					.then(() => {
						this.$emit("onInitialize");
						notify.primary("File successfully deleted", "top");
						this.$emit("initialise");
					})
					.catch(() => {
						notify.error("Error deleting file", "top");
					});
			} catch {
				notify.error("Error deleting file", "top");
			}
			this.deleteFileId = null;
		},


		/**
		 * @desc Error catcher for file uploader
		 */
		onRejected() {
			notify.error(
				"Error uploading files. Too many files selected",
				"top"
			);
		},
	},
};

export default AuditTabComponent;
</script>

<style scoped lang="less">
@import "../../../assets/styles/audit.less";
</style>
