<template>
  <div class="change-value-container">
    <h5>Edit Point</h5>
    <div class="change-value-item">
      Trend line:
      <q-select
      borderless outlined
        label="Please select which trend line you would like to apply this to"
        v-model="selectedDataset"
        :options="datasetList"
      >
      </q-select>
    </div>
    <div class="change-value-item">
      <span>Year</span>
      <q-input class="change-value-item-input" borderless disable v-model="selectedYear" />
    </div>
    
    <div class="change-value-item">
      <span>Emissions</span>
      <q-input class="change-value-item-input" v-model.number="newUserValue" type="number" :rules="emissionRules" no-error-icon/>
    </div>

    <div class="change-value-item">
      <span>Comments</span>
      <q-input class="message-area" borderless outlined v-model="message" type="textarea" lazy-rules
          :rules="[ val => val.trim() && val.trim().length > 0 || 'Please enter a comment']"
        />
    </div>
      
    <div class="change-value-buttons-container">
      <q-btn class="change-value-button" :disabled="loading" @click="targetStore.showChangeValueComponent=false" label="Cancel" no-caps /> 
      <q-btn class="change-value-button" color="green-6" :disabled="loading" @click="confirm" label="Confirm" no-caps />
    </div>
  </div>
</template>

<script>
import { useTargetStore } from "@/stores/target.store";
import notify from "@/services/util/notify";

const ChangeValueComponent = {
  name: "ChangeValueComponent",
  data() {
    return {
        loading: false,
        targetStore: useTargetStore(),
        newUserValue: this.oldValue,
        selectedDataset: undefined,
        message: "",

        emissionRules: [
        val => (val !== null && val !== '') || 'Value is required and must be a number',
      ],
    };
  },

  created(){
    this.selectedDataset = this.datasetList[0];
  },

  computed: {
    datasetList() {
      let activeElements = this.targetStore.chartMetaData.activeElement;

      let datasetList = [];
      activeElements.forEach((x) => {
        datasetList.push({
          label: this.targetStore.chartData.datasets[x.datasetIndex].label,
          index: x.datasetIndex,
        });
      });
      return datasetList;
    },

    selectedYear() {
      return this.targetStore.chartData.labels[
        this.targetStore.chartMetaData.index
      ];
    },

    oldValue() {
      return this.targetStore.chartData.datasets?.[this.selectedDataset?.index]?.data?.[this.targetStore.chartMetaData.index];
    },
  },
  watch: {
    oldValue() {
      this.newUserValue = this.oldValue;
    },
  },

  methods: {
    async confirm() {
      try {
        if(this.message && this.selectedDataset && this.newUserValue!=this.oldUserValue){
          this.loading = true;
          await this.targetStore.postUserTargets(this.selectedDataset.label, {
            label: this.selectedYear,
            oldValue: this.oldValue,
            newValue: this.newUserValue,
            comments: this.message,
          });
          this.loading = false;
        } else {
          notify.warning("Please make sure all sections are filled in")
        }

      } catch (error) {
        console.error(error);
        notify.error("Something went wrong while adding new point")
      } finally {
        this.loading = false;
      }
    },
  },
};

export default ChangeValueComponent;
</script>

<style>
    @import "../../../assets/styles/chart.css";
</style>
