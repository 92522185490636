<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <FileStorageComponent></FileStorageComponent>
    </div>
</template>

<script>

import FileStorageComponent from '@/components/DataComponents/FileStorageComponent.vue';
import { sidebarWidth } from '@/components/SidebarComponents/state'

export default {
    name:  "filesView",
    components: { FileStorageComponent },
    title: 'files-view',
    setup() {
        return { sidebarWidth }
    }
};
</script>