//TODO This should come from the database (public table toituFactorUnitMappings).

module.exports = {
    "kg": "kilograms",
    "pkm": "person kilometres",
    "litre": "litres",
    "kWh": "kiloWatt hours",
    "Room per night": "visitor nights",
    "per head": "head",
    "tkm": "tonne km",
    "km": "kilometres",
    "$": "dollars",
    "dollar": "dollars",
}