/**
 * axios-instance.js
 * 
 * File for handling axios API requests. Creates an axios instance with the server URL that'll be used
 * within the API files.
 */
import axios from "axios";
import notify from "../util/notify";
import getRole from "@/services/util/role";
import roles from "@/roles";

// Network dev server URl
// const SERVER_URL = 'http://192.168.106.7:3000/'
let SERVER_URL;
let timeout = 20000

if (process.env.VUE_APP_ENV_MODE == 'LOCAL' || process.env.VUE_APP_ENV_MODE == 'DEBUG') {
    // Local dev server URL
    SERVER_URL = "http://localhost:3000";

    if (process.env.VUE_APP_ENV_MODE == 'DEBUG') {
        timeout = 0;
    }

} else if (process.env.VUE_APP_ENV_MODE == 'DEV') {
    SERVER_URL = 'https://pavlovadevserver.azurewebsites.net';
} else if (process.env.VUE_APP_ENV_MODE == 'PROD') {
    // Deployed server URL
    SERVER_URL = "https://pavlovaprodserver.azurewebsites.net";
} else {
    throw `Environment variable is incorrectly set.`
}

const axiosInstance = axios.create({
    baseURL: SERVER_URL,
    timeout: timeout
});

axiosInstance.interceptors.request.use(
    config => {
        var token;
        if (sessionStorage.getItem('authToken')) {
            token = sessionStorage.getItem('authToken');
            sessionStorage.setItem('guestMode', true)
        } else if (sessionStorage.getItem("supplierToken")) {
            token = sessionStorage.getItem("supplierToken");
        } else {
            token = localStorage.getItem('authToken');
        }
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    res => res,
    error => {
        // If a response is 401 reroute the user to the login page for re-authentication
        if (error.response?.status === 401) {
            const isSupplier = getRole() === roles.Supplier;
            
            sessionStorage.clear();
            localStorage.clear();
            notify.warning("Your Session has expired. Please log in again.", "top", "You've been signed out");


            if (isSupplier) {
                window.location.href = "/auth/supplier/login";
            } else {
                window.location.href = "/auth/login";
            }

        } else {
            return Promise.reject(error)
        }
    }
);

export default axiosInstance;
