<template>
    <div id="main">
        <NavbarComponent class="top-of-screen" v-if="!$route.meta.hideNavbar" />
        <SidebarComponent v-if="!$route.meta.hideNavbar" />
        <div class="stacked-components">
            <GuestModeStickerComponent v-if="isGuestMode()" />
            <router-view :key="$route.fullPath"></router-view>
        </div>
    </div>
</template>

<script>

import SidebarComponent from '@/components/SidebarComponents/SidebarComponent'
import { sidebarWidth } from '@/components/SidebarComponents/state'
import GuestModeStickerComponent from '../components/OrganisationComponents/GuestModeStickerComponent.vue';
import NavbarComponent from '@/components/SidebarComponents/NavbarComponent'

export default {
    name: 'mainPage',
    components: { SidebarComponent, NavbarComponent, GuestModeStickerComponent },

    data() {
        return {
            sidebar: 1,
        };
    },

    setup() {

        return { sidebarWidth }
    },

    methods: {
        isGuestMode() {
            if (sessionStorage.getItem('guestMode') == null) {
                return false
            } else
                return true
        },

    }
}
</script>


<style >
.stacked-components {
    display: flex;
    flex-direction: column;
    background-color: var(--home-color);
    min-height: 95.2vh;
}

.top-of-screen {
    position: sticky;
    top: 0;
}

@media (max-width: 550px) {
    .stacked-components {
        margin-left: 45px !important
    }
}
</style>