<template>
    <div class="datasource-list-container">
        <div class="datasource-list-title">
            Connect Water Data (Invoice Scraping)
        </div>
        <q-icon class="close-modal"
	size="2rem"
	name="close" 
	@click="$emit('close')" 
	/>

        <div class="datasource-information">

            <div class="datasource-info-toc">
                <h5>Table of Contents</h5>

                <ul class="datasource-table-list">
                    <li>Overview</li>
                    <li><a href="#connected-suppliers">Connect a Supplier</a></li>
                    <li><a href="#supported-suppliers">Supported Suppliers</a></li>
                </ul>

            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4>Overview</h4>
                </div>
                <div class="datasource-info-text">
                    <p>Water within the GreenHalo platform currently keeps track of your data by scraping your usage information
                    from invoices provided by your providers.</p>
                </div>
            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4 id="connected-suppliers">Connect a supplier</h4>
                </div>
                <div class="datasource-info-text">
                    <p>As mentioned, we retrieve your water usage data directly from your suppliers. This is achieved through an automation which extracts the
                    critical data from the invoices and uploads them. To ensure we receive the invoices, you will need to set up an inbox rule that will automatically
                    forwards any invoices you receive to our inbox. Please use the following links to set up these rules. </p>
                    <p>Please set the forwarding email address as <strong>'innovation@embertechnology'</strong>.</p>
                    <ul>
                        <li>Gmail: <a href="https://www.technipages.com/gmail-how-to-auto-forward-emails-from-specific-senders" target="_blank">How to Auto-Forward Emails From Specific Senders</a></li>
                        <li>Outlook:<a href="https://www.montana.edu/office365/windows-outlook-forwarding.html" target="_blank">Forwarding Email from Outlook (Windows)</a></li>
                    </ul>
                </div>
                <div  class="add-customer-number">
                    <p>
                        Please add your customer number below. We use this to tie invoices to your account
                        <q-form  @submit="onSubmit">
                            <q-input
                                filled
                                v-model="customerNumber"
                                label="Customer number"
                                hint="Please type your water invoice customer number"
                                lazy-rules
                                required
                                maxlength="30"
                            />
                            <div>
                                <q-btn label="Submit" type="submit" color="primary"/>
                            </div>
                        </q-form>
                    </p>
                </div>
            </div>

            <div class="datasource-info-group">
                <div class="datasource-info-title">
                    <h4 id="supported-suppliers">Suported Suppliers</h4>
                </div>
                <div class="datasource-info-text">
                    <p>The following contains the supported water providers that have invoice scraping support.</p>
  
                
                        <ul>
                            <li>Dunedin City Council</li>
                            <li>Taranaki City Council(Coming Soon!)</li>
                        </ul>

                </div>
            </div>

        </div>

        <q-dialog
            v-model="gasInvoice"
            >
            <q-card class="scroll" style="min-width: 50vw">
                <q-img src="../../../../assets/tutorials/stationaryFuels/rockgas-invoice.png"></q-img>
            </q-card>
        </q-dialog>

        <q-dialog
            v-model="coalInvoice"
            >
            <q-card class="scroll" style="min-width: 50vw">
                <q-img src="../../../../assets/tutorials/stationaryFuels/tailored-energy-invoice.png"></q-img>
            </q-card>
        </q-dialog>

    </div>
</template>

<script>
import api from '@/services/api/api';
import notify from "@/services/util/notify";



const StationaryFuelsSourceOptionsComponent = {
    name: "StationaryFuelsSourceOptionsComponent",

    data() {
        return {
            customerNumber: "",
            loadSuccess: "",
            loadFail: "",
            loading: false,



        };
    },

    methods: {
        async onSubmit() {
            await api.water.addWaterCustomerNumber({customerNumber: this.customerNumber})
            .then(() => {
					this.loadSuccess = true;
					this.loadFail = false;
					notify.primary('Successfully added customer number to database', 'top');
                    this.$emit('close')

				})
				.catch(() => {
					this.loadFail = true;
					this.loadSuccess = false;
					notify.error('Could not upload customer number to database', 'top');

				})
				.finally(() => {
					this.loading = false;
				})
        }
    }

}

export default StationaryFuelsSourceOptionsComponent;
</script>

<style scoped>

@import "../../../../assets/styles/viewDataSources.less";

</style>