import { getYear } from "date-fns";
// import { useTargetStore } from "@/stores/target.store";
import { emitter } from "@/config/emitter"

export function getTargetLineOptions() {
    // const targetStore = useTargetStore()
    return {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: "#1976D2",
        indexAxis: "x",
        onClick: (evt, activeElements) => {
            // const clickedDatasetIndex = activeElements[0]?.datasetIndex;
            emitter.emit('targetGraphClick', {evt: evt, activeElements: activeElements});
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (item) {
                        var label = item.label,
                            currentValue = item.raw;
                        return label + ": " + parseFloat(currentValue.toFixed(2)).toLocaleString("en-NZ") + " kg";
                    },
                },
            },

            legend: {
                position: "bottom",
                align: "start",
            },

            datalabels: {
                display: false,
            },

            annotation: {
                // Create a line to identify the current year
                annotations: (context) => {
                    let label = find(context.chart.scales.x.ticks, [
                        "label",
                        getYear(new Date()),
                    ]);
                    if (label) {
                        return {
                            line1: {
                                type: "line",
                                borderDash: [5],
                                xMax: label.value,
                                xMin: label.value,
                                borderColor: "grey",
                                borderWidth: 2,
                            },
                        };
                    }
                },
            },
        },
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: "Year",
                    padding: { top: 15 },
                    font: {
                        size: 13,
                    },
                },
                ticks: {
                    font: (value) => {
                        if (getYear(new Date()) === value.tick.label) {
                            return {
                                weight: 900,
                            };
                        }
                    },
                },
            },
            y: {
                display: true,
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Emissions (kg)",
                    font: {
                        size: 13,
                    },
                },
            },
        },

        elements: {
            point: {
                pointHoverBorderWidth: 10,
            },
            line: {
                cubicInterpolationMode: "monotone",
            },
        },
    }
}