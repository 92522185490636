// Common role permissions

import roles from "./roles.js";

const rolesObject = {
    accessSettings: [roles.Admin],
    manageFavourites: [roles.Admin, roles.Auditor, roles.Consultant, roles.User, roles.Demo],
    csvExport: [roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest],
    manualUpload: [roles.Admin, roles.Editor, roles.Demo],
    viewGoals: [roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest],
    createGoals: [roles.Admin, roles.Demo],
    viewDocuments: [roles.Admin, roles.Consultant, roles.Auditor, roles.Demo, roles.Guest],
    addDataConnections: [roles.Admin, roles.Demo],
    addData: [roles.Admin, roles.Editor, roles.Demo],
    viewCommuteSurveys: [roles.Admin, roles.Auditor, roles.Consultant, roles.Demo, roles.Guest],
    createCustomDatasets: [roles.Admin, roles.Demo],
    manageSites: [roles.Admin, roles.Demo],
    manageRoles: [roles.Admin],
    manageSuppliers: [roles.Admin],
    manageSubscriptions: [roles.Master, roles.TestMaster],
    manageOrganisationConnections: [roles.Admin],
    manageEmissionFactors: [roles.Admin, roles.Master],
    manageOrganisationIntensityDenominators: [roles.Admin, roles.Master],
    toituExport: [roles.Admin, roles.Auditor, roles.Consultant, roles.Demo],
	editFinancialYear: [roles.Admin],
    deleteDataFeedConnection: [roles.TestMaster, roles.Admin, roles.Master],
};

//these are the default roles that should be a part of all roles
Object.keys(rolesObject).forEach((role) => rolesObject[role] = ([...rolesObject[role], roles.Master, roles.TestMaster]))
export default rolesObject
