/**
 * emissions.js
 * 
 * API for handling getting all emission associated data
 */

import axiosInstance from "./axios-instance";
import { promptDownload } from "../util/file";

/**
 * @desc Retrieves all data for an emission source by site id
 * 
 * @param {String} emissionSource the emission source to retrieve data for
 * @param {Number} siteId the site to retrieve data for
 * @returns {Promise<AxiosResponse<Array>>} the data or an error
 */
const getEmissionData = async function (emissionSource, siteId) {
    return await axiosInstance.get(`/api/emissions/${emissionSource}`, { params: { siteId } }).then(res => res);
}


/**
 * @desc Retrieves the definition of an emission source
 * 
 * @param {String} emissionSource 
 * @returns {Promise<AxiosResponse<Object>>} the definition or an error
 */
const getEmissionDatasetDefiniton = async function (emissionSource) {
    return await axiosInstance.get(`/api/emissions/${emissionSource}/definition`).then(res => res);
}

/**
 * @desc Adds a singular datapoint to an emission source
 * 
 * @param {String} emissionSource the emission source to add data to
 * @param {Object<Any>} data the data to add
 * @returns {Promise<AxiosResponse<Array>} the updated data or an error
 */
const addEmissionData = async function (emissionSource, data) {
    return await axiosInstance.post(`/api/emissions/${emissionSource}`, data).then(res => res);
}

/**
 * @desc Updates a singular datapoint relating the provided emission source
 * 
 * @param {String} emissionSource the emission source of the datapoint being updated
 * @param {Number} dataId the id of the data to update
 * @param {Object<Any>} data the updated data
 * @returns {Promise<AxiosResponse<Array>} the updated data or an error
 */
const updateEmissionData = async function (emissionSource, dataId, data) {
    return await axiosInstance.patch(`/api/emissions/${emissionSource}/${dataId}`, data).then(res => res);
}

/**
 * @desc Deletes a singular datapoint relating the provided emission source
 * 
 * @param {String} emissionSource the emission source of the datapoint being deleted
 * @param {Number} dataId the id of the data to delete
 * @returns {Promise<AxiosResponse<Array>} the deleted data or an error
 */
const deleteEmissionData = async function (emissionSource, dataId) {
    return await axiosInstance.delete(`/api/emissions/${emissionSource}/${dataId}`).then(res => res);
}

/**
 * @desc Retrieves all emission insights for a given emission source
 * 
 * @param {*} emissionSource the emission source to retrieve insights for
 * @param {*} siteId the site to retrieve insights for
 * @returns {Promise<AxiosResponse<Object>} An object containing the insights for the provided emission source
 */
const getEmissionInsights = async function (emissionSource, siteId, startDate, endDate, granularity) {

        const params = { 
            siteId,
            startDate: startDate,
            endDate: endDate,
            granularity: granularity
        };

    return await axiosInstance.get(`/app/emissions/${emissionSource}/insights`, { params: params }).then(res => res);
}

/**
 * @desc Retrieves emission data formated to be viewed on a chart
 * 
 * @param {String} emissionSource the emission source to retrieve chart data for
 * @param {String} granularity granularity of graph
 * @param {Number} params - Any optional query params to use for the request
 * @param {AbortSignal} signal - A signal used to abort the axios request
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionChartData = async (emissionSource, granularity, params, signal) => {
    return await axiosInstance.get(`/app/emissions/${emissionSource}/chart/${granularity}`, { 
        params: params,
        signal
     }).then(res => res)
}

/**
 * @desc Retrieves emission year on year chart data
 * 
 * @param {String} emissionSource the emission source to retrieve chart data for
 * @param {Number} siteId site to retrieve graph for
 * @param {AbortSignal} signal - A signal used to abort the axios request
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearOnYearChartData = async (emissionSource, siteId, tags, signal) => {
    return await axiosInstance.get(`/app/emissions/${emissionSource}/year-on-year`, {
        params: {siteId, tags},
        signal
    }).then(res => res);
}

/**
 * @desc Retrieves emission year on year chart data
 * 
 * @param {String} emissionSource the emission source to retrieve chart data for
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearOnYearOrganisationChartData = async (emissionSource) => await axiosInstance.get(`/app/emissions/${emissionSource}/year-on-year/organisation`).then(res => res)

/**
 * @desc Get Emission data in a file
 * 
 * @param {String} emissionSource the emission source to retrieve chart data for
 * @param {Number} siteId the site to retrieve csv data for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionFile = async function (emissionSource, siteId) {
    return axiosInstance({
        url: `/app/emissions/${emissionSource}/export`,
        method: 'GET',
        responseType: 'blob',
        params: { siteId }
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${emissionSource}.xlsx`);
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Uploads a CSV file relating to the provided emission source to the server
 * 
 * @param {String} emissionSource the emission source to upload the csv to
 * @param {Number} siteId the site to upload the csv to
 * @param {File} file the csv file to upload
 * @returns 
 */
const uploadEmissionCSV = async function (emissionSource, siteId, file) {
    let formData = new FormData();
    formData.append('file', file);

    return await axiosInstance.post(`/app/emissions/${emissionSource}/manual/csv`, formData, { params: { siteId }}).then(res => res);
}

/**
 * @desc Sends a request to the server to retrieve an example CSV for a given emission source
 * 
 * @param {String} emission emission source to generate example CSV.
 * @returns emission source data in csv format
 */
const getExampleCSV = async function(emission) {
    return axiosInstance({
        url: `/app/emissions/${emission}/csv-example`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${emission}-data-example.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}


/**
 * @desc Sends a request to the server to retrieve an template CSV for a given emission source. Contains all the headers
 * required for the emission souce
 * 
 * @param {String} emission emission source to generate template CSV.
 * @returns emission source data in csv format
 */
const getTemplateCSV = async function(emission) {
    return axiosInstance({
        url: `/app/emissions/${emission}/csv-template`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${emission}-template.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Uploads an invoice file.
 * 
 * @param {String} method the type of scraper, e.g. "BP", "Z", "Waste Management", "Orbit"
 * @returns 
 */
const uploadEmissionInvoice = async (files, method, siteId) => {
    const formData = new FormData();
    
    for (const file of files) {
        formData.append("files", file);
    }
    
    return axiosInstance.post(`/app/emissions/manual/invoice/${method}`, formData, {
        params: {
            siteId,
        }
    }).then(res => res);
}
/**
    *
 * @desc Uploads a split invoice file.
 * 
 * @param {String} method the type of scraper, e.g. "BP", "Z", "Waste Management", "Orbit"
 * @returns 
 */
const uploadEmissionInvoiceSplit = async (files, method, siteId) => {
    const formData = new FormData();
    
        formData.append("file", files);
    
    return axiosInstance.post(`/app/emissions/manual/invoice/${method}/split/parse`, formData, {
        params: {
            siteId,
        }
    }).then(res => res);
}

/**
    *
 * @desc Uploads a split invoice file.
 * 
 * @param {String} method the type of scraper, e.g. "BP", "Z", "Waste Management", "Orbit"
 * @returns 
 */
const uploadEmissionSplitData = async (body, fileId) => {
   const formData = new FormData();

    // Append each key-value pair from the body object to FormData
    Object.keys(body).forEach(key => {
        formData.append(key, JSON.stringify(body[key]));
    });

    return axiosInstance.post(`/app/emissions/manual/invoice/${fileId}/split`, formData).then(res => res);
}

/**
 * Retrieves a bulk upload example file
 * 
 * @param {String} type The type of example, 'template' or 'file'
 */
const getExampleBulkFile = async function (type) {
    return axiosInstance({
        url: `/app/emissions/example/bulk/${type}`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        promptDownload(response.data, `Bulk ${type} example.xlsx`);
    });
}

/**
 * Uploads a bulk file
 * 
 * @param {File} file The file to be uploaded
 * @returns 
 */
const uploadBulkFile = async (file, mappings=null, sheetName = "") => {
    const formData = new FormData();
    formData.append("file", file);
	formData.append("mappings", JSON.stringify(mappings));
    formData.append("sheetName", sheetName);

    const reqConfig = {
        url: "/app/emissions/manual/bulk-upload",
        method: "POST",
        data: formData,
    };
    
    return await axiosInstance(reqConfig);
};

const parseBulkFile = async (file, sheetNames) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("sheetNames", JSON.stringify([].concat(sheetNames)));
      
      const reqConfig = {
        url: "/api/files/bulk/parse",
        method: "POST",
        data: formData,
    };
    
    return await axiosInstance(reqConfig);
};

const getMappingOptions = async () => {
    return await axiosInstance.get("/api/files/mapping/options").then(res => res);
};


const getSavedMappingOptions = async () => {
    return await axiosInstance.get("/api/files/mapping/options/saved").then(res => res);
};


const parseEmissionFactorAndUnitColumnsBulkFile = async (sheet, file, efHeaderName, unitHeaderName) => {
	const formData = new FormData();
	formData.append("file", file);

	const reqConfig = {
		url: `/api/emissions/manual/bulk-upload/parse/units`,
		method: `POST`,
		params: {
			sheetName: sheet,
			emissionFactorColumnName: efHeaderName,
			unitColumnName: unitHeaderName,
		},
		data: formData,
	};
	return await axiosInstance(reqConfig);
}

export default {
    getEmissionData,
    getEmissionDatasetDefiniton,
    addEmissionData,
    updateEmissionData,
    deleteEmissionData,
    getEmissionInsights,
    getEmissionChartData,
    getEmissionFile,
    getExampleCSV,
    getTemplateCSV,
    uploadEmissionCSV,
    getYearOnYearChartData,
    getYearOnYearOrganisationChartData,
    uploadEmissionInvoice,
    uploadEmissionSplitData,
    uploadEmissionInvoiceSplit,
    getExampleBulkFile,
    uploadBulkFile,
    parseBulkFile,
    getMappingOptions,
	getSavedMappingOptions,
	parseEmissionFactorAndUnitColumnsBulkFile,
}
