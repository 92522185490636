
import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    // Default Colours for the application
    brand: {
      primary: '#9569E1', // (Purple)
      secondary: '#26D391', // (Green)
    }
  },
  plugins: {
    Notify,
    Dialog
  }
}