<template>
    <q-card class="bulk-upload-mapping-container">
		<q-btn class="close-btn" style="margin-left: auto;" flat round dense icon="close" v-close-popup color="red" size="1.25em"/>	
			
			<q-stepper
				v-model="step"
				class="stepper"
				ref="stepper"
				color="primary"
			>
				<q-step
					v-if="hasMultipleSheets"
					:name="1"
					title="Select Sheet"
				>
					<div>
						<q-card-section>
							<h2 class="bulk-upload-mapping-title">Sheet Selection</h2>
						</q-card-section>
				
						<q-card-section>
							<p>
								Please select the sheet you wish to upload data from.
								<br><br>
								Note: only sheets that are valid and have not already been uploaded are available.
							</p>
						</q-card-section>
					</div>
					<q-option-group
						v-model="selectedSheet"
						:options="sheetOptions"
						color="primary"
						@update:model-value="(sheetName) => $emit('initFileData', sheetName)"
					/>
					<q-card-actions class="bulk-upload-mapping-buttons">
						<q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" no-caps label="Back" class="q-ml-sm" />
						<q-btn style="width: 7.5rem;" @click="$refs.stepper.next()" label='Next' color="secondary" no-caps :disable="!selectedSheet || Object.keys(fileData).length === 0" />
					</q-card-actions>
				</q-step>
				<q-step
					v-if="hasInvalidHeaders"
					:name="hasMultipleSheets ? 2 : 1"
					title="Invalid Headers"
				>
					<div>
						<q-card-section>
							<h2 class="bulk-upload-mapping-title">Map Invalid Headers</h2>
						</q-card-section>
				
						<q-card-section>
							<p>
								The system has detected some invalid headers in the file.
								Please ensure all headers below are matched correctly.
							</p>
						</q-card-section>
					</div>
			
					<q-table
						class="bulk-upload-mapping-table"
						:columns="columns"
						:rows="invalidHeaderRows"
						:rows-per-page-options="[0]"
						selection="multiple"
						v-model:selected="selectedInvalidHeaders"
						row-key="fileValue"
						virtual-scroll
						@selection="deselectHeader"
					>
						<template #body-cell-seperator="props">
							<q-td :props="props">
								<q-icon name="arrow_forward" size="1.5rem" />
							</q-td>
						</template>
						<template #body-cell-matchingValue="props">
							<q-td :props="props">
								<q-select 
									class="bulk-upload-mapping-selection" 
									v-model="mappingValues[props.row.fileValue].selected[props.row.fileValue]"
									:options="mappingValues[props.row.fileValue]?.filtered"
									emit-value
									map-options
									outlined 
									dense 
									use-input 
									hide-selected
									fill-input
									hide-bottom-space
									@update:model-value="(value) => updateMapping('invalidHeaders',
									props.row.fileValue, value)"
									@filter="(val, update) => filterFn(val, update, props.row.fileValue)"
									@input-value="(matchingHeader) => handleInvalidHeaderSelection(matchingHeader, props.row.fileValue)"
								>
									<template v-slot:prepend>
										<q-icon name="search" />
									</template>
								</q-select>
							</q-td>
						</template>
					</q-table>
			
					<q-separator dark></q-separator>
					
					<q-card-actions class="bulk-upload-mapping-buttons">
						<q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" no-caps label="Back" class="q-ml-sm" />
						<q-btn style="width: 7.5rem;"
							@click="initMappingValidHeaders()" label='Next' color="secondary" no-caps />
					</q-card-actions>
				</q-step>

				<q-step
						:name="emissionFactorMappingStep"
						title="Emission Factors"
					>
				
					<div v-if="initLoading" class="loading-spinner">
						<q-spinner size="xl" color="primary" />
					</div>
		
					<EmissionFactorMapping 
						v-else
						:emissionFactorHeaderName="emissionFactorHeaderName" 
						v-model="mappingValues" 
						:mappings="mappings" 
						:fileData="fileData" 
						:savedMappings="savedMappings"
						:fileEmissionFactorUnitMap="fileEmissionFactorUnitMap"
						:isSubmitButton="headersToMapInLoop.length + hasInvalidHeaders +
						hasMultipleSheets + 1 === step"
						:hasBackButton="step > 1"
						:unitHeaderName="unitHeaderName"
						:loadingUpload="loadingUpload"
						@updateMapping="updateMapping"
						@submit="upload"
						@next="$refs.stepper.next()"
						@back="$refs.stepper.previous()"
						:file="file"
						:sheetName="selectedSheet"
						/> 
				</q-step>

				<q-step
					v-for="(header, index) in headersToMapInLoop"
					:key="header"
					:name="hasMultipleSheets + hasInvalidHeaders + index + 2"
					title="Headers"
				>
					<div>
						<q-card-section>
							<h2 class="bulk-upload-mapping-title">Map {{ header === 'emissionfactor' ? 'Emission Factor': startCase(header) }}</h2>
						</q-card-section>
				
						<q-card-section>
							<p>
								Please ensure all values below are matched correctly
							</p>
						</q-card-section>
					</div>
					<q-table
						class="bulk-upload-mapping-table"
						:columns="columns"
						:rows="getRows(header)"
						:rows-per-page-options="[0]"
						row-key="fileValue"
						virtual-scroll
					>
						<template #body-cell-seperator="props">
							<q-td :props="props">
								<q-icon name="arrow_forward" size="1.5rem" />
							</q-td>
						</template>
						
						<template #body-cell-matchingValue="props">
							<q-td :props="props">
								<q-select 
									class="bulk-upload-mapping-selection"
									v-model="mappingValues[header].selected[props.row.fileValue]"
									:options="mappingValues[header]?.filtered"
									outlined 
									dense 
									use-input 
									hide-bottom-space
									hide-selected
									@update:model-value="(value) => updateMapping(header,
									props.row.fileValue, value)"
									fill-input
									@filter="(val, update) => filterFn(val, update, header)"
									:rules="[ val => val != undefined  || 'Please select a matching value' ]"
								>
									<template v-slot:prepend>
										<q-icon name="search" />
									</template>
								</q-select>
							</q-td>
						</template>
					</q-table>
			
					<q-separator dark></q-separator>
					
					<q-card-actions class="bulk-upload-mapping-buttons">
						<q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Back" class="q-ml-sm" no-caps />
						<q-btn
							v-if="step < headersToMapInLoop.length + hasInvalidHeaders +
							hasMultipleSheets + 1"
							style="width: 7.5rem;"
							@click="$refs.stepper.next()"
							:label="'Next'"
							color="secondary" 
							no-caps 
							:disable="!validHeaderMapping(header)"
						/>
						<q-btn
							v-if="step >= headersToMapInLoop.length + hasInvalidHeaders +
							hasMultipleSheets + 1"
							style="width: 7.5rem;"
							@click="upload()"
							:label="'Upload'"
							color="secondary" 
							no-caps 
							:loading="loadingUpload"
							:disable="!validHeaderMapping(header)"
						/>
					</q-card-actions>

				</q-step>

			</q-stepper>
    </q-card>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import { startCase, camelCase } from "lodash";
import EmissionFactorMapping from "./EmissionFactorMapping";

export default {
	name: "BulkUploadMapping",
	props: {
		fileData: {
			type: Object,
			default: new Object(),
		},
		mappings: {
			type: Object,
			default: new Object(),
		},
		savedMappings: {
			type: Object,
			default: new Object(),
		},
		sheetNames: {
			type: Array,
			default: new Array(),
		},
		initSavedMappings: {
			type: Function,
			required: true,
		},
		file: {
			type: File,
		},
	},
	components: { EmissionFactorMapping },
	data() {
		return {
			startCase: startCase,
			camelCase: camelCase,

			step: 1,
			loadingUpload: false,
			initLoading: false,
			columns: [
				{
					name: "fileValue",
					label: "Value",
					align: "left",
					classes: "bg-white",
					style: "width: 2vw",
					field: (row) => row.fileValue,
				},
				{
					name: "seperator",
					align: "center",
					label: "",
					classes: "bg-white",
				},
				{
					name: "matchingValue",
					label: "Matching Value",
					align: "right",
					classes: "bg-white",
					field: (row) => row.matchingValue,
				},
			],
			selectedInvalidHeaders: [],
			mappingValues: {},
			ignoreHeaders: ["amount", "timestamp", "tags"],
			ignoreMappingHeaders: ["emissionfactor", "unit"],
			defaultEmissionFactorHeaderName: "emissionfactor",
			emissionFactorHeaderName: "emissionfactor",
			unitHeaderName: undefined,
			fileEmissionFactorUnitMap: {},
			

			// These headers should contain a mapping in this.mappings, as there needs to be values to map to
			otherHeadersToMap: ["supplier"],

			validHeaders: [],
			selectedSheet: "",
		};
	},
	async created() {
		this.selectedSheet = this.sheetNames[0] ?? "";
		await this.initComponent();
	},
	watch: {
		fileData: {
			async handler() {
				// As each file could contain different data we need to re-init the component
				this.unitHeaderName = undefined;
				this.emissionFactorHeaderName = this.defaultEmissionFactorHeaderName;
				await this.initComponent();
			},
			deep: true,
		},
	},
	computed: {
		// Checks if we are on the emission factor step
		emissionFactorMappingStep() {
			let step = 1;
			if (this.hasMultipleSheets) {
				step += 1;
			}
			if (this.hasInvalidHeaders) {
				step += 1;
			}
			return step;
		},

		// Gets all rows that are associated with an invalid header
		invalidHeaderRows() {
			return this.fileData.invalidHeaders?.map((header) => {
				return {
					fileValue: header,
				};
			});
		},

		// Returns a list of which headers to v-for for mapping
		// Removes emissionfactor as this mapping is done on a separate component
		headersToMapInLoop() {
			return this.validHeaders.filter(
				(header) => !this.ignoreMappingHeaders.includes(header),
			);
		},

		// Gets all unique headers in a file
		uniqueHeaders() {
			return this.validHeaders?.concat(this.fileData.invalidHeaders) ?? [];
		},

		// Gets all selected headers
		selectedHeaders() {
			const selections = [];

			for (const row of this.selectedInvalidHeaders) {
				if (!selections.includes(row.fileValue)) {
					selections.push(row.fileValue);
				}
			}

			return selections;
		},

		// Returns true if a file has multiple sheets, used for identifying if a sheet needs to be selected
		hasMultipleSheets() {
			return Object.keys(this.sheetNames).length > 1;
		},

		// Returns true if a file has invalid headers
		hasInvalidHeaders() {
			return this.fileData?.invalidHeaders?.length > 0;
		},

		// Gets the available sheet options
		sheetOptions() {
			return this.sheetNames.map((sheet) => {
				return {
					label: sheet,
					value: sheet,
				};
			});
		},

		// Returns all the mapping keys for the mapping object
		mappingKeys() {
			return new Set(Object.keys(this.mappings));
		},
	},
	methods: {
		/**
		 * Initialises mapping component and load saved mappings
		 */
		async initComponent() {
			this.initLoading = true;

			this.validHeaders = [];
			this.initMatchingValues();
			await this.loadSavedMappings();

			this.initUnitMappings(
				this.mappings.efUnitOptions,
				this.mappings.efDefaultUnits,
			);
			this.initSelectedInvalidHeaders();
			await this.generateEmissionFactorUnitMap();
			this.initLoading = false;
		},

		/** --- Unit Mappings --- */

		/**
		 * Maps the previous emission factor units from saved mappings to the current mapping values.
		 * Iterates through each entry in the emissionFactorUnitMappings object and sets the corresponding
		 * selected unit in the efUnitMappings to the saved unit.
		 */
		mapPreviousEmissionFactorUnits() {
			Object.entries(this.savedMappings.emissionFactorUnitMappings).map(
				([emissionFactor, savedUnit]) => {
					this.mappingValues.efUnitMappings.selected[emissionFactor] =
						savedUnit;
				},
			);
		},

		/**
			Adds empty necessary unit mapping objects to mappingValues
		*/
		addBlankHeader(header) {
			this.mappingValues[header] ??= {};
			const headerMapObj = this.mappingValues[header];
			headerMapObj.filtered ??= {};
			headerMapObj.original ??= {};
			headerMapObj.selected ??= {};
		},

		/**
		Adds unit mapping options and unit default mappings to the
		mappingValues object.
		*/
		initUnitMappings(efOptions, efDefaults) {
			this.addBlankHeader("efUnitMappings");
			this.addBlankHeader("unitMappings");

			const efUnitMappings = this.mappingValues.efUnitMappings;
			for (const [ef, opts] of Object.entries(efOptions)) {
				efUnitMappings.original[ef] = opts;
				efUnitMappings.filtered[ef] = opts;
			}

			for (const [ef, unit] of Object.entries(efDefaults)) {
				efUnitMappings.selected[ef] = unit;
			}
			//this needs to be called at the end of this as the object used in the method relies on the object created in initUnitMappings
			if (this.savedMappings.emissionFactorUnitMappings) {
				this.mapPreviousEmissionFactorUnits();
			}
		},


		/**
			@desc asks server to generate a map between unique emission factor and unit
			values, given known emission factor and unit column header names.
			@param {Method} callback to call when this async method is finished
		*/
		async generateEmissionFactorUnitMap () {
			// If this is true, the user did not map a units column, so we just call finished()
			// and leave
			if (!this.unitHeaderName || !this.emissionFactorHeaderName) {
				return;
			}

			// For each ef get associated units
			try {
				const serverResponse = await api.emissions.parseEmissionFactorAndUnitColumnsBulkFile(
					this.selectedSheet,
					this.file,
					this.emissionFactorHeaderName,
					this.unitHeaderName,
				);
				this.fileEmissionFactorUnitMap = serverResponse.data;
			} catch (err) {
				console.error(err.message);
				notify.error(err?.response?.data ?? "Sorry, we couldn't compare your emission factor column to your units column.", "top");
			}
		},




		/** --- Invalid Headers --- */

		/**
		 * Generates a list of header options that are invalid given a list of values
		 */
		invalidHeaderOptions() {
			const optionalHeaders = this.fileData.optionalHeaders ?? [];
			const requiredHeaders = this.fileData.validHeaders;

			// Sort by alphabetical order, but ensure metadata is at the end
			optionalHeaders.sort((a, b) =>
				a === "metadata" ? 1 : b === "metadata" ? -1 : a.localeCompare(b),
			);
			requiredHeaders.sort();

			let opts = requiredHeaders.concat(optionalHeaders).map((value) => {
				let label = "";

				switch (value) {
					case "emissionfactor":
						label = "Emission Factor";
						break;
					case "metadata":
						label = "Add to Metadata";
						break;
					default:
						label = startCase(value);
				}

				if (optionalHeaders.includes(value)) {
					label += " (Optional)";
				}

				return { value, label };
			});

			// Filter out already supplied matching headers
			opts = opts.filter(
				(header) =>
					!Object.keys(this.fileData.uniqueValues).includes(header.value),
			);

			return opts;
		},

		initSelectedInvalidHeaders() {
			this.selectedInvalidHeaders = this.invalidHeaderRows;
		},

		hasOtherHeaderSelected(otherHeader) {
			// Checks if any of the matching values for the selected headers = otherHeader
			for (const header of Object.keys(this.fileData.uniqueValues)) {
				// If header exists then return true
				if (header === otherHeader) {
					return true;
				}

				let matchingValue = this.mappingValues[header]?.selected?.[header];
				if (matchingValue) {
					matchingValue = matchingValue.toLowerCase();
					if (
						matchingValue === otherHeader &&
						this.mappingKeys.has(otherHeader)
					) {
						return true;
					}
				}
			}
			return false;
		},

		/**
		 * @desc Updates mappings and saved data if a header is deselected
		 * @param {Object} selection - The contains information about the rows added/removed
		 */
		async deselectHeader(selection) {
			if (!selection.added) {
				for (const row of selection.rows) {
					const header = row.fileValue;
					const matchingHeader = this.mappingValues[header].selected[header];

					if (matchingHeader === "unit") {
						this.unitHeaderName = undefined;
					}

					// Re-map the mapped header to its original values
					if (
						this.validHeaders.includes(matchingHeader) &&
						matchingHeader !== "site"
					) {
						this.validHeaders.splice(
							this.validHeaders.indexOf(matchingHeader),
							1,
						);

						this.mappingValues[matchingHeader].selected = {};
						for (const value of this.fileData.uniqueValues[matchingHeader] ??
							[]) {
							this.mappingValues[matchingHeader].selected[value] =
								this.mappings[matchingHeader].includes(value) ? value : null;
						}
					}

					// Reset the de-selected header map
					this.mappingValues[header].selected = {};

					if (matchingHeader) {
						await this.deleteMapping("invalidHeaders", header, matchingHeader);
					}
				}
			}
		},

		// Handles the mapping selection of invalid headers
		handleInvalidHeaderSelection(matchingValue, invalidHeader) {
			if (matchingValue && !this.selectedHeaders.includes(invalidHeader)) {
				this.selectedInvalidHeaders.push({ fileValue: invalidHeader });
			}
		},

		// Checks to see if a header is mapped correctly
		validHeaderMapping(header) {
			if (!this.mappingValues[header]?.selected) {
				return false;
			}
			// No matching value is null/undefined
			return (
				Object.keys(this.mappingValues[header].selected).length > 0 &&
				Object.values(this.mappingValues[header].selected).every(
					(matchingValue) => matchingValue != null,
				)
			);
		},

		/** --- Loading Mapping Data --- */

		/**
		 * @desc Builds this.mappingValues object
		 */
		initMatchingValues() {
			// Reset Mapping Values
			this.mappingValues = {};

			const skip = new Set(
				this.ignoreHeaders.concat(this.fileData.invalidHeaders),
			);
			this.validHeaders = this.fileData.validHeaders?.filter(
				(header) => !skip.has(header),
			);

			// Add any other headers that need a mapping section
			for (const header of this.otherHeadersToMap) {
				if (this.hasOtherHeaderSelected(header)) {
					this.validHeaders.push(header);
				}
			}

			for (const header of this.fileData.optionalHeaders ?? []) {
				if (
					Object.keys(this.fileData.uniqueValues).includes(header) && !this.validHeaders.includes(header) && !this.ignoreHeaders.includes(header)
				) {
					this.validHeaders.push(header);
				}
			}

			for (const header of this.uniqueHeaders) {
				if (this.fileData.invalidHeaders.includes(header)) {
					const options = this.invalidHeaderOptions();
					this.mappingValues[header] = {
						filtered: [...options],
						original: options,
						selected: {},
					};
				} else {
					if (header === "unit") {
						this.unitHeaderName = "unit";
						continue;
					}

					this.mappingValues[header] = {
						filtered: [...(this.mappings?.[header] ?? [])],
						original: this.mappings?.[header] ?? [],
						selected: {},
					};
				}
			}
		},

		/**
		 * @desc Autofill any values where:
			- The column header and value matches a previously saved user mapping
			- The column header and value matches GreenHalo values
			- If neither of these conditions can be satisfied, then
			- this.mappingValues[header][value] is set to null which will require the
			- user to provide a mapping for this given header-value combo.
		*/
		async loadSavedMappings() {
			await this.initSavedMappings();
			for (const header in this.mappingValues) {
				// Auto-fill any saved and correctly mapped values
				for (const value of this.fileData.uniqueValues[header] ?? []) {
					let matchingValue = null;

					if (this.savedMappings[header]?.[value]) {
						// If the value has already been mapped by the user
						matchingValue = this.savedMappings[header][value];
					} else if (this.mappings[header]?.includes(value)) {
						// If the value already correctly matches a value in GreenHalo
						matchingValue = value;
					} else if (this.savedMappings.invalidHeaders?.[header]) {
						// If the header is an invalid header and there is a saved mapping for it
						const savedMapping = this.savedMappings.invalidHeaders?.[header];
						const headerOptions = this.invalidHeaderOptions().map(
							(option) => option.value,
						);
						if (savedMapping && headerOptions.includes(savedMapping)) {
							this.mappingValues[header].selected[header] = savedMapping;
						}
					}
					// mappingValues[header].selected[value] will always have a value
					// because the current validation implementation requires a key to exist
					this.mappingValues[header].selected[value] = matchingValue;
				}
			}
		},

		/**
		 * Initializes the mapping for valid headers.
		 * Checks if the headers mapping is valid, then initializes matching values and sets default header names.
		 * Iterates through the mapping values to set emission factor and unit headers.
		 * If the header is valid and does not exist in mappingValues, it initializes the mapping and sets selected values.
		 * Emits an event to generate a map for emission factor and unit columns and advances the stepper when done.
		 */
		async initMappingValidHeaders() {
			if (this.validInvalidHeadersMapping()) {
				this.emissionFactorHeaderName = this.defaultEmissionFactorHeaderName;

				for (const header in this.mappingValues) {
					const matchingHeader = this.mappingValues[header].selected[header];

					// We need to take special note if the header that's mapped was
					// the emission factor, as this mapped column header is passed to
					// the separate ef/unit mapping component
					if (matchingHeader === "emissionfactor") {
						this.emissionFactorHeaderName = header;
					} else if (matchingHeader === "unit") {
						this.unitHeaderName = header;
					} else if (
						this.hasOtherHeaderSelected(matchingHeader) &&
						!this.validHeaders.includes(matchingHeader)
					) {
						this.validHeaders.push(matchingHeader);
					}

					// Autofills if header is newly selected
					if (this.validHeaders.includes(matchingHeader)) {
						this.mappingValues[matchingHeader] = {
							filtered: [...this.mappings[matchingHeader]],
							original: this.mappings[matchingHeader],
							selected: {},
						};

						for (const value of this.fileData.uniqueValues[header]) {
							const savedMapping = this.savedMappings[matchingHeader]?.[value];
							if (savedMapping) {
								this.mappingValues[matchingHeader].selected[value] =
									savedMapping;
							} else {
								this.mappingValues[matchingHeader].selected[value] =
									this.mappings[matchingHeader].includes(value) ? value : null;
							}
						}
					}
				}

				await this.generateEmissionFactorUnitMap();
				this.$refs.stepper.next();
			}
			
		},

		validInvalidHeadersMapping() {
			const matchingValues = [];

			for (const header of this.fileData.invalidHeaders) {
				if (!this.selectedHeaders.includes(header)) {
					continue;
				}

				const matchingValue = this.mappingValues[header].selected[header];

				if (matchingValue) {
					matchingValues.push(matchingValue);
				}
			}

			// This is to allow multiple selections of metadata
			const matchingValuesNoDupeMetadata = this.removeDuplicates(
				matchingValues,
				"metadata",
			);

			if (matchingValues.length < this.selectedHeaders.length) {
				notify.warning(
					"Please enter a matching value for all selected headers",
					"top",
					"Missing Input",
				);
				return false;
			}

			if (
				matchingValuesNoDupeMetadata.length !==
				new Set(matchingValuesNoDupeMetadata).size
			) {
				notify.warning(
					"Please ensure there are no duplicates",
					"top",
					"Duplicate Headers",
				);
				return false;
			}

			const currentValidHeaders = new Set(
				Object.keys(this.fileData.uniqueValues)
					.filter((header) => this.fileData.validHeaders.includes(header))
					.concat(matchingValues),
			);
			const missingHeaders = [];

			for (const header of this.fileData.validHeaders) {
				if (!currentValidHeaders.has(header)) {
					const formattedHeader =
						header === "emissionfactor" ? "Emission Factor" : startCase(header);
					missingHeaders.push(formattedHeader);
				}
			}

			if (missingHeaders.length > 0) {
				notify.warning(
					`Missing headers: ${missingHeaders.join(", ")}`,
					"top",
					"Missing File Headers",
				);
				return false;
			}

			return true;
		},

		/** --- Mapping Helper Functions ---  */

		/**
		Calls server to save a new mapping between a client and app label, under a given
		column.
		*/
		async updateMapping(columnName, clientLabel, appLabel) {
			try {
				await api.manualBulkUpload.updateSingleMapping({
					columnName,
					clientLabel,
					appLabel,
				});
			} catch (err) {
				console.error(
					`Error updating bulk upload mapping between '${clientLabel}' and '${appLabel}'`,
				);
				console.error(err.message);
			}
		},

		async deleteMapping(columnName, clientLabel, appLabel) {
			await api.manualBulkUpload.deleteSingleMapping(
				columnName,
				clientLabel,
				appLabel,
			);
		},

		/** --- Table Helper Functions ---  */

		filterFn(inputVal, update, header) {
			update(() => {
				const needle = inputVal.toLowerCase();

				// If the options are objects
				if (typeof this.mappingValues[header].original[0] === "object") {
					this.mappingValues[header].filtered = this.mappingValues[
						header
					].original.filter((option) =>
						option.label.toLowerCase().includes(needle),
					);
				} else {
					this.mappingValues[header].filtered = this.mappingValues[
						header
					].original.filter((value) => value.toLowerCase().includes(needle));
				}
			});
		},

		getRows(header) {
			const returnRows = [];

			if (this.hasInvalidHeaders) {
				for (const invalidHeader of this.fileData.invalidHeaders) {
					const matchingHeader =
						this.mappingValues[invalidHeader].selected[invalidHeader];
					if (header === matchingHeader) {
						for (const value of this.fileData.uniqueValues[invalidHeader]) {
							if (value) {
								returnRows.push({
									fileValue: value,
								});
							}
						}

						break;
					}
				}
			}

			if (returnRows.length === 0) {
				for (const value of this.fileData.uniqueValues?.[header] ?? []) {
					if (value) {
						returnRows.push({
							fileValue: value,
						});
					}
				}
			}

			return returnRows;
		},

		// Function to remove duplicates of a specific element in an array
		removeDuplicates(array, element) {
			// If value is not the selected element or it's the first occurrence, include it in the new array
			return array.filter(
				(value, index) => value !== element || index === array.indexOf(element),
			);
		},

		/** --- Upload (Finished Mapping)  --- */

		/**
			Returns unit mappings for only the emission factors that the user has mapped.
			e.g. If user only mapped 'Electricity Grid Average' and 'Waste Average', grab those
			and add them to the efUnitMappings object.
		*/
		getOnlyMappedEmissionFactorUnits(efMappings, allEFUnitMappings) {
			const efUnitMappings = {};
			for (const ef of Object.values(efMappings)) {
				efUnitMappings[ef] = allEFUnitMappings[ef];
			}
			return efUnitMappings;
		},

		/* Calls file upload emit method, passing 
		mappings determined in this component*/
		upload() {
			const invalidHeadersMap = {};
			const returnHeaders = this.selectedHeaders.concat(this.validHeaders);
			const returnMappings = {};
			const mappings = this.mappingValues;

			for (const [column, mapping] of Object.entries(mappings)) {
				// Skip headers that aren't selected
				if (!returnHeaders.includes(column)) {
					continue;
				}

				if (this.validHeaders.includes(column)) {
					returnMappings[column] = mapping.selected;
				} else if (this.fileData.invalidHeaders.includes(column)) {
					invalidHeadersMap[column] = mapping.selected[column];
				}
			}
			returnMappings.efUnitMappings = this.getOnlyMappedEmissionFactorUnits(
				returnMappings.emissionfactor,
				mappings.efUnitMappings.selected,
			);
			returnMappings.unitMappings = this.mappingValues.unitMappings.selected;
			this.$emit(
				"submitBulkFile",
				{ validHeadersMap: returnMappings, invalidHeadersMap },
				this.hasMultipleSheets ? this.selectedSheet : "",
			);
		},
	},
};
</script>

<style lang="less">
.bulk-upload-mapping-container {
    padding: 5px;
    min-width: 40vw;
    display: flex;
    flex-direction: column;

    .q-stepper__header {
        display: none;
    }

    .bulk-upload-mapping-table {
        height: 45rem;

        .q-table tbody td:after {
            background: initial;
        }

        tr:nth-child(even) {
            background: initial;
        }

        .q-checkbox__bg {
            color: var(--primary);
        }
    }
    
    .bulk-upload-mapping-title {
        line-height: normal;
        margin: 0;
        font-size: 30px;
        font-weight: bold;
    }

    .bulk-upload-mapping-buttons {
        display: flex;
        justify-content: flex-end;
    }

    .bulk-upload-mapping-selection {
        width: 100%;
    }

    .highlight-emission-factor{
        box-shadow: 0 0 0 2px rgba(2, 136, 209, 0.5) !important;
    }

}
</style>
