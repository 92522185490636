<template>
          <!-- Table -->
      <div class="orgs-connected-container">
        <q-card>
            <q-table
              class="org-table q-pa-md"
              :columns="columns"
              :rows="rows"
            >
            
            <template v-slot:body="props">
            <q-tr v-if="!props.row.data" :props="props"> 
                <q-td>
                    <q-spinner color="primary" size="3em" />
                </q-td>
            </q-tr>

            <q-tr v-else >
                <q-td>
                    {{ props.rowIndex + 1}}
                </q-td>
              <q-td>
                    {{ props.row.data.company.name }}
                </q-td>

                <q-td>
                    {{ props.row.data.company.industry }}
                </q-td>

                <q-td>
                    {{ props.row.data.company.FTE }}
                </q-td>
                <q-td>
                    {{ props.row.data.company.address }}
                </q-td>
                <q-td>
                    {{ props.row.data.emissionData.total.toFixed(2) }} <span style="font-size: 0.65rem; color: #999;">CO2e</span>
                </q-td>     
                <q-td>
                    <q-btn
                    
                    icon="visibility"
                    flat
                    @click="openGuestTab(props.row.sourceTenantId)"
                    />
                    
                </q-td>


            </q-tr>
            </template>

        <!-- From Date -->
        <template v-slot:top-left>
            <q-btn-dropdown class="dropdown q-mb-sm"  no-caps :label="period.label" color="grey-6" text-color="white">
                <q-list>
                    <q-item clickable v-close-popup @click="period={value: 'day', label: 'Last Day'}">
                        <q-item-section>
                            <q-item-label>Last Day</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="period={value: 'week', label: 'Last Week'}">
                        <q-item-section>
                            <q-item-label>Last Week</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="period={value: 'month', label: 'Last Month'}">
                        <q-item-section>
                            <q-item-label>Last Month</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item clickable v-close-popup @click="period={value: 'year', label: 'Last Year'}">
                        <q-item-section>
                            <q-item-label>Last Year</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>
        </template>
            </q-table>

        </q-card>
      </div>
  </template>
  
  <script>

    export default {
        props:['data'],
      data() {
        return {
            period: { label: 'Week', value: 'week' },
            columns: [
                {
                    name: 'index',
                    label: '#',
                    field: 'index',
                    align: 'left'
                },
                {
                    name: 'id',
                    required: true,
                    label: 'Organisation Id',
                    align: 'left',
                    field: row => row.id,
                    sortable: true
                },
                {
                    name: 'industry',
                    required: true,
                    label: 'Industry',
                    align: 'left',
                    field: row => row.industry,
                    sortable: true
                },
                {
                    name: 'fte',
                    required: true,
                    label: 'FTE',
                    align: 'left',
                    field: row => row.fte,
                    sortable: true
                },
                {
                    name: 'address',
                    required: true,
                    label: 'Address',
                    align: 'left',
                    field: row => row.address,
                    sortable: true
                },
                {
                    name: 'totalEmissions',
                    required: true,
                    label: 'Total Emissions',
                    align: 'left',
                    field: row => row.totalEmissions,
                    sortable: true
                },
                {
                    name: 'drillDown',
                    required: true,
                    label: 'Drill Down',
                    align: 'left',
                    sortable: true
                }
            ],
            
        }
      },
      
      watch: {
        period(){
            this.$emit('period', this.period.value)
        }
      },


      computed: {
        rows() {
            if(this.data){
                return Object.values(this.data).sort((org1, org2) => org1?.data?.emissionData?.total - org2?.data?.emissionData?.total);
            } else {
                return this.data
            }
        }
         
      },
      methods: {
        openGuestTab(sourceTenantId) {
            const routeData = this.$router.resolve({name: 'guestLogin', query: {sourceTenantId: sourceTenantId}});
            window.open(routeData.href, '_blank')
        }
      
      }
    }
  </script>
  
  <style lang="less" src="@/assets/styles/organisationLeaderboard.less" />