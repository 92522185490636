import { createApp } from 'vue'
import App from './App.vue'
import FontAwesomeIcon from "./assets/fontawesome.js";
import store from './store'
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { PiniaSharedState } from 'pinia-shared-state';
import { emitter } from './config/emitter';
import flags from './config/featureFlags.config.json';
// const emitter = mitt();

import './assets/styles/style.css';

import router from './routes'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
pinia.use(
    PiniaSharedState({
      // Enables the plugin for all stores. Defaults to true.
      enable: true,
      // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
      initialize: false,
      // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
      type: 'localstorage',
    }),
  );

app.use(Quasar, quasarUserOptions);

app.config.globalProperties.$malInstance = {};
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.flags = flags;
app.use(VueShepherdPlugin)
app.use(pinia);
app.use(router);
app.use(store);


app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app');
