<template>
	<q-select
		:display-value="displayValue"
		hide-dropdown-icon
		:model-value="selectedTags"
	>	
		<q-tooltip v-if="modelValue && modelValue.length !== 0" >{{modelValue.join(', ')}}</q-tooltip>
		<template v-slot:append>
			<q-btn dense rounded color="secondary" :loading="loading" flat icon="new_label" >
				<q-popup-proxy>
					<q-card style="width: min-content;">
						<q-card-section class="column">
							<q-select 
								ref="new_tag_select"
								class="q-mb-md" 
								dense 
								use-input
								outlined
								multiple
								maxlength="30"
								placeholder="Add new tag..." 
								hide-selected
								:options="filteredTags"
								:model-value="selectedTags"
								@input-value="$refs.new_tag_select.setOptionIndex(-1)"
								@update:model-value="(val) => selectedTags = val"
								@new-value="(val, done) => $emit('new-tag', val, done)"
								@filter="(val, update) => filterFn(val, update)"
							>
								<template v-slot:option="scope">
									<q-item v-close-popup
										v-bind="scope.itemProps"
									>
										<q-item-section>
											<q-item-label v-html="scope.label"></q-item-label>
										</q-item-section>
									</q-item>
								</template>
							</q-select>
							<span>Selected Tags:</span>

							<div style="max-height: 20vh;" class="scroll">
								<q-chip v-for="tag in selectedTags" :key="tag" removable @remove="removeTag(tag)">{{tag}}</q-chip>
							</div>
						</q-card-section>
					</q-card>
				</q-popup-proxy>
			</q-btn>
		</template>
	</q-select>
</template>
<script>

export default {
	name: 'AddTagsComponent',
	props: {
		modelValue: {
			type: Array,
		},

		loading: {
			type: Boolean
		},
	
		availableTags: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			filteredTags: [] 
		}
	},
	emits: ['update:modelValue', "new-tag"],
	methods: {
		/**
		 * Given a UUID, set the unit id for that row to the default.
		 * @param {String} uuid - UUID to retrieve default for
		 * @param {Object} row - Row to update
		 */
		filterFn(val, update) {
			update(() => {
				const needle = val.toLowerCase();
				this.filteredTags = this.availableTags.filter(
					option => option.toLowerCase().includes(needle)
				);
			});
		},


		removeTag(tag) {
			const index = this.selectedTags.indexOf(tag)
			this.selectedTags.splice(index, 1)
		},
	},

	computed: {
		displayValue() {
			const tags = this.selectedTags ? this.selectedTags.length : 0;
			return `${tags} ${tags === 1 ? 'Tag' : 'Tags'} Selected`
		},

		selectedTags: {
			get() {
				return this.modelValue ?? [];
			},
			set(val) {
				this.$emit('update:modelValue', val);
			}
		}
	},
}
</script>
<style scoped/>