<template>
    <div class="dashboard-help-container">

        <p class="text-h5">Overview</p>

        The GreenHalo homepage contains several components that provide insights into your organization's emission sources. The purpose of this page is to provide insight into the different components displayed.
        
        <p class="text-h6 q-mt-md">Dashboard</p>

        The GreenHalo dashboard provides a quick insight into your organization's emissions. You can view the dashboard at three different periods:
        <ul>
            <li>Weekly</li>
            <li>Monthly</li>
            <li>Yearly</li>
        </ul>
        
    
        In addition to selecting a period, you can also select how you'd want to view the emission data. The default is the carbon output.

        <p class="q-mt-md">Be aware, the value shown is the difference in the amount for current period against the amount for the period before that.</p>

        <p class="q-mt-md text-weight-bold">Carbon Output: <a href="https://environment.govt.nz/publications/measuring-emissions-a-guide-for-organisations-2022-quick-guide/" target="_blank">2022 MFE Guide</a></p> 

        <ul>
            <li>Every emission source has a corresponding carbon amount. This is calculated by multiplying the emissions usage by its carbon conversion rate (the amount of kilogram carbon dioxide equivalent per unit of the given emission source).</li>
            <li>The number displayed is the total emission carbon output for the selected period. If weekly is selected, it is the amount of carbon generated for the past 7 days.</li>
            <li>It also displays a percentage change. Percentage change is the relative difference between an old value and a new value. As we are dealing with carbon emission, a positive value represents an increase over time and is therefore seen as negative. Therefore, the percentage change is highlighted in red. 
                A negative value represents a decrease over time, indicating that your organizations usage has decreased for the emission source. A decrease is highlighted in green. The percentage change adjusts for the selected period accordingly.</li>
        </ul>

        <p class="q-mt-md text-weight-bold">Carbon Cost / Finance: <a href="https://www.carbonnews.co.nz/tag.asp?tag=Carbon+prices" target="_blank">Resource</a></p>

        <ul>
            <li>Carbon pricing is an instrument that captures the external cost of greenhouse gas (GHG) emissions. For example, if one ton of CO2 emissions costs the public $100, it should cost $100 for your organisation to emit that ton of CO2.</li>
            <li>On the homepage, we multiply an emissions carbon amount by the current carbon price to display the carbon cost of that emission source. You are also able to define your own carbon price. 
                A negative carbon price indicates that you have decreased your usage for the emission source, and therefore “saved money” as you will be paying less for the carbon you've emitted compared to the previous period.</li>
        </ul>

        <p class="q-mt-md text-weight-bold">Tree Removals: <a href="https://www.encon.eu/en/calculation-co2-offsetting-trees" target="_blank">Resource</a></p>
        <ul>
            <li>It is widely agreed that offsetting carbon emissions can be achieved by planting trees. Trees extract CO2 from the air and convert it into oxygen and plant material through photosynthesis. To compensate for 1 tonne of CO2, it can be concluded that 31 to 46 trees are needed/planted.</li>
            <li>Within GreenHalo, we convert an emission source's usage into its carbon dioxide equivalent and divide by 1000 to retrieve its tonnage value. We then multiply this value by 38 (an average of the 31 to 46 trees required to offset a tonne) to retrieve the number of trees required to offset the carbon amount.</li>
            <li>If the carbon usage for a given emission source has increased over the selected period, it is displayed as you are 'removing' trees (a bad thing). This is because you have increased your emission output, and therefore the equivalent of your organisation removing that many trees.</li>
            <li>If the carbon usage for a given emission source has decreased over the selected period, it is displayed as you 'planting' trees (a good thing). This is because you have decreased your emission output over that period, which is the equivalent of planting that many trees.</li>
        </ul>
            

        <p class="q-mt-md text-weight-bold">Car: <a href="https://rightcar.govt.nz/environmental-ratings" target="_blank">Resource</a></p>
        <ul>
            <li>A typical passenger vehicle emits about 4.6 metric tonnes of carbon dioxide per year. Therefore, we can convert an emissions usage into its car usage equivalent.</li>
            <li>To calculate this, we divide the emissions of carbon usage by 4600 to determine its car equivalence. If weekly or monthly is selected for the period, we then divide the car equivalence by 52 (weeks in a year) or 12 (months in a year) to achieve the car equivalence for that period.</li>
            <li>If the change for the emission source is greater for the most recent period compared to the previous, it is shown as adding cars (a bad thing) to the road.</li>
            <li>If you have decreased you emission output for the given period, it is shown as removed that many cars from the road.</li>
        </ul>

        <p class="text-h6 q-mt-md">Emission Contributors</p>

        The emission contributors graphs show your total CO2e emission contributions for the selected period, separated by the scope of the emission. Essentially, scope 1 are those direct emissions that are owned or controlled by a company, 
        whereas scope 2 and 3 indirect emissions are a consequence of the activities of the company but occur from sources not owned or controlled by it. For example, Air Travel is considered scope 3, as the emissions emitted aren't controlled by your organisation.
    </div>
</template>

<script>
const DashboardHelpComponent = {
	name: "Dashboard Help",
	
	data() {
		return {
				
        };
	},

};

export default DashboardHelpComponent;
</script>

<style lang="less">
@import "../../assets/styles/home.less";
</style>