<template>
	<div class="tutorial-container">
		<div class="tutorial-header">Connect NZ Post API</div>
		<div class="tutorial-description">
			This guide details how to connect your NZ Post API and Subscription 
			key to GreenHalo. From here your freight data will be periodically
			collected and added to your account automatically.
		</div>
		
		<div class="tutorial-step">
			<p>To access your API, log into eShip <a href="https://eship.nzpost.co.nz/intro/nzpost.aspx" target="_blank">here.</a></p>
			<p>Select <strong>Settings</strong> on the bottom left on the screen.</p>
			<p>On the <strong>Settings</strong> page, select <strong>API</strong>.</p>
			<q-img src="../../../assets/tutorials/freight/eship2-1.png" width="20%"></q-img>
			<p>Your API Key and Subscription Key should be shown. You may need to regenerate if you haven't before.</p>
			<q-img src="../../../assets/tutorials/freight/eship2-2.png" width="100%"></q-img>
		</div>

	</div>
</template>
<script>
	export default {
	name: "EShipTutorial"
	}

</script>
<style scoped src="../../../assets/styles/dataSourceTutorial.css"/>
