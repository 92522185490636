<template>
  <div class="upload-manual-csv">
    <q-file
      class="upload-files"
      v-model="file"
      type="file"
      counter
      :input-style="{ height, }"
      :counter-label="({totalSize, filesNumber, fileLimit}) => `${filesNumber} files | ${totalSize}`"
      :max-files="maxFiles"
      :multiple="multiple || !!maxFiles"
      :accept="accept"
      :disable="disable"
      :max-file-size="maxFileSize"
      :max-total-size="maxTotalSize"
      @rejected="(rejectedEntries) => $emit('rejected', rejectedEntries)"
      item-aligned
      clearable
    >
      <div v-if="!file" class="no-file-messsage-container cursor-pointer">
        <q-icon class="no-file-icon" name="upload_file" size="5rem" />
        <span class="no-file-message">Drag and drop files or Browse</span>
      </div>
    </q-file>

    <div v-if="!removeSubmit" class="manual-button-center">
      <q-btn
        class="file-input-button"
        no-caps
        color="secondary"
        :disabled="disableSubmit"
        @click="submitUpload()"
        label="Upload"
        icon-right="upload"
        :loading="loading"
      />
        <q-tooltip v-if="disableSubmit && disabledMessage">{{ disabledMessage }}</q-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Object, Array],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "15rem",
    },
    maxFiles: {
      type: [Number, String],
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: undefined,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: String,
      default: ""
    },
    removeSubmit: {
      type: Boolean,
      default: false,
    },
    maxFileSize: {
      type: [Number, String],
      default: undefined,
    },
    maxTotalSize: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    file: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
  methods: {
    submitUpload() {
      this.$emit('submit', this.file);
    },
  },
};
</script>

<style lang="less" src="@/assets/styles/dataManualUploadComponent.less" scoped />
