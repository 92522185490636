/**
 * airTravel.js
 * 
 * API for handling getting all air travel associated data
 */
import axiosInstance from "./axios-instance";


/**
 * @desc Retrieves air travel chart data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAirTravelChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/air-travel/chart/${granularity}`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Generate Air Travel CSV file
 * 
 * @param {Number} siteId site to retrieve data for 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAirTravelCSV = async function(siteId) {
    return axiosInstance({
        url: `/app/air-travel/csv`,
        method: 'GET',
        responseType: 'blob',
        params: {siteId}
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `air-travel-data.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
}


/**
 * @desc Create a new air travel flight information entry
 * 
 * @param {String} carrierCode AITA airline code e.g. NZ, JQ
 * @param {Object} payload the flight information to upload
 * @param {Number} siteId the siteId to upload to
 * @returns {Promise<AxiosResponse<any>>}
 */
const postAirTravelFlight = async function (carrierCode, payload, siteId){
    return await axiosInstance.post(`/app/air-travel/lookup/${carrierCode}`, payload, { params: { siteId }}).then(res => res);
}


/**
 * @desc Gets past year usage for air travel data
 * 
 * @param {Number} siteId site to retrieve data for 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearUsage = async function(siteId) {
    return await axiosInstance.get("/app/air-travel/insights/year/usage", { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year emissions for air travel data
 * 
 * @param {Number} siteId site to retrieve data for 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearEmissions = async function(siteId) {
    return await axiosInstance.get("/app/air-travel/insights/year/emissions", { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year total flight count
 * 
 * @param {Number} siteId site to retrieve data for 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearFlightCount = async function(siteId) {
    return await axiosInstance.get("/app/air-travel/insights/year/flight-count", { params: { siteId } }).then(res => res)
}


/**
 * @desc Gets past year average flight distance
 * 
 * @param {Number} siteId site to retrieve data for 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getPastYearAvgFlightDistance = async function(siteId) {
    return await axiosInstance.get("/app/air-travel/insights/year/average-flight-distance", { params: { siteId } }).then(res => res)
}


export default {
    getAirTravelChart,
    getAirTravelCSV,
    postAirTravelFlight,

    getPastYearUsage,
    getPastYearEmissions,
    getPastYearFlightCount,
    getPastYearAvgFlightDistance
}