<template>
  <div class="data-traceability-container">
    <div class="header">
      <q-btn
        flat
        round
        dense
        size="md"
        color="primary"
        icon="arrow_back"
        @click="() => {
          dataStore.$reset();
          store.changeView('default');
        }"
        />
      <div class="main-heading">
        Data Tracing
      </div>
    </div>

  
    <!-- Data View -->
    <div class="data-view row">

      <div class="info-section col">
        <!-- Traceability File Component -->
        <q-card class="file-box">
            <TraceabilityFilesComponent/>
        </q-card>

        <!-- Traceability Small Chart Component -->
        <q-card class="graph-box">
            <TraceabilityGraphComponent />
        </q-card>
      </div>


      <!-- Traceability Data Table Component -->
      <q-card v-if="selectedTraceDataFileId" class="data-box col">
        <div class="q-pa-md" >
          <q-btn
            dense
            flat
            icon="close"
            size="20px"
            @click="this.dataStore.changeTraceDataFileId('')"
          />
          
          <TraceabilityDataTableComponent  :emissionSource="emissionSource"/>
  
        </div>
      </q-card>
    </div>

  </div>
</template>

<script>
// Store
import { useChartStateStore } from "@/stores/chartState.store";
import { useDataStateStore } from "@/stores/dataState.store";

//Components
import TraceabilityFilesComponent from "./TraceabilityFilesComponent.vue";
import TraceabilityDataTableComponent from "./TraceabilityDataTableComponent.vue";
import TraceabilityGraphComponent from "./TraceabilityGraphComponent.vue";

// Helpers
import union from "lodash/union";


const TraceabilityViewComponent = {
  name: "TraceabilityView",
  props: ["emissionSource"],

  components: {
    TraceabilityFilesComponent,
    TraceabilityGraphComponent,
    TraceabilityDataTableComponent,
  },
  data() {
    return {
      anzcoFeatureFlag: process.env.VUE_APP_FEATURE_FLAG_ANZCO,
      store: useChartStateStore(),
      dataStore: useDataStateStore(),
      dataTableFileId: "",
      selectedFile: undefined,
      selectedFilename: "",
    };
  },
  computed: {
    selectedTraceDataFileId(){
      return this.dataStore.selectedTraceDataFileId
    },

    // The unique file ids for the selected data point
    fileIds() {

        const chartData = this.store.chartData[this.store.selectedUnit]

        const labels = chartData ? Object.keys(chartData) : [];
        const index = labels.indexOf(this.store.selectedLabel)
        let fileIds = [];

        if(index != -1){
          var dataPoint = Object.entries(this.store.chartData[this.store.selectedUnit][labels[index]])

        for(const dataType of dataPoint) {
                fileIds =  union(fileIds, dataType[1].fileId);
          }
        }
      return fileIds
    }

  },

  mounted() {
    // Clear selected file when loading page if file id not in provided file ids
    // Solves issue of files being visible for the wrong emission sources
    if (!this.fileIds.includes(this.selectedTraceDataFileId)) {
      this.selectedFile = undefined;
      this.selectedFilename = "";
      this.dataTableFileId = "";
      this.dataStore.changeTraceDataFileId("");
    }
  },


  methods: {
    clearLabel() {
      this.store.addLabel("");
    },

    handleDataTable(fileId = "", filename = "") {

      this.selectedFilename = filename;
      if (this.dataTableFileId === fileId) {
        this.dataTableFileId = "";
      } else {
        this.dataTableFileId = fileId;
      }
    },
  },
};

export default TraceabilityViewComponent;
</script>

<style lang="less" scoped src="../../../assets/styles/traceability.less"/>

