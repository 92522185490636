<template>
    <div>
        <div class="organisation-dashboard-sub-title">Year On Year</div>
        <div class="organisation-comparison-chart-container">
            <div class="organisation-comparison-chart-options">
                <q-select filled dense label-color="deep-purple-4" v-model="selectedEmissionSource" option-label="sourcedisplayname" :options="this.emissionSources"
                    label="Select Emission Source" style="width: 200px" />
            </div>
            <div class="organisation-comparison-chart" v-if="!loading">
                <Line :chartData="data['Kg CO₂e']" :chartOptions="chartOptions" />
            </div>
        </div>
    </div>
</template>

<script>
import Chart from "../ChartComponents/chartGenerators/chart.js";
import { useChartStateStore } from '@/stores/chartState.store';
import api from "@/services/api/api"
import notify from "@/services/util/notify";

import Line from "@/components/ChartComponents/Line.vue";
import * as lineChartOptions from "@/components/ChartComponents/ChartOptions/LineOptions.js";
const OrganisationComparison = {
    name: "OrganisationComparison",

    props: ["emissionSources"],

    components: { Line },

    data() {
        return {
            store: useChartStateStore(),
            loading: false,

            // Set end date as current date by default
            endDate: new Date().toISOString().split("T")[0],

            selectedOrganisations: [],
            selectedEmissionSource: {sourcedisplayname: 'Electricity', urlName: 'electricity'},
            data: {}
        };
    },

    beforeMount() { 
        this.updateChartData()
    },

    computed: {

        /**
         * @desc generates graph options for the site graphs on this report page.
         * The options for these graphs are pretty specific to this report, so this method is just
         * housed in this component.
         *
         * @param {String} site determines graph title.
         * @returns {Object} Chart options for site graph
         */
        chartOptions() {
            return lineChartOptions.getYearOnYearLineOptions(
                "Month",
                "Kg CO₂e",
                `Year on Year ${this.selectedEmissionSource.sourcedisplayname} Usage`,
                "Kg CO₂e"
            )
        },
    },

    watch: {
        selectedEmissionSource() {
            this.updateChartData();
        }
    },

    methods: {
        /**
         * @desc calls the chart generator for orgnaisation year on year comparison. 
         * We use the response for all tenancy data, and format to the required format for the year on year chart data generator
         * @return {Object} chart data
         */
        async updateChartData() {
            this.loading = true 
            try {
                this.data = await api.emissions.getYearOnYearOrganisationChartData(this.selectedEmissionSource.urlName);
                this.data = Chart.generateChartData(this.data.data.chartData);
            } catch (error) {
                this.loading = false
                notify.error(error.response.data)
            } finally {
                this.loading = false
            }

        },
    },
};

export default OrganisationComparison;
</script>

<style lang="less">
@import "@/assets/styles/organisationDashboard.less";
</style>
