<template>
	<div class="tutorial-container">
      <div class="tutorial-header">Connect Z Fuel Card</div>
      <div class="tutorial-description">
        This guide details how to set up a Z Fuel Card scheduled report to be
        sent to our GreenHalo inbox. From here the reports will be scraped for
        transport fuel usage data which will be added to your account
        automatically.
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          1
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/login.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Navigate to the
          <a
            class="outgoing-link"
            href="https://zbusinessonline.co.nz/IFCSWeb-zen/faces/secure/home/homepage.xhtml"
            target="_blank"
            >Z login portal.
            <q-icon
              name="login"
              style="text-decoration: underline; text-underline-offset: -1px"
          /></a>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          2
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/homepage.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Once logged in, hover over <b>Reports</b> and click on
          <b>Scheduled Reports</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          3
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/reportsSearch.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Click on the <b>ADD A SCHEDULED REPORT</b> button.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          4
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/reportType.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Under the <b>Report Type</b> dropdown, select <b>DTE-B</b>.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">5</div>
        <div class="tutorial-step">
          You may name the <b>Schedule Name</b> whatever you like, and select
          whatever Report Schedule <b>Frequency</b> best fits your needs.
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          6
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/deliveryType.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Under the <b>Delivery Type</b> dropdown, select <b>Email</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          7
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/emailAddress.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Set the <b>Email Address</b> the report is sent to as
          <b>reports@greenhalo.nz</b>
        </div>
      </div>

      <div class="tutorial-step">
        <div class="tutorial-step-title">
          8
          <q-icon name="help" color="grey" size="sm">
            <q-tooltip>
              <q-img
                style="width: 60vh"
                fit="fill"
                class="tutorial-img"
                src="@/assets/tutorials/fuel/z/saveButton.png"
              >
              </q-img>
            </q-tooltip>
          </q-icon>
        </div>
        <div class="tutorial-step">
          Finally, click the <b>Save</b> button. You should get a success
          message near the top of the window.
        </div>
      </div>
	</div>
</template>
<script>
  export default {
    name: "ZTutorial"
  }
</script>
<style scoped src="../../../assets/styles/dataSourceTutorial.css"/>