/**
 * CID.js
 * 
 * API for handling getting all custom intensity denominator associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Gets all custom intensity denominators for a tenancy
 * 
 * @returns a list of the tenancies intensity denominators
 */
const getAllCID = async function () {
    return await axiosInstance.get('/app/custom-intensity-denominators').then(res => res);
}

/**
 * @desc Gets all custom intensity denominators for a specific site
 * 
 * @param {Number} siteId The id of the site to get CID's for
 * 
 * @returns a list of the sites intensity denominators
 */
const getCIDBySite = async function (siteId) {
    return await axiosInstance.get(`/app/custom-intensity-denominators/${siteId}`).then(res => res);
}

/**
 * @desc Posts a new custom intensity denominator to tenancy
 * 
 * @param {Object} payload the CID data to post
 *      {
 *          name: "Puff Puff",
 *          default: 420,
 *      }
 * @returns {Promise<AxiosResponse<any>>}
 */
const postCID = async function(payload) {
    return await axiosInstance.post(`/api/custom-intensity-denominators`, payload).then(res => res);
};


/**
 * @desc Edits the custom denominators for a site
 * 
 * @param {Number} siteId The ID of the site
 * @param {Object} payload Custom denominaors to be edited
 *      {
 *          TEST: 40,
 *          VALUE: 30,
 *      }
 * @returns 
 */
const editSiteCIDs = async function(siteId, payload) {
    return await axiosInstance.patch(`/api/custom-intensity-denominators/${siteId}`, payload).then(res => res);
}
 

/**
 * @desc Deletes a custom intensity denominator given a provided UUID
 * 
 * @param {String} uuid The identify of the intensity denominator to delete 
 */
const deleteCID = async function (uuid) {
    return await axiosInstance.delete(`/api/custom-intensity-denominators/${uuid}`).then(res => res);
};



export default {
    getAllCID,
    getCIDBySite,
    postCID,
    editSiteCIDs,
    deleteCID
}