/**
 * EIEP.js
 * 
 * API for handling getting EIEP related data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Gets list of names and codes from public retailerIdentifier table
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getRetailerIdentifiers = async function() {
    return await axiosInstance.get(`/app/electricity-information-exchange/retailer-identifiers`).then(res => res);
};


/**
 * @desc Creates EIEP request file based on info in retailerIdentifier table
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const createEIEPRequestFile = async function(ICPIdentifier) {
    return await axiosInstance.post(`/app/electricity-information-exchange/13c`, {ICPIdentifier: ICPIdentifier}).then(res => res);
};


/**
 * @desc Gets a list of all the ICP meters in EIEPInformation table for a tenant
 * 
 * @param {Object} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEIEPMeterList = async function(payload) {
    return await axiosInstance.get(`app/electricity-information-exchange/13c/info`, payload).then(res => res);
};


export default {
    getRetailerIdentifiers,
    createEIEPRequestFile,
    getEIEPMeterList,
}