<template>
	<q-card style="max-width: 80%; min-height: 96.2vh">
		<q-card-section>
			<div class="text-h4" style="text-align: center;"> Advanced Control Over Invoices </div>
		</q-card-section>

		<q-card-section>
			Sometimes invoices you recieve will need to be split up. Whether it is because you
			only need a portion of the invoice for your business or whether you want to assign 
			portions of the invoice to different sites. 

			This feature aims to allow users to do exactly that but still have the data points be
			tied to the invoice itself for auditing purposes.
			<br><br>
			Further slides on this guide will illustrate
			how to use the Split Invoice feature.
		</q-card-section>
		<q-card-section>
			<SplitInvoiceHelpCarousel/>
		</q-card-section>

		<q-card-actions align="right">
			<q-btn flat label="OK" color="primary" v-close-popup />
		</q-card-actions>
	</q-card>
</template>


<script>

	import SplitInvoiceHelpCarousel from "./SplitInvoiceHelpCarousel.vue";

	export default {
		components: {
			SplitInvoiceHelpCarousel,
		},
	}
</script>

<style lang="less" src="@/assets/styles/bulkUpload.less" scoped />
