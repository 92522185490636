<template>
	<q-card flat>
		<div class="business-unit-mapping-header">
			Mapping GreenHalo sites to Toitu Business Unit
		</div>

		
			<div class="business-unit-mapping-container">
				<div class="site-select-container">
					<q-checkbox style="visibility: hidden" :model-value="false" />
					<span class="business-unit-mapping-title">Site Name</span>
				</div>
				<div class="business-unit-details">
					<q-icon name="arrow_forward" size="1.5rem" color="transparent" style="margin-right: 25px;" />
					<span class="business-unit-mapping-title text-left">Toitu Business Unit</span>
				</div>
			</div>

			<div class="separator-line"></div>

			<q-form @submit.prevent="nextPage()" ref="businessUnitForm">
				<q-scroll-area style="height: 30vh;">
					<div class="business-unit-mapping-container">
						<div class="site-select-container">
							<q-checkbox v-model="selectAll"/>
							<span class="site-name" >Select All</span>
						</div>
					</div>
					<div class="q-mb-md business-unit-mapping-container" v-for="(site, index) in this.siteList" :key="site"
						:name="index">
						<div class="site-select-container">
							<q-checkbox v-model="site.selected" />
							<span class="site-name" :style="{ color: site.selected ? 'black' : 'gray' }">{{ site.name }}</span>
						</div>
						<div class="business-unit-details">
							<q-icon name="arrow_forward" size="1.5rem" :color="site.selected ? 'black' : 'grey-4'"
								style="margin-right: 25px;" />
							<q-select 
								:disable="!site.selected" 
								class="toitu-audit-business-unit-input"
								:options="filteredBusinessUnits" 
								dense 
								style="margin-bottom: -20px;" 
								emit-value 
								map-options
								v-model="siteMappings[site.id]" 
								use-input
								outlined 
								hide-selected
								fill-input
								@filter="(val, update) => filterFn(val, update)"
								:rules="[(val) => (!!val && site.selected)]"
								/>
						</div>
					</div>
			</q-scroll-area>
			<div class="toitu-mapping-btn-container">
				<q-btn class="next" :loading="buttonLoading" :disable="siteSelected" no-caps color="secondary" padding="8px 20px" type="submit" :label="buttonLabel" />
			</div>
		</q-form>
	</q-card>
</template>

<script>
import api from "@/services/api/api.js";
import notify from "@/services/util/notify";

export default {
	props: {
		businessUnitMappings: {
			type: Object,
			required: true,
		},

		buttonLoading: {
			type: Boolean,
			required: true
		},

		buttonLabel: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			loadingSites: false,
			siteList: [],
			filteredBusinessUnits: [],
			thing: null,
			siteMappings: {},
			selectAll: true
		}
	},

	async mounted() {
		await this.getSites();
	},

	computed: {
		siteSelected() {
			return !this.siteList.filter((site) => site.selected).length > 0
		},
	},


	watch: {
		selectAll(newVal) {
			const siteListLen = this.numSitesSelected(this.siteList);
			if (newVal) {
				this.siteList.forEach((site) => site.selected = true);
			} else if (!newVal && siteListLen == this.siteList.length) {
				this.siteList.forEach((site) => site.selected = false);
			} else if (siteListLen <= 0) {
				this.siteList.forEach((site) => site.selected = false);
			}
		},

		siteList: {
			handler: function(after) {
				this.selectAll = this.numSitesSelected(after) >= after.length;
			},
			deep: true,
		}
	},

	methods: {

		/**
		 * @desc Filters business units for selecting
		 * @param {String} inputVal - The value to filter options by 
		 */
		filterFn(inputVal, update) {
            update(() => {
                const needle = inputVal.toLowerCase();
                this.filteredBusinessUnits = Object.keys(this.businessUnitMappings).filter((value) => value.toLowerCase().includes(needle));
            });
        },

		/**
		 * Returns the number of selected sites
		 * @param {Number} siteList Selected sites
		 */
		numSitesSelected(siteList) {
			return siteList.filter((site) => site.selected).length
		},


		/**
		 * @desc retrieves list of sites for site Id options
		 */
		async getSites() {
			this.loadingSites = true;

			api.sites.getSites()
				.then((res) => {
					this.siteList = res.data;
					this.siteList.forEach((site) => {
						site.selected = true;
					});
					this.buildSiteMappings(this.siteList);
				})
				.catch((err) => {
					console.error(err);
					notify.withObject(err.response);
				})
				.finally(() => this.loadingSites = false);
		},


		/**
		 * Handles mapping existing site mappings between GH sites and Toitu business units
		 * coming through from the incoming prop
		 */
		mapExistingSites () {
			const businessUnitMappingsMap = this.getBUMappingsMap();
			this.siteList.forEach((site) => {
				const siteId = parseInt(site.id);
				const bu = businessUnitMappingsMap.get(siteId);

				if (bu) {
					this.siteMappings[siteId] = bu;
				}
			});
		},


		/**
		 * Create a reversed map of the businessUnitMappings prop, returning a 
		 * new map that maps siteIds to business units.
		 */
		getBUMappingsMap () {
			let map = new Map();
			for (const [bu, siteIds] of Object.entries(this.businessUnitMappings)) {
				for (let siteId of siteIds) {
					map.set(parseInt(siteId), bu);
				}
			}
			return map;
		},

		/**
		 * Builds out the siteMappings object according to the site list retrieved from
		 * database and the businessUnitMappings object passed through as a prop. Return null if no mappings found.
		 */
		buildSiteMappings(siteList) {
			for (let site of siteList) {
				this.siteMappings[site.id] = this.getExistingMapping(site.id);
			}
			
			this.mapExistingSites();
		},


		/**
		 * Retrieves business unit mapped to given site id if one exists.
		 * If one doesn't exist, return null;
		 */
		getExistingMapping(siteId) {
			for (let unit of Object.keys(this.businessUnitMappings)) {
				if (this.businessUnitMappings[unit].includes(parseInt(siteId))) return unit;
			}
		},

		/**
		 * @desc Converts siteMappings to the required object format when the user goes to the
		 * next page.
		 * @example
		{
			'ANZCO Foods': [1],
			'ANZCO Foods': [2],
			'ANZCO Foods/ANZCO Foods Canterbur y': [],
			'ANZCO Foods/ANZCO Foods Eltham': [],
			'ANZCO Foods/ANZCO Foods Green Island': [],
			'ANZCO Foods/ANZCO Foods Kokiri': [],
			'ANZCO Foods/ANZCO Foods Manawatu': [],
		 */
		async nextPage() {
			let newMappings = {};
			for (const [siteId, toituBusinessUnit] of Object.entries(this.siteMappings)) {
				if (this.getSiteById(siteId).selected && toituBusinessUnit) {
					newMappings[toituBusinessUnit] ??= [];
					newMappings[toituBusinessUnit].push(siteId);
				}
			}

			for (const toituBusinessUnit of Object.keys(this.businessUnitMappings)) {
				newMappings[toituBusinessUnit] ??= [];
			}

			this.$emit("nextPage", newMappings );
		},


		/**
		 * Gets a site object by its ID.
		 * @param {number} siteId - The ID of the site to retrieve.
		 * @returns {Object} The found site object, or an object with `selected: false` if not found.
		 */
		getSiteById(siteId) {
			const siteListCopy = structuredClone(this.siteList);
			const foundSite = siteListCopy.filter((site) => site.id == siteId);
			if (foundSite.length >= 1) {
				return foundSite[0];
			} else {
				return { selected: false }
			}
		}
	},

}

</script>

<style lang="less" scoped src="@/assets/styles/auditToitu.less"/>
