<template>
  <div class="signup-container">
    <h1 class="main-heading">Tell us more about your business</h1>
    <q-form ref="signupDetailForm">
      <div class="inputs-container">
        <q-input
          class="detail-input"
          outlined
          stack-label
          v-model="store.company.companyName"
          label="Business name"
          :rules="[(val) => !!val || 'Please fill out this section']"
					maxlength="50"
        />

        <AddressInput class="detail-input" v-model="store.company.address" />

        <q-select
          class="detail-input"
          outlined
          stack-label
          v-model="store.company.primaryIndustry"
          label="Primary industry"
          :options="industries"
          :rules="[(val) => !!val || 'Please select an industry']"
        />
        <q-input
          class="detail-input"
          outlined
          stack-label
          v-model="store.company.numSites"
          label="Number of sites"
          type="number"
          min="0"
          max="100"
          :rules="[
            (val) => parseInt(val) !== NaN || 'Please enter a number',
            (val) => val > 0 || 'Please enter a positive number',
            (val) => val % 1 === 0 || 'Please enter an integer',
          ]"
          required
        />
        <div
          class="detail-input"
          v-if="this.flags['FLAG_4863_FY_YEAR_SETTINGS']"
        >
          <p style="text-align: left; width: 100%">
			Carbon Financial Year end date (Optional)
          </p>
          <VueDatePicker
            input-class-name="date-picker"
			:disabled="loading"
			v-model="store.company.financialYearEndDate"
			format="dd/MM"
			text-input
			auto-apply
			/>
		</div>
	</div>
	</q-form>
  </div>
</template>

<script>
	import api from "@/services/api/api";
	import { useSignupStore } from "@/stores/signup.store";
	import AddressInput from "../FormComponents/AddressInput.vue";
	import VueDatePicker from "@vuepic/vue-datepicker";

	export default {
		name: "CompanyDetails",

		components: {
			AddressInput,
			VueDatePicker,
		},

		data() {
			return {
				addresses: [],

				store: useSignupStore(),

				industries: [
					"Accommodation and Food Services",
					"Administrative and Support Services",
					"Adult, Community and Other Education",
					"Advertising, Market Research and Management Services",
					"Agriculture, Forestry and Fishing Support Services and Hunting",
					"Arts and Recreation Services",
					"Arts, Recreation and Other Services",
					"Auxiliary Finance and Insurance Services",
					"Banking and Financing",
					"Basic Chemical and Chemical Product Manufacturing",
					"Basic Material Wholesaling",
					"Beverage and Tobacco Product Manufacturing",
					"Building Cleaning, Pest Control and Other Support Services",
					"Building Construction",
					"Civil, Professional and Other Interest Groups",
					"Clothing, Knitted Products and Footwear Manufacturing",
					"Commission Based Wholesaling",
					"Computer System Design and Related Services",
					"Construction Services",
					"Dairy Cattle Farming",
					"Department Stores",
					"Education and Training",
					"Electricity, Gas, Water and Waste Services",
					"Electronic and Electrical Equipment Manufacturing",
					"Employment and Other Administrative Services",
					"Fabricated Metal Product Manufacturing",
					"Fertiliser and Pesticide Manufacturing",
					"Finance",
					"Insurance Services",
					"Fishing and Aquaculture",
					"Food Product Manufacturing ",
					"Food and Beverage Services",
					"Forestry and Logging",
					"Furniture Manufacturing",
					"Gambling",
					"Grocery, Liquor and Tobacco Product Wholesaling",
					"Health Care and Social Assistance",
					"Heavy and Civil Engineering Construction",
					"Heritage and Artistic Activities",
					"Horticulture and Fruit Growing",
					"Hospitals",
					"Information Media and Telecommunications",
					"Insurance",
					"Legal and Accounting Services",
					"Life Insurance",
					"Machinery Manufacturing",
					"Machinery and Equipment Wholesaling",
					"Medical and Other Health Care Services",
					"Mining",
					"Motor Vehicle and Motor Vehicle Parts Wholesaling",
					"Non-Store and Commission Based Retailing",
					"Personal Care, Funeral and Other Personal Services",
					"Petroleum and Coal Product Manufacturing",
					"Pharmaceutical, Cleaning and Other Chemical Manufacturing",
					"Polymer Product and Rubber Product Manufacturing",
					"Postal and Courier Pick Up and Delivery Services",
					"Poultry, Deer and Other Livestock Farming",
					"Preschool Education",
					"Primary Metal and Metal Product Manufacturing",
					"Printing",
					"Professional, Scientific and Technical Services",
					"Property Operators and Real Estate Services",
					"Public Order, Safety and Regulatory Services",
					"Pulp, Paper and Converted Paper Product Manufacturing",
					"Rail, Water, Air and Other Transport",
					"Real Estate Services",
					"Recreational, Clothing, Footwear and Personal Accessory Retailing",
					"Rental and Hiring Services",
					"Repair and Maintenance",
					"Retail Trade and Accommodation",
					"Road Transport",
					"School Education",
					"Scientific, Architectural and Engineering Services",
					"Sheep, Beef Cattle and Grain Farming",
					"Sport and Recreation Activities",
					"Supermarket, Grocery Stores and Specialised Food Retailing",
					"Telecommunications, Internet and Library Services",
					"Tertiary Education",
					"Textile and Leather Manufacturing",
					"Transport, Postal and Warehousing",
					"Travel Agency and Tour Arrangement Services",
					"Veterinary and Other Professional Services",
					"Warehousing and Storage Services",
					"Water, Sewerage, Drainage and Waste Services",
					"Wholesale Trade",
					"Wood Product Manufacturing",
				],
			};
		},

		methods: {
			filterFn(val, update) {
				update(() => {
					api.sites
						.getAutoCompleteAddresses(val)
						.then((addresses) => (this.addresses = addresses));
				});
			},
		},
	};
</script>

<style lang="less">
@import "../../assets/styles/signup.less";
</style>
