<template>
    <div class="emission-contributors-component" >
        <div v-if="loading" class="loading">
            <q-spinner color="primary" size="6em"/>
        </div>

        <template v-else>
            <span class="title" > Emission Contributors </span>
            <span class="totals">({{ formatAmount(scope1Emissions + scope2Emissions + scope3Emissions) }})</span>
            <div class="body">
                <q-card class="body-data">
                    <div class= "graph-header" >
                        <span class="subtitle"> Scope 1 & 2 Total Emissions: </span>
                        <span class="totals"> {{ formatAmount(scope1Emissions + scope2Emissions) }}</span>
                    </div>
                    <ReportBarGraph :graphData="scope12ChartData"/>
                </q-card>

                <q-card class="body-data">
                    <div class="graph-header" >
                        <span class="subtitle" > Scope 3 Total Emissions: </span>
                        <span class="totals"> {{ formatAmount(scope3Emissions) }} </span>
                    </div>
                    <ReportBarGraph :graphData="scope3ChartData"/>
                </q-card>
            </div>
        </template>   
    </div>
</template>
<script>

import ReportBarGraph from '@/components/ReportsComponents/GraphComponents/ReportBarGraph.vue';
import api from "@/services/api/api";
import notify from '@/services/util/notify';
import { merge } from 'lodash/object';

export default {
    name: "EmissionContributorsComponent",
    props: ["sourceIds", "granularity", "siteId", "organisations"],
    components: {
        ReportBarGraph,
    },
    data() {
        return {
            loading: false,

            scope1Emissions: 0,
            scope2Emissions: 0,
            scope3Emissions: 0,

            scope12ChartData: {},
            scope3ChartData: {},
        }
    },


    mounted() {
        this.init();
    },
    watch: {
        granularity() {
            this.init();
        },
        siteId() {
            this.init();
        }
    },
    computed: {
        totalEmissions() {
            return this.scope1Emissions + this.scope2Emissions + this.scope3Emissions;
        },

        viewGranularity() {
            switch(this.granularity) {
                case "Monthly":
                    return "Month"
                case "Yearly":
                    return "Year"
            }
            return "Month"
        }
    },
    methods: {
        init() {
            this.loading = true;

            // Use source ids to get an array of scoped emission data based on connected tenants 

            this.scope1Emissions = 0
            this.scope2Emissions = 0
            this.scope3Emissions = 0

            this.scope12ChartData = {}
            this.scope3ChartData = {}


            const requests = []
            
            if (this.organisations) {
                const today = new Date();
                
                if (this.granularity === "Monthly") {
                    requests.push(api.data.getScopedEmissions(new Date(today.getFullYear(), today.getMonth(), 1), today, this.sourceIds[0]).then(res => res.data))
                } else if (this.granularity === "Yearly") {
                    requests.push(api.data.getScopedEmissions(new Date(today.getFullYear() - 1, today.getMonth(), 1), today, this.sourceIds[0]).then(res => res.data))
                }
            } else {
                requests.push(api.data.getScopedEmissionsWithGranularity(this.viewGranularity, this.siteId).then(res => res.data));
            }

            Promise.all(requests)
            .then(responses => {
                responses.forEach(data => {
                    this.scope12ChartData = merge(this.scope12ChartData, data['1'], data['2']);

                    this.scope3ChartData = merge(this.scope3ChartData, data['3']);

                    this.scope1Emissions += Object.values(data['1'] ?? {}).reduce((a, b) => a + b, 0)

                    this.scope2Emissions += Object.values(data['2'] ?? {}).reduce((a, b) => a + b, 0)

                    this.scope3Emissions += Object.values(data['3'] ?? {}).reduce((a, b) => a + b, 0)
                })
            }).catch(() => {
                notify.error('Unable to get Organisation Emission Contributions', 'top');
            }).finally(() => {
                this.loading = false;
            });
        },

        /**
         * Formats value to tonnes if it is over a 500 thousand kgs
         * @param {Number} val the emission value in kgs
         */
        formatAmount(val) {
            if (val >= 500000) {
                return  Number.parseFloat(((val / 1000).toFixed(2))).toLocaleString() + " Tonnes" ;
            }

            return Number.parseFloat((val.toFixed(2))).toLocaleString() + " kg"

        }

    }
}
</script>
<style lang="less" src="@/assets/styles/dashboard.less" />