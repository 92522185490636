/**
 * audit.js
 *
 * API for creating and returning the Toitu spreadsheet
 */
import axiosInstance from './axios-instance'

/**
 * @desc Generates and downloads the Toitu spreadsheet
 * @param reqBody object containing date and emission source selections
 * 
 * @returns {Promise<AxiosResponse<any>>}
 * @throws {Object} an object containing the status and message
 * {
 *   status: 500,
 *   data: "Error..." 
 * }
 */
const getToituAudit = async function(reqBody) {
    return axiosInstance({
        url: `/app/audit/toitu`,
        method: 'GET',
        responseType: 'blob',
        params: reqBody,
        timeout: 60000 // Timeout after 1 min
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const dateString = reqBody.startDate + (reqBody.startDate === reqBody.endDate ? "" : `_to_${reqBody.endDate}`);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `toitu_spreadsheet_${dateString}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(async (error) => {
        const errorMessage = JSON.parse(await error.response.data.text());
        throw {
            status: error.response.status,
            data: errorMessage
        };
    })
}


export default {
    getToituAudit,
}