<template>
  <div class="create-target-container">
    <div v-if="Object.keys(currentTarget).length === 0">
      <h2 class="create-target-heading">Set a Target</h2>
      <p>Set your Company's Emission Target below</p>
    </div>

    <div v-else>
      <h2 class="create-target-heading">Edit Target</h2>
      <p>Edit your Company's Emission Target below</p>
    </div>

    <q-form class="create-target-form" @submit="onSubmit">
      <div class="radio-btns-container">
        <q-option-group
          v-model="option"
          :options="[
            {
              label: 'Reduce By %',
              value: true
            },
            {
              label: 'Reduce To',
              value: false
            },
          ]"
          color="primary"
          inline
          required
        />
      </div>

      <div class="inputs-container">
        <div>
          <span class="input-field-label">Target name</span>
          <q-input class="target-name-field" outlined v-model="targetName" required maxlength="50" />
        </div>
        
        <div class="dates-container">
          <div>
            <span class="input-field-label">Baseline year</span>
            <VueDatePicker class="date-picker" v-model="baselineYear" :max-date="maxDate" year-picker text-input auto-apply required />

            <q-radio v-model="yearType" val="calendar" label="Calendar Year"/>
            <q-radio v-if="financialYearEnd" v-model="yearType" val="financial" label="Financial Year"/>
                <div v-if="yearType == 'calendar'">
                    Jan 01 - Dec 31
               </div> 
               <div v-else-if="financialYearEnd">

                {{ financialYearStart }} - {{ financialYearDate }}
               </div> 
          </div>
          <div>
            <span class="input-field-label">End year</span>
            <VueDatePicker class="date-picker" v-model="endYear" :min-date="new Date(parseInt(baselineYear) + 1, 0, 1)" year-picker @cleared="endYear = undefined" text-input auto-apply required />
          </div>
        </div>
        
        <div>
          <span class="input-field-label">Target amount</span>
          <q-input 
            class="amount-field" 
            outlined 
            autofocus
            v-model="targetAmount" 
            reactive-rules
            type="number"
            :rules="[(val) => validateTargetValue(val)]" 
            :disable="baselineEmissions === null || baselineEmissions === 0" 
            required>
          </q-input>
          <q-tooltip class="text-body2" v-if="baselineEmissions === null || baselineEmissions === 0">Please select a baseline year before setting target amount</q-tooltip>

        </div>

        <div v-if="flags['FLAG_4790_TARGETS_UPDATE']">
            <span class="input-scope-label">Select scopes to track</span>
            <ScopeSelectionComponent @update="updateScope" :targetScopes="scopes"/>
        </div>
      </div>
      <div class="baseline-amount-container">
        <span class="baseline-amount-span">Baseline Amount (kg CO2e):</span>
        <div v-if="!loadingBaseline">
              <q-input
                outlined
                v-model= "baselineAmount"
                type="number"
                min="1"
                max="999999999999999"
                :rules="[val => val <= 999999999999999n || 'Baseline amount is too large.']"
              />
              <q-chip text-color="black" style="margin-top: 10px" icon="o_info" square v-if="!Number.isNaN(actualBaselineAmount)">
                Actual Amount: {{ actualBaselineAmount.toLocaleString() }}
                <q-tooltip>The actual emissions for the selected baseline year</q-tooltip>
              </q-chip>  
        </div>
        <q-spinner v-else class="spinner" color="primary" size="1rem" />
      </div>
      
      <div class="row" style="gap: 1em;">
        <q-btn class="submit-form-btn" label="Submit" type="submit" color="secondary" :loading="loading" :disable="baselineAmount === null || baselineAmount === 0"  />
        <q-btn v-if="Object.keys(currentTarget).length >= 1" class="submit-form-btn" label="Delete" color="red" @click="this.delete()"  :loading="deleteLoading"/>
      </div>
      
    </q-form>
  </div>
</template>

<script>
import api from '@/services/api/api';
import notify from '@/services/util/notify';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ScopeSelectionComponent from './ScopeSelectionComponent.vue';
import dayjs from '@/services/util/dayjs';

export default {
  name: "CreateTarget",
  props: [ 'currentTarget' ],
  emits: [ 'close' ],
  components: {
    VueDatePicker,
      ScopeSelectionComponent,
  },
  data() {
    return {
      financialYearEnd: null,
      yearType: this.currentTarget.yearType ?? 'calendar',
      option: this.currentTarget.reduceBy ? this.currentTarget.reduceBy : true,
      targetName: this.currentTarget.name ? this.currentTarget.name : "",
      targetAmount: this.currentTarget.targetValue ? this.currentTarget.targetValue : 0,

      // This should be defined as a date in motion and only be converted to string to send to backend
      baselineYear: dayjs().subtract(1, "year").year().toString(),
      endYear: dayjs().year().toString(),


      loading: false,
      deleteLoading: false,

      // Baseline Data
      loadingBaseline: false,
      baselineEmissions: null,
      baselineAmount: 0,
      actualBaselineAmount: 0,

      scopes: this.currentTarget.scopes ? this.currentTarget.scopes : [] 
    }
  },

  async created() {
    if (Object.keys(this.currentTarget).length > 0) {
      this.baselineYear = dayjs(this.currentTarget.startDate).year().toString();
      this.endYear = dayjs(this.currentTarget.endDate).year().toString();
      this.baselineAmount = this.currentTarget?.baselineAmount;
    }

    if (this.currentTarget.yearType === "financial") {
      this.baselineYear = dayjs(this.baselineYear, "YYYY").add(1, "year").year().toString();
    }

    if (!this.currentTarget?.baselineEmissions) {
      await this.initBaselineEmissions();
    } else {
      this.baselineEmissions = this.currentTarget.baselineEmissions;
    }

    let companyYearEnd = await api.company.getFinancialYearSetting()
    if(Object.keys(companyYearEnd.data).length > 0) this.financialYearEnd = companyYearEnd.data
  },

  watch: {
    async baselineYear() {
      this.baselineAmount = null;
      this.baselineEmissions = null;
  
      if (this.baselineYear) {
        await this.initBaselineEmissions();
      } 
      else {
        this.baselineAmount = undefined
      }
    },

    async yearType() {
      this.baselineAmount = null;
      this.baselineEmissions = null;

      await this.initBaselineEmissions();
    }
  },
  computed:{
    financialYearStart(){
      const date = dayjs(this.financialYearEnd.fullDate)
      return date.add(1, 'day').format('MMM DD')
    },

    financialYearDate(){
      const date = dayjs(this.financialYearEnd.fullDate)
      return date.format('MMM DD')
    },

    maxDate() {
      return dayjs().subtract(1, 'year').toDate();
    }
  },
  methods: {

    updateScope(scopeObject) {
        this.scopes = scopeObject
    },


    validateTargetValue(val) {
      if (this.option) {
        return val > 0 && val <= 100 || 'Target percentage is invalid';
      } else {
        if (this.baselineEmissions) {
			return val >= 0 && val < this.baselineEmissions || 'Target value must be less than the baseline amount, and be a positive number';
        }

        return true;
      }
    },


    async initBaselineEmissions() {

      this.loadingBaseline = true;

      try {
        if(this.baselineYear){
          const baselineResponse = await api.targets.getYearBaseline({year: this.baselineYear, yearType: this.yearType})

          this.baselineEmissions = Math.round(baselineResponse.data);

          this.baselineAmount = this.baselineEmissions ? this.baselineEmissions : 0;
          this.actualBaselineAmount = this.baselineAmount;
        } else {
          this.baselineAmount = 0;
        }
      } finally {
        this.loadingBaseline = false;
      }

     
    },


    async delete() {
      this.deleteLoading = true;
      try {
          let res = await api.targets.deleteTarget(this.currentTarget.id);
          notify.withObject(res);
          this.$emit('close');
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.deleteLoading = false;
      }
    },


    async onSubmit() {
      this.loading = true;

      try {
          const payload = {
            name: this.targetName,

            // Send to the backend as year and let server handle actual date range
            startYear: this.baselineYear,
            endYear: this.endYear,

            targetValue: this.targetAmount,
            reduceBy: this.option,
            baselineAmount: this.baselineAmount,
            yearType: this.yearType,
            scopes: this.scopes,
          }

          let res;
          if (Object.keys(this.currentTarget).length != 0) {
            res = await api.targets.patchTarget(this.currentTarget.id, payload);
          } else {
            res = await api.targets.createTarget(payload);
          }
          notify.withObject(res);
          this.$emit('close');


      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="less" src="@/assets/styles/createTarget.less" scoped />
