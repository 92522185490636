<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <SiteEmissionsLeaderboardComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import SiteEmissionsLeaderboardComponent from "@/components/SiteComponents/SiteEmissionsLeaderboardComponent.vue";

export default {
    name: "site-emissions-leaderboard-view",
    components: { SiteEmissionsLeaderboardComponent },
    title: 'SiteEmissionsLeaderboard',
    setup() {
    return { sidebarWidth }
    }
};
</script>
