<template>
	<div class="commute-survey-container ">

		<div class="back" @click="this.$router.back()">
			<q-icon name="chevron_left" size="2rem"/>
		</div>

		<div class="commute-survey-header">
			Staff Commute Surveys
			<div class="commute-survey-id">
				Survey ID: {{ this.$route.params.surveyHash }}
			</div>
		</div>

		<div v-if="responseCount>0" class="commute-chart-container">

			<div class="survey-graph-container ">
				<div class="graph-chart medium-graph">
					<Pie v-bind:chartData="surveyDistanceTypeDetails" v-bind:chartOptions="typeePieChartjsOptions"
					ref="graph" />
				</div>
				<div class="graph-chart medium-graph">
					<Pie v-bind:chartData="surveyFrequencyTypeDetails" v-bind:chartOptions="frequencyePieChartjsOptions"
					ref="graph" />
				</div>
				<div class="graph-chart large-graph">
					<Pie v-bind:chartData="surveyDistanceDetails" v-bind:chartOptions="distancePieChartjsOptions" ref="graph" />
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import api from "../../services/api/api";
import notify from "@/services/util/notify";
import * as pieChartOptions from "../ChartComponents/ChartOptions/InteractivePieOptions";
import Pie from "../ChartComponents/Pie.vue";

const SingleCommuteSurveyComponent = {
	name: 'SingleCommuteSurvey',

	components: {
		Pie,
	},

	data() {
		return {

			loading: false,
			responseCount: 0,
			surveyFrequencyDetails: [],
			surveyDistanceDetails: [],
			surveyDistanceTypeDetails: [],
			surveyFrequencyTypeDetails: [],

			distancePieChartjsOptions: pieChartOptions.getPieOptions(
				"Total Commute Distance (Km)",
				"Distance Travelled by Type (Km)"
			),
			frequencyePieChartjsOptions: pieChartOptions.getPieOptions(
				"Total Commutes (Frequency)",
				"Frequency of commute types (Frequency)"
			),
			typeePieChartjsOptions: pieChartOptions.getPieOptions(
				"Totalff Commutes (Frequency)",
				"Frequency of commute types (Frequency)"
			),

		}
	},

	created() {
		this.initalise()
	},


	methods: {
		initalise() {
			api.commute.getResponseRate(this.$route.params.surveyHash)
			.then((res) => {
				this.responseCount = res.data[0].responses

				if (this.responseCount <= 0) {
					notify.warning("No survey responses available", "top")
				} else {
					this.getSurveyDetails()
				}

			})
			.catch(() => {
				notify.error("Unable to retreve response rate", "top")
			})

		},


		/**
		 * @desc Retrieves a list of staff commute types available
		 */
		async getSurveyDetails() {
			
			api.commute
				.getStaffCommuteSurveyFrequencyByTypeChart(this.$route.params.surveyHash)
				.then((res) => {

					this.surveyFrequencyDetails = res.data.responses;
					this.surveyFrequencyTypeDetails = res.data.types;

				}).catch(() => {
					notify.error("Error retrieving surveys", "top")
				})


			api.commute
				.getStaffCommuteSurveyDistanceByTypeChart(this.$route.params.surveyHash)
				.then((res) => {

					this.surveyDistanceDetails = res.data.responses;
					this.surveyDistanceTypeDetails = res.data.types;

					
					})
				.catch(() => {
					notify.error("Error retrieving surveys", "top")
				})
		},
	}
}


export default SingleCommuteSurveyComponent;
</script>

<style scoped>
@import "../../assets/styles/commute.css";
</style>