<template>
	<div class="sites-manage-container">
		<!-- Loading Spinner -->
		<div
			v-if="loading"
			class="home-loading-spinner-container"
			style="margin: 100px 0"
		>
			<q-spinner
				color="deep-purple-4"
				size="5em"
			/>
		</div>

		<!-- Loaded page content with site already created -->
		<div v-if="!loading && !noSites">
			<div class="sites-title">Manage {{ siteName }}</div>
			<div class="manage-site-form">
				<q-form @submit.prevent="editSite()">
					<q-input
						outlined
						bg-color="white"
						v-model="site.name"
						label="Site Name"
						required
						maxlength="50"
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The name of your site.
								</q-tooltip>
							</q-icon></template
						>
					</q-input>

					<q-input
						outlined
						type="number"
						bg-color="white"
						use-input
						step="any"
						hide-dropdown-icon
						v-model.number="site.FTE"
						label="FTE employees"
						@new-value="newCategory"
						required
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The equivalent number of full time employees
									currently employed at this new site.
								</q-tooltip>
							</q-icon></template
						>
					</q-input>

					<q-select
						outlined
						bg-color="white"
						use-input
						hide-dropdown-icon
						v-model="site.categoryDetail"
						label="Category"
						:options="siteCategories"
						@new-value="newCategory"
						:rules="[(val) => !!val || 'Category is required']"
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The category of your site.
								</q-tooltip>
							</q-icon></template
						>
					</q-select>
					<q-input
						outlined
						bg-color="white"
						v-model="address"
						label="Address"
						required
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The address of your site.
								</q-tooltip>
							</q-icon></template
						>

						<q-menu
							no-focus
							auto-close
							fit
						>
							<q-list>
								<q-item
									v-if="
										addresses.length == 0 && address != ''
									"
								>
									{{ addressResult }}
								</q-item>

								<q-item
									clickable
									v-for="ad in addresses"
									:key="ad"
									@click="address = ad"
								>
									{{ ad }}
								</q-item>
							</q-list>
						</q-menu>
					</q-input>

					<q-select
						outlined
						bg-color="white"
						multiple
						use-chips
						:disable="sourcesLoading"
						v-model="sources"
						label="Emission Sources"
						:options="getSourceDisplayNames"
						:rules="[ val => val.length>0 || 'Please select at least 1 (one) emission source' ]"
					>
						<template v-slot:after>
							<q-icon
								name="help"
								color="grey"
								size="sm"
							>
								<q-tooltip style="font-size: 1rem">
									The emission sources that you wish to track
									for this site.
								</q-tooltip>
							</q-icon></template
						>
					</q-select>

					<q-btn
						:disable="submitLoading"
						:loading="submitLoading"
						square
						no-caps
                        style="margin-top: 35px;"
						padding="8px 24px"
						color="blue-4"
						label="Update"
						type="submit"
					/>
				</q-form>
			</div>
		</div>

		<CreateSiteComponent
			v-if="noSites"
			@close="closeCreatePanel()"
            :hideCloseButton="true"
		/>
	</div>
</template>

<script>
import api from "@/services/api/api";
import notify from "@/services/util/notify";
import CreateSiteComponent from "./SidePanels/CreateSiteComponent.vue";
import {useSiteStateStore} from "@/stores/siteState.store"

const SitesComponent = {
	name: "Sites",

	components: {
		CreateSiteComponent,
	},

	data() {
		return {
			siteStore: useSiteStateStore(),
			loading: false,
			submitLoading: false,
            sourcesLoading: false,
			site: {},
			siteName: "",
			noSites: false,

			siteCategories: [],
			emissionSources: [],
			sources: [],
			address: "",

			showCreateSitePanel: false,
		};
	},

	created() {
		this.init();
	},

	watch: {
		address: {
			handler(add) {
				// Removes residual addresses from previous searches if input is empty
				if (add.length < 1) {
					this.addresses = [];
					return;
				}

				this.addressResult = "Finding Addresses ...";
				api.sites
					.getAutoCompleteAddresses(add)
					.then((addresses) => (this.addresses = addresses))
					.catch((error) => console.error(error))
					.finally((this.addressResult = "No Addresses Found"));
			},
		},
	},

	computed: {
		getSourceDisplayNames() {
			return this.emissionSources.map(function (item) {
				return item.sourcedisplayname;
			});
		},
	},

	methods: {
		/**
		 * @desc Retrieves all sites and assigns them as list to this.sites
		 */
		async init() {
			this.loading = true;

			api.sites
				.getSites()
				.then((res) => {
					if (res.data.length < 1) {
						this.noSites = true;
						return;
					}else{
                        this.noSites = false;
                    }
					this.site = res.data[0];
					this.address = `${this.site.addressNumber} ${this.site.addressStreet}, ${this.site.addressSuburb}, ${this.site.addressCity}, ${this.site.addressPostCode}, ${this.site.addressCountry}`;
					this.siteName = this.site.name;
					api.sites
						.getSiteEmissionSources(this.site.id)
						.then((res) =>
							res.data.emissionSources.map((emission) => this.sources.push(emission.sourcedisplayname))
						)
						.catch((error) => {
							notify.withObject(error.response);
						});
				})
				.catch((error) => {
					notify.withObject(error.response);
				});

			api.data
				.getEmissionSources()
				.then((res) => (this.emissionSources = res.data))

			this.loading = false;
		},

		/**
		 * @desc Posts new site data to server based on info filled out in form.
		 */
		editSite() {
			this.submitLoading = true;

			/* We need to set site.category to the string representation of the category detail name
			selected by the user, as this is what the site patch route takes when updating a site's category. */
			this.site.category = this.site.categoryDetail;
			// The patch route takes the fte lowercase, so we also need to set that.
			this.site.fte = this.site.FTE;

			// Update Address

			api.sites.getAddressDetails(this.address).then((res) => {
				if (res.data.features.length == 0) {
					notify.error("Unable to find provided address.", "top");
					this.submitLoading = false;
					return;
				}

				const address = res.data.features[0].properties;

				this.site.addressNumber = address.housenumber;
				this.site.addressStreet = address.street;
				this.site.addressSuburb = address.district;
				this.site.addressCity = address.city;
				this.site.addressCountry = address.country;
				this.site.addressPostCode = address.postcode;

				api.sites
					.patchSite(this.site.id, this.site)
					.then(() => {
						let emissionSourceIds = this.getEmissionSourceIDs();
						api.sites
							.patchSiteEmissionSources(
								this.site.id,
								emissionSourceIds
							)
							.then(() => {
								notify.primary(
									`Successfully updated site ${this.site.name}`,
									"top"
								);
								this.siteName = this.site.name;
								this.siteStore.getSiteList()
								this.$emit("close");
							})
							.catch((error) => {
								notify.withObject(error.response, "top", `Failed to Update emission soures for site: ${this.site.name}`);
							});
					})
					.catch((error) => {
						notify.withObject(error.response, "top", `Failed to Update Site: ${this.site.name}`)
					})
					.finally(() => {
						this.submitLoading = false;
					});
			});
		},

		/**
		 * @desc This method will determine the IDs of the selected emission
		 * sources in this.sources, and return an array of objects with the attribute emissionSourceId: <id>
		 *
		 * @returns array of objects with the attribute emissionSourceId: <id>
		 */
		getEmissionSourceIDs() {
			let ids = [];
			this.sources.forEach((selectedSource) => {
				this.emissionSources.forEach((source) => {
					if (source.sourcedisplayname == selectedSource) {
						ids.push({ emissionSourceId: source.emissionSourceId });
					}
				});
			});
			return ids;
		},

		/**
		 * @desc Adds new site category to siteCategories
		 */
		newCategory(val, done) {
			if (!this.siteCategories.includes(val)) {
				this.siteCategories.push(val);
				done(val, "add-unique");
			}
		},
	},
};

export default SitesComponent;
</script>

<style lang="less">
@import "../../assets/styles/sites.less";
</style>
