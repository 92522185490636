import { read, utils } from "xlsx";
import mime from "mime-types";

/**
 * Converts a file to an ArrayBuffer.
 *
 * @param {File} file - The file to be converted.
 * @returns {Promise<ArrayBuffer>} A promise that resolves with the ArrayBuffer.
 */
export const fileToBuffer = async (file) => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result); // On load, resolve with the result
      reader.onerror = (error) => reject(error); // On error, reject with the error
  });
};

/**
 * Checks if a sheet in the workbook is empty.
 *
 * @param {Object} workbook - The workbook object.
 * @param {string} sheetName - The name of the sheet to check.
 * @returns {boolean} Returns true if the sheet is empty, false otherwise.
 */
export const isEmptySheet = (workbook, sheetName) => {
  const worksheet = workbook.Sheets[sheetName];

  // If the sheet has no reference range, it is empty
  if (!worksheet["!ref"]) {
    return true;
  }

  // Decode the reference range of the sheet
  const range = utils.decode_range(worksheet["!ref"]);

  // Iterate over each cell in the range
  for (let rowIndex = range.s.r; rowIndex <= range.e.r; rowIndex++) {
    for (let columnIndex = range.s.c; columnIndex <= range.e.c; columnIndex++) {
      const cellAddress = {c: columnIndex, r: rowIndex};
      const cellRef = utils.encode_cell(cellAddress);

      // If any cell in the range is not empty, the sheet is not empty
      if (worksheet[cellRef]) {
        return false;
      }
    }
  }

  // If no non-empty cells were found, the sheet is empty
  return true;
}

/**
 * Gets the names of non-empty sheets in a workbook.
 *
 * @param {File} file - The file to be read.
 * @returns {Promise<string[]>} A promise that resolves with an array of non-empty sheet names.
 */
export const getNonEmptySheetNames = async (file) => {
  const buffer = await fileToBuffer(file);
  const wb = read(buffer);
  
  return wb.SheetNames.filter((sheetName) => !isEmptySheet(wb, sheetName));
};

/**
 * This function gets the file extension from a filename.
 * 
 * @param {string} filename - The name of the file.
 * @returns {string} The file extension.
 */
export const getFileExtention = (filename) => {
  return /(?:\.([^.]+))?$/.exec(filename)[1];
}

/**
 * Prompts the user to download a file by creating a temporary download link.
 * 
 * @param {ArrayBuffer|Array|Blob|string} file - The file data. This can be any object type that is supported by the Blob constructor.
 * @param {string} filename - The name of the file. If the file extension is not provided, it will be inferred from the MIME type.
 * @param {string} [type=""] - The MIME type of the file. If not provided, it will be inferred from the file extension or default to 'application/octet-stream'.
 */
export const promptDownload = (file, filename, type = "") => {
  if (Array.isArray(file)) {
    file = new Uint8Array(file).buffer;
  }

  let fileExtension = getFileExtention(filename);

  if (!fileExtension && type) {
    fileExtension = mime.extension(type);
    filename = `${filename}.${fileExtension}`;
  } else if (fileExtension && !type) {
    type = mime.lookup(fileExtension);
  }
  
  if (!type) {
    type = file instanceof Blob ? file.type : "application/octet-stream";
  }

  const blob = file instanceof Blob ? file : new Blob([file], { type });

  // Create a temporary download link
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  
  // Append the link to the document body and simulate a click to start the download
  document.body.appendChild(a);
  a.click();

  // Remove the temporary link from the document body
  document.body.removeChild(a);
};
