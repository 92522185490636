<template>
    <div v-if="!loading && hasData">
        <q-table class="trace-table data-table"
          :rows="dataPoints"
          :columns="columns"
          row-key="id"
          separator="horizontal"
          :loading="loading"
          v-model:pagination="pagination"
          :title="fileName"
          binary-state-sort
          :rows-per-page-options="[0]"
          :filter="filter"
        />
    </div>
    <div v-else-if="!hasData" class="no-data-container">
      <span class="no-data-span">
        Click <q-icon class="visibility-icon" name="visibility" size="2em" /> on
        the files to view them here
      </span>
      <span v-if="userRole == 'demo'" class="no-data-span" >
        As you are using a demo account, you are unable to see the data.
      </span>
    </div>
    <div v-else>
      <q-inner-loading showing color="deep-purple-4" size="5em" />
    </div>
  </template>
  
  <script>
  import api from "@/services/api/api";
  import notify from "@/services/util/notify";
  import getRole from "@/services/util/role";
  import { useDataStateStore } from "@/stores/dataState.store";
  import dayjs from "@/services/util/dayjs";
  
  const TraceabilityDataComponent = {
    name: "TracabilityDataTable",
    props: [ 'emissionSource' ],
  
    data() {
      return {
        dataStore: useDataStateStore(),
  
        fileName: "",
        columns: [],
        dataPoints: [],
        filter: "",
        hasData: true,
        loading: false,
        pagination: {
          rowsPerPage: 15,
          sortBy: "id",
        },
  
        userRole: getRole(),
      };
    },
  
    computed: {
      fileId(){
        return this.dataStore.selectedTraceDataFileId
      }
    },
  
    watch: {
      fileId() {
        this.setData(this.fileId);
      },
    },
  
    mounted: function () {
      this.setData(this.fileId);
    },
  
    methods: {
      /**
       * Also dynamicly updates table with relivant data from that file, this includes columns and data fields
       * @param {Number} fileId
       */
      async setData(fileId) {
        this.loading = true;
  
        const fileInfo = await this.getData(fileId);
  
        if (fileInfo) {
          this.fileName = fileInfo.fileName;
          this.hasData = true;
        } else {
          this.loading = false;
          this.hasData = false;
          return;
        }
  
        this.dataPoints = fileInfo.data;
        this.columns = [];
  
        const defualtColumnFormat = {
          name: "",
          label: "",
          align: "left",
          field: "",
          sortable: true,
          required: true,
          format: (value) => {
            if (typeof value === "number") {
              return Number.isInteger(value) ? value : value.toFixed(2);
            } else if (dayjs(value).isValid() && (typeof value === "string" || value instanceof Date)) {
                return new Date(value).toLocaleString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour12: false,
                });
            } else if (Array.isArray(value)) {
              return value.join(", ");
            }

            return value;
          }
        };
  

        fileInfo.columns.forEach((column) => {
          const newColumn = Object.assign({}, defualtColumnFormat);
          newColumn.name = column;
  

          let splitcolumns = column.split(/([A-Z][a-z]+)/).map(function (str) {
            return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
          });
  
          newColumn.label = splitcolumns.join(" ");
          newColumn.field = column;
  
          this.columns.push(newColumn);
        });

        this.loading = false;
      },
  
      /**
       * @desc Retrieves all the data related to the fileId.
       *
       * @param {Number} fileId
       * @returns {Object}:
       * {
       *   columns: [col1, col2, ...],
       *   data: [
       * 		{
       * 		  col1: val1,
       * 		  col2: val2,
       * 		  ...
       * 		},
       * 		...
       * 	 ]
       * }
       */
      async getData(fileId) {
        if(this.fileId){
            try {
            const response = await api.file.getFileDataById(fileId, this.emissionSource);
            return response.data;
          } catch (err) {
            notify.warning(err.response.data ?? "Error retrieving file information", "top");
          }
        }
      },
    },
  };
  
  export default TraceabilityDataComponent;
  </script>
  <style scoped lang="less" src= "../../../assets/styles/traceability.less" />
  
