<template>
  <div class="reimbursement-container">
    <!-- Loading Spinner -->
    <div v-if="loading" class="home-loading-spinner-container" style="margin: 100px 0">
      <q-spinner color="deep-purple-4" size="5em" />
    </div>

    <SideModal v-model="openSideModal">
      <CreateEditMileageComponent :mileageData="item" :vehicleOptions="vehicleOptions" :editing="editing" :siteId="siteId" @close="openSideModal = false" @refresh="refreshReimbursementCards" ></CreateEditMileageComponent>
    </SideModal>

    <div v-if="!loading">
      <div class="reimbursement-controls" style="margin-top: 50px;">
        <div class="reimbursement-view-requests">
          <span> You have {{ pendingRequests }} pending request<span v-if="pendingRequests != 1">s </span> to process </span>
          <q-icon size="xl" name="o_notifications">
            <q-badge v-if="pendingRequests > 0" class="text-white" color="red" rounded floating :label="pendingRequests" />
          </q-icon>
        </div>

        <div class="sites-leaderboard-type-toggle">
          <q-btn 					
            no-caps
            id="create-claim"
            color="deep-purple-4"
            label="Create a claim"
            icon="add"
            square
            padding="10px 20px" @click="createNewMileageRequest()"/>
        </div>
      </div>
      <div class="reimbursement-table">
        <q-table 
        :pagination="pagination"
        :rows="this.loading ? [] : mileageData" 
        :columns="columns" 
        :filter="filter"
        row-key="name" 
        :loading="loading && !firstLoad">
          <template v-slot:top-left >
              <q-input outlined borderless dense debounce="300" v-model="filter" placeholder="Search Mileage Claims" clearable>
                  <template v-slot:prepend>
                      <q-icon name="search" />
                  </template>
              </q-input>
              <q-icon class="q-ml-sm" name="help" size="sm" >
                <q-tooltip class="text-body2">Filter is applied to all columns</q-tooltip>
              </q-icon>
          </template>
          
          <template v-slot:loading>
            <q-inner-loading showing color="deep-purple-4" size="5em" />
          </template>

          <template v-slot:body-cell-status="props">
            <q-td :props="props">
              <div class="reimbursement-request-status">
                <q-badge v-if="props.value == 'Pending'" align="middle" text-color="orange">
                  <div>{{ props.value }}</div>
                  <q-tooltip>
                    The request is being reviewed. Please check back in a few days
                  </q-tooltip>
                </q-badge>
                <q-badge v-if="props.value == 'Approved'" text-color="green">
                  <div>{{ props.value }}</div>
                </q-badge>

                <q-badge v-if="props.value == 'Denied'" text-color="red">
                  <div>{{ props.value }}</div>
                  <q-tooltip>
                    The request has been denied, please contact your employer if
                    you have any concerns.
                  </q-tooltip>
                </q-badge>
              </div>
            </q-td>
          </template>

          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <div class="reimbursement-actions" v-if="props.row.status == 'Pending'">
                  <q-btn
                    padding="10px 10px 10px 0px"
                    color="green-6"
                    label="Approve"
                    no-caps
                    flat
                    @click="approveRequest(props.row.id)"
                  />
                  <q-btn
                    id="edit-claim"
                    padding="10px"
                    color="blue-6"
                    label="Edit"
                    no-caps
                    flat
                    @click="editRequest(props.row.id)"
                  />
                  <q-btn
                    padding="10px"
                    color="deep-purple-4"
                    label="Deny"
                    no-caps
                    flat
                    @click="denyRequest(props.row.id)"
                  />
              </div>
            </q-td>
          </template>

        </q-table>
      </div>
    </div>
  </div>
</template>
<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import api from "../../services/api/api";
import notify from "@/services/util/notify";
import getRole from "@/services/util/role";

import SideModal from "../SideModalComponent.vue";
import CreateEditMileageComponent from "./CreateEditMileageComponent.vue";

const MileageComponent = {
  name: "MileageComponent",
  components: { SideModal, CreateEditMileageComponent },

  data() {
    return {
      userRole: getRole(),

      loading: false,
      mileageData: [],
      pagination: { rowsPerPage: 30 },

      openSideModal: false,
      editing: false,
      item: {},

      vehicleOptions: {},

      columns: [
      {
          name: "id",
          style: "width: 12.6%",
          label: "Request Id",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "amount",
          style: "width: 12.6%",
          label: "Distance Travelled",
          align: "left",
					field: (row) => {
						try {
							return row.amount + 'km'
						} catch (e) {
							return 0;
						}
					},
          sortable: true,
        },
        {
          name: "vehicle",
          style: "width: 12.6%",
          label: "Vehicle Type",
          align: "left",
          field: "vehicle",
          sortable: true,
        },
        {
          name: "dateOfTravel",
          style: "width: 16.6%",
          label: "Date",
          align: "left",
					field: (row) => {
						try {
							return new Date(row.timestamp).toLocaleString().split(',')[0]
						} catch (e) {
							return 0;
						}
					},
          sortable: true,
        },
        {
          name: "email",
          style: "width: 16.6%",
          label: "Requestor",
          align: "left",
          field: "email",
          sortable: true,
        },
        {
          name: "status",
          style: "width: 20.6%",
          label: "Status",
          align: "left",
          field: "status",
          sortable: true,
        },
        {
          name: "actions",
          style: "width: 20.6%",
          label: "Action",
          align: "left",
          field: "",
          classes: getRole() != 'admin' ? '' : 'hidden',
          headerClasses: getRole() != 'admin' ? '' : 'hidden',
        },
      ],
      
      filter: '',
    };
  },

  watch: {
    siteId() {
      this.refreshReimbursementCards();
    }
  },

  computed: {

    siteId() {
      return useSiteStateStore().siteId;
    },

    pendingRequests() {
      return this.mileageData.filter((obj) => obj.status === "Pending").length;
    }
  },

  created() {
    this.refreshReimbursementCards();

    this.getVehicleOptions();
  },

  methods: {


    /**
     * @desc Load the reimbursement cards.
     */
    refreshReimbursementCards() {
      this.loading = true;

      api.mileage
        .getMileageData(false, this.siteId)
        .then((res) => {
          this.mileageData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          notify.withObject(err.response);
          this.loading = false;
        });
    },


    /**
     * @desc Retrieve vehicle options to select for travel request
     */
    getVehicleOptions() {
      api.carbon.getEmissionFactors('mileage-claims').then((response) => {
        this.vehicleOptions = response.data;
      }).catch((err) => {
        notify.withObject(err.response);
      })
    },

    createNewMileageRequest() {
      this.editing = false;
      this.openSideModal = true;
    },

    /**
     * @desc Opens a edit modal for mileage reimbursement requests
     * @param {Number} entryId the id of the mileage request to open
     */
    editRequest(entryId) {
      this.item = this.mileageData.find((item) => {
        return item.id == entryId;
      });
      this.editing = true

      this.openSideModal = true;
    },


    /**
     * @desc Approve a given mileage reimbursement request and remove the request from the data list
     * @param {Number} entryId The id of the entry to be approved
     */
    approveRequest(entryId) {
      api.mileage
        .approveMileageReimbursementRequest(entryId)
        .then(() => {
          notify.withObject({status: 200, data: "Success: Approved request"});
          this.refreshReimbursementCards();
        })
        .catch((err) => {
          notify.withObject(err.response);
        });
    },


    /**
     * @desc Deny a given mileage reimbursement request and remove the request from the data list
     * @param {Number} entryId The id of the request to be denied
     */
    denyRequest(entryId) {
      api.mileage
        .denyMileageRequestReimbursementRequest(entryId)
        .then(() => {
          notify.withObject({status: 200, data: "Success: Denied request"});
          this.refreshReimbursementCards();
        })
        .catch((err) => {
          notify.withObject(err.response);
        });
    },
  },
};

export default MileageComponent;
</script>

<style scoped>
@import "../../assets/styles/mileage.less";
</style>
