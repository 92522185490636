<template>
    <div class="factor-filter-container">

        <div class="factor-controls">
            <div class="button-container">
                <q-select class="dropdown" dropdown-icon="expand_more" align="between" no-caps label="Supplier" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedSuppliers"
                    :options="availableSuppliers" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section class="no-hl">
								Select All
                            </q-item-section>
                            <q-item-section side class="no-hl">
                                <q-checkbox :model-value="allSuppliers" @update:model-value="selectAllSuppliers()" />
                            </q-item-section>
                        </q-item>
                        <q-separator/>
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section class="no-hl">
                                {{opt}} 
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Emission Source" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedEmissionSources"
                    :options="availableEmissionSources" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section class="no-hl">
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allEmissions" @update:model-value="selectAllEmissions()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section class="no-hl">
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Emission Factors" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedEmissionFactors"
                    :options="availableEmissionFactors" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section class="no-hl">
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allEmissionFactors" @update:model-value="selectAllEmissionFactors()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section class="no-hl">
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-select class="dropdown fixed-height-select" dropdown-icon="expand_more" align="between" no-caps label="Sites" outline
                    option-value="name" hide-bottom-space multiple outlined dense v-model="selectedSites"
                    :options="availableSites" options-dense>
                    <template v-slot:before-options>
                        <q-item>
                            <q-item-section class="no-hl">
                                <q-item-label>Select All</q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="allSites" @update:model-value="selectAllSites()" />
                            </q-item-section>
                        </q-item>
                        <q-separator />
                    </template>
                    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section class="no-hl">
                                {{opt}}
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-btn flat square color="amber-9" no-caps @click="resetFilters" label="Reset Filters"/>

            </div>

        </div>
    </div>
</template>

<script>

const ConnectionFilterComponent = {
    name: "ConnectionFilter",

    props: {
        availableSuppliers: {
            type: Array,
            required: true,
        },

        availableEmissionSources: {
            type: Array,
            required: true,
        },

        availableEmissionFactors: {
            type: Array,
            required: true,
        },

        availableSites: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            allSuppliers: false,
            selectedSuppliers: [],

            allEmissions: false,
            selectedEmissionSources: [],

            allEmissionFactors: false,
            selectedEmissionFactors: [],

            allSites: false,
            selectedSites: [],

            isResettingFilters: false
        };
    },


    watch: {
        selectedSuppliers() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedEmissionSources() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedEmissionFactors() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        },

        selectedSites() {
            if (!this.isResettingFilters) {
                this.applyFilters();
            }
        }
    },

    methods: {

        /**
         * @desc Generate the filter object and call the parent component method
         */
        applyFilters() {
            let filterObject = {
                'supplier': this.selectedSuppliers,
                'emissionSources': this.selectedEmissionSources,
                'emissionFactors': this.selectedEmissionFactors,
                'sites': this.selectedSites
            }

            this.$emit("updateDisplayedConnections", filterObject);
        },


        /**
         * @desc Sets the filters to the default values.
         */
        resetFilters() {
            // Set a flag to indicate filters are being reset
            this.isResettingFilters = true;

            this.allSuppliers = false
            this.selectedSuppliers = []

            this.allEmissions = false
            this.selectedEmissionSources = []

            this.allEmissionFactors = false
            this.selectedEmissionFactors = []

            this.allSites = false
            this.selectedSites = []

            this.$nextTick(() => {
                this.isResettingFilters = false;
                this.applyFilters();
            });
        },


        /**
         * @desc Sets sources to list of all available sources
         */
        selectAllSuppliers() {
			this.allSuppliers = !this.allSuppliers
			
			if (this.allSuppliers) {
				this.selectedSuppliers = this.availableSuppliers;
			} else {
				this.selectedSuppliers = []
			}
		},


        /**
         * @desc Sets emissions to list of all available emissions
         */
        selectAllEmissions() {
            this.allEmissions = !this.allEmissions
			
			if (this.allEmissions) {
				this.selectedEmissionSources = this.availableEmissionSources;
			} else {
				this.selectedEmissionSources = []
			}
        },


        /**
         * @desc Sets scopes to list of all available scopes
         */
        selectAllEmissionFactors() {
            this.allEmissionFactors = !this.allEmissionFactors
			
			if (this.allEmissionFactors) {
				this.selectedEmissionFactors = this.availableEmissionFactors;
			} else {
				this.selectedEmissionFactors = []
			}
        },


        /**
         * @desc Sets categories to list of all available categories
         */
        selectAllSites() {
            this.allSites = !this.allSites
			
			if (this.allSites) {
				this.selectedSites = this.availableSites;
			} else {
				this.selectedSites = []
			}
        }
    },
};

export default ConnectionFilterComponent;
</script>

<style lang="less" src="@/assets/styles/emissionFactorSettings.less" scoped />
