<template>
        <q-list class="target-edit-container">
            <q-item clickable  @click="targetStore.showChangeValueComponent=!targetStore.showChangeValueComponent"> 
                <div class="target-item-actions">
                    <q-icon class="target-action-icon" name="o_edit"/>
                    Edit
                </div>
            </q-item>
        </q-list>
</template>

<script>
import {useTargetStore} from '@/stores/target.store';

const ChartOptionsListContainerComponent = {
    name: "ChartOptionsListContainerComponent",
    data(){
        return {
            targetStore: useTargetStore()
        }
    }

}

export default ChartOptionsListContainerComponent

</script>

<style>
    @import "@/assets/styles/chart.css";
</style>
