<template>
  <div class="card-container home-page-card-data" style="flex-basis: 23.5%">
    <q-card class="card" :key="card.label">
      <q-card-section class="card-favourite-icon q-pa-sm">
        <div v-if="permissions.manageFavourites.includes(this.userRole)">
          <q-icon v-if="!favourite" class="home-insight-favourite-selector home-insight-not-favourite" name="star"
            size="1.85rem" @click="$emit('toggleFavourite', this.card.emissionSource)" @click.stop.prevent />
          <q-icon v-else class="home-insight-favourite-selector home-insight-favourite" name="star" color="secondary"
            size="1.85rem" @click="$emit('toggleFavourite', this.card.emissionSource)" @click.stop.prevent />
        </div>
      </q-card-section>
      <q-card-section>
        <div v-if="!loading" class="main-content">
          <q-card-section class="card-section">
            <div class="card-label">{{ card.label }}</div>
            <div>
              <span class="card-value">{{ card.value }}</span>
              <span v-if="card.unit" class="card-unit">{{ card.unit }}</span>
            </div>
          </q-card-section>
          <q-card-section class="slot-section">
            <slot></slot>
          </q-card-section>
        </div>
        <div v-else class="loading-container">
          <q-spinner class="spinner" color="primary" size="3em" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
  import getRole from "@/services/util/role";
  import permissions from "@/rolePermissions";

  export default {
    props: ["card", "loading", "favourite",],

    data() {
      return {
        userRole: getRole(),
        permissions: permissions,
      }
    }
  }


</script>

<style lang="less" src="../../assets/styles/card.less" scoped />