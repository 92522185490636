<template>
    <div class="chart-container">

        <h2 class="tab-section-heading">Emissions</h2>


        <div class="chart-button-card">
            <div class="chart-button-container">
                <!-- Chart Type Selector -->
                <q-btn-dropdown align="between" class="dropdown" outline auto-close no-caps :label="this.view.label" dropdown-icon="expand_more">
                    <q-list>
                        <q-item v-for="option in typeOptions" :key="option" @click="this.view = option" clickable>
                            <q-item-section>
                                <q-item-label>{{ option.label }}</q-item-label>
                                <q-item-label caption> {{ option.tooltip }}  </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>


                <!-- Chart Style Selector -->

                <q-btn-dropdown align="between" class="dropdown" outline no-caps :label="this.displayGraphType + ' Graph'" dropdown-icon="expand_more">
                    <q-list>
                        <q-item clickable v-close-popup @click="this.chartType = 'line'">
                            <q-item-section>
                                <q-item-label>Line Graph</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup @click="this.chartType = 'bar'">
                            <q-item-section>
                                <q-item-label>Bar Graph</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>

                <!-- Tag Selector -->

                <TagSelectionComponent v-if="flags['FLAG_4108_DATA_TAGS']" @updateSelectedTags="updateSelectedTags"/>
                
                <!-- Granularity Selector -->

                <q-btn-dropdown align="between" class="dropdown" :disable="this.startDateError || this.endDateError || this.view.value === 'year'"  no-caps :label="store.filterDisplayGranularity" outline dropdown-icon="expand_more">
                    <q-list>
                        <q-item v-for="option in granularityOptions" v-bind:key="option"  clickable v-close-popup @click="store.setFilterGranularity(option.granularity); store.setFilterDisplayGranularity(option.displayGranularity)" >
                            <q-item-section>
                                <q-item-label>{{ option.displayGranularity }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>

                <!-- Date Range Selector -->

                <q-input class="date-select" :disable="this.view.value === 'year'" v-model="store.filterStartDate" :error="startDateError" hide-bottom-space :clearable="clearableDates" 
                    :label="startDateLabel" dense outlined :mask="dateFormat.replace(/[a-z]/gi, '#')" :placeholder="dateFormat">
                <template v-slot:append>
                    <q-icon size="sm" name="event" color="black" class="cursor-pointer">
                    <q-popup-proxy transition-show="scale" transition-hide="scale">
                        <q-date :options="startDateOptions" minimal v-model="store.filterStartDate" :mask="dateFormat">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                            </div>
                        </q-date>
                    </q-popup-proxy>
                    </q-icon>
                </template>
                </q-input>

                <q-input :disable="this.view.value === 'year'" v-if="endDateVisible" v-model="store.filterEndDate" :error="endDateError" hide-bottom-space :clearable="clearableDates" 
                    class="date-select" label="End Date" 
                    dense outlined :mask="dateFormat.replace(/[a-z]/gi, '#')" :placeholder="dateFormat">
                <template v-slot:append>
                    <q-icon size="sm" name="event" color="black" class="cursor-pointer">
                        <q-popup-proxy transition-show="scale" transition-hide="scale">
                            <q-date :options="endDateOptions" minimal v-model="store.filterEndDate" :mask="dateFormat">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                            </div>
                            </q-date>
                        </q-popup-proxy>
                    </q-icon>
                </template>
                </q-input>
                <q-space />

             
             
                <q-btn class="reload-btn" rounded dense flat icon="sync" @click="updateChartData">
                    <q-tooltip>
                        Reload Data
                    </q-tooltip>
                </q-btn>
                <q-btn v-if="permissions.csvExport.includes(this.userRole)" @click="getFile()" flat dense rounded no-caps icon="ios_share" :loading="gettingFile">
                    <q-tooltip>
                        Export Data
                    </q-tooltip>
                </q-btn>
            </div>
        </div>

        <div class="chart-data" >
            <div class="chart-spinner" v-if="store.loadingChartData">
                <q-spinner color="primary" size="6rem"/>
            </div>
            <template v-else-if="Object.keys(formattedChartData).length">
                <q-card class="chart-card q-pa-sm" :style="(Object.keys(formattedChartData).indexOf(unit) === Object.keys(formattedChartData).length - 1 && 
                                                                        (Object.keys(formattedChartData).indexOf(unit) + 1 % 2 !== 0 && Object.keys(formattedChartData).length % 2) && Object.keys(formattedChartData).length > 2) || 
                                                                        Object.keys(formattedChartData).length === 1 ? 'width: 100%' : 'width: 49.3%'" v-for="unit of Object.keys(formattedChartData)" :key="unit">
                    <div class="chart-graph-container">
                        <div class="chart-options">
                            <q-select class="unit-selector" label="Display Unit" stack-label v-if="unitOptions[unit]" v-model="unitOptions[unit]" outlined dense option-label="toSymbol" borderless  :options="unitSelectionOptions(unit)" />
                            <q-skeleton class="unit-selector"  type="QInput" v-else/>
                        </div>
                    
                    
                        <Line v-if="this.chartType == 'line'" :chartData="formattedChartData[unit]" :chartOptions="lineOptions(unit)" />
                        <Bar v-else-if="this.chartType == 'bar'" :chartData="formattedChartData[unit]" :chartOptions="barOptions(unit)" />
                   
                       
                    </div>
                </q-card>
            </template>
            <div class="no-data-chart-container" v-else>
                    <q-icon name="query_stats" size="15em" />
                    <span style="font-size: 2.5em;">No Chart Data</span>
            </div>
        </div>
    </div>
</template>
<script>
import { useSiteStateStore } from '@/stores/siteState.store';
import { useChartStateStore } from '@/stores/chartState.store';

import notify from "@/services/util/notify";
import api from "@/services/api/api";

import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";

import Bar from "../ChartComponents/Bar.vue";
import Line from "../ChartComponents/Line.vue";
import Chart from "../ChartComponents/chartGenerators/chart.js";

import * as lineChartOptions from "../ChartComponents/ChartOptions/LineOptions.js";
import * as barChartOptions from "../ChartComponents/ChartOptions/BarOptions.js";

import TagSelectionComponent from "../DataComponents/TagSelectionComponent.vue"
import dayjs from 'dayjs';

const TrendGraphComponent = {
    name: "TrendGraphComponent",
    props: {
        emissionSource: {
            type: String,
            required: true
        },
    },
    components: {
        Line,
		Bar,
        useChartStateStore,
        TagSelectionComponent
    },
    data() {
        return {
            store: useChartStateStore(),
            permissions: permissions,
            userRole: getRole(),

            view: { label: 'Default', value: 'default', tooltip: 'Default chart view.' },
            chartType: "line",
            ready: true,
            clicked: 0,
            carbonChartData: {},

            loadingChart: false,
            gettingFile: false,

            barChartOptions: {},
            lineChartOptions: {},

            // Generated on Fetch Data
            defaultLineOptions: {},
            defaultBarOptions: {},

            unitOptions: {},

            granularityOptions: [
                { granularity: 'minute', displayGranularity: 'Minutely' },
                { granularity: 'hour', displayGranularity: 'Hourly' },
                { granularity: 'day', displayGranularity: 'Daily' },
                { granularity: 'week', displayGranularity: 'Weekly' },
                { granularity: 'month', displayGranularity: 'Monthly'},
                { granularity: 'year', displayGranularity: 'Yearly' }
            ],

            //tags
            selectedTags: [],

            startDateError: false,
            endDateError: false,

            // Date Formatting
            dateFormat: 'DD/MM/YYYY'
        }
    },
    computed: {
        displayEmissionSource() {
            return this.emissionSource.split(/-/).map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(" ");
        },
        
        displayGraphType() {
			return this.chartType.charAt(0).toUpperCase() + this.chartType.slice(1);
		},

		formattedChartData() {
            let chartData = null;
            
            chartData = Chart.generateChartData(this.store.chartData);
            const convertedChartData = {};
            for (const [unit, data] of Object.entries(chartData)) {
                const conversion = this.unitOptions?.[unit]?.factor ?? 1;
                
                const convertedData = structuredClone(data);
                convertedData.datasets = convertedData.datasets.map(dataset => {
                    const convertedDataset = structuredClone(dataset);                   
                    convertedDataset.data = dataset.data.map(point => point * conversion);
                    return convertedDataset;

                })
        
                convertedChartData[unit] = convertedData;
            }
			return convertedChartData;
		},

        typeOptions() {
            const types = [
                    { label: 'Default', value: 'default', tooltip: 'Default chart view.' },
                    { label: 'Financial', value: 'financial', tooltip: 'View the chart in its New Zealand dollar equivalence. ' },
                    { label: 'Year on Year', value: 'year', tooltip: 'View year on year chart. The end date is the final day of the current month.'}
            ]

            // As carbon and default are the same for 'carbon-offset' data points remove the option when selecting carbon offsets
            if (this.emissionSource != 'carbon-offset') {
                types.splice(2, 0, { label: 'Carbon', value: 'carbon', tooltip: 'View the chart in its carbon equivalence.'})
            }

            return types;
        },

        chartLoaded() {
            return Object.keys(this.defaultLineOptions).length > 0 && Object.keys(this.defaultBarOptions).length > 0;
        },

        siteId() {
			return useSiteStateStore().siteId;
		},

        startDateLabel() {
            return this.store.filterGranularity == 'minute' ? "Date" : "Start Date";
        },

        endDateVisible() {
            return this.store.filterGranularity != 'minute';
        },

        clearableDates() {
            return this.store.filterGranularity != 'hour' && this.store.filterGranularity != 'minute';
        }
    },
    created() {
        this.store.addCustomDatasetId(this.$route.params.datasetId);  

        // Disable "Minutely" for demo accout users
        if (this.userRole == 'demo') {
            this.granularityOptions = this.granularityOptions.filter((val) => {
                    return val.granularity != 'minute'
            });
        }
        this.updateChartData();

    },
    watch: {
        'store.filterStartDate': function(val, oldVal) {
            if (!val) {
                this.startDateError = false;
                this.updateChartData();
            } else if (dayjs(val, this.dateFormat, true).isValid()) {
                this.startDateError = false;

                if (oldVal && dayjs(val, this.dateFormat).isSame(dayjs(oldVal,this.dateFormat))) {
                    // If value is the same and the watch is triggered ignore
                    return;
                }

                /**
                * If granularity is set to 'hour' this ensures that if the start date 
                * is selected outside of a week from the end date that the end date 
                * is updated accordingly
                */
                if (this.store.filterGranularity == 'hour' && this.store.filterEndDate) {
                    const start = dayjs(val, this.dateFormat);
                    const end = dayjs(this.store.filterEndDate, this.dateFormat);

                    const allowedEnd = start.add(1, 'week') // Start Date + 1 Week

                    // Checks to see if end date is within a week of the start date
                    if (!end.isBetween(start, allowedEnd, null, "[]")) {
                        this.store.setFilterEndDate(allowedEnd.format(this.dateFormat));
                    }
                }

                this.updateChartData();
            } else if (val.length >= 10) { // If an invalid date is inputed
                this.startDateError = true;
            }
        },

        'store.filterEndDate': function(val, oldVal) {
            if (!val) {
                this.endDateError = false;
                this.updateChartData();
            } else if (dayjs(val, this.dateFormat, true).isValid()) {
                this.endDateError = false;

                if (oldVal && dayjs(val, this.dateFormat).isSame(dayjs(oldVal,this.dateFormat))) {
                    // If value is the same and the watch is triggered ignore
                    return;
                }

                /**
                * If granularity is set to 'hour' this ensures that if the end date 
                * is selected outside of a week from the start date that the start 
                * date is updated accordingly
                */
                if (this.store.filterGranularity == 'hour' && this.store.filterStartDate) {
                    const start = dayjs(this.store.filterStartDate, this.dateFormat);
                    const end = dayjs(val, this.dateFormat);
                    const allowedStart = end.subtract(1, "week"); // End Date - 1 Week

                    // Checks to see if start date is within a week of the end date
                    if (!start.isBetween(allowedStart, end, null, "[]")) {
                        this.store.setFilterStartDate(allowedStart.format(this.dateFormat));
                    }
                }

                this.updateChartData();
            } else if (val.length >= 10) { // If an invalid data is inputed
                this.endDateError = true;
            }
        },

        'store.filterGranularity': function() {
            this.updateChartData();
		},

        view() {
            this.updateChartData();
        },

		siteId() {
            this.updateChartData();
		},

        selectedTags() {
            this.updateChartData();
		}


    },
    methods: {
        updateSelectedTags(selectedTags) {
            this.selectedTags = selectedTags
        },

        unitSelectionOptions(unit) {
            const storedUnits = this.store.unitConversionMap[unit];
            if (storedUnits && Object.keys(storedUnits).length > 1) {
                return Object.values(storedUnits);
            } else {
                return [ {"toSymbol": unit} ]
            }
        },
        
        lineOptions(unit) {
            if (this.view.value === 'year') {
                return lineChartOptions.getYearOnYearLineOptions (
                    "Month",
                    "Kg CO₂e",
                    undefined,
                    "Kg CO₂e", 
                )
            } else {
                return lineChartOptions.getLineOptions (
                    "Date", 
                    this.unitOptions?.[unit]?.toSymbol ?? unit, 
                    undefined,
                    this.unitOptions?.[unit]?.toSymbol ?? unit,
                )
            }
        },
        
        barOptions(unit) {
            if (this.view.value === 'year') {
                return barChartOptions.getYearOnYearBarOptions (
                    "Month",
                    "Kg CO₂e",
                    undefined,
                    "Kg CO₂e", 
                )
            } else {
                return barChartOptions.getBarOptions(
                    "Date", 
                    this.unitOptions?.[unit]?.toSymbol ?? unit, 
                    undefined,
                    this.unitOptions?.[unit]?.toSymbol ?? unit,
                )
            }
        },

        startDateOptions(date) {
            const start = dayjs(date);
            const end = dayjs(this.store.filterEndDate, this.dateFormat, true);

            return !end.isValid() || start.isSameOrBefore(end) || this.store.filterGranularity == 'minute';
        },

        endDateOptions(date) {
            const start = dayjs(this.store.filterStartDate, this.dateFormat, true)
            const end = dayjs(date);

            return !start.isValid() || end.isSameOrAfter(start);
        },

        /**
         * @desc Gets the chart data given the provided granularity, view and provided siteId
         **/
        async updateChartData() {
            let startDate = this.store.filterStartDate ? dayjs(this.store.filterStartDate, this.dateFormat, true) : undefined;
            let endDate = this.store.filterEndDate ? dayjs(this.store.filterEndDate, this.dateFormat, true) : undefined;

            // Update values based on selection
            if (this.store.filterGranularity == 'hour') {
                const today = dayjs();

                if (!startDate && !endDate) {
                    endDate = today;
                    startDate = today.subtract(1, "week"); 
                } else if (!startDate) {
                    startDate = endDate.subtract(1, "week");
                } else if (!endDate) {
                    endDate = startDate.add(1, "week"); 
                }
            } else if (this.store.filterGranularity == 'minute') {

                // If 'minute' is selected as the granularity set start and end date to the same balues
                if (!startDate) {
                    startDate = dayjs();
                }

                endDate = startDate.endOf("day");
            }

            /* Validate Date Input and Format Dates */
            if (startDate && !startDate.isValid()) {
                this.startDateError = true;
                return
            } else if (startDate) {
                this.store.setFilterStartDate(startDate.format(this.dateFormat));
                startDate = startDate.startOf('day').toISOString();
            }

            if (endDate && !endDate.isValid()) {
                this.endDateError = true;
                return
            } else if (endDate) {
                this.store.setFilterEndDate(endDate.format(this.dateFormat));
                endDate = endDate.endOf('day').toISOString();
            }

            await this.store.fetchGraphData(this.emissionSource, this.store.filterGranularity, this.view.value, startDate, endDate, this.selectedTags);
			
            if (this.view.value !== "carbon" && this.view.value !== "year") {
                await this.store.getUnitConversions(this.store.chartData);
            }
            
            for (const unit of Object.keys(this.store.chartData)) {
                this.unitOptions[unit] = this.store.unitConversionMap?.[unit]?.[unit] ?? { toSymbol: unit };
            }
            
        },

        /**
        * @desc Returns a file for the usage graph
        */
        getFile(emissionSource = this.emissionSource) {
            this.gettingFile = true;
            api.emissions.getEmissionFile(emissionSource, this.siteId).then(() => {
                notify.primary(`Successfully retrieved ${emissionSource.split(/-/).map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(" ")} data`, "top", "File exported");
            }).catch((err) => {
                notify.withObject(err.response, "top", "Data Export");
            }).finally(
                () => {
                    this.gettingFile = false;
                }
            );
        },
    },
}

export default TrendGraphComponent;


</script>
<style scoped src="../../assets/styles/data.less" lang="less" />
