<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <AddICPMeterComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import AddICPMeterComponent from "@/components/DataComponents/ConnectDataSourceComponents/Electricity/AddICPMeterComponent.vue";

export default {
    name: "add-icp-meter-view",
    components: { AddICPMeterComponent },
    title: 'AddICPMeterComponent',
    setup() {
    return { sidebarWidth }
    }
};
</script>


<style >

</style>