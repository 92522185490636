import { defineStore } from 'pinia'
import {ref} from "vue";


export const useDataStateStore = defineStore('data', () => {
  const selectedTraceDataFileId = ref("");

  function $reset() {
    selectedTraceDataFileId.value = "";
  }

  function changeTraceDataFileId(fileId){
    if(fileId ==selectedTraceDataFileId.value){
        selectedTraceDataFileId.value = ""
    } else {
        selectedTraceDataFileId.value = fileId
    }
  }
  


  return { selectedTraceDataFileId, changeTraceDataFileId, $reset }
})