<template>
    <div :style="{ 'margin-left': sidebarWidth }">
      <ViewAllSurveysComponent />
    </div>
  </template>
  
  <script>
  import { sidebarWidth } from "@/components/SidebarComponents/state";
  import ViewAllSurveysComponent from "../../components/CommuteComponents/ViewAllSurveysComponent.vue";

  export default {
    name: "view-all-surveys-view",
    components: { ViewAllSurveysComponent },
    title: "ViewAllSurveysComponent",
    setup() {

      return { sidebarWidth };
    },
  };
  </script>