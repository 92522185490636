<template>
    <div style="display: flex; flex-direction: row; gap: 5em;">
        <div>
            <span class="text-bold">Baseline Amount: </span> <span>{{ parseInt(target.baselineAmount).toLocaleString() }} kg CO2e</span>
        </div>
        <div>
            <div v-if="target.reduceBy">
                <span class="text-bold"> Reduce Emissions by:</span> <span>{{ target.targetValue }}%</span>
            </div>
            <div v-else>
                <span class="text-bold"> Reduce Emissions to:</span> <span>{{ parseInt(target.targetValue).toLocaleString() }} kg</span>
            </div>
        </div>
        <div>
            <span class="text-bold">Baseline Date:</span> <span>{{ baselineDate }}</span>
        </div>
        <div>
            <span class="text-bold" >Due Date:</span> <span>{{ dueDate }}</span>   
        </div>
        <q-space/>
        <q-btn icon="edit" color="secondary" no-caps label="Edit Target" @click="showCreateTargetModal = true" :disabled="loading">
            <SideModalComponent v-model="showCreateTargetModal">
                <CreateTargetComponent :currentTarget="target" @close="() => {showCreateTargetModal = false; $emit('getTarget')}"/>
            </SideModalComponent>
        </q-btn>

        
    </div>

    <SideModalComponent v-model="targetStore.showChangeValueComponent">
        <ChangeValueComponent/>
    </SideModalComponent>
 
    <div v-if="!targetStore.loadingChartData" style="height: 30em;">
        <Line v-bind:chartData="targetStore.chartData" v-bind:chartOptions="chartOptions"/>
    </div>
    <div v-else class="loading-spinner">
			<q-spinner color="primary" size="3em" />
	</div>
</template>
<script>

import Line from "../ChartComponents/Line.vue";
import SideModalComponent from "../SideModalComponent.vue";
import CreateTargetComponent from "./CreateTargetComponent.vue";
import * as TargetLineOptions from "../ChartComponents/ChartOptions/TargetLineOptions"
import {useTargetStore} from "../../stores/target.store"
import ChangeValueComponent from "../ChartComponents/ChartOptionsListComponents/ChangeValueComponent.vue"
import dayjs from "dayjs";
import notify from "@/services/util/notify";

const TargetGraphComponent = {
    name: "TargetGraphComponent",
    props: ['target'],
    emits: ['edit', 'getTarget'],
    components: {
        Line,
        SideModalComponent,
        CreateTargetComponent,
        ChangeValueComponent
    },
    data() {
        return {
            loading: false,
            showCreateTargetModal: false,
            chartOptions: TargetLineOptions.getTargetLineOptions(),
            targetStore: useTargetStore()
            
        }
    },

    async created() {
        try {
            await this.targetStore.loadChartData(this.target.id)
        } catch (error) {
            notify.error("There was an issue loading the graph");
        }
    },

    computed: {
        baselineDate() {
            if (this.target.startDate) {
                return dayjs(this.target.startDate).format("MMM YYYY");
            }
            return '';
        },
        dueDate() {

            if (this.target.endDate) {

                return dayjs(this.target.endDate).format("MMM YYYY")
            }
            return ""
        }

    },

}

export default TargetGraphComponent;

</script>
<style>
</style>