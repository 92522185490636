<template>
      <div>
        <div class="users-invite-container">
            <p v-if="showStaffCommuting">Edit Survey Frequency</p>

                <div
                  v-if="loadingForm"
                  class="home-loading-spinner-container"
                  style="margin: 50px 0; justify-self: center; align-self: center;"
                >
                  <q-spinner
                    color="deep-purple-4"
                    size="5em"
                  />
                </div>

                <q-form v-else-if="showStaffCommuting" @submit.prevent="submitSettings()">
                    <q-input class="frequency-input"
                        borderless
                        v-model="frequency"
                        label="How often would you like surveys to be sent (days)"
                        :rules="[(val) => !!val || 'Please input a number', (val) => val > 0 || 'Please input a number greater than 0']"
                    />
                
                    <!-- submit button -->
                    <div class="users-invite-button">
                    <q-btn
                        no-caps
                        :loading="loading"
                        :disabled="!frequency || frequency < 1"
                        label="Submit"
                        type="submit"
                     />
                    </div>
                </q-form>

                <div class="survey-frequency-subtitle" v-else>
                  <q-icon color="warning"  size="xl" name="warning"/>
                  <div class="text">
                    To update these settings please add <span class="commuting-text">Staff Commuting</span> to one of your sites <span class="link" @click="$router.push({ name: 'sites' })">here</span>.
                  </div>
                </div>
               

            </div>
        </div>
  </template>
  
  <script>
  import "chartjs-adapter-date-fns";
  import api from "@/services/api/api";
  import notify from "@/services/util/notify";
  
  const CreateGoalComponent = {
    name: "EditCommutingSurvey",
    components: {},
  
    data() {
      return {
        // Loading vars
        loadingForm: false,
        loading: false,
        showStaffCommuting: false,

        // Form inputs
        frequency: "",
        
      };
    },

    async mounted() {
        this.loadingForm = true;

        const emissionSources = (await api.sites.getSiteEmissionSources(null)).data.emissionSources;

        this.showStaffCommuting = emissionSources.filter(source => source.urlName === 'staff-commuting').length == 1;

        this.loadingForm = false;
    },

    methods: {
  
      /**
       * @desc Submits a new goal based on form data
       */
      async submitSettings() {
        this.loading = true;
        await api.commute.editSurveyFrequency({ "frequency": this.settingsFrequency })
          .then(() => {
            notify.primary("Successfuly changed survey frequency", "top")
          })
          .catch(() => {
            notify.error("Failed to establish connection to database", "top")
          })
          .finally(() => {
            this.loading = false;
          })
      },
  
    },
  };
  
  export default CreateGoalComponent;
  </script>
  
  <style scoped>
  @import '@/assets/styles/settings.less';
  </style>