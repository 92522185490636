<template>

    <div class="guest-view-popup">
      <div>You are currently browsing the <span style="color:#9569E1">{{ getSourceOrgName() }}</span> organisation in guest mode.<q-icon 
      style="margin-left: 0.5em; cursor: pointer; "
      size="1.5em" name="close" @click="exitGuestMode()"/></div>
      
    </div>


</template>

<script>
import jwt from "jsonwebtoken";
export default {
  name: 'GuestModeSticker',
  title: 'GuestModeSticker',

  data(){
    return{
      sidebar: 1,
      navbar: 1,
    };
  },

  methods: {
    isGuestMode() {
        if(sessionStorage.getItem('guestMode') == null){
            return false
        } else {
            return true
        }
    },
    exitGuestMode(){
        sessionStorage.clear()
        window.close();
    },
    getSourceOrgName(){
      
        if(sessionStorage.getItem('guestMode') == null){
            return "N/A"
        } else {
            let fields = jwt.decode(sessionStorage.getItem('authToken'));
            let sourceTenantName = fields.sourceTenantName ?? "Unknown";
            return sourceTenantName
        }
    }
  }


};
</script>


<style >

.guest-view-popup {
  margin: 2em auto -1em auto;
  display: flex;
  font-size: 1.05rem;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  background-color: #f7f7f7;
  border-radius: 10px;
}

</style>