<template>
  <div class="password-strength-input-container">
    <q-input
      class="password-input"
      type="password"
      no-error-icon
      autocomplete
      :model-value="password"
      @update:model-value="(val) => password = val" outlined :rules="[() => strengthFactor >= 0.5 || 'Password is too weak.']"
    >
      <template v-slot:prepend>
        <q-icon name="o_lock" />
      </template>
      <q-linear-progress class="password-strength-meter" :value="strengthFactor" :color="strengthColour" size="5px" rounded/>
    </q-input>
  </div>
</template>

<script>
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';

zxcvbnOptions.setOptions({
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
});

export default {
  props: ["value"],
  data() {
    return {
      password: this.value,
      result: null,
    }
  },
  
  computed: {
    strengthColour() {
      if (this.strengthFactor <= 0.25) {
        return "red";
      } else if (this.strengthFactor <= 0.50) {
        return "orange";
      } else if (this.strengthFactor <= 0.75) {
        return "light-green";
      } else {
        return "green";
      }
    },
    strengthFactor() {
      const factor = (this.result?.score ?? 0) / 4;
      return this.password && factor < 0.25 ? 0.25 : factor;
    }
  },
  methods: {
    useZxcvbn() {
      if (this.password) {
        this.result = zxcvbn(this.password);
      } else {
        this.result = null;
      }
    },
  },
  watch: {
    password() {
      this.$emit('update:modelValue', this.password)
      this.useZxcvbn();
    },
  }
}
</script>

<style lang="less">
.password-strength-input-container {
  > .password-input {
    .q-field__control-container {
      display: flex;
      align-items: center;
    }

    .password-strength-meter {
      width: 35%;
      margin-left: 1rem;
    }
  }
}
</style>