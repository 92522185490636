<template>
  <div :style="{ 'margin-left': sidebarWidth }">
    <div class="main-tab-container">
      <div class="header">
        <h1 class="main-heading">Data Hub</h1>
      </div>
      <div class="tabs-container">
        <q-tabs
          dense
          no-caps
          active-color="primary"
          indicator-color="success"
          align="left"
          narrow-indicator
        >
          <q-route-tab
            v-for="tab in tabs"
            :key="tab.name"
            :name="tab.name"
            :label="tab.label"
            :to="{ query: { tab: tab.name } }"
          />
        </q-tabs>
        <q-separator style="margin-bottom: 1.5rem" color="grey" />
      </div>
      <component :is="selectedComponent" />
    </div>
  </div>
</template>

<script>

import getRole from "@/services/util/role";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";
import permissions from "@/rolePermissions";

import { sidebarWidth } from '@/components/SidebarComponents/state';

import BulkUploadComponent from '@/components/ManualUploadComponents/BulkUpload/BulkUploadComponent.vue';
import InvoiceUploadDataHubComponent from '@/components/ManualUploadComponents/InvoiceUploadDataHubComponent.vue';
import DataFeedConnectionsComponent from '../components/SupplierComponents/Connections/DataFeedConnectionsComponent.vue';

export default {
  components: {
    BulkUploadComponent,
    InvoiceUploadDataHubComponent,
    DataFeedConnectionsComponent
  },
  data() {
    return {
      userRole: getRole(),
      subscription: getSub(),
      subPermissions: subPermissions,
      permissions: permissions,
      sidebarWidth: sidebarWidth,
      tabs: [
        {
          name: "bulk-upload",
          label: "Excel Upload",
          component: "BulkUploadComponent",
        },
      ],
    }
  },
  created() {
    if (!this.$route.query.tab) {
      this.$route.query.tab = this.tabs[0]?.name;
    }

    if (this.flags["FLAG_5253_AI_INVOICE"] &&
      this.permissions.addData.includes(this.userRole) &&
      this.subPermissions.aiInvoiceScrape.includes(this.subscription)
    ) {
      this.tabs.push({
        name: "invoice-upload",
        label: "Invoice Upload",
        component: "InvoiceUploadDataHubComponent"
      })
    }

    if (this.flags["FLAG_5879_DATA_HUB_CONNECTIONS"] &&
      this.permissions.addData.includes(this.userRole) &&
      this.subPermissions.aiInvoiceScrape.includes(this.subscription)
    ) {
      this.tabs.push({
        name: "data-feed-connections",
        label: "Connections",
        component: "DataFeedConnectionsComponent"
      })
    }


  },
  computed: {
    selectedComponent() {
      return this.tabs.find((tab) => tab.name === this.$route.query.tab)?.component ?? this.tabs[0]?.component;
    }
  }
}
</script>

<style lang="less" scoped>

</style>
