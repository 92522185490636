<template>
  <div class="carbon-offset-upload-container">
    <div class="carbon-offset-upload-header">
      <h2 class="tab-section-heading">Upload Carbon Credits</h2>
      <p>Here you can upload your carbon credits. We will use this information to track your emission offset.</p>
    </div>
    <q-form class="carbon-offset-upload-form" @submit="uploadCarbonOffset()" ref="uploadForm">
      <div>
        <span class="offset-input-heading">Date purchased</span>
        <q-input class="offset-date-purchased" filled v-model="datePurchased" :mask="dateFormat.replace(/[a-z]/gi, '#')" :rules="[(val) => dayjs(val, dateFormat, true).isValid()]" :placeholder="dateFormat" error-message="Please enter a valid date" required>
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date v-model="datePurchased" :mask="dateFormat">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Ok" color="primary" flat></q-btn>
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div>
        <span class="offset-input-heading">Carbon credits (tonnes)</span>
        <q-input class="offset-input-credit" v-model.number="carbonCredits" filled type="number" required/>
      </div>

      <div>
        <span class="offset-input-heading">Cost of carbon credits (optional)</span>
        <q-input class="offset-input-credit" v-model.number="carbonCreditsCost" filled type="number" />
      </div>

      <div>
        <span class="offset-input-heading">Upload your files below</span>
        <div class="upload-manual-csv">
          <q-file class="upload-files justify-center items-center" v-model="file" type="file" ref="file" required
              label="Drag and drop files, or Browse" :input-style="{ height: '230px' }"
              :item-aligned=true>
  
              <template v-if="file" v-slot:prepend>
                  <q-icon name="cancel" @click.stop.prevent="file = null" class="cursor-pointer" />
              </template>
          </q-file>
        </div>
      </div>

      <q-btn class="offset-upload-button" type="submit" color="secondary" :loading="loading">Upload</q-btn>
    </q-form>
    <p></p>
  </div>
</template>

<script>
import api from "../../../services/api/api";
import notify from "@/services/util/notify";
import dayjs from "@/services/util/dayjs";

  export default {
    props: ["siteId"],
    data() {
      return {
        dateFormat: "DD/MM/YYYY",
        dayjs: dayjs,
        datePurchased: null,
        carbonCredits: null,
        carbonCreditsCost: null,
        file: null,
        loading: false,
      }
    },

    methods: {
      resetForm() {
        this.datePurchased = null;
        this.carbonCredits = null;
        this.carbonCreditsCost = null;
        this.file = null;
        this.$refs.uploadForm.reset();
      },

      async uploadCarbonOffset() {
        this.loading = true;
        try {
          await api.carbon.insertCarbonOffsetEntry({
            timestamp: this.datePurchased,
            amount: this.carbonCredits,
            cost: this.carbonCreditsCost,
          }, this.file, this.siteId)
          this.resetForm();
          notify.primary("Sucessfully uploaded carbon credits", "top");
        } catch (error) {
          let status = error?.response?.status;
          status >= 400 && status <= 499 ?
          notify.warning(error?.response?.data ?? "Unable to upload carbon credits. Please try again", "top", "Upload Unsuccessful") : 
          notify.error(error?.response?.data ?? "Unable to upload carbon credits. Please try again", "top", "Upload Unsuccessful");
        } finally {
          this.loading = false;
        }
      }
    }
  }
</script>

<style lang="less" src="@/assets/styles/carbonOffset.less" scoped />