i<template>
	<div class="Invoice-upload-status-table-container">
		<q-table  	

			:rows="invoiceStatusData" 
			:columns="columns"
			row-key="fileId"
			:pagination="pagination">

			<template #body="props">
				<q-tr :props="props">
					<q-td v-for="(col, index) in props.cols" :key="col.name" :props="props" :style="index === 0 ? retrieveBorderColour(props.row.statusCode) : {}">
						<div v-if="col.name === 'actions'">
							<q-btn v-if="props.row.statusCode === 'SUCCESS' || props.row.statusCode === 'APPROVED'" icon="visibility" round flat color="primary" @click="$emit('splitInvoice', props.row.fileId, props.row.fileName)">
								<q-tooltip>
									This action allows you to view the extracted invoice data
								</q-tooltip>
							</q-btn>
	
							<q-btn v-if="props.row.statusCode !== 'PROCESSING'" icon="download" round flat color="primary" @click="$emit('download', props.row.fileId, props.row.fileName)"></q-btn>
	
							<q-btn v-if="['testMaster', 'master', 'admin', 'demo'].includes(userRole) && props.row.statusCode !== 'PROCESSING'" icon="delete" round flat color="deep-orange">
								<q-popup-proxy :ref="`deletePopup${props.row.fileId}`">
									<q-card>
										<q-card-section>
											<span class="text-bold row">Are you sure you</span>
											<span class="text-bold row">want to delete this file?</span>
											<span class="row q-pt-sm">This action can not be undone.</span>
										</q-card-section>
										<q-card-actions align="right">
											<q-btn color="grey-6" v-close-popup>Cancel</q-btn>
											<q-btn :loading="loadingUpdate" color="red" @click="$emit('delete', props.row.fileId)">Confirm</q-btn>
										</q-card-actions> 
									</q-card>
								</q-popup-proxy>
							</q-btn>

							<q-btn v-if="stuckProcessing(props.row)" icon="cancel" round flat color="deep-orange" @click="updateFileStatus(props.row, 'FAILURE')">
								<q-tooltip>
									If the fail processing is stuck, you can manually fail the invoice.
								</q-tooltip>
							</q-btn>

						</div>
						<div v-else-if="col.name === 'fileStatus'">
							<q-circular-progress v-if="props.row.statusCode == 'PROCESSING'" rounded size="30px" :thickness="0.3" color="primary" indeterminate class="q-ml-sm"/>
							<span v-else :style="{'font-size': '1rem', 'color': retrieveStatusColour(props.row.statusCode)}">
								{{ this.fileStatusMap[props.row.statusCode] }}
							</span>
						</div>
						<div v-else>
							{{ col.value }}
						</div>
					</q-td>
				</q-tr>
			</template>
		</q-table>
	</div>
</template>

<script>
	import api from "@/services/api/api"
	import getRole from "@/services/util/role";

	export default {
		name: "InvoiceUploadStatusTable",
		props: ['invoiceStatusData', "loadingUpdate"],
		data(){
			return {
				userRole: getRole(),
				fileStatusDefinitions: [],
				columns: [
					{
						name: 'fileName',
						label: 'File Name',
						field: row=> row.fileName,
						sortable:true,
						align: 'left'
					},
					{
						name: 'fileStatus',
						label: 'Status',
						sortable:true,
						align: 'left',
						field: row=> row.statusCode
					},
					{
						name: 'uploadedBy',
						label: 'Uploaded By',
						sortable:true,
						align: 'left',
						field: row=> row.uploadedBy
					},
					{
						name: 'uploaded',
						label: 'Uploaded Date',
						sortable:true,
						align: 'left',
						field: (row)=> {
							let date = new Date(row.uploaded)
							return date.toLocaleString('en-NZ')
						}
					},
					{
						name: "actions",
						style: "width: 10%",
						label: "Action",
						align: "right",
						field: "",
					},
				],
				pagination: { rowsPerPage: 20 },

				today: new Date()
			}
		},
		computed: {
			fileStatusMap() {
				const statusMap = {};
				if(this.fileStatusDefinitions != undefined) {
					this.fileStatusDefinitions.forEach((definitionObject) => statusMap[definitionObject.statusCode] = definitionObject.description)
				}
				return statusMap
			}
		},
		async created() {
			this.fileStatusDefinitions = (await api.invoice.getInvoiceStatusOptions()).data
		},


		methods: {
			/**
			* @desc Return the colour of a given stage
			* @param {*} stageId 
			*/
			retrieveStatusColour(status) {
				let colour = '';
				switch (status) {
					case "APPROVED":
					case "SUCCESS":
						colour = "var(--secondary)";
						break;
					case "PROCESSING":
						colour = "var(--primary)";
						break;
					case "FAILURE":
						colour = "red";
						break;
					case "USAGE_LIMIT":
						colour = "orange";
						break;
					default:
						colour = "black";
						break;
				}

				return colour;
			},

			retrieveSplitInvoiceColour(hasBeenSplit) {
				let colour = '';
				switch (hasBeenSplit) {
					case true:
						colour = "primary";
						break;
					case false:
					case undefined:
						colour = "orange";
						break;
					default:
						colour = "primary";
						break;
				}

				return colour;
			},

			retrieveBorderColour(statusCode) {
				let colour = statusCode === "PROCESSING" ? "var(--primary)" : this.retrieveStatusColour(statusCode);
				return {
					borderLeft: `5px solid ${colour}`
				}
			},

			/**
			 * @desc Check if the file has been processing for more than 10 minutes. If it has, allow the user to set the status
			 * @param {Object} row
			 */
			stuckProcessing(row) {
				if (row.statusCode == 'PROCESSING') {
					const uploadedDate = new Date(row.uploaded);
					const differenceInMilliseconds = this.today - uploadedDate;

					// Convert the difference to minutes
					const differenceInMinutes = Math.floor(differenceInMilliseconds / 1000 / 60);

					return differenceInMinutes > 10;
				} 
				
				return false;
			},


			/**
			 * @desc Update the file status
			 * @param {Object} row 
			 * @param {String} status 
			 */
			async updateFileStatus(row, status) {
				try {
					await api.invoice.updateInvoiceStatus(row.fileId, status)
					row.statusCode = status
				} catch (error) {
					console.error(error);
				}
			}
		}


	}
</script>
