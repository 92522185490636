<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <SingleStaffCommuteSurvey/>
    </div>
</template>

<script>
import SingleStaffCommuteSurvey from "@/components/CommuteComponents/SingleCommuteSurveyComponent.vue";

import { sidebarWidth } from '@/components/SidebarComponents/state'
// import {  onBeforeRouteUpdate } from 'vue-router';
// import api from "@/services/api/api";

export default {
    name: "single-staff-commute-view",
    components: { SingleStaffCommuteSurvey },
    title: 'single-staff-commute',
    setup() {

		return { sidebarWidth }

	}
}
</script>
