<template>
	<div class="view-custom-datasets-container">
		<div class="custom-datasets-title">Custom datasets</div>
		<div
			v-if="loading"
			class="home-loading-spinner-container"
		>
			<q-spinner
				color="primary"
				size="8em"
			/>
		</div>

		<div v-if="!loading && loadSuccess">
			<q-table
				:filter="filter"
				:rows="datasets"
				:columns="tableColumns"
				@row-click="datasetRowClicked"
			>
				<template v-slot:top-left>
					<q-input
						outlined
						borderless
						dense
						debounce="300"
						v-model="filter"
						placeholder="Search"
					>
						<template v-slot:append>
							<q-icon
								name="close"
								@click="filter = ''"
								class="cursor-pointer"
							/>
							<q-icon name="search" />
						</template>
					</q-input>
				</template>
			</q-table>
		</div>
		<q-btn
			v-if="permissions.createCustomDatasets.includes(this.userRole)"
			label="Create new custom dataset"
			color="green-5"
			no-caps
			@click="this.showCreateCustomDatasetModal = true"
		/>

		<q-dialog
			v-if="permissions.createCustomDatasets.includes(this.userRole)"
			position="right"
			v-model="showCreateCustomDatasetModal"
		>
			<CreateCustomDatasetComponent
				class="new-dataset-container"
				@close="this.showCreateCustomDatasetModal = false"
				@reload="getCustomDatasets()"
				style="width: 60vw; max-width: 80vw"
			/>
		</q-dialog>
	</div>
</template>

<script>
import notify from "@/services/util/notify";
import api from "@/services/api/api";
import getRole from "@/services/util/role";
import roles from "@/roles";
import permissions from "@/rolePermissions";
import CreateCustomDatasetComponent from "./CreateCustomDatasetComponent.vue";

const ViewCustomDatasetsComponent = {
	name: "ViewCustomDatasets",

	components: { CreateCustomDatasetComponent },

	data() {
		return {
			userRole: getRole(),
			roles: roles,
			permissions: permissions,

			loading: false,
			loadSuccess: false,
			loadFail: false,

			showCreateCustomDatasetModal: false,

			datasets: [],
			tableColumns: [
				{
					name: "id",
					field: "id",
					label: "ID",
					align: "left",
					sortable: true,
				},
				{
					name: "datasetName",
					field: "datasetName",
					label: "Name",
					sortable: true,
				},
				{
					name: "emissionsFactor",
					field: "emissionsFactor",
					label: "Emission Factor",
					sortable: true,
				},
				{
					name: "units",
					field: "units",
					label: "Units",
					sortable: true,
				},
			],

			filter: "",
		};
	},

	created() {
		this.getCustomDatasets();
	},

	methods: {
		/**
		 * @desc Retrieve all custom datasets from the server
		 */
		async getCustomDatasets() {
			this.loading = true;
			this.loadSuccess = false;
			api.customDatasets
				.getCustomDatasets()
				.then((res) => {
					this.datasets = res.data;
					this.loadSuccess = true;
				})
				.catch((err) => {
					let errMessage = err.response.data ?? err.message;
					notify.error(errMessage, "top");
					this.loadFail = true;
				})
				.finally(() => {
					this.loading = false;
				});
		},

		/**
		 * @desc When a custom dataset is clicked, redirect the user to that custom dataset data page.
		 * @param {Object} row row containing id of page to redirect to
		 */
		datasetRowClicked(evt, row) {
			this.$router.push({
				name: "customDataset",
				params: { datasetId: row.id },
			});
		},
	},
};

export default ViewCustomDatasetsComponent;
</script>

<style scoped>
@import "../../../assets/styles/customDatasets.css";
</style>
