<template>
    <div class="datasource-list-container">
      <h2 class="tab-section-heading">Connect Water Data (Invoice Scraping)</h2>
  
      <div class="content-item center-column eiep-list-item">
        <q-table :rows="customerNumberList" :columns="columns" row-key="name">
          <template v-slot:body-cell-status="props">
            <q-td :props="props">
              <div class="meter-connection-status">
                <q-badge v-if="props.value == 'Valid'" text-color="green">
                  <div class="text-center full-width">{{ props.value }}</div>
                </q-badge>
  
                <q-badge v-if="props.value == 'Invalid'" text-color="red">
                  <div class="text-center full-width">{{ props.value }}</div>
                  <q-tooltip>
                    Something has gone wrong while setting up your API connection
                    Please contact us to solve this issue
                  </q-tooltip>
                </q-badge>
              </div>
            </q-td>
          </template>
  
          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <q-spinner 
                v-if="orderUpdateLoading"
                color="primary"
                size="3em"
                style="padding: 10px"
              />

              <q-btn
                icon="delete"
                flat
                color="deep-orange"
                @click="(confirm = true) && (deleteAPIId = props.row.id)"
              ></q-btn>
            </q-td>
          </template>
          
        </q-table>
  
        <q-btn
          class="add-connection-btn"
          no-caps
          text-color="black"
          color="white"
          icon="add"
          label="Add a new water connection"
          @click="showAddWaterCustomerNumber = true"
        >
          <q-tooltip>
            Connect your water  with us to have automatic water
            data
          </q-tooltip>
        </q-btn>
      </div>
      
      <q-dialog position="right" v-model="showAddWaterCustomerNumber" class="new-connection">
        <AddFreightAPI @close="showAddWaterCustomerNumber = false"/>
      </q-dialog>
  
      <q-dialog v-model="confirm" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <span class="q-ml-md"
            >Are you sure you want to delete this API connection?</span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Cancel"
            color="primary"
            @click="deleteAPIId = null"
            v-close-popup
          />
          <q-btn
            flat
            label="Delete"
            color="deep-orange"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    </div>
  </template>
  
  <script>
  import api from "@/services/api/api";
  import notify from "@/services/util/notify";
  
  import AddFreightAPI from "@/components/DataComponents/ConnectDataSourceComponents/Water/AddWaterCustomerNumberComponent.vue";
  
  const WaterSourceOptionsComponent = {
    name: "WaterSourceOptionsComponent",
  
    components: { AddFreightAPI },
  
    data() {
      return {
        customerNumberList: [],
        columns: [
          {
            name: "platformName",
            align: "left",
            label: "Customer Number",
            field: "waterCustomerNumber",
            sortable: true,
          },
          {
          name: "actions",
          style: "width: 10%",
          label: "",
          align: "left",
          field: "",
        },

        ],
  
        confirm: false,
        deleteAPIId: null,
  
        showAddWaterCustomerNumber: false,
  
        orderUpdateLoading: false,
      };
    },
  
    created() {
      this.onInitialize();
    },
  
    methods: {
      /**
       * Retrieve current API connections
       */
      onInitialize() {
        api.water
          .getWaterCustomerNumber()
          .then((res) => {
            this.customerNumberList = res.data;

          })
          .catch((error) => {
            notify.error(error.response.data, "top");
          });
      },
  
      /**
       * Given a APIId, delete the corresponding api information
       * @param {*}
       */
      deleteAPIConnection() {
        api.freight
          .deleteAPIConnection(this.deleteAPIId)
          .then(() => {
            this.apiList = this.apiList.filter(
              (item) => item.id !== this.deleteAPIId
            );
            this.deleteAPIId = "";
            notify.primary("API connection successfully deleted", "top");
          })
          .catch(() => {
            notify.error("Error deleting API connection", "top");
          });
      },
  
  
      /**
       * Update eShip Orders
       */
      updateOrders() {
        this.orderUpdateLoading = true
        api.freight.updateOrders().then(() => {
            notify.primary("Successfully updated orders", "top")
          })
          .catch((error) => {
            notify.error(error.response.data, "top");
          }).finally(() => {
            this.orderUpdateLoading = false
          })
      }
    },
  };
  
  export default WaterSourceOptionsComponent;
  </script>
  
  <style scoped>
  @import "../../../../assets/styles/viewDataSources.less";
  </style>