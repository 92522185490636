import { useChartStateStore } from '@/stores/chartState.store'
import { useDataStateStore } from '@/stores/dataState.store'

import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";

// const label = useChartLabelStore()

/**
 * @desc Returns the style options for the different line charts we use
 * 
 * @param {String} xAxisTitle the title of the x axis
 * @param {String} yAxisTitle the title of the y axis
 * @param {String} graphTitle title of graph
 * @param {String} unit the unit of the data being displayed
 * 
 * @returns {Object} Chartjs graph options
 */
export function getLineOptions(xAxisTitle, yAxisTitle, graphTitle, unit = '') {
  const store = useChartStateStore();
  const dataStore = useDataStateStore();
  return {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (evt, activeElements) => {
      const clickedDatasetIndex = activeElements[0]?.datasetIndex;

      if (unit !== '' && subPermissions.dataTracing.includes(getSub()) && clickedDatasetIndex !== undefined) {
        try {
          store.changeView("trace");
          store.dataView.valueOf("trace");
          const index = evt.chart.data.labels[activeElements[0].index];
          store.addLabel(index);
          const baseUnit = Object.keys(store.unitConversionMap).find(key => Object.keys(store.unitConversionMap[key]).includes(unit));
          store.addUnit(baseUnit);
          store.addIndex(Object.keys(store.chartData[baseUnit]).indexOf(index));
          store.addDatasetIndex(clickedDatasetIndex);
        } catch {
          store.addLabel("");
          store.addFileId("");
          dataStore.changeTraceDataFileId("");
        }
      }
    },
    borderColor: "#1976D2",
    indexAxis: "x",
    
    plugins: {
      tooltip: {
        callbacks: {
          label: function (item) {
            var label = item.dataset.label, currentValue = item.raw
            let formattedCurrentValue = parseFloat(currentValue.toFixed(2)).toLocaleString("en-US")
            if (unit === '$') {
              return label + ": "+ unit + formattedCurrentValue
            } else {
              return label + ": " + formattedCurrentValue  + ' ' + unit
            }
          }
        }
      },

      title: {
        display: true,
        text: graphTitle,
        font: {
          size: 16
        }
      },

      legend: {
        position: 'bottom'
      },

      datalabels: {
        display: false
      }

    },

    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: xAxisTitle,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        title: {
          display: true,
          text: yAxisTitle,
        },
      },
    },

    elements: {
      point: {
        pointHoverBorderWidth: 10,
      },
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
  };
}


/**
 * @desc Return the style options for the year on year line chart. It has different tooltips and also doesn't allow
 * for file tracing.
 * 
 * @param {String} xAxisTitle the title of the x axis
 * @param {String} yAxisTitle the title of the y axis
 * @param {String} graphTitle title of graph
 * @param {String} tooltipUnit the unit to be displayed in the tooltup
 * 
 * @returns {Object} Chartjs graph options
 */
export function getYearOnYearLineOptions(xAxisTitle, yAxisTitle, graphTitle, tooltipUnit = '') {

  return {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: "#1976D2",
    indexAxis: "x",

    plugins: {
      tooltip: {
        callbacks: {
          label: function (item){
            var label = item.label,
            
              today = new Date(),
              n = 0,
              year,
              months = {Jan:1, Feb:2, Mar:3, Apr:4, May:5, Jun:6, Jul:7, Aug:8, Sept:9, Oct:10, Nov:11, Dec:12}

              let labelMonth = months[label];

              // Determine how many years we need to go back. If it is in the second group, we know we at least need to go back one full year
              if (item.dataset.label.split(" ")[0] == '24') { n += 1}

              // If the label month is greater than the current month, we need to go back a full year. 
              if (labelMonth > today.getMonth() + 1) {
                year = today.getFullYear() - (n + 1)
              } else {
                year = today.getFullYear() - (n)
              }

            return label + ": " + item.raw.toFixed(2) + ' ' + tooltipUnit + ' (' + year + ")"
          }
        }
      },

      title: {
        display: true,
        text: graphTitle,
        font: {
          size: 20
        }
      },

      legend: {
        position: 'bottom'
      },

      datalabels: {
        display: false
      }

    },

    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: xAxisTitle,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        title: {
          display: true,
          text: yAxisTitle,
        },
      },
    },

    elements: {
      point: {
        pointHoverBorderWidth: 10,
      },
      line: {
        cubicInterpolationMode: "monotone",
      },
    },
  };
}