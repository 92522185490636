<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <MileageComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import MileageComponent from "../components/MileageComponents/MileageComponent.vue";

export default {
    name:  "mileage-claims-view",
    components: { MileageComponent },
    title: 'mileage-claims',
    setup() {
        return { sidebarWidth }
    }
};
</script>