<template>
  <div class="oid-container">
    <div class="oid-header">
      <h2 class="tab-section-heading">Organisation Intensity Denominators</h2>
      <div class="oid-sub-header">
        <p>You can manage your organisation intensity denominators below</p>
        <q-btn fab-mini icon="add" color="secondary" @click="openCreateOIDModal = true" no-caps />
        <SideModalComponent v-model="openCreateOIDModal">
          <CreateOIDComponent @close="initOIDRows(); openCreateOIDModal = false" />
        </SideModalComponent>
        </div>
    </div>
    <q-card>
      <q-card-section>
        <q-input class="search-table" placeholder="Search" color="primary" v-model="search">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
        <q-table
          class="oid-table"
          :rows="oidRows"
          :columns="oidColumns"
          :filter="search"
          :loading="loadingOIDs"
          :rows-per-page-options="[10]"
          row-key="uuid"
        >
          <template #body="props">
            <q-tr :props="props">
              <q-td v-for="col in props.cols" :key="col.name" :props="props">
                <div v-if="col.name === 'displayName'">
                  <span>{{ props.row.displayName }}</span>
                  <q-btn 
                    dense
                    round
                    flat
                    style="margin-left: 3px;"
                    :icon="props.expand ? 'arrow_drop_up' : 'arrow_drop_down'"
                    @click="handleDropdown(props)"
                  />
                </div>
                <div v-else-if="col.name === 'action'">
                  <q-btn  v-if="flags.FLAG_4650_OID" flat fab-mini icon="edit_square" color="primary" class="edit-button" @click="initDenominators(props.row)">
                    <q-popup-proxy
                      class="edit-denominator-popup-proxy"
                      :ref="`editOIDPopup${props.row.uuid}`"
                      @before-hide="resetValues()"
                    >
                      <q-banner>
                        <q-form class="edit-form" @submit="submitChanges(props)">
                          <span class="edit-heading">Edit Organisation Denominator</span>
                          <div class="edit-input">
                            <q-input
                              class="name-input"
                              label="Name"
                              :model-value="editDenominatorName"
                              @update:model-value="(val) => editDenominatorName = val"
                              maxlength="50"
                              required
                            />
                            <br>
                              <q-input
                                borderless
                                outlined
                                label="Description"
                                :model-value="editDenominatorDescription"
                                @update:model-value="(val) => editDenominatorDescription = val"
                                type="textarea"
                                maxlength="200"
                                required
                              />
                          </div>

                          <div class="buttons-container">
                            <q-btn label="Cancel" no-caps color="grey-5" v-close-popup />
                            <div>
                              <q-btn
                                type="submit"
                                label="Confirm"
                                color="secondary"
                                no-caps
                                />
                              </div>
                          </div>
                        </q-form>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>

                  <q-btn flat fab-mini icon="delete" color="red" @click="confirmOIDDeletion[props.row.uuid] = true">
                    <q-popup-proxy class="oid-rate-delete-popup" :ref="`deleteOIDPopup${props.row.uuid}`">
                      <q-banner>
                        <div class="oid-rate-delete-confirmation">
                          <span class="delete-confirmation-heading">Are you sure you want to delete this?</span>
                          <span>This will be deleted permanently. You can not undo this.</span>
                          <div>
                            <q-btn style="margin-right: 1rem; border: 1px solid black" text-color="black" type="submit" label="Cancel" color="white" no-caps v-close-popup />
                            <q-btn type="submit" label="Delete" color="red" @click="deleteOID(props.row.uuid)" no-caps />
                          </div>
                        </div>
                      </q-banner>
                    </q-popup-proxy>
                  </q-btn>
                </div>

                <span v-else>{{ col.value }}</span>
              </q-td>
            </q-tr>

            <q-tr v-if="props.expand" :props="props">
              <q-td colspan="100%">
                <q-card flat>
                  <q-card-section>
                    <q-table
                      :columns="oidRateColumns"
                      :rows="oidRates[props.row.uuid].data || []"
                      :rows-per-page-options="[10]"
                      :loading="oidRates[props.row.uuid].loading"
                      row-key="uuid"
                    >
                      <template #body-cell-action="rateProps">
                        <q-td :props="rateProps">
                          <q-btn flat fab-mini icon="delete" color="red">
                            <q-popup-proxy class="oid-rate-delete-popup" :ref="`deleteRatePopup${rateProps.row.id}`">
                              <q-banner>
                                <div class="oid-rate-delete-confirmation">
                                  <span class="delete-confirmation-heading">Are you sure you want to delete this?</span>
                                  <span>This will be deleted permanently. You can not undo this.</span>
                                  <div>
                                    <q-btn style="margin-right: 1rem; border: 1px solid black" text-color="black" type="submit" label="Cancel" color="white" no-caps v-close-popup />
                                    <q-btn type="submit" label="Delete" color="red" @click="deleteOIDRate(props.row.uuid, rateProps.row.id)" no-caps />
                                  </div>
                                </div>
                              </q-banner>
                            </q-popup-proxy>
                          </q-btn>
                        </q-td>
                      </template>
                    </q-table>

                    <q-expansion-item
                      class="add-expansion"
                      expand-icon="add"
                      expanded-icon="remove"
                      dense
                      expand-icon-toggle
                      @before-show="initNewOIDRate(props.row.uuid)"
                      @after-hide="resetNewOIDRateInputs(props.row.uuid)"
                      :ref="`addOIDRateExpansion${props.row.uuid}`"
                    >
                      <q-form v-if="oidRates[props.row.uuid].new" class="add-oid-rate-form" :ref="`addOIDRateForm${props.row.uuid}`" @submit="addOIDRate(props.row.uuid)">
                        <q-tr>
                          <q-td>
                            <span class="add-oid-rate-label">Start Date</span>
                            <q-input
                              v-model="oidRates[props.row.uuid].new.startDate"
                              dense
                              :mask="dateFormat.replace(/[a-z]/gi, '#')"
                              :placeholder="dateFormat"
                              :rules="[(date) => dayjs(date, dateFormat, true).isValid() && isValidStartDate(date, oidRates[props.row.uuid].new.endDate, dateFormat, dateFormat) || 'Please enter a valid date']"
                              clearable
                              required
                              :ref="`startDateInput${props.row.uuid}`"
                            >
                              <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy cover transition-show="scale" transition-hide="scale" @before-hide="checkEndDate(oidRates[props.row.uuid].new, props.row.uuid)">
                                    <q-date v-model="oidRates[props.row.uuid].new.startDate" :mask="dateFormat">
                                      <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                            </q-input>
                          </q-td>
                          <q-td>
                            <span class="add-oid-rate-label">End Date</span>
                            <q-input
                              dense
                              v-model="oidRates[props.row.uuid].new.endDate"
                              :mask="dateFormat.replace(/[a-z]/gi, '#')"
                              :placeholder="dateFormat"
                              :rules="[(date) => dayjs(date, dateFormat, true).isValid() && isValidEndDate(oidRates[props.row.uuid].new.startDate, date, dateFormat, dateFormat) || 'Please enter a valid date']"
                              clearable
                              required
                            >
                              <template v-slot:append>
                                <q-icon name="event" class="cursor-pointer">
                                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                                    <q-date v-model="oidRates[props.row.uuid].new.endDate" :mask="dateFormat" :options="(date) => isValidEndDate(oidRates[props.row.uuid].new.startDate, date, dateFormat, 'YYYY/MM/DD')">
                                      <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                      </div>
                                    </q-date>
                                  </q-popup-proxy>
                                </q-icon>
                              </template>
                            </q-input>
                          </q-td>
                          <q-td>
                            <span class="add-oid-rate-label">Value</span>
                            <q-input
                              dense
                              v-model="oidRates[props.row.uuid].new.value" :rules="[(val) => val > 0 || 'Please enter a number greater than 0']"
                              required
                              step="any"
                              type="number"
                            />
                          </q-td>
                        </q-tr>
                        <q-btn type="submit" label="Add" color="secondary" no-caps />
                      </q-form>
                    </q-expansion-item>
                  </q-card-section>
                </q-card>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import api from "@/services/api/api.js";
import notify from "@/services/util/notify";
import dayjs from "@/services/util/dayjs";
import CreateOIDComponent from "./SidePanels/CreateOIDComponent"
import SideModalComponent from "../SideModalComponent.vue";

export default {
  components: {
    CreateOIDComponent,
    SideModalComponent,
  },
  data() {
    return {
      openCreateOIDModal: false,
      dayjs: dayjs,
      dateFormat: "DD/MM/YYYY",
      confirmOIDDeletion: {},
      loadingOIDs: false,
      search: "",
      oidRates: {},
      oidColumns: [
        {
          name: "displayName",
          label: "Name",
          field: (row) => row.displayName,
          align: "left",
          required: true,
        },
        {
          name: "description",
          label: "Description",
          field: (row) => row.description,
          align: "left",
        },
        {
          name: "action",
          label: "Action",
          align: "center",
        },
      ],
      oidRows: [],
      oidRateColumns: [
        {
          name: "startDate",
          label: "Start Date",
          field: (row) => row.startDate,
          align: "left",
          sort: (a, b) => new Date(a) - new Date(b),
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour12: false
          }),
          sortable: true,
        },
        {
          name: "endDate",
          label: "End Date",
          field: (row) => row.endDate,
          align: "left",
          format: (date) => new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour12: false
          }),
          sortable: true,
        },
        {
          name: "value",
          label: "Value",
          field: (row) => row.value,
          align: "left",
        },
        {
          name: "action",
          label: "Action",
          align: "center",
        },
      ],
      editDenominatorName: null,
      editDenominatorDescription: null
    }
  },
  async created() {
    await this.initOIDRows();
  },
  methods: {
    /**
     * @desc Checks if the start date is the same or after the end date.
     * 
     * @param {String} startDate 
     * @param {String} endDate 
     * @returns {Boolean}
     */
    isValidEndDate(startDate, endDate, startDateFormat, endDateFormat) {
      return startDate ? dayjs(endDate, endDateFormat).isSameOrAfter(dayjs(startDate, startDateFormat)) : true;
    },


    /**
     * @desc Checks if the start date is the same or before the end date.
     * 
     * @param {String} startDate 
     * @param {String} endDate 
     * @returns {Boolean}
     */
    isValidStartDate(startDate, endDate, startDateFormat, endDateFormat) {
      return endDate ? dayjs(startDate, startDateFormat).isSameOrBefore(dayjs(endDate, endDateFormat)) : true;
    },


    /**
     * @desc Checks if the end date is before the start date. Reset the end date if so.
     * 
     * @param {Object} newOidRate
     */
    checkEndDate(newOidRate, uuid) {
      const start = dayjs(newOidRate.startDate, this.dateFormat);
      const end = dayjs(newOidRate.endDate, this.dateFormat);

      if (start.isAfter(end)) {
        newOidRate.endDate = "";
        this.$refs[`startDateInput${uuid}`].validate();
      }
    },


    /**
     * @desc Initialises the OIDs
     */
    async initOIDRows() {
      this.loadingOIDs = true;

      try {
        this.oidRows = (await api.oid.getOIDs()).data;
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingOIDs = false;
      }
    },


    /**
     * @desc Initialises the OID rates
     * 
     * @param {String} uuid 
     */
    async initOIDRates(uuid) {
      if (!this.oidRates[uuid]) {
        this.oidRates[uuid] = {};
      }

      this.oidRates[uuid].loading = true;
      
      try {
        this.oidRates[uuid].data =  (await api.oid.getOIDRatesByUUID(uuid)).data;
      } catch (err) {
        this.oidRates[uuid].data = [];
        notify.withObject(err.response);
      } finally {
        this.oidRates[uuid].loading = false;
      }
    },


    /**
     * @desc Adds a new OID rate
     * 
     * @param {String} uuid 
     */
    async addOIDRate(uuid) {
      try {
        await api.oid.createOIDRate(
          uuid,
          dayjs(this.oidRates[uuid].new.startDate, this.dateFormat).format("YYYY-MM-DD"),
          dayjs(this.oidRates[uuid].new.endDate, this.dateFormat).format("YYYY-MM-DD"),
          this.oidRates[uuid].new.value,
        );
        this.$refs[`addOIDRateExpansion${uuid}`].hide();
        this.resetNewOIDRateInputs(uuid);
        this.initOIDRates(uuid);
        notify.primary(`Successfully created new OID rate for ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      }
    },


    /**
     * @desc Resets the new OID rate inputs
     * 
     * @param {String} uuid 
     */
    initNewOIDRate(uuid) {
      this.oidRates[uuid].new = {
        startDate: "",
        endDate: "",
        value: null,
      };
    },


    /**
     * @desc Deletes an OID rate
     * 
     * @param {String} uuid 
     * @param {Number} id 
     */
    async deleteOIDRate(uuid, id) {
      try {
        await api.oid.deleteOIDRateByID(id);
        this.initOIDRates(uuid);

        this.$refs[`deleteRatePopup${id}`].hide();
        notify.primary("Successfully deleted OID rate");
      } catch (err) {
        notify.withObject(err.response);
      }
    },


    /**
     * @desc Deletes an OID
     * 
     * @param {String} uuid 
     */
    async deleteOID(uuid) {
      try {
        await api.oid.deleteOID(uuid);
        this.initOIDRows();
        this.$refs[`deleteOIDPopup${uuid}`][0].hide();
        notify.primary(`Successfully deleted OID: ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      }
    },


    /**
     * @desc Resets the new OID values
     * 
     * @param {String} uuid 
     */
    resetNewOIDRateInputs(uuid) {
      this.initNewOIDRate(uuid);
      this.$refs[`addOIDRateForm${uuid}`].reset();
    },


    /**
     * @desc Opens the OID rates dropdown
     * 
     * @param {Object} props 
     */
    async handleDropdown(props) {
      props.expand = !props.expand;

      if (props.expand) {
        await this.initOIDRates(props.row.uuid);
      } else {
        delete this.oidRates[props.row.uuid];
      }
    },


    /**
     * Set the values for the edit denominator popup
     * @param {Object} row 
     */
    initDenominators(row) {
      this.editDenominatorName = row.displayName
      this.editDenominatorDescription = row.description
    },

    
    /**
     * @desc Reset the input values.
     */
    resetValues() {
      this.editDenominatorName = null;
      this.editDenominatorDescription = null;
    },


    /**
     * @desc Submit the changes.
     * 
     * @param {Object} props the props value contaning the row data
     */
    async submitChanges(props) {
      let uuid = props.row.uuid;

      let payload = {
        displayName: this.editDenominatorName,
        description: this.editDenominatorDescription
      }

      try {
        await api.oid.editOID(uuid, payload) 

        notify.primary(`Successfully updated OID: ${uuid}`)

        props.row.displayName = this.editDenominatorName;
        props.row.description = this.editDenominatorDescription;

        this.$refs[`editOIDPopup${uuid}`][0].hide()

      } catch (err) {
        notify.withObject(err.response)
      }
    },

  }
}
</script>

<style lang="less" src="@/assets/styles/oidSettings.less" />