import axiosInstance from "./axios-instance";

/**
 * @desc Get all the traces for data types.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDataTypesTrace = async () => {
  return await axiosInstance.get("/api/trace/data-type").then(res => res);
};

/**
 * @desc Get all the data types. If provided, for specific emission source.
 * 
 * @param {String} emissionSource 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getDataTypes = async (emissionSource = "") => {
  const query = emissionSource ? `?emissionSource=${emissionSource}` : "";
  return await axiosInstance.get(`/api/data-types${query}`).then((res) => res);
};

const getDataTypesUsed = async (startDate, endDate) => {
	return await axiosInstance.get(
		`/api/data-types/used`,
		{
			params: {
				startDate,
				endDate
			},
		}
	).then((res) => res);
}

const addDataType = async (payload) => {
	return await axiosInstance.post("/api/data-types", payload).then((res) => res);
};

const deleteDataType = async (uuid) => {
	return await axiosInstance.delete(`/api/data-types/${uuid}`).then((res) => res);
};

const getConversionRates = async (uuid) => {
	return await axiosInstance.get(`/api/data-types/${uuid}/conversion-rates`).then((res) => res);
}

const updateConversionRate = async (conversionRateId, payload) => {
	return await axiosInstance.patch(`/api/data-types/${conversionRateId}/conversion-rates`, payload).then((res) => res);
};

const deleteConversionRate = async (uuid, conversionRateId, message) => {
	return await axiosInstance.delete(`/api/data-types/${uuid}/conversion-rate/${conversionRateId}`, {data: {message}}).then((res) => res);
};

const addConversionRate = async (uuid, payload) => {
	return await axiosInstance.post(`/api/data-types/${uuid}/conversion-rate`, payload).then((res) => res);
}

const updateDataType = async (uuid, payload) => {
	return await axiosInstance.patch(`/api/data-types/${uuid}`, payload).then((res) => res);
};

const getGHGSubcategories = async () => {
	return await axiosInstance.get(`/api/data-types/ghg/sub-categories`).then((res) => res);
};

export default {
	getDataTypes,
	getDataTypesUsed,
	getDataTypesTrace,
	addDataType,
	getConversionRates,
	updateConversionRate,
	deleteConversionRate,
	addConversionRate,
	deleteDataType,
	updateDataType,
	getGHGSubcategories,
}
