/**
 * carbon.js
 * 
 * API for handling getting all carbon associated data
 */

import axiosInstance from "./axios-instance";
import axios from 'axios'


/**
 * @desc Gets The latest carbon price from database
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCarbonPrice = async function() {
    return await axiosInstance.get(`/api/carbon/price/credit`).then(res => res);
}


/**
 * @desc Gets the emissions factors currently used within platform
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEmissionFactors = async function(emissionSource = "") {
    return await axiosInstance.get(`/api/carbon/${emissionSource}`).then(res => res);
}


/**
 * @desc Gets electricity carbon data in line chart format
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getElectricityCarbonLineChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/electricity/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Gets transport carbon data in line chart format
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTransportCarbonLineChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/fuel/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Gets water carbon data in line chart format
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWaterCarbonChart =  async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/water/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Gets stationary Fuels data in line chart format
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStationaryFuelsCarbonLineChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/stationary-fuel/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Gets total carbon emissision in pie chart format
 * 
 * @param {String} filters query string to filter data
 * @param {String} granularity granularity of graph
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTotalCarbonPieChart = async function(filters, granularity) {
    return await axiosInstance.get(`/app/carbon/total/chart${filters}/${granularity}`).then(res => res)
}


/**
 * @desc Get waste chart of carbon conversion data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWasteCarbonConversionChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/waste/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Get air travel chart of carbon conversion data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAirTravelCarbonConversionChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/air-travel/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Get custom dataset chart of carbon conversion data
 * 
 * @param {String} datasetId id of dataset
 * @param {String} granularity granularity of graph
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCustomDatasetCarbonConversionChart = async function(datasetId, granularity) {
    return await axiosInstance.get(`/app/carbon/custom-datasets/${datasetId}/chart/${granularity}`).then(res => res);
};


/**
 * @desc Get freight chart of carbon conversion data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFreightCarbonConversionChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/freight/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Get stationary fuels chart of carbon conversion data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStationaryFuelsCarbonChart = async function(granularity, siteId) {
	return await axiosInstance.get(`/app/carbon/stationary-fuels/chart/${granularity}`, {params: {siteId}}).then(res => res);
}

/**
 * @desc Get staff commute chart of carbon conversion data
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCommutingCarbonConversionChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/carbon/commute/chart/${granularity}`, {params: {siteId}}).then(res => res);
};


/**
 * @desc Retrieve live carbon price data.
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLiveCarbonPrice = async function() {
    return await axios({
        method: 'get',
        url: 'https://api.dovu.earth/api/carbon/price/credit',
    })
};


/**
 * @desc Retrieve the exchange rate of Euro to NZD
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLiveEURtoNZD = async function() {
    return await axios({
        method: 'get',
        url: 'https://api.exchangerate.host/convert?from=EUR&to=NZD',
    })
};

/**
 * @desc Retrievestotal carbon offset over the given time period
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCarbonOffsetTotal = async function(granularity, siteId) {
	return await axiosInstance.get(`/app/carbon-offset/total/${granularity}`, {params: {siteId}}).then(res => res);
}

/**
 * @descAdds a carbon offset entry
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const insertCarbonOffsetEntry = async function(payload, file, siteId) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('timestamp', payload.timestamp)
    formData.append('amount', payload.amount)
    formData.append('cost', payload.cost)

	return await axiosInstance.post(`/app/carbon-offset`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params: {
            siteId,
        }
    }).then(res => res);
}

export default {
    getEmissionFactors,
    getElectricityCarbonLineChart,
    getTransportCarbonLineChart,
    getTotalCarbonPieChart,
    getWasteCarbonConversionChart,
    getWaterCarbonChart,
    getAirTravelCarbonConversionChart,
    getFreightCarbonConversionChart,
	getStationaryFuelsCarbonLineChart,
    getCustomDatasetCarbonConversionChart,
	getStationaryFuelsCarbonChart,
	getCommutingCarbonConversionChart,
    getLiveCarbonPrice,
    getLiveEURtoNZD,
    getCarbonPrice,
    getCarbonOffsetTotal,
    insertCarbonOffsetEntry
}