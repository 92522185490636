/**
 * @desc Returns the style options for the pie charts we use
 * 
 * @param {String} tooltipUnit the unit to be displayed in the tooltup
 * @param {String} graphTitle title of graph
 * 
 * @returns {Object} Chartjs graph options
 */
export function getPieOptions(tooltipUnit, graphTitle) {
  return {
    responsive: true,
    maintainAspectRatio: false,

    onClick: function () {
      this.plz = this.getActiveElements()[0];
    },

    plugins: {
      tooltip: {
        callbacks: {
          label: function (item) {
            var label = item.label,
              currentValue = item.raw,
              total = 0,
              data = item.dataset.data;

            for (let i = 0; i < data.length; i++) {
              total += parseFloat(data[i]);
            }
            let percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return (
              label +
              ": " +
              currentValue +
              " " +
              tooltipUnit +
              " (" +
              percentage +
              "%)"
            );
          },
        },
      },

      title: {
        display: true,
        text: graphTitle,
        font: {
          size: 20,
        },
      },
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          boxWidth: 10,
        },
        datalabels: {
          display: false
        }
      },
    },
  };
}
