/**
 * commute.js
 * 
 * API for handling getting all staff commute associated data
 */

import axiosInstance from "./axios-instance";

//INSIGHTS


const getAllInsights = async (siteId) => {
    return await axiosInstance.get("/app/commute/insights", {params: {siteId}}).then(res => res);
};


/**
 * @desc Get a list of commute types from database
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCommuteTypes = async function () {
    return await axiosInstance.get(`/api/commute/types`).then(res => res);
};


/**
 * @desc Submit a survey response
 * 
 * @param {*} response data to upload
 * @param {*} surveyHash survey the response is for
 * @returns {Promise<AxiosResponse<any>>}
 */
const postStaffCommuteSurveyResponse = async function (response, surveyHash) {
    return await axiosInstance.post('/api/commute/response/'+ surveyHash, response).then(res => res)
}


/**
 * @desc Get a list of all Commute Surveys
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommuteSurveys = async function () {
    return await axiosInstance('api/commute').then(res => res)
}

/**
 * @desc Get staff commuting survey distance by type chart
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommuteSurveyDistanceByTypeChart = async function (surveyHash) {
    return await axiosInstance(`api/commute/${surveyHash}/distance`).then(res => res)
}

/**
 * @desc Get staff commuting survey frequency by type chart
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommuteSurveyFrequencyByTypeChart = async function (surveyHash) {
    return await axiosInstance(`api/commute/${surveyHash}/frequency`).then(res => res)
}


/**
 * @desc Get staff commuting survey distance line chart
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommuteDistanceLineChart = async function () {
    return await axiosInstance('api/commute/chart/distance').then(res => res)
}


/**
 * @desc Get staff commuting survey frequency line chart
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommuteFrequencyLineChart = async function(granularity, siteId) {
    return await axiosInstance(`api/commute/chart/frequency/${granularity}`, {params: {siteId}}).then(res => res);
}


/**
 * @desc Get latest commute survey
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getLastestCommuteSurvey = async function () {
    return await axiosInstance('api/commute/latest').then(res => res)
}


/**
 * @desc Get response rate number for a given survey hash
 * 
 * @param {*} surveyHash survey the response is for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getResponseRate = async function (surveyHash) {
    return await axiosInstance('api/commute/response/' + surveyHash). then(res => res)
}


/**
 * @desc Get number of responses for a given survey hash
 * 
 * @param {*} surveyHash survey the response is for
 * @returns {Promise<AxiosResponse<any>>}
 */
const hasResponded = async function (surveyHash) {
	return await axiosInstance.patch('api/commute/' + surveyHash ).then(res => res)
}


/**
 * @desc Edit the survery frequency
 * 
 * @param {Object} payload contains the survey hash and new frequency
 * @returns {Promise<AxiosResponse<any>>}
 */
const editSurveyFrequency = async function (payload){
	return await axiosInstance.patch('api/survey-scheduler/', payload).then(res => res)
}


/**
 * @desc Retrieves staff commuting CSV export
 * 
 * @param {String} filters query string to filter CSV data with
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStaffCommutingCSVExport = async function(filters) {
    return axiosInstance({
        url: `/app/commute/csv${filters}`,
        method: 'GET',
        responseType: 'blob'
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data],  { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `staff_commute_data.csv`); 
        document.body.appendChild(link);
        link.click();
    })
}

export default {
    getAllInsights,
    getCommuteTypes,
    postStaffCommuteSurveyResponse,
    getStaffCommuteSurveys,
	getLastestCommuteSurvey,
    getResponseRate,
	getStaffCommuteSurveyDistanceByTypeChart,
    getStaffCommuteSurveyFrequencyByTypeChart,
	hasResponded,
	editSurveyFrequency,
    getStaffCommuteDistanceLineChart,
    getStaffCommuteFrequencyLineChart,
	getStaffCommutingCSVExport,
}