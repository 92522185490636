<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <q-inner-loading :showing="loading" label="Generating Scope Snapshot Report" label-class="text-purple"
			label-style="font-size: 1.1em" />
        <div v-if="!loading">

            <q-btn
                flat
                round
                size="lg"
                color="primary"
                icon="arrow_back"
                @click="this.$router.back()"
                />

            <div class="board-report-buttons">
				<q-btn :loading="generatingPDF" no-caps color="primary" @click="exportToPDF">Export to	PDF</q-btn>
			</div>

            <div class="board-report-container" id="element-to-convert">
                <div class="board-report-header"> 
                    <img src="@/assets/greenhalologo.png" style="height: 2.2em; width: 10em;"/>
                </div>
                
                <div>
                    <p class="title">Scope Snapshot Report: {{ dateTitle }}</p>
                </div>

                <div class="board-report-summary">
                    <span class="site-title" >Summary by Scope</span>        
                    <div class="board-report-summary-items">
                        <div class='summary-item'> 
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="summary-title">Total Emissions</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div class="summary-amount">{{ parseInt(totalEmissions).toLocaleString() }}</div>
                                    <div class="summary-unit">tonnes</div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <div class='summary-item'>
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="summary-title">Scope 1 Total</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div v-if="scope1Emissions == 0|| totalEmissions == 0" class="summary-amount">0%</div>
                                    <div v-else class="summary-amount">{{ (scope1Emissions / totalEmissions * 100).toFixed(2) + '%' }}</div>
                                    <div class="summary-unit">{{ scope1Emissions.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} tonnes</div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <div class='summary-item'>
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="summary-title">Scope 2 Total</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div v-if="scope2Emissions == 0|| totalEmissions == 0" class="summary-amount">0%</div>
                                    <div v-else class="summary-amount">{{ (scope2Emissions / totalEmissions * 100).toFixed(2) + '%' }}</div>
                                    <div class="summary-unit">{{ scope2Emissions.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} tonnes</div>
                                </q-card-section>
                            </q-card>
                        </div>
                        <div class='summary-item'>
                            <q-card flat class="summary-card">
                                <q-card-section class='q-pb-none'>
                                    <div class="summary-title">Scope 3 Total</div>
                                </q-card-section>
                                <q-card-section class="q-pb-none">
                                    <div v-if="scope3Emissions == 0|| totalEmissions == 0" class="summary-amount">0%</div>
                                    <div v-else class="summary-amount">{{ (scope3Emissions / totalEmissions * 100).toFixed(2) + '%' }}</div>
                                    <div class="summary-unit">{{ scope3Emissions.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} tonnes</div>
                                </q-card-section>
                            </q-card>
                        </div>
                    </div>
                </div>
                
                <span class="site-title" style="margin-bottom: -40px;" >Scope Breakdown</span>
                <div class="summary-top-container">
                    <div class="summary-group">
                        <div class="summary-card" style="padding: 0.5em;" >
                            <span class="subtitle">
                            Scope 1 & 2
                            </span>
                            <div class="summary-top">
                                <ReportBarGraph style="height: 565px;" :graphData="chart1Data"></ReportBarGraph>
                            </div>
                        </div>
                        
                    </div>

                    <div class="summary-group">
                        <div class="summary-card" style="padding: 0.5em;">
                            <span class="subtitle">
                                Scope 3
                            </span>
                            <div class="summary-top">
                                <ReportBarGraph style="height: 565px;" :graphData="chart2Data"></ReportBarGraph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ReportBarGraph from './GraphComponents/ReportBarGraph.vue';

import api from "@/services/api/api";
import { merge } from 'lodash/object';
import { sidebarWidth } from "../SidebarComponents/state";
import exportToPDF from '@/services/util/exportToPDF';
import notify from '@/services/util/notify';


const GraphicalScopeReportComponent = {
    name: "ScopeReport",
    props: ["startDate", "endDate", "includeConnected", "tag"],
    components: {
        ReportBarGraph,
    },
    data() {
        return {
            loading: true,
            generatingPDF: false,
            sidebarWidth: sidebarWidth,

            chart1Data: {},
            chart2Data: {},

            scope1Emissions: 0,
            scope2Emissions: 0,
            scope3Emissions: 0,
        }
    },
    created() {
        this.init();
    },

    computed: {

        /**
         * Date title for report
         */
        dateTitle() {
            const startDate = new Date(this.startDate);
            const endDate = new Date(this.endDate);

            const startDay = startDate.getDate();
            const startMonth = startDate.toLocaleDateString('en-US', { month: 'short' });
            const startYear = startDate.getFullYear()

            const endDay = endDate.getDate();
            const endMonth = endDate.toLocaleDateString('en-US', { month: 'short' });
            const endYear = endDate.getFullYear();

            return `${startDay} ${startMonth} ${startYear} -  ${endDay} ${endMonth} ${endYear}`
        },


        /**
         * Calculation of all the emission from each scope
         */
        totalEmissions() {
            return this.scope1Emissions + this.scope2Emissions + this.scope3Emissions
        },
    },
    methods: {

        /**
         * @desc Initilases the scope report data
         */
        async init() {
            this.loading = true;

            if (this.includeConnected) {

                // Get All source ids for connected organisation
                const sourceIds = (await api.tenantConnections.getAllConnectedOrganisations()).map(org => org.sourceTenantId);
                const requests = []

                // Get scoped emissions for each sourceId, this includes this tenancy
                for (const id of sourceIds) {
                    requests.push(api.data.getScopedEmissions(this.startDate, this.endDate, id, this.tag).then(res => res.data));
                }

                await Promise.all(requests)
                .then(responses => {
                    responses.forEach(data => { 
                        // Converting kg to tonnes
                        this.scope1Emissions += Object.values(data['1']).reduce((a, b) => a + b, 0) / 1000;
                        this.scope2Emissions += Object.values(data['2']).reduce((a, b) => a + b, 0) / 1000;
                        this.scope3Emissions += Object.values(data['3']).reduce((a, b) => a + b, 0) / 1000;

                        this.chart1Data = merge(this.chart1Data, data['1'], data['2']);
                        this.chart2Data = merge(this.chart2Data, data['3']);
                    })
                }).catch((error) => {
                    notify.withObject(error.response);
                })
            } else {
                await api.data.getScopedEmissions(this.startDate, this.endDate, undefined, this.tag)
                .then((res) => {
                    // Converting kg to tonnes
                    this.scope1Emissions = Object.values(res.data['1']).reduce((a, b) => a + b, 0) / 1000;
                    this.scope2Emissions = Object.values(res.data['2']).reduce((a, b) => a + b, 0) / 1000;
                    this.scope3Emissions = Object.values(res.data['3']).reduce((a, b) => a + b, 0) / 1000;

                    this.chart1Data = merge(res.data['1'], res.data['2']);
                    this.chart2Data = res.data['3'];
                }).catch((error) => {
                    notify.withObject(error.response);
                })
            }

            this.loading = false;
        },

        /**
        * @desc Exports html page to a pdf using html2pdf
        */
        async exportToPDF() {
            this.generatingPDF = true;
            await exportToPDF("element-to-convert", "scope_snapshot");
            this.generatingPDF = false;
        },
    },
};

export default GraphicalScopeReportComponent;
</script>
<style lang="less" scoped src="@/assets/styles/report.less"/>