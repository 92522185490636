<template>
  <div class="manage-emission-factors-container">
    <div>
      <div class="manage-emission-factors-header">
        <h2 class="tab-section-heading">Manage Emission Factors</h2>
        <div class="manage-emission-factors-sub-header">
          <p>You can manage your emission factors below. Click the <strong>></strong> at the beginning of each row to view and add new conversion rates.</p>
          <q-btn fab-mini icon="add" color="secondary" @click="openAddDataTypeModal = true" no-caps />
        </div>
      </div>
      <ManageEmissionFactorComponent
        ref="manageTable"
        @updateFactor="submitFactorChanges"
        @deleteFactor="deleteFactor"
        @addFactor="addFactor"
        @deleteType="deleteDataType"
        @updateType="updateDataType"
        
        :ghgCategoryOptions="ghgCategoryOptions" 
        :emissionSourceOptions="emissionSourceOptions"
        :unitOptions="unitOptions"
        :loadingSubmit="loadingSubmit"
      />
    </div>

    <div>
      <div>
        <h2 class="tab-section-heading">History</h2>
        <p>You can view the logs below</p>
      </div>
      <EmissionFactorHistoryComponent ref="historyTable" />
    </div>

    <SideModalComponent v-model="openAddDataTypeModal">
      <AddDataTypeComponent @addDataType="addDataType" :ghgCategoryOptions="ghgCategoryOptions" :emissionSourceOptions="emissionSourceOptions" :unitOptions="unitOptions" :loading="loading"/>
    </SideModalComponent>
  </div>
</template>

<script>
import api from "@/services/api/api.js";
import notify from '@/services/util/notify';

import ManageEmissionFactorComponent from "../EmissionFactorComponents/ManageEmissionFactorComponent.vue";
import EmissionFactorHistoryComponent from "../EmissionFactorComponents/EmissionFactorHistoryComponent.vue";
import AddDataTypeComponent from "../EmissionFactorComponents/AddDataTypeComponent.vue";
import SideModalComponent from "../SideModalComponent.vue";


export default {
  components: {
    ManageEmissionFactorComponent,
    EmissionFactorHistoryComponent,
    AddDataTypeComponent,
    SideModalComponent,
  },

  data() {
    return {
      openAddDataTypeModal: false,

      emissionSourceOptions: [],
      unitOptions: [],
      ghgCategoryOptions: [],

      loading: false,
      loadingSubmit: false,
    }
  },


  created() {
    this.loadEmissionSources();
    this.loadUnits()
    this.loadGHGSubcategories();
  },

  methods: {


    /**
     * @desc Submits changes for a conversion factor specified by conversionRateId for a data type identified by its UUID.
     * 
     * @param {string} uuid - The UUID of the data type to update the conversion factor for
     * @param {string} conversionRateId - The ID of the conversion rate to be updated
     * @param {number} conversionRate - The updated conversion rate value
     * @param {string} message - Additional message or reason for updating the conversion factor
     */
    async submitFactorChanges(uuid, conversionRateId, conversionRate, message) {
      this.loadingSubmit = true;
      try {
        await api.dataTypes.updateConversionRate(conversionRateId, {conversionRate, message});
        this.$refs.manageTable.initConversionRates(uuid);
        this.$refs.historyTable.init();
        notify.primary(`Successfully added update a conversion factor for ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSubmit = false
      }
    },


    /**
     * @desc Deletes a conversion factor with the specified conversionRateId for a data type identified by its UUID.
     * 
     * @param {string} uuid - The UUID of the data type to delete the conversion factor from
     * @param {string} conversionRateId - The ID of the conversion rate to be deleted
     * @param {string} message - Additional message or reason for deleting the conversion factor
     */
    async deleteFactor(uuid, conversionRateId, message) {
      this.loadingSubmit = true;
      try {
        await api.dataTypes.deleteConversionRate(uuid, conversionRateId, message);
        this.$refs.manageTable.initConversionRates(uuid);
        this.$refs.historyTable.init();
        notify.primary(`Successfully added deleted a conversion factor for ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSubmit = false
      }
    },


    /**
     * @desc Adds a new conversion factor for a data type specified by its UUID.
     * 
     * @param {string} uuid - The UUID of the data type to add the conversion factor to
     * @param {Object} payload - The data payload containing the new conversion factor information
     */
    async addFactor(uuid, payload) {
      this.loadingSubmit = true;
      try {
        await api.dataTypes.addConversionRate(uuid, payload);
        this.$refs.manageTable.$refs[`addFactorExpansion${uuid}`].hide();
        this.$refs.manageTable.resetNewFactorInputs(uuid);
        this.$refs.manageTable.initConversionRates(uuid);
        this.$refs.historyTable.init();
        notify.primary(`Successfully added a new conversion factor for ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSubmit = false
      }
    },


    /**
     * @desc Adds a new data type with the provided payload.
     * 
     * @param {Object} payload - The data payload containing the information for the new data type
     */
    async addDataType(payload) {
      this.loading = true;
      try {
        const result = await api.dataTypes.addDataType(payload);
        this.$refs.manageTable.initDataTypes();
        this.$refs.historyTable.init();
        this.openAddDataTypeModal = false;
        notify.primary(`Successfully added a new emission factor: ${result.data}`)
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loading = false
      }
    },


    /**
     * @desc Deletes a data type with the specified UUID.
     * 
     * @param {string} uuid - The UUID of the data type to be deleted
     */
    async deleteDataType(uuid) {
      try {
        await api.dataTypes.deleteDataType(uuid);
        this.$refs.manageTable.initDataTypes();
        this.$refs.historyTable.init();
        this.$refs.manageTable.$refs[`deleteTypePopup${uuid}`][0].hide();
        notify.primary(`Successfully deleted emission factor: ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      }
    },


    /**
     * @desc Updates a data type with the specified UUID using the provided payload.
     * 
     * @param {String} uuid - The UUID of the data type to be updated
     * @param {Object} payload - The data payload containing the updated information for the data type
     */
    async updateDataType(uuid, payload) {
      this.loadingSubmit = true;
      try {
        if (payload['subcategory']) {
          payload['ghgSubcategoryId'] = payload['subcategory'].id
          delete payload['subcategory']
      }

        await api.dataTypes.updateDataType(uuid, payload);
        this.$refs.manageTable.initDataTypes();
        this.$refs.historyTable.init();
        this.$refs.manageTable.$refs[`editTypePopup${uuid}`][0].hide();
        notify.primary(`Successfully updated emission factor ${uuid}`);
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.loadingSubmit = false
      }
    },


    async loadUnits() {
      try {
        const units = (await api.units.getUnits()).data;
        units.forEach(unit => this.unitOptions.push({label: unit.symbol, symbol: unit.symbol, id: unit.id}));
      } catch (err) {
        notify.withObject(err.response);
      }
    },

    /**
     * @desc Load emission sources from the server and populate the emissionSourceOptions array with the sourcedisplayname and emissionSourceId.
     */
    async loadEmissionSources() {
      try {
        const emissionSources = (await api.sites.getSiteEmissionSources()).data.emissionSources;

        for (const emission of emissionSources) {
          this.emissionSourceOptions.push({
            label: emission.sourcedisplayname,
            value: emission.emissionSourceId,
          });
        }
      } catch (err) {
        notify.withObject(err.response);
      }
    },


    /**
    * @desc Retrieves all GHG subcategory options from the server.
    */
    async loadGHGSubcategories(){
      this.loadingGHG = true;
      api.dataTypes.getGHGSubcategories()
        .then((res) => this.ghgCategoryOptions = res.data)
        .catch((err) => notify.withObject(err.data))
        .finally(() => this.loadingGHG = false);
    },
  }
}
</script>

<style lang="less" src="@/assets/styles/emissionFactorSettings.less" />
