<template>
	<div class="commute-survey-container">
		<div class="commute-survey-heading">
			<h5>Staff Commute Surveys</h5>
			
		</div>
		<div class="content-item center-column eiep-list-item">

			<!-- TODO: Individual Survey Page, new design and scope -->
			<q-table :loading="loading" :rows="surveyList" :columns="columns" row-key="name">
				<template v-slot:top-right>
					<q-icon 
					class="commute-settings-icon" 
					name="settings" 
					size="2rem"
					@click="showSettingsModal=!showSettingsModal"/>

				</template>
				<template v-slot:body-cell-surveyHash="props">
					<q-td class="commute-table-surveyHash" :props="props">
						{{props.value}}
					</q-td>
				</template>

				<template v-slot:body-cell-responseRate="props">
					<q-td :props="props">
						<div class="meter-connection-responseRate">
							<q-linear-progress class="responseRateBar" stripe rounded size="20px" :value="parseInt(props.value)"
								color="secondary">
								<div class="absolute-full flex flex-center">
									<q-badge color="secondary" text-color="white" :label="props.value" />
								</div>
							</q-linear-progress>
						</div>
					</q-td>
				</template>

			</q-table>
		</div>
	</div>

	<q-dialog full-height full-width position="right" v-model="showSettingsModal">
	</q-dialog>
</template>
	
<script>

import api from "@/services/api/api";
import notify from "@/services/util/notify";

const ViewAllSurveysComponent = {
	name: "ViewAllSurveysComponent",
	data() {
		return {

			showSettings: false,
			loading: false,
			settingsFrequency: null,
			showSettingsModal: false,
			surveyList: [],
			columns: [
				{ name: 'surveyHash', align: 'left', label: 'Survey Identifier', field: 'surveyHash', sortable: true },
				{ name: 'responseRate', align: 'left', label: 'Responses', field: 'responseRate', sortable: true },
			],

		};
	},

	created() {
		this.getAllCommuteSurveys()

	},

	methods: {

		/**
		 * @desc Retrieve all commuting surveys from the server
		 */
		async getAllCommuteSurveys() {
			this.loading = true;

			api.commute
				.getStaffCommuteSurveys()
				.then(async (res) => {
					this.surveyList = res.data
					this.surveyList.map(async (survey) => {

						api.commute.getResponseRate(survey.surveyHash).then((res) => {
							survey.totalUsers = res.data[0].totalusers
							survey.responses = res.data[0].responses

							survey.responseRate = (survey.responses / survey.totalUsers * 100).toFixed(0) + "%"
						})
					});
				})
				.catch(() => {
					notify.error("Failed to establish connection to database", "top")
				}).finally(() => {
					this.loading = false;
				})
		},


		/**
		 * @desc When a row is clicked, navigate to that survey page.
		 * 
		 * @param {Object} row object containing survey hash
		 */
		datasetRowClicked(evt, row) {
			this.$router.push({
				name: "singleStaffCommuteSurvey",
				params: { surveyHash: row.surveyHash },
			});
		},
	}
};

export default ViewAllSurveysComponent;
</script>

<style>
@import "../../assets/styles/commute.css";
</style>
	
