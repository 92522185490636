<template>
	<q-carousel
			v-model="slide"
			animated
			navigation-position="bottom"
			navigation
			padding
			arrows
			height="850px"
			control-color="purple"
			class="bg-white text-white rounded-borders"
			>
			<q-carousel-slide name="intro" class="column no-wrap ">
				<div class="q-mt-md">
					<span class="text-h6">In bulk upload, we have four required columns:</span>
					<ul>
						<li>Amount
							<ul>
								<li>
									Determines how many of the emission factor's <b>units</b>
									have been used e.g. 5 <b>Kg</b>
								</li>
							</ul>
						</li>
						<li>Timestamp
							<ul>
								<li>
									Determines at which date and time the emission source usage
									took place.
								</li>
							</ul>
						</li>
						<li>Emission Factor
							<ul>
								<li>
									Determines what emission factor and conversion rate to use for
									a given row of data..
								</li>
							</ul>
						</li>
						<li>Site
							<ul>
								<li>
									The name of the site that the emission source usage should be recorded
									under. 
								</li>
							</ul>
						</li>
					</ul>


					<span class="text-h6">We also support a number of optional columns:</span>
					<ul>
						<li>Supplier
							<ul>
								<li>
									Determines which supplier to record a row of data under.
								</li>
							</ul>
						</li>
						<li>Tags
							<ul>
								<li>
									Allows for tagging individual entries of data. Any new tags we
									encounter will be automatically created in your account.
								</li>
								<li>
									Multiple tags can be assigned to one row of data. This can be
									done by separating the tags by commas.
								</li>
								<li>
									e.g. <i>North Island<b>,</b>Store<b>,</b>Commercial</i>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</q-carousel-slide>

			<q-carousel-slide name="header-map" class="column no-wrap flex-center">
				<div class="help-slide-text">
					We understand that your column headers may not be called <b>Emission Factor</b>,
					<b>Amount</b>, or <b>Site</b>. The bulk upload feature allows you to map your
					column header values to the header values that are required by GreenHalo:
				</div>
				<q-img
						fit="fill"
						width="30rem"
						class="tutorial-img"
						src="@/assets/tutorials/bulkUpload/mapHeaders.png"
						>
				</q-img>
			</q-carousel-slide>

			<q-carousel-slide name="ignore-headers" class="column no-wrap flex-center">
				<div class="help-slide-text">
					We also understand that your spreadsheets may have extra columns that
					GreenHalo won't need. You are able to select which columns you want to map 
					to GreenHalo column values. Any columns
					that you don't want/need to map, simply disable the checkbox next
					to it:
				</div>
				<q-img
						fit="fill"
						width="30rem"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/bulkUpload/ignoreHeaders.png"
						>
				</q-img>
			</q-carousel-slide>

			<q-carousel-slide name="value-map" class="column no-wrap flex-center">
				<div class="help-slide-text">
					Once the column headers have been mapped, you will be taken through
					each column in which we've detected any non-GreenHalo values.
					<br><br>
					For each of these values, you will be asked to determine which GreenHalo
					value it should be mapped to:
				</div>

				<q-img
						fit="fill"
						width="30rem"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/bulkUpload/mapValues.png"
						>
				</q-img>
			</q-carousel-slide>

			<q-carousel-slide name="upload" class="column no-wrap flex-center">
				<div class="help-slide-text">
					Once all values have been mapped, you can press upload to submit
					the file to GreenHalo.
				</div>

				<q-img
						fit="fill"
						width="30rem"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/bulkUpload/uploadFile.png"
						>
				</q-img>
			</q-carousel-slide>

			<q-carousel-slide name="failed-rows" class="column no-wrap flex-center">
				<div class="help-slide-text">
					GreenHalo will upload every row that it can. 
					<br>
					We'll keep track of any rows that we can't upload,
					and will let you know which rows these are (if any). 
					<br><br>
					You will have the option to download a .csv spreadsheet detailing 
					which rows couldn't be uploaded, and why, so that you're able to easily 
					fix any invalid data.
				</div>
				<q-img
						fit="fill"
						width="30rem"
						loading="true"
						class="tutorial-img"
						src="@/assets/tutorials/bulkUpload/uploadFailExample.png"
						>
				</q-img>
			</q-carousel-slide>

	</q-carousel>
</template>

<script>

	export default {

		data() {
			return {
				slide: "intro",
			}
		}
	}

</script>

<style lang="less" src="@/assets/styles/bulkUpload.less" scoped />
