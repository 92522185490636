<template>
    <q-card flat class="toitu-audit-parent-container">
        <div class="row justify-end">
                <q-btn class="close-btn" flat round dense icon="close" v-close-popup color="red"/>
        </div>
        <div class="inner">
            <ToituBusinessUnitMappings v-if="pageNum == 0" :businessUnitMappings="clonedMappings.businessUnits"
                @nextPage="handleBusinessUnitSubmission" :buttonLoading="loadingInUseEmissionFactors" buttonLabel="Next"/>

            <KeepAlive>
                <ToituEmissionFactorMappings v-if="pageNum == 1" 
                    :emissionFactorMappings="clonedMappings.emissionFactors" 
                    :selectedEmissionSources="selectedEmissionSources"
                    :unitMappings="clonedMappings.units"
                    :buttonLoading="savingToituMappings" 
                    @nextPage="handleEmissionFactorSubmission" @previousPage="prevPage" buttonLabel="Generate" />
            </KeepAlive>  
        </div>
    </q-card>
</template>

<script>
import ToituBusinessUnitMappings from './ToituBusinessUnitMappings.vue';
import ToituEmissionFactorMappings from './ToituEmissionFactorMappings.vue';

import notify from '@/services/util/notify';
import api from '@/services/api/api';

export default {
    components: {
        ToituBusinessUnitMappings,
        ToituEmissionFactorMappings
    },

    props: {
        mappingId: {
            type: [Number, String],
            required: true
        },

        toituMappings: {
            type: Object,
            required: true,
        },

        dateRange: {
            type: Object,
            required: false,
        },

        selectedEmissionSources: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            clonedMappings: structuredClone(this.toituMappings),
            loadingInUseEmissionFactors: false,
            pageNum: 0,
            savingToituMappings: false,
        }
    },


    methods: {
        // Go to next page.
        nextPage () {
            this.pageNum++;
        },

        // Go to the previous page.
        prevPage () {
            this.clonedMappings.emissionFactors = structuredClone(this.toituMappings.emissionFactors);
            if (this.pageNum - 1 < 0) {
                this.pageNum = 0
            } else {
                this.pageNum--;
            }
        },


        /**
         * Handles the submission of mapped business unit data. Updates the `clonedMappings.businessUnits` with the provided mapped data,
         * retrieves the emission factors currently in use by calling `getEmissionFactorsInUse`, and then navigates to the next page.
         *
         * @param {Object[]} mappedData - The mapped data for business units.
         */
        async handleBusinessUnitSubmission(mappedData) {
            this.clonedMappings.businessUnits = mappedData;
            this.clonedMappings = await this.getEmissionFactorsInUse();
            this.nextPage();
        },


        /**
         * Handles the submission of mapped emission factor data. 
         *
         * @param {Object[]} mappedData - The mapped data for emission factors.
         */
        handleEmissionFactorSubmission(mappedData) {
            //We can't manipulate cloned mappings as it will re render the child component. So make new object.
            let completedMappings = {
                ...this.clonedMappings,
                emissionFactors: mappedData
            };
            this.saveToituMappings(completedMappings);
        },


        /**
         * Asynchronously retrieves the emission factors in use. 
         *
         * @returns {Promise<Object>} A Promise that resolves to the emission factors data in use.
         */
        async getEmissionFactorsInUse() {
            this.loadingInUseEmissionFactors = true;

            try {
                const response = await api.reports.getEmissionFactorsInUseToitu(this.clonedMappings, this.dateRange)
                return response.data;
            } catch (error) {
                notify.withObject(error);
            } finally {
                this.loadingInUseEmissionFactors = false;
            }
        },


        
        /**
         * Saves Toitu mappings for a report.
         * 
         * @param {Object} completedMappings - The Toitu mappings to save.
         */
        async saveToituMappings(completedMappings) {
            this.savingToituMappings = true;
            try {
                await api.reports.updateToituMapping(this.mappingId, completedMappings);
                notify.primary("Successfuly Saved Mappings", "top", "Toitū emanage");

                this.$emit('generateReport');
            } catch (error) {
                notify.error(error.response?.data ?? `Something went wrong saving your Toitu emanage mappings.`)
            } finally {
                this.savingToituMappings = false;
            }
        },
    },
}

</script>

<style lang="less" scoped src="@/assets/styles/auditToitu.less"/>

