/**
 * stationaryFuels.js
 * 
 * API for handling getting all stationary fuels (Coal, gas etc) associated data
 */

import axiosInstance from "./axios-instance";

/**
 * @desc Get stationary fuel data in line chart format.
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getStationaryFuelsChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/chart/${granularity}`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Get natural gas fuel data in line chart format
 * 
 * @param {String} granularity granularity of graph
 * @param {Number} siteId site to retrieve graph for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getNaturalGasChart = async function(granularity, siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/chart/natural-gas/${granularity}`, { params: { siteId }}).then(res => res);
};


/**
 * @desc Get the total weight of stationary fuel used for the past year
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearlyWeightInsight  = async function(siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/insights/weight`, { params: { siteId }}).then(res => res);
}


/**
 * @desc Get the total cost spent on stationary fuels in the past year
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearlyCostInsight = async function(siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/insights/cost`, { params: { siteId }}).then(res => res);
}


/**
 * @desc Get the month where most Kg's of stationary fuel was bought and the amount
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getMostFrequentMonthInsight = async function(siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/insights/frequent-month`, { params: { siteId }}).then(res => res);
}


/**
 * @desc Get the total emissions caused by stationary fuel in the past year
 * 
 * @param {Number} siteId specifies the site id to filter for
 * @returns {Promise<AxiosResponse<any>>}
 */
const getYearlyEmissionsInsight = async function(siteId) {
    return await axiosInstance.get(`/app/stationary-fuels/insights/emissions`, { params: { siteId }}).then(res => res);
}



export default {
    getStationaryFuelsChart,
    getNaturalGasChart,
	getYearlyWeightInsight,
	getYearlyCostInsight,
	getMostFrequentMonthInsight,
	getYearlyEmissionsInsight,
}