<template>
	<div>
		<div class="manual-upload-container">
			<h2 class="tab-section-heading">Manual Upload 
			</h2>
			<div>
				If you're uploading multiple sites and/or emission sources, please try out our new <a class="click-link" @click="this.$router.push({name: 'dataHub'})"> Bulk Upload </a> feature
			</div>
			<br/>
			<p>You can upload your {{ displayEmissionSource }} data to GreenHalo manually with a CSV import. Please ensure that the 
				<span class="text-primary">header rows of your CSV file</span> contains the required attributes <span class="text-primary" >(case sensitive)</span>: </p>

				<p v-if="displayEmissionSource == 'Mileage Claims'">
					Please ensure you only upload <span class="text-primary">Approved</span> mileage requests.
				</p>
			<span style="color: red; font-size: large;">*</span><i>Required</i>
			<div class="data-component-container">
				<ReusableManualUpload
					:emission="emissionSource"
					:headers="headerRow"
					:rows="[exampleRow]"
					:loading="loading"
					:siteId="siteId"
					@uploadCSV="uploadCSV($event)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import api from "../../services/api/api";
import notify from "@/services/util/notify";
import manualUploadHelper from "./manualUploadHelper";
import ReusableManualUpload from "./ReusableManualUploadComponent.vue";

const ManualUploadComponent = {
	name: "ManualUpload",
	components: {
		ReusableManualUpload,
	},

	props: ["emissionSource", "exampleRow"],

	data() {
		return {
			file: null,
			loading: false,
		};
	},

	created() {
		if (this.flags.FLAG_4108_DATA_TAGS) {
				this.exampleRow["tags"] = "A custom identifier unique to your business. Format: String containing letters, numbers, and underscores"
        }  
	}, 


	computed: {
		siteId() {
			return useSiteStateStore().siteId;
		},

		headerRow() {
			return Object.keys(this.exampleRow).map(col => {
				return {label: col, field: col}
			});
		},

        displayEmissionSource() {
            return this.emissionSource.split(/-/).map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(" ");
        },
	},

	methods: {
		/**
		 * @desc Submits the CSV file to the server. This method will also set loading variables
		 * to their correct values depending on the response of the server.
		 *
		 * Checks that a file has actually been uploaded before attempting to send
		 * the file to the server storage.
		 */
		async uploadCSV(event) {
			if (!manualUploadHelper.validateFileType(event.file.name)) {
				notify.error("Please only upload CSV files (.csv)", "top", "Upload Unsuccessful");
				return;
			}
			this.loading = true;
			try {
				await api.emissions.uploadEmissionCSV(this.emissionSource, this.siteId, event.file);	
				notify.primary("Successfully uploaded CSV file", "top", `Uploaded ${this.displayEmissionSource} Data`);
			} catch (err) {

				err?.response?.status >= 400 && err?.response?.status <= 499 ? 
				notify.warning(err?.response?.data ?? "Unable to upload document. Please try again", "top", "Upload Unsuccessful") : 
				notify.error(err?.response?.data ?? "Unable to upload document. Please try again", "top", "Upload Unsuccessful");
				
			} finally {
				this.loading = false;
			}
		},
	},
};

export default ManualUploadComponent;
</script>

<style scoped lang="less" src="../../assets/styles/manualUpload.less" />
