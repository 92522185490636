<template>
	<div class="data-feed-container">
		<div class="data-feed-header">
			<h2 class="tab-section-heading">Manage Data Feed Connections</h2>
			<q-btn class="add-connection-trigger" fab-mini icon="add" color="secondary" @click="openAddConnection = true"
				no-caps />
		</div>
		<SideModalComponent v-model="openAddConnection">
			<AddSupplierConnectionParentComponent ref="addConnection" @createConnection="createConnection" />
		</SideModalComponent>

		<DataFeedRemappingComponent v-if="openRemappingDialog" :connectionId="alertedFeedConnectionId" v-model="openRemappingDialog" @updated="retreiveConnections()"/>

		<q-card>
			<q-card-section class="row">
				<ConnectionFilterComponent @updateDisplayedConnections="updateDisplayedConnections"
					:availableSuppliers="availableSuppliers" :availableEmissionSources="availableEmissions"
					:availableEmissionFactors="availableEmissionFactors" :availableSites="availableSites" />

                <q-table class="connections-table" :rows="displayedConnections" :columns="connectionsTableColumns"
                    :loading="loadingConnections" :rows-per-page-options="[10]" row-key="id"
                    binary-state-sort
                    no-data-label="No connections available. Please create your first connection to see them here."
                    >

					<template v-slot:body-cell="props">
						<q-td :props="props">
							{{ props.value }}
							<q-tooltip 
								v-if="['siteNames', 'emissionFactors'].includes(props.col.name) &&
									props.value.length > 70" 
								class="text-body2">
								{{ props.value }}
							</q-tooltip>
						</q-td>
					</template>


					<template v-slot:body-cell-actions="props">
						<q-td :props="props">
							<q-btn v-if="props.row.alert" @click="clickAlert(props.row.dataFeedConnectionId)" color="orange"
								flat icon="o_notification_important" />

							<q-btn v-if="permissions.deleteDataFeedConnection.includes(userRole)" icon="delete" flat
								color="deep-orange">
								<q-popup-proxy>
									<q-card>
										<q-card-section>
											<span class="text-bold row">Are you sure you</span>
											<span class="text-bold row">want to delete this connection?</span>
											<span class="row q-pt-sm">This action can not be undone.</span>
										</q-card-section>
										<q-card-actions align="center">
											<q-btn color="grey-6" v-close-popup>Cancel</q-btn>
											<q-btn :loading="loadingDelete" color="red"
												@click="deleteConnection(props.row.dataFeedConnectionId)">Confirm</q-btn>
										</q-card-actions>
									</q-card>
								</q-popup-proxy>
							</q-btn>
						</q-td>
					</template>
				</q-table>
			</q-card-section>
		</q-card>
	</div>
</template>

<script>

import api from "@/services/api/api.js";
import notify from "@/services/util/notify";
import getRole from "@/services/util/role";
import permissions from "@/rolePermissions";
import AddSupplierConnectionParentComponent from "./AddSupplierConnectionParentComponent.vue";
import SideModalComponent from "@/components/SideModalComponent.vue";
import ConnectionFilterComponent from "./ConnectionFilterComponent.vue";
import DataFeedRemappingComponent from './DataFeedRemappingComponent.vue';

import { useSiteStateStore } from '@/stores/siteState.store';

export default {
	components: {
		SideModalComponent,
		AddSupplierConnectionParentComponent,
		ConnectionFilterComponent,
		DataFeedRemappingComponent
	},
	data() {
		return {
			loadingDelete: false,
			openAddConnection: false,
			openRemappingDialog: false,
			userRole: getRole(),
			permissions: permissions,
			loadingConnections: false,
			alertedFeedConnectionId: undefined,
			connections: [],
			displayedConnections: [],
			connectionsTableColumns: [
				{ name: 'supplier', label: 'Supplier', align: 'left', sortable: true, field: 'supplierName' },
				{ name: 'siteNames', label: 'Sites', align: 'left', field: (row) => row.siteNames.join(', ') },
				{ name: 'emissionSources', label: 'Emission Source', align: 'left', field: (row) => row.emissionSources.join(', ') },
				{ name: 'emissionFactors', label: 'Emission Factors', align: 'left', field: (row) => row.emissionFactors.join(', '), style: "max-width: 400px; overflow: hidden; text-overflow: ellipsis " },
				{ name: 'schedule', label: 'Schedule', align: 'left', sortable: true, field: 'schedule' },
				{ name: "actions", style: "width: 5%", label: "Actions", align: "right", field: "" },
			],

			availableSuppliers: [],
			availableEmissions: [],
			availableEmissionFactors: [],
			availableSites: [],

			uniqueSuppliers: new Set(),
			uniqueEmissions: new Set(),
			uniqueEmissionFactors: new Set(),

			currentFilters: null,
			loadingUpdate: false,

            siteStore: null,
		}
	},


	created() {
		this.retreiveConnections();

        this.siteStore = useSiteStateStore();
	},


    computed: {
        sites() {
            return this.siteStore.siteList;
        }
    },

	methods: {

		async createConnection(supplierUUID, payload) {
			this.$refs.addConnection.submitLoading = true;

			try {
				await api.dataFeeds.createConnection(supplierUUID, payload);
				notify.primary("Successfully added a new connection.");
				this.openAddConnection = false;
				await this.retreiveConnections();
			} catch (err) {
				notify.withObject(err.response);
			} finally {
				if (this.$refs.addConnection?.submitLoading) {
					this.$refs.addConnection.submitLoading = false;
				}
			}
		},

		/**
		 * @desc Triggers when an alert button is clicked and will open the mapping dialog
		 * @param {Number} connectionId - The id of the connection that the alert is for 
		 */
		clickAlert(connectionId) {
			this.alertedFeedConnectionId = connectionId;
			this.openRemappingDialog = true;
		},


		/**
		 * @desc Retrieve all the data feed connections for the current user.
		 */
		async retreiveConnections() {

			this.loadingConnections = true;

			api.dataFeeds.getDataFeeds().then((res) => {
				this.connections = res.data;

				// Set the filter selects to the list of available items.
				this.connections.forEach(connection => {
					this.uniqueSuppliers.add(connection.supplierName);
					connection.emissionSources.forEach(source => this.uniqueEmissions.add(source));
					connection.emissionFactors.forEach(factor => this.uniqueEmissionFactors.add(factor));
				});

				// Converts sets to arrays
				this.availableSuppliers = [...this.uniqueSuppliers];
				this.availableEmissions = [...this.uniqueEmissions];
				this.availableEmissionFactors = [...this.uniqueEmissionFactors];

                this.availableSites = this.sites.map(item => item.name);

				if (this.currentFilters) {
					this.updateDisplayedConnections()
				} else {
					this.displayedConnections = this.connections
				}

			}).catch((error) => {
				console.error(error)
				notify.error("Unable to retrieve connections", "top")
			}).finally(() => {
				this.loadingConnections = false
			})
		},


		/**
		 * @desc Deletes a data feed connection using the connection id
		 * 
		 * @param {Number} connectionId id of connection to delete
		 */
		async deleteConnection(connectionId) {
			this.loadingDelete = true;
			api.dataFeeds.deleteConnection(connectionId).then(() => {
				notify.primary("Connection successfully deleted", "top");
			}).catch((error) => {
				notify.error(error.response.data ?? 'Unable to delete selected connection', 'top');
			}).finally(() => {
				this.retreiveConnections();
				this.loadingDelete = false;
			});
		},

		/**
		 * @desc Applies the selected filters from EmissionFactorFilterComponent to the list of displayed emission sources.
		 * @param {Object} selectedFilters 
		 * @example
		 * {
		 *     'source': ['MfE', 'Customer'],
		 *     'emissionSources': ['Air Travel'],
		 *     'scopes': [1, 2, 3],
		 *     'categories': [],
		 * }
		 */
		updateDisplayedConnections(selectedFilters) {
			this.currentFilters = selectedFilters ?? this.currentFilters

			this.displayedConnections = this.connections

			for (const [filter, selectedValues] of Object.entries(this.currentFilters)) {
				if (selectedValues.length > 0) {
					switch (filter) {
						case "supplier":
							this.displayedConnections = this.displayedConnections.filter(item => selectedValues.includes(item.supplierName));
							break;
						case "emissionSources":
							this.displayedConnections = this.displayedConnections.filter(item => selectedValues.some(element => item.emissionSources.includes(element)));
							break;
						case "emissionFactors":
							this.displayedConnections = this.displayedConnections.filter(item => selectedValues.some(element => item.emissionFactors.includes(element)));
							break;
						case "sites":
							this.displayedConnections = this.displayedConnections.filter(item => selectedValues.some(element => item.siteNames.includes(element)));
							break;
						default:
							// Handle unknown filters
							console.error("Unknown filter:", filter);
					}
				}
			}
		},
	}

}
</script>

<style lang="less" scoped src="../../../assets/styles/dataFeedConnections.less" />
