<template>
  <div>
    <div>
      <div>
        If you have an Excel file that combines multiple data sources, you can upload it below and the data will
        automatically be assigned to the correct emissions.
		<br><br>
        <div v-if="!flags.FLAG_5086_BULK_MAPPING">
			Before Uploading, please ensure your Excel headers match the following:
		</div>
		<div v-else>
			Below are the required headers ( <span style="color: red;">*</span> = required )
			for bulk upload.
			<br>
			We offer the option to remap your column header and row values
			with our <a class="bulk-upload-guide" @click="displayHelpModal = true"> 
				<q-tooltip style="font-size: 0.9rem;">
					Click here for a guide on the bulk upload mapping process.
				</q-tooltip>
				Bulk Upload mapping feature.
				<q-btn 
					flat 
					round 
					dense 
					icon="help" color="primary" class="q-mr-sm"/>
			</a>
		</div>
      </div>
    </div>
    <div>
      <q-table
        class="manual-upload-table"
        :rows="rows"
        :columns="columns"
        hide-bottom
      >
        <template v-slot:header-cell="props">
          <q-th>
            <span v-if="props.col.isRequired" style="color: red;">*</span>
			<span>{{ props.col.label }}</span>
          </q-th>
        </template>

            <template v-slot:body-cell-timestamp="props">
                <q-td :props="props">
                    <div>
                        <span>Date associated with data. Format: d/mm/yyyy h:mm</span>
                        <q-btn flat round dense icon="help" color="primary" class="q-mr-sm" @click="(showTimestampHelp = true)" />
                    </div>
                </q-td>
            </template>
        <template v-slot:body-cell-emissionFactor="props">
          <q-td :props="props">
            <div>
                <span>The name of the factor for the emission usage</span>
                <q-btn flat round dense icon="help" color="primary" class="q-mr-sm" @click="(showFactors = true)" />
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-supplierName="props">
          <q-td :props="props">
            <div>
                <span>The name of the supplier for the emission usage</span>
            </div>
          </q-td>
        </template>
      </q-table>

      <div class="manual-button-center">
        <q-btn flat no-caps label="Download template" class="download-btn template" color="blue"
            icon-right="download" @click="downloadTemplate()" :loading="downloadingExampleTemplate">
            <q-tooltip>
                Download Excel file with only the required headers. You can use this template and upload your own data. Please be sure to rename the file.
            </q-tooltip>
        </q-btn>
        |
        <q-btn flat no-caps label="Download example" class="download-btn example" color="green"
            icon-right="download" @click="downloadExample()" :loading="downloadingExampleFile">
            <q-tooltip>
                Download Excel file with the required headers AND several rows of example data.
            </q-tooltip>
        </q-btn>
      </div>
    </div>

    <FileUpload
      v-model="fileUploadStore.manualBulkUploadFile" 
      @submit="uploadFile" 
      @rejected="notify.warning('Only .xlsx files are supported', 'top', 'Unsupported File Format')" 
      :loading="uploadingFile" 
      :disable-submit="!fileUploadStore.manualBulkUploadFile"
      accept=".xlsx,.xls" 
      disabled-message="Please upload a file"
    />

    <q-dialog v-model="showTimestampHelp">
      <TimestampHelpComponent/>
    </q-dialog>

    <q-dialog v-model="showFactors">
      <EmissionFactorComponent />
    </q-dialog>

    <q-dialog v-model="showMapping" no-backdrop-dismiss>
      <BulkUploadMapping
        ref="columnMapper" 
        :fileData="fileData" 
        :file="uploadedFile"
        :sheetNames="sheetNames"
        :mappings="mappings" 
        :savedMappings="savedMappings" 
        @submitBulkFile="uploadFileWithMappings" 
        @initFileData="initFileData"
        :initSavedMappings="initSavedMappings" 
      />
    </q-dialog>

	<q-dialog v-model="displayFailedRowsModal">
		<div class="failed-rows-container">
			<div class="failed-rows-header">{{failedRowsText}}</div>
			<div class="failed-rows-subheader">
				Would you like to download an excel file detailing all of your
				failed rows?
			</div>
			<div class="confirmation-buttons">
				<div>
				<q-btn
				no-caps
				color="red"
				label="No thanks"
				@click="this.displayFailedRowsModal = false;"
				padding="0.7rem 1.5rem"
				/>
				</div>
				<div>
				<q-btn 
				no-caps
				color="secondary"
				label="Download"
				@click="downloadFailedRows"
				padding="0.7rem 1.5rem"
				/>
				</div>
			</div>
		</div>
	</q-dialog>
	<q-dialog v-model="displayHelpModal">
		<BulkUploadHelpComponent />
	</q-dialog>
  </div>
</template>

<script>
import EmissionFactorComponent from "@/components/DataComponents/EmissionFactorComponent.vue";
import TimestampHelpComponent from "../../DataComponents/TimestampHelpComponent.vue";
import BulkUploadMapping from "./BulkUploadMappingComponent.vue";
import FileUpload from "@/components/FileUploadComponent.vue";
import BulkUploadHelpComponent from "./BulkUploadHelpComponent.vue";
import notify from "@/services/util/notify";
import api from "@/services/api/api";
import { getNonEmptySheetNames, promptDownload } from "@/services/util/file";

import {useFileUploadStore} from "@/stores/fileUpload.store";

export default {
  components: {
    EmissionFactorComponent,
    FileUpload,
    TimestampHelpComponent,
    BulkUploadMapping, 
	BulkUploadHelpComponent,
  },
  data() {
    return {
		notify: notify,
		displayHelpModal: false,
		displaySideBar: false,
		showFactors: false,
		showTimestampHelp: false,
		showMapping: false,
		downloadingExampleTemplate: false,
		downloadingExampleFile: false,
		fileUploadStore: useFileUploadStore(),
		displayFailedRowsModal: false,
		failedFile: null,
		failedRowsText: "",
		failedFileName: "",

		sheetData: {},

		uploadedFile: null,
		uploadingFile: false,
		fileData: {},
		mappings: {},
		fileEmissionFactorUnitMap: {},
		savedMappings: {},
		columns: [
			{
				name: "amount",
				label: "Amount",
				field: "amount",
				isRequired: true,
			},
			{
				name: "timestamp",
				label: "Timestamp",
				field: "timestamp",
				isRequired: true,
			},
			{
				name: "emissionFactor",
				label: "Emission Factor",
				field: "emissionFactor",
				isRequired: true,
			},
			{
				name: "siteName",
				label: "Site",
				field: "siteName",
				isRequired: true,
			},
			{
				name: "unit",
				label: "Unit",
				field: "unit",
				isRequired: false,
			},
			{
				name: "supplierName",
				label: "Supplier",
				field: "supplierName",
				isRequired: false,
			},
			{
				name: "tags",
				label: "Tags",
				field: "tags",
				isRequired: false,
			},
		],
		rows: [
			{
			amount: "Usage amount (a number)",
			siteName: "The name of the site where usage was recorded",
			tags: "A comma separated string of desired tags. Format: tag1, tag2, ...",
			unit: "The unit for your emission amount"
			}
		],
    }
  },

  computed: {
    sheetNames() {
      return Object.keys(this.sheetData);
    }
  },

  watch: {
    showMapping() {
      if (!this.showMapping) {
        this.resetData();
      }
    }
  },

  methods: {
    resetData() {
      this.fileData = {};
      this.sheetData = {};
      this.mappings = {};
      this.savedMappings = {};
    },

    async downloadExample() {
      this.downloadingExampleFile = true;

      try {
        await api.emissions.getExampleBulkFile("file");
        notify.primary("Successfully downloaded example file!", "top");
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.downloadingExampleFile = false;
      }
    },
    async downloadTemplate() {
      this.downloadingExampleTemplate = true;

      try {
        await api.emissions.getExampleBulkFile("template");
        notify.primary("Successfully downloaded example template!", "top");
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.downloadingExampleTemplate = false;
      }
    },

    async initFileData(sheetName) {
      // This ensures the latest saved mappings are available whenever a new sheet is selected
      await this.initSavedMappings();
    
      this.fileData = this.sheetData[sheetName] ?? await this.getFileData(this.uploadedFile, sheetName);
    },

    async getFileData(file, sheetName) {
      try {
        return (await api.emissions.parseBulkFile(file, sheetName)).data;
      } catch {
        return {};
      }
    },

    async initSavedMappings() {
      this.savedMappings = (await api.emissions.getSavedMappingOptions()).data;
    },

    async initMappings() {
      this.mappings = (await api.emissions.getMappingOptions()).data;
      await this.initSavedMappings();
    },

    async initSheetData() {
      const sheetNames = await getNonEmptySheetNames(this.uploadedFile);
      this.sheetData = (await api.emissions.parseBulkFile(this.uploadedFile, sheetNames)).data;
    },

    /**
     * Uploads the file(s)
     * 
     * @param {File | Array<File>} uploadedFile One File or an array of Files
     */
    async uploadFile(uploadedFile) {
      this.uploadingFile = true;
      try {
        this.uploadedFile = uploadedFile;
        await this.initSheetData();

        if (this.sheetNames.length > 0) {
          await this.initFileData(this.sheetNames[0]);
          await this.initMappings();
          this.showMapping = true;
        } else {
          notify.warning("There are no sheets with valid data in the file.");
        }
      } catch (err) {
        notify.withObject(err.response);
      } finally {
        this.uploadingFile = false;
      }
    },

	/**
	  Uploads the file(s) with mapping objects supplied
	  */
	async uploadFileWithMappings(mappings, sheetName){
    this.$refs.columnMapper.loadingUpload = true;

		const uploadedFile = this.uploadedFile;
    try {
        const res = await api.emissions.uploadBulkFile(uploadedFile, mappings, sheetName);
        
        if (!res.data.allSuccessful) {
          this.failedFile = res.data.failed.data;
          this.failedRowsText = res.data.message;
          this.failedFileName = `${uploadedFile.name.replace(/\.[^/.]+$/, "")} - Failed Upload.xlsx`;
          this.displayFailedRowsModal = true;
        }

        this.showMapping = false;
		this.fileUploadStore.manualBulkUploadFile = null;
        notify.primary(res.data.message);
    } catch (err) {
      notify.withObject(err.response);
    } finally {
      this.$refs.columnMapper.loadingUpload = false;
    }
  },

	downloadFailedRows() {
    promptDownload(this.failedFile, this.failedFileName);
		this.displayFailedRowsModal = false;
	},

  }
}
</script>

<style lang="less" scoped>

.bulk-upload-guide {
	color: var(--primary);
	cursor: pointer;
}

.bulk-upload-guide:active {
	color: #9b76a6;
}
</style>

