<template>
	<div>
        <div class="header">
            <h1 class="main-heading">{{ displayEmissionSource }}</h1>
        </div>
		<div class="tabs-container">
			<q-tabs dense no-caps active-color="primary" indicator-color="success" align="left" narrow-indicator>
                <q-route-tab 
                    :to="{ query: { tab: 'trends' } }"
                    label="Trends" 
                    exact 
                    replace 
                />
                <q-route-tab 
                    v-if="subPermissions.viewTargets.includes(subscription)"
                    :to="{ query: { tab: 'goals' } }"
                    label="Goals"
                    exact
                    replace
                />
                <q-route-tab
                    v-if="permissions.addData.includes(userRole)"
                    :to="{ query: { tab: 'documents' } }"
                    label="Documents" 
                    exact
                    replace
                />
                <!-- <q-route-tab
                    v-if="permissions.addData.includes(userRole)"
                    :to="{ query: { tab: 'manual-upload' } }"
                    label="Manual Upload" 
                    exact
                    replace
                />
                <q-route-tab 
                    v-if="(emissionSource === 'electricity' ? flags.FLAG_4435_ELECTRICITY_INVOICE : hasInvoices) && permissions.addData.includes(userRole)"
                    :to="{ query: { tab: 'invoice-upload' } }"
                    label="Invoice Upload" 
                    exact
                    replace 
                /> -->
                <q-route-tab
                    v-if="emissionSource === 'mileage-claims'"
                    :to="{ query: { tab: 'claims' } }"
                    label="Claims" 
                    exact
                    replace
                />
                <!-- <q-route-tab 
                    v-if="permissions.addDataConnections.includes(this.userRole) && subPermissions.addDataConnections.includes(this.subscription) && hasConnections"
                    :to="{ query: { tab: 'connections' } }"
                    label="Connections" 
                    exact
                    replace
                /> -->
                <q-route-tab
                    v-for="tab in customTabs"
                    :key="tab.name"
                    :name="tab.name"
                    :label="tab.label"
                />
			</q-tabs>
		</div>
        <q-separator color="grey" />
		<div v-if="!loading && tab == 'trends'">
			<div class="data-dashboard-container">
				<h2 class="tab-section-heading">Trends</h2>

                <InsightsContainerComponent
                    :emissionSource="emissionSource"
                    :icon="datasetDefinition.icon"
                />
			</div>

			<div class="data-dashboard-chart-container">
				<TrendGraphComponent :emissionSource="emissionSource" :emissionUnit="datasetDefinition.unit"/>
            </div>  
        </div>
		<ViewGoalsComponent v-if="tab === 'goals'" :emissionSource="emissionSource"/>
		<ManualUploadComponent v-if="tab === 'manual-upload' && definitionLoaded" :exampleRow="datasetDefinition.exampleRow" :emissionSource="emissionSource" @initialise="initialise()" />
		<DocumentsTabComponent v-if="tab === 'documents'" :type="emissionSource" :loadingFiles="loadingFiles" :files="files" @onInitialize="getFiles()" />
        <InvoiceUploadComponent v-if="tab === 'invoice-upload'" :emissionSource="emissionSource" />
        <MileageComponent v-if="tab === 'claims'" />
		
        <div v-if="tab == 'connections'">
            <FreightDataSourceOptionsComponent v-if="emissionSource == 'freight'" />
            <ElectricityDataSourceOptionsComponent v-if="emissionSource == 'electricity'"/>
            <FuelConnectionsListComponent v-if="emissionSource == 'transport-fuel'"/>
            <WasteConnectionsListComponent v-if="emissionSource == 'waste'"/>
        </div>

        <!-- Add Custom Components Here Per customTab -->
        <div v-for="tabInfo in customTabs" :key="tabInfo.name">
            <component :is="tabInfo.component" v-if="tab == tabInfo.name"/>
        </div>

	</div>
</template>

<script>
import { useSiteStateStore } from '@/stores/siteState.store';

import notify from "@/services/util/notify";
import api from "@/services/api/api";


import getRole from "@/services/util/role";
import roles from "@/roles";
import permissions from "@/rolePermissions";
import getSub from "@/services/util/subscription.js";
import subPermissions from "@/subPermissions";

// Connections Components
import FreightDataSourceOptionsComponent from "@/components/DataComponents/ConnectDataSourceComponents/Freight/FreightOptionsComponent.vue";
import ElectricityDataSourceOptionsComponent from "@/components/DataComponents/ConnectDataSourceComponents/Electricity/ElectricityOptionsComponent.vue";
import FuelConnectionsListComponent from "@/components/DataComponents/ConnectDataSourceComponents/Fuel/FuelConnectionsListComponent.vue";
import WasteConnectionsListComponent from "./ConnectDataSourceComponents/Waste/WasteConnectionsListComponent.vue";

import TrendGraphComponent from "./TrendGraphComponent.vue";
import CustomDatasetDataUpload from "./CustomDatasetComponents/CustomDatasetDataUploadComponent.vue";
import CustomDatasetFiles from "./CustomDatasetComponents/CustomDatasetFilesComponent.vue";
import InsightsContainerComponent from "./InsightsComponents/InsightsContainerComponent.vue";
import ViewGoalsComponent from '@/components/GoalsComponents/ViewGoalsComponent.vue';
import DocumentsTabComponent from './DocumentsComponents/DocumentsTabComponent.vue';
import ManualUploadComponent from '../ManualUploadComponents/ManualUploadComponent.vue';
import InvoiceUploadComponent from '../ManualUploadComponents/InvoiceUploadComponent.vue';
import MileageComponent from '../MileageComponents/MileageComponent.vue';

const ViewDatasetComponent = {
	name: "ViewDatasetComponent",
    props: ["emissionSource", "customTabs"],
	components: {
		CustomDatasetDataUpload,
		CustomDatasetFiles,
		DocumentsTabComponent,
		InsightsContainerComponent,
		ViewGoalsComponent,
        ManualUploadComponent,
		TrendGraphComponent,
        InvoiceUploadComponent,
        MileageComponent,

        // Connections Components
        FreightDataSourceOptionsComponent,
        ElectricityDataSourceOptionsComponent,
        FuelConnectionsListComponent,
        WasteConnectionsListComponent
	},
	data() {
		return {
            carbonRate: {},
            loadingChart: false,
            loading: false,
            definitionLoaded: false,

            datasetDefinition: {},

            // Access Control
            userRole: getRole(),
            roles: roles,
            permissions: permissions,
            subscription: getSub(),
            subPermissions: subPermissions,

            tab: undefined,
            files: [],
            loadingFiles: false,
		};
	},

    async beforeMount(){
		await this.initialise();
    },

	async created() {
        if (!this.$route.query.tab) {
            this.$route.query.tab = "trends";
        }

        this.tab = this.$route?.query?.tab;
        if(this.tab == "connections" && !this.hasConnections){
            this.tab = "trends";
        } else if (this.tab == "invoiceUpload" && !this.hasInvoices){
            this.tab = "trends";
        }
	},

	watch: {
		siteId() {
			this.initialise();
		}
	},

	computed: {
        displayEmissionSource() {
            return this.emissionSource.split(/-/).map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(" ");
        },

        hasConnections() {
            return ["electricity", "freight", "transport-fuel", "waste"].includes(this.emissionSource);
        },

        hasInvoices() {

            // Put all currently fully implemented invoice scrapers here
            const hasInvoices = ["air-travel", "electricity", "waste", "transport-fuel", "stationary-fuel"]

            // Feature flagged
            if (this.flags?.FLAG_4744_CCC_INVOICE) {
                hasInvoices.push("water");
            }

            return hasInvoices.includes(this.emissionSource);
        },
		
		siteId() {
			return useSiteStateStore().siteId
		}
	},

	methods: {
        /**
		* @desc Initialises all required page data and handles loading vars.
		*/
		async initialise() {
			this.loading = true;
            
            await api.emissions.getEmissionDatasetDefiniton(this.emissionSource)
            .then((res) => {
                this.datasetDefinition = res.data;
            }).catch(err => {
                notify.withObject(err.response);
            }).finally(() => {
                this.definitionLoaded = true;
                this.loading = false;
            })
            
		},

		/**
		 * @desc Retrieve all the files
		 */
		async getFiles() {  
            this.loadingFiles = true;
                    
            api.file.getAllFileInformationByType(this.emissionSource, this.siteId).then((res) => {
                this.files = res.data;
            }).catch(err => {
                notify.withObject(err.response, "top","Files");
            }).finally(() => this.loadingFiles = false)
		},



        /**
        * @desc Given month number, return the string version
        * @param {Number} monthNumber month number to convert to string
        * @return {String} string version of month
        */
        toMonthName(monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber - 1);

            return date.toLocaleString("en-US", {
                month: "long",
            });
        },


        /**
         * @desc Given hour number, return time of day (etc 9:00am)
         * @param {Number} hourNumber hour to conver to TOD (time-of-day)
         * @return {String} TOD given hour
         */
        toHourString(hourNumber) {
            if (hourNumber < 13) {
                return "0" + hourNumber + ":00am";
            } else {
                return "0" + hourNumber - 12 + ":00pm";
            }
        },

	},
};

export default ViewDatasetComponent;
</script>

<style scoped lang="less" src="../../assets/styles/data.less" />
