<template>
	<!-- Emission source usage changes -->
	<div class="home-insights-container">
		<div v-if="!loading" style="width: 100%">
			<q-carousel
				class="insights-carousel"
				style="width: 100%"
				v-model="slide"
				transition-prev="slide-right"
				transition-next="slide-left"
				animated
				:navigation="slideCount !== 1"
				navigation-position="bottom"
				infinite
				control-color="grey"
				ref="carousel"
			>
				<q-carousel-slide
					v-for="(n, index) in slideCount"
					:key="index.id"
					:name="Number(index + 1)"
					class="insights-carousel-slide q-ma-none"
				>
					<div v-if="insightChanges.length > 0" class="home-insights-cards-container">
						<HomeCardComponent class="home-insight-card home-card-data" v-for="insight in insightChanges.slice(
								index * cardsPerslide,
								index * cardsPerslide + cardsPerslide
							)"
							:key="insight.id"
							:card="getTabInfo(insight)"
							@toggleFavourite="toggleFavourite"
							:favourite="isFavourite(insight)"
							@click="this.$router.push({ name: 'viewDataset', params: { emissionSource: insight.emissionSource }})"
							>
							<div class="card-slot-content" >
								<q-icon :name="insight.icon" size="2em" />

									<span v-if="this.tab != 'trees' && this.tab != 'cars'" class="percentage-change" :class="[insight.change < 0 ? 'increase' : insight.change > 0 ? 'decrease': 'neutral']">
										<q-icon :name="insight.change >= 0 ? 'arrow_upward' : 'arrow_downward'" size="1.25em" />
										{{ Math.abs(insight.change) }}%
									</span>
									
									<span v-if="this.tab == 'trees'" class="percentage-change" :class="[insight.change < 0 ? 'increase' : insight.change > 0 ? 'decrease': 'neutral']">
										{{ insight.change <= 0 ? 'Planted': 'Removed' }}
									</span>
									
									<span v-if="this.tab == 'cars'" class="percentage-change" :class="[!carsAdded(insight) ? 'increase' : carsAdded(insight) ? 'decrease' : 'neutral']">
										{{ getTabInfo(insight).value == 0 ? '' : carsAdded(insight) ? 'Added' : 'Removed' }}
									</span>
							</div>
						</HomeCardComponent>
					</div>
					<div v-else class="home-insights-cards-container">
						<HomeCardComponent class="home-insight-card" v-for="insight in noDataInsights" :key="insight.label" :card="getTabInfo(insight)">
							<div class="card-slot-content" >
								<q-icon :name="insight.icon" size="2em" />
								<q-btn class="connect-btn" style="margin-top: 1em" label="Connect" no-caps color="primary" @click="goToDataPage(insight.connection)" />
							</div>
						</HomeCardComponent>
					</div>
				</q-carousel-slide>
				<template v-if="slideCount !== 1" v-slot:control>
						<q-carousel-control
							position="bottom"
							class="insights-carousel-control"
						>
							<q-btn round color="secondary" icon="arrow_back" @click="$refs.carousel.previous()" />
							<q-btn round color="secondary" icon="arrow_forward" @click="$refs.carousel.next()" />
						</q-carousel-control>
				</template>
			</q-carousel>
		</div>

		<!-- Loading Spinner -->
		<div
			v-if="loading"
			class="home-loading-spinner-container"
		>
			<q-spinner
				color="primary"
				size="3.65em"
			/>
		</div>
	</div>
</template>

<script>
import api from "./../../services/api/api";
import notify from "@/services/util/notify";
import HomeCardComponent from './HomeCardComponent.vue';

const HomeComponent = {
	name: "Home",
	components: {
		HomeCardComponent,
	},
	props: ["dateRange", "siteId", "tab", "carbonPrice", "subscription"],

	data() {
		return {
			cardsPerslide: 4,
			insightChanges: [],
			HomeInsightsComponentSettings: {},
			loading: false,
			firstLoading: true,
			chartLoaded: false,
			slide: 1,

			// This var determines how big the text of the q-tooltips (that exist when an emission
			// source's proportion is < 10% of total co2e emissions) are displayed.
			tooltipSizeRem: 1.2,

			insights: {
				changes: {},
				proportions: {},
			},

			noDataInsights: [
				{
					carbonUsage: {
						current: 0,
						historical: 0,
					},
					usage: {
						current: 0,
						historical: 0
					},
					change: 0,
					title: "Electricity",
					unit: "kWh",
					icon: "bolt",
					connection: "electricity",
				},
				{
					carbonUsage: {
						current: 0,
						historical: 0,
					},
					usage: {
						current: 0,
						historical: 0
					},
					change: 0,
					title: "Transport Fuels",
					unit: "litres",
					icon: "local_gas_station",
					connection: "transport-fuel",
				},
				{
					carbonUsage: {
						current: 0,
						historical: 0,
					},
					usage: {
						current: 0,
						historical: 0
					},
					change: 0,
					title: "Waste",
					unit: "kg",
					icon: "fas fa-trash-alt",
					connection: "waste",
				},
				{
					carbonUsage: {
						current: 0,
						historical: 0,
					},
					usage: {
						current: 0,
						historical: 0
					},
					change: 0,
					title: "Freight",
					unit: "tkm",
					icon: "local_shipping",
					connection: "freight",
				},
			]
		};
	},

	created() {
		this.onInitialize();
	},

	watch: {
		dateRange() {
			this.onInitialize();
		},
	},

	computed: {
		slideCount() {
			const n = this.insightChanges.length > 0 ? this.insightChanges.length : this.noDataInsights.length;
			return Math.ceil(n / this.cardsPerslide);
		},
	},

	methods: {
		/**
		 * @desc Returns true/false if the carbon usage is equivalent to adding/removing x cars.
		 * 
		 * @param {Object} carInsight 
		 * @returns {Boolean}
		 */
		carsAdded(carInsight) {
			return carInsight.carbonUsage.current - carInsight.carbonUsage.historical > 0;
		},
		/**
		 * @desc Given an insight, determine if it has been favourited by the user
		 * @param {Object} insight the insight being checked if favourite
		 */
		isFavourite(insight) {
			if (this.HomeInsightsComponentSettings.favourites && this.HomeInsightsComponentSettings.favourites.includes(insight.emissionSource)) {
				return true;
			}
		},

		/**
		 * Formats the emission source insights.
		 * 
		 * @param {Object} insight insight to be formated
		 */
		getTabInfo(insight){
			var value; var unit;

			switch(this.tab){
				case "carbon":
					value = Math.abs(parseInt((insight.carbonUsage.current).toFixed(0))).toLocaleString();
					unit = 'kg CO2e';
					break;
				case "trees":
					// 38 is roughly the number of trees needed to offset 1 ton of CO2e
					value = Math.abs(parseInt((((insight.carbonUsage.current - insight.carbonUsage.historical)/1000)*38).toFixed(0))).toLocaleString();
					unit = "trees";
					break;
				case "cars":

					var carEmission = 0;

					// 4600 is the amount of CO2e in kg produced by an avg passenger car yearly
					switch(this.dateRange) {
						case 'Weekly':
							carEmission = 52;
							break;
						case 'Monthly':
							carEmission = 12;
							break;
						case 'Yearly': 
							carEmission = 1;
							break;
						default:
							break;
					}

					value = Math.abs(parseInt(((((insight.carbonUsage.current - insight.carbonUsage.historical)/4600)/carEmission)).toFixed(0))).toLocaleString();
					unit = "cars";
					break;
				case "finance":
					var price = this.carbonPrice;
					value = "$" + parseInt(((insight.carbonUsage.current - insight.carbonUsage.historical)/1000*price).toFixed(0)).toLocaleString();
					break;

				default:
					break;
			}

			return {
				emissionSource: insight.emissionSource,
				label: insight.title,
				value: value,
				unit: unit,
			}
		},

		/**
		 * @desc Toggle the favourite status of an emission source insight
		 * @param {String} emissionSource the source being toggled
		 */
		async toggleFavourite(emissionSource) {
			if (
				this.HomeInsightsComponentSettings.favourites.includes(
					emissionSource
				)
			) {
				this.HomeInsightsComponentSettings.favourites.splice(
					this.HomeInsightsComponentSettings.favourites.indexOf(
						emissionSource
					),
					1
				);
			} else {
				this.HomeInsightsComponentSettings.favourites.push(
					emissionSource
				);
			}
			this.sortCarousel();
			await api.users.addUserSettings({
				homeinsightscomponent: this.HomeInsightsComponentSettings,
			});
		},

		/**
		 * @desc Sort the insights carousel
		 */
		sortCarousel() {
			this.insightChanges.sort(this.compareFavourites);
		},

		/**
		 * @desc Compare favourites, and sort alphabetical
		 * @param {Object} a first favourite
		 * @param {Object} b other favourite to compare against
		 */
		compareFavourites(a, b) {
			this.HomeInsightsComponentSettings.favourites =
				this.HomeInsightsComponentSettings.favourites ?? [];

			if (
				this.HomeInsightsComponentSettings.favourites.includes(
					a.emissionSource
				) &&
				!this.HomeInsightsComponentSettings.favourites.includes(
					b.emissionSource
				)
			) {
				return -1;
			}
			if (
				!this.HomeInsightsComponentSettings.favourites.includes(
					a.emissionSource
				) &&
				this.HomeInsightsComponentSettings.favourites.includes(
					b.emissionSource
				)
			) {
				return 1;
			}
			// Else go to the 2nd item
			if (a.emissionSource < b.emissionSource) {
				return -1;
			} else if (a.emissionSource > b.emissionSource) {
				return 1;
			} else {
				// nothing to split them
				return 0;
			}
		},

		/**
		 * @desc On load up, retrieve the required data from the backend
		 */
		async onInitialize() {
			this.loading = true;
			let query = this.queryBuilder();

			try {
				this.HomeInsightsComponentSettings = (await api.users.getUserSettings()).data.userSettings?.homeinsightscomponent ?? {};
				
				this.insightChanges = (await api.data.getHomePageInsights(query, this.siteId)).data.changes;
				this.sortCarousel();
				
			} catch (error) {
				notify.withObject(error.response);
			} finally {
				this.loading = false;
				this.firstLoading = false;
			}
		},

		/**
		 * @desc Pushes router to correct page init
		 */
		goToDataPage(emission) {
			if (this.subscription === "Basic") {
				this.$router.push({
					name: "viewDataset",
					params: { emissionSource: emission },
					query: { tab: "upload" },
				});
			} else {
				this.$router.push({
					name: "viewDataset",
					params: { emissionSource: emission },
					query: { tab: "connections" },
				});
			}
		},


		/**
		 * @desc Determines how many days back the query should use based on what this.dateRange is.
		 * @returns {Number} number of days to go back
		 */
		queryBuilder() {
			let numDaysBack = 0;
			switch (this.dateRange) {
				case "Weekly":
					numDaysBack = 7;
					break;
				case "Monthly":
					numDaysBack = 30;
					break;
				case "Yearly":
					numDaysBack = 365;
					break;
			}
			return numDaysBack;
		},
	},
};

export default HomeComponent;
</script>

<style lang="less" src="@/assets/styles/home.less" scoped />
