<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <ElectrictyOptionsComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import ElectrictyOptionsComponent from "@/components/DataComponents/ConnectDataSourceComponents/Electricity/ElectricityOptionsComponent.vue";

export default {
    name: "electricity-data-source-view",
    components: { ElectrictyOptionsComponent },
    title: 'ElectricityDataSourceOptions',
    setup() {
    return { sidebarWidth }
    }
};
</script>


<style >

</style>