/*eslint indent: ["error", "tab", {"SwitchCase": 1}]*/
// carbon.chart.js
/*  */

const chartHelpers = require("./helpers.charts.js");
const { aircraftCodeCarbonRates } = require("./airlineCodes");


/**
 * Takes a list of raw electricity carbon data, and formats it in the chart.js
 * line graph format. Will process and determine what the labels should be.
 * Time granularity is monthly.
 *
 * @param {*} electricityCarbonData List of raw electricity carbon data,
 * @returns Data formatted as chart.js line graph data.
 */
exports.generateElectricityCarbonLineChart = function (electricityCarbonData, electricityLossData, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Electricity Carbon Emissions (Grid Average)",
				data: [],
				backgroundColor: "#f87979",
				borderColor: "#f87978",
			},
			{
				label: "Electricity Transmission & Distribution Loss",
				data: [],
				backgroundColor: "#002BFF",
				borderColor: "#002BFF",
			},
		],
	};

	// Create an array of Date objects based on each petrol entry's timestamp value.
	let datesArray = [];
	for (let item of electricityCarbonData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	// Make an object with the keys being the monthly labels, and the values being the number of kwh for that given month (0 by default)
	let barData = {};
	const lossData = {};
	for (let label of chartData.labels) {
		barData[label] = 0.0;
		lossData[label] = 0.0;
	}

	// For each entry in petrolData, increment the relevant attribute of the data object by the kwh recorded.
	for (let entry of electricityCarbonData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		barData[entryLabel] += parseFloat(entry.carbonEmissions);
	}

	for (const [date, data] of Object.entries(electricityLossData)) {
		const entryLabel = chartHelpers.getEntryLabelString(date, granularity);
		lossData[entryLabel] = data["Electricity Loss"].sum;
	}

	// Set the first dataset of chartData to a list of the values of the data object.
	chartData.datasets[0].data = Object.values(barData);
	chartData.datasets[1].data = Object.values(lossData);
	
	return chartData;
};


/**
 * Returns a line chart for petrol and diesel usage
 * @param {*} transportCarbonData
 * @param {String} granularity granularity of graph
 * @returns
 */
exports.generateTransportCarbonLineChart = function (transportCarbonData, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Petrol Emissions",
				data: [],
				borderColor: "#f87979",
				backgroundColor: "#f88979",
			},
			{
				label: "Diesel Emissions",
				data: [],
				borderColor: "#28a3be",
				backgroundColor: "#28a4be",
			},
		],
	};

	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of transportCarbonData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	// Make an object with the keys being the labels, and the values being the number of litres for that given month (0 by default)
	let petrolData = {};
	let dieselData = {};
	for (let label of chartData.labels) {
		petrolData[label] = 0.0;
		dieselData[label] = 0.0;
	}

	// For each entry in transportCarbonData, increment the relevant attribute of the data object by the litres recorded.
	for (let entry of transportCarbonData) {

		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);

		if (entry.type == "petrol") {
			petrolData[entryLabel] += parseFloat(entry.carbonEmissions);
		} else if (entry.type == "diesel") {
			dieselData[entryLabel] += parseFloat(entry.carbonEmissions);
		}
	}
	chartData.datasets[0].data = Object.values(petrolData);
	chartData.datasets[1].data = Object.values(dieselData);

	return chartData;
};

/**
 * Returns a line chart for petrol and diesel usage
 * @param {*} coalCarbonData
 * @param {*} gasCarbonData
 * @param {String} granularity granularity of graph
 * @returns
 */
exports.generateStationaryFuelsCarbonLineChart = function (coalCarbonData, gasCarbonData, woodCarbonData, naturalGasDistributionLossData, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Gas Emissions",
				data: [],
				borderColor: "#f87979",
				backgroundColor: "#f88979",
			},
			{
				label: "Coal Emissions",
				data: [],
				borderColor: "#28a3be",
				backgroundColor: "#28a4be",
			},
			{
				label: "Wood Emissions",
				data: [],
				borderColor: "#ff872b",
				backgroundColor: "#ff872b",
			},
			{
				label: "Natural Gas Transmission & Distribution Loss",
				data: [],
				borderColor: "#0064FF",
				backgroundColor: "#0064FF",
			},
		],
	};

	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of coalCarbonData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);
	
	// Make an object with the keys being the monthly labels, and the values being the number of kwh for that given month (0 by default)
	let gasData = {};
	let coalData = {};
	let woodData = {};
	let naturalGasData = {};
	for (let label of chartData.labels) {
		gasData[label] = 0.0;
		coalData[label] = 0.0;
		woodData[label] = 0.0;
		naturalGasData[label] = 0.0;
	}

	for (let entry of gasCarbonData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		gasData[entryLabel] += parseFloat(entry.carbonEmissions);
	}

	for (let entry of coalCarbonData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		coalData[entryLabel] += parseFloat(entry.carbonEmissions);
	}

	for (let entry of woodCarbonData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		woodData[entryLabel] += parseFloat(entry.carbonEmissions);
	}

	for (const [date, data] of Object.entries(naturalGasDistributionLossData)) {
		const entryLabel = chartHelpers.getEntryLabelString(date, granularity);
		naturalGasData[entryLabel] = data["Natural Gas"].sum;
	}

	chartData.datasets[0].data = Object.values(gasData);
	chartData.datasets[1].data = Object.values(coalData);
	chartData.datasets[2].data = Object.values(woodData);
	chartData.datasets[3].data = Object.values(naturalGasData);

	return chartData;
};

/**
 * Takes a list of air travel data along with plane co2e conversion rats and returns the data in chart.js graph format.
 * The returned data will serve as both line and bar chart data.
 *
 * @param {Array} airTravelData The air travel data to convert to chart.js graph format
 * @param {Array} carbonConversions The conversion rates for plane types.
 * @param {String} granularity granularity of graph
 */
exports.generateAirTravelCarbonLineChart = function (airTravelData, carbonConversions, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Air Travel Carbon Emissions",
				data: [],
				borderColor: "rgb(75, 192, 192)",
				backgroundColor: "rgb(75, 192, 192)",
			},
		],
	};

	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of airTravelData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	let dataset = {};
	for (let label of chartData.labels) {
		dataset[label] = 0.0;
	}

	// Set up the conversion lookup object.
	let conversions = {};
	for (let conversion of carbonConversions) {
		conversions[conversion.carbonRateName] = conversion.conversionRate;
	}
	for (let entry of airTravelData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);

		if (!aircraftCodeCarbonRates[entry.aircraftType]) {
			console.error(`Encountered unknown aircraft code: ${entry.aircraftType}`);
		} else {
			let conversionName = aircraftCodeCarbonRates[entry.aircraftType];
			dataset[entryLabel] += entry.amount * conversions[conversionName];
		}
	}
	chartData.datasets[0].data = Object.values(dataset);

	return chartData;
};


/**
 * Takes a list of wasteData with conversion rates and returns the data in chart.js graph format.
 * The returned data will serve as both
 *
 * @param {*} wasteData The waste data to convert to chart.js graph format
 * @param {String} granularity granularity of graph
 */
exports.generateWasteCarbonLineChart = function (wasteData, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Waste Carbon Emissions",
				data: [],
				borderColor: "rgb(75, 192, 192)",
				backgroundColor: "rgb(75, 192, 192)",
			},
		],
	};

	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of wasteData) {
		datesArray.push(item.timestamp);
	}
	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	let dataset = {};
	for (let label of chartData.labels) {
		dataset[label] = 0.0;
	}

	for (let entry of wasteData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		switch (entry.units.toLowerCase()) {
			case "kg":
				dataset[entryLabel] += entry.carbonEmissions;
				break;
			case "litres":
				// 0.185 is the conversion rate from Litres to Kg.
				dataset[entryLabel] += entry.carbonEmissions * 0.185;
				break;
			default:
				console.error(
					`Encountered invalid Waste entry units value: ${entry.units}`
				);
				break;
		}
	}

	chartData.datasets[0].data = Object.values(dataset);

	return chartData;
};


/**
 * Takes a list of waterData with conversion rates and returns the data in chart.js graph format.
 * The returned data will serve as both
 *
 * @param {*} waterData The water data to convert to chart.js graph format
 */
exports.generateWaterCarbonChart = function (waterData, granularity) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Water Carbon Emissions",
				data: [],
				borderColor: "rgb(75, 192, 192)",
				backgroundColor: "rgb(75, 192, 192)",
			},
		],
	};

	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of waterData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	let dataset = {};
	for (let label of chartData.labels) {
		dataset[label] = 0.0;
	}

	for (let entry of waterData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		dataset[entryLabel] += entry.carbonEmissions;
	}

	chartData.datasets[0].data = Object.values(dataset);

	return chartData;
};

/**
 * Takes a list of staffCommuting data with emissions already calculated and returns the data in chart.js graph format.
 * The returned data will serve as both
 *
 * @param {*} staffCommutingData The water data to convert to chart.js graph format
 */
exports.generateStaffCommuteCarbonLineChart = function (staffCommutingData, granularity="Monthly") {
	let colours = {
		personalVehicle: "#26D391",
		publicTransport: "#000000",
		carbonFree: "#2D395D",
	};

	let chartData = {
		labels: [],
		datasets: [
			{
				label: "Personal Vehicle Usage",
				data: [],
				backgroundColor: colours.personalVehicle,
				borderColor: colours.personalVehicle,
			},
			{
				label: "Public Transport Usage",
				data: [],
				backgroundColor: colours.publicTransport,
				borderColor: colours.publicTransport,
			},
			{
				label: "Carbon Free Transport Usage",
				data: [],
				backgroundColor: colours.carbonFree,
				borderColor: colours.carbonFree,
			},
		],
	};

	// Create an array of Date objects based on each diesel entry and petrol entry timestamp value.
	let datesArray = [];
	for (let item of staffCommutingData) {
		datesArray.push(item.date);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	// Make an object with the keys being the monthly labels, and the values being the number of quantity of fuel for that given month (0 by default)
	let personalVehicleMappingData = {};
	let publicTransportMappingData = {};
	let carbonFreeMappingData = {};


	for (let label of chartData.labels) {
		personalVehicleMappingData[label] = 0.0;
		publicTransportMappingData[label] = 0.0;
		carbonFreeMappingData[label] = 0.0;
	}

	// For each entry in dieselData, increment the relevant attribute of the data object by the quantity of fuel recorded.
	for (let entry of staffCommutingData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.date, granularity);
		switch (entry.transportCategory) {
			case "Personal Vehicle": {
				personalVehicleMappingData[entryLabel] += parseFloat(entry.emissions);
				break;
			}
			case "Public Transport": {
				publicTransportMappingData[entryLabel] += parseFloat(entry.emissions);
				break;
			}
			case "Carbon Free": {
				publicTransportMappingData[entryLabel] += parseFloat(entry.emissions);
				break;
			}
		}
	}


	// Set the first dataset of chartData to a list of the values of the petrolMappingData object.
	chartData.datasets[0].data = Object.values(personalVehicleMappingData);
	chartData.datasets[1].data = Object.values(publicTransportMappingData);
	chartData.datasets[2].data = Object.values(publicTransportMappingData);
	return chartData;
};


/**
 * Returns a pie chart for total carbon
 * @param {*} totalCarbonData
 * @returns
 */
exports.generateTotalCarbonPieGraph = function (totalCarbonData) {
	let chartData = {
		labels: ["Electricity", "Petrol", "Diesel", "Waste", "Freight"],
		datasets: [
			{
				label: "Total Carbon Emissions (CO2e)",
				data: [0, 0, 0, 0, 0],
				backgroundColor: [
					"#58508d",
					"#ff6361",
					"#ffa600",
					"#6e0000",
					"#33ddaa",
				],
			},
		],
	};
	let totals = {
		electricity: 0,
		petrol: 0,
		diesel: 0,
		waste: 0,
		freight: 0,
	};

	// For each entry in transportCarbonData, increment the relevant attribute of the data object by the kwh recorded.
	for (let entry of totalCarbonData) {
		if (entry.type == "electricity") {
			totals.electricity += parseFloat(entry.carbonEmissions);
		} else if (entry.type == "petrol") {
			totals.petrol += parseFloat(entry.carbonEmissions);
		} else if (entry.type == "diesel") {
			totals.diesel += parseFloat(entry.carbonEmissions);
		} else if (entry.type == "waste") {
			totals.waste += parseFloat(entry.carbonEmissions);
		} else if (entry.type == "freight") {
			totals.freight += parseFloat(entry.carbonEmissions);
		}
	}
	// Calculate total emissions
	let total = 0;
	Object.values(totals).forEach((item) => {
		total += item;
	});

	// For each data type, calculate the percentage of the total emissions it is and round to 1 dp
	chartData.datasets[0].data[0] = totals.electricity.toFixed(1);
	chartData.datasets[0].data[1] = totals.petrol.toFixed(1);
	chartData.datasets[0].data[2] = totals.diesel.toFixed(1);
	chartData.datasets[0].data[3] = totals.waste.toFixed(1);
	chartData.datasets[0].data[4] = totals.freight.toFixed(1);
	chartData.totalEmissions = total;
	return chartData;
};

/**
 * Takes a list of wasteData with conversion rates and returns the data in chart.js graph format.
 * The returned data will serve as both
 *
 * @param {*} wasteData The waste data to convert to chart.js graph format
 */
exports.generateCustomCarbonLineChart = function (
	customDatasetData,
	datasetDefinition,
	granularity
) {
	let chartData = {
		labels: [],
		datasets: [
			{
				label: datasetDefinition.units,
				data: [],
				borderColor: "#71DA78",
				backgroundColor: "#71DA78",
			},
		],
	};
	// Create an array of Date objects based on each diesel entry's timestamp value.
	let datesArray = [];
	for (let item of customDatasetData) {
		datesArray.push(item.timestamp);
	}

	chartData.labels = chartHelpers.getDateLabels(datesArray, granularity);

	let dataset = {};
	for (let label of chartData.labels) {
		dataset[label] = 0.0;
	}

	for (let entry of customDatasetData) {
		let entryLabel = chartHelpers.getEntryLabelString(entry.timestamp, granularity);
		dataset[entryLabel] += parseFloat(entry.amount * datasetDefinition.emissionsFactor);
	}

	chartData.datasets[0].data = Object.values(dataset);

	return chartData;
};
