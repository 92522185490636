<template>
    <q-card class="q-pa-sm dialog-card">
      <q-card-section class="card-content">
			<q-form @submit="submitMappings()">
				<div>
					<span class="sub-heading">Site Mapping Required</span>
					<p>In order to upload your data please provide the sites you would like to map your data to.</p>
				</div>
				<div class="row justify-center">
					<q-spinner v-if="loadingValues" color="primary" size="xl"/>
					<DataFeedMappingComponent v-else :values="connectionValues" :mappings="connectionMappingValues" :savedMappings="savedMappings" @update="(_, value) => mappings = value" />
				</div>
			<q-card-actions class="datafeed-button-center">
				<q-btn
					:loading="loadingSubmit"
					no-caps
					class="button"
					color="secondary"
					label="Save"
					type="submit"
					
					/>
			</q-card-actions>
			</q-form>
      </q-card-section>
    </q-card>
</template>
<script>

import notify from '@/services/util/notify';
import DataFeedMappingComponent from './DataFeedMappingComponent.vue';
import api from '@/services/api/api';
import { useSiteStateStore } from '@/stores/siteState.store';


export default {
	name: "SiteRemappingComponent",
	components: {
		DataFeedMappingComponent,
	},

	props: {
		connectionId: {
			required: true
		}
	},

	data() {
		return {
			loadingValues: false,
			loadingSubmit: false,

			currentSites: [],

			connectionValues: {},
			connectionMappingValues: {},
			savedMappings: { sites: {} },
			mappings: {}
		}
	},

	computed: {
		sites() {
			return useSiteStateStore().siteList;
		}
	},

	async created() {
		await this.loadMappingValues();
		this.$emit("update-site-map", this.mappings);
	},

	watch: {
		async connectionId() {
			this.loadMappingValues();
		}
	},


	methods: {

		async loadMappingValues() {
			this.loadingValues = true;
			try {
				this.currentSites = (await api.dataFeeds.getUnmappedSites(this.connectionId)).data; 
				this.connectionValues = { sites: this.currentSites.map(site => site.supplierMappingValue) }
				this.connectionMappingValues = { sites: this.sites.map(site => site.name) }

				// If sites already exist create a saved mapping to upload
				for (const unmappedSite of (this.currentSites)) {
					this.savedMappings.sites[unmappedSite.supplierMappingValue] = this.sites.find((site) => site.id === unmappedSite.siteId)?.name
				}
			} catch (error) {
				console.error(error);
				notify.withObject(error);
			} finally {
				this.loadingValues = false;
			}
		},

		/**
		 * @desc Formats and submits site ids mapped to the incoming site values
		 */
		async submitMappings() {
			this.loadingSubmit = true;

			try {

				const siteIdMappings = {}

				// Set all the site names to site ids
				for (const [key, value] of Object.entries(this.mappings.sites)) {
					siteIdMappings[key] = this.sites.find(site => site.name === value).id
				}

				await api.dataFeeds.updateConnectionSiteMappings(this.connectionId, siteIdMappings);
				this.$emit('updated');
				this.$emit("update-site-map", this.mappings);


				this.$refs.dialog.hide();
			} catch (error) {
				console.error(error);
				notify.withObject(error);
			} finally {
				this.loadingSubmit = false;
			}
		}
	}
}

</script>
<style scoped lang="less" src="../../../assets/styles/dialog.less" />