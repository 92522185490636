/**
 * api.js
 *
 * All api requests - may need to separate into relevant folder
 */ 
import axiosInstance from "./axios-instance";
import file from "./file";
import users from "./users";
import tenantConnections from "./tenantConnections";
import sites from "./sites";
import electricity from "./electricity";
import diesel from "./diesel";
import petrol from "./petrol";
import carbon from "./carbon";
import transportFuels from "./transportFuels";
import airTravel from "./airTravel";
import fertiliser from "./fertiliser";
import EIEP from "./EIEP";
import waste from "./waste";
import freight from "./freight";
import commute from "./commute.js";
import mileage from "./mileage";
import goals from "./goals";
import audit from "./audit";
import data from "./data";
import customDatasets from "./customDatasets";
import stationaryFuels from "./stationaryFuels";
import gas from "./gas";
import coal from "./coal";
import water from "./water";
import livestock from "./livestock";
import emissions from "./emissions";
import reports from "./reports";
import filestorage from "./filestorage";
import targets from "./targets";
import dataTypes from "./dataTypes";
import stripe from "./stripe";
import tags from "./tags";
import cid from  "./cid";
import icp from "./icp";
import oid from "./oid";
import company from "./company";
import supplier from "./supplier";
import invoice from "./invoice";
import dataFeeds from "./dataFeeds";
import manualBulkUpload from "./manualBulkUpload";
import units from "./units";


const callWithRoute = async (url) => {
	return await axiosInstance.get(url).then((res) => res.data ? res.data : res);
}


export default {
	callWithRoute,
  users,
  file,
  tenantConnections,
  company,
  sites,
  goals,
  data,
  customDatasets,
  electricity,
  diesel,
  petrol,
  transportFuels,
  airTravel,
  carbon,
  EIEP,
	manualBulkUpload,
  waste,
  freight,
  mileage,
  commute,
  audit,
  stationaryFuels,
  gas,
  coal,
  water,
  livestock,
  fertiliser,
  emissions,
  reports,
  filestorage,
  targets,
  dataTypes,
  stripe,
  icp,
  tags,
  cid,
  oid,
  supplier,
  invoice,
  dataFeeds,
  units,
};
