<template>
    <div :style="{ 'margin-left': sidebarWidth }">
        <StationaryFuelsOptionsComponent/>
    </div>
</template>

<script>

import { sidebarWidth } from '@/components/SidebarComponents/state'
import StationaryFuelsOptionsComponent from "@/components/DataComponents/ConnectDataSourceComponents/StationaryFuels/StationaryFuelsOptionsComponent.vue";

export default {
    name: "stationary-fuels-data-source-view",
    components: { StationaryFuelsOptionsComponent },
    title: 'StationaryFuelsOptions',
    setup() {
    return { sidebarWidth }
    }
};
</script>


<style >

</style>